






































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Leave } from '@/vuex/offender/leave';
import { Offender } from '@/vuex/offender/offender';

import LeaveActions from '@/components/offender/leave/LeaveActions.vue';
import LeaveBackfillDialog from '@/components/offender/leave/LeaveBackfillDialog.vue';
import LeaveRequestDialog from '@/components/offender/leave/LeaveRequestDialog.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },

  created(): void {
    /**
     * At startup, we only want to fetch data if an offenderID was correctly
     * provided. A separate watcher on offenderID (see below) will trigger
     * data fetch if the offenderID is changed later.
     */
    if (this.offenderID) {
      this.fetchData();
    }
  },

  data(): any {
    return {
      hideInvoluntaryLeaves: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('offender', [
      'leavesForOffender',
      'voluntaryLeaveDaysThisYearForOffender',
      'isVoluntaryLeave',
      'offenderIsReleased',
      'offenderWithID',
    ]),
    ...mapGetters('housing', ['facilityName']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    /**
     * leaves builds the current list of Leaves for this Offender from
     * the Vuex store data.
     */
    leaves(): Leave[] {
      const leaves = this.leavesForOffender(this.offenderID);
      if (this.hideInvoluntaryLeaves) {
        return leaves.filter(this.isVoluntaryLeave);
      }
      return leaves;
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },

    /**
     * canAdd returns true if its acceptable to add a new *pending* leave. This
     * property only affects the large green + button, not the backfill feature.
     */
    canAdd(): boolean {
      if (this.offenderIsReleased(this.offenderID)) {
        // Released Offenders can't go on leave
        return false;
      }

      const activeLeaves = _.filter(this.leaves, (leave) => {
        return _.includes(
          ['pending', 'scheduled', 'in-progress'],
          leave.status,
        );
      });
      if (activeLeaves.length > 0) {
        return false;
      }

      return this.hasPermission(
        'offender.leave.request',
        'facility',
        this.offender.housingFacilityID,
      );
    },

    /**
     * canBackfill returns true if its acceptable to add a new historical leave.
     * (one which may have already have been completed).
     */
    canBackfill(): boolean {
      return this.hasPermission(
        'offender.leave.backfill',
        'facility',
        this.offender.housingFacilityID,
      );
    },

    /**
     * totalLeaveDays computes the total number of leave days this Offender
     * has used in the current year.
     */
    totalLeaveDays(): number {
      return this.voluntaryLeaveDaysThisYearForOffender(this.offenderID);
    },
  },

  watch: {
    /**
     * If offenderID prop is changed on this component, we need to ensure
     * data for the new Offender is fetched.
     */
    offenderID(val: number): void {
      if (this.offenderID) {
        this.fetchData();
      }
    },
  },

  methods: {
    /**
     * fetchData asks the Vuex store to fetch all leaves for this Offender and
     * update local state with the results. We don't need to listen for a
     * response because the `leaves` computed property will update automatically.
     */
    fetchData(): void {
      this.$store.dispatch('offender/fetchLeaves', {
        offenderID: this.offenderID,
      });
    },

    leaveDurationInDays(duration): number {
      return Math.floor(duration / 24.0);
    },

    leaveDurationBasedOnStatus(leave): number {
      if (leave.status === 'complete') {
        return leave.durationInHours;
      } else {
        return leave.estimatedDurationInHours;
      }
    },

    hideLeaveDuration(leave): boolean {
      return leave.status === 'denied' || leave.status === 'canceled';
    },
  },

  components: {
    LeaveActions,
    LeaveBackfillDialog,
    LeaveRequestDialog,
  },
});
