








































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },
  data() {
    return {
      loading: false,

      nationalityResults: [] as any[],
      birthplaceResults: [] as any[],
    };
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const url = `/offenders/by-birthplace?${this.queryString}`;
        const responses = await Promise.all([
          offenderAPI.get(`/offenders/by-nationality?${this.queryString}`),
          offenderAPI.get(`/offenders/by-birthplace?${this.queryString}`),
        ]);

        // responses will be an array ordered the same as the calls were made
        // we extract them by position:
        this.nationalityResults = _.get(responses, '[0].data.data') || [];
        this.birthplaceResults = _.get(responses, '[1].data.data') || [];
      } finally {
        this.loading = false;
      }
    },
  },
});
