































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import i18n from '@/i18n';
import { Prosecutor } from '@/vuex/investigation/prosecutor';
import { modelsToState } from '@/lib/vuex-domainmodel';

import AddProsecutorDialog from '@/components/investigation/prosecutor/AddProsecutorDialog.vue';
import EditProsecutorDialog from '@/components/investigation/prosecutor/EditProsecutorDialog.vue';

export default Vue.extend({
  props: {
    selectable: Boolean,
    dense: Boolean,
  },

  created() {
    this.fetchData();
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,

      resultIDs: [] as number[],
      query: '',

      // Pagination attributes
      currentPage: 1,
      pageSize: 10,
      serverTotalResults: 0,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('investigation', ['prosecutorWithID', 'prosecutorName']),

    allowAdding(): boolean {
      // Never let users who lack permissions add new entries.
      if (!this.hasPermission('prosecutors.create')) {
        return false;
      }

      if (!this.selectable) {
        // On the ProsecutorList page, or any context where the user is not being
        // asked to select something, allow them to Add new Prosecutors.
        return true;
      }

      return !!this.query; // Require a search before adding.
    },

    allowEditing(): boolean {
      if (this.selectable) {
        // Don't allow users to edit Prosecutor data while they are selecting
        // a Prosecutor via ProsecutorSearchSelector
        return false;
      }

      // Otherwise allow editing if they have the relevant permission.
      return this.hasPermission('prosecutors.create');
    },

    prosecutors(): Prosecutor[] {
      return _.map(this.resultIDs, this.prosecutorWithID);
    },

    headers(): any[] {
      const headers: any[] = [
        {
          text: i18n.t('person.name'),
          value: 'name',
        },
        {
          text: i18n.t('person.phoneNumber'),
          value: 'phoneNumber',
        },
        {
          text: i18n.t('person.emailAddress'),
          value: 'emailAddress',
        },
      ];
      if (this.allowEditing) {
        headers.unshift({
          text: i18n.t('command.edit'),
          value: 'id',
          width: '10%',
        });
      }
      return headers;
    },
  },
  methods: {
    /**
     * afterProsecutorAdded is triggered when the user successfully adds a new
     * record via the add dialog attached to the search interface. It receives
     * the ID of the newly-created Prosecutor, and immediately selects it if the
     * searcher is in selectable mode. If not, it forcefully adds it to the
     * front of the search results so the user immediately sees it.
     */
    afterProsecutorAdded(newID: number): void {
      if (this.selectable) {
        const pros = this.prosecutorWithID(newID);
        this.selectProsecutor(pros);
      } else {
        this.resultIDs.unshift(newID);
        if (this.serverTotalResults < 1) {
          this.serverTotalResults = 1;
        }
      }
    },

    /**
     * selectProsecutor is triggered by a click on one of the rows in the table,
     * or when a new record is added. Both can only happen when the component
     * has the selectable prop.
     */
    selectProsecutor(pros: Prosecutor): void {
      this.$emit('selected', pros.id);
    },

    /**
     * reset clears the search and resets search results. Primarily designed
     * to be called externally via a $ref to this component so that the searcher
     * can be reset when a dialog is opened.
     */
    reset(): void {
      this.$nextTick(() => {
        this.query = '';
        this.fetchData();
        (this.$refs.query as any).focus();
      });
    },

    /**
     * fetchData performs the actual search. It hits the API with the active
     * query and pagination criteria and updates the locally-held search results
     * data.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;

      const args = [] as string[];
      args.push(`page[size]=${this.pageSize}`);
      args.push(`page[number]=${this.currentPage}`);
      args.push(`q=${this.query || ''}`);

      try {
        const response = await investigationAPI.get(
          `/prosecutors/search?` + args.join('&'),
        );
        if (response && response.data && response.data.data) {
          // Update metadata for the pager
          if (response.data.meta) {
            this.serverTotalResults = response.data.meta.totalResults;
          }

          // First add all the found Prosecutors to the Vuex State
          const prosecutors = response.data.data;
          const newState = modelsToState('prosecutor', prosecutors);
          this.$store.commit('investigation/setState', newState);

          // Then update the resultIDs with the IDs of those prosecutors
          this.resultIDs = _.map(response.data.data, 'id');
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    AddProsecutorDialog,
    EditProsecutorDialog,
  },
});
