








































import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';

interface FinePayment {
  id: number;
  date: string;
  receiptNumber: string;
  amountInPuls: number;
  notes: string;
}

export default Vue.extend({
  props: {
    partyID: Number,
  },
  data() {
    return {
      offenderRecords: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters('housing', ['facilityName']),
  },
  methods: {
    async fetchData(): Promise<void> {
      const offenderResponse = await offenderAPI.get(
        `/offenders/for-party/${this.partyID}`,
      );
      this.offenderRecords = offenderResponse.data.data;
    },
  },
});
