import _ from 'lodash';
import JWT from 'jwt-decode';
import api from '@/api';
import router from '@/router';

const JWT_KEYNAME = 'cmsauth';

import { RoleEntityType, User, UserPermissions } from '@/vuex/user/user';

export interface Credentials {
  username: string;
  password: string;
}

export interface LoginResponse {
  user: User | null;
  token: string | null;
}

export interface AuthState {
  user: User | null;
  token: string | null;
  tokenExpiresAt: Date | null;
  error: string | null;
}

const state = {
  token: localStorage.getItem(JWT_KEYNAME),
  afterLoginPath: '/',
  tokenExpiresAt: null,
  timer: null,
  user: null,
  error: null,
};

const getters = {
  isAuthenticated(state): boolean {
    return !!state.token;
  },

  /**
   * hasPermission is the universal getter to do client-side permissions checks,
   * such as when hiding a particular menu item if a user does not have
   * the rights to access it. Usage examples:
   *
   * hasPermission('menu.admin')
   * hasPermission('offender.transfer.request', 'facility', 834)
   * hasPermission('offender.transfer.complete', 'facility', 345)
   *
   */
  hasPermission:
    (state, getters, rootState, rootGetters) =>
    (
      permissionKey: number | string,
      entityType: string,
      entityID: number,
    ): boolean => {
      // Get the currently logged-in user
      const user = state.user;

      // If no user exists, then we immediately report no access
      if (!user) {
        return false;
      }

      // Translate what might be a string or integer reference to the permission
      // into a number to match the database storage of permissionIDs.
      const permID = _.isNumber(permissionKey)
        ? permissionKey
        : rootState.user.permissionIDsByKey[permissionKey];
      if (!permID) {
        console.error(`Unknown permission: ${permissionKey}`);
      }

      // Get the permissions for the current user
      const userPerms: UserPermissions =
        rootGetters['user/permissionsForUser'](user);
      const allUserPermissionIDs = _.chain(userPerms)
        .values()
        .flatten()
        .uniq()
        .value();

      const validIDs = [permID, '*'];

      // Special case for entity-less permission checks.
      // If the hasPermission question was asked without an entityType or entityID
      // then we only care if the referenced permissionID (or a '*') appears in
      // any of the UserRoles.
      if (!entityType && !entityID) {
        const intersection = _.intersection(allUserPermissionIDs, validIDs);
        return intersection.length > 0;
      }

      // keysToCheck will contain an array of the permission keys that are
      // relevant to this request. If we're checking for a permission with
      // entityType = 'department', and entityID = 34, then the following keys
      // should be checked:
      // 'department:34'
      // 'department:*'
      //
      const keysToCheck: string[] = [];
      keysToCheck.push(`global:*`);
      keysToCheck.push(`${entityType}:${entityID}`);
      keysToCheck.push(`${entityType}:*`);

      for (const key of keysToCheck) {
        const keyPermissionIDs: any[] = [userPerms[key]];
        const intersection = _.intersection(
          _.flatten(keyPermissionIDs),
          validIDs,
        );
        if (intersection.length > 0) {
          return true;
        }
      }

      return false;
    },

  /**
   * entityIDsWithPermission returns the the IDs of Courts, Departments or
   * Facilities at which the currently logged-in user has the provided
   * permission.
   */
  entityIDsWithPermission:
    (state, getters, rootState, rootGetters) =>
    (permissionKey: number | string): Array<number | string> => {
      // Get the currently logged-in user
      const user = state.user;
      if (!user) {
        return [];
      }
      return rootGetters['user/entityIDsWithPermissionForUser'](
        user,
        permissionKey,
      );
    },

  /**
   * allowedCourtIDs calculates which Courts the current user has
   * access to. Most of the time, it returns an array of numbers, indicating
   * the Court ID to which the user has access. Some users have access to
   * all Courts, which is indicated by a '*' instead of a numeric ID.
   */
  allowedCourtIDs(
    state,
    getters,
    rootState,
    rootGetters,
  ): Array<string | number> {
    return rootGetters['user/allowedEntitiesForUser'](
      state.user,
      RoleEntityType.Court,
    );
  },

  /**
   * allowedDepartmentIDs calculates which Departments the current user has
   * access to. Most of the time, it returns an array of numbers, indicating
   * the Department ID to which the user has access. Some users have access to
   * all Departments, which is indicated by a '*' instead of a numeric ID.
   */
  allowedDepartmentIDs(
    state,
    getters,
    rootState,
    rootGetters,
  ): Array<string | number> {
    return rootGetters['user/allowedEntitiesForUser'](
      state.user,
      RoleEntityType.Department,
    );
  },

  /**
   * allowedFacilityIDs calculates which Housing Facilities the current user has
   * access to. Most of the time, it returns an array of numbers, indicating
   * the Facility ID to which the user has access. Some users have access to
   * all Facilities, which is indicated by a '*' instead of a numeric ID.
   */
  allowedFacilityIDs(
    state,
    getters,
    rootState,
    rootGetters,
  ): Array<string | number> {
    return rootGetters['user/allowedEntitiesForUser'](
      state.user,
      RoleEntityType.HousingFacility,
    );
  },

  /**
   * hasAllFacilities returns true if any of the currently logged-in user's
   * roles has been assigned to all Housing Facilities.
   */
  hasAllFacilities(state, getters): boolean {
    return _.includes(getters.allowedFacilityIDs, '*');
  },
};

const mutations = {
  setToken(state, token: string | null) {
    if (token === null || token === '') {
      state.token = null;
      state.user = null;
      state.tokenExpiresAt = null;
      localStorage.removeItem(JWT_KEYNAME);
    } else {
      localStorage.setItem(JWT_KEYNAME, token);
      state.token = token;
      interface Claims {
        exp: number;
      }
      const t = JWT<Claims>(token);
      state.tokenExpiresAt = new Date(0);
      state.tokenExpiresAt.setUTCSeconds(t.exp);
    }
  },
  setProfile(state, user: User | null) {
    state.user = user;
  },
  setError(state, error: string | null) {
    state.error = error;
  },
  setAfterLoginPath(state, path: string) {
    state.afterLoginPath = path;
  },
};

const actions = {
  /**
   * autoLogin is called via the router navigation guard whenever the page first
   * starts loading.
   *
   * It checks for a JWT in local storage, and if found,
   * begins to treat the user as if they are logged in
   * and fetches their current profile. Failure at that point
   * will end up sending the user back to the login screen
   * because of a globally-accessible API interceptor configured
   * in the axios initializer.
   */
  async autoLogin({ state, commit, dispatch }): Promise<void> {
    if (state.user) {
      // Early exit in case we're already logged in. Since autoLogin gets called
      // in navigation guards (before every route change) we expect it will be
      // called many times when the user is already authenticated.
      return;
    }

    commit('setError', null);
    const token = localStorage.getItem(JWT_KEYNAME);
    if (token) {
      commit('setToken', token);
      await dispatch('fetchProfile');
      dispatch('startTokenRefreshTimer');
    }
  },

  /**
   * login attempts to authenticate the user with the server
   * and obtain a JSON Web Token (JWT) for future API requests
   */
  async login({ state, commit, dispatch }, creds: Credentials): Promise<void> {
    commit('setError', null);
    try {
      const response = await api.post('/authservice/login', { data: creds });
      const token: string = response.data.data;
      commit('setToken', token);
      await dispatch('fetchProfile');
      dispatch('startTokenRefreshTimer');
      router.push(state.afterLoginPath || { name: 'home' });
    } catch (error) {
      commit('setToken', null);
      commit('setError', error);
    }
  },

  /**
   * logout triggers the deletion of locally-stored credentials
   * and returns the user to the login screen
   */
  logout({ commit }): void {
    commit('setToken', null);
    if (router.currentRoute.path !== '/login') {
      router.push('/login');
    }
  },

  /**
   * fetchProfile retrieves the currently logged-in user's profile
   * data from the server
   */
  async fetchProfile({ commit }): Promise<void> {
    try {
      const response = await api.get('/authservice/whoami');
      commit('setProfile', response.data.data);
    } catch (error) {
      commit('setToken', null);
      commit('setProfile', null);
    }
  },

  /**
   * startTokenRefreshTimer starts a JS timeout to renew the current
   * token before it expires. Acts as a no-op if there is no token.
   */
  startTokenRefreshTimer({ dispatch, state }): void {
    if (!state.tokenExpiresAt) {
      return;
    }
    const refresher = () => dispatch('refreshToken');
    const expiresInMillis =
      state.tokenExpiresAt.getTime() - new Date().getTime();
    if (state.timer) {
      clearTimeout(state.timer);
    }
    if (expiresInMillis < 5_000) {
      state.timer = setTimeout(refresher, 3_000);
    } else if (expiresInMillis < 60_000) {
      state.timer = setTimeout(refresher, expiresInMillis * 0.6);
    } else {
      state.timer = setTimeout(refresher, expiresInMillis * 0.9);
    }
  },

  /**
   * refreshToken asks the server for an updated JWT with a new expiration
   * date based on the token we already have.
   */
  async refreshToken({ commit, dispatch, state }): Promise<void> {
    try {
      const response = await api.post('/authservice/refresh');
      const token: string = response.data.data;
      commit('setToken', token);
      dispatch('startTokenRefreshTimer');
    } catch (error) {
      if (state.tokenExpiresAt && state.tokenExpiresAt < new Date()) {
        // Clear the existing token if it is expired
        commit('setToken', null);
        commit('setAfterLoginPath', router.currentRoute.path);
      } else {
        dispatch('startTokenRefreshTimer');
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
