















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { VSelectItem } from '@/lib/vue-typescript';
import { Role } from '@/vuex/user/user';

export default Vue.extend({
  props: {
    label: String,
    value: [Number, Array],
    rules: Array,
    disabled: Boolean,
    multiple: Boolean,
    chips: Boolean,
    clearable: Boolean,

    // entityType can be specified with one of the role entity types (e.g.
    // 'global', 'court', 'department', 'facility') to limit the Roles to
    // only those that are categorized under that type.
    entityType: String,
  },
  computed: {
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('user', ['allRoles']),
    ...mapState('user', ['roles']),

    /**
     * roleChoices computes the filtered list of options which appear for the
     * user to chose from the dropdown control.
     */
    roleChoices(): VSelectItem[] {
      let roles: Role[] = this.roles;
      if (this.entityType) {
        roles = _.filter(roles, { entityType: this.entityType });
      }
      return _.map(roles, (r) => ({
        value: r.id,
        text: this.valueForLocale(r.name),
      }));
    },
  },
});
