















































































import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Program } from '@/vuex/offender/program';
import { LookupItem } from '@/vuex/offender/options';

import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: ['offenderProgramID'],
  data() {
    return {
      isOpen: false,

      endTypes: ['terminated', 'dropped-out'],
      endType: 'terminated',
      endedAt: moment().format(),
      notes: '',
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('offender', ['courseWithID', 'offenderProgramWithID']),

    /**
     * program pulls an individual Program for a single Offender from the Vuex
     * store.
     */
    program(): Program | null {
      return this.offenderProgramWithID(this.offenderProgramID) || null;
    },

    /**
     * course retrieves the LookupItem for the course on this program, so that
     * we can display its type and title in the form.
     */
    course(): LookupItem | null {
      if (!this.program) {
        return null;
      }
      return this.courseWithID(this.program.courseID);
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset() {
      this.endedAt = moment().format();
      this.notes = '';
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to trigger the API call to persist the entity to the database.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('programs/end', {
          data: {
            programID: this.offenderProgramID,
            endedAt: this.endedAt,
            endType: this.endType,
            notes: this.notes,
          },
        });
        const newState = response.data.data;
        this.$store.commit('offender/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    OffenderNameAndPIN,
  },
});
