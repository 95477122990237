









































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  created() {
    this.fetchData();
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('language', ['locale']),
    ...mapGetters('corpus', ['corpora']),
  },
  methods: {
    ...mapActions('corpus', ['fetchCorpora']),
    async fetchData(): Promise<void> {
      this.loading = true;
      await this.fetchCorpora(false);
      this.loading = false;
    },
  },
});
