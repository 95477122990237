

































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { PunishmentType } from '@/vuex/crime/crime';
import { SentenceComponent } from '@/vuex/court/participant';

import PunishmentTypeSelector from '@/components/crime/punishmentType/PunishmentTypeSelector.vue';
import SubjectPropertySelector from '@/components/subjectProperty/SubjectPropertySelector.vue';

export default Vue.extend({
  props: {
    value: Object,
    dossierID: Number,
    punishmentTypeID: Number,
    isValid: Boolean,
    minFine: Number,
    maxFine: Number,
    minDuration: Object,
    maxDuration: Object,
    readonly: Boolean,
  },
  data() {
    return {
      isOpen: false,
      isChecked: false,

      durationInHours: 0,
      amountInPuls: 0,
      notes: '',
      subjectPropertyIDs: [] as number[],
      isConsequential: false,
    };
  },
  computed: {
    ...mapState('crime', ['punishmentTypes', 'punishmentTypeCategories']),
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('crime', ['punishmentTypeWithID']),
    ...mapGetters('court', ['subjectPropertyName']),

    /**
     * punishmentType retrieves the complete PunishmentType object for the
     * currently-active punishment in this dialog.
     */
    punishmentType(): PunishmentType {
      return this.punishmentTypeWithID(this.punishmentTypeID);
    },

    /**
     * categoryID retrieves the categoryID of whatever punishmentType this
     * sentence component is set to. It's needed so that the sentence component
     * can identify from which category the sentence component came from in reports.
     */
    categoryID(): number {
      if (!this.punishmentType) {
        return 0;
      }
      return this.punishmentType.categoryID;
    },

    /**
     * requiresCustomization returns true if the selected punishment type
     * requires the user to complete the data entry form. If this returns false,
     * checking the box will not open a dialog.
     */
    requiresCustomization(): boolean {
      if (this.readonly) {
        return false;
      }

      const pt = this.punishmentType;
      if (!pt) {
        return false;
      }
      if (pt.canHaveAmount || pt.requiresAmount) {
        return true;
      }
      if (pt.canHaveDuration || pt.requiresDuration) {
        return true;
      }
      if (pt.requiresNotes) {
        return true;
      }
      if (pt.canReferenceSubjectProperty) {
        return true;
      }
      return false;
    },

    /**
     * showFineRange returns true if this punishment type has a range of
     * valid fine amounts, and it makes sense to show it.
     */
    showFineRange(): boolean {
      if (!this.fineRange) {
        return false;
      }
      return this.fineOutOfRange || !this.amountInPuls;
    },

    /**
     * fineRange returns a string of the range of fines which are valid for
     * this punishment type, or a blank string if it doesn't apply.
     */
    fineRange(): string {
      if (this.punishmentTypeID !== 1) {
        return '';
      }
      let range = '';
      if (this.minFine || this.maxFine) {
        range = new Intl.NumberFormat().format((this.minFine || 0) / 100);
      }
      if (this.maxFine) {
        range =
          range + ' — ' + new Intl.NumberFormat().format(this.maxFine / 100);
      } else if (this.minFine) {
        range = range + '+';
      }
      return range;
    },

    /**
     * fineOutOfRange returns true if the entered fine amount is too high
     * or too low.
     */
    fineOutOfRange(): boolean {
      if (this.punishmentTypeID !== 1) {
        return false;
      }
      if (
        this.minFine &&
        this.amountInPuls &&
        this.amountInPuls < this.minFine
      ) {
        return true;
      }
      if (this.maxFine && this.amountInPuls > this.maxFine) {
        return true;
      }
      return false;
    },

    /**
     * showDurationRange returns true if this punishment type has a range of
     * valid imprisonment durations and it makes sense to show it.
     */
    showDurationRange(): boolean {
      if (!this.durationRange) {
        return false;
      }
      return this.durationOutOfRange || !this.durationInHours;
    },

    /**
     * durationRange builds a string of the valid range of imprisonment
     * lengths, or a blank string if it doesn't apply.
     */
    durationRange(): string {
      let range = '';
      const pt = this.punishmentType;
      if (!pt) {
        return range;
      }
      if (!pt.isImprisonment) {
        return range;
      }
      if (!this.isValid) {
        return range;
      }
      if (this.minDuration) {
        range = this.minDuration.name[this.locale] + ' — ';
      }
      if (this.maxDuration) {
        range = range + this.maxDuration.name[this.locale];
      }
      return range;
    },

    /**
     * durationOutOfRange returns true if the entered imprisonment duration is
     * too short or too long
     */
    durationOutOfRange(): boolean {
      const pt = this.punishmentType;
      if (!pt) {
        return false;
      }
      if (!pt.isImprisonment) {
        return false;
      }
      if (!this.isValid) {
        return false;
      }
      if (
        this.minDuration &&
        this.durationInHours &&
        this.durationInHours < this.minDuration.minimumHours
      ) {
        return true;
      }
      if (
        this.maxDuration &&
        this.durationInHours &&
        this.durationInHours > this.maxDuration.maximumHours
      ) {
        return true;
      }
      return false;
    },

    /**
     * checkColor returns the color of the checkbox
     */
    checkColor(): string {
      if (this.isChecked) {
        if (!this.isValid) {
          return 'red';
        }
        return 'green';
      }
      if (!this.isValid) {
        return 'grey';
      }
      return '';
    },

    /**
     * isError returns true if there is any error at all on this punishment
     * type. It's used to color the message text red.
     */
    isError(): boolean {
      return (
        this.durationOutOfRange ||
        this.fineOutOfRange ||
        (this.isChecked && !this.isValid)
      );
    },
  },

  watch: {
    /**
     * value is watched so that we can update our internal variables when
     * the externally data-bound value is changed.
     */
    value: {
      immediate: true,
      handler(component: SentenceComponent): void {
        if (component) {
          this.durationInHours = this.value.durationInHours;
          this.amountInPuls = this.value.amountInPuls;
          this.notes = this.value.notes;
          this.subjectPropertyIDs = [...this.value.subjectPropertyIDs];
          this.isConsequential = this.value.isConsequential;
          this.isChecked = true;
        } else {
          this.isChecked = false;
          this.reset();
        }
      },
    },

    /**
     * isChecked is watched so that we can do some processing to see if we
     * need to open the customization dialog.
     *
     * We check first that customization is actually needed, and if not, we
     * simulate a loop through opening and clicking save.
     *
     * If the user un-checked the box, then this manifests as an $emit to
     * v-model with a null value, effectively removing this SentenceComponent.
     */
    isChecked(nowChecked: boolean): void {
      if (nowChecked) {
        if (this.requiresCustomization) {
          this.isOpen = true;
        } else {
          this.reset();
          this.save();
        }
      } else {
        this.$emit('input', null);
      }
    },
  },

  methods: {
    /**
     * cancel is our event handler for CMSFormDialog's cancel event. It fires
     * when the dialog is dismissed without saving. We use it to discard the
     * object being edited.
     */
    cancel(): void {
      this.isChecked = false;
      this.durationInHours = 0;
      this.amountInPuls = 0;
      this.subjectPropertyIDs = [];
      this.notes = '';
      this.isConsequential = false;
      this.$emit('input', null);
    },

    /**
     * reset is our event handler for CMSFormDialog's reset event. It fires when
     * the dialog is activated. We use it to create a dialog-local copy of the
     * entity being edited, or to create a new entity from scratch if none
     * has been created yet.
     */
    reset(): void {
      this.durationInHours = 0;
      this.amountInPuls = 0;
      this.notes = '';
      this.subjectPropertyIDs = [];
      this.isConsequential = false;
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We  use it to emit the
     * complete SentenceComponent object which was created by the dialog.
     */
    async save(): Promise<void> {
      const externalCopy = {
        punishmentTypeID: this.punishmentTypeID,
        categoryID: this.categoryID,
        durationInHours: this.durationInHours,
        amountInPuls: this.amountInPuls,
        notes: this.notes,
        subjectPropertyIDs: [...this.subjectPropertyIDs],
        isConsequential: this.isConsequential,
      };

      // Dismiss the dialog
      this.isOpen = false;
      this.$emit('input', externalCopy);
    },
  },
  components: {
    PunishmentTypeSelector,
    SubjectPropertySelector,
  },
});
