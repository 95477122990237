
























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Participant } from '@/vuex/court/participant';

import AddParticipantDialog from '@/components/court/participant/AddParticipantDialog.vue';
import ParticipantForm from '@/components/court/participant/ParticipantForm.vue';

export default Vue.extend({
  props: {
    value: {
      type: Array,
      default: () => [] as Participant[],
    },
    caseType: String,
    courtStageID: Number,
  },

  computed: {
    ...mapState('court', ['partyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * participants is the array of objects being operated upon in the form.
     * It is built from the value prop.
     */
    participants(): Participant[] {
      // The forceful type assertion is necessary here because this.value
      // is an unknown[].
      return (this.value ? this.value : []) as Participant[];
    },

    /**
     * partyIDs computes the unique list of partyIDs that are presently in the
     * form. Needed to feed to the AddParticipantDialog so that it can warn
     * before a duplicate party is added.
     */
    partyIDs(): number[] {
      return _.chain(this.participants || [])
        .map('partyID')
        .uniq()
        .compact()
        .value();
    },

    /**
     * participantColumnWidth computes how many columns wide each participant
     * card is on medium and larger screens.
     */
    participantColumnWidth(): number {
      if (this.participants.length === 1) {
        return 12;
      }
      if (this.caseType === 'criminal') {
        return 12;
      }
      return 6;
    },
  },

  methods: {
    /**
     * addParticipant is the event handler for the AddParticipantDialog being
     * submitted. We must re-emit a new array when an item is added to preserve
     * Vue reactivity.
     */
    addParticipant(p: Participant): void {
      const newItems = [...this.participants, p];
      this.$emit('input', newItems);
    },

    /**
     * removeParticipant is the event handler for the delete button being clicked
     * on one of the participants. We remove via .splice and re-emit to preserve
     * Vue reactivity.
     */
    removeParticipant(i: number): void {
      const newItems = [...this.participants];
      newItems.splice(i, 1);
      this.$emit('input', newItems);
    },
  },

  components: {
    ParticipantForm,
    AddParticipantDialog,
  },
});
