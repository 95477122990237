








































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { AllCourtStageIDs } from '@/vuex/court/court';
import { Case } from '@/vuex/court/case';
import { Dossier } from '@/vuex/court/dossier';

import CaseHeaderNav from '@/components/court/case/CaseHeaderNav.vue';
import CaseSummaryCard from '@/components/court/case/CaseSummaryCard.vue';
import DossierCourts from '@/views/dossier/DossierCourts.vue';
import PrimaryParticipants from '@/components/court/participant/PrimaryParticipants.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['caseWithID', 'casesForDossier', 'dossierWithID']),
    loading(): boolean {
      // The page is considered loading until both the Case and Dossier are
      // fetched
      return !this.kase || !this.dossier;
    },
    kase(): Case {
      return this.caseWithID(this.id);
    },
    dossier(): Dossier | null {
      if (!this.kase) {
        return null;
      }
      return this.dossierWithID(this.kase.dossierID);
    },
    cases(): Case[] {
      if (!this.dossier) {
        return [];
      }
      return this.casesForDossier(this.dossier.id) || ([] as Case[]);
    },
    stageCases(): any {
      return AllCourtStageIDs.map((stageID) => ({
        stageID,
        cases: this.cases.filter((c) => c.stageID === stageID) || [],
      }));
    },
  },
  components: {
    CaseHeaderNav,
    CaseSummaryCard,
    DossierCourts,
    PrimaryParticipants,
  },
});
