





























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Court } from '@/vuex/court/court';

import CourtAddDialog from '@/components/court/court/CourtAddDialog.vue';
import CourtEditDialog from '@/components/court/court/CourtEditDialog.vue';
import CourtContainer from '@/components/court/court/CourtContainer.vue';

export default Vue.extend({
  data() {
    return {
      loading: true,
      search: '',
      grouped: true,
    };
  },

  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('language', ['locale']),
    ...mapState('court', ['courtStage', 'courtStages']),
    ...mapGetters('geography', ['geographyWithID']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['allCourts']),
    canAdd(): boolean {
      return this.hasPermission('court.create');
    },
    headers(): any[] {
      const h = [
        {
          text: this.$i18n.t('court.name'),
          value: 'name',
          width: '20%',
        },
        {
          text: this.$i18n.t('court.stage.singular'),
          value: 'stages',
          width: '10%',
        },
        {
          text: this.$i18n.t('dashboard.singular'),
          value: 'dashboard',
          width: '15%',
        },
        {
          text: this.$i18n.t('court.courtroom.plural'),
          value: 'courtrooms',
          width: '15%',
        },
      ] as any[];
      if (this.canAdd) {
        h.unshift({
          text: this.$i18n.t('command.edit'),
          value: 'id',
          width: '5%',
          sortable: false,
        });
      }
      return h;
    },
    translatedCourts(): Court[] {
      const courts = this.allCourts;
      return courts.map((court) => {
        const c = Object.assign({}, court);
        c.name = c.name[this.locale];
        c.stages = _.map(c.stageIDs, (id) => {
          return this.nameForIDInCollection(id, this.courtStages);
        }).join(', ');
        c.dashboard = { name: 'courtDashboard', query: { courtID: c.id } };
        c.courtrooms = { name: 'courtrooms', params: { id: c.id } };
        const geo = this.geographyWithID(c.locationGeographyID);
        if (geo) {
          c.location = geo.name[this.locale];
        }
        return c;
      });
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      Promise.all([
        this.$store.dispatch('court/fetchCourts'),
        this.$store.dispatch('geography/fetchGeographies'),
      ]).then(() => {
        this.loading = false;
      });
    },
    edit(courtID): void {
      (this.$refs.editDialog as any).edit(courtID);
    },
  },
  components: {
    CourtAddDialog,
    CourtEditDialog,
    CourtContainer,
  },
});
