










import _ from 'lodash';
import Vue from 'vue';

import CrimeAdminMenu from '@/components/crime/CrimeAdminMenu.vue';
import CrimeSearcher from '@/components/crime/CrimeSearcher.vue';

export default Vue.extend({
  components: {
    CrimeAdminMenu,
    CrimeSearcher,
  },
});
