














import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Judge } from '@/vuex/court/judge';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['judgeWithID', 'judgeName']),
    payload(): any {
      return this.entry.payload;
    },
    judge(): Judge | null {
      return this.judgeWithID(this.payload.judgeID);
    },
  },
});
