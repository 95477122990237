








































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import offenderAPI from '@/vuex/offender/offenderAPI';

import { Offender } from '@/vuex/offender/offender';
import { Profile } from '@/vuex/profile/profile';
import { VSelectItem } from '@/lib/vue-typescript';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';
import { AdjustmentReasonID } from '@/vuex/offender/options';
import { SentenceAdjustmentReasonID } from '@/vuex/offender/sentenceAdjustment';

const enum Direction {
  Subtract = -1,
  Set = 0,
  Add = 1,
}

export default Vue.extend({
  props: {
    offenderID: Number,
  },

  data() {
    return {
      isOpen: false,
      dossierID: 0,
      reasonID: AdjustmentReasonID.DetentionExtension,
      pardonDecreeID: 0,
      adjustmentDirection: 1,
      adjustmentAmountInHours: 0,
      filedAt: moment().format(),
      notes: '',
      error: null as any,
    };
  },

  computed: {
    ...mapGetters('court', ['dossiersForParty']),
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('investigation', ['pardonDecreeWithID']),

    /**
     * offender returns the full Offender from the offenderID prop provided to
     * this component.
     */
    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    /**
     * profile returns the full Profile from the offender.profileID attribute
     */
    profile(): Profile {
      return this.profileWithID(this.offender.profileID);
    },

    /**
     * partyID returns the PartyID for the current Offender, by way of their
     * attached Profile.
     */
    partyID(): number {
      if (this.profile) {
        return this.profile.partyID;
      }
      return 0;
    },

    /**
     * disallowedReasonIDs restricts the choices which the user is allowed
     * to choose from the full offender/sentenceAdjustmentReasons lookup
     * table
     */
    disallowedReasonIDs(): number[] {
      return [
        SentenceAdjustmentReasonID.Unknown,
        SentenceAdjustmentReasonID.Intake,
      ];
    },

    /**
     * directionChoices builds the list of choices for the Sentence Adjustment
     * direction dropdown control.
     */
    directionChoices(): VSelectItem[] {
      return [
        { value: -1, text: i18n.t('sentenceAdjustment.subtract') },
        { value: 0, text: i18n.t('sentenceAdjustment.set') },
        { value: 1, text: i18n.t('sentenceAdjustment.add') },
      ];
    },

    /**
     * pardonDecreeChoices builds an array of dropdown selection choices to
     * allow the user to select which Pardon Decree prompted this Sentence
     * Adjustment. Only Pardon Decrees which have been granted to this
     * Offender are valid choices.
     */
    pardonDecreeChoices(): VSelectItem[] {
      return _.map(this.offender.pardonDecreeIDs || [], (id) => {
        const pd = this.pardonDecreeWithID(id);
        return {
          value: id,
          text: `${pd.number} - ${pd.title}`,
        };
      });
    },

    /**
     * dossierChoices builds an array of dropdown selection choices to
     * allow the user to select which Dossier prompted this Sentence Adjustment.
     * Only Dossiers Which involve this offender as a Party are valid, and the
     * currently-linked Dossier is removed from the selection list.
     */
    dossierChoices(): VSelectItem[] {
      if (!this.partyID) {
        return [];
      }
      const dossiers = this.dossiersForParty(this.partyID) || [];
      return _.chain(dossiers)
        .reject((d) => d.id === this.offender.dossierID)
        .map((d) => {
          return { value: d.id, text: d.number };
        })
        .value();
    },

    /**
     * needsAssociatedPardonDecree returns true when the user needs to select
     * the Pardon Decree to which this SentenceAdjustment relates.
     */
    needsAssociatedPardonDecree(): boolean {
      return this.reasonID === AdjustmentReasonID.PardonDecree;
    },

    /**
     * needsAssociatedDossier returns true when the user needs to select a
     * Dossier to associate with this sentence adjustment.
     */
    needsAssociatedDossier(): boolean {
      return this.reasonID === AdjustmentReasonID.ConvictionOfAnotherCrime;
    },
  },
  watch: {
    reasonID(newVal: number) {
      switch (newVal) {
        case AdjustmentReasonID.PardonDecree:
          this.adjustmentDirection = Direction.Subtract;
          break;
        case AdjustmentReasonID.DetentionExtension:
          this.adjustmentDirection = Direction.Add;
          break;
        case AdjustmentReasonID.Intake:
          this.adjustmentDirection = Direction.Set;
          break;
        case AdjustmentReasonID.CourtDecision:
          this.adjustmentDirection = Direction.Set;
          break;
        case AdjustmentReasonID.ClericalError:
          this.adjustmentDirection = Direction.Set;
          break;
        case AdjustmentReasonID.PostponedEnforcement:
          this.adjustmentDirection = Direction.Add;
          break;
      }
    },
    adjustmentDirection(newDirection: number) {
      switch (newDirection) {
        case Direction.Subtract:
          this.adjustmentAmountInHours = 0;
          break;
        case Direction.Add:
          this.adjustmentAmountInHours = 0;
          break;
        case Direction.Set:
          this.adjustmentAmountInHours =
            this.offender.imprisonmentDurationInHours;
          break;
      }
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state and to
     * load all relevant data needed for dropdowns.
     */
    async reset(): Promise<void> {
      await this.$store.dispatch('court/fetchDossiersForParty', this.partyID);

      if (this.offender) {
        this.dossierID = this.offender.dossierID;
      }

      this.reasonID = AdjustmentReasonID.DetentionExtension;
      this.adjustmentDirection = 1;
      this.adjustmentAmountInHours = 0;
      this.filedAt = moment().format();
      this.notes = '';
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('adjust-sentence', {
          data: {
            offenderID: this.offenderID,
            dossierID: this.dossierID,
            reasonID: this.reasonID,
            pardonDecreeID: this.pardonDecreeID,
            adjustmentDirection: this.adjustmentDirection,
            adjustmentAmountInHours: this.adjustmentAmountInHours,
            notes: this.notes,
            filedAt: this.filedAt,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.sentenceAdjustment)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'sentenceAdjustmentIDsForOffender',
          index: this.offenderID,
          value: newID,
        });

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
