


























































































import _ from 'lodash';
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';

import { Corpus } from '@/vuex/corpus/corpus';
import { Crime } from '@/vuex/crime/crime';

import EditCrimeDialog from '@/components/crime/crime/EditCrimeDialog.vue';
import NewCrimeDialog from '@/components/crime/crime/NewCrimeDialog.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  created() {
    this.fetchData();
  },

  data() {
    return {
      waiting: true,
      corpus: null as Corpus | null,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapState('corpus', ['corporaByID']),
    ...mapGetters('corpus', ['corporaWithParentID']),
    ...mapGetters('crime', ['crimesWithCorpusID']),
    ...mapGetters('auth', ['hasPermission']),

    canAdd(): boolean {
      return this.hasPermission('admin.corpora.create');
    },

    canAddCrime(): boolean {
      return this.hasPermission('admin.crimeType.create');
    },

    children(): Corpus[] {
      return _.sortBy(this.corporaWithParentID(this.id), 'number');
    },

    crimes(): Crime[] {
      if (this.corpus == null) {
        return [];
      }
      return _.sortBy(this.crimesWithCorpusID(this.id), 'article');
    },

    canHaveChildren(): boolean {
      return this.crimes.length === 0 || this.children.length > 0;
    },

    canHaveCrimes(): boolean {
      return this.children.length === 0 || this.crimes.length > 0;
    },

    breadcrumbs(): any[] {
      const items = [] as any[];

      // Exit early with empty breadcrumbs if we don't have a Corpus
      if (this.corpus === null) {
        return items;
      }

      // Begin adding breadcrumbs from right (last) to left (first).
      // We add the current corpus in a disabled state.
      if (this.corpus.title) {
        items.unshift({
          text: this.corpus.title[this.locale],
          disabled: true,
        });
      }

      // Then loop until we get a null parentID (indicating a root
      // corpus).
      let parentID = this.corpus.parentID;
      while (parentID !== null) {
        const parent = this.corporaByID[parentID];
        if (parent) {
          items.unshift({
            text: parent.title[this.locale],
            to: {
              name: 'corpusShow',
              params: { id: parent.id },
            },
          });
          parentID = parent.parentID;
        }
      }
      items.unshift({
        text: this.$i18n.t('corpus.plural').toString(),
        to: { name: 'corpusList' },
      });
      return items;
    },
  },

  watch: {
    id(newValue) {
      this.fetchData();
    },
  },

  methods: {
    ...mapActions('corpus', ['fetchCorpus']),

    async fetchData(): Promise<void> {
      if (this.id) {
        this.waiting = true;
        await this.fetchCorpus(this.id);
        this.waiting = false;
        this.corpus = this.corporaByID[this.id];
      }
    },
  },

  components: {
    EditCrimeDialog,
    NewCrimeDialog,
  },
});
