import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface FinePayment {
  id: number;
  dossierID: number;
  partyID: number;
  paidAt: string;
  amountInPuls: number;
  receiptNumber: string;
  notes: string;
}

export const state = {
  finePayment: {} as { [id: number]: FinePayment },
  finePaymentIDsForDossier: {} as { [id: number]: number[] },
};

export const getters = {
  finePaymentsForDossier:
    (state) =>
    (dossierID: number): FinePayment[] => {
      let payments = state.finePaymentIDsForDossier[dossierID] || [];
      payments = payments.map(
        (id: number) => state.finePayment[id],
      ) as FinePayment[];
      return payments;
    },
  finePaymentsForDossierPartyWithID:
    (state) =>
    (dossierID: number, partyID: number): FinePayment[] => {
      let payments = state.finePaymentIDsForDossier[dossierID] || [];
      payments = payments.map(
        (id: number) => state.finePayment[id],
      ) as FinePayment[];
      payments = payments.filter((p) => p.partyID === partyID);
      return payments;
    },
};

export const mutations = {};

export const actions = {
  async fetchFinePaymentsForDossier(
    { commit },
    payload: { dossierID: number },
  ) {
    const response = await courtAPI.get(
      `dossiers/${payload.dossierID}/fine-payments`,
    );
    const finePayments = response.data.data;
    const newState = modelsToState('finePayment', finePayments);
    commit('setState', newState);
    commit('setTarget', {
      target: 'finePaymentIDsForDossier',
      index: payload.dossierID,
      value: _.map(finePayments, 'id'),
    });
  },
};
