







































































































import _, { camelCase, cloneDeep, includes, set, tap } from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { CustomField } from '@/vuex/court/options';
import { CustomFieldTypes } from '@/vuex/court/customFieldType';

import AssessmentAmountSelector from '@/components/court/fee/AssessmentAmountSelector.vue';
import PartyTypeSelector from '@/components/dossier/PartyTypeSelector.vue';

export default Vue.extend({
  props: {
    value: Object,
    disableRename: Boolean,
    disableTypeChange: Boolean,
    alreadyUsedNames: Array,
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableNameOptions: [
        'court/attorneyTypes',
        'court/caseTypes',
        'court/cancellationReasons',
        'court/civilCaseTopics',
        'court/civilObjectionTypes',
        'court/criminalObjectionTypes',
        'court/dispositionTypes',
        'court/feeCategories',
        'court/hearingTypes',
        'court/lawsuitTypes',
        'offender/releaseReasons',
      ],
    };
  },

  computed: {
    ...mapGetters('language', ['languages']),

    /**
     * customField computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    customField(): CustomField {
      const v = this.value
        ? this.value
        : {
            name: '',
            label: { en: '', fa: '', ps: '' },
            type: 'TextField',
            typeArgs: {},
            isRequired: false,
          };
      return v;
    },

    customFieldEnglishLabel(): string {
      return this.customField.label.en;
    },

    fieldTypes(): string[] {
      return _.map(CustomFieldTypes, 'name');
    },
  },

  watch: {
    customFieldEnglishLabel(newVal, oldVal): void {
      if (this.editing) {
        return; // Don't update the field name on subsequent edit
      }

      if (this.customField.name === '') {
        this.customField.name = camelCase(newVal);
      }
      if (camelCase(oldVal) === this.customField.name) {
        this.customField.name = camelCase(newVal);
      }
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.customField), (v) => set(v, key, modifiedValue)),
      );
    },

    isUnique(value): boolean {
      // Need this in order to have ths nice error text on the field
      const isRepeated = includes(this.alreadyUsedNames, value);
      return !isRepeated;
    },

    isAlphaNumeric(value): boolean {
      return /^\w+$/g.test(value); // regex for alphanumeric (no spaces)
    },
  },

  components: {
    AssessmentAmountSelector,
    PartyTypeSelector,
  },
});
