






































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import housingAPI from '@/vuex/housing/housingAPI';
import { HousingFacility } from '@/vuex/housing/housing';

import AddFacilityChildrenDialog from '@/components/offender/facility/AddFacilityChildrenDialog.vue';
import EditHousingFacilityDialog from '@/components/offender/facility/EditHousingFacilityDialog.vue';

export default Vue.extend({
  name: 'HousingFacilityContainer',

  props: {
    facilityID: Number,
    search: String,
    depth: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('language', ['locale']),
    ...mapGetters('housing', ['facilityWithID', 'facilitiesUnder']),

    facility(): HousingFacility {
      return this.facilityWithID(this.facilityID);
    },

    facilities(): HousingFacility[] {
      const facilities = this.facilitiesUnder(this.facilityID);
      return _.reject(facilities, { id: 0 });
    },

    translatedFacilities(): any[] {
      return _.chain(this.facilities)
        .map((f) => {
          return {
            ...f,
            translatedName: f.name[this.locale],
          };
        })
        .orderBy(['translatedName'])
        .value();
    },

    /**
     * isPaginated controls whether the data-iterator has pagination controls
     * enabled. We always paginated the top level (level 0), and paginate
     * lower levels when there is an excessive number of entries.
     */
    isPaginated(): boolean {
      if (this.depth === 0) {
        return true;
      }
      return this.translatedFacilities.length > 50;
    },
  },

  watch: {
    facilityID: {
      immediate: true,
      handler(newID: number) {
        this.fetchChildren();
      },
    },
  },

  methods: {
    /**
     * hasPermissionInPath() splits a facility's path (for e.g. 119.814.3870)
     * and checks each result against hasPermission(). This is used to determine
     * permissions to facilities that the user has "parent-permission" to.
     */
    hasPermissionInPath(
      permissionKey: string,
      entityType: string,
      path: string,
    ): boolean {
      const pathIDs = path.split('.').map(Number) as number[];

      for (const pathID of pathIDs) {
        if (this.hasPermission(permissionKey, entityType, pathID)) {
          return true;
        }
      }

      return false;
    },

    fetchChildren() {
      this.$store.dispatch('housing/fetchFacilityChildren', {
        force: false,
        parentID: this.facilityID,
      });
    },

    async hideFacility(facilityID: number): Promise<void> {
      const resp = await housingAPI.post('/facilities/hide', {
        data: { housingFacilityID: facilityID },
      });

      const newState = _.get(resp, 'data.data');
      this.$store.commit('housing/setState', newState);
    },

    async unHideFacility(facilityID: number): Promise<void> {
      const resp = await housingAPI.post('/facilities/unhide', {
        data: { housingFacilityID: facilityID },
      });

      const newState = _.get(resp, 'data.data');
      this.$store.commit('housing/setState', newState);
    },

    canAddChildren(f: HousingFacility): boolean {
      if (!f) {
        return false;
      }

      if (f.hidden || f.type === 'cell') {
        return false;
      }

      return this.hasPermissionInPath('facility.create', 'facility', f.path);
    },
  },

  components: {
    AddFacilityChildrenDialog,
    EditHousingFacilityDialog,
  },
});
