




import Vue from 'vue';

export default Vue.extend({
  props: {
    name: String,
    status: String,
  },
  computed: {
    nameStyle(): string {
      if (this.status) {
        return `${this.status}--text`;
      } else {
        return '';
      }
    },
  },
});
