import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';

export interface Case {
  id: number;
  number: string;
  courtID: number;
  headOfCourtJudgeID: number;
  dossierID: number;
  filedAt: string;
  registeredAt: string;
  stageID: number;
  type: string;
  disposedAt: string;
  isFinal?: boolean;
  finalizedAt: string;
  statusID: number;
  activeAt: string;
  dispositionReasonID: number;
}

export const enum CaseStatus {
  Unknown = 0,
  Filed = 1,
  Active = 2,
  Inactive = 3,
  Suspended = 4,
  Reopened = 5,
  Disposed = 6,
}

export const state = {
  casesLoaded: false,
  caseIDsForDossier: {} as { [caseID: number]: number[] },
  case: {} as { [id: number]: Case },
};

export const getters = {
  caseWithID:
    (state) =>
    (caseID: number): Case => {
      return state.case[caseID];
    },
  casesForDossier:
    (state) =>
    (dossierID: number): Case[] => {
      return (state.caseIDsForDossier[dossierID] || []).map(
        (id: number) => state.case[id],
      ) as Case[];
    },
};
export const mutations = {
  setCasesLoaded(state, val: boolean) {
    state.casesLoaded = val;
  },
};

export const actions = {
  async fetchCase({ commit, dispatch }, id: number) {
    let response = await courtAPI.get(`cases/${id}`);
    const newState = response.data.data;

    commit('setState', newState);

    commit('createIndex', {
      target: 'participantIDsForCase',
      data: newState.participant,
      indexKey: 'caseID',
    });

    if (newState.civilDecision) {
      commit('createIndex', {
        target: 'civilDecisionIDForCase',
        data: newState.civilDecision,
        indexKey: 'caseID',
      });
    }

    response = await dispatch('fetchCourtroomsForCourt', {
      courtID: newState.case[id].courtID,
      force: false,
    });
    response = await dispatch('fetchHearings', { caseID: id });
  },
};
