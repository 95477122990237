




import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Attachment } from '@/vuex/attachment/attachment';

export default Vue.extend({
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityID: Number,
    location: {
      type: String,
      required: true,
    },
    width: [String, Number],
    placeholder: String,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {};
  },
  watch: {
    entityID() {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters('attachment', ['attachmentsForEntity']),
    src(): string {
      if (this.currentAttachment) {
        return this.currentAttachment.uri || '';
      }
      return this.placeholder;
    },
    currentAttachment(): Attachment | null {
      if (this.attachments.length > 0) {
        return this.attachments[0];
      }
      return null;
    },
    attachments(): Attachment[] {
      const attachments =
        this.attachmentsForEntity(
          this.entityType,
          this.entityID,
          this.location,
        ) || [];
      return _.sortBy(attachments, 'uploadedAt').reverse();
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      await this.$store.dispatch('attachment/fetchAttachments', {
        entityType: this.entityType,
        entityID: this.entityID,
        location: this.location,
      });
    },
  },
});
