













import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';
import { Court } from '@/vuex/court/court';

import CourtForm from '@/components/court/court/CourtForm.vue';

export default Vue.extend({
  data() {
    return {
      court: null as Court | null,
      isOpen: false,
      error: null as any,
    };
  },
  methods: {
    reset(): void {
      this.court = null;
    },
    async save(): Promise<void> {
      if (this.court == null) {
        return;
      }
      this.error = null;
      try {
        const response = await courtAPI.post('courts/create', {
          data: {
            name: this.court.name,
            stageIDs: this.court.stageIDs,
            locationGeographyID: this.court.locationGeographyID,
            parentID: this.court.parentID,
            rank: this.court.rank,
            headOfCourtJudgeID: this.court.headOfCourtJudgeID,
            legacyDepartmentIDs: this.court.legacyDepartmentIDs,
            maxCaseDurationInDays: this.court.maxCaseDurationInDays,
            warnAfterDays: this.court.warnAfterDays,
          },
        });

        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/prependToTarget', {
          target: 'courtIDs',
          value: Object.keys(newState.court),
        });
        this.$store.commit('court/calculateCourtChildStats');
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    CourtForm,
  },
});
