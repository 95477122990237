export const InvestigationDecisionTypes = {
  57: {
    en: 'Case Referral to other Competent Organ',
    fa: 'ارجاع دوسیه به ارگان ذیصلاح دیگر',
    ps: 'ارجاع دوسیه به ارگان ذیصلاح دیگر',
  },
  58: {
    en: 'Case Dismissal',
    fa: 'حفظ دوسیه',
    ps: 'حفظ دوسیه',
  },
  59: {
    en: 'Charge Sheet',
    fa: 'اتهام نامه',
    ps: 'اتهام نامه',
  },
  60: {
    en: 'Detention Cancellation Decision',
    fa: 'قرار لغوی توقیفی',
    ps: 'قرار لغوی توقیفی',
  },
  62: {
    en: 'Unknown',
    fa: 'نامعلوم',
    ps: 'نامعلوم',
  },
  121: {
    en: 'Indictment',
    fa: 'صورت دعوی',
    ps: 'صورت دعوی',
  },
  122: {
    en: "Objection to the Court's Decision",
    fa: 'اعتراض بر فیصله محکمه',
    ps: 'اعتراض بر فیصله محکمه',
  },
  123: {
    en: 'Appealing',
    fa: 'استیناف خواهی',
    ps: 'استیناف خواهی',
  },
  124: {
    en: 'Request for Appeal',
    fa: 'تمیز طلبی',
    ps: 'تمیز طلبی',
  },
  125: {
    en: 'Satisfaction',
    fa: 'قناعت',
    ps: 'قناعت',
  },
  126: {
    en: 'Dissatisfaction',
    fa: 'عدم قناعت',
    ps: 'عدم قناعت',
  },
  253: {
    en: 'Order on Stopping Order Enforcement',
    fa: 'قرار مبنی بر متوقف شدن تنفیذ حکم',
    ps: 'قرار مبنی بر متوقف شدن تنفیذ حکم',
  },
  356: {
    en: 'Order on Lack of Necessity to File a Criminal Lawsuit',
    fa: 'قرار عدم لزوم اقامه دعوی جزایی',
    ps: 'قرار عدم لزوم اقامه دعوی جزایی',
  },
  357: {
    en: 'Order on Delaying Investigation',
    fa: 'قرار مبنی بر تعویق تحقیق',
    ps: 'قرار مبنی بر تعویق تحقیق',
  },
  479: {
    en: 'Order on Referring Case to the Detection Organ',
    fa: 'قرار احاله به مرجع کشفی',
    ps: 'قرار احاله به مرجع کشفی',
  },
  480: {
    en: 'Order on Elimination of Gaps and Discrepancies',
    fa: 'قرار مبنی بر رفع خلا و ن واقص',
    ps: 'قرار مبنی بر رفع خلا و نواقص',
  },
  481: {
    en: 'Case Referral to Huqooq Department',
    fa: 'ارجاع دوسیه به حقوق',
    ps: 'ارجاع دوسیه به حقوق',
  },
  482: {
    en: 'Order on Lack of Authority to Handle the Case',
    fa: 'قرار مبنی بر عدم صلاحیت رسیدگی',
    ps: 'قرار مبنی بر عدم صلاحیت رسیدگی',
  },
  483: {
    en: 'Analysis of Order',
    fa: 'حل یا تحلیل قرار',
    ps: 'حل یا تحلیل قرار',
  },
  485: {
    en: 'Order on Completion of Detention Duration',
    fa: 'قرار مبنی بر ختم توقیفی',
    ps: 'قرار مبنی بر ختم توقیفی',
  },
  489: {
    en: 'Order on Lack of Necessity to File a Lawsuit',
    fa: 'قرار مبنی بر عدم لزوم اقامه دعوی',
    ps: 'قرار مبنی بر عدم لزوم اقامه دعوی',
  },
  578: {
    en: 'Under work',
    fa: 'تحت کار',
    ps: 'د کار لاندې',
  },
  723: {
    en: 'Investigation order',
    fa: 'قرار تعقیبی',
    ps: 'د څېړنې قرار',
  },
  724: {
    en: 'Instructive order (Referral of Documents)',
    fa: 'قرار رهنمودی (احاله اوراق)',
    ps: 'لارښود قرار (د پاڼو احاله)',
  },
  813: {
    en: 'Arrest Warrant',
    fa: 'قرار دستگیری',
    ps: 'قرار دستگیری',
  },
  814: {
    en: 'Invitation Warrant',
    fa: 'قرار جلب و احضار',
    ps: 'قرار جلب و احضار',
  },
  830: {
    en: 'Ruling on monitoring of lower courts’ decisions',
    fa: 'قرار نظارتی بر فیصله های محاکم تحتانی',
    ps: 'قرار نظارتی بر فیصله های محاکم تحتانی',
  },
  831: {
    en: 'Ruling to monitor the enforcement of the court’s final verdict',
    fa: 'قرار نظارتی بر تطبیق حکم نهایی',
    ps: 'د نهائی حکم د تطبیق نظارتی قرار',
  },
  832: {
    en: 'Ruling on objection based on appeal',
    fa: 'قرار مبنی بر اعتراض استینافی',
    ps: 'د استینافی اعتراض په اساس قرار',
  },
  833: {
    en: 'Ruling on conflict of authority',
    fa: 'قرار مبنی بر تنازع صلاحیت',
    ps: 'دصلاحیت د تنازع پر اساس قرار',
  },
  834: {
    en: 'Lack of verdict enforcement',
    fa: 'عدم تطبیق حکم',
    ps: 'د حکم نه تطبیق',
  },
  835: {
    en: 'Rejecting by official letter',
    fa: 'مکتوبی مسترد',
    ps: 'مکتوبی مسترد ول ',
  },
  836: {
    en: 'Proceeding of personal right matter',
    fa: 'رسیدگی به موضوع حق العبدی',
    ps: 'حق العبدی موضوع ته رسیدگی کول',
  },
  837: {
    en: 'Revocation of decision and send for new decision',
    fa: 'ارسال جهت فیصله مجدد',
    ps: 'مجددی فیصلی ته لیژل',
  },
  838: {
    en: 'Refer to similar court',
    fa: 'ارسال به محکمه مماثل',
    ps: 'مماثل محکمی ته لیژل',
  },
  839: {
    en: 'Ruling on dismissal of criminal disputes',
    fa: 'قرار مبنی بر سقوط دعوی جزائی',
    ps: 'د جزائی دعوا د سقوط پر اساس قرار',
  },
  840: {
    en: 'Order to investigate',
    fa: 'قرار تحقیقی',
    ps: 'تحقیقی قرار',
  },
};

export const FateOfAccused = {
  470: {
    en: 'Custody',
    fa: 'نظارت',
    ps: 'نظارت',
  },
  471: {
    en: 'Detention',
    fa: 'توقیف',
    ps: 'توقیف',
  },
  472: {
    en: 'Release on Guarantee',
    fa: 'به ضمانت رها',
    ps: 'به ضمانت رها',
  },
  473: {
    en: 'Release',
    fa: 'رها',
    ps: 'رها',
  },
  474: {
    en: 'Guarantee by Money',
    fa: 'کفالت باالمال',
    ps: 'کفالت باالمال',
  },
  475: {
    en: 'Segment Guarantee',
    fa: 'ضمانت قطعه',
    ps: 'ضمانت قطعه',
  },
  476: {
    en: 'Escape',
    fa: 'فرار',
    ps: 'فرار',
  },
  477: {
    en: 'Under Arrest',
    fa: 'تحت جلب',
    ps: 'تحت جلب',
  },
  478: {
    en: 'Under Detection',
    fa: 'تحت کشف',
    ps: 'تحت کشف',
  },
  484: {
    en: 'Under the imprisonment',
    fa: 'تحت حبس',
    ps: 'تحت حبس',
  },
  490: {
    en: 'Confined',
    fa: 'تحت حجز',
    ps: 'په حجز کې',
  },
  491: {
    en: 'Imprison in Military Unit',
    fa: 'حبس در قطعه',
    ps: 'حبس په قطعه کې',
  },
  492: {
    en: 'Confined in Home',
    fa: 'حجز در منزل',
    ps: 'په کور کې تر حجز لاندې',
  },
  493: {
    en: 'Confined in Close Centre',
    fa: 'حجز در محل بسته',
    ps: 'حجز په بند ځای کې',
  },
  495: {
    en: 'Suspended Imprisonment',
    fa: 'حبس در حال تعلیق',
    ps: 'حبس د ځنډولو په حال کې',
  },
  496: {
    en: 'Confined in Open Centre',
    fa: 'حجز در مرکز باز',
    ps: 'په خلاص مرکز کی تر حجز لاندی',
  },
  841: {
    en: 'Alternative imprisonment',
    fa: 'بدیل حبس',
    ps: 'دحبس بدیل',
  },
  855: {
    en: 'Passed away',
    fa: 'متوفی',
    ps: 'مړ شوی',
  },
};

export const CourtDecisionTypes = {
  '592': {
    en: 'Confiscation of Property',
    fa: 'مصادره اموال',
    ps: 'مصادره اموال',
  },
  '593': { en: 'Seizure', fa: 'ضبط', ps: 'ضبط' },
  '594': { en: 'Alternative to Imprisonment', fa: 'بدیل حبس', ps: 'بدیل حبس' },
  '595': {
    en: 'Alternative to Detention/ Confinement',
    fa: 'بدیل حجز',
    ps: 'بدیل حجز',
  },
  '596': { en: 'Execution', fa: 'اعدام', ps: 'اعدام' },
  '597': { en: 'Cash Fine', fa: 'جزای نقدی', ps: 'جزای نقدی' },
  '598': { en: 'Imprisonment', fa: 'حبس', ps: 'حبس' },
  '599': { en: 'Confinement, Detention', fa: 'حجز', ps: 'حجز' },
  '614': {
    en: 'Discharged from any Obligation',
    fa: 'بری الذمه',
    ps: 'بری الذمه',
  },
  '631': {
    en: 'Compensation',
    fa: 'تاوان و جبران خساره',
    ps: 'تاوان و جبران خساره',
  },
  '632': { en: 'Judicial Order', fa: 'قرار قضایی', ps: 'قرار قضایی' },
  '649': { en: 'Life Imprisonment', fa: 'حبس ابد', ps: 'د ژوند حبس' },
  '802': {
    en: 'Submission to Parents',
    fa: 'تسلیم به والدین',
    ps: 'والدینوته سپارل',
  },
  '803': { en: 'Suspended Imprisonment', fa: 'حبس تعلیقی', ps: 'تعلیقی حبس' },
  '808': {
    en: 'Imprisonment at Military Base',
    fa: 'حبس قطعه',
    ps: 'په قطعه کی حبس',
  },
  '842': {
    en: 'Annulment of the Court verdict',
    fa: 'ابطال حکم',
    ps: 'دحکم ابطال',
  },
  '846': {
    en: 'Suspended Criminal Fine',
    fa: 'جزای نقدی تعلیقی',
    ps: 'جزای نقدی تعلیقی',
  },
  '851': { en: 'Blood Money', fa: 'خون بها / دیت', ps: 'خون بها / دیت' },
  '856': { en: 'Rejection By Letter', fa: 'مسترد مکتوبی', ps: 'مسترد مکتوبی' },
};
