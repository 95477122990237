












































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';

import { LookupItem } from '@/vuex/offender/options';

export default Vue.extend({
  props: {
    /**
     * cellData expects an array of objects with precisely 3 attributes:
     *
     * - a "col" attribute indicating which column the data belongs in
     * - a "row" attribute indicating which row the data belongs in
     * - a "value" attribute indicating the data that will be placed at that X/Y position
     *
     * This component assumes the above attribute names will be used, but
     * they can be overriden. Example:
     *
     * [
     * 	{ thingID: 1, statusID: 2, count: 10 },
     * 	{ thingID: 1, statusID: 4, count 3 }
     * ]
     *
     * We have a matrix of counts of Things in each Status. To have Status rows
     * and Thing columns, we would use:
     *
     * <LookupTableMatrix
     * 	:cell-data="data"
     * 	:row-data="things"
     *  :col-data="statuses"
     *  :row-attr="thingID"
     *  :col-attr="statusID"
     *  :value-attr="count" />
     *
     */
    cellData: {
      type: Array,
      required: true,
    },

    /**
     * rowData expects an array of LookupItems (i.e. an entire lookup table).
     * These values are used to label the column headers and align the cell
     * values with the correct header.
     */
    rowData: {
      type: Array,
      required: true,
    },

    /**
     * rowAttr can be used to override the attribute name for rows.
     */
    rowAttr: {
      type: String,
      default: 'row',
    },

    /**
     * colData expects an array of LookupItems (i.e. an entire lookup table).
     * These values are used to label the row headers and align the cell
     * values with the correct header.
     */
    colData: {
      type: Array,
      required: true,
    },

    /**
     * colAttr can be used to override the attribute name for columns.
     */
    colAttr: {
      type: String,
      default: 'col',
    },

    /**
     * valueAttr can be used to override the attribute name for the cell values.
     */
    valueAttr: {
      type: String,
      default: 'value',
    },

    /**
     * excludeUnknownRow can force an "Unknown" row (one with a 0 id) to be
     * excluded. The Unknown row is excluded by default if it contains no data.
     */
    excludeUnknownRow: Boolean,

    /**
     * excludeUnknownCol can force an "Unknown" column (one with a 0 id) to be
     * excluded. The Unknown column is excluded by default if it contains no
     * data.
     */
    excludeUnknownCol: Boolean,

    /**
     * totalCol triggers include of a Total column
     */
    totalCol: Boolean,

    /**
     * totalRow triggers inclusion of a Total row
     */
    totalRow: Boolean,
  },

  computed: {
    ...mapGetters('language', ['valueForLocale']),

    /**
     * items reshapes the provided cellData from "long" format into "wide" format
     */
    items(): any[] {
      const rows: any[] = [];
      for (const rowType of this.rowTypes) {
        const row = { label: this.valueForLocale(rowType.name), rowTotal: 0 };
        for (let colIndex = 0; colIndex < this.colTypes.length; colIndex++) {
          const colType = this.colTypes[colIndex] as LookupItem;
          const relevantCells = _.filter(this.cellData, {
            [this.colAttr]: colType.id,
            [this.rowAttr]: rowType.id,
          });
          const cellValue = _.sumBy(relevantCells, this.valueAttr);
          row[`col${colIndex}`] = cellValue;
          row.rowTotal = row.rowTotal + cellValue;
        }

        // Even if the user didn't explicityly exclude the Unknown row,
        // we exclude it automatically if that row would contain 0's in
        // every column
        if (rowType.id === 0 && row.rowTotal === 0) {
          continue;
        }
        rows.push(row);
      }

      return rows;
    },

    /**
     * colTotals builds a lookup table containing the totals for each column
     * in the data set, including the rowTotal column.
     */
    colTotals(): { [col: string]: number } {
      const totals = { rowTotal: 0 };
      for (const row of this.items) {
        for (let colIndex = 0; colIndex < this.colTypes.length; colIndex++) {
          const attr = `col${colIndex}`;
          totals[attr] = (totals[attr] || 0) + row[attr];
          totals.rowTotal = (totals.rowTotal || 0) + row[attr];
        }
      }
      return totals;
    },

    /**
     * rowTypes computes the array of LookupItems which define the rows.
     * This function will exclude unknown items (those with ID=0) if requested
     * by the user, and will sort by the rank of the LookupItem.
     */
    rowTypes(): LookupItem[] {
      let types = (this.rowData || []) as LookupItem[];
      if (this.excludeUnknownRow) {
        types = _.reject(types, { id: 0 });
      }
      types = _.sortBy(types, (t) => t.rank || 999999);
      return types;
    },

    /**
     * colTypes computes the array of LookupItems which define the columns.
     * This function will exclude unknown items (those with ID=0) if requested
     * by the user, and will sort by the rank of the LookupItem.
     */
    colTypes(): LookupItem[] {
      let types = (this.colData || []) as LookupItem[];
      if (this.excludeUnknownCol) {
        types = _.reject(types, { id: 0 });
      }
      types = _.sortBy(types, (t) => t.rank || 999999);
      return types;
    },

    /**
     * headers computes the headers attribute for the VDataTable from the
     * colTypes, which is in turn defined by the colData prop which expects
     * an array of LookupItems for the column types.
     */
    headers(): any[] {
      const h: any[] = [];

      // First column is the label column, which gets blank text in the
      // header.
      h.push({ text: '', value: 'label' });

      // Second column is the total column, which is optional
      if (this.totalCol) {
        h.push({
          text: i18n.t('dashboard.total'),
          value: 'rowTotal',
          align: 'right',
        });
      }

      for (let colIndex = 0; colIndex < this.colTypes.length; colIndex++) {
        const colType = this.colTypes[colIndex] as LookupItem;
        const attr = `col${colIndex}`;

        // Even if the user didn't explicityly exclude the Unknown column,
        // we exclude it automatically if that column would contain 0's in
        // every row
        if (colType.id === 0 && this.colTotals[attr] === 0) {
          continue;
        }

        h.push({
          text: this.valueForLocale(colType.name),
          value: attr,
          align: 'right',
        });
      }

      return h;
    },
  },
});
