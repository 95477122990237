



















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { DocketEntry } from '@/vuex/court/docket';

import NewDocketEntryMenu from './NewDocketEntryMenu.vue';
import DocketEventTable from '@/components/docket/DocketEventTable.vue';

export default Vue.extend({
  props: {
    caseID: Number,
    dossierID: Number,
    moduleOnly: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  created(): void {
    this.fetchData();
  },
  watch: {
    caseID(val: number): void {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters('court', ['docketEventsForCase']),
    ...mapState('court', ['docketEventTypes', 'hearingTypes']),
    events(): DocketEntry[] {
      return this.docketEventsForCase(this.caseID);
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('court/fetchDocketEntriesForCase', {
        caseID: this.caseID,
        force: false,
      });
      this.loading = false;
    },
  },
  components: {
    DocketEventTable,
    NewDocketEntryMenu,
  },
});
