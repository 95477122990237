


















import _ from 'lodash';
import Vue from 'vue';

import crimeAPI from '@/vuex/crime/crimeAPI';
import { Crime } from '@/vuex/crime/crime';

import CrimeForm from '@/components/crime/crime/CrimeForm.vue';

export default Vue.extend({
  props: {
    corpusID: Number,
  },

  data() {
    return {
      isOpen: false,
      error: null as any,

      crime: null as Crime | null,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.crime = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.crime) {
        return;
      }

      try {
        // API call and response
        const res = await crimeAPI.post('/crimes/create', {
          data: this.crime,
        });

        // Add the Crime to our local Vuex state
        const newState = _.get(res, 'data.data', {});
        this.$store.commit('crime/setState', newState);
        this.$store.commit('crime/appendToTarget', {
          target: 'crimeIDs',
          value: Object.keys(newState.crime),
        });

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    CrimeForm,
  },
});
