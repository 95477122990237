


























import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Profile } from '@/vuex/profile/profile';
import { Offender } from '@/vuex/offender/offender';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('profile', ['profileWithID']),
    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },
    profile(): Profile {
      return this.profileWithID(this.offender.profileID);
    },
  },
});
