var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.containers.length)?_c('v-expansion-panels',{staticClass:"mt-4",attrs:{"multiple":""}},_vm._l((_vm.containers),function(department){return _c('v-expansion-panel',{key:department.id},[_c('v-expansion-panel-header',{attrs:{"color":"grey lighten-4"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.hasPermission('admin.department.create'))?_c('EditDepartmentDialog',{attrs:{"id":department.id}}):_vm._e(),_c('span',{staticClass:"text--disabled px-2"},[_vm._v(_vm._s(department.code))]),_vm._v(" "+_vm._s(department.name[_vm.locale])+" ")],1),_c('v-col',{staticClass:"text-center text--disabled",attrs:{"cols":"6"}},[_vm._v(" ( "+_vm._s(_vm.departmentChildCount(department.id))+" Child Departments ) ")])],1)],1),_c('v-expansion-panel-content',[_c('DepartmentContainer',{attrs:{"parentID":department.id}})],1)],1)}),1):_vm._e(),_c('v-row',{attrs:{"justify":"space-around"}},[(_vm.searchable)?_c('v-col',{attrs:{"cols":"12","sm":"5","md":"4"}},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1):_vm._e(),(_vm.selected.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('CMSFormDialog',{attrs:{"title":"Move Departments"},on:{"reset":function($event){_vm.newParentID = _vm.parentID},"save":_vm.move},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"accent"}},on),[_vm._v(" Move "+_vm._s(_vm.selected.length)+" Departments ")])]}}],null,false,3122552193),model:{value:(_vm.isMoveOpen),callback:function ($$v) {_vm.isMoveOpen=$$v},expression:"isMoveOpen"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DepartmentSelector',{attrs:{"rules":[
                    function (v) { return !!v || _vm.$t('error.required'); },
                    function (v) { return !_vm.selectedIDs.includes(_vm.newParentID) ||
                      _vm.$t('error.invalidChoice'); } ],"zero-label":"none"},model:{value:(_vm.newParentID),callback:function ($$v) {_vm.newParentID=$$v},expression:"newParentID"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('CMSFormDialog',{attrs:{"title":"Delete Departments","color":"error"},on:{"reset":function($event){_vm.replacementID = _vm.parentID},"save":_vm.replace},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"error"}},on),[_vm._v(" Delete (Merge) "+_vm._s(_vm.selected.length)+" Departments ")])]}}],null,false,3749167382),model:{value:(_vm.isReplaceOpen),callback:function ($$v) {_vm.isReplaceOpen=$$v},expression:"isReplaceOpen"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Deleting: "),_vm._l((_vm.selected),function(department){return _c('div',{key:department.id},[_vm._v(" "+_vm._s(department.name[_vm.locale])+" ")])})],2),_c('v-col',{attrs:{"cols":"12"}},[_c('DepartmentSelector',{attrs:{"label":"Migrate Associated Data to Replacement Department","rules":[
                    function (v) { return !!v || _vm.$t('error.required'); },
                    function (v) { return !_vm.selectedIDs.includes(_vm.replacementID) ||
                      _vm.$t('error.invalidChoice'); } ],"zero-label":"none"},model:{value:(_vm.replacementID),callback:function ($$v) {_vm.replacementID=$$v},expression:"replacementID"}})],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(_vm.uncontainedDepartments.length)?_c('v-data-table',{attrs:{"show-select":"","items":_vm.uncontainedDepartments,"headers":_vm.headers,"search":_vm.search,"page":_vm.page,"hide-default-footer":!_vm.paginated,"disable-sorting":"","disable-pagination":!_vm.paginated,"footer-props":{
          itemsPerPageText: _vm.$t('uiElements.rowsPerPage'),
        }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('td',[(_vm.hasPermission('admin.department.create'))?_c('EditDepartmentDialog',{attrs:{"id":item.id}}):_c('div',[_vm._v(_vm._s(item.id))])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }