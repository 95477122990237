




















































































import _ from 'lodash';
import moment from 'moment-timezone';
import Vue from 'vue';
import { mapState } from 'vuex';

import { toLocalizedDate, toLocalizedMonthName } from '@/lib/dates';

const TZPart = '+04:30';

export default Vue.extend({
  props: {
    /**
     * value is our v-model data-bound prop. It stores an ISO8601 Date or
     * DateTime, always in Gregorian date format. All Jalaali dates are computed
     * from this value on the fly.
     */
    value: String,
    label: String,
    hint: String,
    disabled: Boolean,
    readonly: Boolean,
    clearable: Boolean,
    rules: Array,
    max: String,
    pastDatesOnly: Boolean,
    yearFirst: Boolean,
    withTime: Boolean,
    minuteIncrement: {
      type: Number,
      default: 15,
    },
  },

  created(): void {
    /**
     * The delay here is required because of the bug documented here:
     * https://github.com/vuetifyjs/vuetify/issues/9129
     *
     * The combination of :close-on-click="true" (which closes the menu)
     * if the user clicks elsewhere on the page (a feature we want) interacts
     * with the @focus event such that the click is reported after the focus
     * has already triggered the menu to open. By delaying the open signal
     * for several milliseconds, we end up with both events triggering before
     * the dialog is opened.
     */
    this.open = _.debounce(this.open, 100);
  },

  data(): any {
    return {
      isOpen: false,
      activeTab: 'date',

      datePart: '',
      timePart: '',
    };
  },

  computed: {
    ...mapState('language', ['locale']),

    /**
     * normalizedMaxDate controls the interaction between the pastDatesOnly and
     * max props. If pastDatesOnly is true, then we ignore the max prop.
     * Otherwise, max is passed-through to the underlying v-date-picker.
     */
    normalizedMaxDate(): string | undefined {
      if (this.pastDatesOnly) {
        return moment.tz('Asia/Kabul').endOf('day').format('YYYY-MM-DD');
      }
      return this.max;
    },

    /**
     * valueForDisplay controls what value is displayed in the text box. We
     * always display the "bound" value, which is only modified after the user
     * clicks the OK/Save button on the dialog.
     */
    valueForDisplay(): string {
      return toLocalizedDate(this.value, this.withTime);
    },

    /*
     * combinedISO8601Value combines the two widget-bound internal values
     * (datePart and timePart) into a combined value in ISO8601 format (always
     * in Gregorian calendar format). If the withTime prop is false, this
     * function will omit the time portion.
     */
    combinedISO8601Value(): string {
      if (!this.datePart) {
        return '';
      }

      let val = this.datePart;
      if (this.withTime) {
        val += 'T' + this.timePart + ':00' + TZPart;
      }
      return val;
    },

    /*
     * allowedMinutes computes the value of the same name for the v-time-picker
     * from the minuteIncrement prop on this component. If not supplied, we
     * return undefined, which causes the time picker to show all minutes
     * as selectable.
     */
    allowedMinutes(): number[] | undefined {
      if (this.minuteIncrement === 1) {
        return undefined;
      }
      const allowed = [] as number[];
      for (let i = 0; i < 60; i += this.minuteIncrement) {
        allowed.push(i);
      }
      return allowed;
    },

    /**
     * initialPicker computes whether the picker should be shown for the year
     * initially, or a full date picker initially. When choosing someone's
     * birthdate, the year is the best initial picker to use.
     */
    initialPicker(): string {
      if (this.yearFirst) {
        return 'YEAR';
      } else {
        return 'DATE';
      }
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newValStr: string) {
        this.valueChangedOutside(newValStr);
      },
    },

    isOpen(nowOpen: boolean) {
      if (nowOpen) {
        this.activeTab = 'date';
        this.resetPickersToValue();
      }
    },

    withTime(newWithTime: boolean) {
      if (newWithTime && this.timePart === '') {
        // If we weren't tracking a time before, then we need to initialize a
        // default time when withTime is set. We arbitrarily chose noon.
        this.timePart = '12:00';
      }

      // Changing the withTime prop means our data-bound value should be
      // adjusted to either include or omit the time part, so we need to
      // trigger an emit.
      this.emitDate();
    },
  },

  methods: {
    toLocalizedMonthName,

    /**
     * open triggers the
     */
    open(on: any, $event: any): void {
      on.click($event);
    },

    /*
     * valueChangedOutside handles any scenario where the data-bound value
     * was changed outside this component. It is responsible for updating
     * internal representations. External data binding is accepted ONLY in
     * ISO8601 format.
     */
    valueChangedOutside(newVal: string): void {
      this.resetPickersToValue();

      // Special case handler: The process of resetting the internal values
      // itself resulted in the value being changed. This could happen when
      // the withTime prop and the external value don't match (e.g. time provided
      // when not desired, or vice versa), in which case the time part may have
      // been added or removed.
      const result = this.combinedISO8601Value;
      if (result && result !== newVal) {
        this.emitDate();
      }
    },

    /*
     * resetPickersToValue updates the internal datePart and timePart variables
     * (which the date-picker and time-picker bind with) to make sure they match
     * the current value for this component. This needs to be done anytime
     * the value is changed from outside, and anytime the menu is opened (to
     * reset any modifications the user may have made previously).
     */
    resetPickersToValue(): void {
      if (!this.value) {
        this.datePart = '';
        this.timePart = '';
        return;
      }
      const m = moment.tz(this.value, 'Asia/Kabul');
      this.datePart = m.format('YYYY-MM-DD');
      if (!this.withTime) {
        this.timePart = '';
      } else {
        if (this.value.length > 10) {
          this.timePart = m.format('HH:mm');
        } else {
          this.timePart = '12:00';
        }
      }
    },

    /*
     * emitDate notifies v-model consumers of this component that the value has
     * changed. We always emit the combined date + time value (time only
     * included when the withTime prop is true).
     */
    emitDate(): void {
      this.$emit('input', this.combinedISO8601Value);
    },

    /*
     * save is the handler for the OK button in the menu. We don't $emit the
     * newly selected values until the user clicks the OK button so that
     */
    save(): void {
      this.emitDate();
      this.close();
    },

    /*
     * dateSelected is the handler called when the user has selected a new date
     * on the date tab.
     */
    dateSelected() {
      if (this.withTime) {
        this.timePart = '12:00';
        this.activeTab = 'time';
        if (this.$refs.timePicker) {
          this.$refs.timePicker.selectingHour = true;
        }
      }
    },

    /**
     * close dismisses the dialog interface and resets it back to
     * its default values. This function is called immediately when
     * cancel is clicked and called eventually (after emitting) when
     * OK is clicked.
     */
    close() {
      this.isOpen = false;
    },
  },
});
