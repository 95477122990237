




































































































import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { HousingFacility } from '@/vuex/housing/housing';
import { Offender } from '@/vuex/offender/offender';
import { Profile } from '@/vuex/profile/profile';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data(): any {
    return {
      isOpen: false,

      estimatedDurationInDays: 0,
      scheduledDepartureAt: moment().format(),
      selectedDestination: 0,
      selectedReasonID: 0,
      notes: '',
      isDetainee: false,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('housing', ['facilityWithID']),
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('profile', ['profileWithID']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    profile(): Profile {
      return this.profileWithID(this.offender.profileID);
    },

    destinationFacility(): HousingFacility {
      return this.facilityWithID(this.selectedDestination);
    },

    destinationRules(): any[] {
      let originID = 0;
      const rules = Array();

      if (this.offender) {
        originID = this.offender.housingFacilityID;
      }

      rules.push(
        (v) => v !== 0 || this.$i18n.t('transfer.error.unknownDestination'),
      );
      rules.push(
        (v) => v !== originID || this.$i18n.t('transfer.error.sameDestination'),
      );

      if (this.isDetainee) {
        rules.push(
          () =>
            this.destinationFacility.canHoldDetainees ||
            this.$i18n.t('transfer.error.detaineesNotAllowed'),
        );
      }

      if (!this.isDetainee) {
        rules.push(
          () =>
            this.destinationFacility.canHoldPrisoners ||
            this.$i18n.t('transfer.error.prisonersNotAllowed'),
        );
      }

      if (this.profile.genderID === 1) {
        rules.push(
          () =>
            this.destinationFacility.canHoldMales ||
            this.$i18n.t('transfer.error.malesNotAllowed'),
        );
      }

      if (this.profile.genderID === 2) {
        rules.push(
          () =>
            this.destinationFacility.canHoldFemales ||
            this.$i18n.t('transfer.error.femalesNotAllowed'),
        );
      }

      if (this.offender.isJuvenile) {
        rules.push(
          () =>
            this.destinationFacility.canHoldJuveniles ||
            this.$i18n.t('transfer.error.juvenilesNotAllowed'),
        );
      }

      if (!this.offender.isJuvenile) {
        rules.push(
          () =>
            this.destinationFacility.canHoldAdults ||
            this.$i18n.t('transfer.error.adultsNotAllowed'),
        );
      }
      return rules;
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      if (this.offender) {
        this.isDetainee = this.offender.isDetainee;
      }
      this.estimatedDurationInDays = 0;
      this.selectedDestination = 0;
      this.selectedReasonID = 0;
      this.notes = '';
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('transfers/request', {
          data: {
            offenderID: this.offenderID,
            fromHousingFacilityID: this.offender.housingFacilityID || 0,
            toHousingFacilityID: this.selectedDestination,
            scheduledDepartureAt: new Date(this.scheduledDepartureAt),
            estimatedDurationInHours: this.estimatedDurationInDays * 24,
            reasonID: this.selectedReasonID,
            notes: this.notes || '',
            isDetainee: this.isDetainee,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.transfer)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'transferIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.transfer),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        // Close the Dialog
        this.isOpen = false;

        // Navigate to Housing in case the transfer was triggered from the
        // OffenderNavHeader area
        this.goToHousingPage();
      } catch (error) {
        this.error = error;
      }
    },

    /**
     * goToHousingPage does programmatic navigation to the referenced
     * offender's housing page (if we're not already there).
     */
    goToHousingPage(): void {
      this.$router
        .push({
          name: 'offenderHousing',
          params: { id: `${this.offenderID}` },
        })
        .catch(() => {
          // An error is thrown if we're already here
          // We can safely ignore it.
        });
    },
  },

  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
