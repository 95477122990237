



































































































































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import { Crime, LegacyCrime } from '@/vuex/crime/crime';
import CrimeSelector from '@/components/crime/CrimeSelector.vue';
import UserInitials from '@/components/user/UserInitials.vue';

export default Vue.extend({
  props: {
    value: Object,
  },

  data() {
    return {
      valid: false,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['languages']),
    ...mapGetters('crime', ['crimeWithID']),
    ...mapState('court', ['generalAggravators', 'generalMitigators']),
    ...mapState('crime', [
      'completionStatuses',
      'participationTypes',
      'severities',
    ]),
    ...mapState('auth', ['user']),

    /**
     * legacyCrime computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    legacyCrime(): LegacyCrime {
      return this.value
        ? this.value
        : {
            legacyID: 0,
            legacyName: {},
            legacyLawID: 0,
            legacyLawName: {},
            legacyCategoryID: 0,
            legacyCategoryName: {},
            crimeID: 0,
            participationTypeID: 0,
            completionStatusID: 0,
            severityID: 0,
            generalAggravatorIDs: [],
            generalMitigatorIDs: [],
            lastReviewedAt: null,
            lastReviewedByUserID: 0,
            lastApprovedAt: null,
            lastApprovedByUserID: 0,
            lastGiroaReviewAt: null,
            lastGiroaReviewByUserID: 0,
            lastGiroaApprovedAt: null,
            lastGiroaApprovedByUserID: 0,
          };
    },

    crimeID(): number {
      if (this.legacyCrime && this.legacyCrime.crimeID) {
        return this.legacyCrime.crimeID;
      }
      return 0;
    },

    /**
     * crime provides convenient access to the Crime entity (CMS2 Crime) to
     * which this legacy crime is currently pointed.
     */
    crime(): Crime {
      return this.crimeWithID(this.legacyCrime.crimeID);
    },
  },

  watch: {
    crimeID: {
      immediate: true,
      handler(newID: number) {
        if (newID) {
          // Get the text and other details.
          this.$store.dispatch('crime/fetchCrime', newID);
        }
      },
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any): void {
      this.$emit(
        'input',
        tap(cloneDeep(this.legacyCrime), (v) => set(v, key, value)),
      );
    },

    updateReviewCheckbox(prefix: string, value: boolean): void {
      const dateKey = `${prefix}At`;
      const userKey = `${prefix}ByUserID`;
      const newLegacyCrime: LegacyCrime = cloneDeep(this.legacyCrime);
      if (value) {
        newLegacyCrime[dateKey] = moment().format();
        newLegacyCrime[userKey] = this.user.id;
      } else {
        newLegacyCrime[dateKey] = null;
        newLegacyCrime[userKey] = 0;
      }
      this.$emit('input', newLegacyCrime);
    },
  },

  components: {
    CrimeSelector,
    UserInitials,
  },
});
