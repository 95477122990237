




import moment from 'moment';
import jmoment from 'moment-jalaali';
import Vue from 'vue';
import i18n from '@/i18n';

import { toLocalizedDate } from '@/lib/dates';

export default Vue.extend({
  props: {
    birthdate: String,
    isApproximate: Boolean,
  },
  computed: {
    display(): string {
      if (!this.birthdate) {
        return '';
      }
      if (this.isApproximate) {
        return this.yearDisplay;
      }
      return this.dateDisplay;
    },
    yearDisplay(): string {
      if (i18n.locale === 'en') {
        const m = moment(String(this.birthdate), 'YYYY-MM-DD');
        return m.format('YYYY');
      }
      const j = jmoment(String(this.birthdate), 'YYYY-MM-DD');
      return j.format('jYYYY');
    },

    dateDisplay(): string {
      return toLocalizedDate(this.birthdate);
    },
  },
});
