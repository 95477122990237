
























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import { criteriaToQueryString } from '@/lib/criteria';
import { Geography } from '@/vuex/geography/geography';

export default Vue.extend({
  props: {
    value: Boolean,
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      results: [],
    };
  },
  computed: {
    ...mapGetters('geography', ['geographyWithID', 'fullGeographyName']),

    geographies(): Geography[] {
      return _.chain(this.results || [])
        .map('locationID')
        .map(this.geographyWithID)
        .compact()
        .value();
    },

    /**
     * mostCommonType scans the list of facilities and returns the one that
     * occurs most frequently. This is used to create a summary title.
     */
    mostCommonGeographyType(): string {
      const types = _.map(this.geographies || [], 'type') as string[];
      const geo = _.chain(types)
        .countBy() // Produces { "facility": 14, "cell": 10 }
        .entries() // Converts to [["facility", 14], ["cell", 10]]
        .maxBy(_.last) // Filters to ["facility", 14]
        .head() // Retrieves first element ("facility")
        .value() as string; // Exit lodash chain and type-assert to string
      return geo || 'country';
    },

    /**
     * fullCriteria ensures that whatever criteria comes in, the activeOnly
     * attribute is always true. This widget only tracks active cases.
     */
    fullCriteria(): any {
      const criteria = Object.assign({}, this.criteria, {
        activeOnly: true,
      });
      return criteria;
    },

    /**
     * queryString computes the parameters to send to the API. This must
     * be a computed property so that it can be watched to trigger a new
     * API call.
     */
    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },

    headers(): any[] {
      return [
        { text: i18n.t('name'), value: 'name' },
        {
          text: i18n.t('case.activeCases'),
          align: 'right',
          value: 'activeCaseCount',
        },
        {
          text: i18n.t('case.averageAge'),
          align: 'right',
          value: 'averageAge',
        },
      ];
    },
  },
  watch: {
    /**
     * queryString is watched so that any change the API parameters will
     * trigger a new data fetch.
     */
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const url = `/cases/by-court-location?${this.queryString}`;
        const res = await courtAPI.get(url);
        this.results = _.get(res, 'data.data', []);
      } finally {
        this.loading = false;
      }
    },
  },
});
