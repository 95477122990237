






import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';

import i18n from '@/i18n';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },

  data() {
    return {
      loading: false,
      error: null as any,

      results: [] as any[],
    };
  },

  computed: {
    chartData(): any[] {
      const rows: any[] = [];
      rows.push([i18n.t('case.finalizedAt'), i18n.t('count')]);
      _.each(this.results, (row) => {
        rows.push([row.date, row.count]);
      });
      return rows;
    },

    chartOptions(): object {
      const dates = _.map(this.results, 'date');
      const hTickCount = Math.ceil(this.results.length / 5);

      return {
        title: i18n.t('dossier.finalizedByDate'),
        width: '100%',
        height: '100%',
        chartArea: { width: '90%' },
        legend: {
          position: 'none',
        },
        colors: ['#104721'],
        hAxis: {
          showTextEvery: hTickCount,
        },
      };
    },

    /**
     * fullCriteria builds the actual API criteria which will be sent. This is
     * done by combining the incoming criteria prop with customizations set
     * directly on this component.
     */
    fullCriteria(): any {
      return Object.assign({}, this.criteria, {
        activeOnly: false, // TODO Find a way to avoid needing to override this.
        finalizedBetween: `${moment().subtract(52, 'weeks').format()},`,
      });
    },

    /**
     * queryString builds the URL querystring which will be sent to the API
     * endpoint. It exists as a standalone computed prop so that the watcher
     * can trigger only when the query has changed.
     */
    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      try {
        const url = `/dossiers/finalized-by-date?${this.queryString}`;
        const response = await investigationAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
  },
});
