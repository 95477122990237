























































import Vue from 'vue';
import { HealthHistoryRecord } from '@/vuex/offender/medical';
import { Offender } from '@/vuex/offender/offender';
import { mapGetters } from 'vuex';

import AddHealthHistoryRecordDialog from '@/components/offender/medical/AddHealthHistoryRecordDialog.vue';

export default Vue.extend({
  props: {
    id: Number,
    contentOnly: Boolean,
  },

  created(): void {
    if (this.id) {
      this.fetchData();
    }
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', [
      'healthHistoryRecordsForOffender',
      'offenderIsReleased',
      'offenderWithID',
    ]),

    offender(): Offender {
      return this.offenderWithID(this.id);
    },

    healthHistoryRecords(): HealthHistoryRecord[] {
      return this.healthHistoryRecordsForOffender(this.id);
    },
    last(): number {
      return (
        Object.keys(this.healthHistoryRecordsForOffender(this.id)).length - 1
      );
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.id);
    },

    /**
     * canAdd returns true if its acceptable to add a new Sentence Adjustment
     */
    canAdd(): boolean {
      return (
        !this.isReleased &&
        this.hasPermission(
          'offender.medical.createHistory',
          'facility',
          this.offender.housingFacilityID,
        )
      );
    },
  },

  watch: {
    id(val: number): void {
      if (this.id) {
        this.fetchData();
      }
    },
  },

  methods: {
    fetchData(): void {
      this.$store.dispatch('offender/fetchHealthHistoryRecords', {
        offenderID: this.id,
      });
    },
  },
  components: {
    AddHealthHistoryRecordDialog,
  },
});
