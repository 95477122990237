





















































































































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Case } from '@/vuex/court/case';
import { Party, PartyTypeID } from '@/vuex/court/party';
import { Participant } from '@/vuex/court/participant';

import EditPartyDialog from '@/components/court/party/EditPartyDialog.vue';
import EditParticipantAttorneysDialog from '@/components/court/attorney/EditParticipantAttorneysDialog.vue';
import PartyDetails from '@/components/court/party/PartyDetails.vue';
import ParticipantAttorneysTable from '@/components/court/attorney/ParticipantAttorneysTable.vue';
import ChargesTable from '@/components/charges/ChargesTable.vue';
import SentenceSummary from '@/components/court/SentenceSummary.vue';

export default Vue.extend({
  data(): any {
    return {
      isOpen: false,
      participantID: 0,
    };
  },
  computed: {
    ...mapState('court', ['partyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['caseWithID', 'partyName', 'participantWithID']),
    ...mapGetters('auth', ['hasPermission']),

    kase(): Case | null {
      return this.caseWithID(this.participant.caseID);
    },

    participant(): Participant | null {
      return this.participantWithID(this.participantID) || null;
    },

    party(): Party | null {
      if (!this.participant) {
        return null;
      }
      return this.partyWithID(this.participant.partyID);
    },

    canHaveCharges(): boolean {
      if (!this.participant) {
        return false;
      }
      return this.participant.originalPartyTypeID === PartyTypeID.Defendant;
    },

    canHaveAttorneys(): boolean {
      if (!this.participant) {
        return false;
      }
      return this.participant.originalPartyTypeID !== PartyTypeID.Prosecutor;
    },
  },

  methods: {
    /**
     * open is called via external refs to this component. It is passed the
     * participantID which should be loaded.
     */
    open(participantID: number): void {
      this.participantID = participantID;
      this.isOpen = true;
    },

    /**
     * close dismisses the dialog.
     */
    close(): void {
      this.isOpen = false;
    },
  },
  components: {
    ChargesTable,
    EditPartyDialog,
    EditParticipantAttorneysDialog,
    ParticipantAttorneysTable,
    PartyDetails,
    SentenceSummary,
  },
});
