


































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Transfer } from '@/vuex/offender/transfer';

import TransferActions from '@/components/offender/transfer/TransferActions.vue';
import TransferAddMenu from '@/components/offender/transfer/TransferAddMenu.vue';
import TransferBackfillDialog from '@/components/offender/transfer/TransferBackfillDialog.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },

  created() {
    this.fetchData();
  },

  data() {
    return {
      hideMoves: false,
    };
  },

  computed: {
    ...mapGetters('offender', ['transfersForOffender', 'offenderIsReleased']),
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * transfers computes the array of Transfer objects to display in the table
     */
    transfers(): Transfer[] {
      const transfers = this.transfersForOffender(this.offenderID);
      if (this.hideMoves) {
        return transfers.filter(this.isTransfer);
      }
      return transfers;
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },
  },

  watch: {
    offenderID(val: number): void {
      if (this.offenderID) {
        this.fetchData();
      }
    },
  },

  methods: {
    /**
     * fetchData performs the API call to populate the list of this Offender's
     * transfers
     */
    async fetchData(): Promise<void> {
      this.$store.dispatch('offender/fetchTransfers', {
        offenderID: this.offenderID,
      });
    },

    /**
     * isTransfer takes a full Transfer object and returns true if its a
     * facility-to-facility, real Transfer and not an internal Move.
     */
    isTransfer(transfer: Transfer): boolean {
      return transfer.toHousingFacilityID !== transfer.fromHousingFacilityID;
    },
  },

  components: {
    TransferActions,
    TransferAddMenu,
    TransferBackfillDialog,
  },
});
