














import _ from 'lodash';
import Vue from 'vue';

import { mapState, mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    attorney: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('court', ['attorneyTypes']),
    ...mapGetters('court', ['attorneyName']),
    ...mapGetters('language', ['nameForIDInCollection']),
  },
});
