








































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Department } from '@/vuex/investigation/department';

import NewDepartmentDialog from '@/components/investigation/NewDepartmentDialog.vue';
import EditDepartmentDialog from '@/components/investigation/EditDepartmentDialog.vue';
import investigationAPI from '@/vuex/investigation/investigationAPI';

export default Vue.extend({
  name: 'DepartmentContainer',
  props: {
    parentID: Number,
  },
  data() {
    return {
      search: '',
      page: 1,
      newParentID: this.parentID,
      isMoveOpen: false,
      isReplaceOpen: false,
      replacementID: 0,
      selected: [],
    };
  },
  computed: {
    ...mapGetters('investigation', [
      'departmentsUnder',
      'departmentChildCount',
    ]),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('language', ['locale']),

    departments(): Department[] {
      return this.departmentsUnder(this.parentID);
    },

    selectedIDs(): number[] {
      return _.map(this.selected, 'id');
    },

    /**
     * translatedDepartments returns an array of Departments with the name attribute
     * converted from a TranslatedString to the string for the active locale,
     * and adds a locationGeography attibute set to the full name of the geography.
     * This is done so that the v-data-table's search can interact with these
     * fields instead of the Object or number which underlie them.
     */
    translatedDepartments(): any[] {
      return _.chain(this.departments)
        .map((department) =>
          Object.assign({}, department, {
            translatedName: department.name[this.locale], //
            locationGeography: this.fullGeographyName(
              department.locationGeographyID,
            ),
            rank: department.rank || 999999, // Sort blank ranks to the end
          }),
        )
        .orderBy(['rank', 'translatedName'])
        .value();
    },

    containers(): Department[] {
      return _.filter(
        this.translatedDepartments,
        (d: Department) => d.childCount && d.childCount > 0,
      );
    },

    uncontainedDepartments(): Department[] {
      return _.filter(
        this.translatedDepartments,
        (d: Department) => !d.childCount || d.childCount < 1,
      );
    },

    /**
     * headers builds the column header metadata for the v-data-table
     */
    headers(): any[] {
      const h = [
        {
          text: this.hasPermission('admin.department.create')
            ? this.$i18n.t('command.edit')
            : 'ID',
          value: 'id',
          width: '2%',
          sortable: false,
        },
        {
          text: 'Code',
          value: 'code',
          align: 'right',
        },
        {
          text: this.$i18n.t('person.name'),
          value: 'translatedName',
        },
        {
          text: this.$i18n.t('location.singular'),
          value: 'locationGeography',
        },
        {
          text: 'CMS1 Records',
          value: 'cms1RecordCount',
          align: 'right',
        },
        {
          text: 'CMS 1 Importance',
          value: 'cms1Importance',
          align: 'right',
        },
      ];
      return h;
    },

    searchable(): boolean {
      return this.uncontainedDepartments.length > 10;
    },

    paginated(): boolean {
      return this.uncontainedDepartments.length > 10;
    },
  },
  watch: {
    search(newVal: string) {
      // Always jump to page 1 after the search changes
      this.page = 1;
    },
    parentID(newID: number) {
      this.newParentID = newID;
    },
    isReplaceOpen() {
      this.replacementID = 0;
    },
  },
  methods: {
    async move(): Promise<void> {
      const response = await investigationAPI.post('/departments/move', {
        data: {
          oldParentID: this.parentID,
          newParentID: this.newParentID,
          childIDs: this.selectedIDs,
        },
      });
      const newState = response.data.data;
      this.$store.commit('investigation/setState', newState);
      this.$store.commit('investigation/calculateDepartmentChildStats');
      this.selected = [];
      this.isMoveOpen = false;
    },
    async replace(): Promise<void> {
      const response = await investigationAPI.post('/departments/replace', {
        data: {
          oldDepartmentIDs: this.selectedIDs,
          newDepartmentID: this.replacementID,
        },
      });
      const newState = response.data.data;
      this.$store.commit('investigation/setState', newState);
      this.$store.commit('investigation/calculateDepartmentChildStats');
      this.selected = [];
      this.isReplaceOpen = false;
    },
  },
  components: {
    EditDepartmentDialog,
    NewDepartmentDialog,
  },
});
