














import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Dossier } from '@/vuex/court/dossier';
import { Profile } from '@/vuex/profile/profile';

import ProfileDossierCard from '@/components/profile/ProfileDossierCard.vue';
import ProfileHeaderNav from '@/components/profile/ProfileHeaderNav.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('court', ['dossiersForParty']),
    profile(): Profile {
      return this.profileWithID(this.id);
    },
    dossiers(): Dossier[] {
      if (!this.profile || !this.profile.partyID) {
        return [];
      }
      return this.dossiersForParty(this.profile.partyID);
    },
  },
  components: {
    ProfileDossierCard,
    ProfileHeaderNav,
  },
});
