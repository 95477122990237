














































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import { CaseJudge } from '@/vuex/court/caseJudge';

import EditCaseJudgesDialog from '@/components/court/judge/EditCaseJudgesDialog.vue';

export default Vue.extend({
  props: {
    caseID: Number,
    courtID: Number,
  },

  created(): void {
    this.fetchData();
  },

  computed: {
    ...mapGetters('court', ['caseWithID', 'caseJudgesByCaseID', 'judgeName']),

    kase(): Case {
      return this.caseWithID(this.caseID);
    },

    caseJudges(): CaseJudge[] {
      if (this.caseJudgesByCaseID(this.caseID).length === 0) {
        return [] as CaseJudge[];
      }
      return _(this.caseJudgesByCaseID(this.caseID))
        .sortBy((j) => !j.isLeadJudge)
        .value();
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      await this.$store.dispatch('court/fetchCaseJudges', {
        caseID: this.caseID,
      });
    },
  },

  components: {
    EditCaseJudgesDialog,
  },
});
