

























































import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    value: Boolean,
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      results: [],
    };
  },
  computed: {
    /**
     * queryString computes the parameters to send to the API. This must
     * be a computed property so that it can be watched to trigger a new
     * API call.
     */
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a computed property so
     * that it will update with new translations when the locale changes.
     */
    headers(): any[] {
      return [
        { text: i18n.t('name'), value: 'name' },
        {
          text: i18n.t('crimeReport.activeCrimeReports'),
          align: 'right',
          value: 'crimeReportCount',
        },
        {
          text: 'Average Filing Delay', // TODO Translate
          align: 'right',
          value: 'averageFilingDelay',
        },
      ];
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const url = `/crime-reports/by-department?${this.queryString}`;
        const response = await investigationAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } finally {
        this.loading = false;
      }
    },
  },
});
