














































































































































































































import Vue from 'vue';
import _ from 'lodash';
import i18n from '@/i18n';
import { mapState, mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import {
  AllDocketEventTypeModules,
  DocketEventType,
} from '@/vuex/court/options';
import { VSelectItem } from '@/lib/vue-typescript';

import { LookupItem } from '@/vuex/court/options';

import EditDocketEventTypeDialog from '@/components/court/docketEvent/EditDocketEventTypeDialog.vue';
import NewDocketEventTypeDialog from '@/components/court/docketEvent/NewDocketEventTypeDialog.vue';
import { CustomFieldTypes } from '@/vuex/court/customFieldType';

export default Vue.extend({
  created() {
    if (_.isEmpty(this.initialCriteria)) {
      this.initialCriteria = _.cloneDeep(this.criteria);
    }
  },

  data() {
    return {
      advancedSearch: false,

      initialCriteria: {},
      criteria: {
        q: '',

        willChangeDossierStage: null as boolean | null,
        newDossierStageID: undefined as number | undefined,
        willChangeDossierStatus: null as boolean | null,
        newDossierStatusID: 0,
        willChangeCaseStatus: null as boolean | null,
        newCaseStatusID: 0,
        willChangeCourtStage: null as boolean | null,
        newCourtStageID: 0,
        willChangeDepartment: null as boolean | null,
        willSetAwarenessNumber: null as boolean | null,
        willFinalizeDossier: null as boolean | null,
        customFieldType: '',
      },
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['docketEventTypes', 'docketEventCategories']),

    canAdd(): boolean {
      return this.hasPermission('admin.docketEvent.create');
    },

    /**
     * filteredModules returns the module names which contain Docket Event Types
     * which match the current filter criteria (e.g. ['detection', 'court'])
     */
    filteredModules(): string[] {
      return _.filter(
        AllDocketEventTypeModules,
        (module: string) => this.moduleEventCounts[module] > 0,
      );
    },

    /**
     * moduleEventCounts computes the number of DocketEventTypes that match the
     * current filtering criteria:
     *
     * {
     *    'detection': 21,
     *    'investigation': 108,
     *    'court': 55
     * }
     *
     */
    moduleEventCounts(): { [module: string]: number } {
      return _.countBy(this.filteredDocketEventTypes, 'module');
    },

    /**
     * filteredDocketEventTypes transforms the raw array of all known
     * DocketEventTypes and filters it to contain only those which match the
     * active filter criteria (both "simple" and "advanced").
     */
    filteredDocketEventTypes(): DocketEventType[] {
      return _.filter(this.docketEventTypes, (det: DocketEventType) => {
        return _.every([
          this.matchesSimpleCriteria(det),
          this.matchesDossierStageCriteria(det),
          this.matchesDossierStatusCriteria(det),
          this.matchesCourtStageCriteria(det),
          this.matchesCaseStatusCriteria(det),
          this.matchesCustomFieldTypeCriteria(det),
          this.criteria.willChangeDepartment === null ||
            det.willChangeDepartment === this.criteria.willChangeDepartment,
          this.criteria.willSetAwarenessNumber === null ||
            det.willSetAwarenessNumber === this.criteria.willSetAwarenessNumber,
          this.criteria.willFinalizeDossier === null ||
            det.willFinalizeDossier === this.criteria.willFinalizeDossier,
        ]);
      });
    },

    translatedDocketEventTypes(): DocketEventType[] {
      return _.chain(this.filteredDocketEventTypes)
        .map((rt) =>
          Object.assign(
            {
              translatedName: rt.name[this.locale],
              translatedCaseTypes: rt.allowedCaseTypes.map((t) =>
                i18n.t('dossier.caseTypeOptions.' + t),
              ),
              translatedCourtStages: rt.allowedCourtStageIDs.map((id: number) =>
                this.nameForIDInCollection(id, 'court/courtStages'),
              ),
              translatedDossierStages: rt.allowedDossierStageIDs.map(
                (id: number) =>
                  this.nameForIDInCollection(id, 'court/dossierStages'),
              ),
            },
            rt,
          ),
        )
        .sortBy('translatedName')
        .value();
    },

    /**
     * groupedDocketEventTypes transforms the filtered array of DocketEventyType
     * into a double-grouped object, with the first layer of grouping done
     * by module, and the second group done by categoryID:
     *
     * {
     *    'detection': {
     *      1: DocketEventType[],
     *      4: DocketEventType[],
     *    },
     *    'investigation': {
     *      3: DocketEventType[],
     *    }
     * }
     */
    groupedDocketEventTypes(): any {
      return _.chain(this.translatedDocketEventTypes)
        .sortBy('rank')
        .groupBy('module')
        .mapValues((entries: DocketEventType[]) =>
          _.groupBy(entries, 'categoryID'),
        )
        .value();
    },

    // /**
    //  * docketEventFieldTypes builds the array of objects for the V-Select
    //  */
    docketEventFieldTypes(): string[] {
      return _.map(CustomFieldTypes, 'name');
    },
  },

  watch: {
    /**
     * advancedSearch changes the UI, so we need to make sure to reset
     * the no longer visible fields
     */
    advancedSearch(isAdvanced: boolean) {
      if (!isAdvanced) {
        this.resetAdvancedCriteria();
      }
    },
  },

  methods: {
    // Makes sure accessing grouping doesn't break when filtering results in
    // an empty result
    groupedDocketEventTypesForModule(module: string): any {
      return this.groupedDocketEventTypes.hasOwnProperty(module)
        ? this.groupedDocketEventTypes[module]
        : {};
    },

    headersForModule(module: string): any[] {
      const h: any[] = [
        {
          text: this.$i18n.t('sort.rank'),
          value: 'rank',
          align: 'right',
        },
        {
          text: this.$i18n.t('docketEventType.name'),
          value: 'translatedName',
        },
        {
          text: this.$i18n.t('docketEventType.allowedCaseTypes'),
          value: 'translatedCaseTypes',
        },
      ];

      if (module === 'court') {
        h.push({
          text: this.$i18n.t('docketEventType.allowedStages'),
          value: 'translatedCourtStages',
        });
      } else {
        h.push({
          text: this.$i18n.t('docketEventType.allowedDossierStages'),
          value: 'translatedDossierStages',
        });
      }

      if (this.hasPermission('admin.docketEvent.create')) {
        h.unshift({
          text: this.$i18n.t('command.edit'),
          value: null,
          with: '2%',
          sortable: false,
          align: 'center',
        });
      }

      return h;
    },

    /**
     * categoriesForModule returns an array of DocketEventCategory objects
     * which have entries for the requested module, sorted by the rank of the
     * module,
     */
    categoriesForModule(module: string): LookupItem[] {
      return _.chain(this.filteredDocketEventTypes)
        .filter({ module })
        .map('categoryID')
        .uniq()
        .map((id) => _.find(this.docketEventCategories, { id }))
        .compact()
        .sortBy('rank')
        .value();
    },

    resetAdvancedCriteria(): void {
      this.criteria = Object.assign(this.criteria, this.initialCriteria);
    },

    /**
     * matchesSimpleCriteria returns true if the provided DocketEventType
     * is a valid match for the current simple search criteria (q).
     */
    matchesSimpleCriteria(det: DocketEventType): boolean {
      if (!this.criteria.q) {
        return true;
      }

      const search = this.criteria.q.toLowerCase();
      const name = det.name[this.locale].toLowerCase();
      const category = this.nameForIDInCollection(
        det.categoryID,
        'court/docketEventCategories',
      ).toLowerCase();
      if (name.includes(search) || category.includes(search)) {
        return true;
      }

      return false;
    },

    /**
     * matchesDossierStageCriteria returns true if the provided DocketEventType
     * is a valid match for the current Dossier stage criteria (
     * willChangeDossierStage, and newDossierStageID).
     */
    matchesDossierStageCriteria(det: DocketEventType): boolean {
      const wcdt = this.criteria.willChangeDossierStage;
      const newStageID = this.criteria.newDossierStageID;

      if (wcdt === null) {
        return true;
      }

      if (det.willChangeDossierStage !== wcdt) {
        return false;
      }

      if (wcdt && newStageID !== undefined) {
        return det.newDossierStageID === newStageID;
      }

      return true;
    },

    /**
     * matchesDossierStatusCriteria returns true if the provided DocketEventType
     * is a valid match for the current Dossier Status criteria (
     * willChangeDossierStatus and newDossierStatusID)
     */
    matchesDossierStatusCriteria(det: DocketEventType): boolean {
      const wcds = this.criteria.willChangeDossierStatus;
      const newStatusID = this.criteria.newDossierStatusID;

      if (wcds === null) {
        return true;
      }

      if (det.willChangeDossierStatus !== wcds) {
        return false;
      }

      if (wcds && newStatusID > 0) {
        return det.newDossierStatusID === newStatusID;
      }

      return true;
    },

    /**
     * matchesCourtStageCriteria returns true if the provided DocketEventType
     * is a valid match for the current Court Stage criteria in the advanced
     * search (willChangeCourtStage and newCourtStageID).
     */
    matchesCourtStageCriteria(det: DocketEventType): boolean {
      const wccs = this.criteria.willChangeCourtStage;
      const newStageID = this.criteria.newCourtStageID;

      if (wccs === null) {
        return true;
      }

      if (det.willChangeCourtStage !== wccs) {
        return false;
      }

      if (wccs && newStageID > 0) {
        return det.newCourtStageID === newStageID;
      }

      return true;
    },

    /**
     * matchesCaseStatusCriteria returns true if the provided DocketEventType
     * is a valid match for the current Court Status criteria in the advanced
     * search (willChangeCaseStatus and newCaseStatusID)
     */
    matchesCaseStatusCriteria(det: DocketEventType): boolean {
      const wccs = this.criteria.willChangeCaseStatus;
      const newStatusID = this.criteria.newCaseStatusID;

      if (wccs === null) {
        return true;
      }

      if (det.willChangeCaseStatus !== wccs) {
        return false;
      }

      if (wccs && newStatusID > 0) {
        return det.newCaseStatusID === newStatusID;
      }

      return true;
    },

    /**
     * matchesCustomFieldTypeCriteria returns true if the provided
     * DocketEventType is a valid match for the current field type criteria in
     * the advanced search.
     */
    matchesCustomFieldTypeCriteria(det: DocketEventType): boolean {
      const criteriaFieldType = this.criteria.customFieldType;
      if (!criteriaFieldType) {
        // If no field type is specified in the form, all types match
        return true;
      }

      const fieldsInUse = _.map(det.fields, 'type');
      return _.includes(fieldsInUse, criteriaFieldType);
    },
  },

  components: {
    EditDocketEventTypeDialog,
    NewDocketEventTypeDialog,
  },
});
