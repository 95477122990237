































import _ from 'lodash';
import Vue from 'vue';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },

  data() {
    return {
      loading: false,
      error: null as any,

      occurredBetween: '',

      results: [] as any[],
    };
  },

  computed: {
    /**
     * fullCriteria builds the actual API criteria which will be sent. This is
     * done by combining the incoming criteria prop with customizations set
     * directly on this component.
     */
    fullCriteria(): any {
      const criteria = Object.assign({}, this.criteria, {
        activeOnly: false, // TODO Find a way to avoid needing to override this.
        occurredBetween: this.occurredBetween,
      });
      return criteria;
    },

    /**
     * queryString builds the URL querystring which will be sent to the API
     * endpoint. It exists as a standalone computed prop so that the watcher
     * can trigger only when the query has changed.
     */
    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },
  },

  watch: {
    /**
     * queryString is watched so that a new API fetch can be triggered when
     * parameters are changed.
     */
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      try {
        const url = `/crime-reports/by-province?${this.queryString}`;
        const response = await investigationAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
  },
});
