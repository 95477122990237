







































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    active: Boolean,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
});
