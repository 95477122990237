













import _ from 'lodash';
import Vue from 'vue';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { Department } from '@/vuex/investigation/department';

import DepartmentForm from '@/components/investigation/DepartmentForm.vue';

export default Vue.extend({
  data() {
    return {
      department: null as Department | null,
      isOpen: false,
      error: null as any,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.department = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.department) {
        return;
      }

      this.error = null;
      try {
        const response = await investigationAPI.post('departments/create', {
          data: {
            parentID: this.department.parentID || null,
            rank: this.department.rank,
            code: this.department.code,
            name: this.department.name,
            locationGeographyID: this.department.locationGeographyID,
            year: this.department.year,
            lastCaseNumber: this.department.lastCaseNumber,
            legacyDepartmentIDs: this.department.legacyDepartmentIDs,
          },
        });
        const newState = response.data.data;
        this.$store.commit('investigation/setState', newState);
        this.$store.commit('investigation/prependToTarget', {
          target: 'departmentIDs',
          value: Object.keys(newState.department),
        });
        this.$store.commit('investigation/calculateDepartmentChildStats');
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    DepartmentForm,
  },
});
