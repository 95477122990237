















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import housingAPI from '@/vuex/housing/housingAPI';
import { HousingFacility } from '@/vuex/housing/housing';

import HousingFacilityForm from '@/components/offender/facility/HousingFacilityForm.vue';

export default Vue.extend({
  props: {
    facilityID: Number,
  },

  data() {
    return {
      isOpen: false,
      error: null as any,
      loading: false,
      facility: null as null | HousingFacility,
    };
  },

  computed: {
    ...mapGetters('housing', ['facilityWithID']),
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    async reset(): Promise<void> {
      this.loading = true;
      this.error = null;
      try {
        await this.$store.dispatch('housing/fetchFacility', {
          force: true,
          facilityID: this.facilityID,
        });
        const localCopy = _.cloneDeep(this.facilityWithID(this.facilityID));
        this.facility = localCopy;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      if (!this.facility) {
        return;
      }

      try {
        // Execute the API call and await the response
        const resp = await housingAPI.post('/facilities/update', {
          data: {
            housingFacilityID: this.facility.id,
            parentID: this.facility.parentID,
            name: this.facility.name,
            type: this.facility.type,
            geographyID: this.facility.geographyID,
            canHoldJuveniles: this.facility.canHoldJuveniles,
            canHoldAdults: this.facility.canHoldAdults,
            canHoldDetainees: this.facility.canHoldDetainees,
            canHoldPrisoners: this.facility.canHoldPrisoners,
            canHoldFemales: this.facility.canHoldFemales,
            canHoldMales: this.facility.canHoldMales,
            acceptsTransfers: this.facility.acceptsTransfers,
            capacity: this.facility.capacity,
            legacyIDs: this.facility.legacyIDs,
          },
        });

        // Parse the resulting state and inject it into Vuex
        const newState = _.get(resp, 'data.data');
        this.$store.commit('housing/setState', newState);

        // Close the dialog
        this.isOpen = false;
      } catch (err) {
        this.error = err;
      }
    },
  },
  components: {
    HousingFacilityForm,
  },
});
