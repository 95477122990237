

































































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { Party } from '@/vuex/court/party';

import PartyAttorneyDisplay from '@/components/court/attorney/PartyAttorneyDisplay.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },

  computed: {
    ...mapState('court', ['partyTypes', 'attorneyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['fullPartyName', 'partyWithID']),
    ...mapState('profile', ['genders']),
    ...mapGetters('geography', ['fullGeographyName']),

    payload(): any {
      return this.entry.payload;
    },

    party(): Party {
      return this.partyWithID(this.partyID);
    },

    partyID(): number {
      if (!this.entry.affectedRecords) {
        return 0;
      }
      return _.first(this.entry.affectedRecords.partyIDs) || 0;
    },
  },

  components: {
    PartyAttorneyDisplay,
  },
});
