















































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import etlAPI from '@/vuex/etl/etlAPI';
import { Dossier } from '@/vuex/court/dossier';
import { LegacyDossierVersion } from '@/vuex/etl/legacy-dossier-version';
import CMS1Viewer from '@/components/etl/CMS1Viewer.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
  },

  data() {
    return {
      isOpen: false,
      isLoading: false,
      error: null as any,

      selected: null as LegacyDossierVersion | null,
      selectedDataLoading: false,
      selectedData: null as any,

      versions: [] as LegacyDossierVersion[],
    };
  },

  computed: {
    ...mapGetters('court', ['dossierWithID']),

    dossier(): Dossier {
      return this.dossierWithID(this.dossierID);
    },

    dossierNumber(): string {
      if (!this.dossier) {
        return '';
      }
      return this.dossier.number;
    },

    headers(): any[] {
      const h: any[] = [
        { value: 'id', text: i18n.t('id'), width: '8em', align: 'right' },
        { value: 'cms1LastChangedAt', text: 'CMS1 Last Change' },
        { value: 'cms1LastChangedTable', text: 'CMS1 Table' },
        { value: 'timesReceived', text: 'Received (Loaded)', align: 'right' },
        { value: 'firstReceivedAt', text: 'First Received' },
        { value: 'lastReceivedAt', text: 'Last Received' },
        { value: 'lastLoadError', text: 'Last Load Error' },
      ];
      return h;
    },
  },

  watch: {
    dossierNumber: {
      immediate: true,
      handler(newNumber: string) {
        this.fetchVersions();
      },
    },
  },

  methods: {
    async fetchVersions(): Promise<void> {
      if (!this.dossierNumber) {
        this.versions = [];
        return;
      }

      const res = await etlAPI.get(`/dossiers/${this.dossierNumber}/versions`);
      this.versions = _.get(res, 'data.data', []);
    },

    async versionSelected(v: LegacyDossierVersion): Promise<void> {
      this.selected = v;
      this.selectedDataLoading = true;
      try {
        const url = `/dossiers/${this.dossierNumber}/versions/${v.id}/data`;
        const res = await etlAPI.get(url);
        this.selectedData = _.get(res, 'data.data', {});
      } finally {
        this.selectedDataLoading = false;
      }
    },

    async load(id: number): Promise<void> {
      try {
        this.isLoading = true;
        const url = `/dossiers/${this.dossierNumber}/versions/${id}/reload`;
        await etlAPI.post(url);
        window.location.reload();
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },

    close(): void {
      this.isOpen = false;
    },
  },

  components: {
    CMS1Viewer,
  },
});
