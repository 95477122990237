

































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { LookupItem } from '@/vuex/court/options';
import { TAll, TUnknown } from '@/lib/translated';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    /**
     * items is the Vuex state address this component will pull its
     * choices from. Example usage:
     *
     * items="court/attorneyTypes"
     * items="court/summonsModes"
     * items="offender/releaseReasons"
     * items="offender/criminalGroups"
     *
     */
    items: {
      type: String,
      required: true,
    },
    type: String,
    disabled: Boolean,
    disableSorting: Boolean,
    excludeUnknown: Boolean,
    excludedIDs: Array,
    allowedIDs: Array,
    value: [Number, Array],
    label: String,
    rules: Array,
    multiple: Boolean,
    clearable: Boolean,
    chips: Boolean,
    smallChips: Boolean,
    deletableChips: Boolean,
    autocomplete: Boolean,

    /**
     * zeroLabel can be used to override the display of the "zero" choice
     * (the one with id == 0). In some contexts (such as when using a selector
     * as a search input) you want the lack of a selection to mean "All".
     * In other contexts (such as data entry), you want the lack of an entry
     * to mean "Unknown". Use zero-label="all" for the former,
     * and zero-label="unknown" for the latter. Most of the time,
     * zero-label="unknown" is unnecessary because the database value for 0
     * is often already set to that.
     */
    zeroLabel: { type: String },
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['valueForLocale']),

    /**
     * choices builds the raw data which will be displayed as choices in the
     * Dropdown list. We use the string provided as the items prop to retrieve
     * a list of data from the Vuex store. After retrieving those raw elements,
     * we apply some transformations if necessary:
     *
     * - sorting by the rank field (unless sorting is disabled)
     * - removing the 'unknown' choice
     * - filtering out items which have blank names
     */
    choices(): LookupItem[] {
      let choices: LookupItem[] = [];

      // Get the choices from the state
      const itemNames = this.items.split('/');
      if (itemNames.length > 1) {
        const moduleName = itemNames[0];
        const variableName = itemNames[1];
        // A deep clone is needed so that transformations below aren't
        // persisted in Vuex State
        choices = _.cloneDeep(this.$store.state[moduleName][variableName]);
      } else {
        // A deep clone is needed so that transformations below aren't
        // persisted in Vuex State
        choices = _.cloneDeep(this.$store.state[this.items]);
      }
      if (!choices) {
        throw new Error(`Could not find "${this.items}" in Vuex state.`);
      }

      // Filter choices with no name
      choices = _.filter(choices, (c) => {
        return !!c.name;
      });

      // Exclude specific choices and/or the unknown value if
      // appropriate props are set
      const excludedIDs = _.flatten([this.excludedIDs || []]);
      if (this.excludeUnknown) {
        excludedIDs.push(0);
      }
      if (excludedIDs.length > 0) {
        choices = _.reject(choices, (c) => {
          return _.includes(excludedIDs, c.id);
        });
      }

      // Restrict choices to only those which are allowed if the allowedIDs
      // prop is populated
      if (this.allowedIDs && this.allowedIDs.length > 0) {
        choices = _.filter(choices, (c) => {
          return _.includes(this.allowedIDs, c.id);
        });
      }

      // Restrict choices to those that match the type if it was provided
      if (this.type) {
        choices = _.filter(choices, { type: this.type });
      }

      // Override the Unknown/Zero Label if requested
      if (this.zeroLabel) {
        choices = _.map(choices, (c) => {
          if (c.id === 0) {
            if (this.zeroLabel === 'all') {
              c.name = TAll;
            } else if (this.zeroLabel === 'unknown') {
              c.name = TUnknown;
            }
          }
          return c;
        });
      }

      // Sort the list either by rank, or alphabetically... unless the developer
      // has disabled sorting via a prop
      if (!this.disableSorting) {
        const hasRanks = _.some(choices, (c) => c.rank && c.rank > 0);
        if (hasRanks) {
          // Sort by rank, then alphabetically by the active locale
          choices = _.chain(choices)
            .map((c) =>
              Object.assign(c, {
                rank: c.rank || 999,
              }),
            )
            .sortBy(['rank', (c) => this.valueForLocale(c.name)])
            .value();
        } else {
          // Sort alphabetically by the active locale
          choices = _.sortBy(choices, (c) => this.valueForLocale(c.name));
        }
      }

      return choices;
    },

    /**
     * translatedChoices transforms the list of choices into the VSelectItem
     * format (value, text) that the V-Select and V-Autocomplete components
     * expect.
     */
    translatedChoices(): VSelectItem[] {
      return _.map(this.choices, (c) => {
        return { value: c.id, text: c.name[this.locale] };
      });
    },
  },

  watch: {
    /**
     * type is watched so that when the type is changed, the selected item
     * jumps to the first valid selection for that type, preventing the
     * previous selection from a now-invalid type being preserved.
     */
    type(newType: string): void {
      const value = this.value;
      const validIDs = _.map(this.choices, 'id');
      if (validIDs.length > 0 && !_.includes(validIDs, value)) {
        this.$emit('input', validIDs[0]);
      }
    },
  },

  methods: {
    /**
     * clear is the handler for the v-select/v-autocomplete control's clear
     * event. By default, clear() sets the value to undefined, which we don't
     * like. This code clears to either 0 or [] instead, depending on whether
     * the value is currently an array.
     */
    clear(): void {
      const isArray = Array.isArray(this.value);
      this.$nextTick(() => {
        if (isArray) {
          this.$emit('input', []);
        } else {
          this.$emit('input', 0);
        }
      });
    },
  },
});
