













































































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Hearing } from '@/vuex/court/hearing';

import RescheduleHearingDialog from '@/components/court/hearing/RescheduleHearingDialog.vue';

export default Vue.extend({
  props: {
    caseID: Number,
  },
  data() {
    return {
      cancellationReasonID: 0,
      cancellationNotes: '',
      courtHearings: [] as Hearing[],
      loading: false,
      showPastHearings: false,
    };
  },
  computed: {
    ...mapGetters('court', ['hearingsForCase', 'caseWithID', 'courtroomName']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['hearingTypes']),

    /**
     * rawHearings returns the complete list of hearings for the case
     */
    rawHearings(): Hearing[] {
      let hearings = [] as Hearing[];
      if (this.caseID) {
        hearings = this.hearingsForCase(this.caseID);
      } else {
        hearings = this.courtHearings;
      }
      // Remove cancelled hearings
      hearings = _.filter(hearings, (h) => !h.isCancelled);
      return _.sortBy(hearings, (h) => h.hearingDate);
    },

    /**
     * hearings returns the filtered array of hearings which should
     * be displayed based on current filter settings
     */
    hearings(): Hearing[] {
      let hearings = this.rawHearings;

      // Remove past hearings if desired
      if (!this.showPastHearings) {
        hearings = hearings.filter((hearing) => this.isUpcoming(hearing));
      }

      return hearings;
    },

    /**
     * hasPastHearings returns true if any past hearings exist
     */
    hasPastHearings(): boolean {
      const pastHearings = this.rawHearings.filter((hearing) =>
        this.isPast(hearing),
      );
      return pastHearings.length > 0;
    },
  },
  methods: {
    isUpcoming(hearing: Hearing): boolean {
      return hearing.hearingDate >= moment().format();
    },
    isPast(hearing: Hearing): boolean {
      return !this.isUpcoming(hearing);
    },
    async cancel(hearing: Hearing): Promise<void> {
      const response = await courtAPI.post(`hearings/cancel`, {
        data: {
          hearingID: hearing.id,
          caseID: hearing.caseID,
          cancellationReasonID: this.cancellationReasonID,
          cancellationNotes: this.cancellationNotes,
        },
      });
      const newState = response.data.data;
      this.$store.commit('court/setState', newState);
      this.$store.commit('court/addDocketEntry', response);
    },
  },
  components: {
    RescheduleHearingDialog,
  },
});
