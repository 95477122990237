







































import Vue from 'vue';

import ProfileIdentityDisplay from '@/components/profile/ProfileIdentityDisplay.vue';

export default Vue.extend({
  props: {
    profile: Object,
  },
  methods: {
    print() {
      window.print();
    },
  },
  components: {
    ProfileIdentityDisplay,
  },
});
