













import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Court } from '@/vuex/court/court';

import CourtForm from '@/components/court/court/CourtForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      court: null as Court | null,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('court', ['courtWithID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      if (this.id) {
        const myCopy = Object.assign({}, this.courtWithID(this.id));
        this.court = myCopy;
      } else {
        this.court = null;
      }
    },
    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.court) {
        return;
      }
      this.error = null;
      try {
        const response = await courtAPI.post('courts/update', {
          data: {
            id: this.court.id,
            parentID: this.court.parentID,
            rank: this.court.rank,
            code: this.court.code,
            name: this.court.name,
            locationGeographyID: this.court.locationGeographyID,
            stageIDs: this.court.stageIDs,
            headOfCourtJudgeID: this.court.headOfCourtJudgeID,
            legacyDepartmentIDs: this.court.legacyDepartmentIDs,
            year: this.court.year,
            maxCaseDurationInDays: this.court.maxCaseDurationInDays,
            warnAfterDays: this.court.warnAfterDays,
            lastCaseNumber: this.court.lastCaseNumber,
          },
        });
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/calculateCourtChildStats');
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    CourtForm,
  },
});
