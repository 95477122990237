





























































































































































































































































































































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender } from '@/vuex/offender/offender';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: ['value', 'offenderID'],
  data(): any {
    return {
      createdAt: moment().format(),
      housingFacilityID: 0,
      hasPrescribedMedication: null as boolean | null,
      prescribedMedication: '',
      isAllergic: null as boolean | null,
      allergicMedication: '',
      hasBeenUnderPhysicianCare: null as boolean | null,
      careForWhat: '',
      hasBeenHospitalized: null as boolean | null,
      hospitalizedWhereWhat: '',
      hasDisabilitiesOrMentalHealth: null as boolean | null,
      whatDisabilitiesOrIssues: '',
      hasHeartMurmurOrTreatedCondition: null as boolean | null,
      hasBeenTreatedForTumorOrCancer: null as boolean | null,
      hasProlongedBleeding: null as boolean | null,
      hasLatexAllergy: null as boolean | null,
      useNonprescriptionDrugs: null as boolean | null,
      infectiousDiseaseIDs: [] as number[],
      healthConditionIDs: [] as number[],
      motherChildConcernIDs: [] as number[],
      unlistedHealthCondition: '',
      hasInjuryAbuse: null as boolean | null,
      injuryAbuse: '',
      hasSufferedDisease: null as boolean | null,
      sufferedDisease: '',
      error: null as any,
      isOpen: false,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.reset();
      }
    },
  },
  computed: {
    ...mapState('offender', [
      'infectiousDiseases',
      'healthConditions',
      'motherAndChildConcerns',
    ]),
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('offender', ['offenderWithID']),
    ...mapState('language', ['locale']),
    offender(): Offender | null {
      return this.offenderWithID(this.offenderID) || null;
    },
    healthConditionItems(): any {
      return _.orderBy(this.healthConditions, ['name.' + this.locale], ['asc']);
    },
  },
  methods: {
    async save(): Promise<void> {
      this.clearDetailsThatAreFalse();
      this.error = null;

      try {
        const response = await offenderAPI.post(
          'intake-health-screenings/create',
          {
            data: {
              offenderID: this.offenderID,
              createdAt: this.createdAt,
              housingFacilityID: this.housingFacilityID,
              hasPrescribedMedication: this.hasPrescribedMedication,
              prescribedMedication: this.prescribedMedication,
              isAllergic: this.isAllergic,
              allergicMedication: this.allergicMedication,
              hasBeenUnderPhysicianCare: this.hasBeenUnderPhysicianCare,
              careForWhat: this.careForWhat,
              hasBeenHospitalized: this.hasBeenHospitalized,
              hospitalizedWhereWhat: this.hospitalizedWhereWhat,
              hasDisabilitiesOrMentalHealth: this.hasDisabilitiesOrMentalHealth,
              whatDisabilitiesOrIssues: this.whatDisabilitiesOrIssues,
              hasHeartMurmurOrTreatedCondition:
                this.hasHeartMurmurOrTreatedCondition,
              hasBeenTreatedForTumorOrCancer:
                this.hasBeenTreatedForTumorOrCancer,
              hasProlongedBleeding: this.hasProlongedBleeding,
              hasLatexAllergy: this.hasLatexAllergy,
              useNonprescriptionDrugs: this.useNonprescriptionDrugs,
              infectiousDiseaseIDs: this.infectiousDiseaseIDs,
              healthConditionIDs: this.healthConditionIDs,
              motherChildConcernIDs: this.motherChildConcernIDs,
              unlistedHealthCondition: this.unlistedHealthCondition,
              hasInjuryAbuse: this.hasInjuryAbuse,
              injuryAbuse: this.injuryAbuse,
              hasSufferedDisease: this.hasSufferedDisease,
              sufferedDisease: this.sufferedDisease,
            },
          },
        );
        const newState = response.data.data;
        const newID = Object.keys(newState.intakeHealthScreening)[0];

        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'intakeHealthScreeningIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.intakeHealthScreening),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
    reset(): void {
      this.createdAt = moment().format();
      this.hasPrescribedMedication = null;
      this.prescribedMedication = '';
      this.isAllergic = null;
      this.allergicMedication = '';
      this.hasBeenUnderPhysicianCare = null;
      this.careForWhat = '';
      this.hasBeenHospitalized = null;
      this.hospitalizedWhereWhat = '';
      this.hasDisabilitiesOrMentalHealth = null;
      this.whatDisabilitiesOrIssues = '';
      this.hasHeartMurmurOrTreatedCondition = null;
      this.hasBeenTreatedForTumorOrCancer = null;
      this.hasProlongedBleeding = null;
      this.hasLatexAllergy = null;
      this.useNonprescriptionDrugs = null;
      this.healthConditionIDs = [] as number[];
      this.motherChildConcernIDs = [] as number[];
      this.hasInjuryAbuse = null;
      this.injuryAbuse = '';
      this.hasSufferedDisease = null;
      this.sufferedDisease = '';
      this.unlistedHealthCondition = '';
      if (this.offender) {
        this.housingFacilityID = this.offender.housingFacilityID;
        // Use the ES6 spread operator to get a copy of these arrays
        // so that the original ones are not modified merely by
        // interacting with the form and then canceling the submission
        if (this.offender.infectiousDiseaseIDs) {
          this.infectiousDiseaseIDs = [...this.offender.infectiousDiseaseIDs];
        }
        if (this.offender.motherChildConcernIDs) {
          this.motherChildConcernIDs = [...this.offender.motherChildConcernIDs];
        }
      }
    },
    clearDetailsThatAreFalse(): void {
      if (!this.hasPrescribedMedication) {
        this.prescribedMedication = '';
      }

      if (!this.isAllergic) {
        this.allergicMedication = '';
      }

      if (!this.hasBeenUnderPhysicianCare) {
        this.careForWhat = '';
      }

      if (!this.hasBeenHospitalized) {
        this.hospitalizedWhereWhat = '';
      }

      if (!this.hasDisabilitiesOrMentalHealth) {
        this.whatDisabilitiesOrIssues = '';
      }

      if (!this.hasInjuryAbuse) {
        this.injuryAbuse = '';
      }

      if (!this.hasSufferedDisease) {
        this.sufferedDisease = '';
      }
    },
  },
  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
