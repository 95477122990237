

























































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Incident } from '@/vuex/offender/incident';
import { Offender } from '@/vuex/offender/offender';

import ReportIncidentDialog from '@/components/offender/incident/ReportIncidentDialog.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },

  created(): void {
    if (this.offenderID) {
      this.fetchData();
    }
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('offender', [
      'incidentsForOffender',
      'offenderIsReleased',
      'offenderWithID',
    ]),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    incidents(): Incident[] {
      return this.incidentsForOffender(this.offenderID);
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },
  },

  watch: {
    offenderID(val: number): void {
      if (this.offenderID) {
        this.fetchData();
      }
    },
  },

  methods: {
    fetchData(): void {
      this.$store.dispatch('offender/fetchIncidents', {
        offenderID: this.offenderID,
      });
    },
  },
  components: {
    ReportIncidentDialog,
  },
});
