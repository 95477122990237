
























import _ from 'lodash';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { HousingFacility } from '@/vuex/housing/housing';

export default Vue.extend({
  props: {
    // label is a passthrough to the v-autocomplete label prop
    label: String,

    // Override just in case it is easier to customize the list
    // outside of this component
    items: {
      type: Array,
      default: () => [] as HousingFacility[],
    },

    /**
     * show-hidden will be used to show facilities _even if_ they are
     * hidden == true. Useful when showing where an offender current resides
     * because he/she may be in a hidden facility
     */
    showHidden: {
      type: Boolean,
      default: false,
    },
    // rules is a passthrough to the v-autocomplete rules prop
    rules: {
      type: Array,
      default: () => [], // Default value is a function which returns a blank array
    },
    // disabled is a passthrough to the v-autocomplete disabled prop
    disabled: Boolean,
    // value is the prop that makes this component work with v-model
    value: Number,
    // Allows an allowed set of ids to be excluded from list
    // most common used to exclude self
    excludedIDs: {
      type: Array,
      default: () => [],
    },
    // Used to filter the list of facilities down to to
    // transferable ones only
    mustAcceptTransfers: {
      type: Boolean,
      default: false,
    },
    mustHavePermission: String,
    // Pass-through variables to v-autocomplete
    hint: String,
    persistentHint: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    placeholder: String,
    autoSelectFirst: Boolean,
    autoFocus: Boolean,
    prependIcon: String,
    appendIcon: String,
    appendOuterIcon: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    value() {
      this.fetchData();
    },
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('housing', ['allFacilities', 'fullFacilityName']),
    ...mapGetters('auth', ['entityIDsWithPermission']),
    facilityChoices(): HousingFacility[] {
      let choices: HousingFacility[] = [];

      // check items first because that is the override
      // collection that may have been passed into
      // this component
      if (this.items.length > 0) {
        choices = this.items as HousingFacility[];
      } else {
        choices = this.allFacilities;
      }

      if (this.mustAcceptTransfers) {
        choices = choices.filter((f) => f.acceptsTransfers);
      }

      if (!this.showHidden) {
        choices = choices.filter((f) => !f.hidden);
      }

      if (this.mustHavePermission) {
        const allowedIDs = this.entityIDsWithPermission(
          this.mustHavePermission,
        );
        const hasNone = allowedIDs.length < 1;
        const hasAll = _.includes(allowedIDs, '*');

        if (hasNone) {
          // If no allowed IDs, the list should be empty
          choices = [];
        } else if (!hasAll) {
          choices = _.filter(choices, (f) => {
            return _.includes(allowedIDs, f.id);
          });
        }
      }

      return _.chain(choices)
        .reject((f) => this.excludedIDs.includes(f.id))
        .sortBy((f) => f.name[this.locale])
        .value();
    },
  },
  methods: {
    ...mapActions('housing', ['fetchFacilities']),
    facilityChanged(newVal) {
      this.$emit('input', newVal);
    },
    async fetchData(): Promise<void> {
      this.loading = true;

      await Promise.all([
        this.$store.dispatch('housing/fetchFacilities'),
        this.$store.dispatch('housing/fetchFacility', {
          facilityID: this.value,
        }),
      ]);

      this.loading = false;
    },
  },
});
