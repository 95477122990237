


































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { AfghanistanID, Geography } from '@/vuex/geography/geography';

import NewGeographyDialog from '@/components/geography/NewGeographyDialog.vue';
import EditGeographyDialog from '@/components/geography/EditGeographyDialog.vue';

export default Vue.extend({
  data() {
    return {
      activeGeographyID: AfghanistanID,
      search: '',
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('geography', ['geographiesWithParentID']),
    ...mapGetters('auth', ['hasPermission']),

    /**
     * geographies computes the list of Geography entities which are children
     * of whatever level is selected. If no geography is selected, it retrieves
     * all top-level geographies (those with parentID == null).
     */
    geographies(): Geography[] {
      let parentID: number | null = this.activeGeographyID;
      if (!this.activeGeographyID) {
        parentID = null;
      }
      return this.geographiesWithParentID(parentID);
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a computed property so
     * that it will update with new translations when the locale changes.
     */
    headers(): any[] {
      const h = [
        {
          text: i18n.t('command.edit'),
          value: 'edit',
          width: '30px',
          align: 'center',
          sortable: false,
        },
        {
          text: i18n.t('id'),
          value: 'id',
          width: '30px',
          align: 'right',
        },
        {
          text: this.$i18n.t('geography.name'),
          value: `name.${this.locale}`,
        },
        {
          text: i18n.t('geography.type'),
          value: 'type',
        },
        {
          text: i18n.t('geography.code'),
          value: 'code',
          align: 'center',
        },
        {
          text: i18n.t('geography.iso3166Code'),
          value: 'iso3166Code',
          align: 'center',
        },
      ];
      return h;
    },

    /**
     * mostCommonType returns the most commonly-occurring geography type in the
     * list of Geography entities displayed in the table.
     */
    mostCommonType(): string {
      const types = _.map(this.geographies || [], 'type') as string[];
      const geo = _.chain(types)
        .countBy() // Produces { "province": 14, "district": 1 }
        .entries() // Converts to [["province", 14], ["district", 10]]
        .maxBy(_.last) // Filters to ["province", 14]
        .head() // Retrieves first element ("province")
        .value() as string; // Exit lodash chain and type-assert to string
      return geo || 'country';
    },
  },

  components: {
    EditGeographyDialog,
    NewGeographyDialog,
  },
});
