import _ from 'lodash';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Transfer {
  id: number;
  offenderID: number;
  scheduledDepartureAt: string;
  estimatedDurationInHours: number;
  estimatedArrivalAt: number;
  cancelDate?: string;
  cancelReason?: string;
  status: string;
  fromHousingFacilityID: number;
  toHousingFacilityID: number;
  fromCellID: number;
  toCellID: number;
  approvedAt: string;
  departedAt: string;
  arrivedAt: number;
  durationInHours: number;
  reasonID?: number;
  notes?: string;
  pin: string;
  din: string;
  isDetainee: boolean;
}

export const enum TransferStatus {
  Pending = 'pending',
  Scheduled = 'scheduled',
  InTransit = 'in-transit',
  Denied = 'denied',
  Canceled = 'canceled',
  Aborted = 'aborted',
  Completed = 'completed',
}

export const TransferStatuses = [
  TransferStatus.Pending,
  TransferStatus.Scheduled,
  TransferStatus.InTransit,
  TransferStatus.Denied,
  TransferStatus.Canceled,
  TransferStatus.Aborted,
  TransferStatus.Completed,
];

export const ActiveTransferStatuses = [
  TransferStatus.Pending,
  TransferStatus.Scheduled,
  TransferStatus.InTransit,
];

export const CancelableTransferStatuses = [
  TransferStatus.Pending,
  TransferStatus.Scheduled,
];

export const state = {
  transfer: {} as { [id: number]: Transfer },
  transferIDsForOffender: {} as { [offenderID: number]: number[] },
  transferIDsForFacility: {} as { [facilityID: number]: number[] },
};

export const getters = {
  transferWithID:
    (state) =>
    (transferID: number): Transfer => {
      return state.transfer[transferID];
    },
  transfersForOffender:
    (state) =>
    (offenderID: number): Transfer[] => {
      return (state.transferIDsForOffender[offenderID] || []).map(
        (id: number) => state.transfer[id],
      ) as Transfer[];
    },
  transfersForFacility:
    (state) =>
    (facilityID: number): Transfer[] => {
      return (state.transferIDsForFacility[facilityID] || []).map(
        (id: number) => state.transfer[id],
      ) as Transfer[];
    },
};

export const mutations = {};

export const actions = {
  async fetchTransfers({ commit }, payload: { offenderID: number }) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/transfers`,
    );
    const transfers = response.data.data;
    const newState = modelsToState('transfer', transfers);
    commit('setState', newState);
    commit('setTarget', {
      target: 'transferIDsForOffender',
      index: payload.offenderID,
      value: _.map(transfers, 'id'),
    });
  },
  async fetchActiveTransfersForFacility({ commit }, payload: { id: number }) {
    const response = await offenderAPI.get(
      `facilities/${payload.id}/active-transfers`,
    );
    const transfers = response.data.data;
    const newState = modelsToState('transfer', transfers);
    commit('setState', newState);
    commit('setTarget', {
      target: 'transferIDsForFacility',
      index: payload.id,
      value: _.map(transfers, 'id'),
    });
  },
};
