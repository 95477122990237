

















import Vue from 'vue';
import { mapGetters } from 'vuex';

import SubjectPropertySelector from '@/components/subjectProperty/SubjectPropertySelector.vue';
import SubjectPropertyForm from '@/components/subjectProperty/SubjectPropertyForm.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
    value: Object,
    rules: Array,
  },

  data() {
    return {
      selectedID: 0,
    };
  },

  computed: {
    ...mapGetters('court', ['subjectPropertyWithID']),
  },

  watch: {
    value(newVal: any): void {
      if (!newVal) {
        this.selectedID = 0;
      }
    },

    selectedID(id: number): void {
      // If no property is selected for editing, blank the data.
      if (!id) {
        this.$emit('input', null);
      }

      // Make a local copy so that modifications in the form are not
      // immediately applied to Vuex.
      const localCopy = Object.assign({}, this.subjectPropertyWithID(id));
      this.$emit('input', localCopy);
    },
  },

  components: {
    SubjectPropertyForm,
    SubjectPropertySelector,
  },
});
