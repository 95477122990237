
























import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { HousingFacility } from '@/vuex/housing/housing';
import { TUnknown } from '@/lib/translated';

/**
 * CellSelector provides a v-model-compatible interface to select the ID
 * for a "cell" (a HousingFacility structure which is hierarchically beneath
 * some other facility).
 *
 * Example usage:
 *
 * <CellSelector
 *  label="Select a Cell"
 *  :facilityID="offender.housingFacilityID"
 *  v-model="offender.cellID"/>
 *
 */
export default Vue.extend({
  props: {
    /**
     * label will be displayed above all of the cell selection dropdowns.
     */
    label: String,

    /**
     * rules are passed-through to each v-autocomplete (dropdown) inside
     * the CellSelector.
     *
     * TODO - Conceptually: we should only be enforcing rules on the last
     * dropdown... its' the one that controls the Form Input Value of this
     * control. However, it seems like our current usage only ever triggers
     * validations on the last component anyway... so it may be a non-issue.
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * show-hidden will be used to show facilities _even if_ they are
     * hidden == true. Useful when showing where an offender current resides
     * because he/she may be in a hidden facility
     */
    showHidden: {
      type: Boolean,
      default: false,
    },

    /**
     * disabled is passed-through to each v-autocomplete (dropdown) inside
     * the CellSelector.
     *
     * Set true to display the current value but not allow the user to
     * change it.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * value makes this component compatible with Vue's Form Input Bindings
     * and the v-model attribute (see: https://vuejs.org/v2/guide/forms.html).
     */
    value: {
      type: Number,
      required: true,
    },

    /**
     * facilityID is required. It scopes the selection down to the referenced
     * facilty's children.
     */
    facilityID: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.valueChangedFromOutside(this.value);
  },
  data() {
    return {
      types: [] as string[],
      selections: [0] as number[],
      choices: [] as HousingFacility[][],
    };
  },
  watch: {
    facilityID() {
      /**
       * All previous choices are made invalid when the facilityID prop is
       * changed at any time after initialization, so we reset all existing
       * choices and rebuild the controls.
       */
      this.selections = [0];
      this.selectionChanged(0);
    },
    value(newID) {
      this.valueChangedFromOutside(newID);
    },
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('housing', [
      'ancestorIDs',
      'facilityWithID',
      'facilityChildren',
      'facilityVisibleChildren',
    ]),
  },
  methods: {
    /**
     * selectionChanged handles changes made by a user to one of the internal
     * dropdowns.
     */
    selectionChanged(controlIndex: number) {
      // A change made to a "higher-level" control will invalidate selections
      // made in "lower-level" controls.
      //
      // For example, if we have: Block 1 - Floor 1...
      // and we change to         Block 2...
      // Then "Floor 1" is no longer a valid selection.
      //
      // To represent this we throw away higher-indexed selections
      // by resetting the arrays length
      this.selections.length = controlIndex + 1;

      // Since lower-level values have been removed, we need to
      // broadcast a change in the value out
      this.$emit('input', _.last(this.selections) || 0);

      // Then ensure the lower-level controls are rebuilt
      this.buildControls();
    },

    /**
     * valueChangedFromOutside handles a change to the cell value coming
     * from outside the component. It's guaranteed to fire at least
     * once with the value/v-model prop when the component is first created. It
     * will fire additional times when that prop is changed later.
     *
     * This method's primary purpose is to find the facility IDs of each
     * ancestor and store it in the selections array. If we have a simple
     * 3-level structure 1 > 2 > 3, and the value is set to 3, then this
     * function causes this.selections = [1,2,3] instead of [3].
     *
     * NOTE: This function ensures that the facilityID not be one of the
     * selections, so in the 1 > 2 > 3 example above, if "1" is the facility,
     * then this.selections = [2,3].
     */
    async valueChangedFromOutside(newID: number): Promise<void> {
      await this.$store.dispatch('housing/fetchFacility', {
        facilityID: newID,
        force: false,
      });
      const ancestorIDs = this.ancestorIDs(newID);
      const facilityIndex = ancestorIDs.indexOf(this.facilityID);
      if (facilityIndex < 0) {
        // Reject the provided value because it's not inside the bound
        // facility.
        this.selections = [0];
        this.$emit('input', 0);
      } else {
        // Remove the facility from the list of ancestors, and use the rest
        // of the values as selection values for the dropdowns.
        this.selections = ancestorIDs.slice(
          facilityIndex + 1,
          ancestorIDs.length,
        );
      }

      // Ensure the dropdown options match the proovded parent IDs
      this.buildControls();
    },

    /**
     * buildControls is responsible for assembling the choice values for each
     * of the dropdowns in the selector.
     */
    async buildControls(): Promise<void> {
      // Before we hit this point, we depend on valueChangedFromOutside
      // setting the selections array with the currently-selected value
      // and all of its ancestors.
      const facility = this.facilityWithID(this.facilityID);
      const parentIDs = [this.facilityID, ...this.selections];

      // First we ensure the children for each referenced facility are
      // loaded. The Vuex action ensures prior API calls aren't repeated
      // (in other words, previously-fetched children are cached), so
      // these promises should return very quickly for previously-
      // encountered facilities.
      await Promise.all(
        parentIDs.map((id) => {
          return this.$store.dispatch('housing/fetchFacilityChildren', {
            force: false,
            parentID: id,
          });
        }),
      );

      const newChoices = [] as HousingFacility[][];
      const newTypes = [] as string[];

      // Given that the children fetches completed successfully,
      // we can loop over the ancestors to build an array of child
      // choices for each one.
      for (const parentID of parentIDs) {
        let children = [] as HousingFacility[];
        if (this.showHidden) {
          children = this.facilityChildren(parentID);
        } else {
          children = this.facilityVisibleChildren(parentID);
        }

        // When no children are found, no control is needed
        if (children.length <= 0) {
          continue;
        }

        // Assume all the found children have the same type
        // This is needed to display a label for each dropdown (e.g. "Block")
        const childType = _.last(children)!.type;
        newTypes.push(childType);

        // Add a choice for "Unknown" at this level
        const parent = this.facilityWithID(parentID);
        const uc = this.unknownChild(parent);
        uc.type = childType;
        children.unshift(this.unknownChild(parent));

        newChoices.push(children);
      }

      // Special Case: none of the parentIDs actually had children.
      //
      if (newChoices.length === 0) {
        // If we ended up with no children at any level, then we need to
        // render a single dropdown with an unknown choice in it.

        // Build an unknown child. Arbitrarily say it's a 'block' type
        const uc = this.unknownChild(facility);
        const childType = 'block';
        uc.type = childType;

        // Add it to the type and choices arrays.
        // (these choice be the only values in them)
        newTypes.push(childType);
        newChoices.push([uc]);
      }

      // Pre-select the Unknown choice at each level where a selection
      // is not already defined.
      while (newChoices.length > this.selections.length) {
        this.selections.push(0);
      }

      this.choices = newChoices;
      this.types = newTypes;
    },

    /**
     * unknownChild is a utility function which creates a fake HousingFacility
     * to represent an Unknown choice at a particular level in the hierarchy.
     */
    unknownChild(parent: HousingFacility): HousingFacility {
      const child = Object.assign({}, parent);
      child.id = 0;
      child.name = TUnknown;
      return child;
    },
  },
});
