


















































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Dossier } from '@/vuex/court/dossier';
import { Offender } from '@/vuex/offender/offender';
import { Participant } from '@/vuex/court/participant';
import { Profile } from '@/vuex/profile/profile';

import ChargesTable from '@/components/charges/ChargesTable.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('offender', ['offenderWithID', 'offenderIsReleased']),
    ...mapGetters('court', ['participantInDossier', 'dossierWithID']),
    ...mapGetters('profile', ['profileWithID']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    profile(): Profile {
      return this.profileWithID(this.offender.profileID);
    },

    participant(): Participant {
      return this.participantInDossier(
        this.offender.dossierID,
        this.profile.partyID,
      );
    },

    dossier(): Dossier {
      return this.dossierWithID(this.offender.dossierID);
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },
  },
  components: {
    ChargesTable,
  },
});
