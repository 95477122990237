

































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';

import SentenceComponentDescription from '@/components/enforcement/SentenceComponentDescription.vue';

export default Vue.extend({
  props: {
    sentenceComponent: Object,
  },
  data() {
    return {
      isFulfilled: false,
      isOpen: false,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['subjectPropertyName']),
    ...mapState('crime', ['punishmentTypes']),
    /**
     * checkColor provides the color of the check for this value: green
     * if isFulfilled = true, grey if isFulfilled = false
     */
    checkColor(): string {
      if (this.sentenceComponent.isFulfilled) {
        return 'green';
      }
      return 'grey lighten-2';
    },
  },

  methods: {
    /**
     * reset is our event handler for CMSFormDialog's reset event. It fires when
     * the dialog is activated. We use it to reset the form.
     */
    reset(): void {
      if (this.sentenceComponent) {
        this.isFulfilled = this.sentenceComponent.isFulfilled;
      } else {
        this.isFulfilled = false;
      }
    },
    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We use it to submit the
     * completion event to the server.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post('/sentences/components/fulfill', {
          data: {
            sentenceComponentID: this.sentenceComponent.id,
            dossierID: this.sentenceComponent.dossierID,
            // caseID is excluded intentionally to keep this action off the Case
            // Docket and treat it like a Dossier docket event.
            // caseID: this.sentenceComponent.caseID,
            partyID: this.sentenceComponent.partyID,
            punishmentTypeID: this.sentenceComponent.punishmentTypeID,
            categoryID: this.sentenceComponent.categoryID,
            durationInHours: this.sentenceComponent.durationInHours,
            amountInPuls: this.sentenceComponent.amountInPuls,
            notes: this.sentenceComponent.notes,
            subjectPropertyIDs: this.sentenceComponent.subjectPropertyIDs,
            isConsequential: this.sentenceComponent.isConsequential,
            isFulfilled: this.isFulfilled,
          },
        });

        // The entire Participant is returned with all up-to-date data...
        const newState = response.data.data;

        // ... we update local state.
        this.$store.commit('court/setState', newState);

        // ... and close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    SentenceComponentDescription,
  },
});
