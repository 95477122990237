














import Vue from 'vue';
import i18n from '@/i18n';
import { mapState } from 'vuex';

export default Vue.extend({
  props: {
    label: String,
    value: [String, Array],
    disabled: Boolean,
    multiple: Boolean,
    chips: Boolean,
    clearable: Boolean,
    deletableChips: Boolean,
  },
  computed: {
    ...mapState('offender', ['custodyStatuses']),
    custodyStatusChoices(): any[] {
      return this.custodyStatuses.map((s) => ({
        value: s,
        text: i18n.t('offender.custodyStatus.' + s),
      }));
    },
  },
});
