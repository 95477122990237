









import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  props: {
    /**
     * freedAt should be given the same-named value from Offender entities.
     * If this prop is not empty, this value will be displayed in the component,
     * as we don't want to show a past release
     */
    freedAt: String,

    /**
     * expectedReleaseAt should be given the same-named value from Offender
     * entities. It will be displayed only when freedAt is not provided.
     */
    expectedReleaseAt: String,

    /**
     * warningChipOnly should be set to true when using this component in
     * a standalone place where it is the only ReleaseDate component in an area
     * of the page. This will make the text show as a simple <span> unless a
     * warning is triggered. This avoids drawing attention to the component
     * when its not needed. This should not be used in table cells (<td>).
     * When an entire column contains release dates, it looks more pleasing
     * if they are all formatted the same, with chip colors indicating the
     * level of warning.
     */
    warningChipOnly: {
      type: Boolean,
      default: false,
    },

    small: Boolean,
  },
  computed: {
    /**
     * displayDate computes which of the date props is displayed. The freedAt
     * date takes precedence over the expectedReleaseAt date.
     */
    displayDate(): string {
      if (this.freedAt) {
        return this.freedAt;
      }
      return this.expectedReleaseAt;
    },

    /**
     * useChip computes whether the component should display as a v-chip or
     * not.
     */
    useChip(): boolean {
      if (!this.warningChipOnly) {
        return true;
      }
      if (this.alreadyReleased || this.far) {
        return false;
      }
      return this.past || this.near;
    },

    /**
     * daysUntilRelease computes how many days away the expectedReleaseAt date
     * is. It will return a negative value if the expected release date is
     * in the past.
     */
    daysUntilRelease(): number {
      const today = moment();
      const expectedRelease = moment(this.expectedReleaseAt);
      return expectedRelease.diff(today, 'days');
    },

    /**
     * alreadyReleased returns true when the freedAt date has been provided. This is
     * needed to disable the chip coloring since the release date is in the
     * past, but should not present a warning.
     */
    alreadyReleased(): boolean {
      return !!this.freedAt;
    },

    /**
     * past returns true when the expectedReleaseAt date has already past.
     * This triggers the most severe warning.
     */
    past(): boolean {
      return this.daysUntilRelease <= 0;
    },

    /**
     * near returns true when the expectedReleaseAt date is approaching. It
     * triggers a slightly less severe warning.
     */
    near(): boolean {
      return this.daysUntilRelease < 15;
    },

    /**
     * far returns true when the expectedReleaseAt date is far enough in the
     * future to not need any warnings.
     */
    far(): boolean {
      return this.daysUntilRelease >= 15;
    },

    /**
     * chipClass computes the CSS class names to be applied to the v-chip based
     * on the warning properties.
     */
    chipClass(): string {
      if (this.alreadyReleased) {
        return 'invisible text--disabled';
      } else if (this.past) {
        return 'error';
      } else if (this.near) {
        return 'warning';
      } else {
        return '';
      }
    },
  },
});
