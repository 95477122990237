import _ from 'lodash';

import crimeAPI from '@/vuex/crime/crimeAPI';
import { domainmodelMutations, modelsToState } from '@/lib/vuex-domainmodel';
import { LookupTableRow } from '@/vuex/language/language';
import { TranslatedString } from '@/lib/translated';

export const enum CompletionStatusID {
  Unknown = 0,
  Completed = 1,
  Conspiracy = 2,
  Attempt = 3,
}

export const enum ParticipationTypeID {
  Unknown = 0,
  PrincipalOffender = 1,
  Accessory = 2,
  Accomplice = 3,
  CriminalOrganizer = 4,
}

export const enum CrimeSeverityID {
  Unknown = 0,
  PettyCrime = 1,
  Misdemeanor = 2,
  Felony = 3,
}

export interface Crime {
  id: number;
  title: TranslatedString;
  text?: TranslatedString;

  article: number;
  relatedArticles: number[];
  corpusID: number;

  validSeverityIDs: number[];
  validParticipationTypeIDs: number[];
  validPunishmentTypeIDs: number[];
  validImprisonmentDurationIDs: number[];
  validCompletionStatusIDs: number[];
  triggersConsequentialPunishments: boolean;

  minimumFineInPuls?: number;
  maximumFineInPuls?: number;

  aggravators: TranslatedString[];
  mitigators: TranslatedString[];

  legacyCrimeTypeIDs: number[];

  lastReviewedAt: string;
  lastReviewedByUserID?: number;
  lastApprovedAt: string;
  lastApprovedByUserID?: number;

  lastGiroaReviewAt: string;
  lastGiroaReviewByUserID?: number;
  lastGiroaApprovedAt: string;
  lastGiroaApprovedByUserID?: number;

  isNonchargeable: boolean;
}

export interface CrimeGroup {
  id: number;
  name: TranslatedString;
  crimeIDs: number[];
}

export interface LegacyCrime {
  legacyID: number;
  legacyName: TranslatedString[];
  legacyLawID: number;
  legacyLawName: TranslatedString[];
  legacyCategoryID: number;
  legacyCategoryName: TranslatedString[];
  crimeID: number;
  participationTypeID: number;
  completionStatusID: number;
  severityID: number;
  generalAggravatorIDs: number[];
  generalMitigatorIDs: number[];
  lastReviewedAt: string;
  lastReviewedByUserID: number;
  lastApprovedAt: string;
  lastApprovedByUserID: number;
  lastGiroaReviewAt: string;
  lastGiroaReviewByUserID: number;
  lastGiroaApprovedAt: string;
  lastGiroaApprovedByUserID: number;
}

export interface CompletionStatus {
  id: number;
  rank: number;
  name: TranslatedString;
  isInchoate: boolean;
}

export interface PunishmentType {
  id: number;
  categoryID: number;
  name: TranslatedString;
  isComplementary: boolean;
  isConsequential: boolean;
  isSafetyMeasure: boolean;
  isATI: boolean;
  isImprisonment: boolean;
  isCashFine: boolean;
  canHaveAmount: boolean;
  requiresAmount: boolean;
  canHaveDuration: boolean;
  requiresDuration: boolean;
  requiresNotes: boolean;
  canReferenceSubjectProperty: boolean;
  trackFulfillment: boolean;
  rank: number;

  // Pseudo (non-database) attributes
  isValid?: boolean;
}

export interface ImprisonmentDuration {
  id: number;
  rank: number;
  name: TranslatedString;
  minimumHours: number;
  maximumHours: number;
}

const state = {
  crimesLoaded: false,
  crimeIDs: [] as number[],
  crime: {} as { [id: number]: Crime },

  crimeGroupsLoaded: false,
  crimeGroupIDs: [] as number[],
  crimeGroup: {} as { [id: number]: CrimeGroup },

  legacyCrimesLoaded: false,
  legacyCrimeLegacyIDs: [],
  legacyCrime: {} as { [legacyID: number]: LegacyCrime },

  optionsLoaded: false,
  completionStatuses: [] as CompletionStatus[],
  consequentialPunishments: [] as LookupTableRow[],
  imprisonmentDurations: [] as ImprisonmentDuration[],
  participationTypes: [] as LookupTableRow[],
  punishmentTypes: [] as PunishmentType[],
  punishmentTypeCategories: [] as LookupTableRow[],
  severities: [] as LookupTableRow[],
};

const getters = {
  /**
   * allCrimes retrieves the entire list of Crimes which have beeen fetched
   * from the server.
   */
  allCrimes(state): Crime[] {
    return state.crimeIDs.map((id) => state.crime[id]);
  },

  /**
   * crimeWithID returns a Crime entity from the Vuex store based on its id.
   */
  crimeWithID:
    (state) =>
    (id): Crime => {
      return state.crime[id] as Crime;
    },

  /**
   * crimesWithCorpusID retrieves all Crime entities from the Vuex store which
   * have the provided CorpusID.
   */
  crimesWithCorpusID:
    (state) =>
    (corpusID: number): Crime[] => {
      return state.crimeIDs
        .map((id) => state.crime[id])
        .filter((crime: Crime) => crime.corpusID === corpusID);
    },

  /**
   * allCrimeGroups returns all CrimeGroup entities which exist in the Vuex
   * store.
   */
  allCrimeGroups(statex): CrimeGroup[] {
    return _.map(state.crimeGroupIDs, (id: number) => state.crimeGroup[id]);
  },

  /**
   * crimeGroupWithID retrieves a single CrimeGroup from the Vuex store by
   * its id.
   */
  crimeGroupWithID:
    (state) =>
    (id: number): CrimeGroup | null => {
      return state.crimeGroup[id] || null;
    },

  /**
   * allLegacyCrimes retrieves the entire list of LegacyCrime entities that
   * exist in the Vuex store.
   */
  allLegacyCrimes(state): LegacyCrime[] {
    return state.legacyCrimeLegacyIDs.map(
      (legacyID) => state.legacyCrime[legacyID],
    );
  },

  /**
   * legacyCrimeWithLegacyID retrieves a single LegacyCrime entity from the Vuex
   * store by its legacyID.
   */
  legacyCrimeWithLegacyID:
    (state) =>
    (legacyID): LegacyCrime => {
      return state.legacyCrime[legacyID] as LegacyCrime;
    },

  /**
   * legacyCromesWithCrimeID retrieves all LegacyCrime entities which map to the
   * CMS 2 Crime with the provided id.
   */
  legacyCrimesWithCrimeID:
    (state, getters) =>
    (crimeID: number): LegacyCrime[] => {
      return getters.allLegacyCrimes.filter((lc: LegacyCrime) => {
        return lc.crimeID === crimeID;
      });
    },

  /**
   * punishmentTypeWithID retrieves the PunishmentType entity with the provided
   * id.
   */
  punishmentTypeWithID:
    (state) =>
    (id: number): PunishmentType => {
      return _.find(state.punishmentTypes, (pt) => pt.id === id);
    },

  /**
   * punishmentTypeCategoryWithID retrieves the punishment type category with
   * the provided id.
   */
  punishmentTypeCategoryWithID:
    (state) =>
    (id: number): LookupTableRow => {
      return _.find(state.punishmentTypeCategories, (c) => c.id === id);
    },

  /**
   * imprisonmentDurationWithID retrieves the ImprisonmentDuration entity with
   * the provided id.
   */
  imprisonmentDurationWithID:
    (state) =>
    (id): ImprisonmentDuration => {
      return _.find(state.imprisonmentDurations, (d) => d.id === id);
    },
};

const mutations = {
  ...domainmodelMutations,

  /**
   * setCrimesLoaded sets the tracking variable to indicate whether the crime
   * list has already been fetched.
   */
  setCrimesLoaded(state, val: boolean) {
    state.crimesLoaded = val;
  },

  /**
   * setCrimeGroupsLoaded sets the tracking variable to indicate whether the
   * list of crime groups has already been fetched.
   */
  setCrimeGroupsLoaded(state, val: boolean) {
    state.crimeGroupsLoaded = val;
  },

  /**
   * setLegacyCrimesLoaded sets the tracking variable to indicate whether the
   * legacy crime list has already been fetched.
   */
  setLegacyCrimesLoaded(state, val: boolean) {
    state.legacyCrimesLoaded = val;
  },

  /**
   * setOptionsLoaded sets the tracking variable to indicate whether the
   * options list has already been fetched.
   */
  setOptionsLoaded(state, val: boolean) {
    state.optionsLoaded = val;
  },
};

const actions = {
  /**
   * fetchOptions retrieves all dropdown options for crime-related data.
   *
   * This function returns immediately if the options have already been
   * fetched.
   */
  async fetchOptions(
    { commit, state },
    payload = {} as { force: boolean },
  ): Promise<boolean> {
    if (state.optionsLoaded && !payload.force) {
      return Promise.resolve(true);
    }
    const response = await crimeAPI.get('/options');
    if (response.data && response.data.data) {
      const newState = response.data.data;
      // Set each option array in the state. This will throw
      // an error if the server sends any top-level
      // properties which are not initialized to their
      // initial state (typically an empty array) above in
      // the state definition.
      commit('setState', newState);
      commit('setOptionsLoaded', true);
      return Promise.resolve(true);
    }

    // We reach here only in case of failed response
    return Promise.reject();
  },

  /**
   * fetchCrimes retrieves all crime types, but OMITS the .text, .aggravators
   * and .mitigators fields to save space. The EditCrime screen calls
   * fetchCrime to get those fields loaded before editing.
   *
   * By default, this function returns immediately if the crimes have
   * already been fetched. That can be overridden by setting force = true.
   */
  async fetchCrimes({ commit, state }, force: boolean): Promise<void> {
    if (!force && state.crimesLoaded) {
      return Promise.resolve();
    }
    const response = await crimeAPI.get(`/crimes`);
    const crimes = response.data.data;
    const newState = modelsToState('crime', crimes);
    commit('setState', newState);
    commit('setTarget', {
      target: 'crimeIDs',
      value: _.map(crimes, 'id'),
    });
    commit('setCrimesLoaded', true);
  },

  /**
   * fetchCrime force updates the Vuex state for a single Crime with the
   * current server state of the data for that Crime. Calling this is
   * necessary to get the .text, .aggravators and .mitigators fields
   * because fetchCrimes omits those to save space on startup.
   */
  async fetchCrime({ commit }, id: number): Promise<void> {
    const response = await crimeAPI.get(`/crimes/${id}`);
    const newState = modelsToState('crime', response.data.data);
    commit('setState', newState);
  },

  /**
   * fetchLegacyCrimes retrieves all legacy crime types.
   * By default, this function returns immediately if the legacy crimes have
   * already been fetched. That can be overridden by setting force = true.
   */
  async fetchLegacyCrimes({ commit, state }, force: boolean): Promise<void> {
    if (!force && state.crimesLoaded) {
      return Promise.resolve();
    }
    const response = await crimeAPI.get(`/legacy-crimes`);
    const legacyCrimes = response.data.data;
    const newState = modelsToState('legacyCrime', legacyCrimes, 'legacyID');
    commit('setState', newState);
    commit('setTarget', {
      target: 'legacyCrimeLegacyIDs',
      value: _.map(legacyCrimes, 'legacyID'),
    });
    commit('setLegacyCrimesLoaded', true);
  },

  /**
   * fetchLegacyCrime force-updates the Vuex state for a single legacyCrime
   */
  async fetchLegacyCrime({ commit }, legacyID: number): Promise<void> {
    const response = await crimeAPI.get(`/legacy-crimes/${legacyID}`);
    const lcs = _.get(response, 'data.data', []);
    const newState = modelsToState('legacyCrime', lcs, 'legacyID');
    commit('setState', newState);
  },

  /**
   * fetchLegacyCrimesMappedTo force-updates the Vuex state for each LegacyCrime
   * which points to the CMS 2 crime referenced in the argument.
   */
  async fetchLegacyCrimesMappedTo({ commit }, crimeID: number): Promise<void> {
    const response = await crimeAPI.get(`/crimes/${crimeID}/legacy-crimes`);
    if (response.data && response.data.data) {
      const legacyCrimes = response.data.data;
      const newState = modelsToState('legacyCrime', legacyCrimes, 'legacyID');
      commit('setState', newState);
      commit('setTarget', {
        target: 'legacyCrimeLegacyIDs',
        value: _.map(legacyCrimes, 'legacyID'),
      });
    }
  },

  /**
   * fetchCrimeGroups fetches all CrimeGroup entities from the server and
   * stores them in the Vuex state.
   */
  async fetchCrimeGroups(
    { commit, state },
    payload = {} as { force: boolean },
  ): Promise<void> {
    if (!payload.force && state.crimeGroupsLoaded) {
      return Promise.resolve();
    }
    // Fetch all crimeGroups from the API
    const response = await crimeAPI.get('/crime-groups');
    const crimeGroups = _.get(response, 'data.data', []);
    const newState = modelsToState('crimeGroup', crimeGroups);

    commit('setState', newState);
    commit('setTarget', {
      target: 'crimeGroupIDs',
      value: _.map(crimeGroups, 'id'),
    });
    commit('setCrimeGroupsLoaded', true);
  },

  /**
   * fetchCrimeGroup fetches a single CrimeGroup entity from the server and
   * force-updates its corresponding record in the Vuex store.
   */
  async fetchCrimeGroup({ commit }, id: number) {
    const response = await crimeAPI.get(`/crime-groups/${id}`);
    const crimeGroup = response.data.data;

    const newState = modelsToState('crimeGroup', crimeGroup);
    commit('setState', newState);
    commit('setTarget', {
      target: 'crimeGroup',
      index: crimeGroup.id,
      value: crimeGroup,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
