
































import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Dossier } from '@/vuex/court/dossier';
import { Offender } from '@/vuex/offender/offender';
import { Profile } from '@/vuex/profile/profile';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data(): any {
    return {
      isOpen: false,
      dossierID: 0,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('court', ['dossiersForParty']),
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('profile', ['profileWithID']),
    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },
    profile(): Profile | null {
      if (this.offender && this.offender.profileID) {
        return this.profileWithID(this.offender.profileID);
      }
      return null;
    },
    partyID(): number {
      if (this.profile && this.profile.partyID) {
        return this.profile.partyID;
      }
      return 0;
    },
    dossiers(): Dossier[] {
      if (this.partyID) {
        return this.dossiersForParty(this.partyID);
      }
      return [];
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the dossierID to its default state.
     */
    async reset(): Promise<void> {
      if (this.partyID) {
        await this.$store.dispatch('court/fetchDossiersForParty', this.partyID);
        if (this.offender.dossierID) {
          // If the current Offender already has a DossierID assigned, don't
          // presume they are going to change it.
          this.dossierID = this.offender.dossierID;
        } else if (this.dossiers.length > 0) {
          // If there was 1 or more dossiers returned, go ahead and
          // preselect the first one for them.
          this.dossierID = this.dossiers[0].id;
        }
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('offenders/link-dossier', {
          data: {
            offenderID: this.offender.id,
            dossierID: this.dossierID,
          },
        });
        const newState = response.data.data;
        this.$store.commit('offender/setState', newState);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
