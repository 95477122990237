












import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import { TUnknown } from '@/lib/translated';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    value: Boolean,
    label: String,
    unknownLabel: String,
    trueLabel: String,
    falseLabel: String,
    readonly: Boolean,
    hint: String,
    persistentHint: Boolean,
    rules: Array,
    loading: Boolean,
    hideDetails: Boolean,
    excludeUnknown: Boolean,
  },
  data() {
    return {
      internalValue: 'null',
    };
  },
  computed: {
    ...mapGetters('language', ['valueForLocale']),

    items(): VSelectItem[] {
      return [
        {
          text: this.unknownLabel || this.valueForLocale(TUnknown),
          value: 'null',
        },
        { text: this.trueLabel || i18n.t('true').toString(), value: 'true' },
        { text: this.falseLabel || i18n.t('false').toString(), value: 'false' },
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal: any) {
        switch (newVal) {
          case null:
          case undefined:
            if (this.excludeUnknown) {
              this.internalValue = 'false';
              // Correct malformatted input
              this.$emit('input', false);
            } else {
              this.internalValue = 'null';
            }
            break;
          case 'false':
          case false:
            this.internalValue = 'false';
            break;
          default:
            this.internalValue = 'true';
        }
      },
    },
    internalValue(newInternalVal: string): void {
      switch (newInternalVal) {
        case 'true':
          this.$emit('input', true);
          break;
        case 'false':
          this.$emit('input', false);
          break;
        case 'null':
          this.$emit('input', null);
          break;
      }
    },
  },
});
