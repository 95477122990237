














import Vue from 'vue';

import { UserRole } from '@/vuex/user/user';
import UserRoleForm from '@/components/user/UserRoleForm.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      role: null as UserRole | null,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.role = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    save(): void {
      this.$emit('save', this.role);
      this.isOpen = false;
    },
  },
  components: {
    UserRoleForm,
  },
});
