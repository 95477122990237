







































import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString } from '@/lib/criteria';

import CrimeTitle from '@/components/crime/CrimeTitle.vue';

export default Vue.extend({
  props: {
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      results: [],
    };
  },
  computed: {
    headers(): any[] {
      return [
        { text: i18n.t('crime.singular'), value: 'crimeID', sortable: false },
        {
          text: 'Active Crime Reports',
          value: 'count',
          align: 'right',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const queryString = criteriaToQueryString(this.criteria);
        const url = `/crime-reports/by-crime?${queryString}`;
        const response = await investigationAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    CrimeTitle,
  },
});
