var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.fetchData()}}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","extension-height":_vm.dense ? '20px' : '40px'},scopedSlots:_vm._u([(_vm.allowAdding)?{key:"extension",fn:function(){return [(_vm.hasPermission('prosecutors.create'))?_c('AddProsecutorDialog',{on:{"saved":function($event){return _vm.afterProsecutorAdded($event)}}}):_vm._e()]},proxy:true}:null],null,true)},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('prosecutor.plural')))]),_c('v-spacer'),_c('v-text-field',{ref:"query",attrs:{"label":_vm.$t('search'),"single-line":"","hide-details":"","append-icon":"search","clearable":""},on:{"keyup":function($event){_vm.currentPage = 1;
          _vm.fetchData();},"click:clear":_vm.fetchData},model:{value:(_vm.query),callback:function ($$v) {_vm.query=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"query"}})],1)],1),_c('v-card-text',[_c('FormError',{attrs:{"error":_vm.error}}),_c('v-data-table',{attrs:{"items":_vm.prosecutors,"headers":_vm.headers,"disable-sort":"","loading":_vm.loading,"page":_vm.currentPage,"items-per-page":_vm.pageSize,"server-items-length":_vm.serverTotalResults,"footer-props":{
        'items-per-page-options': [10, 20, 40, 50, 100],
      },"item-class":function (item) { return (_vm.selectable ? 'selectable' : ''); }},on:{"update:page":[function($event){_vm.currentPage=$event},function($event){return _vm.fetchData()}],"update:itemsPerPage":function($event){_vm.pageSize=$event},"update:items-per-page":[function($event){_vm.pageSize=$event},function($event){return _vm.fetchData()}],"click:row":_vm.selectProsecutor},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('EditProsecutorDialog',{attrs:{"id":item.id}})]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prosecutorName(item.id))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }