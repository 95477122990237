import Vue from 'vue';
import Vuex from 'vuex';
import application from '@/vuex/application/application';
import attachment from '@/vuex/attachment/attachment';
import auth from '@/vuex/auth/auth';
import corpus from '@/vuex/corpus/corpus';
import crime from '@/vuex/crime/crime';
import geography from '@/vuex/geography/geography';
import housing from '@/vuex/housing/housing';
import language from '@/vuex/language/language';
import offender from '@/vuex/offender/offender';
import profile from '@/vuex/profile/profile';
import settings from '@/vuex/settings/settings';
import user from '@/vuex/user/user';
import court from '@/vuex/court/court';
import investigation from '@/vuex/investigation/investigation';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    application,
    attachment,
    auth,
    corpus,
    crime,
    geography,
    housing,
    language,
    offender,
    profile,
    settings,
    user,
    court,
    investigation,
  },
});

export default store;
