














import _ from 'lodash';
import Vue from 'vue';

import ChargeForm from './ChargeForm.vue';

export default Vue.extend({
  data() {
    return {
      charge: null,
      isOpen: false,
    };
  },

  methods: {
    reset(): void {
      this.charge = null;
    },

    save(): void {
      this.$emit('save', this.charge);
      this.isOpen = false;
    },
  },

  components: {
    ChargeForm,
  },
});
