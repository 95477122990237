









































import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import Vue from 'vue';

import i18n from '@/i18n';
import { Judge } from '@/vuex/court/judge';

import EditJudgeDialog from '@/components/court/judge/EditJudgeDialog.vue';
import NewJudgeDialog from '@/components/court/judge/NewJudgeDialog.vue';

export default Vue.extend({
  created(): void {
    this.$store.dispatch('court/fetchJudges', { force: false });
  },

  data() {
    return {
      search: '',
      showDeleted: false,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapState('court', ['courtStages']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', [
      'activeJurisdictionsForJudge',
      'allJudges',
      'judgeName',
    ]),
    ...mapGetters('geography', ['fullGeographyName']),

    canAdd(): boolean {
      return this.hasPermission('judge.create');
    },

    headers(): any[] {
      const h: any[] = [
        {
          text: i18n.t('person.name'),
          value: 'name',
        },
        {
          text: i18n.t('person.residence'),
          value: 'activeResidences',
        },
        {
          text: i18n.t('court.stage.singular'),
          value: 'activeStages',
        },
      ];
      if (this.canAdd) {
        h.unshift({
          text: i18n.t('command.edit'),
          value: 'id',
          width: '4em',
          sortable: false,
        });
      }
      return h;
    },

    judges(): any[] {
      let judges = this.allJudges;
      if (!this.showDeleted) {
        judges = judges.filter((j: Judge) => !j.isDeleted);
      }
      return judges.map((j: Judge) => {
        const name = this.judgeName(j.id);
        const js = this.activeJurisdictionsForJudge(j.id);
        const activeResidences = _.chain(js)
          .map('residenceGeographyID')
          .uniq()
          .map((id) => this.fullGeographyName(id))
          .value()
          .join(' / ');
        const activeStages = _.chain(js)
          .flatMap('stageIDs')
          .uniq()
          .map((id: number) => this.nameForIDInCollection(id, this.courtStages))
          .value()
          .join(', ');
        return Object.assign({}, j, {
          name,
          activeResidences,
          activeStages,
        });
      });
    },
  },

  components: {
    EditJudgeDialog,
    NewJudgeDialog,
  },
});
