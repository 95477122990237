

























import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import profileAPI from '@/vuex/profile/profileAPI';
import { Profile } from '@/vuex/profile/profile';

import ProfileForm from '@/components/profile/ProfileForm.vue';

export default Vue.extend({
  props: {
    profileID: Number,
    offenderID: Number,
  },
  data() {
    return {
      error: null as any,
      isOpen: false,
      profile: null as Profile | null,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('profile', ['profileWithID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    async reset(): Promise<void> {
      if (this.profileID) {
        const myCopy = Object.assign({}, this.profileWithID(this.profileID));
        this.profile = myCopy;
      } else {
        this.profile = null;
      }
      this.error = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.profile) {
        return;
      }
      try {
        this.error = null;
        const response = await profileAPI.post('profiles/update-profile', {
          data: {
            offenderID: this.offenderID || 0,
            profileID: this.profile.id,
            nationalityID: this.profile.nationalityID,
            birthdate: this.profile.birthdate,
            birthdateIsApproximate: this.profile.birthdateIsApproximate,
            heightInCm: this.profile.heightInCm,
            weightInKg: this.profile.weightInKg,
            hairColorID: this.profile.hairColorID,
            eyeColorID: this.profile.eyeColorID,
            genderID: this.profile.genderID,
            criminalGroupIDs: this.profile.criminalGroupIDs,
            criminalGroupRoleID: this.profile.criminalGroupRoleID,
            infectiousDiseaseIDs: this.profile.infectiousDiseaseIDs,
            mentalDisorderIDs: this.profile.mentalDisorderIDs,
            motherChildConcernIDs: this.profile.motherChildConcernIDs,
            bloodType: this.profile.bloodType,
            maritalStatusID: this.profile.maritalStatusID,
            numberOfChildren: this.profile.numberOfChildren,
            nativeLanguageID: this.profile.nativeLanguageID,
            secondLanguageID: this.profile.secondLanguageID,
            nationalID: this.profile.nationalID,
            eNationalID: this.profile.eNationalID,
            passportNumber: this.profile.passportNumber,
            phoneNumber: this.profile.phoneNumber,
            emailAddress: this.profile.emailAddress,
            isMilitary: this.profile.isMilitary,
            militaryRankID: this.profile.militaryRankID,
            occupation: this.profile.occupation,
            description: this.profile.description,
            educationLevelID: this.profile.educationLevelID,
            canRead: this.profile.canRead,
            canWrite: this.profile.canWrite,
            familyContact: this.profile.familyContact,
            visitors: this.profile.visitors,
            aliases: this.profile.aliases,
            isKochi: this.profile.isKochi,
          },
        });
        const combinedState = response.data.data;

        const offenderState = _.pick(combinedState, ['offender']);
        this.$store.commit('offender/setState', offenderState);

        const profileState = _.pick(combinedState, ['profile']);
        this.$store.commit('profile/setState', profileState);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    ProfileForm,
  },
});
