





















































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import PartySearcher from '@/components/court/party/PartySearcher.vue';

export default Vue.extend({
  props: {
    value: [Array, Number],

    // label is a passthrough to the v-autocomplete label prop
    label: String,

    // rules is a passthrough to the v-autocomplete rules prop
    rules: Array,

    // disabled is a passthrough to the v-autocomplete disabled prop
    disabled: Boolean,

    // value is the prop that makes this component work with v-model
    clearable: Boolean,

    // multiple specifies if selection is a single party ID or an array
    // of IDs
    multiple: Boolean,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  watch: {
    isOpen(newVal: boolean) {
      this.$nextTick(() => {
        (this.$refs.partySearcher as any).reset();
      });
    },
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('court', ['partyName']),
    currentPartyName(): string {
      // Convert value to an array of IDs, which might be empty
      const ids = _.flatten([this.value || []]);
      // Map the ids into party names, and join into a comma-separated string
      return _.map(ids, this.partyName).join(', ');
    },
  },

  methods: {
    /**
     * clearSelection is the event handler for the textfield's clear button
     * We use it to reset the data-bound value to its zero value. In
     * single mode, this is the number 0. In multiple mode, this is an empty
     * array.
     */
    clearSelection(): void {
      if (!this.multiple) {
        this.$emit('input', 0);
      } else {
        this.$emit('input', []);
      }
    },

    /**
     * makeSelection is the event handler for the user either clicking-on
     * or finishing the creation of an entity. In both cases, that ID becomes
     * part of the selection. In multiple mode, we add it to the array.
     * In single mode we set the value directly.
     */
    makeSelection(selectedID: any): void {
      if (!this.multiple) {
        this.$emit('input', selectedID);
        // We close immediately after selection in single-select mode
        this.isOpen = false;
        return;
      }
      const existingIDs = _.flatten([this.value || []]);
      const newIDs = _.uniq([...existingIDs, selectedID]);
      this.$emit('input', newIDs);
    },

    /**
     * removeSelectionAtIndex is the event handler for the X (remove) button
     * on the chips of selected items in multiple mode. We use it to splice
     * out the clicked item from the values and re-emit the updated value.
     */
    removeSelectionAtIndex(i: number): void {
      const existingIDs = _.flatten([this.value || []]);
      const newIDs = [...existingIDs];
      newIDs.splice(i, 1);
      this.$emit('input', newIDs);
    },
  },
  components: {
    PartySearcher,
  },
});
