




















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Summons } from '@/vuex/court/summons';

import RecordServiceDetails from '@/components/court/summons/RecordServiceDetails.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },

  computed: {
    ...mapGetters('court', ['summonsWithID']),

    summons(): Summons {
      const id = _.get(this, 'entry.affectedRecords.summonsIDs[0]', 0);
      return this.summonsWithID(id);
    },
  },

  components: {
    RecordServiceDetails,
  },
});
