
























import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';

import { mapGetters } from 'vuex';
import { Participant } from '@/vuex/court/participant';

import PartySelector from '@/components/court/party/PartySelector.vue';

export default Vue.extend({
  props: {
    caseID: Number,
    dossierID: Number,
    value: Object,
  },
  data() {
    return {
      partyID: 0,
    };
  },
  computed: {
    ...mapGetters('court', ['participantInDossierOrCase']),
    participant(): Participant | null {
      return this.value ? this.value : null;
    },
  },
  watch: {
    /**
     * dossierID is watched so that any previously-selected party can be
     * cleared out, in case that party is not on the new dossier.
     */
    dossierID() {
      this.partyID = 0;
    },
    /**
     * caseID is watched so that any previously-selected party can be
     * cleared out, in case that party is not on the new case.
     */
    caseID() {
      this.partyID = 0;
    },
    /**
     * value is watched so that we can externally bind to a Participant
     * object provided externally. The internal partyID needs to be set
     * to match the newly-provided Participant.
     */
    value(newVal: Participant | null) {
      if (!newVal) {
        this.partyID = 0;
      } else if (newVal.partyID && newVal.partyID !== this.partyID) {
        this.partyID = newVal.partyID;
      }
    },
  },
  methods: {
    /**
     * partyIDChanged is the event handler for a new choice being made on the
     * PartySelector dropdown control. When the partyID is changed, we need
     * to fetch the matching Participant and $emit() it, so that it returns
     * as our value prop. This $emit loop is what causes the BooleanSelector
     * to be initialized to the correct/current value of
     * participant.isDecisionAccepted at the moment a new Party is selected.
     */
    partyIDChanged(newPartyID: number) {
      if (newPartyID) {
        const participant = this.participantInDossierOrCase(
          this.dossierID,
          this.caseID,
          newPartyID,
        );
        this.$emit('input', participant);
      } else {
        this.$emit('input', null);
      }
    },
    update(key: string, value: any): void {
      if (this.participant) {
        this.$emit(
          'input',
          tap(cloneDeep(this.participant), (v) => set(v, key, value)),
        );
      }
    },
  },
  components: {
    PartySelector,
  },
});
