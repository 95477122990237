















































































































































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import profileAPI from '@/vuex/profile/profileAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';
import {
  changedCriteria,
  criteriaToQueryString,
  routeToCriteria,
} from '@/lib/criteria';
import { Profile } from '@/vuex/profile/profile';

import AddProfileDialog from '@/components/profile/AddProfileDialog.vue';
import CrimeSelector from '@/components/crime/CrimeSelector.vue';
import ProfileDetailsCard from '@/components/profile/ProfileDetailsCard.vue';
import ProfileIdentityCard from '@/components/profile/ProfileIdentityCard.vue';
import { ChargeDispositionTypeID } from '@/vuex/court/charge';

export default Vue.extend({
  props: {
    /**
     * route expects the $route object from the parent component. It is optional
     * and should only be supplied by OffenderList, where we *want* a tight
     * connection between the page URL and the OffenderSearcher criteria object.
     */
    route: Object,

    /**
     * dense triggers a more condensed design for the component, which is useful
     * when it appears in a popup or other compressed space.
     */
    dense: Boolean,

    selectable: Boolean,
  },

  created() {
    if (this.route) {
      this.routeChanged();
    }

    // Load the initial data...
    this.fetchData();

    // ...then make every future calls to this.fetchData or this.routeChanged
    // be debounced so that they can never be called faster than once
    // every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
    this.routeChanged = _.debounce(this.routeChanged, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,
      advancedSearch: false,

      initialCriteria: {},
      criteria: {
        q: '',
        name: '',
        surname: '',
        fatherName: '',
        grandfatherName: '',
        alias: '',
        genderID: 0,
        birthplaceGeographyID: 0,
        residenceGeographyID: 0,
        minAge: 0,
        maxAge: 0,
        minHeightInCm: 0,
        maxHeightInCm: 0,
        minWeightInKg: 0,
        maxWeightInKg: 0,
        hairColorID: 0,
        eyeColorID: 0,
        bloodType: '',
        nativeLanguageID: 0,
        secondLanguageID: 0,
        educationLevelID: 0,
        canRead: null as null | boolean,
        canWrite: null as null | boolean,
        isKochi: null as null | boolean,
        isMilitary: null as null | boolean,
        militaryRankID: 0,
        criminalGroupIDs: [],
        nationalityID: 0,
        crimeIDs: [],
        crimeMatchAll: false,
        severityID: 0,
        chargeDispositionIDs: [0, 1],
      },
      resultIDs: [] as number[],

      page: {
        number: 1,
        size: 10,
        serverCount: 0,
      },
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('auth', ['hasPermission']),

    allowAdding(): boolean {
      if (!this.hasPermission('profile.create')) {
        return false;
      }

      if (!this.selectable) {
        return true;
      }
      return !!this.queryString;
    },

    /**
     * criteriaUnchanged returns true when the currently-active criteria
     * is unchanged from its original, default state on the form.
     */
    criteriaUnchanged(): boolean {
      return _.isEqual(this.criteria, this.initialCriteria);
    },

    /**
     * profiles computes the array of Profile entities which currently appear
     * in the data table. By injecting search results into the Profile store in
     * Vuex, we ensure that all Profile entity data for the selected item
     * lives on when selecting a Profile for use on other pages.
     */
    profiles(): Profile[] {
      return _.map(this.resultIDs || [], this.profileWithID);
    },

    /**
     * queryString computes the URL querystring parameters which will be
     * submitted to the server to perform the search. NOTE that this
     * value is combined with pagination criteria inside fetchData, so this
     * value doesn't exactly match the API request.
     */
    queryString(): string {
      if (_.isEmpty(this.initialCriteria)) {
        this.initialCriteria = _.cloneDeep(this.criteria);
      }
      const cc = changedCriteria(this.criteria, this.initialCriteria);
      return criteriaToQueryString(cc, this.page);
    },

    /**
     * headers dynamically builds the headers array so that translations for
     * the column titles can update live, and so that the edit link column is
     * removed *automatically when the selectable prop is set
     */
    headers(): any[] {
      const headers: any[] = [
        { text: i18n.t('person.name'), value: 'name' },
        { text: i18n.t('person.surname'), value: 'surname' },
        { text: i18n.t('person.fatherName'), value: 'fatherName' },
        { text: i18n.t('person.grandfatherName'), value: 'grandfatherName' },
        { text: i18n.t('person.alias.plural'), value: 'alias', width: '15%' },
        {
          text: i18n.t('person.birthplace'),
          value: 'birthplaceGeographyID',
        },
        {
          text: 'Age',
          value: 'birthdate',
        },
        { value: null, text: '', width: '2%' },
      ];
      return headers;
    },

    /**
     * crimeCols computes the number of columns each UI element should
     * occupy in the crime selection row.
     */
    crimeCols(): number {
      if (this.showChargeDisposition) {
        return 4;
      }
      return 6;
    },

    /**
     * showChargeDisposition computes whether the Charge Disposition Column
     * should be displayed.
     */
    showChargeDisposition(): boolean {
      const haveCrimes = this.criteria.crimeIDs.length > 0;
      const haveSeverity = this.criteria.severityID !== 0;
      if (haveCrimes || haveSeverity) {
        return true;
      }
      return false;
    },
  },

  watch: {
    /**
     * route is watched so that when the $route of the topmost component
     * changes, we can update the query string. Note that we don't watch
     * $route directly from this component (note the lack of a dollar sign here),
     * because that would potentially trigger at undesirable times.
     *
     * Since we can search for Offenders from other places than the full
     * OffenderList page, we don't always want the route to be tied to this
     * component's behavior.
     */
    route(route: any) {
      this.routeChanged();
    },

    /**
     * queryString is watched so that a new search can be triggered.
     */
    queryString(newVal: string) {
      this.fetchData();
    },

    /**
     * criteria is watched so that when the search criteria is changed after
     * results have already been returned, we can reset the page number.
     */
    criteria: {
      deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        if (this.page.serverCount > 0 && this.page.number > 1) {
          this.page.number = 1;
        }
      },
    },

    /**
     * advancedSearch changes the UI, so we need to make sure to reset
     * the no longer visible fields
     */
    advancedSearch(isAdvanced: boolean) {
      if (isAdvanced) {
        this.resetSimpleCriteria();
      } else {
        this.resetAdvancedCriteria();
      }
    },

    showChargeDisposition(show: boolean): void {
      if (show) {
        this.criteria.chargeDispositionIDs = [
          ChargeDispositionTypeID.Unknown,
          ChargeDispositionTypeID.Guilty,
        ];
      }
    },
  },

  methods: {
    /**
     * afterProfileAdded is triggered when the user successfully adds a new
     * record via the add dialog attached to the search interface. It receives
     * the ID of the newly-created Profile, and immediately selects it if the
     * searcher is in selectable mode. If not, it forcefully adds it to the
     * front of the search results so the user immediately sees it.
     */
    afterProfileAdded(newID: number): void {
      if (this.selectable) {
        this.selectProfile(newID);
      } else {
        this.resultIDs.unshift(newID);
        if (this.page.serverCount < 1) {
          this.page.serverCount = 1;
        }
      }
    },

    /**
     * selectProfile is triggered by a click on one of the rows in the table,
     * only when the selectable prop is set.
     */
    selectProfile(profileID: any): void {
      this.$emit('selected', parseInt(profileID, 10));
    },

    /**
     * routeChanged is triggered when the page URL changes.
     */
    routeChanged(): void {
      routeToCriteria(this.route, this.criteria, this.page);
      if (!this.criteriaUnchanged && this.criteria.q === '') {
        this.advancedSearch = true;
      }
    },

    /**
     * reset clears the search and resets search results. Primarily designed
     * to be called externally via a $ref to this component so that the searcher
     * can be reset when a dialog is opened.
     */
    reset(): void {
      this.$nextTick(() => {
        this.resetSimpleCriteria();
        this.resetAdvancedCriteria();

        if (this.$refs.query) {
          (this.$refs.query as any).focus();
        }
      });
    },

    resetSimpleCriteria(): void {
      this.criteria.q = '';
    },

    resetAdvancedCriteria(): void {
      this.criteria = Object.assign(this.criteria, this.initialCriteria);
    },

    /**
     * fetchData performs the actual search. It hits the API with the active
     * query and pagination criteria and updates the locally-held search results
     * data.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;

      try {
        const url = `/profiles/search?${this.queryString}`;
        const resp = await profileAPI.get(url);
        this.page.serverCount = _.get(resp, 'data.meta.totalResults', 0);
        const profiles = _.get(resp, 'data.data', []);
        this.resultIDs = _.map(profiles, 'id');
        const newState = modelsToState('profile', profiles);
        this.$store.commit('profile/setState', newState);
        this.$emit('queryChanged', this.queryString);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    AddProfileDialog,
    CrimeSelector,
    ProfileDetailsCard,
    ProfileIdentityCard,
  },
});
