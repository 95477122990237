













import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Prosecutor } from '@/vuex/investigation/prosecutor';
import investigationAPI from '@/vuex/investigation/investigationAPI';

import ProsecutorForm from './ProsecutorForm.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  data() {
    return {
      error: null as any,
      isOpen: false,
      prosecutor: null as Prosecutor | null,
    };
  },
  computed: {
    ...mapGetters('investigation', ['prosecutorWithID']),
  },
  methods: {
    /**
     * reset is our event handler for CMSFormDialog's reset event. It fires when
     * the dialog is activated. We use it to create a dialog-local copy of the
     * entity being edited.
     */
    async reset(): Promise<void> {
      await this.$store.dispatch('investigation/fetchProsecutor', this.id);
      const localCopy = Object.assign({}, this.prosecutorWithID(this.id));
      this.prosecutor = localCopy;
      this.error = null;
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We use it to persist the
     * changes to the server.
     */
    async save(): Promise<void> {
      if (!this.prosecutor) {
        return;
      }

      try {
        this.error = null;
        const response = await investigationAPI.post('prosecutors/update', {
          data: {
            id: this.prosecutor.id,
            name: this.prosecutor.name,
            surname: this.prosecutor.surname,
            fatherName: this.prosecutor.fatherName,
            grandfatherName: this.prosecutor.grandfatherName,
            birthplaceGeographyID: this.prosecutor.birthplaceGeographyID,
            residenceGeographyID: this.prosecutor.residenceGeographyID,
            genderID: this.prosecutor.genderID,
            emailAddress: this.prosecutor.emailAddress,
            phoneNumber: this.prosecutor.phoneNumber,
            partyID: this.prosecutor.partyID,
            isDeleted: this.prosecutor.isDeleted,
            caseTypes: this.prosecutor.caseTypes,
            stageIDs: this.prosecutor.stageIDs,
          },
        });
        const newState = response.data.data;
        this.$store.commit('investigation/setState', newState);
        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    ProsecutorForm,
  },
});
