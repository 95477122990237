















































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('court', ['fullPartyName']),
    ...mapState('court', ['partyTypes']),
    payload(): any {
      return this.entry.payload;
    },
  },
});
