













import Vue from 'vue';

import { User } from '@/vuex/user/user';
import { firstModelIDInState } from '@/lib/vuex-domainmodel';
import userAPI from '@/vuex/user/userAPI';

import UserForm from '@/components/user/UserForm.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      error: null as any,
      user: null as User | null,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.user = null;
      this.error = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.user) {
        return;
      }

      try {
        this.error = null;
        const response = await userAPI.post('/users/create', {
          data: this.user,
        });

        // Update Vuex state
        const newState = response.data.data;
        this.$store.commit('user/setState', newState);

        // Notify consumers of the newly-created record's ID
        const newID = firstModelIDInState(newState, 'user');
        this.$emit('saved', newID);

        // Close the Dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    UserForm,
  },
});
