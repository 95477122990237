














import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { AccompanyingChild } from '@/vuex/offender/accompanyingChild';

import AccompanyingChildForm from '@/components/offender/accompanyingChild/AccompanyingChildForm.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  data() {
    return {
      error: null as any,
      isOpen: false,

      accompanyingChild: null as AccompanyingChild | null,
    };
  },

  computed: {
    ...mapGetters('offender', ['accompanyingChildWithID']),
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.error = null;
      const localCopy = _.cloneDeep(this.accompanyingChildWithID(this.id));
      this.accompanyingChild = localCopy;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      try {
        this.error = null;

        // Make the API call
        const response = await offenderAPI.post('/update-accompanying-child', {
          data: this.accompanyingChild,
        });

        const newState = _.get(response, 'data.data', {});
        this.$store.commit('offender/setState', newState);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    AccompanyingChildForm,
  },
});
