













import Vue from 'vue';

import DossierCourts from '@/views/dossier/DossierCourts.vue';
import DossierEnforcement from '@/views/dossier/DossierEnforcement.vue';
import DossierHeaderNav from '@/components/dossier/DossierHeaderNav.vue';
import DossierDocket from '@/views/dossier/DossierDocket.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  created(): void {
    this.fetchData();
  },
  watch: {
    id(val: number): void {
      if (this.id) {
        this.fetchData();
      }
    },
  },
  computed: {
    dossierPage(): string {
      return this.$route.name || '';
    },
  },
  methods: {
    fetchData(): void {
      // Fetch the Dossier
      this.$store.dispatch('court/fetchDossier', this.id).catch((e) => {
        if (e.message.match(/404|500/)) {
          this.$store.dispatch(
            `application/showSnackbarError`,
            `${e.name}:  ${e.message}. Cant find Dossier #${this.id}`,
          );
        }
      });

      // Fetch Fine Payments
      this.$store.dispatch('court/fetchFinePaymentsForDossier', {
        dossierID: this.id,
      });

      // Fetch Subject Properties
      this.$store.dispatch('court/fetchSubjectPropertiesForDossier', {
        dossierID: this.id,
      });
    },
  },
  components: {
    DossierCourts,
    DossierEnforcement,
    DossierHeaderNav,
    DossierDocket,
  },
});
