import store from '@/vuex/store';

/******************************************************************************
 * Navigation Guards
 *
 * The functions below are Vue Navigation guards
 * https://router.vuejs.org/guide/advanced/navigation-guards.html
 *
 * They are code that runs immediately prior to the Vue router navigating to a
 * new URL. This happens both for "full page" loads (as when the user hard-
 * refreshes the current page or navigates to a page for the first time)... and
 * when the user clicks an internal application link.
 */

/**
 * fetchCaseFirst retrieves the basic data needed for all Court Case-related
 * pages.
 *
 * @param to the target Route Object being navigated to
 *        see: https://router.vuejs.org/api/#the-route-object
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export async function fetchCaseFirst(to, from, next): Promise<void> {
  // Navigate right away... pages should implement loading logic while they
  // wait for the Case and Dossier to load.
  next();

  if (to.params.id) {
    const id = Number(to.params.id);
    let kase = null as any;

    // Fetch the Case
    try {
      await store.dispatch('court/fetchCase', id);
      kase = store.getters['court/caseWithID'](id);
    } catch (e) {
      if (e.message.match(/404|500/)) {
        store.dispatch(
          'application/showSnackbarError',
          `${e.name}:  ${e.message}. Cant find Case #${id}`,
        );
      }
    }

    // Fetch the Dossier which is attached to this Case
    if (kase && kase.dossierID) {
      try {
        await store.dispatch('court/fetchDossier', kase.dossierID);
      } catch (e) {
        console.error(e);
        if (e.message.match(/404|500/)) {
          store.dispatch(
            `application/showSnackbarError`,
            `${e.name}:  ${e.message}. Cant find Dossier #${kase.dossierID}`,
          );
        }
      }
    }
  }
}

/**
 * fetchProfileFirst retrieves the basic data needed for all Profile-related
 * pages.
 *
 * @param to the target Route Object being navigated to
 *        see: https://router.vuejs.org/api/#the-route-object
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export async function fetchProfileFirst(to, from, next): Promise<void> {
  // Navigate right away... pages should implement loading logic while they
  // wait for the Profile to load.
  next();

  // Get the profile ID from the URL via the to route object
  const id = Number(to.params.id);

  try {
    // Since we can fetch the Offender and Profile data directly from the ID
    // do that simultaneously...
    await Promise.all([
      store.dispatch('profile/fetchProfile', id),
      store.dispatch('offender/fetchOffenderForProfile', id),
    ]);
  } catch (e) {
    console.error(e);
  }

  // ... then after both of those return, get the Profile
  const profile = store.getters['profile/profileWithID'](id);
  if (!profile) {
    store.dispatch('application/showSnackbarError', `Cant load Profile #${id}`);
  }

  if (profile && profile.partyID) {
    await store.dispatch('court/fetchDossiersForParty', profile.partyID);
  }
}

/**
 * fetchOffenderFirst retrieves the basic data needed for all Offender-related
 * pages.
 *
 * @param to the target Route Object being navigated to
 *        see: https://router.vuejs.org/api/#the-route-object
 * @param from the current route being navigated away from
 * @param next this function must be called to resolve the hook
 */
export async function fetchOffenderFirst(to, from, next): Promise<void> {
  // Navigate right away... pages should implement loading logic while they
  // wait for the Offender data to load.
  next();

  // Get the ID from out of the URL in the to route
  const id = Number(to.params.id);

  try {
    // Fetch the Offender
    await store.dispatch('offender/fetchOffender', id);
    const offender = store.getters['offender/offenderWithID'](id);
    if (!offender) {
      store.dispatch(
        'application/showSnackbarError',
        `Cant load Offender #${id}`,
      );
      return;
    }

    // Fetch the Attached Dossier
    if (offender && offender.dossierID) {
      try {
        await store.dispatch('court/fetchDossier', offender.dossierID);
      } catch (e) {
        if (e.message.match(/404|500/)) {
          store.dispatch(
            `application/showSnackbarError`,
            `${e.name}:  ${e.message}.  Cant find linked Offender Dossier #${offender.dossierID}`,
          );
        }
      }
    }
  } catch (e) {
    if (e.message.match(/404|500/)) {
      store.dispatch(
        `application/showSnackbarError`,
        `${e.name}:  ${e.message}.  Cant find Offender #${id}`,
      );
    }
  }
}
