import _ from 'lodash';
import VueI18n from 'vue-i18n';
import moment from 'moment-timezone';
import jmoment from 'moment-jalaali';
import i18n from '@/i18n';

/**
 * DateRangeOption defines a choice in the list of predefined date ranges
 * in the dropdown. This interface wraps
 */
export interface DateRangeOption {
  /**
   * key is the unique identifier for this date range. Required. Will be used
   * as the translation key unless a label() function is provided.
   */
  key: string;

  /**
   * label is a property which defines the function which will be
   * called to get the display label.
   */
  label: () => string | VueI18n.TranslateResult;

  /**
   * dates is a required property which defines the function which will be
   * called to generate the dates which should be used for this DateRangeOption.
   */
  dates: () => [string, string] | undefined;
}

/**
 * EmptyDateRange defines the selection which represents an empty start and
 * end date. With a label indicating the selection is for events on any dates.
 */
export const EmptyDateRange = {
  key: 'anytime',
  label() {
    return i18n.t('daterange.empty');
  },
  dates(): [string, string] {
    return ['', ''];
  },
};

export const CustomDateRange = {
  key: 'custom',
  label() {
    return i18n.t('daterange.custom');
  },
  dates(): undefined {
    return undefined;
  },
};

export function allDateRanges(): DateRangeOption[] {
  let mom = moment;
  if (i18n.locale !== 'en') {
    mom = jmoment;
  }

  const items: DateRangeOption[] = [];

  items.push(EmptyDateRange);
  items.push(CustomDateRange);

  // Current Week, Month, Year, etc
  items.push(current('week'), current('month'));
  if (i18n.locale === 'en') {
    // moment-jalaali doesn't support startOf() or subtract() 'quarter'
    items.push(current('quarter'));
  }
  items.push(current('year'));

  // Prior Week, Month, Year, etc
  items.push(prior('week'), prior('month'));
  if (i18n.locale === 'en') {
    // moment-jalaali doesn't support startOf() or subtract() 'quarter'
    items.push(prior('quarter'));
  }
  items.push(prior('year'));

  // Last N Days
  items.push(lastNDays(30), lastNDays(60), lastNDays(90), lastNDays(365));

  return items;
}

/**
 * current builds a DateRangeOption representing a current time period (e.g.
 * Current Year, Current Month, Current Week). The jalaali-moment library
 * doesn't support date math for Quarters, so this function shouldn't be calld
 * with 'quarter' for a Jalaali calendar presentation.
 *
 * @param period A string indicating a moment time period ('week', 'month', 'year', etc)
 * @returns
 */
function current(
  period: moment.unitOfTime.DurationConstructor,
): DateRangeOption {
  const capitalPeriod = _.capitalize(period);
  return {
    key: `current${capitalPeriod}`,
    label() {
      return i18n.t(`daterange.current${capitalPeriod}`);
    },
    dates() {
      let start: string = '';
      let end: string = '';
      if (i18n.locale !== 'en') {
        start = jmoment().startOf(`j${capitalPeriod}`).format('YYYY-MM-DD'); // prettier-ignore
        end = jmoment().endOf(`j${capitalPeriod}`).format('YYYY-MM-DD'); // prettier-ignore
      } else {
        start = moment().startOf(period).format('YYYY-MM-DD');
        end = moment().endOf(period).format('YYYY-MM-DD');
      }
      return [start, end];
    },
  };
}

/**
 * prior builds a DateRangeOption representing a prior period (e.g.
 * Previous Year, Previous Month). The jalaali-moment library doesn't support
 * date math for Quarters, so this function shouldn't be called with 'quarter'
 * for a Jalaali calendar presentation.
 *
 * @param period A string indicating a moment time period ('week', 'month', 'year', etc)
 * @returns
 */
function prior(period: moment.unitOfTime.DurationConstructor): DateRangeOption {
  const capitalPeriod = _.capitalize(period);
  return {
    key: 'prior' + capitalPeriod,
    label() {
      return i18n.t(`daterange.prior${capitalPeriod}`);
    },
    dates() {
      let start: string = '';
      let end: string = '';

      if (i18n.locale !== 'en') {
        const jUnit = `j${capitalPeriod}`;
        start = jmoment().startOf(jUnit).subtract(1, jUnit).format('YYYY-MM-DD'); // prettier-ignore
        end = jmoment().startOf(jUnit).subtract(1, 'day').format('YYYY-MM-DD'); // prettier-ignore
      } else {
        const startUnit = period as moment.unitOfTime.StartOf;
        const duration = period as moment.unitOfTime.DurationConstructor;
        start = moment().startOf(startUnit).subtract(1, duration).format('YYYY-MM-DD'); // prettier-ignore
        end = moment().startOf(startUnit).subtract(1, 'day').format('YYYY-MM-DD'); // prettier-ignore
      }

      return [start, end];
    },
  };
}

/**
 * lastNDays dynamically builds a DateRangeOption with a start N days ago
 * and an end on today's date. These DateRangeOption's work the same regardless
 * of the calendar system being used.
 *
 * @param n Number of days ago to use as the start of the range
 * @returns
 */
function lastNDays(n: number): DateRangeOption {
  return {
    key: `last${n}Days`,
    label() {
      return i18n.t('daterange.lastNDays', { days: n });
    },
    dates() {
      const start = moment().subtract(n, 'days').format('YYYY-MM-DD');
      const end = moment().format('YYYY-MM-DD');
      return [start, end];
    },
  };
}

export function dateRangeFromDates(
  dates: [string, string],
  ranges?: DateRangeOption[],
): DateRangeOption | undefined {
  if (!ranges || ranges.length === 0) {
    ranges = allDateRanges();
  }
  return _.find(ranges, (range) => {
    const datesForRange = range.dates();
    if (
      datesForRange &&
      datesForRange[0] === dates[0] &&
      datesForRange[1] === dates[1]
    ) {
      return true;
    }
    return false;
  });
}
