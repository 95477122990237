































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString } from '@/lib/criteria';

import LookupTableMatrix from '@/components/global/formatting/LookupTableMatrix.vue';

/**
 * DisposedChargeCount defines the shape of the "long format" data which the
 * serverreturns. It is transformed into a "wide format" by the
 * LookupTableMatrix component.
 */
interface DisposedChargeCount {
  dispositionTypeID: number;
  crimeSeverityID: number;
  count: number;
}

export default Vue.extend({
  props: {
    criteria: Object,
  },

  data() {
    return {
      loading: false,
      error: null as any,

      finalizedBetween: '',
      results: [] as DisposedChargeCount[],
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('crime', ['severities']),
    ...mapState('court', ['chargeDispositionTypes']),

    /**
     * fullCriteria builds the actual API criteria which will be sent. This is
     * done by combining the incoming criteria prop with customizations set
     * directly on this component.
     */
    fullCriteria(): any {
      return Object.assign({}, this.criteria, {
        activeOnly: false, // TODO Find a way to avoid needing to override this.
        finalizedBetween: this.finalizedBetween,
      });
    },

    /**
     * queryString builds the URL querystring which will be sent to the API
     * endpoint. It exists as a standalone computed prop so that the watcher
     * can trigger only when the query has changed.
     */
    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      try {
        const url = `/disposed-charges-by-severity?${this.queryString}`;
        const response = await investigationAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
  },

  components: {
    LookupTableMatrix,
  },
});
