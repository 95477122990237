













import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';

import { Flag } from '@/vuex/offender/options';

export default Vue.extend({
  props: {
    value: Array,
    small: Boolean,
    xSmall: Boolean,
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapState('offender', ['flags']),

    /**
     * visibleFlags returns the subset of all known flag options
     * whose IDs are in the ID provided to this component's prop.
     */
    visibleFlags(): Flag[] {
      // Ensure we have an array
      const valAsArray = _.flatten([this.value || []]);

      return _.filter(this.flags, (flag) => {
        return _.includes(valAsArray, flag.id);
      });
    },
  },
});
