import _ from 'lodash';
import { inCurrentJalaaliYear } from '@/lib/dates';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

/**
 * Leave describes a temporary absence from a facility for an Offender
 */
export interface Leave {
  id: number;
  offenderID: number;
  housingFacilityID: number;
  reasonID: string;
  details: string;
  status: string;
  scheduledReleaseDate: string;
  estimatedReturnDate: string;
  estimatedDurationInHours: number;
  durationInHours: number;
  requestedAt: string;
  approvedAt: string;
  deniedAt: string;
  approverTitle: string;
  approverName: string;
  canceledAt: string;
  cancelReason: string;
  releasedAt: string;
  returnedAt: string;
}

export const enum LeaveReason {
  Unknown = 0,
  Court = 1,
  Deposition = 2,
  Death = 3,
  Sickness = 4,
  Personal = 5,
}

export const state = {
  leave: {} as { [id: number]: Leave },
  leaveIDsForOffender: {} as { [offenderID: number]: number[] },
};

export const getters = {
  leavesForOffender:
    (state) =>
    (offenderID: number): Leave[] => {
      return (state.leaveIDsForOffender[offenderID] || []).map(
        (id: number) => state.leave[id],
      ) as Leave[];
    },

  // voluntaryLeaveDaysThisYearForOffender returns the total number of days
  // of voluntary leave that have been completed during the current year.
  voluntaryLeaveDaysThisYearForOffender:
    (state, getters) =>
    (offenderID: number): number => {
      const totalHoursThisYear = _.reduce(
        getters.voluntaryLeavesInCurrentJalaaliYear(offenderID),
        (sum, leave) => {
          return sum + leave.durationInHours;
        },
        0,
      );

      return Math.floor(totalHoursThisYear / 24);
    },

  // leavesInCurrentJalaaliYear returns the collection of Leave objects
  // for the offenderID with releasedAt dates in the current Jalaali year.
  // A leave will be calculated based on when the Leave is started.
  //
  //
  // Example: Prisoner Zach begins his 14 day leave on March 15, 2019 and
  // returns to prison on March 29, 2020. The 14 days of Leave would be
  // counted in 2019 and not 2020.
  //
  leavesInCurrentJalaaliYear:
    (state, getters) =>
    (offenderID: number): Leave[] => {
      return getters
        .leavesForOffender(offenderID)
        .filter((leave) => inCurrentJalaaliYear(leave.releasedAt));
    },

  // voluntaryLeavesInCurrentJalaaliYear returns the collection of Leave objects
  // for the offenderID with releasedAt dates in the current Jalaali year which
  // adhere to the 'isVoluntaryLeave' requirements
  voluntaryLeavesInCurrentJalaaliYear:
    (state, getters) =>
    (offenderID: number): Leave[] => {
      return getters.leavesInCurrentJalaaliYear(offenderID).filter((leave) => {
        getters.isVoluntaryLeave(leave);
      });
    },

  // isVoluntaryLeave tests whether the given leave is considered voluntary
  // based on a list of acceptable reasons
  isVoluntaryLeave:
    (state) =>
    (leave): boolean => {
      return ['death', 'sickness', 'personal', 'unknown'].includes(
        leave.reason,
      );
    },
};

export const mutations = {};

export const actions = {
  /**
   * fetchLeaves retrieves the complete list of leaves for a particular offender
   * from the server, and updates the local store with the results.
   */
  async fetchLeaves({ commit }, payload: { offenderID: number }) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/leaves`,
    );
    const leaves = response.data.data;
    const newState = modelsToState('leave', leaves);
    commit('setState', newState);
    commit('setTarget', {
      target: 'leaveIDsForOffender',
      index: payload.offenderID,
      value: _.map(leaves, 'id'),
    });
  },
};
