





















































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';

import { toLocalizedDate, toLocalizedMonthName } from '@/lib/dates';
import {
  allDateRanges,
  DateRangeOption,
  dateRangeFromDates,
} from '@/lib/date-range';

export default Vue.extend({
  props: {
    label: String,
    value: String,
    persistentHint: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    max: String,

    twoFields: {
      type: String,
      default: 'when-necessary',
      validator: (v) =>
        ['never', 'always', 'when-blank', 'when-necessary'].indexOf(v) !== -1,
    },

    allowCustom: {
      type: Boolean,
      default: true,
    },

    initialValue: String,
  },

  created() {
    this.attachID = 'LocaleDateRange' + Math.floor(Math.random() * 9999);

    /**
     * The delay here is required because of the bug documented here:
     * https://github.com/vuetifyjs/vuetify/issues/9129
     *
     * The combination of :close-on-click="true" (which closes the menu)
     * if the user clicks elsewhere on the page (a feature we want) interacts
     * with the @focus event such that the click is reported after the focus
     * has already triggered the menu to open. By delaying the open signal
     * for several milliseconds, we end up with both events triggering before
     * the dialog is opened.
     */
    this.open = _.debounce(this.open, 100);

    if (this.initialValue) {
      const range = _.find(this.ranges, { key: this.initialValue });
      this.rangeSelected(range);
    }
  },

  data(): any {
    return {
      /**
       * attachID holds the DOM element ID to which the dialog/menu
       * attaches. It gets set to a random value when the component is
       * created.
       */
      attachID: '',

      isOpen: false,
      selectingDates: false,

      startDate: '',
      endDate: '',

      customStartDate: '',
      customEndDate: '',

      /**
       * selectedRange holds the 'key' property from the selected predefined
       * date range object (eg. 'last90Days', or 'currentMonth'). When the
       * 'custom' range is selected, this value will be set to an empty string
       * after specific dates are chosen.
       */
      selectedRange: null as DateRangeOption | null,
    };
  },

  computed: {
    ...mapState('language', ['locale']),

    /**
     * showTwoFields returns true when two UI fields (start/end dates) need to
     * be shown. This is influenced by the two-fields prop and the currently
     * selected range/value.
     */
    showTwoFields(): boolean {
      switch (this.twoFields) {
        case 'never':
          return false;
        case 'always':
          return true;
        case 'when-blank':
          return !this.selectedRange || this.selectedRange.key === 'anytime';
        case 'when-necessary':
          return !this.selectedRange && this.value !== '';
        default:
          return true;
      }
    },

    /**
     * combinedText computes the combined, localized date values displayed
     * as a range (e.g. "15-Oct-2017 — 30-Oct-2018"). This powers the hint
     * below the single dropdown when a predefined range is selected, and the
     * text displayed inside the dropdown when no range is selected.
     */
    combinedText(): string {
      const start = toLocalizedDate(this.startDate);
      const end = toLocalizedDate(this.endDate);
      const v = `${start} — ${end}`;
      return v === ' — ' ? '' : v;
    },

    /**
     * hint computes the text to display beneath the single dropdown as an
     * explanation of the dates selected (i.e. explaining which dates we mean
     * by "Current Year" or "Last 90 Days".
     */
    hint(): string {
      if (this.twoFields === 'never' && !this.selectedRange) {
        return '';
      }
      return this.combinedText;
    },

    /**
     * commonOptions computes the underlying list of DateRangeOption options that are
     * the basis for all 3 dropdowns (startDate, endDate, and combined).
     */
    ranges(): DateRangeOption[] {
      let ranges = allDateRanges();
      if (!this.allowCustom) {
        ranges = _.reject(ranges, ['key', 'custom']);
      }
      return ranges;
    },
  },

  watch: {
    /**
     * value is watched so that changes to the external data-bound value
     * can be parsed and internalized.
     */
    value: {
      immediate: true,
      handler(newVal: string): void {
        this.valueChangedFromOutside(newVal);
      },
    },

    /**
     * isOpen is watched to initialize the selection menu settings. When
     * the menu opens, we ensure the datepicker dates match whatever values
     * are current in the component. When the menu closes, we need to disable
     * the date selection mode so that the user can chose next time.
     */
    isOpen(nowOpen: boolean) {
      if (nowOpen) {
        // Opening
        this.customStartDate = this.startDate;
        this.customEndDate = this.endDate;
      } else {
        // Closing
        this.selectingDates = false;
      }
    },
  },

  methods: {
    toLocalizedDate,
    toLocalizedMonthName,

    /**
     * setDates is the internal handler for any change which modifies the
     * currently selected dates. We want to ensure that both dates change
     * simultaneously with a single $emit of the combined new value to avoid
     * weird side effects from partial updates.
     */
    setDates(dates: [string, string]) {
      this.startDate = dates[0];
      this.endDate = dates[1];

      const range = dateRangeFromDates(dates);
      if (range) {
        this.selectedRange = range;
      } else {
        this.selectedRange = null;
      }

      const combined = `${this.startDate},${this.endDate}`;
      this.$emit('input', combined === ',' ? '' : combined);
    },

    /**
     * rangeSelected is the internal handler for a new choice being made in the
     * menu.
     */
    rangeSelected(range: DateRangeOption): void {
      if (!range) {
        this.selectedRange = null;
        return;
      }

      this.selectedRange = range;

      const dates = range.dates();
      if (dates) {
        this.setDates(dates);
        this.close();
        return;
      } else {
        this.selectingDates = true;
      }
    },

    /**
     * valueChangedFromOutside is the handler triggered when the data-bound
     * value has changed from outside the component. We parse the value, and
     * figure out if it matches any of the predefined shortcut ranges. We set
     * startDate, endDate, and shortcut as appropriate.
     */
    valueChangedFromOutside(newVal: string): void {
      const dates: [string, string] = ['', ''];
      const parts = newVal.split(',');
      if (parts.length > 0) {
        dates[0] = parts[0];
      }
      if (parts.length > 1) {
        dates[1] = parts[1];
      }
      this.setDates(dates);
    },

    open(): void {
      this.isOpen = true;
    },

    save(): void {
      this.setDates([this.customStartDate || '', this.customEndDate || '']);
      this.close();
    },

    close(): void {
      this.isOpen = false;
    },
  },
});
