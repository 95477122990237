














import Vue from 'vue';
import { Holiday } from '@/vuex/court/holiday';
import courtAPI from '@/vuex/court/courtAPI';
import HolidayForm from './HolidayForm.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      error: null as any,

      holiday: null as Holiday | null,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.holiday = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (this.holiday == null) {
        return;
      }
      this.error = null;

      try {
        const response = await courtAPI.post('holidays/create', {
          data: {
            name: this.holiday.name,
            locationGeographyID: this.holiday.locationGeographyID,
            date: this.holiday.date,
          },
        });
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/prependToTarget', {
          target: 'holidayIDs',
          value: Object.keys(newState.holiday),
        });

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    HolidayForm,
  },
});
