































































































import _ from 'lodash';
import Vue from 'vue';
import i18n from '@/i18n';
import { mapState, mapGetters } from 'vuex';

import { AllCourtStageIDs } from '@/vuex/court/court';
import { AllDossierStageIDs } from '@/vuex/court/dossier';
import { Case, CaseStatus } from '@/vuex/court/case';
import {
  DocketEventType,
  DocketEventTypeModule,
  LookupItem,
} from '@/vuex/court/options';
import { Dossier, DossierStatusID } from '@/vuex/court/dossier';

import AddDocketEventDialog from '@/components/court/case/AddDocketEventDialog.vue';
import AddHearingDialog from '@/components/court/hearing/AddHearingDialog.vue';
import AddParticipantDialog from '@/components/court/participant/AddParticipantDialog.vue';
import AddSubjectPropertyDialog from '@/components/subjectProperty/AddSubjectPropertyDialog.vue';

/**
 * DocketMenuItem is defines an interface which is very similar to
 * DocketEventType. It includes only the properties which are needed in the
 * context of building a menu, and it adds some new properties which are menu
 * specific.
 */
interface DocketMenuItem {
  icon?: string;
  allowedCourtStageIDs: number[];
  allowedDossierStageIDs: number[];
  allowedCaseTypes: string[];
  module?: string | string[];
  categoryID: number;
  rank: number;
  isDiscontinued: boolean;

  label: string;
  method: string;
  payload: any;
  condition?: (kase: Case) => boolean;
}

export default Vue.extend({
  props: {
    dossierID: Number,
    caseID: Number,
    module: [String, Array],
  },

  data() {
    return {
      menuOpen: false, // Trigger for the overall menu

      /**
       * Triggers for the various dialogs this menu triggers
       */
      addParticipantDialogOpen: false,
      addRulingDialogOpen: false,
      addDocketEventDialogOpen: false,
      addSubjectPropertyDialogOpen: false,
      addHearingDialogOpen: false,

      /**
       * Locale state which holds the docket event type which was clicked
       */
      docketEventTypeID: 0,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['languageIsRTL']),
    ...mapGetters('court', ['caseWithID', 'dossierWithID']),
    ...mapState('court', [
      'docketEventCategories',
      'docketEventTypes',
      'caseTypes',
    ]),

    dossier(): Dossier {
      return this.dossierWithID(this.dossierID);
    },

    kase(): Case {
      return this.caseWithID(this.caseID);
    },

    caseType(): string {
      if (this.kase) {
        return this.kase.type;
      }
      if (this.dossier) {
        return this.dossier.type;
      }
      return '';
    },

    courtStageID(): number {
      if (this.kase) {
        return this.kase.stageID;
      }
      return 0;
    },

    isDisposed(): boolean {
      if (this.kase) {
        return this.kase.statusID === CaseStatus.Disposed;
      }
      if (this.dossier) {
        return this.dossier.statusID === DossierStatusID.Closed;
      }
      return false;
    },

    /**
     * categories returns categories with their menu items attached. Categories
     * with no items are filtered out.
     */
    categories(): any[] {
      const categories = this.docketEventCategories as LookupItem[];
      const groupedItems = _.groupBy(this.allMenuItems, (mi) => mi.categoryID);
      return _.chain(categories)
        .map((category) =>
          Object.assign(category, {
            menuItems: groupedItems[category.id] || [],
          }),
        )
        .filter((category) => category.menuItems.length > 0)
        .sortBy(['rank', (category) => category.name[this.locale]])
        .value();
    },

    /**
     * allMenuItems combines the fixed and dynamic docket event menu items
     * filtered to remove those that are not allowed on the current screen.
     */
    allMenuItems(): DocketMenuItem[] {
      let items = [...this.fixedMenuItems, ...this.dynamicMenuItems];

      if (this.module) {
        // console.log(items);
        items = items.filter((mi) => {
          const itemModules = _.flatten([mi.module || []]);
          const componentModules = _.flatten([this.module]);
          const intersection = _.intersection(itemModules, componentModules);
          return intersection.length > 0;
        });
      }

      // Filter based on allowed case type
      items = items.filter((mi) =>
        _.includes(mi.allowedCaseTypes, this.caseType),
      );

      // Filter based on allowed stages if we're on a case
      if (_.includes(_.flatten([this.module]), DocketEventTypeModule.Court)) {
        items = items.filter((mi) =>
          _.includes(mi.allowedCourtStageIDs, this.kase.stageID),
        );
      }

      // Filter based on allowed dossier stages if we're on an Investigation docket
      if (
        _.includes(
          _.flatten([this.module]),
          DocketEventTypeModule.Investigation,
        )
      ) {
        items = items.filter((mi) =>
          _.includes(mi.allowedDossierStageIDs, this.dossier.stageID),
        );
      }

      // Filter out any marked as isDiscontinued
      items = items.filter((item) => !item.isDiscontinued);

      // Filter based on the condition function (if it exists)
      items = items.filter((mi) =>
        mi.condition ? mi.condition(this.kase) : true,
      );

      // Ensure unranked entries get moved to the bottom, and placed in the
      // uncategorized category.
      items = items.map((mi) => {
        mi.categoryID = mi.categoryID || 0;
        mi.rank = mi.rank || 999;
        return mi;
      });

      // Sort by rank and then label
      items = _.sortBy(items, ['rank', 'label']);

      return items;
    },

    /**
     * dynamicMenuItems converts the list of docket event types provided by the
     * backend into the menu item format used by this component. Each entry has
     * 'dynamicDocketEventMenuItemClicked' as its method, and the Docket Event
     */
    dynamicMenuItems(): DocketMenuItem[] {
      const types = this.docketEventTypes;
      return _.map(types, (det: DocketEventType) => {
        return {
          label: det.name[this.locale],
          categoryID: det.categoryID || 0,
          module: det.module || undefined,
          rank: det.rank || 999,
          method: 'dynamicDocketEventMenuItemClicked',
          payload: det.id,
          allowedCaseTypes: det.allowedCaseTypes,
          allowedCourtStageIDs: det.allowedCourtStageIDs,
          allowedDossierStageIDs: det.allowedDossierStageIDs,
          isDiscontinued: det.isDiscontinued || false,
        };
      });
    },

    /**
     * fixedMenuItems returns the list of hard-coded menu items. Each uses
     * 'openDialog' as their method, and the appropriate dialog v-model
     * variable name as their payload, so that the openDialog method opens that
     * dialog when the menu item is clicked.
     */
    fixedMenuItems(): DocketMenuItem[] {
      return [
        {
          icon: 'fa-plus',
          label: i18n.t('party.singular').toString(),
          method: 'openDialog',
          payload: 'addParticipantDialogOpen',
          categoryID: 1,
          rank: 10,
          module: ['investigation', 'court'],
          allowedCourtStageIDs: AllCourtStageIDs,
          allowedDossierStageIDs: AllDossierStageIDs,
          allowedCaseTypes: this.caseTypes,
          isDiscontinued: false,
        },
        {
          icon: 'fa-plus',
          label: i18n.t('subjectProperty.singular').toString(),
          method: 'openDialog',
          payload: 'addSubjectPropertyDialogOpen',
          categoryID: 1,
          rank: 10,
          module: ['investigation', 'court'],
          allowedCourtStageIDs: AllCourtStageIDs,
          allowedDossierStageIDs: AllDossierStageIDs,
          allowedCaseTypes: this.caseTypes,
          isDiscontinued: false,
        },
        {
          label: i18n.t('hearing.singular').toString(),
          method: 'openDialog',
          payload: 'addHearingDialogOpen',
          categoryID: 4,
          rank: 10,
          module: 'court',
          allowedCourtStageIDs: AllCourtStageIDs,
          allowedDossierStageIDs: [],
          allowedCaseTypes: this.caseTypes,
          isDiscontinued: false,
        },
      ];
    },
  },

  methods: {
    /**
     * menuItemClicked is the handler which is called directly when any menu
     * item is clicked. It acts as a "forwarder" or dispatcher and calls another
     * method (identified by the first argument) with a specific payload (its
     * second argument).
     */
    menuItemClicked(method: string, payload: any): void {
      // Close the menu right away.
      this.menuOpen = false;
      // Forward the call to another method.
      this[method](payload);
    },

    /**
     * dynamicDocketEventMenuItemClicked is called when any "dynamic" (that is,
     * "from the database") docket event menu item is clicked. It triggers
     * the AddDocketEventDialog to open with the provided docket event type
     * ID.
     */
    dynamicDocketEventMenuItemClicked(id: number): void {
      this.docketEventTypeID = id;
      this.addDocketEventDialogOpen = true;
    },

    /**
     * openDialog is called when any of the "hard-coded" (that is, "with a
     * custom dialog") menu items are clicked. The name of the v-model dialog
     * variable is the payload to this function. It sets that variable to
     * true, which activates the dialog.
     */
    openDialog(varname: string): void {
      this[varname] = true;
    },
  },

  components: {
    AddHearingDialog,
    AddParticipantDialog,
    AddDocketEventDialog,
    AddSubjectPropertyDialog,
  },
});
