




























import Vue from 'vue';

import { Program } from '@/vuex/offender/program';

import CompleteOffenderProgramDialog from './CompleteOffenderProgramDialog.vue';
import EndOffenderProgramDialog from './EndOffenderProgramDialog.vue';
import { mapGetters, mapState } from 'vuex';

/**
 * OffenderProgramActions shows UI for buttons to interact
 * with a single Program
 */
export default Vue.extend({
  props: {
    offenderProgramID: Number,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('offender', ['offenderProgram']),
    // program provides the current OffenderProgram object
    program(): Program | null {
      return this.offenderProgram[this.offenderProgramID] || null;
    },

    // status returns either the status of the current Program or 'unknown'
    // if the program is not found
    status(): string {
      if (this.program === null) {
        return 'unknown';
      }
      return this.program.status;
    },
  },
  components: {
    CompleteOffenderProgramDialog,
    EndOffenderProgramDialog,
  },
});
