


































































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import router from '@/router';
import courtAPI from '@/vuex/court/courtAPI';
import { CaseTopicID, LookupItem } from '@/vuex/court/options';
import { Participant } from '@/vuex/court/participant';
import { PartyTypeID } from '@/vuex/court/party';
import { SubjectProperty } from '@/vuex/court/subjectProperty';
import { VForm } from '@/lib/vue-typescript';

import CaseTypeSelector from '@/components/court/CaseTypeSelector.vue';
import ParticipantsForm from '@/components/court/participant/ParticipantsForm.vue';
import SubjectPropertiesForm from '@/components/subjectProperty/SubjectPropertiesForm.vue';

export default Vue.extend({
  props: {
    value: Boolean,
    title: String,
    forceCaseType: String,
  },

  data() {
    return {
      number: '',
      type: 'civil', // Can be 'civil', 'state', or 'criminal'
      departmentID: 0,
      filedAt: moment().format(),
      lawsuitTypeIDs: [],
      topicID: 0,
      participants: [] as Participant[],
      subjectProperties: [] as SubjectProperty[],
      error: null as any,
    };
  },

  watch: {
    type: {
      handler(newVal, oldVal) {
        if (newVal === 'criminal') {
          this.topicID = 0;
        }
        if (newVal === 'civil') {
          this.topicID = 1; // Property
        }
        if (newVal === 'state') {
          this.topicID = 4; // Administrative
        }
        this.resetParticipants();
      },
      immediate: true,
    },
    forceCaseType(newVal) {
      this.type = newVal || 'civil';
    },
  },

  computed: {
    ...mapState('court', ['partyTypes', 'civilCaseTopics']),
    ...mapGetters('language', ['nameForIDInCollection', 'valueForLocale']),

    caseTopics(): LookupItem[] {
      if (this.type === 'state') {
        return this.civilCaseTopics.filter((t) => t.id !== CaseTopicID.Family);
      }
      return this.civilCaseTopics;
    },
  },

  methods: {
    reset(): void {
      (this.$refs.form as VForm).resetValidation();

      this.number = '';
      this.type = 'civil';
      this.departmentID = 0;
      this.filedAt = moment().format();
      this.lawsuitTypeIDs = [];
      this.topicID = 0;
      this.resetParticipants();
      this.subjectProperties = [] as SubjectProperty[];
      this.error = null;
    },

    save(): void {
      if (!this.validate()) {
        return;
      }
      this.error = null;
      courtAPI
        .post('dossiers/create', {
          data: {
            type: this.type,
            number: this.number,
            departmentID: this.departmentID,
            filedAt: this.filedAt,
            lawsuitTypeIDs: this.lawsuitTypeIDs,
            topicID: this.topicID,
            participants: this.participants,
            subjectProperties: this.subjectProperties,
          },
        })
        .then((response) => {
          const newState = response.data.data;
          this.$store.commit('court/setState', newState);
          const newDossierID = Object.keys(newState.dossier)[0];

          // Attach the attachments
          (this.$refs.attachments as any).updateEntityID(newDossierID);

          this.reset();
          this.close();
          router.push({
            name: 'dossierShow',
            params: { id: newDossierID },
          });
        })
        .catch((err) => {
          this.error = err;
        });
    },

    close(): void {
      this.reset();
      this.$emit('input', false);
    },

    validate(): boolean {
      return (this.$refs.form as VForm).validate();
    },

    /**
     * resetParticipants re-creates the array with empty parties that
     * are appropriate to the case type (e.g. petitioner and respondent for
     * civil)
     */
    resetParticipants() {
      let typeIDs = [] as number[];

      if (this.type === 'criminal') {
        typeIDs = [PartyTypeID.Defendant];
      }
      if (this.type === 'civil') {
        typeIDs = [PartyTypeID.Plaintiff, PartyTypeID.Respondent];
      }
      if (this.type === 'state') {
        typeIDs = [PartyTypeID.Petitioner, PartyTypeID.Respondent];
      }

      this.participants = typeIDs.map((typeID) => {
        return {
          partyID: 0,
          partyTypeID: typeID,
          originalPartyTypeID: typeID,
          sentenceComponents: [],
        };
      });
    },
  },

  components: {
    CaseTypeSelector,
    ParticipantsForm,
    SubjectPropertiesForm,
  },
});
