














import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Holiday } from '@/vuex/court/holiday';
import courtAPI from '@/vuex/court/courtAPI';
import HolidayForm from './HolidayForm.vue';
export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isOpen: false,
      error: null as any,

      updatedHoliday: null as Holiday | null,
    };
  },
  computed: {
    ...mapGetters('court', ['holidayWithID']),
  },
  watch: {
    /**
     * isOpen is watched because we need to notice when the dialog's activator
     * has been clicked (which opens the dialog). This is our trigger to fetch
     * a dialog-local copy of the object to present to the user for editing.
     */
    isOpen(nowOpening: boolean): void {
      if (nowOpening && this.id) {
        const myCopy = Object.assign({}, this.holidayWithID(this.id));
        this.updatedHoliday = myCopy;
      }
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.updatedHoliday = null;
    },
    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.updatedHoliday) {
        return;
      }
      this.error = null;

      try {
        const response = await courtAPI.post('holidays/update', {
          data: {
            id: this.updatedHoliday.id,
            name: this.updatedHoliday.name,
            locationGeographyID: this.updatedHoliday.locationGeographyID,
            date: this.updatedHoliday.date,
          },
        });
        // Update Vuex state
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    HolidayForm,
  },
});
