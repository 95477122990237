

































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString } from '@/lib/criteria';
import {
  AllDocketEventTypeModules,
  DocketEventTypeModule,
} from '@/vuex/court/options';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    module: String,
    criteria: Object,
  },

  data() {
    return {
      loading: false,
      error: null as any,

      internalModule: this.module || DocketEventTypeModule.Investigation,

      filedBetween: '',

      results: [] as any[],
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),

    items(): any[] {
      return _.map(this.results, (row) => ({
        ...row,
        docketEventType: this.nameForIDInCollection(
          row.docketEventTypeID,
          'court/docketEventTypes',
        ),
      }));
    },

    /**
     * fullCriteria builds the actual API criteria which will be sent. This is
     * done by combining the incoming criteria prop with customizations set
     * directly on this component.
     */
    fullCriteria(): any {
      return Object.assign({}, this.criteria, {
        activeOnly: false, // TODO Find a way to avoid needing to override this.
        module: this.internalModule,
        filedBetween: this.filedBetween,
      });
    },

    /**
     * queryString computes the parameters to send to the API. This must
     * be a computed property so that it can be watched to trigger a new
     * API call.
     */
    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },

    /**
     * moduleChoices builds the array of objects to feed to the module selector
     */
    moduleChoices(): VSelectItem[] {
      return AllDocketEventTypeModules.map((m) => ({
        value: m,
        text: i18n.t('docketEventType.module.' + m),
      }));
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a computed property so
     * that it will update with new translations when the locale changes.
     */
    headers(): any[] {
      return [
        { text: i18n.t('docketEventType.singular'), value: 'docketEventType' },
        {
          text: i18n.t('count'),
          align: 'right',
          value: 'eventCount',
        },
        {
          text: '# ' + i18n.t('dossier.plural'),
          align: 'right',
          value: 'dossierCount',
        },
      ];
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      try {
        const url = `/docket-event-counts?${this.queryString}`;
        const response = await investigationAPI.get(url);
        this.results = _.get(response, 'data.data', {});
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
});
