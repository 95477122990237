
















import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Profile } from '@/vuex/profile/profile';

import EditIdentityDialog from '@/components/profile/EditIdentityDialog.vue';
import ProfileIdentityDisplay from '@/components/profile/ProfileIdentityDisplay.vue';

export default Vue.extend({
  props: {
    id: Number,
    allowEdit: Boolean,
    tile: Boolean,
    flat: Boolean,
    dense: Boolean,
  },

  computed: {
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('geography', ['fullGeographyName']),

    profile(): Profile {
      return this.profileWithID(this.id);
    },
  },

  components: {
    EditIdentityDialog,
    ProfileIdentityDisplay,
  },
});
