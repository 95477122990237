




















import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Dossier } from '@/vuex/court/dossier';

import DossierLookupField from '@/components/dossier/DossierLookupField.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
  },
  data() {
    return {
      isOpen: false,
      relatedDossierID: 0,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('court', ['dossierWithID']),
    dossier(): Dossier {
      return this.dossierWithID(this.relatedDossierID);
    },

    isDuplicate(): boolean {
      return this.relatedDossierID === this.dossierID;
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.relatedDossierID = 0;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post(
          'dossiers/related-dossiers/create',
          {
            data: {
              dossierID: this.dossierID,
              relatedDossierID: this.relatedDossierID,
              relatedDossierNumber: this.dossier.number,
            },
          },
        );
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/setTarget', {
          target: 'relatedDossierIDsForDossier',
          index: this.dossierID,
          value: Object.keys(newState.relatedDossier),
        });
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    DossierLookupField,
  },
});
