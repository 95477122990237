




















import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { User } from '@/vuex/user/user';

export default Vue.extend({
  data() {
    return {
      waiting: true,
    };
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', [
      'allowedDepartmentIDs',
      'allowedCourtIDs',
      'allowedFacilityIDs',
      'hasPermission',
    ]),

    roles(): any[] {
      return this.user.roles;
    },

    dashboardRoute(): any {
      if (this.detectionDashboardRoute) {
        return this.detectionDashboardRoute;
      } else if (this.investigationDashboardRoute) {
        return this.investigationDashboardRoute;
      } else if (this.courtDashboardRoute) {
        return this.courtDashboardRoute;
      } else if (this.offenderDashboardRoute) {
        return this.offenderDashboardRoute;
      } else if (this.userHomeRoute) {
        return this.userHomeRoute;
      }
      return false;
    },

    /**
     * detectionDashboardRoute computes the Route object to redirect this user
     * to for their Detection dashboard. If they don't have permissions to
     * access the Detection Dashboard, false is returned instead.
     *
     * TODO This code duplicates a computed property in PrimaryDetectionNav.
     * It would probably be better to have the DetectionDashboard not require
     * any incoming parameters and figure out the correct default permissions.
     */
    detectionDashboardRoute(): any {
      if (!this.hasPermission('dashboard.detection')) {
        return false;
      }
      const allowedIDs = this.allowedDepartmentIDs;
      if (allowedIDs.length < 1) {
        // Early exit without building a route if the User has no allowed IDs
        return false;
      }
      const route = { name: 'detectionDashboard', params: {}, query: {} };
      if (_.includes(allowedIDs, '*')) {
        route.query = { departmentID: 1000000002 };
      } else {
        route.query = { departmentID: allowedIDs[0] };
      }
      return route;
    },

    /**
     * investigationDashboardRoute computes the Route object to redirect this user
     * to for their Investigation dashboard. If they don't have permissions to
     * access the Investigation Dashboard, false is returned instead.
     *
     * TODO This code duplicates a computed property in PrimaryInvestigationNav.
     * It would probably be better to have the InvestigationDashboard not require
     * any incoming parameters and figure out the correct default permissions.
     */
    investigationDashboardRoute(): any {
      if (!this.hasPermission('dashboard.investigation')) {
        return false;
      }
      const allowedIDs = this.allowedDepartmentIDs;
      if (allowedIDs.length < 1) {
        // Early exit without building a route if the User has no allowed IDs
        return false;
      }

      const route = { name: 'investigationDashboard', params: {}, query: {} };
      if (_.includes(allowedIDs, '*')) {
        route.query = {};
      } else {
        route.query = { lastDepartmentID: allowedIDs[0] };
      }
      return route;
    },

    /**
     * courtDashboardRoute computes the Route object to redirect this user
     * to for their Court dashboard. If they don't have permissions to
     * access the Court Dashboard, false is returned instead.
     *
     * TODO This code duplicates a computed property in PrimaryCourtNav.
     * It would probably be better to have the CourtDashboard not require
     * any incoming parameters and figure out the correct default permissions.
     */
    courtDashboardRoute(): any {
      if (!this.hasPermission('dashboard.court')) {
        return false;
      }

      const allowedIDs = this.allowedCourtIDs;
      if (allowedIDs.length < 1) {
        // Early exit without building a route if the User has no allowed IDs
        return false;
      }

      const route = { name: 'courtDashboard', params: {}, query: {} };
      if (_.includes(allowedIDs, '*')) {
        route.query = {};
      } else {
        route.query = { courtID: allowedIDs[0] };
      }
      return route;
    },

    /**
     * offenderDashboardRoute computes the Route object to redirect this user
     * to for their Offender dashboard. If they don't have permissions to
     * access the Offender Dashboard, false is returned instead.
     *
     * TODO This code duplicates a computed property in PrimaryPrisonNav.
     * It would probably be better to have the CourtDashboard not require
     * any incoming parameters and figure out the correct default permissions.
     */
    offenderDashboardRoute(): any {
      if (!this.hasPermission('dashboard.prison')) {
        return false;
      }

      const allowedIDs = this.allowedFacilityIDs;

      if (allowedIDs.length < 1) {
        // Early exit without building a route if the User has no allowed IDs
        return false;
      }

      const route = { name: 'offenderDashboard', query: {} };
      if (_.includes(allowedIDs, '*')) {
        route.query = {};
      } else {
        route.query = { facilityID: allowedIDs[0] };
      }
      return route;
    },

    /**
     * userHomeRoute computes the Route object to redirect this user
     * to a default page if they DO NOT have permissions for any dashboard
     */
    userHomeRoute(): any {
      if (
        this.hasPermission('dashboard.prison') ||
        this.hasPermission('dashboard.offender') ||
        this.hasPermission('dashboard.court') ||
        this.hasPermission('dashboard.investigation') ||
        this.hasPermission('dashboard.detection')
      ) {
        return false;
      }
      const route = { name: 'userHome', query: {} };
      return route;
    },
  },

  watch: {
    user: {
      immediate: true,
      handler(newUser: User) {
        if (newUser && newUser.id) {
          if (this.dashboardRoute) {
            this.$router.push(this.dashboardRoute);
          } else {
            console.log('No Dashboards');
          }
        }
      },
    },
  },
});
