











































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { Case } from '@/vuex/court/case';
import { CourtDecisionTypes } from '@/vuex/court/cms1';

import ChargesSummary from '@/components/charges/ChargesSummary.vue';
import SentenceSummary from '@/components/court/SentenceSummary.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['caseWithID', 'courtName']),

    payload(): any {
      return _.get(this, 'entry.payload', {});
    },

    kase(): Case {
      return this.caseWithID(this.payload.caseID);
    },

    hasDefenseAttorneyInfo(): boolean {
      if (!this.payload) {
        return false;
      }
      if (this.payload.attName) {
        return true;
      }
      if (this.payload.defenseAttorney === 2) {
        return true;
      }
      return false;
    },

    courtDecisionTypes(): string[] {
      const courtDecType = _.get(this.payload, 'courtDecType', null);
      const rulingType = _.get(this.payload, 'rulingType', null);
      const decDate = _.get(this.payload, 'decDate', null);
      const hearingDate = _.get(this.payload, 'hearingDate', null);
      const rulingDate = _.get(this.payload, 'rulingDate', null);

      if (!courtDecType) {
        if (decDate) {
          return [i18n.t('decision.singular').toString()];
        }
        if (hearingDate) {
          return [i18n.t('hearing.singular').toString()];
        }
        if (rulingDate) {
          return [i18n.t('ruling.singular').toString()];
        }
        return ['Unknown'];
      }

      const types = courtDecType.toString().split(',');
      return _.chain(types)
        .map((t) => {
          const type = CourtDecisionTypes[t];
          if (type) {
            return type[this.locale];
          } else {
            return null;
          }
        })
        .value();
    },
  },
  components: {
    ChargesSummary,
    SentenceSummary,
  },
});
