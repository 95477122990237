var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.results.length)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ExpandableCard',{staticClass:"fill-height",attrs:{"title":_vm.$t('geo.plural.' + _vm.mostCommonGeographyType),"value":_vm.value,"flat":"","dense":"","color":"grey lighten-3"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.results,"headers":_vm.headers,"hide-default-footer":_vm.results.length < 10,"no-data-text":_vm.$t('error.nodata')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('router-link',{attrs:{"to":{
                name: 'courtDashboard',
                query: Object.assign({}, _vm.criteria,
                  {locationID: item.locationID}),
              }}},[_c('GeographyName',{attrs:{"id":item.locationID}})],1)],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'caseSearch',
                query: Object.assign({}, _vm.criteria,
                  {locationID: item.locationID}),
              }}},[_vm._v(" "+_vm._s(item.activeCaseCount)+" ")])],1),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s((item.averageAge || 0).toFixed(1))+" ")])])]}}],null,false,2038631204)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }