













import _ from 'lodash';
import Vue from 'vue';

import geographyAPI from '@/vuex/geography/geographyAPI';
import { AfghanistanID, Geography } from '@/vuex/geography/geography';
import { blankTranslatedString } from '@/lib/translated';

import GeographyForm from '@/components/geography/GeographyForm.vue';

export default Vue.extend({
  props: {
    initialParentID: Number,
    initialType: String,
  },

  data() {
    return {
      isOpen: false,
      error: null as any,

      geography: null as Geography | null,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      if (!this.initialParentID && !this.initialType) {
        this.geography = null;
      } else {
        this.geography = {
          id: 0,
          name: blankTranslatedString(),
          type: this.initialType || '',
          code: '',
          iso3166Code: '',
          parentID: this.initialParentID || AfghanistanID,
          path: '',
        };
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.geography) {
        return;
      }

      this.error = null;
      try {
        const response = await geographyAPI.post('geographies/create', {
          data: {
            name: this.geography.name,
            type: this.geography.type,
            code: this.geography.code,
            iso3166Code: this.geography.iso3166Code,
            parentID: this.geography.parentID,
            path: this.geography.path,
          },
        });
        const newState = response.data.data;
        this.$store.commit('geography/setState', newState);
        this.$store.commit('geography/prependToTarget', {
          target: 'geographyIDs',
          value: Object.keys(newState.geography),
        });
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    GeographyForm,
  },
});
