

































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import AddHousingFacilityDialog from '@/components/offender/facility/AddHousingFacilityDialog.vue';
import HousingFacilityContainer from '@/components/offender/facility/HousingFacilityContainer.vue';

export default Vue.extend({
  data() {
    return {
      search: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('language', ['locale']),
    ...mapGetters('housing', ['transferrableFacilities', 'fullFacilityName']),
  },

  components: {
    AddHousingFacilityDialog,
    HousingFacilityContainer,
  },
});
