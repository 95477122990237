
















































































































































































































































































































































































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import { AfghanistanID } from '@/vuex/geography/geography';
import { Profile } from '@/vuex/profile/profile';

export default Vue.extend({
  props: {
    value: Object,
    allowIdentityEdit: Boolean,
  },

  computed: {
    /**
     * profile computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    profile(): Profile {
      const defaultProfile: Profile = {
        id: 0,
        partyID: 0,
        name: '',
        surname: '',
        fatherName: '',
        grandfatherName: '',
        birthplaceGeographyID: AfghanistanID,
        residenceGeographyID: AfghanistanID,
        nationalityID: 1,
        aliases: [],
        birthdate: '',
        birthdateIsApproximate: true,
        heightInCm: 0,
        weightInKg: 0,
        hairColorID: 0,
        eyeColorID: 0,
        genderID: 1,
        criminalGroupIDs: [],
        criminalGroupRoleID: 0,
        infectiousDiseaseIDs: [],
        mentalDisorderIDs: [],
        motherChildConcernIDs: [],
        bloodType: 'Unknown',
        maritalStatusID: 0,
        numberOfChildren: 0,
        nativeLanguageID: 0,
        secondLanguageID: 0,
        nationalID: '',
        eNationalID: '',
        passportNumber: '',
        isMilitary: false,
        militaryRankID: 0,
        occupation: '',
        description: '',
        identifyingMarks: '',
        canWrite: false,
        canRead: false,
        educationLevelID: 0,
        familyContact: '',
        visitors: '',
        phoneNumber: '',
        emailAddress: '',
        isKochi: false,
      };
      return this.value ? this.value : defaultProfile;
    },

    /**
     * nameRules computes the array of rule funcs that is passed to the
     * rules prop on each of the 4 name fields. It needs to be a computed
     * prop so that it can turn off validation if the profile fields are not
     * allowed to be edited on this screen.
     */
    nameRules(): any[] {
      if (!this.allowIdentityEdit) {
        return [];
      }
      return [(v) => !!v || i18n.t('error.required')];
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, val: any, modifier: string = ''): void {
      if (modifier === 'trim') {
        val = val.trim();
      }

      const newP = tap(cloneDeep(this.profile), (v) => set(v, key, val));

      if (newP.criminalGroupIDs.length < 1) {
        // When no Criminal Groups are selected, we don't want a
        // CriminalGroupRole set
        newP.criminalGroupRoleID = 0;
      }
      if (newP.criminalGroupIDs.length >= 1 && newP.criminalGroupRoleID === 0) {
        // When at least one Criminal Group is set, don't allow Unknown to be
        // set for the CriminalGroupRoleID
        newP.criminalGroupRoleID = 1;
      }

      this.$emit('input', newP);
    },

    /**
     * updateEntityID wraps the same-named function on the
     * AddImageAttachmentDialog components embedded on the ProfileForm. Since
     * they are not easily accessible to consumers of ProfileForm, this function
     * just calls updateEntityID on each embedded component.
     */
    updateEntityID(entityID: number): void {
      (this.$refs.frontUpload as any).updateEntityID(entityID);
      (this.$refs.sideUpload as any).updateEntityID(entityID);
      (this.$refs.rightSideUpload as any).updateEntityID(entityID);
    },
  },
});
