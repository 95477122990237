


































































































































































import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      error: null as any,

      results: [],
    };
  },
  computed: {
    headers(): any[] {
      return [
        { text: i18n.t('dashboard.name'), value: 'name', width: '330px' },

        {
          text: i18n.t('dashboard.headcount'),
          value: 'headcount',
          align: 'right',
        },
        {
          text: i18n.t('offender.detainee.plural'),
          value: 'detaineeCount',
          align: 'right',
        },
        {
          text: this.prisonersHeader,
          value: 'prisonerCount',
          align: 'right',
        },
        {
          text: i18n.t('adult.plural'),
          value: 'adultCount',
          align: 'right',
        },
        {
          text: i18n.t('juvenile.plural'),
          value: 'juvenileCount',
          align: 'right',
        },
        {
          text: i18n.t('dashboard.pastDue'),
          value: 'pastDueCount',
          align: 'right',
        },
        {
          text: i18n.t('offender.nearingRelease'),
          value: 'nearingReleaseCount',
          align: 'right',
        },
        {
          text: i18n.t('offender.imminentRelease'),
          value: 'imminentReleaseCount',
          align: 'right',
        },
      ];
    },
    /**
     * mostCommonType scans the list of facilities and returns the one that
     * occurs most frequently. This is used to create a summary title.
     */
    mostCommonType(): string {
      const types = _.map(this.results, 'type') as string[];
      return _.chain(types)
        .countBy() // Produces { "facility": 14, "cell": 10 }
        .entries() // Converts to [["facility", 14], ["cell", 10]]
        .maxBy(_.last) // Filters to ["facility", 14]
        .head() // Retrieves first element ("facility")
        .value() as string; // Exit lodash chain and type-assert to string
    },

    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    prisonersHeader(): string {
      const jc = _.chain(this.results).map('juvenileCount').sum().value();
      const ac = _.chain(this.results).map('adultCount').sum().value();
      if (jc > 0 && ac < 1) {
        return i18n.t('offender.confinee.plural').toString();
      } else {
        return i18n.t('offender.prisoner.plural').toString();
      }
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.results = [];
      this.error = null;
      try {
        const url = `/offenders/by-location?${this.queryString}`;
        const res = await offenderAPI.get(url);
        this.results = _.get(res, 'data.data', []);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
});
