














import _ from 'lodash';
import Vue from 'vue';
import i18n from '@/i18n';
import { mapState } from 'vuex';
import { AllRoleEntityTypes, RoleEntityType } from '@/vuex/user/user';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    label: String,
    value: [String, Array],
    rules: Array,
    disabled: Boolean,
    multiple: Boolean,
    chips: Boolean,
    clearable: Boolean,
  },
  computed: {
    ...mapState('court', ['caseTypes']),

    entityTypeChoices(): VSelectItem[] {
      return _.map(AllRoleEntityTypes, (t: RoleEntityType) => ({
        text: i18n.t('role.entityTypeOptions.' + t),
        value: t,
      }));
    },
  },
});
