

















import Vue from 'vue';
export default Vue.extend({
  props: [
    'mimeType',
    'color',
    'small',
    'large',
    'dense',
    'disabled',
    'left',
    'light',
    'dark',
    'xSmall',
    'xLarge',
  ],
  computed: {
    icon(): string {
      if (this.mimeType.search('image') === 0) {
        return 'fa-file-image';
      }
      if (this.mimeType.search('application/pdf') === 0) {
        return 'fa-file-pdf';
      }
      if (
        this.mimeType.search('spreadsheet') > 0 ||
        this.mimeType.search('excel') > 0
      ) {
        return 'fa-file-excel';
      }
      if (this.mimeType.search('word') > 0) {
        return 'fa-file-word';
      }
      return 'fa-file';
    },
  },
});
