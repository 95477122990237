


































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';

import { Role, RoleEntityType, UserRole } from '@/vuex/user/user';

import NewUserRoleDialog from '@/components/user/NewUserRoleDialog.vue';

export default Vue.extend({
  props: {
    value: Array,
  },

  computed: {
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('user', ['roleWithID']),
    ...mapGetters('court', ['fullCourtName']),
    ...mapGetters('investigation', ['fullDepartmentName']),
    ...mapGetters('housing', ['fullFacilityName']),

    /**
     * translatedUserRoles computes the array of entries in the table.
     * It's based on the v-model value provided to this component, but
     * translates values and prepares for display in the table. In order to
     * avoid polluting the original objects, this computed prop creates
     * copies of each Object with Object.assign.
     */
    translatedUserRoles(): any[] {
      return _.map(this.value || [], (ur: UserRole) => {
        const translatedEntityType = i18n
          .t('role.entityTypeOptions.' + ur.entityType)
          .toString();
        let entityName = '';
        let roleName = '';
        const role: Role = this.roleWithID(ur.roleID);
        if (role) {
          roleName = this.valueForLocale(role.name);
        }

        switch (ur.entityType) {
          case RoleEntityType.Court:
            entityName = this.fullCourtName(ur.entityID);
            break;
          case RoleEntityType.Department:
            entityName = this.fullDepartmentName(ur.entityID);
            break;
          case RoleEntityType.HousingFacility:
            entityName = this.fullFacilityName(ur.entityID);
            break;
        }

        if (ur.hasAllEntities) {
          entityName = i18n.t('all').toString();
        }

        return Object.assign({}, ur, {
          translatedEntityType,
          entityName,
          roleName,
        });
      });
    },

    /**
     * headers computes the array of column headers for the VDataTable
     */
    headers(): any[] {
      const h = [
        { text: i18n.t('role.entityType'), value: 'translatedEntityType' },
        { text: i18n.t('role.singular'), value: 'roleName' },
        { text: i18n.t('role.entityName'), value: 'facilityName' },
        { text: '', value: null, width: '100px', sortable: false },
      ];
      return h;
    },
  },

  methods: {
    /**
     * addUserRole is the handler for the @save event emitted by
     * NewUserRoleDialog. It adds the newly-created UserRole object to the
     * array and emits the new array.
     */
    addUserRole(ur: UserRole): void {
      const newRoles = (this.value ? this.value : []) as UserRole[];
      newRoles.push(ur);
      this.$emit('input', newRoles);
    },

    /**
     * deleteUserRole is the handler for the click event on the delete icon
     * in the table row. It removes the item at the selected index and
     * re-emits the entire array.
     */
    deleteUserRole(index: number): any {
      const newRoles = [...this.value];
      newRoles.splice(index, 1);
      this.$emit('input', newRoles);
    },
  },
  components: {
    NewUserRoleDialog,
  },
});
