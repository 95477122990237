




































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';

interface RevenueCategorySummary {
  cateogryID: number;
  assessedInPuls: number;
  paidInPUls: number;
  balanceInPuls: number;
}

export default Vue.extend({
  props: {
    caseID: Number,
    dossierID: Number,
    cols: Number,
    sm: Number,
  },

  data() {
    return {
      loading: false,
      error: null as null | any,

      items: [] as RevenueCategorySummary[],
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),

    headers(): any[] {
      return [
        { text: i18n.t('fee.category.singular'), value: 'categoryID' },
        {
          text: i18n.t('fee.assessed'),
          value: 'assessedInPuls',
          align: 'right',
        },
        { text: i18n.t('fee.paid'), value: 'paidInPuls', align: 'right' },
        { text: i18n.t('fee.balance'), value: 'balanceInPuls', align: 'right' },
      ];
    },

    url(): string {
      if (this.caseID) {
        return `/cases/${this.caseID}/revenue-summary`;
      }
      return `/dossiers/${this.dossierID}/revenue-summary`;
    },
  },

  watch: {
    url: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const res = await courtAPI.get(this.url);
        this.items = _.get(res, 'data.data', []);
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
});
