














import Vue from 'vue';

import profileAPI from '@/vuex/profile/profileAPI';
import { firstModelIDInState } from '@/lib/vuex-domainmodel';
import { Profile } from '@/vuex/profile/profile';

import ProfileForm from '@/components/profile/ProfileForm.vue';

export default Vue.extend({
  data() {
    return {
      error: null as any,
      isOpen: false,
      profile: null as Profile | null,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.error = null;
      this.profile = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (this.profile == null) {
        return;
      }
      try {
        this.error = null;
        const response = await profileAPI.post('profiles/create', {
          data: {
            name: this.profile.name,
            surname: this.profile.surname,
            fatherName: this.profile.fatherName,
            grandfatherName: this.profile.grandfatherName,
            birthdate: this.profile.birthdate,
            birthdateIsApproximate: this.profile.birthdateIsApproximate,
            aliases: this.profile.aliases,
            birthplaceGeographyID: this.profile.birthplaceGeographyID,
            residenceGeographyID: this.profile.residenceGeographyID,
            nationalityID: this.profile.nationalityID,
            nationalID: this.profile.nationalID,
            eNationalID: this.profile.eNationalID,
            passportNumber: this.profile.passportNumber,
            criminalGroupIDs: this.profile.criminalGroupIDs,
            criminalGroupRoleID: this.profile.criminalGroupRoleID,
            genderID: this.profile.genderID,
            heightInCm: this.profile.heightInCm,
            weightInKg: this.profile.weightInKg,
            hairColorID: this.profile.hairColorID,
            eyeColorID: this.profile.eyeColorID,
            bloodType: this.profile.bloodType,
            maritalStatusID: this.profile.maritalStatusID,
            numberOfChildren: this.profile.numberOfChildren,
            nativeLanguageID: this.profile.nativeLanguageID,
            secondLanguageID: this.profile.secondLanguageID,
            canWrite: this.profile.canWrite,
            canRead: this.profile.canRead,
            educationLevelID: this.profile.educationLevelID,
            occupation: this.profile.occupation,
            isMilitary: this.profile.isMilitary,
            militaryRankID: this.profile.militaryRankID,
            description: this.profile.description,
            familyContact: this.profile.familyContact,
            visitors: this.profile.visitors,
            phoneNumber: this.profile.phoneNumber,
            emailAddress: this.profile.emailAddress,
            isKochi: this.profile.isKochi,
          },
        });

        // Write the state into the profile store
        const newState = response.data.data;
        this.$store.commit('profile/setState', newState);

        // Attach the attachments
        // Any attachments which were uploaded during the Profile creation are
        // stored in the database associated to a random negative ID. This
        // process ensures each attachment get associated with the newly-created
        // Profile ID after its saved.
        const newID = firstModelIDInState(newState, 'profile');
        (this.$refs.profileForm as any).updateEntityID(newID);

        this.$emit('saved', newID);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    ProfileForm,
  },
});
