
































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import offenderAPI from '@/vuex/offender/offenderAPI';

import { Offender } from '@/vuex/offender/offender';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';

import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data(): any {
    return {
      isOpen: false,

      courseType: 'education',
      startedAt: '',
      courseID: 1,
      housingFacilityID: 0,
      notes: '',
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('offender', ['offenderWithID']),
    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      const now = new Date();
      this.startedAt = now.toISOString().substr(0, 10);
      if (this.offender) {
        this.housingFacilityID = this.offender.housingFacilityID;
      }
      this.courseID = 1;
      this.courseType = 'education';
      this.notes = '';
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to trigger the API call to persist the entity to the database.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('programs/begin', {
          data: {
            offenderID: this.offenderID,
            courseID: this.courseID,
            housingFacilityID: this.housingFacilityID,
            startedAt: this.startedAt,
            startNotes: this.notes,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.offenderProgram)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'offenderProgramIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.offenderProgram),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
