


























































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import { LOCALE_MESSAGE_API_SERVER } from '@/vuex/language/language';

import NewLocaleMessageDialog from '@/components/translation/NewLocaleMessageDialog.vue';
import EditLocaleMessageDialog from '@/components/translation/EditLocaleMessageDialog.vue';

export default Vue.extend({
  data() {
    return {
      search: '',
    };
  },

  computed: {
    ...mapGetters('language', ['languages', 'localeMessages']),
    ...mapGetters('auth', ['hasPermission']),

    currentHostname(): string {
      return window.location.hostname;
    },

    localeAPIHostname(): string {
      if (LOCALE_MESSAGE_API_SERVER) {
        return new URL(LOCALE_MESSAGE_API_SERVER).hostname;
      } else {
        return window.location.hostname;
      }
    },

    wrongAPIServer(): boolean {
      return this.currentHostname !== this.localeAPIHostname;
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a computed property so
     * that it will update with new translations when the locale changes.
     */
    headers(): any[] {
      const h = [
        {
          text: i18n.t('command.edit'),
          value: 'edit',
          align: 'center',
          sortable: false,
        },
        { text: 'Key', value: 'key', align: 'start' },
        { text: i18n.t('en'), value: 'message.en' },
        { text: i18n.t('fa'), value: 'message.fa', align: 'right' },
        { text: i18n.t('ps'), value: 'message.ps', align: 'right' },
        {
          text: 'Verified',
          value: 'lastVerifiedAt',
          align: 'center',
        },
      ];
      return h;
    },
  },

  components: {
    NewLocaleMessageDialog,
    EditLocaleMessageDialog,
  },
});
