


























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { PunishmentType } from '@/vuex/crime/crime';

import AddPunishmentTypeDialog from '@/components/crime/punishmentType/NewPunishmentTypeDialog.vue';
import EditPunishmentTypeDialog from '@/components/crime/punishmentType/EditPunishmentTypeDialog.vue';
import PunishmentTypeIcons from '@/components/crime/punishmentType/PunishmentTypeIcons.vue';

export default Vue.extend({
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('crime', ['punishmentTypes', 'punishmentTypeCategories']),
    ...mapState('language', ['locale']),
    headers(): any[] {
      const h = [
        {
          text: i18n.t('punishmentType.singular'),
          value: 'translatedName',
        },
        {
          text: i18n.t('punishmentType.isConsequential'),
          value: 'isConsequential',
          align: 'center',
          width: '2%',
        },
        {
          text: i18n.t('punishmentType.isATI'),
          value: 'isATI',
          align: 'center',
          width: '2%',
        },
        {
          text: 'Features',
          value: null,
          align: 'center',
          width: '20%',
        },
        {
          text: i18n.t('sort.rank'),
          value: 'rank',
          align: 'right',
          width: '2%',
        },
      ];

      if (this.hasPermission('admin.punishmentType.create')) {
        h.unshift({
          text: 'Edit',
          value: 'edit',
          width: '2%',
          align: '',
        });
      }

      return h;
    },
    groupedPunishments(): any {
      return _.chain(this.punishmentTypes)
        .map((pt: PunishmentType) => ({
          ...pt,
          translatedName: pt.name[this.locale],
        }))
        .sortBy(['rank', 'translatedName'])
        .groupBy('categoryID')
        .value();
    },
  },
  components: {
    AddPunishmentTypeDialog,
    EditPunishmentTypeDialog,
    PunishmentTypeIcons,
  },
});
