














import Vue from 'vue';

import DossierHeader from '@/components/dossier/DossierHeader.vue';
import InvestigationDocket from '@/components/docket/InvestigationDocket.vue';
import PrimaryParticipants from '@/components/court/participant/PrimaryParticipants.vue';
import RelatedDossiersTable from '@/components/dossier/RelatedDossiersTable.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  components: {
    DossierHeader,
    InvestigationDocket,
    PrimaryParticipants,
    RelatedDossiersTable,
  },
});
