var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dark":"","flat":"","color":"primary"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.canAdd)?_c('NewCrimeDialog'):_vm._e(),_c('v-spacer'),_vm._t("extension"),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":_vm.$t('advancedSearch')},model:{value:(_vm.advancedSearch),callback:function ($$v) {_vm.advancedSearch=$$v},expression:"advancedSearch"}})]},proxy:true}],null,true)},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('crime.plural')))]),_c('v-spacer'),(!_vm.advancedSearch)?_c('v-text-field',{ref:"query",attrs:{"label":_vm.$t('search'),"single-line":"","hide-details":"","append-icon":"search","clearable":""},on:{"keyup":function($event){_vm.currentPage = 1}},model:{value:(_vm.criteria.q),callback:function ($$v) {_vm.$set(_vm.criteria, "q", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"criteria.q"}}):_vm._e()],1),_c('v-card-text',[_vm._t("header",function(){return [(!_vm.disableStats)?_c('v-row',{staticClass:"mt-6"},_vm._l(([
            'Reviewed',
            'Approved',
            'GiroaReview',
            'GiroaApproved' ]),function(prefix){return _c('v-col',{key:prefix,attrs:{"cols":"3"}},[_c('v-progress-linear',{attrs:{"value":_vm.overallCompletion(prefix),"height":"20","color":"green lighten-2"}},[_vm._v(" "+_vm._s(prefix)+" ")])],1)}),1):_vm._e()]}),(_vm.advancedSearch)?_c('div',[_c('v-row',{staticClass:"mt-6",attrs:{"dense":"","align":"end"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('crime.title')},model:{value:(_vm.criteria.title),callback:function ($$v) {_vm.$set(_vm.criteria, "title", $$v)},expression:"criteria.title"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('CorpusSelector',{attrs:{"zero-label":"all"},model:{value:(_vm.criteria.corpusID),callback:function ($$v) {_vm.$set(_vm.criteria, "corpusID", $$v)},expression:"criteria.corpusID"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Review Status","items":[
              { text: 'All', value: '' },
              { text: 'Needs Review', value: 'needsReview' },
              { text: 'Needs Approval', value: 'needsApproval' },
              { text: 'Needs GIRoA Review', value: 'needsGiroaReview' },
              {
                text: 'Needs GIRoA Approval',
                value: 'needsGiroaApproval',
              },
              { text: 'Completed', value: 'completed' } ]},model:{value:(_vm.criteria.reviewStatus),callback:function ($$v) {_vm.$set(_vm.criteria, "reviewStatus", $$v)},expression:"criteria.reviewStatus"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('LookupTableItemSelector',{attrs:{"label":_vm.$t('crime.validImprisonmentDurations'),"items":"crime/imprisonmentDurations","zero-label":"all"},model:{value:(_vm.criteria.imprisonmentDurationID),callback:function ($$v) {_vm.$set(_vm.criteria, "imprisonmentDurationID", $$v)},expression:"criteria.imprisonmentDurationID"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('LookupTableItemSelector',{attrs:{"label":_vm.$t('charge.severity'),"items":"crime/severities","zero-label":"all","disable-sorting":""},model:{value:(_vm.criteria.severityID),callback:function ($$v) {_vm.$set(_vm.criteria, "severityID", $$v)},expression:"criteria.severityID"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('IntField',{attrs:{"label":_vm.$t('crime.article'),"type":"number","clearable":""},model:{value:(_vm.criteria.article),callback:function ($$v) {_vm.$set(_vm.criteria, "article", $$v)},expression:"criteria.article"}})],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"mt-6",attrs:{"dense":"","items":_vm.crimes,"headers":_vm.headers,"show-select":_vm.selectable,"items-per-page":15,"search":_vm.criteria.q,"item-class":_vm.rowClasses,"page":_vm.currentPage,"footer-props":{
        'items-per-page-options': [15, 20, 40, 50, 100],
      }},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('EditCrimeDialog',{attrs:{"id":item.id}})]}},{key:("item.corpus.title." + _vm.locale),fn:function(ref){
      var value = ref.value;
return [_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(value))])]}},{key:"item.reviewScore",fn:function(ref){
      var item = ref.item;
return [_c('v-progress-circular',{attrs:{"size":"20","value":item.reviewScore,"color":_vm.reviewColor(item.reviewScore)}})]}}],null,true),model:{value:(_vm.selectedCrimes),callback:function ($$v) {_vm.selectedCrimes=$$v},expression:"selectedCrimes"}})],2),(_vm.$slots.actions)?_c('v-divider'):_vm._e(),(_vm.$slots.actions)?_c('v-card-actions',[_vm._t("actions")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }