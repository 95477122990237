






















import Vue from 'vue';

import ExamsTable from '@/components/offender/medical/ExamsTable.vue';
import HealthHistoryRecordTable from '@/components/offender/medical/HealthHistoryRecordTable.vue';
import IntakeHealthScreeningTable from '@/components/offender/medical/IntakeHealthScreeningTable.vue';
import TreatmentsTable from '@/components/offender/medical/TreatmentsTable.vue';

export default Vue.extend({
  props: {
    id: Number,
    contentOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ExamsTable,
    HealthHistoryRecordTable,
    IntakeHealthScreeningTable,
    TreatmentsTable,
  },
});
