








































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import SubjectPropertyLabel from '@/components/subjectProperty/SubjectPropertyLabel.vue';

export default Vue.extend({
  props: {
    ids: [Number, Array],
    objects: [Object, Array],
    dossierID: Number,
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', [
      'subjectPropertyForDossier',
      'subjectPropertyWithID',
      'subjectPropertyForDossier',
    ]),
    subjectProperties(): SubjectProperty[] {
      if (this.ids) {
        const ids = _.flatten([this.ids]);
        return _.map(ids, this.subjectPropertyWithID);
      }
      if (this.objects) {
        return _.flatten([this.objects]);
      }
      if (this.dossierID) {
        return this.subjectPropertyForDossier(this.dossierID) || [];
      }
      return [];
    },
    subjectPropertiesTotalPuls(): number {
      const validSubjectProperties = _.filter(this.subjectProperties, [
        'isDeleted',
        false,
      ]) as SubjectProperty[];
      return _.sum(
        _.map(
          validSubjectProperties,
          (subjectProperty) => subjectProperty.totalValueInPuls,
        ),
      );
    },
    needsTotal(): boolean {
      if (this.subjectProperties.length > 1) {
        return true;
      }
      return _.some(this.subjectProperties, (sp) => sp.quantity > 1);
    },
  },
  methods: {
    truncateLabel(label): string {
      return _.truncate(label, { length: 30 });
    },
  },
  components: {
    SubjectPropertyLabel,
  },
});
