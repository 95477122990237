










import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [] as number[],
    },
  },
  created() {
    this.valueChangedFromOutside(this.value);
  },
  data() {
    return {
      realValue: [] as number[],
      displayedValue: null as string | null,
    };
  },
  watch: {
    value(newValue) {
      this.valueChangedFromOutside(newValue);
    },
  },
  methods: {
    valueChangedFromOutside(newValue) {
      if (newValue) {
        this.realValue = newValue;
        this.displayedValue = this.realValue.join(', ');
      } else {
        this.displayedValue = null;
        this.realValue = [];
      }
    },
    updateValue() {
      if (!this.displayedValue) {
        this.realValue = [];
      } else {
        this.realValue = _.uniq(
          _.compact(
            this.displayedValue.split(/\s*[, ]\s*/).map((s) => parseInt(s, 10)),
          ),
        );
        this.displayedValue = this.realValue.join(', ');
      }
      this.$emit('input', this.realValue);
    },
  },
});
