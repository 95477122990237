



























































































































import _ from 'lodash';
import Vue from 'vue';
import Draggable from 'vuedraggable';
import { mapState, mapGetters } from 'vuex';

import AddCustomFieldDialog from './AddCustomFieldDialog.vue';
import EditCustomFieldDialog from './EditCustomFieldDialog.vue';

export default Vue.extend({
  props: {
    value: Array,
  },
  data() {
    return {
      addingField: false,
    };
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapState('court', ['partyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * headers builds the column headers for the data table.
     */
    headers(): any[] {
      return [
        { value: `index`, text: '' },
        { value: 'edit', text: '' },
        { value: 'name', text: 'Name' },
        { value: 'type', text: 'Type' },
        { value: 'typeArgs', text: 'Args' },
        { value: 'delete', text: '' },
      ];
    },

    /**
     * alreadyUsedNames maintains a list of the custom field names that exist
     * in the table. It's passed to the field editor to prevent the user
     * from adding a second field with the same name as an existing one.
     */
    alreadyUsedNames(): string[] {
      return _.map(this.value || [], 'name');
    },
  },

  methods: {
    addField(field: any): void {
      // console.log('addField', field);
      const newItems = [...this.value, field];
      this.$emit('input', newItems);
    },

    deleteField(index: number): void {
      const newItems = [...this.value];
      newItems.splice(index, 1);
      this.$emit('input', newItems);
    },
  },

  components: {
    AddCustomFieldDialog,
    Draggable,
    EditCustomFieldDialog,
  },
});
