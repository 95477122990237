






























import Vue from 'vue';
import i18n from '@/i18n';

enum Units {
  Milligrams = 1,
  Nakhud = 2,
  Gram = 3,
  Misqaal = 4,
  Toolee = 5,
  Khurd = 6,
  Paaw = 7,
  Chaarak = 8,
  Sayr = 9,
  Man = 10,
  Kharwaar = 11,
}
const AllUnits = [
  Units.Milligrams,
  Units.Nakhud,
  Units.Gram,
  Units.Misqaal,
  Units.Toolee,
  Units.Khurd,
  Units.Paaw,
  Units.Chaarak,
  Units.Sayr,
  Units.Man,
  Units.Kharwaar,
];

export default Vue.extend({
  props: {
    value: Number,
    clearable: Boolean,
    label: String,
    hint: String,
    prefix: String,
    disabled: Boolean,
    readonly: Boolean,
    persistentHint: Boolean,
    hideDetails: Boolean,
    singleLine: Boolean,
    loading: Boolean,
    rules: Array,
  },
  data() {
    return {
      internalValue: 0,
      units: Units.Milligrams,
    };
  },
  created() {
    this.outsideValueChanged(this.value);
  },
  computed: {
    /**
     * unitsOptions builds the v-select choice values for the unit
     * selector dropdown control.
     */
    unitsOptions(): any[] {
      return AllUnits.map((u: Units) => {
        const translationKey = 'weight.' + Units[u].toLowerCase();
        return {
          value: u,
          text: i18n.t(translationKey).toString(),
        };
      });
    },
  },
  watch: {
    value(newValue: number) {
      this.outsideValueChanged(newValue);
    },
  },
  methods: {
    /**
     * outsideValueChanged is the event that is fired when the v-model prop
     * is provided with a new value. We always interpret this value as
     * being in Milligrams, so we update the internal units to Milligrams and
     * perform a reduction.
     */
    outsideValueChanged(newVal: number): void {
      this.internalValue = newVal;
      this.units = Units.Milligrams;
      this.reduce();
    },

    /**
     * emit notifies v-model consumers of the current value via the 'emit'
     * event. We always $emit in Milligrams, regardless of what the internally
     * displayed units are.
     */
    emit(): void {
      this.reduce();
      const val = this.toMilligrams(this.internalValue, this.units);
      this.$emit('input', val);
    },

    /**
     * reduce converts the current internal value + units into the simplest
     * (i.e. "most reduced") form. This function has no effect on the
     * externally data-bound (e.g. "emitted") value, it merely changes the
     * internal display of that value to be in the simplest form.
     */
    reduce(): void {
      const milligrams = this.toMilligrams(this.internalValue, this.units);
      if (milligrams % 4529848320 === 0) {
        this.internalValue = milligrams / 4529848320;
        this.units = Units.Kharwaar;
      } else if (milligrams % 56623104 === 0) {
        this.internalValue = milligrams / 56623104;
        this.units = Units.Man;
      } else if (milligrams % 7077888 === 0) {
        this.internalValue = milligrams / 7077888;
        this.units = Units.Sayr;
      } else if (milligrams % 1769472 === 0) {
        this.internalValue = milligrams / 1769472;
        this.units = Units.Chaarak;
      } else if (milligrams % 442368 === 0) {
        this.internalValue = milligrams / 442368;
        this.units = Units.Paaw;
      } else if (milligrams % 110592 === 0) {
        this.internalValue = milligrams / 110592;
        this.units = Units.Khurd;
      } else if (milligrams % 18432 === 0) {
        this.internalValue = milligrams / 18432;
        this.units = Units.Toolee;
      } else if (milligrams % 4608 === 0) {
        this.internalValue = milligrams / 4608;
        this.units = Units.Misqaal;
      } else if (milligrams % 1000 === 0) {
        this.internalValue = milligrams / 1000;
        this.units = Units.Gram;
      } else if (milligrams % 192 === 0) {
        this.internalValue = milligrams / 192;
        this.units = Units.Nakhud;
      } else {
        this.internalValue = milligrams;
        this.units = Units.Milligrams;
      }
    },

    /**
     * toMilligrams takes a value and unit and converts to Milligrams. This is a
     * a no-op if the provided units are already in Milligrams.
     */
    toMilligrams(val: number, unit: Units): number {
      switch (unit) {
        case Units.Kharwaar:
          return val * 4529848320;
        case Units.Man:
          return val * 56623104;
        case Units.Sayr:
          return val * 7077888;
        case Units.Chaarak:
          return val * 1769472;
        case Units.Paaw:
          return val * 442368;
        case Units.Khurd:
          return val * 110592;
        case Units.Toolee:
          return val * 18432;
        case Units.Misqaal:
          return val * 4608;
        case Units.Gram:
          return val * 1000;
        case Units.Nakhud:
          return val * 192;
        default:
          return val;
      }
    },
  },
});
