



















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import userAPI from '@/vuex/user/userAPI';
import { User } from '@/vuex/user/user';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    label: String,
    value: [Number, Array],
    multiple: Boolean,
    hideDetails: Boolean,
    clearable: Boolean,
    singleLine: Boolean,
  },

  created() {
    this.fetchData();
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },

  data() {
    return {
      loading: false,
      search: '',
      searchResults: [] as User[],
    };
  },

  computed: {
    ...mapGetters('user', ['userWithID']),

    /**
     * items builds the list of users which appear in the autoselect control.
     */
    items(): VSelectItem[] {
      // Combine any pre-selected users with the search results for the complete
      // list of selected and selectable users.
      const selected: User[] = _.map(_.flatten([this.value]), this.userWithID);
      const selectableUsers = _.compact(_.union(selected, this.searchResults));
      const items = _.map(selectableUsers, (u) => {
        return { value: u.id, text: u.username };
      });
      return _.orderBy(items, 'text');
    },
  },

  watch: {
    /**
     * value is watched so that when new User IDs are bound to the control we
     * can opportunistically fetch the User data from the server.
     */
    async value(newVal: number | number[]): Promise<void> {
      await this.$store.dispatch('user/fetchUser', { id: newVal });
    },

    /**
     * search is watched so that when the user changes the autocomplete search
     * value, we can trigger an API call to search for matching Users.
     */
    search(newSearch: string): void {
      this.fetchData();
    },
  },

  methods: {
    /**
     * fetchData performs the server-side search for users matching the search
     * criteria typed into the autocomplete. This function is debounced
     * so that it is not called immediately after every keypress.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const response = await userAPI.get(`/users/search?q=${this.search}`);
        this.searchResults = _.get(response, 'data.data', []);
      } finally {
        this.loading = false;
      }
    },
  },
});
