









import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';

import {
  SubjectProperty,
  SubjectPropertyTypeID,
} from '@/vuex/court/subjectProperty';
import SubjectPropertyExpander from '@/components/subjectProperty/SubjectPropertyExpander.vue';

export default Vue.extend({
  props: {
    ids: [Number, Array],
    objects: [Object, Array],
    dossierID: Number,

    includeDescription: Boolean,
    expandable: Boolean,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', [
      'subjectPropertyWithID',
      'subjectPropertiesForDossier',
    ]),
    subjectProperties(): SubjectProperty[] {
      if (this.ids) {
        const ids = _.flatten([this.ids]);
        return _.map(ids, this.subjectPropertyWithID);
      }
      if (this.objects) {
        return _.flatten([this.objects]);
      }
      if (this.dossierID) {
        return this.subjectPropertiesForDossier(this.dossierID) || [];
      }
      return [];
    },
  },
  methods: {
    label(sp: SubjectProperty): string {
      switch (sp.typeID) {
        case SubjectPropertyTypeID.Drugs:
          return this.drugLabel(sp);
        case SubjectPropertyTypeID.Currency:
          return this.currencyLabel(sp);
        default:
          if (!!sp.description && this.includeDescription) {
            return `${sp.label} - ${sp.description}`;
          }
          return sp.label;
      }
    },
    drugLabel(sp: SubjectProperty): string {
      const nm = this.nameForIDInCollection(sp.drugID, 'court/illegalDrugs');
      return nm;
    },
    currencyLabel(sp: SubjectProperty): string {
      let val = i18n.t('currency.singular').toString();
      if (sp.currencyID !== 1) {
        const nm = this.nameForIDInCollection(
          sp.currencyID,
          'court/currencies',
        );
        val = val + ' ( ' + nm;
        if (sp.foreignCurrencyAmount > 0) {
          val = val + `: ${sp.foreignCurrencyAmount}`;
        }
        val = val + ' )';
      }
      return val;
    },
  },
  components: {
    SubjectPropertyExpander,
  },
});
