
























































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import { Dossier } from '@/vuex/court/dossier';
import { Participant } from '@/vuex/court/participant';
import { Court } from '@/vuex/court/court';

import ParticipantSummary from '@/components/court/participant/ParticipantSummary.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', [
      'attorneyName',
      'caseWithID',
      'courtName',
      'courtWithID',
      'dossierWithID',
      'judgeName',
      'fullPartyName',
      'primaryParticipantsForCase',
    ]),
    ...mapState('court', ['attorneyTypes', 'partyTypes', 'courtStages']),
    caseParticipants(): Participant[] {
      return this.primaryParticipantsForCase(this.caseID);
    },
    dossier(): Dossier {
      return this.dossierWithID(this.payload.dossierID) || '';
    },
    payload(): any {
      return this.entry.payload;
    },
    caseID(): number {
      return this.entry.affectedRecords.caseIDs[0];
    },
    court(): Court | null {
      if (this.payload.courtID) {
        return this.courtWithID(this.payload.courtID);
      } else if (this.payload.case && this.payload.case.courtID) {
        // Deprecated CreateCase format
        return this.courtWithID(this.payload.case.courtID);
      }
      return null;
    },
    headOfCourtJudgeName(): string {
      return this.judgeName(this.payload.headOfCourtJudgeID);
    },
    kase(): Case {
      return this.caseWithID(this.caseID);
    },
  },
  components: {
    ParticipantSummary,
  },
});
