





















import Vue from 'vue';
import { mapActions } from 'vuex';
import router from '@/router';

import CorpusForm from './CorpusForm.vue';
import { blankCorpus } from '@/vuex/corpus/corpus';

export default Vue.extend({
  created() {
    // Set iniital parentID
    if (this.$route.query.parentID) {
      this.corpus.parentID = parseInt(String(this.$route.query.parentID), 10);
    }
  },
  data() {
    return {
      error: null as any,
      corpus: blankCorpus(),
      valid: false,
    };
  },
  methods: {
    ...mapActions('corpus', ['createCorpus']),
    cancelAdd(): void {
      router.back();
    },
    async save(): Promise<void> {
      // Client-side validation
      const form = this.$refs.form as Vue & { validate: () => boolean };
      if (!form.validate()) {
        return;
      }
      try {
        await this.createCorpus(this.corpus);
        router.push({ name: 'corpusList' });
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    CorpusForm,
  },
});
