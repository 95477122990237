import _ from 'lodash';
import Vue from 'vue';
import { TranslatedString } from '@/lib/translated';

import geographyAPI from './geographyAPI';
import i18n from '@/i18n';
import { domainmodelMutations } from '@/lib/vuex-domainmodel';

export const AfghanistanID = 1;

export interface Geography {
  id: number;
  name: TranslatedString;
  type: string;
  code: string;
  iso3166Code: string;
  parentID?: number;
  path: string;
}

export const GeographyRegions = {
  none: 0,
  country: 1,
  province: 2,
  district: 3,
  village: 4,
};

const state = {
  geographiesLoaded: false,
  geographyIDs: [] as number[],
  countryIDs: [] as number[],
  provinceIDs: [] as number[],
  provinceDistrictIDs: {} as { [id: number]: number[] },
  districtVillageIDs: {} as { [id: number]: number[] },
  districtVillagesLoaded: {} as { [id: number]: boolean },
  geography: {} as { [id: number]: Geography },
};

const getters = {
  allGeographies(state): Geography[] {
    return state.geographyIDs.map((id) => state.geography[id]);
  },
  countries(state): Geography[] {
    return state.countryIDs.map((id) => state.geography[id]);
  },
  provinces(state): Geography[] {
    return state.provinceIDs.map((id) => state.geography[id]);
  },
  districts:
    (state) =>
    (provinceID): Geography[] => {
      return (state.provinceDistrictIDs[provinceID] || []).map(
        (id) => state.geography[id],
      );
    },
  villages:
    (state) =>
    (districtID): Geography[] => {
      return (state.districtVillageIDs[districtID] || []).map(
        (id) => state.geography[id],
      );
    },
  geographiesWithParentID:
    (state, getters) =>
    (parentID: number): Geography[] => {
      return getters.allGeographies.filter((geo) => geo.parentID === parentID);
    },
  geographyWithID:
    (state) =>
    (id: number): Geography | null => {
      return state.geography[id] || null;
    },
  ancestorIDs:
    (state) =>
    (id: number): number[] => {
      const ids = [] as number[];
      let currID = id;
      while (state.geography[currID]) {
        ids.unshift(currID);
        currID = state.geography[currID].parentID;
      }
      return ids;
    },

  provinceCode:
    (state, getters) =>
    (id: number): string => {
      const ancestors: Geography[] = getters.geographyAncestors(id);
      for (const geo of ancestors) {
        if (geo.type === 'province') {
          return geo.code;
        }
      }
      return '';
    },

  districtCode:
    (state, getters) =>
    (id: number): string => {
      const ancestors: Geography[] = getters.geographyAncestors(id);
      for (const geo of ancestors) {
        if (geo.type === 'district') {
          return geo.code;
        }
      }
      return '';
    },

  geographyAncestors:
    (state, getters) =>
    (id: number): Geography[] => {
      // Returns an array of ancestor objects for a given geography ID (country, province, district, village)
      return getters.ancestorIDs(id).map((id) => state.geography[id]);
    },

  /**
   * geographyName returns the name of just this Geography (not including)
   * any of its parents.
   */
  geographyName:
    (state) =>
    (id: number): string => {
      const geo = state.geography[id];
      if (geo) {
        return geo.name[i18n.locale];
      }
      if (id) {
        return `Unknown Geography #${id}`;
      }
      return '';
    },

  /**
   * fullGeographyName returns a complete (translated)
   * description of the geography, with commas separating each level.
   * To save space, it drops the country if the country is Afghanistan,
   * and more-specific geography info is available. Countries other than
   * Afghanistan will always be included.
   */
  fullGeographyName:
    (state, getters) =>
    (id: number): string => {
      // Get Geography objects for each ancestor of the submitted Geography ID
      let geographies = getters.geographyAncestors(id) as Geography[];

      // Re-order so the lowest-level values (village, district) come first,
      // and country comes last.
      geographies = geographies.reverse();

      // Chop off the last entry if it's Afghanistan and we have lower-level
      // information.
      if (geographies.length > 1) {
        const lastGeography = geographies[geographies.length - 1];
        if (lastGeography.id === AfghanistanID) {
          geographies.splice(-1, 1);
        }
      }

      // Convert each entry to a translated string of just the name, join
      // with commas.
      return geographies.map((g) => g.name[i18n.locale]).join(', ');
    },

  /**
   * isDescendantGeography returns true when the childGeography is contained within,
   * or matches, the parentGeographyID. The comparison leverages the .path
   * attribute, which is maintained by the PostgreSQL LTREE extension. Paths
   * are strings of dot-separated ids. Example Afghanistan is ID=1. It's path
   * is the string "1". The Kabul province is ID=201. It's path is the string
   * "1.201" (it's a child of Afghanstan).
   */
  isDescendantGeography:
    (state) =>
    (
      args = {} as { parentGeographyID: number; childGeographyID: number },
    ): boolean => {
      const parent = state.geography[args.parentGeographyID] || null;
      if (!parent) {
        throw new Error(
          `isDescendant getter can't find parentGeographyID=${args.parentGeographyID}. Perhaps it's a village which isn't loaded?`,
        );
      }
      const child = state.geography[args.childGeographyID] || null;
      if (!child) {
        throw new Error(
          `isDescendant getter can't find childGeographyID=${args.childGeographyID}. Perhaps it's a village which isn't loaded?`,
        );
      }
      return child.path.startsWith(parent.path);
    },
};

const mutations = {
  ...domainmodelMutations,

  setGeographiesLoaded(state, val: boolean) {
    state.geographiesLoaded = val;
  },

  setDistrictVillagesLoaded(state, districtID: number) {
    state.districtVillagesLoaded[districtID] = true;
  },

  /**
   * setGeographyState is similar to the standard setState, but with some
   * geography-specific caching and indexing features.
   */
  setGeographyState(state, newState: any) {
    if (!newState.geography) {
      // Early exit if no state was provided
      return;
    }

    const newGeos = Object.values(newState.geography) as Geography[];

    newGeos.forEach((geo) => {
      if (state.geography.hasOwnProperty(geo.id)) {
        // We've already seen and indexed this... bail out
        return;
      }

      // Figure which index we need to store this ID in
      let typeIndex = null as null | number[];
      if (geo.type === 'country') {
        typeIndex = state.countryIDs;
      } else if (geo.type === 'province') {
        typeIndex = state.provinceIDs;
      } else if (geo.type === 'district' && geo.parentID) {
        if (!state.provinceDistrictIDs[geo.parentID]) {
          Vue.set(state.provinceDistrictIDs, geo.parentID, []);
        }
        typeIndex = state.provinceDistrictIDs[geo.parentID];
      } else if (geo.type === 'village' && geo.parentID) {
        if (!state.districtVillageIDs[geo.parentID]) {
          Vue.set(state.districtVillageIDs, geo.parentID, []);
        }
        typeIndex = state.districtVillageIDs[geo.parentID];
      }

      // Incorporate the Geography entity into the state lookup
      Vue.set(state.geography, geo.id, geo);

      // Add to the global geography index
      state.geographyIDs.push(geo.id);

      // Add to the type-specific index if one was identified
      if (typeIndex) {
        typeIndex.push(geo.id);
      }
    });
  },
};

const actions = {
  /**
   * fetchGeography ensures the supplied Geography with the supplied id (or ids)
   * is fetched from the server and is present in the Vuex store.
   */
  async fetchGeography(
    { commit, dispatch, state },
    id: number | number[],
  ): Promise<void> {
    // First ensure the base-level (Country + Province + District) geographies
    // are loaded in case this is one of those, but don't force-load them.
    // This should resolve instantly if the Application-wide initialization
    // routine (in App.vue) succeeded.
    await dispatch('fetchGeographies', false);

    // Find IDs that are not in existing state
    const ids: number[] = _.flatten([id]);
    const idsToFetch: number[] = _.difference(ids, state.geographyIDs);

    // Only make API call if there are new geographies to fetch
    if (idsToFetch.length > 0) {
      const idsStr = idsToFetch.join(',');
      const response = await geographyAPI.get(
        `/geographies/batch?ids=${idsStr}`,
      );
      const newState = response.data.data;
      commit('setState', newState);
    }
  },

  /**
   * fetchGeographies fetches the "base" (Country, Province, and District) geos
   * from the server and adds them to Vuex. This is called by App.vue to ensure
   * base geography data is always available.
   */
  async fetchGeographies({ commit, state }, force: boolean): Promise<void> {
    if (!force && state.geographiesLoaded) {
      return await Promise.resolve();
    }
    const response = await geographyAPI.get(`/geographies`);
    const newState = response.data.data;
    commit('setGeographyState', newState);
    commit('setGeographiesLoaded');
  },

  /**
   * fetchVillagesForDistrict is useful to supplement fetchGeographies, to fetch
   * a targeted set of geographies that were not fetched at startup. The
   * GeographySelector calls this fetcher when a District is selected so that it
   * can display the Villages beneath that district.
   */
  async fetchVillagesForDistrict(
    { commit, state },
    payload: { districtID: number; force: boolean },
  ): Promise<void> {
    if (!payload.force && state.districtVillagesLoaded[payload.districtID]) {
      return await Promise.resolve();
    }
    const response = await geographyAPI.get(
      `/districts/${payload.districtID}/villages`,
    );
    const newState = response.data.data;
    commit('setGeographyState', newState);
    commit('setDistrictVillagesLoaded', payload.districtID);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
