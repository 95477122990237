import _ from 'lodash';
import axios from 'axios';

import GlobalVariables from '@/lib/global-variables';

import Cookies from 'js-cookie';
import { LocaleMessages } from 'vue-i18n';

import i18n from '@/i18n';
import { TranslatedString } from '@/lib/translated';
import { domainmodelMutations } from '@/lib/vuex-domainmodel';

// DEFAULT_LOCALE will be used for the locale if the user has never
// set their preference.
const DEFAULT_LOCALE = GlobalVariables.DEFAULT_LOCALE;

// COOKIE_NAME is the identifier for the cookie which holds the user's
// locale preference.
const COOKIE_NAME = GlobalVariables.LOCALE_COOKIE_NAME;

// RTL_COOKIE_NAME is the identifier for the cookie which holds the user's
// locale's RTL setting.
const RTL_COOKIE_NAME = GlobalVariables.RTL_COOKIE_NAME;

export const LOCALE_MESSAGE_API_SERVER =
  process.env.VUE_APP_LOCALE_MESSAGE_API_SERVER || '';

// Language describes the properties of a user-interface language
// used in this application
//
export interface Language {
  locale: string;
  name: string;
  isRTL: boolean;
  rank: number;
}

export interface ServerLocaleMessage {
  key: string;
  message: TranslatedString;
  lastVerifiedByUserID: number;
  lastVerifiedAt: null | string;
  isDeleted: boolean;
}

export interface LookupTable {
  name: string;
  hasType: boolean;
  hasRank: boolean;
}

export interface LookupTableRow {
  table: string;
  id: number;
  name: TranslatedString;
  type?: string;
  rank?: number;
}

const state = {
  locale: i18n.locale || Cookies.get(COOKIE_NAME) || DEFAULT_LOCALE,
  languagesByLocale: {
    fa: {
      locale: 'fa',
      name: 'دري (Dari)',
      isRTL: true,
      rank: 1,
    },
    ps: {
      locale: 'ps',
      name: 'پشتو (Pashto)',
      isRTL: true,
      rank: 2,
    },
    en: {
      locale: 'en',
      name: 'English',
      isRTL: false,
      rank: 3,
    },
  } as { [code: string]: Language },
  localeMessage: {} as {
    [key: string]: ServerLocaleMessage;
  },
};

const getters = {
  language(state): Language {
    return state.languagesByLocale[state.locale];
  },
  languages(state): Language[] {
    let languages: Language[] = Object.keys(state.languagesByLocale).map(
      (code) => state.languagesByLocale[code],
    );
    languages = _.sortBy(languages, (l) =>
      l.locale === state.locale ? 0 : l.rank,
    );
    return languages;
  },
  locales(state): string[] {
    return Object.keys(state.languagesByLocale);
  },
  languageIsRTL(state): boolean {
    return state.languagesByLocale[state.locale].isRTL || false;
  },
  align(state): string {
    return state.languagesByLocale[state.locale].isRTL ? 'right' : 'left';
  },
  valueForLocale: (state) => (field) => {
    if (!field) {
      return 'ERROR';
    }
    return field[state.locale];
  },

  /**
   * nameForIDInCollection is the universal tool for printing the translated
   * name for a single item in a LookupTable or other collection of objects
   * with a TranslatedString name property (i.e objects that look like this:
   *
   * [{
   *    "id": 1,
   *    "name": {
   *      "en": "English Name",
   *      "fa": "Dari Name",
   *      "ps": "Pashto Name"
   *    }
   * }]
   *
   * The first paramter is the integer id of the desired lookup table item.
   * The second paramter is either the collection of objects itself, or a
   * string "address" of where the collection can be found in state.
   *
   * These two usages are equivalent:
   *
   *  ...mapState('court', ['judgeRemovalReasons']),
   *  nameForIDInCollection(2, this.judgeRemovalReasons)
   *
   *  nameForIDInCollection(1, 'court/judgeRemovalReasons')
   *
   * @param id: number The ID of the item in the LookupTable
   * @param any[] | string Either an array of items in a lookuptable, or
   *                       a Vuex-style string indicating the path to those
   *                       items in the state.
   */
  nameForIDInCollection:
    (state, getters, rootState) =>
    (id: number, collection: any[] | string): string => {
      // Early exit in case of missing/invalid parameters
      if (id === undefined || collection === undefined) {
        return '';
      }

      // Convert string path to a collection into the actual collection,
      // assuming we can find it.
      if (typeof collection === 'string') {
        const lookupTableName = collection.split('/');
        let moduleName = '';
        let variableName = '';
        if (lookupTableName.length > 1) {
          moduleName = lookupTableName[0];
          variableName = lookupTableName[1];
          if (!rootState.hasOwnProperty(moduleName)) {
            return `Invalid module: ${moduleName}`;
          }
          if (!rootState[moduleName].hasOwnProperty(variableName)) {
            return `Invalid collection: ${moduleName}/${variableName}`;
          }
          collection = rootState[moduleName][variableName];
        } else {
          if (!rootState.hasOwnProperty(collection)) {
            return `Invalid collection: ${collection}`;
          }
          collection = rootState[collection];
        }
      }

      if (Array.isArray(collection) && collection.length > 0) {
        const item = _.find(
          collection,
          // Convert both sides to strings so that "1" matches 1.
          // This is needed since the IDs are sometimes string-based
          // keys.
          (item) => item.id.toString() === id.toString(),
        ) as any;
        return item.name[state.locale];
      }

      return '';
    },

  localeMessages(state): ServerLocaleMessage[] {
    return Object.values(state.localeMessage) || [];
  },

  localeMessageKeys(state): string[] {
    return Object.keys(state.localeMessage) || [];
  },

  localeStringForBloodType:
    (state) =>
    (bloodType: string): string => {
      // will always come in as English so we can compare against it
      if (bloodType !== 'Unknown') {
        return bloodType;
      }

      return state.locale === 'en' ? 'Unknown' : 'نامعلوم';
    },
};

const mutations = {
  ...domainmodelMutations,

  /**
   * setLocale changes the currently active locale to the value (e.g. 'en')
   * provided in the accompanying string.
   */
  setLocale(state, l: string) {
    state.locale = l;
    i18n.locale = l;
    const lang = state.languagesByLocale[l];
    Cookies.set(COOKIE_NAME, l, { sameSite: 'strict' });
    Cookies.set(RTL_COOKIE_NAME, lang.isRTL, { sameSite: 'strict' });
  },

  /**
   * refreshBrowserLocaleMessages scans the Vuex state of translation
   * messages and converts them all to the vuei18n locale message format
   * and then overwrites any existing locale messages with the ones
   * from Vuex.
   */
  refreshBrowserLocaleMessages(state) {
    const unflatten = require('flat').unflatten;
    const messages = {} as LocaleMessages;
    for (const msg of Object.values(
      state.localeMessage,
    ) as ServerLocaleMessage[]) {
      if (msg.isDeleted) {
        // The server sends messages regardless of whether they've been deleted.
        // By continue-ing here, we avoid the deleted messages being injected
        // into vue-i18n, making them behave as if they are deleted.
        continue;
      }
      const key = msg.key;
      for (const locale of Object.keys(msg.message)) {
        if (!messages.hasOwnProperty(locale)) {
          messages[locale] = {};
        }
        messages[locale][key] = msg.message[locale];
      }
    }
    for (const locale of Object.keys(messages)) {
      i18n.setLocaleMessage(
        locale,
        Object.assign(i18n.messages[locale], unflatten(messages[locale])),
      );
    }
    return messages;
  },
};

const actions = {
  /**
   * initializeLocale is the action which is called at application bootup to
   * download ALL locale messages from the locale messages server
   */
  async initializeLocale({ commit, state }): Promise<void> {
    const override = Cookies.get(COOKIE_NAME);
    if (override && !!state.languagesByLocale[override]) {
      commit('setLocale', override);
    } else {
      commit('setLocale', DEFAULT_LOCALE);
    }

    const client = axios.create({
      baseURL: `${LOCALE_MESSAGE_API_SERVER}/api/v1/translationservice`,
      headers: {},
    });
    const res = await client.get('/locale-messages');
    const newState = _.get(res, 'data.data', {});
    commit('setState', newState);
    commit('refreshBrowserLocaleMessages');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
