import _ from 'lodash';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Program {
  id: number;
  offenderID: number;
  courseID: number;
  status: string;
  startNotes: string;
  endNotes: string;
}

export const state = {
  offenderProgram: {} as { [id: number]: Program },
  offenderProgramIDsForOffender: {} as { [offenderID: number]: number[] },
};

export const getters = {
  /**
   * offenderProgramWithID is a convenience getter to get a single Program
   * for a single Offender. This is used on the detail popups after an
   * Offender begins a Program.
   */
  offenderProgramWithID:
    (state) =>
    (id: number): Program => {
      return state.offenderProgram[id];
    },

  /**
   * programsForOffender retrieves the entire array of Program entries for a
   * a single Offender.
   */
  programsForOffender:
    (state) =>
    (offenderID: number): Program[] => {
      return (state.offenderProgramIDsForOffender[offenderID] || []).map(
        (id: number) => state.offenderProgram[id],
      ) as Program[];
    },
};

export const mutations = {};

export const actions = {
  /**
   * fetchPrograms retrieves the complete list of programs for a particular
   * Offender from the server, and updates the local store with the results.
   */
  async fetchPrograms({ commit }, payload: { offenderID: number }) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/programs`,
    );
    const programs = response.data.data;
    const newState = modelsToState('offenderProgram', programs);
    commit('setState', newState);
    commit('setTarget', {
      target: 'offenderProgramIDsForOffender',
      index: payload.offenderID,
      value: _.map(programs, 'id'),
    });
  },
};
