




































import Vue from 'vue';
import { mapGetters } from 'vuex';
import AddRelatedDossierDialog from '@/components/dossier/AddRelatedDossierDialog.vue';
import { RelatedDossier } from '@/vuex/court/relatedDossier';

export default Vue.extend({
  props: {
    dossierID: Number,
  },
  created(): void {
    this.fetchData();
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('court', ['relatedDossiersForDossier']),
    headers(): any[] {
      return [
        {
          text: this.$i18n.t('dossier.dossierNumber'),
          value: 'relatedDossierID',
          width: '203px',
        },
      ];
    },
    relatedDossiers(): RelatedDossier[] {
      return this.relatedDossiersForDossier(this.dossierID);
    },
  },
  watch: {
    dossierID() {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch('court/fetchRelatedDossiers', {
        dossierID: this.dossierID,
      });
    },
  },
  components: {
    AddRelatedDossierDialog,
  },
});
