<template>
  <div>
    <component :is="componentFile" :entry="entry"></component>
    <div class="mt-2 text-caption text--disabled">
      <UserInitials :id="entry.userID" />
      &mdash;
      <span @dblclick="showPayload = !showPayload">
        <Date :value="entry.submittedAt" show-time />
      </span>
      <DefaultDocketEntry v-if="showPayload" :entry="entry" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';

import DefaultDocketEntry from './PresentDefaultDocketEntry.vue';
import UserInitials from '@/components/user/UserInitials.vue';

// Keep these lines sorted to decrease merge conflicts
import PresentAddFinePaymentAction from './PresentAddFinePaymentAction.vue';
import PresentAlterCaseCharges from './PresentAlterCaseCharges.vue';
import PresentAlterDossierCharges from './PresentAlterDossierCharges.vue';
import PresentCancelHearingAction from './PresentCancelHearingAction.vue';
import PresentConfigureCaseJudges from './PresentConfigureCaseJudges.vue';
import PresentCourtRecord from './PresentCourtRecord.vue';
import PresentCreateCase from './PresentCreateCase.vue';
import PresentCreateCaseJudge from './PresentCreateCaseJudge.vue';
import PresentCreateCrimeReport from './PresentCreateCrimeReport.vue';
import PresentCreateDocketEvent from './PresentCreateDocketEvent.vue';
import PresentCreateHearing from './PresentCreateHearing.vue';
import PresentCreateNewDossier from './PresentCreateNewDossier.vue';
import PresentCreateParticipantAction from './PresentCreateParticipantAction.vue';
import PresentCreatePartyAction from './PresentCreatePartyAction.vue';
import PresentCreateRelatedDossierAction from './PresentCreateRelatedDossierAction.vue';
import PresentCreateSentenceAdjustment from './PresentCreateSentenceAdjustment.vue';
import PresentCreateSubjectPropertyAction from './PresentCreateSubjectPropertyAction.vue';
import PresentEditCaseJudgeAction from './PresentEditCaseJudgeAction.vue';
import PresentFulfillSentenceComponentAction from './PresentFulfillSentenceComponentAction.vue';
import PresentIntake from './PresentIntake.vue';
import PresentLegacyInvestigationRecord from './PresentLegacyInvestigationRecord.vue';
import PresentLegacyInvestigationSubRecord from './PresentLegacyInvestigationSubRecord.vue';
import PresentRecordSummonsServiceAction from './PresentRecordSummonsServiceAction.vue';
import PresentRemoveJudgeFromCase from './PresentRemoveJudgeFromCase.vue';
import PresentRescheduleHearingAction from './PresentRescheduleHearingAction.vue';
import PresentSetLeadJudge from './PresentSetLeadJudge.vue';
import PresentUpdateParticipantAttorneys from './PresentUpdateParticipantAttorneys.vue';
import PresentUpdatePartyAction from './PresentUpdatePartyAction.vue';
import PresentUpdateSubjectPropertyAction from './PresentUpdateSubjectPropertyAction.vue';

export default Vue.extend({
  /*
   * For each actionType being handled, a Present[ActionTypeName].vue component
   * must be created to match the name of the actionType in the court/docketEntry
   * directory. Then the componentFile() computed method will dynamically import it
   * and supply it to the <component :is="...." /> tag
   */
  props: {
    entry: Object,
  },
  data() {
    return {
      showPayload: false,
    };
  },
  computed: {
    componentFile() {
      if (this.$options.components['Present' + this.entry.actionType]) {
        return 'Present' + this.entry.actionType;
      }
      return 'DefaultDocketEntry';
    },
  },
  components: {
    DefaultDocketEntry,
    UserInitials,

    // Keep these lines sorted to decrease merge conflicts
    PresentAddFinePaymentAction,
    PresentAlterCaseCharges,
    PresentAlterDossierCharges,
    PresentCancelHearingAction,
    PresentConfigureCaseJudges,
    PresentCourtRecord,
    PresentCreateCase,
    PresentCreateCaseJudge,
    PresentCreateCrimeReport,
    PresentCreateDocketEvent,
    PresentCreateHearing,
    PresentCreateNewDossier,
    PresentCreateParticipantAction,
    PresentCreatePartyAction,
    PresentCreateRelatedDossierAction,
    PresentCreateSentenceAdjustment,
    PresentCreateSubjectPropertyAction,
    PresentEditCaseJudgeAction,
    PresentFulfillSentenceComponentAction,
    PresentIntake,
    PresentLegacyInvestigationRecord,
    PresentLegacyInvestigationSubRecord,
    PresentRecordSummonsServiceAction,
    PresentRemoveJudgeFromCase,
    PresentRescheduleHearingAction,
    PresentSetLeadJudge,
    PresentUpdateParticipantAttorneys,
    PresentUpdatePartyAction,
    PresentUpdateSubjectPropertyAction,
  },
});
</script>
