




































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Prosecutor } from '@/vuex/investigation/prosecutor';

import ProsecutorSearcher from '@/components/investigation/prosecutor/ProsecutorSearcher.vue';

export default Vue.extend({
  props: {
    value: Number,

    // label is a passthrough to the v-autocomplete label prop
    label: String,

    // rules is a passthrough to the v-autocomplete rules prop
    rules: Array,

    // disabled is a passthrough to the v-autocomplete disabled prop
    disabled: Boolean,

    // value is the prop that makes this component work with v-model
    clearable: Boolean,

    // exclude is an array prop that contains IDs of attorneys to filter out of
    // the list so that they are not even visible to be selected.
    excludedIDs: Array,

    // partyMode changes the v-model binding behavior of the
    // ProsecutorSearchSelector. Normally, the ProsecutorSearchSelector binds
    // with the ID of a prosecutor.
    partyMode: Boolean,
  },
  data() {
    return {
      loading: false,
      isOpen: false,

      prosecutorID: 0,
    };
  },
  watch: {
    value(newVal: number): void {
      if (!this.partyMode && newVal !== this.prosecutorID) {
        this.prosecutorID = newVal;
        return;
      }

      if (this.partyMode && !newVal) {
        this.prosecutorID = 0;
      }
    },
    isOpen(newVal: boolean): void {
      this.$nextTick(() => {
        (this.$refs.prosecutorSearcher as any).reset();
      });
    },
    async prosecutorID(newID: number): Promise<void> {
      if (this.partyMode) {
        if (this.prosecutor) {
          // In partyMode, we can't be confident that the referenced party
          // object is loaded in the court.party Vuex store, so we have to
          // fetch it.
          const partyID = this.prosecutor.partyID;
          this.loading = true;
          await this.$store.dispatch(
            'court/fetchParty',
            this.prosecutor.partyID,
          );
          this.loading = false;
          this.$emit('input', partyID);
        } else {
          this.$emit('input', 0);
        }
        return;
      }

      this.$emit('input', this.prosecutorID);
    },
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('court', ['partyName']),
    ...mapGetters('investigation', ['prosecutorWithID', 'prosecutorName']),
    prosecutor(): Prosecutor | null {
      return this.prosecutorWithID(this.prosecutorID) || null;
    },
    currentProsecutorName(): string {
      if (!this.value) {
        return '';
      }
      if (this.partyMode) {
        return this.partyName(this.value);
      }
      return this.prosecutorName(this.value);
    },
  },
  components: {
    ProsecutorSearcher,
  },
});
