

















































































import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import { mapGetters, mapState } from 'vuex';
import { SentenceComponent } from '@/vuex/court/participant';
import { PunishmentType } from '@/vuex/crime/crime';

interface SentenceComponentRow {
  // Common Fields
  label: string;
  icon: string;
  iconColor: string;
  isConsequential: boolean;

  // Fields for standard Sentence Components
  punishmentType?: PunishmentType;
  component?: SentenceComponent | null;

  // Fields for the Consequential Punishments Row
  expanded?: boolean;
  consequentialPunishments?: SentenceComponent[] | null;
}

export default Vue.extend({
  props: {
    /**
     * components is the array of SentenceComponents to summarize
     */
    components: Array as () => SentenceComponent[],

    /**
     * hideFulfillment removes the final column when set to true. This is
     * needed when a sentence summary is displayed directly on a Court case,
     * where fulfillment tracking is not relevant.
     */
    hideFulfillment: Boolean,
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection', 'valueForLocale']),
    ...mapGetters('crime', ['punishmentTypeWithID']),
    ...mapGetters('court', ['subjectPropertyName']),

    /**
     * rows computes the rows which are displayed in the sentence components
     * data table. This function collapses all Consequential Punishments into
     * a single, expandable row.
     */
    rows(): SentenceComponentRow[] {
      if (!this.components) {
        return [];
      }

      // Convert each SentenceComponent to a "row", which has some UI-friendly
      // proeprties to help build an expander interface.
      const allRows: SentenceComponentRow[] = _.map(
        (this.components || []) as SentenceComponent[],
        (sc) => {
          const punishmentType: PunishmentType = this.punishmentTypeWithID(
            sc.punishmentTypeID,
          );
          const label = this.valueForLocale(punishmentType.name);
          const icon = sc.isFulfilled ? 'fa-square-check' : 'fa-minus-square';
          const iconColor = !punishmentType.trackFulfillment
            ? 'grey'
            : sc.isFulfilled
            ? 'success'
            : 'error';
          return {
            label,
            isConsequential: punishmentType.isConsequential,
            icon,
            iconColor,

            punishmentType,
            expanded: false,
            component: sc,
          };
        },
      );

      // Remove all the Consequential Punishments. This will be our primary
      // array of table rows
      const rows = _.reject(allRows, { isConsequential: true });

      // Get the Consequential Punishments as a separate list of just the
      // SentenceComponent objects. We'll use this to build a single, one-off
      // row at the bottom of the table.
      const consequentialPunishments = _.chain(allRows)
        .filter({ isConsequential: true })
        .map('component')
        .compact()
        .value();

      // Add the Consequential Punishments row
      if (consequentialPunishments.length > 0) {
        rows.push({
          label: i18n.t('consequentialPunishment.plural').toString(),
          icon: 'fa-chevron-down',
          iconColor: 'grey',
          isConsequential: true,
          expanded: false,
          consequentialPunishments,
        });
      }

      return rows;
    },

    /**
     * headers computes the array of VDataTable headers for the sentence
     * components table. The final column (showing fulfillment status) is
     * dynamic. It's omitted when the hideFulfillment prop is set to true.
     */
    headers(): any[] {
      const headers: any[] = [
        {
          text: i18n.t('court.ruling.type'),
          value: 'label',
        },
        {
          text: i18n.t('notes'),
          value: null,
        },
      ];
      if (!this.hideFulfillment) {
        headers.push({
          text: i18n.t('sentence.fulfilled'),
          value: 'isFulfilled',
          align: 'center',
        });
      }
      return headers;
    },
  },

  methods: {
    /**
     * toggleRow is only used on the Consequential Punishments row of the table
     * it toggles the row's expanded property on an off
     */
    toggleRow(row: SentenceComponentRow): void {
      row.expanded = !row.expanded;
    },
  },
});
