














import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import SubjectPropertyForm from '@/components/subjectProperty/SubjectPropertyForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: false,
      default: 0,
    },
    value: {
      type: Object,
      required: false,
    },
    dossierID: {
      default: 0,
      type: Number,
    },
    caseID: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      isOpen: false,
      error: null as any,

      subjectProperty: null as SubjectProperty | null,
    };
  },

  computed: {
    ...mapGetters('court', ['subjectPropertyWithID']),
  },

  watch: {
    isOpen(nowOpening: boolean): void {
      if (nowOpening) {
        if (this.id) {
          // Existing SubjectProperty stored in Vuex, supplied via our  id prop
          const myCopy = Object.assign({}, this.subjectPropertyWithID(this.id));
          this.subjectProperty = myCopy;
        } else if (this.value) {
          // Existing SubjectProperty stored who knows where, supplied via
          // our v-model binding (the value prop).
          const myCopy = Object.assign({}, this.value);
          this.subjectProperty = myCopy;
        }
      }
    },
  },

  methods: {
    reset(): void {
      this.subjectProperty = null;
    },

    save(): void {
      if (this.subjectProperty === null) {
        return;
      }
      if (this.dossierID) {
        this.saveToServer(this.subjectProperty);
      } else {
        this.saveToParent(this.subjectProperty);
      }
    },

    saveToParent(sp: SubjectProperty): void {
      this.$emit('input', sp);
      this.isOpen = false;
    },

    async saveToServer(sp: SubjectProperty): Promise<void> {
      this.error = null;

      try {
        const response = await courtAPI.post('subject-properties/update', {
          data: {
            id: sp.id,
            dossierID: this.dossierID,
            caseID: this.caseID,
            typeID: sp.typeID,
            drugID: sp.drugID,
            currencyID: sp.currencyID,
            foreignCurrencyAmount: sp.foreignCurrencyAmount,
            label: sp.label,
            description: sp.description,
            locationGeographyID: sp.locationGeographyID,
            locationDetails: sp.locationDetails,
            landAreaInSquareMeters: sp.landAreaInSquareMeters,
            itemValueInPuls: sp.itemValueInPuls,
            quantity: sp.quantity,
            totalValueInPuls: sp.totalValueInPuls,
            isDeleted: sp.isDeleted,
          },
        });
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);

        // Add to the docket
        this.$store.commit('court/addDocketEntry', response);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    SubjectPropertyForm,
  },
});
