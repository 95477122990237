





















































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender } from '@/vuex/offender/offender';
import { LeaveReason } from '@/vuex/offender/leave';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data() {
    return {
      isOpen: false,
      scheduledReleaseDate: '',
      estimatedDurationInDays: 0,
      approverTitle: '',
      approverName: '',
      reasonID: LeaveReason.Personal,
      details: '',
      housingFacilityID: 0,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('offender', [
      'offenderWithID',
      'voluntaryLeaveDaysThisYearForOffender',
    ]),
    offender(): Offender | null {
      return this.offenderWithID(this.offenderID) || null;
    },
    totalLeaveDays(): number {
      return this.voluntaryLeaveDaysThisYearForOffender(this.offenderID);
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state
     */
    reset(): void {
      const releaseDate = new Date();
      releaseDate.setDate(releaseDate.getDate() + 4);
      const returnDate = new Date();
      returnDate.setDate(returnDate.getDate() + 8);
      this.scheduledReleaseDate = releaseDate.toISOString().substr(0, 10);
      this.estimatedDurationInDays = 0;
      this.approverTitle = '';
      this.approverName = '';
      this.reasonID = LeaveReason.Personal;
      this.details = '';
      if (this.offender) {
        this.housingFacilityID = this.offender.housingFacilityID;
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('leaves/request', {
          data: {
            offenderID: this.offenderID,
            housingFacilityID: this.housingFacilityID,
            reasonID: this.reasonID,
            details: this.details,
            scheduledReleaseDate: this.scheduledReleaseDate,
            estimatedDurationInHours: this.estimatedDurationInDays * 24,
            approverTitle: this.approverTitle,
            approverName: this.approverName,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.leave)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'leaveIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.leave),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
