
















































































































































































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import profileAPI from '@/vuex/profile/profileAPI';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { blankOffender, Offender } from '@/vuex/offender/offender';
import { Profile } from '@/vuex/profile/profile';
import { VForm } from '@/lib/vue-typescript';

import DossierLookupField from '@/components/dossier/DossierLookupField.vue';
import IntakeHousingForm from '@/components/offender/facility/IntakeHousingForm.vue';
import OffenderClassificationForm from '@/views/offender/OffenderClassificationForm.vue';
import PartySelector from '@/components/court/party/PartySelector.vue';
import ProfileSearchSelector from '@/components/profile/ProfileSearchSelector.vue';
import { Dossier } from '@/vuex/court/dossier';

export default Vue.extend({
  data(): any {
    return {
      step: 1,
      saving: false,

      dossierID: 0,
      profileID: 0,
      partyID: 0,
      offender: null as Offender | null,
      profile: null as Profile | null,
    };
  },
  computed: {
    ...mapGetters('court', ['dossierWithID', 'partyName', 'participantInCase']),
    ...mapGetters('housing', ['fullFacilityName', 'cellName']),
    ...mapGetters('profile', ['profileWithID']),

    /**
     * identificationComplete is a UI status tracking computed property. It
     * returns true when the Profile has been identified.
     */
    identificationComplete(): boolean {
      return this.profileID !== 0;
    },

    /**
     * housingComplete is a UI status tracking computed property. It returns
     * true when the housing facility fields have been filled out.
     */
    housingComplete(): boolean {
      if (!this.offender) {
        return false;
      }
      return this.offender.housingFacilityID !== 0;
    },

    /**
     * dossier computes the Dossier which has been chosen to relate to this
     * Intake operation. It may be null if the Dossier has not been entered
     * yet, or when the user has elected to proceed with intake without a
     * Dossier.
     */
    dossier(): Dossier | null {
      return this.dossierWithID(this.dossierID);
    },
  },
  watch: {
    /**
     * profile is watched so that classification settings for the new Offender
     * can be set to defaults which are inferred from the Profile of the
     * Party referenced in the Dossier which is being imported.
     */
    profile(p: Profile) {
      this.setDefaultJuvenileStatus();
      this.setDefaultImprisonmentDuration();
    },

    /**
     * profileID is watched so that the entire Profile entity can be fetched
     * when a new Profile is selected for Intake.
     */
    async profileID(newID: number): Promise<void> {
      if (newID) {
        await this.$store.dispatch('profile/fetchProfile', newID);
        const localProfileCopy = Object.assign({}, this.profileWithID(newID));
        this.profile = localProfileCopy;
        this.offender = blankOffender();
        this.offender.profileID = this.profile.id;
      } else {
        this.offender = null;
        this.profile = null;
      }
    },
  },
  methods: {
    reset(): void {
      this.dossierID = 0;
      this.partyID = 0;
      this.profileID = 0;
    },
    async importFromDossier(): Promise<void> {
      const response = await profileAPI.get(
        '/profiles/search?partyID=' + this.partyID,
      );
      const firstID = _.get(response, 'data.data[0].id');
      if (firstID) {
        this.profileID = firstID;
        this.forward(3);
      } else {
        alert('Failed to find a match!');
        console.log(response.data.data);
      }
    },
    startBlank(): void {
      this.step = 2;
      this.reset();
    },
    forward(nextStep: number): void {
      if (!nextStep) {
        nextStep = this.step + 1;
      }
      if (nextStep > 5) {
        nextStep = 5;
      }

      const form = this.$refs.form as VForm;
      if (!form.validate()) {
        return;
      }

      this.step = nextStep;
    },
    async save(): Promise<void> {
      const form = this.$refs.form as VForm;
      if (!form.validate()) {
        return;
      }

      this.saving = true;
      const response = await offenderAPI.post('offenders/intake', {
        data: {
          // Offender Fields
          profileID: this.profileID,
          dossierID: this.dossierID,
          detainedAt: this.offender.detainedAt,
          imprisonmentDurationInHours:
            this.offender.imprisonmentDurationInHours,
          expectedReleaseAt: this.offender.expectedReleaseAt,
          housingFacilityID: this.offender.housingFacilityID,
          cellID: this.offender.cellID,
          DIN: this.offender.DIN,
          PIN: this.offender.PIN,

          // Offender > Classification sub-fields
          classificationScore: this.offender.classificationScore,
          flagIDs: this.offender.flagIDs,
          privilegeIDs: this.offender.privilegeIDs,
          isJuvenile: this.offender.isJuvenile,
          isDetainee: this.offender.isDetainee,

          // Profile Fields
          criminalGroupIDs: this.profile.criminalGroupIDs,
          criminalGroupRoleID: this.profile.criminalGroupRoleID,
          infectiousDiseaseIDs: this.profile.infectiousDiseaseIDs,
          mentalDisorderIDs: this.profile.mentalDisorderIDs,
          motherChildConcernIDs: this.profile.motherChildConcernIDs,
        },
      });
      this.saving = false;

      const combinedState = response.data.data;

      const offenderState = _.pick(combinedState, ['offender']);
      this.$store.commit('offender/setState', offenderState);

      const profileState = _.pick(combinedState, ['profile']);
      this.$store.commit('profile/setState', profileState);

      const offenderID = Object.keys(offenderState.offender)[0];
      this.$router.push({
        name: 'offenderProfile',
        params: { id: offenderID },
      });
    },

    /**
     * setDefaultJuvenileStatus is triggered when the Profile is changed. It
     * examines the Profile to see if the birthdate suggests that the new
     * Offender should be defaulted to have isJuvenile true.
     */
    setDefaultJuvenileStatus(): void {
      const profile = this.profile;
      if (profile && profile.birthdate) {
        const ageInYears = moment().diff(profile.birthdate, 'years');
        if (ageInYears > 0 && ageInYears < 18) {
          this.offender.isJuvenile = true;
        }
      }
    },

    /**
     * setDefaultImprisonmentDuration is triggered when the Dossier is changed.
     * It examines the Participant in that Dossier's current Court Case to
     * see if an Imprisonment duration exists. If it does, it changes the
     * new Offender to have isDetainee = false, and to have their length of
     * stay set to the total amount of time on the sentence.
     */
    setDefaultImprisonmentDuration(): void {
      if (this.dossier) {
        const participant = this.participantInCase(
          this.dossier.currentCaseID,
          this.partyID,
        );
        if (participant && participant.sentence) {
          this.offender.isDetainee = false;
          this.offender.imprisonmentDurationInHours =
            participant.sentence.totalImprisonmentDurationInHours;
        }
      }
    },
  },
  components: {
    DossierLookupField,
    IntakeHousingForm,
    OffenderClassificationForm,
    PartySelector,
    ProfileSearchSelector,
  },
});
