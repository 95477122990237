



































import Vue from 'vue';
import { AxiosResponse } from 'axios';

import attachmentAPI from '@/vuex/attachment/attachmentAPI';
import { mapGetters } from 'vuex';
import { Attachment } from '@/vuex/attachment/attachment';

import AddAttachmentDialog from '@/components/global/attachments/AddAttachmentDialog.vue';
import MimeTypeIcon from '@/components/global/attachments/MimeTypeIcon.vue';

export default Vue.extend({
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityID: Number,
    location: {
      type: String,
      default: '',
    },
    label: String,
    accept: String,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      adding: false,
      internalEntityID: 0,
      internalEntityType: 'temp',
    };
  },
  computed: {
    ...mapGetters('attachment', ['attachmentsForEntity']),
    attachments(): Attachment[] {
      return this.attachmentsForEntity(
        this.internalEntityType,
        this.internalEntityID,
        this.location,
      );
    },
  },
  watch: {
    entityType: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.internalEntityType = newVal;
        } else {
          this.internalEntityType = 'temp';
        }
      },
    },
    entityID: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.internalEntityID = newVal;
        } else {
          this.internalEntityID = Math.floor(Math.random() * -999999);
        }
      },
    },
  },
  methods: {
    async updateEntityID(entityID: number): Promise<AxiosResponse> {
      const data = {
        oldEntityType: this.internalEntityType,
        newEntityType: this.internalEntityType,
        oldEntityID: this.internalEntityID,
        newEntityID: parseInt(entityID.toString(), 10),
      };
      return attachmentAPI.post('/finalize', { data });
    },
    openDownloadLink(uri: string) {
      window.open(uri, '_blank');
    },
    fetchData(): void {
      this.$store.dispatch('attachment/fetchAttachments', {
        entityType: this.entityType,
        entityID: this.entityID,
        location: this.location,
      });
    },
  },
  components: {
    AddAttachmentDialog,
    MimeTypeIcon,
  },
});
