





















































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';
import { Role } from '@/vuex/user/user';

import AddRoleDialog from '@/components/user/AddRoleDialog.vue';
import EditRoleDialog from '@/components/user/EditRoleDialog.vue';
import PermissionSelector from '@/components/user/PermissionSelector.vue';

export default Vue.extend({
  data() {
    return {
      search: '',
      permissionID: 0,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['valueForLocale']),
    ...mapState('user', ['roles']),

    canAdd(): boolean {
      return this.hasPermission('admin.role.create');
    },

    canEdit(): boolean {
      return this.hasPermission('admin.role.create');
    },

    groupedRoles(): any {
      return _.chain(this.roles || [])
        .map((r: Role) => {
          return Object.assign({}, r, {
            translatedName: this.valueForLocale(r.name),
            permissionsSummary: r.hasAllPermissions
              ? i18n.t('all')
              : r.permissionIDs.length,
          });
        })
        .filter(
          (r: any) =>
            r.translatedName
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1,
        )
        .filter((r: any) => {
          if (!this.permissionID || r.hasAllPermissions) {
            return true;
          }

          if (r.permissionIDs === []) {
            return false;
          }

          return r.permissionIDs.includes(this.permissionID);
        })
        .groupBy('entityType')
        .value();
    },

    headers(): any[] {
      const h = [
        {
          text: i18n.t('name'),
          value: 'translatedName',
        },
        {
          text: i18n.t('role.permissionIDs'),
          value: 'permissionsSummary',
          align: 'right',
        },
      ] as any[];

      if (this.canAdd) {
        h.unshift({
          text: null,
          value: 'id',
          width: '18px',
          sortable: false,
        });
      }

      return h;
    },
  },
  methods: {},
  components: {
    AddRoleDialog,
    EditRoleDialog,
    PermissionSelector,
  },
});
