import _ from 'lodash';
import { domainmodelMutations } from '@/lib/vuex-domainmodel';

import {
  state as crimeReportState,
  getters as crimeReportGetters,
  mutations as crimeReportMutations,
  actions as crimeReportActions,
} from './crimeReport';

import {
  state as departmentState,
  getters as departmentGetters,
  mutations as departmentMutations,
  actions as departmentActions,
} from './department';

import {
  state as prosecutorState,
  getters as prosecutorGetters,
  mutations as prosecutorMutations,
  actions as prosecutorActions,
} from './prosecutor';

import {
  state as pardonDecreeState,
  getters as pardonDecreeGetters,
  mutations as pardonDecreeMutations,
  actions as pardonDecreeActions,
} from './pardonDecree';

export interface Investigation {
  id: number;
  name: string;
}

const state = {
  ...crimeReportState,
  ...departmentState,
  ...pardonDecreeState,
  ...prosecutorState,
};

const getters = {
  ...crimeReportGetters,
  ...departmentGetters,
  ...pardonDecreeGetters,
  ...prosecutorGetters,
};

const mutations = {
  ...domainmodelMutations,

  ...crimeReportMutations,
  ...departmentMutations,
  ...pardonDecreeMutations,
  ...prosecutorMutations,
};

const actions = {
  ...crimeReportActions,
  ...departmentActions,
  ...pardonDecreeActions,
  ...prosecutorActions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
