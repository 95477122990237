





















































































import moment from 'moment';
import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';

import CellSelector from '@/components/offender/facility/CellSelector.vue';
import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import { blankOffender, Offender } from '@/vuex/offender/offender';

export default Vue.extend({
  props: {
    value: Object,
  },

  computed: {
    offender(): Offender {
      return this.value ? this.value : blankOffender();
    },

    durationLabel(): string {
      if (this.offender.isDetainee) {
        return 'offender.detentionDuration';
      }
      return 'offender.sentenceDuration';
    },
  },

  methods: {
    durationChanged(newDuration: number): void {
      const o = cloneDeep(this.offender);
      o.imprisonmentDurationInHours = newDuration;
      o.expectedReleaseAt = moment(this.offender.detainedAt)
        .add(newDuration, 'hours')
        .format();
      this.$emit('input', o);
    },

    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.offender), (v) => set(v, key, modifiedValue)),
      );
    },
  },

  components: {
    CellSelector,
    FacilitySelector,
  },
});
