




















































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import { Holiday } from '@/vuex/court/holiday';

import NewHolidayDialog from '@/components/court/holidays/NewHolidayDialog.vue';
import EditHolidayDialog from '@/components/court/holidays/EditHolidayDialog.vue';

export default Vue.extend({
  data() {
    return {
      search: '',
      align: '',
      error: null as any,
      loading: true,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('geography', ['geographyWithID']),
    ...mapGetters('court', ['allHolidays']),

    canAdd(): boolean {
      return this.hasPermission('admin.holiday.create');
    },
    headers(): any[] {
      const h = [
        {
          text: i18n.t('holiday.date'),
          value: 'date',
        },
        {
          text: i18n.t('holiday.name'),
          value: 'name',
        },
        {
          text: i18n.t('location.singular'),
          value: 'locationGeographyID',
        },
      ] as any[];

      if (this.canAdd) {
        h.unshift({
          text: i18n.t('command.edit'),
          value: null,
          with: '2%',
          sortable: false,
        });
        h.push({ text: i18n.t('command.delete').toString() });
      }

      return h;
    },
    holidays(): Holiday[] {
      const holidays = this.allHolidays;
      return holidays.map((holiday) => {
        const h = Object.assign({}, holiday);
        const geo = this.geographyWithID(h.locationGeographyID);
        if (geo) {
          h.locationGeographyID = geo.name[this.locale];
        }
        return h;
      });
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      await [this.$store.dispatch('court/fetchHolidays')];
      this.loading = false;
    },
    deleteHoliday(holidayID): void {
      courtAPI
        .post('holidays/delete', {
          data: {
            id: holidayID,
          },
        })
        .then(() => {
          this.$store.commit('court/removeFromTarget', {
            target: 'holidayIDs',
            value: holidayID,
          });
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
  components: {
    NewHolidayDialog,
    EditHolidayDialog,
  },
});
