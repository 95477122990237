




































































import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import { mapGetters } from 'vuex';
import { PardonDecree } from '@/vuex/investigation/pardonDecree';

import NewPardonDecreeDialog from '@/components/offender/pardonDecree/NewPardonDecreeDialog.vue';
import EditPardonDecreeDialog from '@/components/offender/pardonDecree/EditPardonDecreeDialog.vue';

export default Vue.extend({
  data() {
    return {
      search: '',
      showDeleted: false,
    };
  },
  created(): void {
    // This call is potentially unnecessary since all decrees are
    // fetched at startup (in App.vue) so that they are available
    // on Offender-related pages.
    this.$store.dispatch('investigation/fetchPardonDecrees', { force: false });
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('investigation', ['allPardonDecrees']),

    canAdd(): boolean {
      return this.hasPermission('admin.pardonDecree.create');
    },
    /**
     * decrees is the array the v-data-table presents. We determine whether
     * to return the entire list or just the non-deleted ones, and order
     * descending by number/issue dates.
     */
    decrees(): PardonDecree[] {
      const all: PardonDecree[] = this.allPardonDecrees;
      let decrees = _.chain(all);
      if (!this.showDeleted) {
        decrees = decrees.filter({ isDeleted: false });
      }
      return decrees.orderBy(['number', 'issuedAt'], ['desc', 'desc']).value();
    },

    headers(): any[] {
      const h = [
        {
          text: i18n.t('pardonDecrees.number'),
          value: 'number',
          width: '10%',
          align: 'right',
        },
        {
          text: i18n.t('summons.issuedAt'),
          value: 'issuedAt',
          width: '15%',
        },
        {
          text: i18n.t('corpus.title'),
          value: 'title',
          width: '30%',
        },
        {
          text: i18n.t('pardonDecrees.enforcementBeginsAt'),
          value: 'enforcementBeginsAt',
          width: '15%',
        },
        {
          text: i18n.t('attachment.plural'),
          width: '15%',
        },
        { text: i18n.t('action.plural'), width: '10%' },
      ];

      if (this.canAdd) {
        h.unshift({
          text: i18n.t('command.edit'),
          value: 'id',
          width: '2%',
        });
      }

      return h;
    },
  },
  components: {
    NewPardonDecreeDialog,
    EditPardonDecreeDialog,
  },
});
