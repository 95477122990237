



























import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';

export default Vue.extend({
  props: {
    cols: {
      type: [String, Number],
    },
    xl: {
      required: false,
      type: String,
    },
    lg: {
      required: false,
      type: String,
    },
    md: {
      required: false,
      type: String,
    },
    sm: {
      required: false,
      type: String,
    },
    xs: {
      required: false,
      type: String,
    },
    label: String,
    pluralLabel: {
      required: false,
      type: String, // optionally pass in a specific plural label for collections
    },
    suffix: String,
    value: {
      type: [String, Array, Number, Boolean],
      default: null,
    },
    lookupTable: String,
    includeUnknown: Boolean,
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),
    showThisField(): boolean {
      if (this.$slots && this.$slots.default) {
        return true;
      }

      if (this.computedValue === '') {
        return false;
      }

      if (this.includeUnknown) {
        return true;
      }

      return !!this.computedValue && this.computedValue !== '0';
    },
    computedLabel(): string {
      if (
        this.pluralLabel &&
        Array.isArray(this.value) &&
        this.value.length > 1
      ) {
        return `${this.pluralLabel}`;
      } else {
        return `${this.label}`;
      }
    },
    computedValue(): string {
      if (this.value === null || this.value === undefined) {
        return '';
      }

      if (Array.isArray(this.value)) {
        if (this.lookupTable) {
          return _.chain([this.value])
            .flatten()
            .map((id) => this.nameForIDInCollection(id, this.lookupTable))
            .value()
            .join(', ');
        } else {
          return `${this.value.join(', ')}`;
        }
      } else if (this.value === true || this.value === false) {
        return this.value
          ? i18n.t('command.yes').toString()
          : i18n.t('command.no').toString();
      } else {
        if (this.lookupTable) {
          if (this.value || (this.value === 0 && this.includeUnknown)) {
            return this.nameForIDInCollection(this.value, this.lookupTable);
          } else {
            return '';
          }
        }
        return `${this.value}`;
      }
    },
  },
});
