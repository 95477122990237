






































import Vue from 'vue';

import { mapGetters } from 'vuex';
import { TranslatedString } from '@/lib/translated';

import housingAPI from '@/vuex/housing/housingAPI';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      error: null as any,
      name: {} as TranslatedString,
      type: 'facility',
      geographyID: 201,
    };
  },

  computed: {
    ...mapGetters('language', ['languages']),
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.geographyID = 201;
      this.name = {} as TranslatedString;
      this.error = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await housingAPI.post('facilities/add-facility', {
          data: {
            name: this.name,
            geographyID: this.geographyID,
          },
        });
        const newState = response.data.data;
        this.$store.commit('housing/setState', newState);
        this.$store.commit('housing/prependToTarget', {
          target: 'facilityIDs',
          value: Object.keys(newState.facility),
        });

        // Close the Dialog
        this.isOpen = false;
      } catch (err) {
        this.error = err;
      }
    },
  },
});
