

































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { PunishmentType } from '@/vuex/crime/crime';

export default Vue.extend({
  props: {
    component: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('crime', ['punishmentTypeWithID']),
    ...mapGetters('court', ['subjectPropertyName']),
    punishmentType(): PunishmentType | null {
      if (!this.component) {
        return null;
      }
      return this.punishmentTypeWithID(this.component.punishmentTypeID);
    },
  },
});
