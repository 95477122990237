























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import AddSubjectPropertyDialog from '@/components/subjectProperty/AddSubjectPropertyDialog.vue';
import EditSubjectPropertyDialog from '@/components/subjectProperty/EditSubjectPropertyDialog.vue';
import SubjectPropertyExpander from '@/components/subjectProperty/SubjectPropertyExpander.vue';
import SubjectPropertyLabel from '@/components/subjectProperty/SubjectPropertyLabel.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
    caseID: {
      default: 0,
      type: Number,
    },
  },

  created(): void {
    this.fetchData();
  },

  data() {
    return {
      showDeleted: false,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('court', [
      'subjectPropertyForDossier',
      'subjectPropertyName',
    ]),
    ...mapGetters('geography', ['fullGeographyName']),

    headers(): any[] {
      return [
        { value: 'edit', text: i18n.t('command.edit'), width: '5%' },
        { value: 'label', text: i18n.t('subjectProperties.label') },
        { value: 'locationGeographyID', text: i18n.t('location.singular') },
        {
          value: 'quantity',
          text: i18n.t('subjectProperties.quantity'),
          align: 'right',
        },
        {
          value: 'totalValueInPuls',
          text: i18n.t('subjectProperties.totalValue'),
          align: 'right',
        },
        { value: 'delete', text: i18n.t('command.delete'), width: '5%' },
        { value: null, text: '', width: '5%' },
      ];
    },

    subjectProperties(): SubjectProperty[] {
      return this.subjectPropertyForDossier(this.dossierID) || [];
    },

    displayedSubjectProperties(): SubjectProperty[] {
      if (!this.showDeleted) {
        return _.filter(this.subjectProperties, (p) => !p.isDeleted);
      }
      return this.subjectProperties;
    },

    onDossierPage(): boolean {
      return this.$route.path.includes('dossiers');
    },

    haveDeleted(): boolean {
      return _.some(this.subjectProperties, { isDeleted: true });
    },
  },

  watch: {
    dossierID(val: number): void {
      this.fetchData();
    },
  },

  methods: {
    fetchData(): void {
      if (this.dossierID) {
        this.$store.dispatch('court/fetchSubjectPropertiesForDossier', {
          dossierID: this.dossierID,
        });
      }
    },

    edit(subjectPropertyID: number): void {
      (this.$refs.editDialog as any).edit(subjectPropertyID);
    },

    deleteSubjectProperty(sp: SubjectProperty) {
      courtAPI
        .post(`subject-properties/update`, {
          data: {
            id: sp.id,
            caseID: this.caseID,
            dossierID: this.dossierID,
            label: sp.label,
            description: sp.description,
            subjectPropertyType: sp.typeID,
            locationGeographyID: sp.locationGeographyID,
            locationDetails: sp.locationDetails,
            landAreaInSquareMeters: sp.landAreaInSquareMeters,
            itemValueInPuls: sp.itemValueInPuls,
            quantity: sp.quantity,
            totalValueInPuls: sp.totalValueInPuls,
            isDeleted: true,
          },
        })
        .then((response) => {
          const newState = response.data.data;
          this.$store.commit('court/setState', newState);
          this.$store.commit('court/addDocketEntry', response);
        });
    },
  },

  components: {
    AddSubjectPropertyDialog,
    EditSubjectPropertyDialog,
    SubjectPropertyExpander,
    SubjectPropertyLabel,
  },
});
