










import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Profile } from '@/vuex/profile/profile';

import ProfileHeaderNav from '@/components/profile/ProfileHeaderNav.vue';
import ProfileDetailsDisplay from '@/components/profile/ProfileDetailsDisplay.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('court', ['dossiersForParty']),
    ...mapGetters('offender', ['offendersForProfile']),
    ...mapGetters('housing', ['facilityName']),
    profile(): Profile {
      return this.profileWithID(this.id);
    },
  },
  components: {
    ProfileDetailsDisplay,
    ProfileHeaderNav,
  },
});
