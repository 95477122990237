















































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import i18n from '@/i18n';
import router from '@/router';
import {
  changedCriteria,
  criteriaToQueryString,
  routeToCriteria,
} from '@/lib/criteria';

import { Dossier } from '@/vuex/court/dossier';

import CaseTypeSelector from '@/components/court/CaseTypeSelector.vue';
import CourtSelector from '@/components/court/court/CourtSelector.vue';
import CourtStageSelector from '@/components/court/CourtStageSelector.vue';
import CrimeSelector from '@/components/crime/CrimeSelector.vue';
import DossierStageSelector from '@/components/dossier/DossierStageSelector.vue';
import PartySearchSelector from '@/components/court/party/PartySearchSelector.vue';

export default Vue.extend({
  props: {
    /**
     * route expects the $route object from the parent component. It is optional
     * and should only be supplied by OffenderList, where we *want* a tight
     * connection between the page URL and the OffenderSearcher criteria object.
     */
    route: Object,

    /**
     * dense triggers a more condensed design for the component, which is useful
     * when it appears in a popup or other compressed space.
     */
    dense: Boolean,
  },

  created() {
    if (this.route) {
      this.routeChanged();
    }

    // Load the initial data...
    this.fetchData();

    // ...then make every future calls to this.fetchData or this.routeChanged
    // be debounced so that they can never be called faster than once
    // every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
    this.routeChanged = _.debounce(this.routeChanged, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,
      advancedSearch: false,

      initialCriteria: {},
      criteria: {
        q: '',
        activeOnly: false,
        number: '',
        awarenessNumber: '',
        firstDepartmentID: 0,
        firstDepartmentLocationID: 0,
        lastDepartmentID: 0,
        lastDepartmentLocationID: 0,
        crimeIDs: [],
        crimeMatchAll: false,
        severityID: 0,
        partyIDs: [] as number[],
        partyMatchAll: false,
        partyTypeID: 0,
        statusID: 0,
        caseType: '',
        withDocketEventTypeID: 0,
        withoutDocketEventTypeID: 0,
        dossierStageID: null as number | null,
        courtStageID: 0,
        firstCourtID: 0,
        lastCourtID: 0,
        finalOnly: false,
        includesSubjectPropertyTypeID: 0,
        filedBetween: '',
      },

      results: [] as Dossier[],

      page: {
        number: 1,
        size: 10,
        serverCount: 0,
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['subjectPropertyTypes']),

    /**
     * criteriaUnchanged returns true when the currently-active criteria
     * is unchanged from its original, default state on the form.
     */
    criteriaUnchanged(): boolean {
      return _.isEqual(this.criteria, this.initialCriteria);
    },

    /**
     * queryString computes the query string component of the search API call.
     * It includes pagination parameters controlled by the v-data-table props,
     * but only if they are not the only parameters.
     */
    queryString(): string {
      if (_.isEmpty(this.initialCriteria)) {
        this.initialCriteria = _.cloneDeep(this.criteria);
      }
      const cc = changedCriteria(this.criteria, this.initialCriteria);
      return criteriaToQueryString(cc, this.page);
    },

    /**
     * canExport computes whether the current user is allowed to download
     * an Excel export of the data on the screen.
     */
    canExport(): boolean {
      return this.hasPermission('dossier.excelExport');
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a commputed property so that it will
     * update with new translations when the locale changes.
     */
    headers(): any[] {
      return [
        {
          text: i18n.t('dossier.dossierNumber'),
          value: 'number',
          width: '203px',
        },
        {
          text: i18n.t('dossier.awarenessNumber.singular'),
          value: 'awarenessNumber',
        },
        { text: i18n.t('court.caseType.singular'), value: 'type' },
        { text: i18n.t('case.current'), value: 'currentCaseNumber' },
        { text: i18n.t('case.filedAt'), value: 'filedAt' },
        { text: i18n.t('court.status'), value: 'statusID' },
        { text: i18n.t('party.plural'), value: 'partyNames' },
      ];
    },
  },

  watch: {
    /**
     * route is watched so that when the $route of the topmost component
     * changes, we can update the query string. Note that we don't watch
     * $route directly from this component (note the lack of a dollar sign here),
     * because that would potentially trigger at undesirable times.
     *
     * Since we can search for Dossiers from other places than the full
     * DossierList page, we don't always want the route to be tied to this
     * component's behavior.
     */
    route(route: any) {
      this.routeChanged();
    },

    /**
     * queryString is watched so that a new search can be triggered.
     */
    queryString(newVal: string, oldVal: string) {
      this.fetchData();
    },

    /**
     * criteria is watched so that when the search criteria is changed after
     * results have already been returned, we can reset the page number.
     */
    criteria: {
      deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        if (this.page.serverCount > 0 && this.page.number > 1) {
          this.page.number = 1;
        }
      },
    },

    crimeIDs(val: number[]) {
      if (val.length < 2) {
        // When there's a single item, matching ANY is OK.
        // This is relevant because the UI for changing this value gets hidden.
        this.criteria.crimeMatchAll = false;
      }
    },

    partyIDs(val: number[]) {
      if (val.length < 2) {
        // When there's a single item, matching ANY is OK.
        // This is relevant because the UI for changing this value gets hidden.
        this.criteria.partyMatchAll = false;
      }
    },

    /**
     * advancedSearch changes the UI, so we need to make sure to reset
     * the no longer visible fields
     */
    advancedSearch(isAdvanced: boolean) {
      if (isAdvanced) {
        this.resetSimpleCriteria();
      } else {
        this.resetAdvancedCriteria();
      }
    },
  },

  methods: {
    /**
     * routeChanged is triggered when the page URL changes.
     */
    routeChanged(): void {
      routeToCriteria(this.route, this.criteria, this.page);
      if (!this.criteriaUnchanged && this.criteria.q === '') {
        this.advancedSearch = true;
      }
    },

    /**
     * fetchData is called when the search button is pressed or when any table
     * pagination setting is changed. It refreshes the locally-held search
     * results with the current page of results from the server.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;

      try {
        const url = `/dossiers/search?${this.queryString}`;
        const res = await courtAPI.get(url);
        this.page.serverCount = _.get(res, 'data.meta.totalResults', 0);
        this.results = _.get(res, 'data.data', []);
        this.$emit('queryChanged', this.queryString);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    /**
     * reset clears the search and resets search results. Primarily designed
     * to be called externally via a $ref to this component so that the searcher
     * can be reset when a dialog is opened.
     */
    reset(): void {
      this.resetSimpleCriteria();
      this.resetAdvancedCriteria();
    },

    resetSimpleCriteria(): void {
      this.criteria.q = '';
    },

    resetAdvancedCriteria(): void {
      this.criteria = Object.assign(this.criteria, this.initialCriteria);
    },

    /**
     * goToDossier is the row click handler for case search results. It triggers
     * navigation to the Offender detail page for the offender in that row.
     */
    goToDossier(dossier: Dossier): void {
      router.push({
        name: 'dossierShow',
        params: { id: String(dossier.id) },
      });
    },
  },

  components: {
    CaseTypeSelector,
    CourtSelector,
    CourtStageSelector,
    CrimeSelector,
    DossierStageSelector,
    PartySearchSelector,
  },
});
