







































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { blankTranslatedString, TranslatedString } from '@/lib/translated';

export default Vue.extend({
  props: {
    value: {
      type: Array,
      default: () => [] as TranslatedString[],
    },
    label: String,
  },

  computed: {
    ...mapGetters('language', ['align', 'languages', 'valueForLocale']),

    items(): TranslatedString[] {
      const items = (this.value || []) as TranslatedString[];
      return items;
    },
  },

  methods: {
    update(i: number, locale: string, value: string): void {
      const newItems = Object.assign([], this.items) as TranslatedString[];
      if (newItems.length < i - 1) {
        newItems.push(blankTranslatedString());
      }
      newItems[i][locale] = value.trim();
      this.$emit('input', newItems);
    },

    blurred(rowIndex: number, langIndex: number): void {
      if (rowIndex === this.items.length - 1) {
        // If the blur event happens on the last row of data
        if (langIndex === this.languages.length - 1) {
          // ...and the last language in that row
          this.removeEmptyRows();
        }
      }
    },

    addRow(): void {
      if (this.items.length > 0) {
        const lastItem = _.last(this.items);
        if (this.isEmpty(lastItem)) {
          return;
        }
      }
      const newItems = Object.assign([], this.items);
      newItems.push(blankTranslatedString());
      this.$emit('input', newItems);
    },

    removeRow(): void {
      if (this.items.length <= 0) {
        return;
      }
      const lastItem = _.last(this.items);
      if (this.isEmpty(lastItem)) {
        const newItems = Object.assign([], this.items);
        newItems.splice(newItems.length - 1, 1);
        this.$emit('input', newItems);
      }
    },

    removeEmptyRows(): void {
      const newItems = Object.assign([], this.items);
      for (let i = newItems.length - 1; i >= 0; i--) {
        const row = newItems[i];
        if (this.isEmpty(row)) {
          newItems.splice(i, 1);
        }
      }
      this.$emit('input', newItems);
    },

    isEmpty(row: TranslatedString | undefined): boolean {
      if (row === undefined) {
        return true;
      }
      return _.every(_.values(row), (v) => v === '');
    },
  },
});
