
























































import Vue from 'vue';

export default Vue.extend({
  props: {
    value: Number,
  },
  data() {
    return {
      min: 0,
      max: 50,
      range: [0, 50],
      score: this.value ? this.value : 20,
    };
  },
  watch: {
    value(val) {
      this.score = val;
    },
    score(newVal) {
      this.$emit('input', newVal);
    },
  },
  computed: {
    getColor(): string {
      if (this.score < 10) {
        return 'amber';
      }
      if (this.score < 20) {
        return 'orange';
      }
      if (this.score < 50) {
        return 'red';
      }
      return 'red';
    },
    securityLevel(): string {
      if (this.score < 10) {
        return 'minimum';
      }
      if (this.score < 20) {
        return 'medium';
      }
      if (this.score <= 50) {
        return 'maximum';
      }
      return 'maximum';
    },
  },
  methods: {
    textColor(val) {
      return val + '--text';
    },
  },
});
