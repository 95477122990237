


















import _ from 'lodash';
import axios from 'axios';
import Vue from 'vue';
import { mapState } from 'vuex';

import { TranslatedString } from '@/lib/translated';
import { VSelectItem } from '@/lib/vue-typescript';

// ROOTID is the topmost item in the legacy database (it's the
// Islamic Republic of Afghanistan entry)
const ROOTID = 1000000001;

export default Vue.extend({
  props: {
    label: String,
    value: [Number, Array],
    rules: Array,
    disabled: Boolean,
    readonly: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    chips: Boolean,
  },
  data() {
    return {
      loading: false,
      ids: [] as number[],
      nameLookup: {} as {
        [id: number]: { id: number; name: TranslatedString; parentID: number };
      },
      isExpanded: {} as { [id: number]: boolean },
      errorMessages: [] as string[],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapState('language', ['locale']),

    /**
     * legacyDepartmentChoices builds the dropdown choice items. Most of
     * the processing has been pre-done, but we need to choose the active
     * locale and then sort the result here so that the component translates
     * on the fly when the locale is changed.
     */
    legacyDepartmentChoices(): VSelectItem[] {
      const choices = Object.values(this.nameLookup);
      return _.chain(this.ids)
        .map((id: number) => ({
          value: id,
          text: this.nameLookup[id].name[this.locale],
        }))
        .sortBy('text')
        .value();
    },
  },
  methods: {
    /**
     * fetchData pulls the legacy department list from a static Github Gist
     * file and processes the raw data into a lookup table.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      this.errorMessages = [];
      const url =
        'https://gist.githubusercontent.com/adlio/e517e68d26de2d09054bc67dd1a5d4d4/raw/3911c9ac68eb159e7e17629587f12c0d9c726a66/login_departments.json';
      try {
        const response = await axios.get(url);
        if (response && response.data) {
          this.processData(response.data);
        } else {
          this.errorMessages = ['API Request Failed'];
        }
      } catch (err) {
        this.errorMessages = [err];
      }
      this.loading = false;
    },

    /**
     * processData accepts the raw API data input, and processes it into the
     * nameLookup table and the ids array so that we can efficiently build
     * the dropdown choices
     */
    processData(entries: any[]): void {
      // Reset our internal storage
      this.ids = [];
      this.nameLookup = {};
      this.isExpanded = {};

      // First pass:
      // Update values in the name lookup table, which will hold the raw data
      // and an ids array which will aid iteration tasks.
      for (const entry of entries) {
        // First update the lookup table. We want every entry stored in the
        // loookup table
        Vue.set(this.nameLookup, entry.org_id, {
          id: entry.org_id,
          name: {
            en: entry.org_nameen || '',
            fa: entry.org_namedr || '',
            ps: entry.org_namepa || '',
          },
          parentID: entry.org_parent || 0,
        });

        // Not every entry gets added to the ids array. This allows us to
        // exclude certain items from being chosen, but lets them exist as
        // a parent entry so that expandNames can find them.

        // Skip blank entries
        if (!entry.org_nameen) {
          continue;
        }

        // Skip inactive entries
        if (entry.org_active === 0) {
          continue;
        }

        this.ids.push(entry.org_id);
      }

      // Second pass:
      // Expand names by prepending the names of parents. We can't do this
      // until we're sure every parent is already in the lookup table.
      for (const id of this.ids) {
        this.expandNames(id);
      }
    },

    /**
     * expandNames recursively processes an item in our nameLookup table,
     * prepending the names of all parents except the country-level item.
     */
    expandNames(id: number): void {
      // Avoid re-processing already expanded items
      if (this.isExpanded[id]) {
        return;
      }

      const item = this.nameLookup[id];
      const parentID = item.parentID;
      if (parentID > 0) {
        // First make sure the parent is processed. This will exit quickly
        this.expandNames(parentID);
        if (parentID !== ROOTID) {
          const parent = this.nameLookup[parentID];
          item.name = {
            en: parent.name.en + ' :: ' + item.name.en,
            fa: parent.name.fa + ' :: ' + item.name.fa,
            ps: parent.name.ps + ' :: ' + item.name.ps,
          };
        }
      }

      this.isExpanded[id] = true;
    },
  },
});
