














import Vue from 'vue';
import { mapGetters } from 'vuex';

import { User } from '@/vuex/user/user';
import userAPI from '@/vuex/user/userAPI';

import UserForm from '@/components/user/UserForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null as any,
      isOpen: false,
      loading: false,
      user: null as User | null,
    };
  },
  computed: {
    ...mapGetters('user', ['userWithID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    async reset(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('user/fetchUser', {
        id: this.id,
        force: true,
      });
      const myCopy = Object.assign({}, this.userWithID(this.id));
      this.user = myCopy;
      this.loading = false;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.user) {
        return;
      }

      try {
        this.error = null;
        const response = await userAPI.post('/users/update', {
          data: {
            userID: this.id,
            fullName: this.user.fullName,
            initials: this.user.initials,
            email: this.user.email,
            telephone: this.user.telephone,
            remarks: this.user.remarks,
            password: this.user.password,
            passwordConfirmation: this.user.passwordConfirmation,
            roles: this.user.roles,
            isSuspended: this.user.isSuspended,
            isDeleted: this.user.isDeleted,
            deletedReason: this.user.deletedReason,
            suspendedReason: this.user.suspendedReason,
            reactivatedReason: this.user.reactivatedReason,
          },
        });

        // Update Vuex state
        const newState = response.data.data;
        this.$store.commit('user/setState', newState);

        // Close the Dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    UserForm,
  },
});
