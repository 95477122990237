



















import Vue from 'vue';

import { Participant } from '@/vuex/court/participant';
import { mapState, mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    participant: {
      type: Object as () => Participant,
    },
  },
  computed: {
    ...mapState('court', ['partyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['partyName']),
  },
});
