




































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import i18n from '@/i18n';
import { criteriaToQueryString } from '@/lib/criteria';
import { modelsToState } from '@/lib/vuex-domainmodel';
import { Attorney } from '@/vuex/court/attorney';

import AddAttorneyDialog from '@/components/court/attorney/AddAttorneyDialog.vue';
import EditAttorneyDialog from '@/components/court/attorney/EditAttorneyDialog.vue';

export default Vue.extend({
  props: {
    selectable: Boolean,
    dense: Boolean,
  },
  created() {
    // Remember what the blank queryString looked like so we can compare
    // it later to see if any user modifications were made.
    this.initialQueryString = this.queryString;

    // Load the initial data...
    this.fetchData();
    // then make every future call to this.fetchData be debounced so that
    // it can never be called faster than once every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },
  data() {
    return {
      loading: false,
      advancedSearch: false,
      criteria: {
        q: '',
        name: '',
        surname: '',
        fatherName: '',
        grandfatherName: '',
        licenseNumber: '',
        residenceGeographyID: 0,
        isDeleted: false,
      },
      attorneyIDs: [] as number[],

      currentPage: 1,
      serverTotalResults: 0,
      pageSize: 10,

      // initialQueryString is set in the created() hook to remember what the
      // initial queryString() output was.
      initialQueryString: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('court', ['attorneyWithID', 'attorneyName']),

    canAdd(): boolean {
      // Some users are just not allowed to create attorneys. Forbid them
      // first.
      if (!this.hasPermission('attorney.create')) {
        return false;
      }

      if (this.selectable) {
        // The selectable prop indicates that the user is supposed to be
        // choosing an existing attorney. Before letting them create one in that
        // circumstance, force them to do a search first.
        return this.queryStringChanged;
      }

      return true;
    },

    /**
     * attorneys computes the array of Attorney entities which currently appear
     * in the data table. By injecting search results into the Attorney store in
     * Vuex, we ensure that all Attorney entity data for the selected item
     * lives on when selecting a Attorney for use on other pages.
     */
    attorneys(): Attorney[] {
      return this.attorneyIDs.map((id: number) => this.attorneyWithID(id));
    },

    /**
     * queryString computes the URL querystring parameters which will be
     * submitted to the server to perform the search. NOTE that this
     * value is combined with pagination criteria inside fetchData, so this
     * value doesn't exactly match the API request.
     */
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    /**
     * queryStringChanged computes whether the queryString on this component
     * has changed from what its initial value was when the component was
     * created.
     */
    queryStringChanged(): boolean {
      return this.queryString !== this.initialQueryString;
    },

    /**
     * headers dynamically builds the headers array so that translations for
     * the column titles can update live.
     */
    headers(): any[] {
      const h: any[] = [
        { text: i18n.t('person.name'), value: 'name', width: '30%' },
        { text: i18n.t('attorney.licenseNumber'), value: 'licenseNumber' },
        { text: i18n.t('person.phoneNumber'), value: 'phoneNumber' },
        { text: i18n.t('person.residence'), value: 'residenceGeographyID' },
      ];
      if (!this.selectable && this.canAdd) {
        h.unshift({
          text: i18n.t('command.edit'),
          value: 'id',
          width: '4em',
          sortable: false,
        });
      }
      return h;
    },
  },
  watch: {
    /**
     * queryString is watched so that a new search can be triggered.
     * We also reset back to page #1 anytime a new search is triggered.
     */
    queryString(newVal: string) {
      this.currentPage = 1;
      this.fetchData();
    },

    /**
     * advancedSearch changes the UI, so we need to make sure to reset
     * the no longer visible fields
     */
    advancedSearch(isAdvanced: boolean) {
      this.reset();
    },
  },
  methods: {
    /**
     * rowClasses conditionally adds classes to the <tr> tag.
     */
    rowClasses(item: Attorney): string[] {
      const classes = [] as string[];
      if (this.selectable) {
        classes.push('selectable');
      }
      if (item.isDeleted) {
        classes.push('isDeleted');
      }
      return classes;
    },

    /**
     * afterAttorneyAdded is triggered when the user successfully adds a new
     * record via the add dialog attached to the search interface. It receives
     * the ID of the newly-created Attorney, and immediately selects it if the
     * searcher is in selectable mode. If not, it forcefully adds it to the
     * front of the search results so the user immediately sees it.
     */
    afterAttorneyAdded(newID: number): void {
      if (this.selectable) {
        this.selectAttorney(newID);
      } else {
        this.attorneyIDs.unshift(newID);
        if (this.serverTotalResults < 1) {
          this.serverTotalResults = 1;
        }
      }
    },

    /**
     * selectAttorney is triggered by a click on one of the rows in the table,
     * only when the selectable prop is set.
     */
    selectAttorney(row: any): void {
      if (this.selectable) {
        this.$emit('selected', parseInt(row.id, 10));
      }
    },

    /**
     * reset clears the search and resets search results. Primarily designed
     * to be called externally via a $ref to this component so that the searcher
     * can be reset when a dialog is opened.
     */
    reset(): void {
      this.$nextTick(() => {
        this.criteria.q = '';
        this.criteria.name = '';
        this.criteria.surname = '';
        this.criteria.fatherName = '';
        this.criteria.grandfatherName = '';
        this.fetchData();
        if (this.$refs.query) {
          (this.$refs.query as any).focus();
        }
      });
    },

    /**
     * fetchData performs the actual search. It hits the API with the active
     * query and pagination criteria and updates the locally-held search results
     * data.
     */
    async fetchData(): Promise<void> {
      this.loading = true;

      const args = [] as string[];
      args.push(`page[size]=${this.pageSize}`);
      args.push(`page[number]=${this.currentPage}`);
      args.push(this.queryString);

      try {
        const response = await courtAPI.get(
          `/attorneys/search?` + args.join('&'),
        );

        if (response && response.data && response.data.data) {
          if (response.data.meta) {
            this.serverTotalResults = response.data.meta.totalResults;
          }

          // Add this batch of attorneys to Vuex
          const attorneys = response.data.data;
          const newState = modelsToState('attorney', attorneys);
          this.$store.commit('court/setState', newState);

          // Track which attorneyIDs are being viewed. This feeds
          // searchResults so that the rows in the table are from Vuex
          // and setState changes made during editing have immediate effect.
          this.attorneyIDs = _.map(attorneys, (a) => a.id);
        }
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    AddAttorneyDialog,
    EditAttorneyDialog,
  },
});
