






































import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Case } from '@/vuex/court/case';
import { Dossier } from '@/vuex/court/dossier';
import { PartyAttorney } from '@/vuex/court/participant';

import PartyAttorneysForm from '@/components/court/attorney/PartyAttorneysForm.vue';

export default Vue.extend({
  props: {
    value: Array,
    dossierID: Number,
    caseID: Number,
    partyID: Number,
  },

  data() {
    return {
      isOpen: false,
      attorneys: [] as PartyAttorney[],

      error: null as any,
    };
  },

  computed: {
    ...mapGetters('court', ['fullPartyName', 'caseWithID', 'dossierWithID']),

    kase(): Case {
      return this.caseWithID(this.caseID);
    },

    dossier(): Dossier {
      return this.dossierWithID(this.dossierID);
    },
  },

  watch: {
    value(newValue) {
      this.attorneys = newValue;
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state, which
     * for this component is the array of PartyAttorney entities bound to our
     * v-model value prop.
     */
    reset(): void {
      this.attorneys = (this.value || []) as PartyAttorney[];
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the current list of attorneys for this Participant
     * to the server.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await courtAPI.post('participants/update-attorneys', {
          data: {
            dossierID: this.dossierID,
            caseID: this.caseID,
            partyID: this.partyID,
            attorneys: this.attorneys,
          },
        });

        // Apply the changed state to the appropriate Vuex store.
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/addDocketEntry', response);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    PartyAttorneysForm,
  },
});
