








import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';

/**
 * JSONAPIError response is the error object which will be returned by the
 * catch clause of API request failures. It may contain an errors array
 * underneath the response.data.errors key.
 */
interface JSONAPIErrorResponse {
  response?: {
    data?: {
      errors?: Array<{
        id: string;
        status: string;
        code: string;
        title: string;
        detail: string;
        debugInfo: any;
      }>;
    };
  };
}

export default Vue.extend({
  props: {
    error: [String, Array, Object, Error],
    errors: [String, Array, Object, Error],
    codeOverride: Object,
  },
  computed: {
    /**
     * messages builds an array of message strings to display in v-alert
     * boxes. This function acts as an adapter/translator for the error and
     * errors props. It combines both, flattens and compacts them, and extracts
     * the response.data.errors entries from a JSONAPI server error.
     */
    messages(): string[] {
      // Combine the singular and plural input into a single flattened array
      const input = _.flatten([this.error, this.errors]);
      return _.chain(input)
        .map(this.expandJSONAPIErrors)
        .flatten()
        .compact()
        .value();
    },
  },
  methods: {
    /**
     * expandJSONAPIErrors expands the response.data.errors array into an
     * array of translated error messages if it exists.
     *
     * If not, it returns the input unmodified (and untranslated).
     */
    expandJSONAPIErrors(err: any | JSONAPIErrorResponse): any | any[] {
      if (!_.has(err, 'response.data.errors')) {
        return err;
      }

      // Exclamation points because we have guaranteed that response.data.errors
      // exists via the _.has test above.
      return _.map(err.response!.data!.errors || [], (err) => {
        if (err.code) {
          /**
           * If we have some code overrides, implement those before translating.
           */
          if (this.codeOverride && this.codeOverride[err.code]) {
            err.code = this.codeOverride[err.code];
          }
          let msg = i18n.t(err.code);
          if (
            err.detail &&
            err.detail !== err.code &&
            err.detail !== err.title
          ) {
            msg = msg + ': ' + err.detail;
          }
          return msg;
        }
        return err.title;
      });
    },
  },
});
