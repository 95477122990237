


















import Vue from 'vue';

import i18n from '@/i18n';

export default Vue.extend({
  props: {
    value: String,
    label: String,
    hint: String,
    readonly: Boolean,
    rules: Array,
    hideDetails: Boolean,
    singleLine: Boolean,
    loading: Boolean,
    disabled: Boolean,
    clearable: Boolean,
  },

  data() {
    return {
      blockSizes: [4, 4, 5],
    };
  },
  computed: {
    mask(): string {
      return this.blockSizes.map((size) => '#'.repeat(size)).join('-');
    },
    /**
     * computedRules combines the user-supplied array of rule functions with
     * a function to forbid invalid rule formats (e.g. not enough digits)
     */
    computedRules(): any[] {
      const ruleFuncs: any[] = [];
      if (this.rules && this.rules.length) {
        ruleFuncs.push(...this.rules);
      }
      // Never allow an incomplete number
      ruleFuncs.push(this.forbidIncomplete);
      return ruleFuncs;
    },
  },
  methods: {
    /**
     * forbidIncomplete is a validator function which can be supplied to the
     * rues prop of a Vuetify component. It returns true if the provided input
     * has the correct format/length, and returns a translated error message
     * otherwise
     */
    forbidIncomplete(v: string): string | boolean {
      if (!v) {
        return true;
      }
      if (v.length === this.mask.length) {
        return true;
      }
      return i18n.t('error.invalidFormat').toString();
    },
  },
});
