


























































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender } from '@/vuex/offender/offender';
import { Transfer } from '@/vuex/offender/transfer';

export default Vue.extend({
  props: {
    transferID: Number,
  },

  data() {
    return {
      pin: '',
      din: '',
      isDetainee: false,
      isOpen: false,
      error: null as any,
    };
  },

  computed: {
    ...mapGetters('offender', ['transferWithID', 'offenderWithID']),
    ...mapGetters('housing', ['facilityName']),

    transfer(): Transfer {
      return this.transferWithID(this.transferID);
    },

    offender(): Offender {
      const transfer = this.transfer;
      return this.offenderWithID(transfer.offenderID);
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      if (this.offender) {
        this.din = this.offender.DIN;
        this.pin = this.offender.PIN;
      }

      if (this.transfer) {
        this.isDetainee = this.transfer.isDetainee;
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('transfers/complete', {
          data: {
            transferID: this.transferID,
            pin: this.pin,
            din: this.din,
            isDetainee: this.isDetainee,
          },
        });
        const newState = response.data.data;
        this.$store.commit('offender/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
