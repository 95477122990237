import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Attorney {
  id: number;
  name: string;
  surname: string;
  fatherName: string;
  grandfatherName: string;
  licenseNumber: string;
  phoneNumber: string;
  emailAddress: string;
  residenceGeographyID: number;
  isDeleted: boolean;
}

export const state = {
  attorney: {} as { [id: number]: Attorney },
};

export const getters = {
  attorneyWithID:
    (state) =>
    (id: number): Attorney | null => {
      return state.attorney[id] || null;
    },
  attorneyName:
    (state, getters) =>
    (id: number): string => {
      const attorney = getters.attorneyWithID(id);
      if (attorney !== null) {
        return `${attorney.name} ${attorney.surname}`;
      } else {
        return `ERR: Don't know about Attorney #${id}`;
      }
    },
  fullAttorneyName:
    (state, getters) =>
    (id: number): string => {
      const attorney = getters.attorneyWithID(id);
      if (attorney !== null) {
        return `${attorney.name} ${attorney.surname} ${attorney.fatherName} ${attorney.grandfatherName}`;
      } else {
        return '';
      }
    },
};
export const mutations = {};

export const actions = {
  async fetchAttorney({ commit }, id: number) {
    const response = await courtAPI.get(`/attorneys/${id}`);
    const attorney = response.data.data;

    const newState = modelsToState('attorney', attorney);
    commit('setState', newState);
    commit('setTarget', {
      target: 'attorney',
      index: attorney.id,
      value: attorney,
    });
  },
};
