























import Vue from 'vue';

import DocketablePresenter from './DocketablePresenter.vue';

export default Vue.extend({
  props: {
    events: Array,
  },
  computed: {
    /**
     * headers builds the VDataTable headers
     */
    headers(): any[] {
      return [
        {
          text: this.$i18n.t('docketEntry.date'),
          value: 'filedAtSort',
          width: '130px',
          sortable: true,
        },
        {
          text: this.$i18n.t('details'),
          value: '',
          sortable: false,
        },
      ];
    },
  },
  components: {
    DocketablePresenter,
  },
});
