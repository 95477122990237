







































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import { Offender } from '@/vuex/offender/offender';
import { PardonDecree } from '@/vuex/investigation/pardonDecree';

import AssignPardonDecreeDialog from '@/components/offender/pardonDecree/AssignPardonDecreeDialog.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', ['offenderWithID', 'offenderIsReleased']),
    ...mapGetters('investigation', ['pardonDecreeWithID']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    pardonDecrees(): PardonDecree[] {
      if (!this.offender) {
        return [];
      }
      const decreeIDs = this.offender.pardonDecreeIDs || [];
      return _.map(decreeIDs, this.pardonDecreeWithID);
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },

    /**
     * canAdd returns true if its acceptable to add a new pardon decree
     */
    canAdd(): boolean {
      if (this.isReleased) {
        return false;
      }

      if (!this.offender) {
        return false;
      }

      return this.hasPermission(
        'offender.assignPardonDecree',
        'facility',
        this.offender.housingFacilityID,
      );
    },

    headers(): any[] {
      const h = [
        {
          text: i18n.t('pardonDecrees.number'),
          value: 'number',
          width: '10%',
        },
        {
          text: i18n.t('summons.issuedAt'),
          value: 'issuedAt',
          width: '15%',
        },
        {
          text: i18n.t('corpus.title'),
          value: 'title',
          width: '30%',
        },
        {
          text: i18n.t('pardonDecrees.enforcementBeginsAt'),
          value: 'enforcementBeginsAt',
          width: '15%',
        },
        {
          text: i18n.t('attachment.plural'),
          width: '15%',
        },
      ];
      return h;
    },
  },
  components: {
    AssignPardonDecreeDialog,
  },
});
