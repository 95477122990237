

















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Case } from '@/vuex/court/case';
import { Dossier } from '@/vuex/court/dossier';

interface CaseTab {
  kase: Case;
  currentCase: boolean;
  disabled: boolean;
}

export default Vue.extend({
  props: {
    dossierID: Number,
    disabledCaseIDs: Array,
    backgroundColor: String,
  },
  data(): any {
    return {
      activeTab: null as number | null,
    };
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', [
      'casesForDossier',
      'dossierWithID',
      'participantsForCase',
    ]),
    ...mapState('court', ['courtStages']),
    dossier(): Dossier {
      return this.dossierWithID(this.dossierID);
    },
    cases(): Case[] {
      return this.casesForDossier(this.dossierID);
    },
    tabs(): CaseTab[] {
      return _.chain(this.cases)
        .orderBy('filedAt', 'desc')
        .map((kase) => {
          return {
            kase,
            currentCase: this.dossier && this.dossier.currentCaseID === kase.id,
            disabled: _.includes(this.disabledCaseIDs || [], kase.id),
          };
        })
        .value();
    },
  },
  watch: {
    tabs(tabs: CaseTab[]): void {
      this.activeTab = _.findLastIndex(tabs, { disabled: false }) || 0;
    },
  },
});
