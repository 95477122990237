
















import _ from 'lodash';
import Vue from 'vue';
import i18n from '@/i18n';

export default Vue.extend({
  props: {
    label: String,
    hint: String,
    persistentHint: Boolean,
    value: [Number, Array],
    multiple: Boolean,
    chips: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    required: Boolean,
    rules: Array,
    items: Array,
  },

  data() {
    return {
      /**
       * fixedValueChoices holds the array of fixed fee assessment amounts that
       * are allowed, measured in AFN.
       */
      fixedValueChoices: [0, 200, 300, 500, 1000, 5000],

      /**
       * percentageChoices holds the array of percentages of the disputed value
       * which are allowed for selection (e.g. 1%, 2%, 5%, 10%).
       */
      percentageChoices: [0.01, 0.02, 0.05, 0.1],
    };
  },

  computed: {
    itemsProvided(): boolean {
      return this.items && this.items.length > 0;
    },

    feeChoices(): any[] {
      const choices = [] as any[];

      // Add fixed value fee amount choices
      let numbers = this.fixedValueChoices.map((n) => n * 100); // Convert from AFN to Puls
      if (this.itemsProvided) {
        numbers = _.intersection(numbers, this.items) as number[];
      }
      choices.push(
        ...numbers.map((amount: number) => ({
          value: amount,
          text:
            amount === 0
              ? i18n.t('fee.exempt').toString()
              : amount / 100 + ' ' + i18n.t('currency.afghani').toString(), // Convert from Puls to AFN when displaying
        })),
      );

      // Add percentage value choices
      let percents = this.percentageChoices;
      if (this.itemsProvided) {
        percents = _.intersection(percents, this.items) as number[];
      }
      choices.push(
        ...percents.map((percentage) => ({
          value: percentage,
          text:
            (percentage * 100).toFixed(0) +
            '%' +
            ' ' +
            i18n.t('fee.valueOfLawsuit').toString(),
        })),
      );

      return choices;
    },
  },
});
