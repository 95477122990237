import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';
import _ from 'lodash';

export interface CaseJudge {
  id: number;
  caseID: number;
  judgeID: number;
  isLeadJudge: boolean;
  isInvestigatorJudge: boolean;
  filedAt?: string;
  registeredAt?: string;
}

export const state = {
  caseJudge: {} as { [id: number]: CaseJudge },
  caseJudgeIDsForCase: {} as { [id: number]: number[] },
};

export const getters = {
  caseJudgesByCaseID:
    (state) =>
    (caseID: number): CaseJudge[] => {
      return (state.caseJudgeIDsForCase[caseID] || []).map(
        (id: number) => state.caseJudge[id],
      ) as CaseJudge[];
    },
};

export const mutations = {};

export const actions = {
  async fetchCaseJudges(
    { commit, state },
    payload = {} as { caseID: number },
  ): Promise<void> {
    const response = await courtAPI.get(`/cases/${payload.caseID}/case-judges`);
    const caseJudges = response.data.data;
    const newState = modelsToState('caseJudge', caseJudges);
    commit('setState', newState);
    commit('setTarget', {
      target: 'caseJudgeIDsForCase',
      index: payload.caseID,
      value: _.map(caseJudges, 'id'),
    });
  },
};
