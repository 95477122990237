

















import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Party } from '@/vuex/court/party';

import PartyForm from './PartyForm.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  data() {
    return {
      error: null as any,
      isOpen: false,
      party: null as Party | null,
    };
  },
  computed: {
    ...mapGetters('court', ['partyWithID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      if (this.id) {
        // Make a copy of the party so that the form remains cancellable
        // and we don't make direct edits to the Vuex object
        const existingParty = this.partyWithID(this.id);
        this.party = Object.assign({}, existingParty);
      } else {
        this.party = null;
      }
      this.error = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.party) {
        return;
      }
      try {
        this.error = null;
        const response = await courtAPI.post('parties/update', {
          data: {
            id: this.id,
            name: this.party.name,
            surname: this.party.surname,
            fatherName: this.party.fatherName,
            grandfatherName: this.party.grandfatherName,
            aliases: this.party.aliases,
            nationalID: this.party.nationalID,
            genderID: this.party.genderID,
            isKochi: this.party.isKochi,
            occupation: this.party.occupation,
            phoneNumber: this.party.phoneNumber,
            emailAddress: this.party.emailAddress,
            birthplaceGeographyID: this.party.birthplaceGeographyID,
            residenceGeographyID: this.party.residenceGeographyID,
            isOrganization: this.party.isOrganization,
            organizationName: this.party.organizationName,
            businessLicenseNumber: this.party.businessLicenseNumber,
            isDeleted: this.party.isDeleted,
          },
        });

        // Store the newly-created entity in the Vuex store
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    PartyForm,
  },
});
