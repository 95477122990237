var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.hasPermission('crimeReport.create') ||
    _vm.hasPermission('search.crimeReport') ||
    _vm.hasPermission('search.profile') ||
    _vm.hasPermission('dashboard.detection') ||
    _vm.hasPermission('profile.backgroundCheck')
  )?_c('v-list-group',{attrs:{"value":_vm.active},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('v-subheader',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm.$t('module.police'))+" ")])],1)]},proxy:true}],null,false,3088111375)},[_vm._l((_vm.detectionDashboardRoutes),function(rte,index){return _c('v-list-item',{key:index,attrs:{"to":rte,"exact":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.singular'))+" "),_c('div',{staticClass:"text--disabled text-caption"},[(rte.query.departmentID > 0)?_c('DepartmentName',{attrs:{"id":rte.query.departmentID}}):_c('GeographyName',{attrs:{"id":1}})],1)])],1)],1)}),(_vm.hasPermission('crimeReport.create'))?_c('NewCrimeReportDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('crimeReport.singular'))+" ")])],1)],1)]}}],null,false,2209741326)}):_vm._e(),(_vm.hasPermission('search.crimeReport'))?_c('v-list-item',{attrs:{"to":{ name: 'crimeReportSearch' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-search")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('crimeReport.plural'))+" ")])],1)],1):_vm._e(),(_vm.hasPermission('search.profile'))?_c('v-list-item',{attrs:{"to":{ name: 'profileList' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-search")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('profile.plural'))+" ")])],1)],1):_vm._e(),(_vm.hasPermission('profile.backgroundCheck'))?_c('v-list-item',{attrs:{"to":{ name: 'backgroundCheck' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-mask")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('profile.backgroundCheck'))+" ")])],1)],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }