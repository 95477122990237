



































































import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';
import { mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import { Hearing } from '@/vuex/court/hearing';

import CourtroomSelector from '@/components/court/courtroom/CourtroomSelector.vue';

export default Vue.extend({
  props: {
    hearingID: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      isOpen: false,
      error: null as any,

      // Hearing fields which we modify
      caseID: 0,
      hearingDate: '',
      time: '',
      courtroomID: 0,
      notes: '',
    };
  },

  computed: {
    ...mapGetters('court', ['caseWithID', 'hearingWithID']),

    hearing(): Hearing {
      return this.hearingWithID(this.hearingID);
    },

    kase(): Case {
      return this.caseWithID(this.hearing.caseID);
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      if (this.hearing) {
        this.caseID = this.hearing.caseID;
        this.hearingDate = this.hearing.hearingDate.substr(0, 10);
        this.time = this.hearing.time;
        this.courtroomID = this.hearing.courtroomID;
        this.notes = this.hearing.notes;
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await courtAPI.post('hearings/reschedule', {
          data: {
            hearingID: this.hearingID,
            caseID: this.caseID,
            hearingDate: this.hearingDate,
            time: this.time,
            courtroomID: this.courtroomID,
            notes: this.notes,
          },
        });

        // Apply the server-provided state to the appropriate Vuex store
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);

        // Reflect the action in the docket.
        this.$store.commit('court/addDocketEntry', response);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    CourtroomSelector,
  },
});
