import { render, staticRenderFns } from "./SentenceComponentDescription.vue?vue&type=template&id=e87598b4&"
import script from "./SentenceComponentDescription.vue?vue&type=script&lang=ts&"
export * from "./SentenceComponentDescription.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports