




































































































































































































import _ from 'lodash';
import Vue from 'vue';
import i18n from '@/i18n';

import { mapGetters, mapState } from 'vuex';
import { Transfer } from '@/vuex/offender/transfer';

import TransferActions from '@/components/offender/transfer/TransferActions.vue';

export default Vue.extend({
  props: {
    facilityID: Number,
    transfers: Array as () => Transfer[],
    status: String,
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('housing', ['facilityName', 'cellName']),
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('profile', ['profileName']),
    ...mapState('offender', ['transferReasons']),
    items(): any[] {
      return this.transfers.map((t: Transfer) => {
        const o = this.offenderWithID(t.offenderID);
        return Object.assign(t, {
          name: o ? this.profileName(o.profileID) : '',
        });
      });
    },
  },

  data() {
    return {
      headers: [
        { text: i18n.t('startDate'), value: 'departedAt' },
        {
          text: i18n.t('transfer.estimatedArrivalAt'),
          value: 'estimatedArrivalAt',
        },
        { text: i18n.t('person.name'), value: '' },
        { text: i18n.t('housingFacility.typeOptions.facility'), value: '' },
        { text: i18n.t('transfer.reason'), value: 'reason' },
        { text: i18n.t('action.plural'), value: '' },
      ],
    };
  },

  methods: {
    translateStatus(val): string {
      return this.$i18n.t('transfer.statusOptions.' + val).toString();
    },

    facilityIDForDisplay(transfer: Transfer): number {
      if (this.facilityID === transfer.toHousingFacilityID) {
        return transfer.fromHousingFacilityID;
      } else {
        return transfer.toHousingFacilityID;
      }
    },
  },

  components: {
    TransferActions,
  },
});
