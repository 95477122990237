



















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { DocketEntry } from '@/vuex/court/docket';

import DocketEventTable from '@/components/docket/DocketEventTable.vue';
import NewDocketEntryMenu from './NewDocketEntryMenu.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
  },
  created(): void {
    this.fetchData();
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    dossierID(val: number): void {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('court', ['docketEventsForDossier']),
    ...mapState('court', ['docketEventTypes']),

    events(): DocketEntry[] {
      let entries: DocketEntry[] = this.docketEventsForDossier(this.dossierID);
      entries = _.filter(entries, (e) => {
        const actionType = _.get(e, 'actionType');
        const typeID = _.get(e, 'payload.typeID');
        if (typeID) {
          const eventType = _.find(this.docketEventTypes, { id: typeID });
          if (eventType) {
            return eventType.module === 'detection';
          }
        }
        return false;
      });
      return entries;
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('court/fetchDocketEntriesForDossier', {
        dossierID: this.dossierID,
        force: false,
      });
      this.loading = false;
    },
  },
  components: {
    DocketEventTable,
    NewDocketEntryMenu,
  },
});
