




import Vue from 'vue';
import i18n from '@/i18n';

export default Vue.extend({
  props: {
    value: Number,
  },
  computed: {
    landArea(): string {
      if (!this.value) {
        return '';
      }

      if (this.value % 2000 === 0) {
        const strVal = (this.value / 2000).toLocaleString();
        return `${strVal} ${i18n.t('area.jerib')}`;
      }

      if (this.value % 100 === 0) {
        const strVal = (this.value / 100).toLocaleString();
        return `${strVal} ${i18n.t('area.biswa')}`;
      }

      if (this.value % 5 === 0) {
        const strVal = (this.value / 5).toLocaleString();
        return `${strVal} ${i18n.t('area.biswasa')}`;
      }

      const strVal = this.value.toLocaleString();
      return `${strVal} ${i18n.t('area.squaremeter')}`;
    },
  },
});
