













import Vue from 'vue';
import { mapGetters } from 'vuex';

import { CaseStatus } from '@/vuex/court/case';

export default Vue.extend({
  props: {
    statusID: Number,
    dispositionReasonID: Number,
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),

    isDisposed(): boolean {
      return this.statusID === CaseStatus.Disposed;
    },
  },
});
