


































































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapState('court', ['partyTypes', 'attorneyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['fullPartyName', 'attorneyName']),
    ...mapState('profile', ['genders']),
    ...mapGetters('geography', ['fullGeographyName']),
    payload(): any {
      return this.entry.payload;
    },
    partyID(): number {
      if (!this.entry.affectedRecords) {
        return 0;
      }
      return _.first(this.entry.affectedRecords.partyIDs) || 0;
    },
  },
});
