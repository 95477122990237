



























































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import NewCrimeReportDialog from '@/components/detection/crimeReport/NewCrimeReportDialog.vue';

export default Vue.extend({
  props: {
    active: Boolean,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'entityIDsWithPermission']),

    detectionDashboardRoutes(): any {
      const entityIDs = this.entityIDsWithPermission('dashboard.detection');
      const routeTemplate = { name: 'detectionDashboard' };

      return _.map(entityIDs, (id: number | string) => {
        if (id === '*') {
          return Object.assign({}, routeTemplate, {
            query: { departmentID: null },
          });
        }
        return Object.assign({}, routeTemplate, {
          query: { departmentID: id },
        });
      });
    },
  },
  components: {
    NewCrimeReportDialog,
  },
});
