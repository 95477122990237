








































import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('housing', ['facilityName', 'cellName']),
    ...mapGetters('profile', ['profileName']),
    payload(): any {
      return this.entry.payload;
    },
    expectedRelease(): any {
      const expectedReleaseAt = moment(this.payload.detainedAt)
        .add(this.payload.imprisonmentDurationInHours, 'hours')
        .format();
      return expectedReleaseAt;
    },
  },
});
