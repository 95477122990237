var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.results.length)?_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.$t('charge.severity')))])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"no-data-text":_vm.$t('error.nodata'),"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'crimeReportSearch',
              query: Object.assign({}, _vm.criteria,
                {maxSeverityID: item.severityID}),
            }}},[_c('NumFmt',{attrs:{"value":item.count}})],1)]}}],null,true)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }