




































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import router from '@/router';
import { Corpus } from '@/vuex/corpus/corpus';

import CorpusForm from './CorpusForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
    },
  },

  created() {
    this.fetchData();
  },

  data() {
    return {
      waiting: true,
      valid: true,
      error: null as any,

      corpus: null as Corpus | null,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapState('corpus', ['corporaByID']),
    ...mapGetters('corpus', ['corporaWithParentID']),
  },

  watch: {
    id(newValue): void {
      this.fetchData();
    },
  },

  methods: {
    ...mapActions('corpus', [
      'deleteCorpus',
      'fetchCorpora',
      'fetchCorpus',
      'saveCorpus',
    ]),

    async fetchData(): Promise<void> {
      if (this.id !== undefined) {
        this.waiting = true;
        await Promise.all([this.fetchCorpora(), this.fetchCorpus(this.id)]);
        this.waiting = false;
        if (this.id !== undefined) {
          this.corpus = Object.assign({}, this.corporaByID[this.id]);
        }
      }
    },

    async deleteConfirmed(): Promise<void> {
      try {
        await this.deleteCorpus(this.id);
        if (this.corpus && this.corpus.parentID) {
          router.push({
            name: 'corpusShow',
            params: { id: String(this.corpus.parentID) },
          });
        } else {
          router.push({ name: 'corpusList' });
        }
      } catch (error) {
        this.error = error;
      }
    },

    cancelEdit(): void {
      if (this.id !== undefined) {
        router.push({ name: 'corpusShow', params: { id: String(this.id) } });
      } else {
        router.push({ name: 'corpusList' });
      }
    },

    async save(): Promise<void> {
      // Client-side validation
      const form = this.$refs.form as Vue & { validate: () => boolean };
      if (!form.validate()) {
        return;
      }
      try {
        await this.saveCorpus(this.corpus);
        router.push({ name: 'corpusShow', params: { id: String(this.id) } });
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    CorpusForm,
  },
});
