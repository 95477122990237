



import Vue from 'vue';

export default Vue.extend({
  props: {
    value: Number,
  },
  computed: {
    /**
     * formatAFN converts the value prop from Puls to AFN and performs an
     * Internationalized number format on it.
     */
    formatAFN(): string {
      return new Intl.NumberFormat().format(this.value / 100);
    },
  },
});
