






















































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import {
  CancelableTransferStatuses,
  Transfer,
  TransferStatus,
} from '@/vuex/offender/transfer';

import TransferCancelDialog from '@/components/offender/transfer/TransferCancelDialog.vue';
import TransferCompleteDialog from '@/components/offender/transfer/TransferCompleteDialog.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    transferID: Number,
  },
  computed: {
    ...mapGetters('offender', ['transferWithID']),
    ...mapGetters('auth', ['hasPermission']),

    transfer(): Transfer {
      return this.transferWithID(this.transferID);
    },

    /**
     * status is a convenience wrapper around transfer.status. It returns
     * 'unknown' until the full Transfer object is available.
     */
    status(): string {
      if (!this.transfer) {
        return 'unknown';
      }
      return this.transfer.status;
    },

    /**
     * isApprovable returns true if this Transfer is awaiting to be approved/denied
     * and the current user has permission to do that.
     */
    isApprovable(): boolean {
      if (this.status !== TransferStatus.Pending) {
        return false;
      }

      return this.hasPermission(
        'offender.transfer.approve',
        'facility',
        this.transfer.fromHousingFacilityID,
      );
    },

    /**
     * isStartable returns true if this Transfer is approved and not yet started
     * and the current user has permissions to start it.
     */
    isStartable(): boolean {
      if (this.status !== TransferStatus.Scheduled) {
        return false;
      }
      return this.hasPermission(
        'offender.transfer.start',
        'facility',
        this.transfer.fromHousingFacilityID,
      );
    },

    /**
     * isCancelable returns true if this Transfer is in a cancelable state and
     * the current user has permissions to cancel it.
     */
    isCancelable(): boolean {
      if (!_.includes(CancelableTransferStatuses, this.status)) {
        return false;
      }
      return this.hasPermission(
        'offender.transfer.request',
        'facility',
        this.transfer.fromHousingFacilityID,
      );
    },

    /**
     * isCompletable returns true if this Transfer is in progress and
     * the current user has permissions to mark it completed.
     */
    isCompletable(): boolean {
      if (this.status !== TransferStatus.InTransit) {
        return false;
      }
      return this.hasPermission(
        'offender.transfer.complete',
        'facility',
        this.transfer.toHousingFacilityID,
      );
    },

    /**
     * isAbortable returns true if this Transfer is in progress and
     * the current user has permissions to abort it ()
     */
    isAbortable(): boolean {
      if (this.status !== TransferStatus.InTransit) {
        return false;
      }
      return this.hasPermission(
        'offender.transfer.complete',
        'facility',
        this.transfer.toHousingFacilityID,
      );
    },
  },

  methods: {
    async approve(): Promise<void> {
      const response = await offenderAPI.post('transfers/approve', {
        data: { transferID: this.transferID },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);
    },

    async deny(): Promise<void> {
      const response = await offenderAPI.post('transfers/deny', {
        data: { transferID: this.transferID },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);
    },

    async start(): Promise<void> {
      const response = await offenderAPI.post('transfers/start', {
        data: { transferID: this.transferID },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);
    },

    async abort(): Promise<void> {
      const response = await offenderAPI.post('transfers/abort', {
        data: { transferID: this.transferID },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);
    },
  },

  components: {
    TransferCancelDialog,
    TransferCompleteDialog,
  },
});
