





































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Escape } from '@/vuex/offender/escape';
import { Offender } from '@/vuex/offender/offender';

import AddAnEscapeDialog from '@/components/offender/escape/AddAnEscapeDialog.vue';
import EscapesActions from '@/components/offender/escape/EscapesActions.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },

  created(): void {
    if (this.offenderID) {
      this.fetchData();
    }
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', [
      'escapesForOffender',
      'offenderIsReleased',
      'offenderWithID',
    ]),
    ...mapGetters('housing', ['facilityName']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    escapes(): Escape[] {
      return this.escapesForOffender(this.offenderID);
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },

    /**
     * canAdd returns true if its acceptable to add a new Escape
     */
    canAdd(): boolean {
      if (this.isReleased) {
        return false;
      }

      if (!this.offender) {
        return false;
      }

      const hasPendingEscape =
        _.filter(this.escapes, (escape) => {
          return !escape.recapturedAt;
        }).length > 0;

      if (hasPendingEscape) {
        return false;
      }

      return this.hasPermission(
        'offender.escape.create',
        'facility',
        this.offender.housingFacilityID,
      );
    },
  },

  watch: {
    offenderID(val: number): void {
      if (this.offenderID) {
        this.fetchData();
      }
    },
  },

  methods: {
    fetchData(): void {
      this.$store.dispatch('offender/fetchEscapes', {
        offenderID: this.offenderID,
      });
    },
  },

  components: {
    AddAnEscapeDialog,
    EscapesActions,
  },
});
