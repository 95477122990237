





















































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Dossier } from '@/vuex/court/dossier';
import { Case } from '@/vuex/court/case';
import { Participant } from '@/vuex/court/participant';

import AddParticipantDialog from '@/components/court/participant/AddParticipantDialog.vue';
import ParticipantDialog from '@/components/court/participant/ParticipantDialog.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
    caseID: Number,
    courtStageID: Number,
  },
  data() {
    return {
      showDeleted: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('court', [
      'partyName',
      'participantsForCase',
      'participantsForDossier',
      'partyWithID',
    ]),
    ...mapState('court', ['partyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('language', ['align']),
    ...mapGetters('court', ['caseWithID', 'dossierWithID']),
    dossier(): Dossier {
      return this.dossierWithID(this.dossierID);
    },
    kase(): Case {
      return this.caseWithID(this.caseID);
    },
    caseType(): string {
      if (this.dossier) {
        return this.dossier.type;
      }
      return '';
    },
    // TODO: use stage id to name helper
    // return string (not number) after helper is used
    courtStage(): string | number {
      if (this.kase) {
        return this.kase.stageID;
      }
      if (this.dossier) {
        return this.dossier.currentCourtStageID;
      }
      return '';
    },
    participants(): Participant[] {
      if (this.caseID) {
        return this.participantsForCase(this.caseID);
      } else {
        return this.participantsForDossier(this.dossierID);
      }
    },
    headers(): any[] {
      const h = [
        {
          text: this.$i18n.t('partyType.singular'),
          value: 'partyType',
        },
        {
          text: this.$i18n.t('person.name'),
          value: 'name',
        },
        {
          text: this.$i18n.t('person.phoneNumber'),
          value: 'phoneNumber',
        },
        {
          text: this.$i18n.t('person.emailAddress'),
          value: 'emailAddress',
        },
      ];
      return h;
    },
    filteredParticipants(): any {
      let participants = this.participants;
      if (!this.showDeleted) {
        participants = participants.filter((p: Participant) => !p.isDeleted);
      }

      return participants.map((p: Participant) => {
        const party = this.partyWithID(p.partyID);
        let partyType = this.nameForIDInCollection(
          p.partyTypeID,
          this.partyTypes,
        );
        if (p.partyTypeID !== p.originalPartyTypeID) {
          const originalType = this.nameForIDInCollection(
            p.originalPartyTypeID,
            this.partyTypes,
          );
          partyType = `${partyType} [ ${originalType} ]`;
        }
        return Object.assign({}, p, {
          partyType,
          fullName: this.partyName(p.partyID),
          phoneNumber: party.phoneNumber,
          emailAddress: party.emailAddress,
        });
      });
    },
  },
  methods: {
    open(participantID: number): void {
      (this.$refs.participantDialog as any).open(participantID);
    },
  },
  components: {
    AddParticipantDialog,
    ParticipantDialog,
  },
});
