




























































































import { get, cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';

import { mapGetters } from 'vuex';

import { AfghanistanID } from '@/vuex/geography/geography';
import { Case } from '@/vuex/court/case';
import { Summons } from '@/vuex/court/summons';

import CourtroomSelector from '@/components/court/courtroom/CourtroomSelector.vue';
import CourtSelector from '@/components/court/court/CourtSelector.vue';
import PartySelector from '@/components/court/party/PartySelector.vue';

export default Vue.extend({
  props: {
    value: Object,
    dossierID: Number,
    caseID: Number,
  },

  data() {
    return {
      timeMenuOpen: false,
    };
  },

  computed: {
    ...mapGetters('court', ['caseWithID', 'partyWithID']),

    kase(): Case {
      return this.caseWithID(this.caseID);
    },

    courtID(): number {
      if (this.kase) {
        return this.kase.courtID;
      } else {
        return 0;
      }
    },

    /**
     * summons computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    summons(): Summons {
      return this.value
        ? this.value
        : {
            dossierID: this.dossierID,
            caseID: this.caseID,
            partyID: 0,
            courtID: get(this, 'kase.courtID', 0),
            issuanceNumber: '',
            filedAt: '',
            iteration: 1,
            registeredAt: '',
            issuanceExpectedLocationID: AfghanistanID,
            issuanceNotes: '',
            presenceDate: '',
            presenceTime: '',
            presenceCourtroomID: 0,
            servedAt: '',
            serviceFiledAt: '',
            serviceResultID: 0,
            modeOfServiceID: 0,
            serviceLocationID: 0,
            servedToName: '',
            serviceNotes: '',
            appeared: false,
          };
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.summons), (v) => set(v, key, modifiedValue)),
      );
    },
  },

  components: {
    CourtroomSelector,
    CourtSelector,
    PartySelector,
  },
});
