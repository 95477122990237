

















import _ from 'lodash';
import Vue from 'vue';
import PhoneNumber from 'awesome-phonenumber';

import i18n from '@/i18n';

export default Vue.extend({
  props: {
    label: String,
    placeholder: String,
    hint: String,
    value: String,
    rules: Array,
    disabled: Boolean,
    readonly: Boolean,
    singleLine: Boolean,
    dense: Boolean,
    hideDetails: Boolean,
  },

  computed: {
    activeRules(): any[] {
      return [
        // Put prop-provided rules first (if they exist)
        ...(this.rules || []),
        (val) => this.isValidFormat(val) || i18n.t('error.provideValidPhone'),
      ];
    },

    computedLabel(): string {
      if (!this.label) {
        return i18n.t('person.phoneNumber').toString();
      }
      return this.label;
    },

    computedPlaceholder(): string {
      return (
        this.placeholder ||
        PhoneNumber.getExample('AF').getNumber('international')
      );
    },
  },

  methods: {
    /**
     * isValidFormat is a helper which returns true if the supplied value can
     * be successfully interpreted and formatted as an international phone
     * number.
     */
    isValidFormat(val: string): boolean {
      if (!val) {
        // Consider a blank input valid for purposes of the format
        // validator. Users can add a separate rule to require presence.
        return true;
      }
      return this.buildPhoneNumber(val).isValid();
    },

    /**
     * reformat forcefully-reformats the current value to have an international
     * format.
     */
    reformat(): void {
      const pn = this.buildPhoneNumber(this.value);
      this.$emit('input', pn.getNumber('international'));
    },

    /**
     * buildPhoneNumber is a utility function to create an awesome-phonenumber
     * object. It initially tries to use the raw input, assuming it's already
     * internationally formatted. If that fails to produce a valid phone number
     * it re-tries with the assumption that it's a local Afghan phone number.
     * This function will return an invalid PhoneNumber object if the input
     * is not interpetable as an international or Afghan number.
     */
    buildPhoneNumber(val: string): PhoneNumber {
      let pn = new PhoneNumber(val || '');
      if (pn.isValid()) {
        return pn;
      }
      pn = new PhoneNumber(val || '', 'AF');
      return pn;
    },
  },
});
