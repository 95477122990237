














import Vue from 'vue';
import i18n from '@/i18n';

export default Vue.extend({
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    value: String,
    label: String,
    hint: {
      type: String,
      default: 'HH:MM',
    },
    persistentHint: Boolean,
    rules: Array,
    loading: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    /**
     * computedRules builds an array of validator functions to pass to the rules
     * prop of the embedded text field. We add the externally-provided rules
     * first, and add a final validator that requires the time to be valid
     * or blank.
     */
    computedRules(): any[] {
      return [
        ...(this.rules || []),
        (val) => this.validTimeOrBlank(val) || i18n.t('error.provideValidTime'),
      ];
    },
  },
  methods: {
    /**
     * validTimeOrBlank is a helper function which returns true if the
     * provided value is a valid time (HH:MM), or is a blank value. We want to
     * ignore blank values so that the developer can separately decide whether
     * this field is required or not.
     */
    validTimeOrBlank(val: string | null): boolean {
      if (!val) {
        return true;
      }

      const segments = val.split(':');
      if (segments.length < 2) {
        return false;
      }
      const hours = segments[0];
      const minutes = segments[1];

      // Hours must be inclusively between 0 and 23
      if (parseInt(hours, 10) > 23 || parseInt(hours, 10) < 0) {
        return false;
      }

      // Minutes must be inclusively between 0 and 59
      if (parseInt(minutes, 10) > 59 || parseInt(hours, 10) < 0) {
        return false;
      }

      return true;
    },
  },
  watch: {},
});
