


















import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Profile } from '@/vuex/profile/profile';

import ProfileEditDialog from '@/components/profile/ProfileEditDialog.vue';
import ProfileDetailsDisplay from '@/components/profile/ProfileDetailsDisplay.vue';

export default Vue.extend({
  props: {
    profileID: Number,
    offenderID: Number,
    allowEdit: Boolean,
    tile: Boolean,
    flat: Boolean,
    dense: Boolean,
  },
  computed: {
    ...mapGetters('profile', ['profileWithID']),

    profile(): Profile {
      return this.profileWithID(this.profileID);
    },
  },
  components: {
    ProfileEditDialog,
    ProfileDetailsDisplay,
  },
});
