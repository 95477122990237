



























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import crimeAPI from '@/vuex/crime/crimeAPI';

import CrimeAdminMenu from '@/components/crime/CrimeAdminMenu.vue';
import NewCrimeGroupDialog from '@/components/crime/group/NewCrimeGroupDialog.vue';
import EditCrimeGroupDialog from '@/components/crime/group/EditCrimeGroupDialog.vue';

import i18n from '@/i18n';

export default Vue.extend({
  created(): void {
    this.fetchData();
  },

  data() {
    return {
      search: '',
      loading: true,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('crime', ['allCrimeGroups']),
    ...mapState('language', ['locale']),

    /**
     * headers builds the VTable column headers. It needs to be a computed prop
     * so that translations of the column header text are made when the
     * translation is changed.
     */
    headers(): any[] {
      return [
        {
          text: i18n.t('command.edit'),
          value: 'id',
          align: 'center',
          width: '20px',
          sortable: false,
        },
        {
          text: i18n.t('crimeGroup.name'),
          value: `name.${this.locale}`,
          align: 'start',
        },
        {
          text: i18n.t('crimeGroup.numberOfCrimes'),
          value: 'crimeIDs',
          align: 'right',
        },
        {
          text: i18n.t('action.plural'),
          value: 'actions',
          align: 'center',
          width: '20px',
          sortable: false,
        },
      ];
    },
  },

  methods: {
    async deleteCrimeGroup(crimeGroupID: number): Promise<void> {
      await crimeAPI.post('crime-groups/delete', {
        data: {
          id: crimeGroupID,
        },
      });
      this.$store.commit('crime/removeFromTarget', {
        target: 'crimeGroupIDs',
        value: crimeGroupID,
      });
    },

    async fetchData(): Promise<void> {
      this.loading = true;
      this.$store.dispatch('crime/fetchCrimeGroups', { force: false });
      this.loading = false;
    },
  },

  components: {
    CrimeAdminMenu,
    NewCrimeGroupDialog,
    EditCrimeGroupDialog,
  },
});
