














import _ from 'lodash';
import Vue from 'vue';
import courtAPI from '@/vuex/court/courtAPI';

import { Judge } from '@/vuex/court/judge';
import JudgeForm from '@/components/court/judge/JudgeForm.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      error: null as any,

      judge: null as Judge | null,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.judge = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.judge) {
        return;
      }
      this.error = null;

      try {
        // API Call and Response
        const response = await courtAPI.post('judges/create', {
          data: {
            name: this.judge.name,
            surname: this.judge.surname,
            fatherName: this.judge.fatherName,
            grandfatherName: this.judge.grandfatherName,
            emailAddress: this.judge.emailAddress,
            phoneNumber: this.judge.phoneNumber,
            jurisdictions: this.judge.jurisdictions,
          },
        });

        const newState = _.get(response, 'data.data', {});
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/prependToTarget', {
          target: 'judgeIDs',
          value: Object.keys(newState.judge),
        });

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    JudgeForm,
  },
});
