var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.hasPermission('admin.geography.create'))?_c('NewGeographyDialog',{attrs:{"initialParentID":_vm.activeGeographyID,"initialType":_vm.mostCommonType}}):_vm._e()]},proxy:true}])},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('geography.plural')))]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":_vm.$t('search'),"append-icon":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('GeographySelector',{attrs:{"max-specificity":"district"},model:{value:(_vm.activeGeographyID),callback:function ($$v) {_vm.activeGeographyID=$$v},expression:"activeGeographyID"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.geographies,"search":_vm.search,"sort-by":("name." + _vm.locale),"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('EditGeographyDialog',{attrs:{"id":item.id}})]}},{key:"item.type",fn:function(ref){
var value = ref.value;
return [_c('span',{class:{
                  'red--text': value !== _vm.mostCommonType,
                  'font-weight-bold': value !== _vm.mostCommonType,
                }},[_vm._v(" "+_vm._s(_vm.$t('geo.' + value))+" ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }