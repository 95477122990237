






























import Vue from 'vue';
import i18n from '@/i18n';

enum Units {
  Jerib = 1,
  Biswa = 2,
  Biswasa = 3,
  SquareMeter = 4,
}
const AllUnits = [Units.Jerib, Units.Biswa, Units.Biswasa, Units.SquareMeter];

export default Vue.extend({
  props: {
    value: Number,
    clearable: Boolean,
    label: String,
    hint: String,
    prefix: String,
    disabled: Boolean,
    readonly: Boolean,
    persistentHint: Boolean,
    hideDetails: Boolean,
    singleLine: Boolean,
    loading: Boolean,
    rules: Array,
  },
  data() {
    return {
      internalValue: 0,
      units: Units.Jerib,
    };
  },
  created() {
    this.outsideValueChanged(this.value);
  },
  computed: {
    /**
     * unitsOptions builds the v-select choice values for the unit
     * selector dropdown control.
     */
    unitsOptions(): any[] {
      return AllUnits.map((u: Units) => {
        const translationKey = 'area.' + Units[u].toLowerCase();
        return {
          value: u,
          text: i18n.t(translationKey).toString(),
        };
      });
    },
  },
  watch: {
    value(newValue: number) {
      this.outsideValueChanged(newValue);
    },
  },
  methods: {
    /**
     * outsideValueChanged is the event that is fired when the v-model prop
     * is provided with a new value. We always interpret this value as
     * being in Square Meters, so we update the internal units to Square Meters and
     * perform a reduction.
     */
    outsideValueChanged(newVal: number): void {
      this.internalValue = newVal;
      this.units = Units.SquareMeter;
      this.reduce();
    },

    /**
     * emit notifies v-model consumers of the current value via the 'emit'
     * event. We always $emit in Square Meters, regardless of what the internally
     * displayed units are.
     */
    emit(): void {
      this.reduce();
      const val = this.toSquareMeters(this.internalValue, this.units);
      this.$emit('input', val);
    },

    /**
     * reduce converts the current internal value + units into the simplest
     * (i.e. "most reduced") form. This function has no effect on the
     * externally data-bound (e.g. "emitted") value, it merely changes the
     * internal display of that value to be in the simplest form.
     */
    reduce(): void {
      const squareMeters = this.toSquareMeters(this.internalValue, this.units);
      if (squareMeters % 2000 === 0) {
        this.internalValue = squareMeters / 2000;
        this.units = Units.Jerib;
      } else if (squareMeters % 100 === 0) {
        this.internalValue = squareMeters / 100;
        this.units = Units.Biswa;
      } else if (squareMeters % 5 === 0) {
        this.internalValue = squareMeters / 5;
        this.units = Units.Biswasa;
      } else {
        this.internalValue = squareMeters;
        this.units = Units.SquareMeter;
      }
    },

    /**
     * toSquareMeters takes a value and unit and converts to Square Meters. This is a
     * a no-op is the provided units are already in Square Meters.
     */
    toSquareMeters(val: number, unit: Units): number {
      switch (unit) {
        case Units.Jerib:
          return val * 2000;
        case Units.Biswa:
          return val * 100;
        case Units.Biswasa:
          return val * 5;
        default:
          return val;
      }
    },
  },
});
