






































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import _ from 'lodash';

import { Participant } from '@/vuex/court/participant';
import {
  AppealPartyTypeIDs,
  DefendantPartyTypeIDs,
  PartyTypeID,
  ProsecutorPartyTypeIDs,
} from '@/vuex/court/party';

import ChargesForm from '@/components/charges/ChargesForm.vue';
import PartyAttorneysForm from '@/components/court/attorney/PartyAttorneysForm.vue';
import PartySearchSelector from '@/components/court/party/PartySearchSelector.vue';
import PartyTypeSelector from '@/components/dossier/PartyTypeSelector.vue';
import ProfileSearchSelector from '@/components/profile/ProfileSearchSelector.vue';
import ProsecutorSearchSelector from '@/components/investigation/prosecutor/ProsecutorSearchSelector.vue';

export default Vue.extend({
  props: {
    value: Object,
    caseType: String,
    courtStageID: Number,
    forcePartyTypeID: Number,
  },

  computed: {
    /**
     * participant computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    participant(): Participant {
      const defaultPartyTypeID =
        this.caseType === 'criminal'
          ? PartyTypeID.Defendant
          : PartyTypeID.Petitioner;
      const initialPartyTypeID = !!this.forcePartyTypeID
        ? this.forcePartyTypeID
        : defaultPartyTypeID;
      return this.value
        ? this.value
        : ({
            id: 0,
            dossierID: 0,
            caseID: 0,
            partyID: 0,
            partyTypeID: initialPartyTypeID,
            originalPartyTypeID: 0,
            attorneys: [],
            charges: [],
          } as Participant);
    },

    lockParty(): boolean {
      // When the Participant has already been saved to the server,
      // forbid changing the identity of the party.
      return !!this.participant.id;
    },

    canHaveCharges(): boolean {
      return !!(
        this.participant &&
        this.participant.partyID &&
        (this.participant.partyTypeID === PartyTypeID.Defendant ||
          this.participant.originalPartyTypeID === PartyTypeID.Defendant)
      );
    },

    canHaveAttorneys(): boolean {
      return !!(
        this.participant &&
        this.participant.partyID &&
        this.participant.partyTypeID !== PartyTypeID.Prosecutor &&
        this.participant.originalPartyTypeID !== PartyTypeID.Prosecutor
      );
    },

    isAppellantOrAppellee(): boolean {
      return _.includes(AppealPartyTypeIDs, this.participant.partyTypeID);
    },

    isDefendant(): boolean {
      return (
        _.includes(DefendantPartyTypeIDs, this.participant.partyTypeID) ||
        _.includes(DefendantPartyTypeIDs, this.participant.originalPartyTypeID)
      );
    },

    isProsecutor(): boolean {
      return (
        _.includes(ProsecutorPartyTypeIDs, this.participant.partyTypeID) ||
        _.includes(ProsecutorPartyTypeIDs, this.participant.originalPartyTypeID)
      );
    },

    showPartySelector(): boolean {
      // Early exit - Require partyTypeID to be known
      if (!this.participant.partyTypeID) {
        return false;
      }
      if (_.includes(AppealPartyTypeIDs, this.participant.partyTypeID)) {
        // When an Appeal party type is selected, we also require the
        // orignalPartyTypeID before selecting the Party.
        return !!this.participant.originalPartyTypeID;
      }
      return true;
    },

    /**
     * partyTypeCols is used to determine the number of cols used for each
     * party type selector on larger screens (md and above). When there's only
     * one selector, it's 4 cols wide. When there are 2 each should be 2 cols
     * so there's 4 total cols used by the Party Type area.
     */
    partyTypeCols(): number {
      if (this.isAppellantOrAppellee) {
        return 3;
      }
      return 6;
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any): void {
      this.$emit(
        'input',
        tap(cloneDeep(this.participant), (v) => set(v, key, value)),
      );
    },

    /**
     * partyTypeChanged is the UI component event handler for the Party Type
     * dropdown being changed by the user. Just like update(), we need to
     * $emit the entire Participant object, but we need to make sure to
     * reset the partyID when the party type was changed.
     */
    partyTypeChanged(newTypeID: number) {
      const newParticipant = cloneDeep(this.participant);
      if (!this.lockParty && newTypeID !== this.participant.partyTypeID) {
        newParticipant.partyID = 0;
      }
      newParticipant.partyTypeID = newTypeID;
      this.$emit('input', newParticipant);
    },

    /**
     * originalPartyTypeChanged is the UI component event handler for the
     * Original Party Type dropdown being changed by the user. Just like
     * update() we need to $emit the entire Participant object, but we need
     * to make sure to reset the partyID first when the user changed the
     * Original Party Type in a way that invalidated the previous choice.
     */
    originalPartyTypeChanged(newTypeID: number): void {
      const newParticipant = cloneDeep(this.participant);
      if (!this.lockParty) {
        newParticipant.partyID = 0;
      }
      newParticipant.originalPartyTypeID = newTypeID;
      this.$emit('input', newParticipant);
    },

    /**
     * partyChagned is the UI component event handler for the Party, Profile
     * and Prosecutor selector. Just like update() we $emit the entire
     * resulting state of the Participant here. But first, we use this
     * opportunity to "lock-in" the originalPartyType field for the Participant
     * at this time.
     */
    partyChanged(newID: number): void {
      const newParticipant = cloneDeep(this.participant);
      newParticipant.partyID = newID;
      const partyTypeKnown = !!newParticipant.partyTypeID;
      const originalPartyTypeKnown = !!newParticipant.originalPartyTypeID;
      if (newID && partyTypeKnown && !originalPartyTypeKnown) {
        newParticipant.originalPartyTypeID = newParticipant.partyTypeID;
      }
      this.$emit('input', newParticipant);
    },
  },

  components: {
    ChargesForm,
    PartyAttorneysForm,
    PartySearchSelector,
    PartyTypeSelector,
    ProfileSearchSelector,
    ProsecutorSearchSelector,
  },
});
