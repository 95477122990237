





































































































































import Vue from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import { Dossier } from '@/vuex/court/dossier';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import CaseDocket from '@/components/docket/CaseDocket.vue';
import CaseHeaderNav from '@/components/court/case/CaseHeaderNav.vue';
import CaseHearingCalendar from '@/components/court/hearing/CaseHearingCalendar.vue';
import CaseJudgesCard from '@/components/court/judge/CaseJudgesCard.vue';
import CaseStatusLabel from '@/components/court/case/CaseStatusLabel.vue';
import CivilDecisionSummary from '@/components/court/CivilDecisionSummary.vue';
import CourtRevenueSummary from '@/components/court/CourtRevenueSummary.vue';
import ParticipantsTable from '@/components/court/participant/ParticipantsTable.vue';
import PrimaryParticipants from '@/components/court/participant/PrimaryParticipants.vue';
import SubjectPropertiesSummary from '@/components/subjectProperty/SubjectPropertiesSummary.vue';
import SubjectPropertyTable from '@/components/subjectProperty/SubjectPropertyTable.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  data() {
    return {
      civilDecisionDialogOpen: false,
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', [
      'courtName',
      'courtroomsForCourt',
      'caseWithID',
      'dossierWithID',
      'hearingsForCase',
      'subjectPropertyForDossier',
    ]),

    loading(): boolean {
      // The page is considered loading until both the Case and Dossier are
      // fetched
      return !this.kase || !this.dossier;
    },

    kase(): Case {
      return this.caseWithID(this.id);
    },

    dossier(): Dossier | null {
      if (this.kase) {
        return this.dossierWithID(this.kase.dossierID);
      } else {
        return null;
      }
    },

    subjectProperties(): SubjectProperty[] {
      if (this.dossier) {
        return this.subjectPropertyForDossier(this.kase.dossierID);
      }
      return [];
    },

    age(): number {
      const filedDate = moment(this.kase.filedAt);
      const finalDate = moment(this.kase.finalizedAt);
      if (this.kase.isFinal) {
        return finalDate.diff(filedDate, 'days');
      } else {
        return moment().diff(filedDate, 'days');
      }
    },
  },

  components: {
    CaseDocket,
    CaseHeaderNav,
    CaseHearingCalendar,
    CaseJudgesCard,
    CaseStatusLabel,
    CivilDecisionSummary,
    CourtRevenueSummary,
    ParticipantsTable,
    PrimaryParticipants,
    SubjectPropertiesSummary,
    SubjectPropertyTable,
  },
});
