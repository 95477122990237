






import Vue from 'vue';

import PartySearcher from '@/components/court/party/PartySearcher.vue';

export default Vue.extend({
  components: { PartySearcher },
});
