


























































































































































import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import { Dossier } from '@/vuex/court/dossier';
import { CrimeReport } from '@/vuex/investigation/crimeReport';

import { Case } from '@/vuex/court/case';
import { Court } from '@/vuex/court/court';
import { Participant } from '@/vuex/court/participant';

import LegacyDossierVersionsDialog from '@/components/etl/LegacyDossierVersionsDialog.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters('court', [
      'dossierWithID',
      'casesForDossier',
      'caseWithID',
      'courtWithID',
      'defendantsForDossier',
      'defendantsForCase',
    ]),
    ...mapGetters('investigation', ['crimeReportForDossier']),
    ...mapState('court', [
      'civilCaseTopics',
      'lawsuitTypes',
      'courtStages',
      'dossierStages',
    ]),
    ...mapGetters('language', ['nameForIDInCollection']),
    dossier(): Dossier {
      return this.dossierWithID(this.id);
    },
    cases(): Case[] {
      return this.casesForDossier(this.id);
    },
    crimeReport(): CrimeReport | null {
      return this.crimeReportForDossier(this.id);
    },
    lawsuitTypeList(): string {
      return (this.dossier.lawsuitTypeIDs || [])
        .map((id) => {
          return this.nameForIDInCollection(id, this.lawsuitTypes);
        })
        .join(', ');
    },
    currentCase(): Case | null {
      if (this.dossier) {
        return this.caseWithID(this.dossier.currentCaseID);
      }
      return null;
    },
    currentCourt(): Court | null {
      if (this.currentCase) {
        return this.courtWithID(this.currentCase.courtID);
      }
      return null;
    },

    /**
     * showEnforcement controls whether the enforcement tab will appear.
     */
    showEnforcement(): boolean {
      if (!this.dossier) {
        return false;
      }
      if (this.dossier.type !== 'criminal') {
        return false;
      }
      return this.hasSentenceComponentsOnAnyCase;
    },

    /**
     * hasSentenceComponentsOnAnyCase is a utility function which combines all sentence
     * components present for all defendants on all cases of this dossier.
     */
    hasSentenceComponentsOnAnyCase(): boolean {
      const components = _.chain(this.cases)
        .map((c) => this.defendantsForCase(c.id))
        .flatten()
        .map((d: Participant) => _.get(d, 'sentenceComponents'))
        .compact()
        .flatten()
        .value();
      return components.length > 0;
    },
    age(): number {
      const filedDate = moment(this.dossier.filedAt);
      const finalDate = moment(this.dossier.finalizedAt);
      if (this.dossier.isFinal) {
        return finalDate.diff(filedDate, 'days');
      } else {
        return moment().diff(filedDate, 'days');
      }
    },
  },
  components: {
    LegacyDossierVersionsDialog,
  },
});
