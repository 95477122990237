




















import Vue from 'vue';
import { mapGetters } from 'vuex';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { PardonDecree } from '@/vuex/investigation/pardonDecree';

import PardonDecreeForm from '@/components/offender/pardonDecree/PardonDecreeForm.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  data() {
    return {
      isOpen: false,
      loading: false,

      pardonDecree: null as PardonDecree | null,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('investigation', ['pardonDecreeWithID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    async reset(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('investigation/fetchPardonDecree', this.id);
      const myCopy = Object.assign({}, this.pardonDecreeWithID(this.id));
      this.pardonDecree = myCopy;
      this.loading = false;
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We use it to persist the
     * object to the server.
     */
    async save(): Promise<void> {
      if (this.pardonDecree === null) {
        return;
      }
      this.error = null;

      try {
        const response = await investigationAPI.post('pardon-decrees/update', {
          data: {
            id: this.pardonDecree.id,
            number: this.pardonDecree.number,
            title: this.pardonDecree.title,
            issuedAt: this.pardonDecree.issuedAt,
            enforcementBeginsAt: this.pardonDecree.enforcementBeginsAt,
            summary: this.pardonDecree.summary,
            isDeleted: this.pardonDecree.isDeleted,
          },
        });

        const newState = response.data.data;
        this.$store.commit('investigation/setState', newState);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    PardonDecreeForm,
  },
});
