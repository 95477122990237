










































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { CaseJudge } from '@/vuex/court/caseJudge';

import AddCaseJudgeDialog from '@/components/court/judge/AddCaseJudgeDialog.vue';
import EditCaseJudgeDialog from '@/components/court/judge/EditCaseJudgeDialog.vue';
import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';
import RemoveCaseJudgeDialog from '@/components/court/judge/RemoveCaseJudgeDialog.vue';

export default Vue.extend({
  props: {
    courtID: Number,
    caseID: Number,
  },
  computed: {
    ...mapState('court', ['caseJudge', 'caseJudgeIDsForCase']),
    ...mapGetters('court', [
      'caseJudgesByCaseID',
      'judgeName',
      'caseJudgeIDsByCaseID',
    ]),
    headers(): any[] {
      const h = [
        {
          text: i18n.t('command.edit'),
          value: null,
          width: '2%',
          align: 'center',
          sortable: false,
        },
        {
          text: i18n.t('judge.investigatingJudge'),
          value: null,
          width: '8%',
          align: 'center',
          sortable: false,
        },
        {
          text: i18n.t('judge.leadJudge'),
          value: null,
          width: '8%',
          align: 'center',
          sortable: false,
        },
        {
          text: i18n.t('person.name'),
          value: 'id',
        },
        {
          text: i18n.t('command.delete'),
          width: '2%',
          align: 'center',
          sortable: false,
        },
      ];
      return h;
    },
    caseJudges(): CaseJudge[] {
      return this.caseJudgesByCaseID(this.caseID);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    close(): void {
      this.$emit('input', false);
    },
    fetchData(): void {
      this.$store.dispatch('court/fetchCaseJudges', { caseID: this.caseID });
    },
  },
  components: {
    AddCaseJudgeDialog,
    EditCaseJudgeDialog,
    JudgeSelector,
    RemoveCaseJudgeDialog,
  },
});
