import _ from 'lodash';
import userAPI from '@/vuex/user/userAPI';
import { domainmodelMutations } from '@/lib/vuex-domainmodel';
import { TranslatedString } from '@/lib/translated';

export interface Role {
  id: number;
  name: TranslatedString;
  entityType: string;
  hasAllPermissions: boolean;
  permissionIDs: number[];
}

export const enum RoleEntityType {
  Global = 'global',
  Court = 'court',
  Department = 'department',
  HousingFacility = 'facility',
}

export const AllRoleEntityTypes = [
  RoleEntityType.Global,
  RoleEntityType.Court,
  RoleEntityType.Department,
  RoleEntityType.HousingFacility,
];
export interface User {
  id: number;
  username: string;
  fullName: string;
  initials: string;
  email: string;
  telephone: string;
  remarks: string;
  password?: string;
  passwordConfirmation?: string;
  roles: UserRole[];
  isSuspended: boolean;
  isDeleted: boolean;
  createdAt: Date | null;
  passwordLastChangedAt: Date | null;
  lastLoginAt: Date | null;
  deletedReason: string;
  suspendedReason: string;
  reactivatedReason: string;
}

export interface UserRole {
  id: number;
  userID: number;
  roleID: number;
  entityType: RoleEntityType;
  hasAllEntities: boolean;
  entityID: number;
}

export interface UserPermissions {
  [key: string]: string | number[];
}

export interface Permission {
  // id is the unique integer identifier for a key, this is what should
  // be added to permissionIDs arrays representing the permissions which
  // a particular role possesses.
  id: number;

  // Key is the developer-friendly , "machine key" which uniquely
  // distinguishes one permission from another.
  key: string;
}

const state = {
  optionsLoaded: false,
  roles: [] as Role[],
  permissions: [] as Permission[],
  permissionKeysByID: {} as { [id: number]: string },
  permissionIDsByKey: {} as { [key: string]: number },

  user: {} as { [id: number]: User },
};

const getters = {
  /**
   * userWithID retrieves the User object with the supplied ID.
   */
  userWithID:
    (state) =>
    (id: number): User | null => {
      return state.user[id] || null;
    },

  /**
   * roleWithID retrieves the Role object with the supplied ID
   */
  roleWithID:
    (state) =>
    (id: number): Role | null => {
      return _.find(state.roles || [], { id });
    },

  /**
   * permissionsForUser builds a permissions lookup table specific for the
   * specified user. The lookup table has string keys, and values which are
   * either a number[] (array of permission IDs) or a string like '*'.
   * Here are some example key value pairs which might be returned:
   *
   * 'global:*': '*' <= A global admin role which has all permissions.
   * 'facility:*': [2,3,4] <= A facility role with 3 permissions, assigned to all facilities.
   * 'court:34': '*' <= A court role that has all permissions, assigned for a specific Court #34
   * 'department:2': [2,3] <= A department role that has 2 permissions at a specific facility
   */
  permissionsForUser:
    (state, getters) =>
    (user: User): UserPermissions => {
      const lookup: UserPermissions = {};
      if (!user) {
        return lookup;
      }
      for (const ur of user.roles || []) {
        const role = _.find(state.roles || [], { id: ur.roleID });
        if (role) {
          // key holds a combination of an entity type (e.g. court or facility,
          // and either a start (indicating all entities of that type) or a
          // number indicating a single ID. In combination, some options might
          // be:
          // 'facility:45'
          // 'court:123'
          // 'global:*'
          let key = '';

          switch (ur.entityType) {
            case RoleEntityType.Global:
              // Global roles aren't assigned to specific entities, so the entity
              // part is always a star.
              key = 'global:*';
              break;
            default:
              key = `${ur.entityType}:${ur.hasAllEntities ? '*' : ur.entityID}`;
          }

          // Get permissions from this user role/this iteration of the loop
          const thisPermIDs = role.hasAllPermissions ? '*' : role.permissionIDs;

          // Initially assume we're going to assign these permissions to the key
          let permIDs: string | number[] = thisPermIDs;

          // .. but if we already have existing permissions, we might need to
          // adjust the permIDs.
          if (_.has(lookup, key)) {
            const existingPermIDs = lookup[key];
            if (existingPermIDs === '*' || permIDs === '*') {
              // If either the previous iteration or this new set of permissions
              // are a '*', then the user has all permissions for this key.
              permIDs = '*';
            } else if (_.isArray(existingPermIDs) && _.isArray(permIDs)) {
              // If both are arrays, we need to merge the permissions into one
              // unique list of permission IDs
              permIDs = _.uniq([...permIDs, ...existingPermIDs]);
            }
          }
          lookup[key] = permIDs;
        }
      }
      return lookup;
    },

  /**
   * allowedEntitiesForUser calculates the IDs for the Departments, Courts
   * or Housing Facilities that this user is allowed to access.
   *
   * NOTE: The returned array will include a '*' entry if this User has been
   * assigned any role with hasAllEntities = true.
   */
  allowedEntitiesForUser:
    (state, getters) =>
    (user: User, entityType: RoleEntityType): Array<number | string> => {
      if (!user) {
        return [];
      }

      // First check for a Global role which has all permissions. This indicates
      // they should have access to all Departments, despite not mentioning any.
      if (getters.isGlobalAdmin(user)) {
        return ['*'];
      }
      // Then collect the Department IDs referenced
      return _.chain(user.roles)
        .filter({ entityType })
        .map((ur) => (ur.hasAllEntities ? '*' : ur.entityID))
        .value();
    },

  /**
   * entityIDsWithPermissionForUser is a more specific version of
   * allowedEntitiesForUser. Instead of returning an array of entity IDs to
   * which the user is associated at all, it returns the list of entity IDs
   * that to which the user is associated via a role with the specified
   * permission.
   */
  entityIDsWithPermissionForUser:
    (state, getters) =>
    (user: User, permissionKey: string | number): Array<number | string> => {
      if (!user) {
        return [];
      }

      // Translate what might be a string or integer reference to the permission
      // into a number to match the database storage of permissionIDs.
      const permID = _.isNumber(permissionKey)
        ? permissionKey
        : state.permissionIDsByKey[permissionKey];
      if (!permID) {
        console.error(`Unknown permission: ${permissionKey}`);
      }

      const entityIDs: Array<string | number> = [];
      for (const ur of user.roles || []) {
        const role: Role = _.find(state.roles || [], { id: ur.roleID });
        if (role) {
          if (role.hasAllPermissions) {
            entityIDs.push('*');
          } else if (_.includes(role.permissionIDs, permID)) {
            if (ur.hasAllEntities) {
              entityIDs.push('*');
            } else {
              entityIDs.push(ur.entityID);
            }
          }
        }
      }

      return _.chain(entityIDs).uniq().value();
    },

  /**
   * isGlobalAdmin returns true if the provided user has a Global role which
   * hasAllPermissions. This indicates that this user has full permissions
   * throughout the system.
   */
  isGlobalAdmin:
    (state, getters) =>
    (user: User): boolean => {
      if (!user) {
        return false;
      }
      return (
        _.chain(user.roles)
          .filter({ entityType: RoleEntityType.Global })
          .map('roleID')
          .map(getters.roleWithID)
          .filter({ hasAllPermissions: true })
          .value().length > 0
      );
    },
};

const mutations = {
  ...domainmodelMutations,

  setOptionsLoaded(state, val: boolean): void {
    state.optionsLoaded = val;
  },

  /**
   * indexPermissions takes the raw array of Permission entities and re-indexes
   * them into two key value pair lookup tables, one mapping string keys to
   * integer ids (key:id) and one mapping integer ids to string keys (id:key).
   */
  indexPermissions(state): void {
    state.permissionKeysByID = _.chain(state.permissions)
      .keyBy('id')
      .mapValues('key')
      .value();
    state.permissionIDsByKey = _.chain(state.permissions)
      .keyBy('key')
      .mapValues('id')
      .value();
  },

  /**
   * setRole adds OR replaces a new or updated Role object in the roles
   * state.
   *
   * @param state
   * @param val
   */
  setRole(state, val: Role | Role[]): void {
    const roles = _.flatten([val]);
    for (const role of roles) {
      const i = _.findIndex(state.roles, { id: role.id });
      if (i >= 0) {
        state.roles.splice(i, 1, role);
      } else {
        state.roles.push(role);
      }
    }
  },
};

const actions = {
  async fetchOptions(
    { state, commit },
    payload = {} as { force: boolean },
  ): Promise<void> {
    // Early return when the options are already loaded
    if (!payload.force && state.optionsLoaded) {
      return;
    }

    const response = await userAPI.get('/options');
    const newState = _.get(response, 'data.data');
    commit('setState', newState);
    commit('setOptionsLoaded', true);
    commit('indexPermissions');
  },

  /**
   * fetchUser retrieves User entities from the server and injects them into the
   * Vuex store. It accepts a single User ID or an array of IDs. Users who are
   * already in the Store are not re-fetched unless force is set to true
   */
  async fetchUser(
    { commit, state },
    payload = {} as { id: number | number[]; force: boolean },
  ): Promise<void> {
    let ids: number[] = _.flatten([payload.id]);
    const existingIDs = _.map(state.user, 'id');
    if (!payload.force) {
      ids = _.difference(ids, existingIDs);
    }

    if (ids.length > 0) {
      const idsStr = ids.join(',');
      const response = await userAPI.get(`/users/batch?ids=${idsStr}`);
      const newState = _.get(response, 'data.data', {});
      commit('setState', newState);
    }
  },
};

const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default store;
