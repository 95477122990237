














import Vue from 'vue';
import jmoment from 'moment-jalaali';

export default Vue.extend({
  props: {
    label: String,
    value: String,
    rules: Array,
    hint: String,
    persistentHint: Boolean,
    errorMessages: [Array, String],
    loading: Boolean,
  },
  data() {
    return {
      internalValue: '',
    };
  },
  watch: {
    /**
     * value is watched so that changes to the data-bound variable (which we
     * see through the value prop) are brought inside to our internalValue.
     */
    value: {
      immediate: true,
      handler(newVal: string): void {
        this.internalValue = newVal;
      },
    },

    internalValue(newVal: string): void {
      this.$emit('input', newVal);
    },
  },
  methods: {
    /**
     * formatNumber ensures the case number is properly formatted before it
     * is emitted outside this component.
     *
     * Properly formatted means:
     *
     * - 9 total characters
     * - In 2 groups of 4
     * - Separated by a dash (e.g. ####-####)
     * - The first 4 digits should be a Jalaali calendar year
     * - If no year is provided, assume the current year
     * - The right group of digits is zero-padded on the left.
     */
    formatNumber(): void {
      if (this.internalValue === '') {
        // Early Exit
        // Don't format an empty string. We need to allow the user to leave
        // the field blank in case they want the server to assign the value.
        this.$emit('input', '');
        return;
      }

      // Split the number into parts separated by a dash
      let newCaseNumber = this.internalValue;
      const parts = newCaseNumber.split('-');

      if (parts.length === 1) {
        // The user didn't enter a dash (or a number long enough for the masker
        // to enter one for them). We only have a number part, which we will
        // pad and prepend the Jalaali year to.
        const numberPart = parts[0].padStart(4, '0');
        newCaseNumber = jmoment().jYear() + '-' + numberPart;
      } else if (parts.length > 1) {
        // The input includes at least 1 dash, so we have to presume the user
        // has provided the year. We still need to make sure the second part
        // is properly padded, however.
        parts[1] = parts[1].padStart(4, '0');
        // Rejoin the parts back together.
        newCaseNumber = parts.join('-');
      }

      this.$emit('input', newCaseNumber);
    },
  },
});
