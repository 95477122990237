import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Holiday {
  id: number;
  name: string;
  locationGeographyID: number;
  date: Date;
}

export const state = {
  holidayIDs: [],
  holiday: {} as { [id: number]: Holiday },
  holidaysLoaded: false,
};

export const getters = {
  holidayWithID:
    (state) =>
    (id: number): Holiday | null => {
      return state.holiday[id] || null;
    },
  allHolidays(state): Holiday[] {
    return _.map(state.holidayIDs, (id: number) => state.holiday[id]);
  },
};

export const mutations = {
  /**
   * setHolidaysLoaded sets the tracking variable which identifies
   * whether the holidays have been successfully fetched from the
   * API or not.
   */
  setHolidaysLoaded(state, val: boolean) {
    state.holidaysLoaded = val;
  },
};

export const actions = {
  /**
   * fetchHolidays fetches all dropdown choices for entities in this Vuex
   * store from the API.
   *
   * Most of the time it should be called as fetchHolidays({ force: false }),
   * to avoid re-fetching holidays from the API that have already been
   * loaded.
   */
  async fetchHolidays(
    { commit, state },
    payload = {} as { force: boolean },
  ): Promise<void> {
    // Early return when the holidays are already loaded
    if (!payload.force && state.holidaysLoaded) {
      return Promise.resolve();
    }

    // Fetch all offender-related holidays from the API
    const response = await courtAPI.get('/holidays');

    const holidays = response.data.data;
    const newState = modelsToState('holiday', holidays);
    commit('setState', newState);
    commit('setTarget', {
      target: 'holidayIDs',
      value: _.map(holidays, 'id'),
    });
    commit('setHolidaysLoaded', true);
  },

  // fetchHoliday doesn't have a promise like fetchHolidays.  Should it?  How?
  async fetchHoliday({ commit }, id: number) {
    const response = await courtAPI.get(`/holidays/${id}`);
    const holiday = response.data.data;

    const newState = modelsToState('holiday', holiday);
    commit('setState', newState);
    commit('setTarget', {
      target: 'holiday',
      index: holiday.id,
      value: holiday,
    });
  },
};
