






















import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';
import { Charge } from '@/vuex/court/charge';
import { NotGuiltyChargeDispositionTypeIDs } from '@/vuex/court/charge';

import AggravatorsMitigatorsDetail from '@/components/charges/AggravatorsMitigatorsDetail.vue';
import CrimeTitle from '@/components/crime/CrimeTitle.vue';

export default Vue.extend({
  props: {
    value: Array,
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * charges computes the array of Charge view objects including translated
     * values for all lookup table IDs.
     */
    charges(): Charge[] {
      const charges = (this.value || []) as Charge[];
      return charges.map((c: Charge) => {
        return Object.assign({}, c, {
          participationType: this.nameForIDInCollection(
            c.participationTypeID,
            'crime/participationTypes',
          ),
          severity: this.nameForIDInCollection(
            c.crimeSeverityID,
            'crime/severities',
          ),
          completionStatus: this.nameForIDInCollection(
            c.completionStatusID,
            'crime/completionStatuses',
          ),
          dispositionType:
            c.dispositionTypeID === 0
              ? '' // No need to print 'Unknown' for unresolved charges
              : this.nameForIDInCollection(
                  c.dispositionTypeID,
                  'court/chargeDispositionTypes',
                ),
          deleted: _.includes(
            NotGuiltyChargeDispositionTypeIDs,
            c.dispositionTypeID,
          ),
        });
      });
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a computed property so
     * that it will update with new translations when the locale changes.
     */
    headers(): any[] {
      return [
        { text: i18n.t('crime.title'), value: 'crimeID' },
        { value: 'aggravatorsAndMitigators' },
        {
          text: i18n.t('charge.participationType'),
          value: 'participationType',
        },
        {
          text: i18n.t('charge.completionStatus'),
          value: 'completionStatus',
        },
        {
          text: i18n.t('charge.severity'),
          value: 'severity',
        },
        {
          text: i18n.t('charge.disposition'),
          value: 'dispositionType',
        },
      ];
    },
  },
  components: {
    AggravatorsMitigatorsDetail,
    CrimeTitle,
  },
});
