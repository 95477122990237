import _ from 'lodash';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Escape {
  id: number;
  offenderID: number;
  housingFacilityID: number;
  escapedAt: Date;
  recapturedAt: Date;
  durationInHours: number;
  wasFailedEscape: boolean;
  causedBuildingDamage: boolean;
  causedStaffInjuries: boolean;
  helpedOthersEscape: boolean;
  remarks: string;
}

export const state = {
  escape: {} as { [id: number]: Escape },
  escapeIDsForOffender: {} as { [offenderID: number]: number[] },
};

export const getters = {
  escapeWithID:
    (state) =>
    (escapeID: number): Escape => {
      return state.escape[escapeID];
    },
  escapesForOffender:
    (state) =>
    (offenderID: number): Escape[] => {
      return (state.escapeIDsForOffender[offenderID] || []).map(
        (id: number) => state.escape[id],
      ) as Escape[];
    },
};

export const mutations = {};

export const actions = {
  /**
   * fetchEscapes retrieves the complete list of escapes for a particular offender
   * from the server, and updates the local store with the results.
   */
  async fetchEscapes({ commit }, payload: { offenderID: number }) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/escapes`,
    );
    const escapes = response.data.data;

    const newState = modelsToState('escape', escapes);
    commit('setState', newState);
    commit('setTarget', {
      target: 'escapeIDsForOffender',
      index: payload.offenderID,
      value: _.map(escapes, 'id'),
    });
  },
};
