

























import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      courtroomID: 0,
      courtID: 0,
      name: '',
      isDeleted: false,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('court', ['courtroomWithID']),
  },
  watch: {
    /**
     * isOpen is watched because we need to notice when the dialog's activator
     * has been clicked (which opens the dialog). This is our trigger to fetch
     * a dialog-local copy of the object to present to the user for editing.
     */
    isOpen(nowOpening: boolean): void {
      if (nowOpening && this.id) {
        const myCopy = Object.assign({}, this.courtroomWithID(this.id));
        const courtroom = myCopy;
        this.courtroomID = this.id;
        this.name = courtroom.name;
        this.isDeleted = courtroom.isDeleted;
      }
    },
  },
  methods: {
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post('courts/courtrooms/update', {
          data: {
            id: this.courtroomID,
            name: this.name,
            courtID: this.courtID,
            isDeleted: this.isDeleted,
          },
        });

        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
