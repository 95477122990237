





















































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import router from '@/router';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import {
  changedCriteria,
  criteriaToQueryString,
  routeToCriteria,
} from '@/lib/criteria';

import CrimeSelector from '@/components/crime/CrimeSelector.vue';
import ProfileSearchSelector from '@/components/profile/ProfileSearchSelector.vue';
import PartySearchSelector from '@/components/court/party/PartySearchSelector.vue';

export default Vue.extend({
  props: {
    /**
     * route expects the $route object from the parent component. It is optional
     * and should only be supplied by OffenderList, where we *want* a tight
     * connection between the page URL and the OffenderSearcher criteria object.
     */
    route: Object,

    /**
     * dense triggers a more condensed design for the component, which is useful
     * when it appears in a popup or other compressed space.
     */
    dense: Boolean,
  },

  created() {
    if (this.route) {
      this.routeChanged();
    }

    // Load the initial data...
    this.fetchData();

    // ...then make every future calls to this.fetchData or this.routeChanged
    // be debounced so that they can never be called faster than once
    // every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
    this.routeChanged = _.debounce(this.routeChanged, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,

      initialCriteria: {},
      criteria: {
        activeOnly: false,
        dossierNumber: '',
        awarenessNumber: '',
        departmentID: 0,
        crimeIDs: [] as number[],
        crimeMatchAll: false,
        coalitionForcesAffected: false,
        suspectIDs: [],
        suspectMatchAll: false,
        subjectPropertyTypeID: 0,
        locationID: 1, // Afghanistan
        occurredBetween: '',
        filedBetween: '',
        includesSeverityID: 0,
        maxSeverityID: 0,
        withDocketEventTypeID: 0,
        withoutDocketEventTypeID: 0,
        victimId: 0,
      },

      results: [] as any[],

      page: {
        number: 1,
        size: 10,
        serverCount: 0,
      },
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('crime', ['crimeWithID']),
    ...mapGetters('auth', ['hasPermission']),

    crimeIDs(): number[] {
      return this.criteria.crimeIDs;
    },

    suspectIDs(): number[] {
      return this.criteria.suspectIDs;
    },

    /**
     * translatedResults processes the current page of search results and
     * prepares it for display in the table.
     */
    translatedResults(): any[] {
      return _.map(this.results, (sr) => {
        const crimes = _.chain(sr.crimeIDs)
          .map(this.crimeWithID)
          .map((crime) => {
            if (crime) {
              return crime.title[this.locale];
            }
          })
          .value()
          .join(', ');

        return Object.assign(sr, {
          translatedCrimes: crimes,
        });
      });
    },

    /**
     * queryString computes the query string component of the search API call.
     * It includes pagination parameters controlled by the v-data-table props,
     * but only if they are not the only parameters.
     */
    queryString(): string {
      if (_.isEmpty(this.initialCriteria)) {
        this.initialCriteria = _.cloneDeep(this.criteria);
      }
      const cc = changedCriteria(this.criteria, this.initialCriteria);
      return criteriaToQueryString(cc, this.page);
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a computed property so that it will
     * update with new translations when the locale changes.
     */
    headers(): any[] {
      return [
        {
          text: i18n.t('dossier.dossierNumber'),
          value: 'dossierNumber',
          width: '203px',
        },
        {
          text: i18n.t('dossier.awarenessNumber.singular'),
          value: 'awarenessNumber',
        },
        { text: i18n.t('crimeReport.locationID'), value: 'locationID' },
        { text: i18n.t('crimeReport.officerName'), value: 'officerName' },
        { text: i18n.t('charge.crime.plural'), value: 'translatedCrimes' },
      ];
    },
  },

  watch: {
    /**
     * route is watched so that when the $route of the topmost component
     * changes, we can update the query string. Note that we don't watch
     * $route directly from this component (note the lack of a dollar sign here),
     * because that would potentially trigger at undesirable times.
     *
     * Since we can search for Offenders from other places than the full
     * OffenderList page, we don't always want the route to be tied to this
     * component's behavior.
     */
    route(route: any) {
      this.routeChanged();
    },

    /**
     * queryString is watched so that a new search can be triggered.
     */
    queryString(newVal: string, oldVal: string) {
      this.fetchData();
    },

    /**
     * criteria is watched so that when the search criteria is changed after
     * results have already been returned, we can reset the page number.
     */
    criteria: {
      deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        if (this.page.serverCount > 0 && this.page.number > 1) {
          this.page.number = 1;
        }
      },
    },

    crimeIDs(val: number[]) {
      if (val.length < 2) {
        // When there's a single item, matching ANY is OK.
        // This is relevant because the UI for changing this value gets hidden.
        this.criteria.crimeMatchAll = false;
      }
    },

    suspectIDs(val: number[]) {
      if (val.length < 2) {
        // When there's a single item, matching ANY is OK.
        // This is relevant because the UI for changing this value gets hidden.
        this.criteria.suspectMatchAll = false;
      }
    },
  },

  methods: {
    /**
     * routeChanged is triggered when the page URL changes.
     */
    routeChanged(): void {
      routeToCriteria(this.route, this.criteria, this.page);
    },

    /**
     * fetchData is called when the search button is pressed or when any table
     * pagination setting is changed. It refreshes the locally-held search
     * results with the current page of results from the server.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;

      try {
        const url = `/crime-reports/search?${this.queryString}`;
        const res = await investigationAPI.get(url);
        this.page.serverCount = _.get(res, 'data.meta.totalResults', 0);
        this.results = _.get(res, 'data.data', []);
        this.$emit('queryChanged', this.queryString);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    goToCrimeReport(row) {
      router.push({
        name: 'crimeReportShow',
        params: { id: String(row.id) },
      });
    },
  },

  components: {
    CrimeSelector,
    ProfileSearchSelector,
    PartySearchSelector,
  },
});
