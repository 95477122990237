




















































import Vue from 'vue';

import attachmentAPI from '@/vuex/attachment/attachmentAPI';

export default Vue.extend({
  props: {
    entityType: String,
    entityID: Number,
    location: String,
    value: Boolean,
  },

  created() {
    this.isOpen = this.value;
  },

  data() {
    return {
      error: null as any,
      file: null as File | null,
      isOpen: false,
      label: '',
      percentUploaded: 0.0,
      mimeType: '',
      fileNameOriginal: '',
      uploadStarted: false,
      uploadResponse: null,
    };
  },

  watch: {
    value(nowOpen: boolean): void {
      this.isOpen = nowOpen;
    },
    isOpen(nowOpen: boolean): void {
      this.$emit('input', nowOpen);
    },
  },

  computed: {
    /**
     * icon computes the FontAwesome icon name to indicate what type of file
     * was uploaded.
     */
    icon(): string {
      if (this.mimeType.search('image') === 0) {
        return 'fa-file-image';
      }
      if (this.mimeType.search('application/pdf') === 0) {
        return 'fa-file-pdf';
      }
      if (
        this.mimeType.search('spreadsheet') > 0 ||
        this.mimeType.search('excel') > 0
      ) {
        return 'fa-file-excel';
      }
      if (this.mimeType.search('word') > 0) {
        return 'fa-file-word';
      }
      return 'fa-file';
    },
  },

  methods: {
    /**
     * filePicked is the event handler which triggers when the user selects
     * a file in the file picker UI widget
     */
    filePicked(): void {
      if (this.file) {
        this.label = this.fileNameToLabel(this.file.name);
        this.mimeType = this.file.type;
        this.fileNameOriginal = this.file.name;
        this.$nextTick(() => {
          // Since the label doesn't appear until after the file is picked,
          // we need to wait a beat before focusing the label editing field
          (this.$refs.label as any).focus();
        });
      }
    },

    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the dialog to its default state.
     */
    reset(): void {
      this.fileNameOriginal = '';
      this.error = null;
      this.file = null;
      this.label = '';
      this.percentUploaded = 0.0;
      this.uploadStarted = false;
      this.mimeType = '';
      this.uploadResponse = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to begin the upload to the server.
     */
    async save(): Promise<void> {
      this.error = null;
      this.uploadStarted = true;

      try {
        // Begin the upload request and wait for the response
        const qs = [
          `entityType=${this.entityType}`,
          `entityID=${this.entityID}`,
          `location=${this.location}`,
          `label=${encodeURIComponent(this.label)}`,
          `fileNameOriginal=${encodeURIComponent(this.fileNameOriginal)}`,
          `mimeType=${this.mimeType}`,
        ];
        const response = await attachmentAPI.put(
          '/upload?' + qs.join('&'),
          this.file,
          {
            // Track real upload progress as it happens and update the
            // progress bar display.
            onUploadProgress: (evt) => {
              if (evt.lengthComputable) {
                this.percentUploaded = Math.round(
                  (evt.loaded * 100) / evt.total,
                );
              }
            },
          },
        );

        // Update local state to store the attachment data
        const newState = response.data.data;
        this.$store.commit('attachment/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      } finally {
        this.uploadStarted = false;
      }
    },

    /**
     * fileNameToLabel is a utility function to convert a raw filename into a
     * more user friendly human name.
     */
    fileNameToLabel(fileName: string): string {
      return (fileName || '').split('.')[0].replace(/_/g, ' ').trim();
    },
  },
});
