




















































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';

import { TAll, TUnknown } from '@/lib/translated';
import { Geography, GeographyRegions } from '@/vuex/geography/geography';

export default Vue.extend({
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    countryID: Number,
    provinceID: Number,
    value: Number,
    label: String,
    minSpecificity: {
      type: String,
      validator: (value) => {
        return Object.keys(GeographyRegions).includes(value);
      },
      default: 'none',
    },
    maxSpecificity: {
      type: String,
      validator: (value) => {
        return Object.keys(GeographyRegions).includes(value);
      },
      default: 'village',
    },
    zeroLabel: {
      type: String,
      default: 'unknown',
    },
    omittedGeographies: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: true,
      selectedCountryID: (this.countryID || 0) as number,
      selectedProvinceID: (this.provinceID || 0) as number,
      selectedDistrictID: 0 as number,
      selectedVillageID: 0 as number,
    };
  },
  created() {
    this.valueChanged();
    this.loading = false;
  },
  watch: {
    value(newVal, oldVal) {
      this.valueChanged();
    },
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapState('geography', ['geography']),
    ...mapGetters('geography', [
      'countries',
      'provinces',
      'districts',
      'villages',
      'geographyWithID',
    ]),

    // Use number represenations of geography region type
    // Easier to set up logical conditions
    minSpecificityLevel(): number {
      return GeographyRegions[this.minSpecificity];
    },
    maxSpecificityLevel(): number {
      return GeographyRegions[this.maxSpecificity];
    },

    /*
     * Validation functions specific to each drop down
     */
    countryValidations(): string[] {
      const errorMessages = new Array();

      if (
        this.minSpecificityLevel >= GeographyRegions.country &&
        this.selectedCountryID === 0
      ) {
        errorMessages.push(
          this.$i18n.t('geographySelector.countryRequired').toString(),
        );
      }

      if (
        this.minSpecificityLevel >= GeographyRegions.province &&
        this.selectedCountryID !== 1
      ) {
        errorMessages.push(
          this.$i18n.t('geographySelector.afghanistanRequired').toString(),
        );
      }

      return errorMessages;
    },
    provinceValidations(): string[] {
      const errorMessages = new Array();

      if (
        this.selectedCountryID === 1 &&
        this.selectedProvinceID === 0 &&
        this.minSpecificityLevel >= GeographyRegions.province // don't require unless specified in props
      ) {
        errorMessages.push(
          this.$i18n
            .t('geographySelector.afghanistanProvinceRequirement')
            .toString(),
        );
      }

      return errorMessages;
    },
    districtValidations(): string[] {
      const errorMessages = new Array();

      if (
        this.minSpecificityLevel >= GeographyRegions.district &&
        this.selectedDistrictID === 0
      ) {
        errorMessages.push(
          this.$i18n.t('geographySelector.districtRequired').toString(),
        );
      }

      return errorMessages;
    },
    villageValidations(): string[] {
      const errorMessages = new Array();

      if (
        this.minSpecificityLevel >= GeographyRegions.village &&
        this.selectedVillageID === 0
      ) {
        errorMessages.push(
          this.$i18n.t('geographySelector.villageRequired').toString(),
        );
      }

      return errorMessages;
    },
    // **********************************************************************

    /**
     * selectedGeography returns the complete Geography object for the selected
     * id, or a placeholder unknown object.
     */
    selectedGeography(): Geography {
      const unknownGeo = {
        id: 0,
        name: Object.assign({}, TUnknown),
        type: 'country',
      };
      return this.geography(this.value) || unknownGeo;
    },
    /**
     * countryOptions returns the list of choices for the Country dropdown
     */
    countryOptions(): Geography[] {
      return this.sortedGeoList(this.countries, 'country');
    },
    /**
     * provinceOptions returns the list of choices for the Province dropdown
     */
    provinceOptions(): Geography[] {
      return this.sortedGeoList(this.provinces, 'province');
    },
    /**
     * districtOptions returns the list of choices for the District dropdown
     */
    districtOptions(): Geography[] {
      return this.sortedGeoList(
        this.districts(this.selectedProvinceID),
        'district',
      );
    },
    /**
     * villageOptions returns the list of choices for the Village dropdown
     */
    villageOptions(): Geography[] {
      return this.sortedGeoList(
        this.villages(this.selectedDistrictID),
        'village',
      );
    },
    showCountry(): boolean {
      // If the countryID **prop** was provided, we're hard-coding that choice
      // for the country and hiding the dropdown.
      if (this.countryID > 0) {
        return false;
      }
      return true;
    },
    showProvince(): boolean {
      // If the proviceID **prop** was provided, we're hard-coding that choice
      // for the province and hiding the dropdown.
      if (this.provinceID > 0) {
        return false;
      }
      // Otherwise, provinces only exist for Afghanistan (CountryID === 1),
      // so we only show if Afghanistan is selected for the country and
      // the specificity level includes this depth
      return (
        this.selectedCountryID === 1 &&
        !!this.maxSpecificityLevel &&
        this.maxSpecificityLevel >= GeographyRegions.province
      );
    },
    showDistrict(): boolean {
      return (
        this.selectedProvinceID > 0 &&
        this.maxSpecificityLevel >= GeographyRegions.district
      );
    },
    showVillage(): boolean {
      return (
        this.selectedDistrictID > 0 &&
        this.maxSpecificityLevel >= GeographyRegions.village
      );
    },
    /**
     * numberOfVisibleDropdowns returns a number between 1 and 4, depeneding
     * on how many dropdown controls are visible
     */
    numberOfVisibleDropdowns(): number {
      let num = 0;
      if (this.showCountry) {
        num++;
      }
      if (this.showProvince) {
        num++;
      }
      if (this.showDistrict) {
        num++;
      }
      if (this.showVillage) {
        num++;
      }
      return num;
    },
  },
  methods: {
    ...mapActions('geography', ['fetchGeography', 'fetchVillagesForDistrict']),
    /**
     * countryChanged is triggered *only* by a manual change to the country
     * dropdown. It ensures all lower-level dropdowns get properly reset.
     */
    countryChanged(): void {
      this.selectedProvinceID = 0;
      this.selectedDistrictID = 0;
      this.selectedVillageID = 0;
      this.recalculateValue();
    },
    /**
     * provinceChanged is triggered *only* by a manual change to the province
     * dropdown. It ensures all lower-level dropdowns get properly reset.
     */
    provinceChanged(): void {
      this.selectedDistrictID = 0;
      this.selectedVillageID = 0;
      this.recalculateValue();
    },
    /**
     * districtChanged is triggered *only* by a manual change to the district
     * dropdown. It ensures the village dropdown gets properly reset.
     */
    districtChanged(): void {
      this.selectedVillageID = 0;
      this.recalculateValue();
    },
    /**
     * villageChanged is triggered *only* by a manual change to the village
     * dropdown.
     */
    villageChanged(): void {
      this.recalculateValue();
    },
    /**
     * valueChange is the responder for an *external* change to the
     * bound value for this component. It ensures that the referenced
     * geography is loaded (if it exists), and sets each of the dropdowns
     * to their correct values.
     *
     * This handler is also triggered indirectly when an internal change
     * causes the data-bound value to change.
     */
    async valueChanged(): Promise<void> {
      const self = this;
      self.loading = true;

      if (!this.value) {
        /**
         * Whenever the value is unset, but we have props forcing
         * a dropdown to a certain value, then 0 is impossible. To fix the
         * issue, we override the value by emitting the prop value. This will
         * cause Vue data binding to loop back to this function with the new
         * value, causing the other half of valueChanged() to be executed.
         */
        if (this.provinceID > 0) {
          this.$emit('input', this.provinceID);
        }
        if (this.countryID > 0) {
          this.$emit('input', this.countryID);
        }
        return;
      }

      await this.fetchGeography(this.value);
      const geo = this.geographyWithID(this.value);

      /**
       * Because villages are not pre-loaded, we need to fetch all 'sibling'
       * villages when the value is set to a village, and all 'child'
       * villages when the value is set to a district.
       */
      let districtID: number | null = null;
      if (geo.type === 'village') {
        districtID = geo.parentID;
      } else if (geo.type === 'district') {
        districtID = geo.id;
      }
      if (districtID) {
        await this.fetchVillageOptions(districtID);
        self.updateSelectors();
        this.loading = false;
      } else {
        self.updateSelectors();
        this.loading = false;
      }
    },
    /**
     * updateSelectors climbs the hierarchy of the currently-selected geography
     * ID and ensures each dropdown selector is set to the correct value. This
     * function depends on all relevant Geography objects being loaded in the
     * Vuex store, so it should only be called by functions which have already
     * loaded the needed geography data.
     */
    updateSelectors(): void {
      // Build an array of the geographyIDs based on parentID
      // relationships.
      const ids = [this.value] as number[];
      let geo = this.geography[this.value];
      while (geo.parentID) {
        ids.unshift(geo.parentID);
        geo = this.geography[geo.parentID];
      }

      // Country
      if (ids.length > 0) {
        this.selectedCountryID = ids[0];
      } else {
        this.selectedCountryID = 0;
      }

      // Province
      if (ids.length > 1) {
        this.selectedProvinceID = ids[1];
      } else {
        this.selectedProvinceID = 0;
      }

      // District
      if (ids.length > 2) {
        this.selectedDistrictID = ids[2];
      } else {
        this.selectedDistrictID = 0;
      }

      // Village
      if (ids.length > 3) {
        this.selectedVillageID = ids[3];
      } else {
        this.selectedVillageID = 0;
      }
    },
    /**
     * recalculateValue is the handler for an *internal* change to the bound
     * value. It examines each of the 4 dropdowns and figures out which one's
     * ID represents the value set by the user. It then $emits the 'input'
     * method to update this component's parent with the newly-set value.
     */
    recalculateValue(): void {
      const value = _.reject(
        [
          this.selectedCountryID,
          this.selectedProvinceID,
          this.selectedDistrictID,
          this.selectedVillageID,
        ],
        (v) => v < 1,
      ).pop(); // Return the last positive integer
      if (value && value > 0) {
        this.$emit('input', value);
      } else {
        this.$emit('input', 0);
      }
    },
    /**
     * fetchVillageOptions triggers a Vuex load of the child Village geographies
     * for the currently-selected District.
     *
     * @param districtID the geography ID of the district whose villages we need
     */
    fetchVillageOptions(districtID: number): Promise<void> {
      if (!districtID) {
        districtID = this.selectedDistrictID;
      }
      const payload = { districtID, force: false };
      return this.fetchVillagesForDistrict(payload);
    },
    /**
     * sortedGeoList is a helper for this component's getters. It sorts
     * the supplied geoList by the currently-active locale, and prepends
     * an "Unknown" choice with a 0 ID as the first entry in the list.
     *
     * It's required that each dropdown have a 0 option available to
     * indicate that only the higher-level value is known
     * (i.e. Herat Province => Unknown District).
     */
    sortedGeoList(geoList: Geography[], type: string): Geography[] {
      const choices = _.sortBy(geoList, (g) => g.name[this.locale]);
      const unknown = {
        id: 0,
        code: '',
        iso3166Code: '',
        name:
          this.zeroLabel === 'all'
            ? Object.assign({}, TAll)
            : Object.assign({}, TUnknown),
        type,
        path: '0',
      };
      choices.unshift(unknown);
      return choices.filter((c: Geography) => {
        return !_.includes(this.omittedGeographies, c.id);
      });
    },
  },
});
