









import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';

export default Vue.extend({
  props: {
    /**
     * data expects an array of objects with some combination of the following
     * columns
     *
     * value  (ALWAYS REQUIRED) The count or level of color intensity to be
     *        used for this province. Can use a different column name if prop
     *        valueCol is provided (e.g. valueCol="crimeReportCount")
     *
     * provinceID  (REQUIRED IF iso3166Code is missing)  The ID of a Province
     *             in the Geographies table. Can use a different column name
     *             if prop provinceIDCol is provided.
     *
     * iso3166Code (REQUIRED IF provinceID is missing)  The ISO 3166-2:AF code
     *             for the province to be highlighted (e.g AF-KAB for Kabul).
     *             https://en.wikipedia.org/wiki/ISO_3166-2:AF
     *
     * label       (OPTIONAL) Will be used instead of iso3166Code for the tooltip
     *             label for this province. The translated geography name is
     *             used for this if provinceID is provided.
     */
    data: Array,

    /**
     * metricName customizes the label attached to the value. If not specified
     * it will be "Count", so the tooltip would say: "Count: 5"
     */
    metricName: {
      type: String,
      default: () => i18n.t('count'),
    },

    /**
     * colors expects an array of string hex color codes. The first value will
     * be used for the low intensity color, and the second value will be used
     * for the
     *
     */
    colors: Array,

    /**
     * provinceIDCol optionally allows the user to use a different column name
     * to specify the provinceID in data. Examples:
     *
     *  provinceIDCol="geographyID"
     *  provinceIDCol="departmentProvinceID"
     */
    provinceIDCol: {
      type: String,
      default: 'provinceID',
    },

    /**
     * valueCol optionally allows the user to use a different column name
     * to specify the value in the data. Examples:
     *
     *  valueCol="crimeReportCount"
     *  valueCol="intensity"
     */
    valueCol: {
      type: String,
      default: 'value',
    },
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('geography', ['geographyWithID']),

    /**
     * chartData reformats the provided data prop into the "Regions Mode Format"
     * for Google Charts GeoChart. Documentation of the format is here:
     *
     * https://developers.google.com/chart/interactive/docs/gallery/geochart#regions-mode-format
     *
     * This property transforms the provided data prop into that shape.
     */
    chartData(): any[] {
      const rows: any[] = [];
      rows.push(['Region', this.metricName]);
      _.each(this.data || [], (sourceRow: any) => {
        let code = _.get(sourceRow, 'iso3166Code', '');
        let label = _.get(sourceRow, 'label', '');
        const value = _.get(sourceRow, this.valueCol, 0);
        const provinceID = _.get(sourceRow, this.provinceIDCol, 0);
        if (provinceID) {
          const geo = this.geographyWithID(sourceRow[this.provinceIDCol]);
          if (geo) {
            label = geo.name[this.locale];
            if (!code) {
              code = geo.iso3166Code;
            }
          }
        }

        if (!!code) {
          if (!!label) {
            rows.push([{ v: code, f: label }, value]);
          } else {
            rows.push([code, value]);
          }
        } else {
          console.error('ProvinceMap got invalid row', sourceRow);
        }
      });
      return rows;
    },

    /**
     * chartOptions builds the object which will be provided as the options
     * argument in the draw(data, options) function in the Google Charts
     * documentation. The available options are documented here:
     *
     * https://developers.google.com/chart/interactive/docs/gallery/geochart#configuration-options
     */
    chartOptions(): object {
      const opts = {
        region: 'AF',
        resolution: 'provinces',
        colorAxis: {
          colors: this.colors,
        },
      };
      if (this.colors && this.colors.length) {
        opts.colorAxis = {
          colors: this.colors,
        };
      }
      return opts;
    },

    /**
     * loadtimeSettings builds the object which will be provided as the options
     * to the startup routine for Google Charts (e.g.e google.charts.load()).
     * The complete list of available options are here:
     *
     * https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
     */
    loadtimeSettings(): object {
      return {
        packages: ['geochart'],
        language: this.locale,
        /**
         * Not supplying a mapsApiKey could theoretically result in Google
         * throttling service requests depending on load. So far so good, and
         * not supplying a key means we don't have to maintain it and ensure
         * somebody's Google account is in good standing. If a key is needed
         * get it here:
         * https://developers.google.com/maps/documentation/javascript/get-api-key
         */
        // mapsApiKey: '',
      };
    },
  },
});
