


































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Exam } from '@/vuex/offender/medical';
import { Offender } from '@/vuex/offender/offender';

import AddExamDialog from '@/components/offender/medical/AddExamDialog.vue';

export default Vue.extend({
  props: {
    id: Number,
    contentOnly: Boolean,
  },
  created(): void {
    if (this.id) {
      this.fetchData();
    }
  },
  data(): any {
    return {
      adding: false,
    };
  },
  watch: {
    id(val: number): void {
      if (this.id) {
        this.fetchData();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('housing', ['facilityName']),
    ...mapGetters('offender', [
      'examsForOffender',
      'offenderIsReleased',
      'offenderWithID',
    ]),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('offender', [
      'mentalDisorders',
      'infectiousDiseases',
      'disabilities',
      'drugAddictions',
      'motherAndChildConcerns',
    ]),

    offender(): Offender {
      return this.offenderWithID(this.id);
    },

    exams(): Exam[] {
      return this.examsForOffender(this.id);
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.id);
    },

    /**
     * canAdd returns true if its acceptable to add a new Sentence Adjustment
     */
    canAdd(): boolean {
      return (
        !this.isReleased &&
        this.hasPermission(
          'offender.medical.createExam',
          'facility',
          this.offender.housingFacilityID,
        )
      );
    },
  },
  methods: {
    fetchData(): void {
      this.$store.dispatch('offender/fetchExams', {
        offenderID: this.id,
      });
    },
    findDisorders(val: number[]): string[] {
      return _.map(val, (id) =>
        this.nameForIDInCollection(id, this.mentalDisorders),
      );
    },
    findDiseases(val: number[]): string[] {
      return _.map(val, (id) =>
        this.nameForIDInCollection(id, this.infectiousDiseases),
      );
    },
    findDisabilities(val: number[]): string[] {
      return _.map(val, (id) =>
        this.nameForIDInCollection(id, this.disabilities),
      );
    },
    findDrugs(val: number[]): string[] {
      return _.map(val, (id) =>
        this.nameForIDInCollection(id, this.drugAddictions),
      );
    },
    findMotherChildConcern(val: number[]): string[] {
      return _.map(val, (id) =>
        this.nameForIDInCollection(id, this.motherAndChildConcerns),
      );
    },
  },
  components: {
    AddExamDialog,
  },
});
