














































































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { HousingFacility } from '@/vuex/housing/housing';
import { TranslatedString } from '@/lib/translated';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';

export default Vue.extend({
  props: {
    value: Object,
  },

  data() {
    return {
      typeOptions: ['facility', 'building', 'wing', 'block', 'floor', 'cell'],
      parentID: 0,
    };
  },

  computed: {
    ...mapState('language', ['locales', 'locale']),
    ...mapGetters('language', ['languages']),

    /**
     * facility computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    facility(): HousingFacility {
      return this.value
        ? this.value
        : {
            id: 0,
            type: 'facility',
            name: {} as TranslatedString,
            geographyID: 1,
            canHoldJuveniles: false,
            canHoldAdults: true,
            canHoldDetainees: true,
            canHoldPrisoners: false,
            canHoldFemals: false,
            canHoldMales: true,
            acceptsTransfers: true,
            capacity: 0,
            parentID: null as number | null,
            hidden: false,
            legacyIDs: [] as number[],
          };
    },
  },

  watch: {
    parentID(idVal: number) {
      if (idVal === undefined) {
        this.facility.parentID = 0;
        this.facility.acceptsTransfers = true;
      } else {
        this.facility.parentID = idVal;
      }
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      if (modifier === 'number') {
        let quant = parseInt(value, 10);
        // Force non-numeric input to a number so that the form
        // ignores it (this will show '0' if you type 'a').
        if (Number.isNaN(quant)) {
          quant = 0;
        }
        modifiedValue = quant;
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.facility), (v) => set(v, key, modifiedValue)),
      );
    },
  },

  components: {
    FacilitySelector,
  },
});
