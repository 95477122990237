



































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { Crime } from '@/vuex/crime/crime';

import CrimeSearcher from '@/components/crime/CrimeSearcher.vue';
import CrimeTitle from '@/components/crime/CrimeTitle.vue';

export default Vue.extend({
  props: {
    label: String,
    value: {
      type: Array as () => number[],
      default: () => [] as number[],
    },
  },

  data() {
    return {
      isOpen: false,

      pendingCrimeIDAdditions: [] as number[],
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('crime', ['crimeWithID']),
    ...mapGetters('corpus', ['corpusWithID']),

    crimes(): Crime[] {
      return _.map(this.value, (id: number) => {
        const crime = this.crimeWithID(id);
        return Object.assign({}, crime, {
          corpus: this.corpusWithID(crime.corpusID),
        });
      });
    },

    headers(): any[] {
      const headers: any[] = [
        {
          text: i18n.t('crime.title'),
          value: 'title.' + this.locale,
          witdh: '50%',
        },
        {
          text: i18n.t('corpus.singular'),
          value: `corpus.title.${this.locale}`,
        },
        {
          text: i18n.t('crime.article'),
          value: 'article',
          align: 'right',
        },
        {
          text: i18n.t('command.delete'),
          value: 'actions',
          align: 'center',
          width: '40px',
          sortable: false,
        },
      ];

      return headers;
    },
  },

  watch: {
    isOpen(nowOpen: boolean) {
      if (nowOpen) {
        this.pendingCrimeIDAdditions = [];
        this.$nextTick(() => {
          const searcher = this.$refs.crimeSearcher as any;
          searcher.reset();
        });
      }
    },
  },

  methods: {
    addPending(): void {
      this.$emit(
        'input',
        _.uniq([...this.value, ...this.pendingCrimeIDAdditions]),
      );
    },

    deleteCrimeWithID(crimeID: number): void {
      const newIDs = _.chain(this.crimes)
        .reject({ id: crimeID })
        .map('id')
        .value();
      this.$emit('input', newIDs);
    },
  },

  components: {
    CrimeSearcher,
    CrimeTitle,
  },
});
