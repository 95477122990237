














































import Vue from 'vue';
import moment from 'moment';

import offenderAPI from '@/vuex/offender/offenderAPI';

import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: ['value', 'offenderID', 'escapeID'],
  data() {
    return {
      currentEscapeID: this.escapeID,
      recapturedDate: moment().format(),
      remarks: '',
      recaptureDialog: false,
      housingFacilityID: 0,
      error: null as any,
      isOpen: false,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.reset();
      }
    },
  },
  methods: {
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('escapes/recapture', {
          data: {
            escapeID: this.escapeID,
            recaptureDate: this.recapturedDate,
            remarks: this.remarks,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.escape)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'escapeIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.escape),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
    reset() {
      this.currentEscapeID = 0;
      this.recapturedDate = moment().format();
      this.housingFacilityID = 0;
      this.remarks = '';
    },
  },
  components: {
    OffenderNameAndPIN,
  },
});
