







































import Vue from 'vue';
import i18n from '@/i18n';

const HoursPerYear = 8760;
const HoursPerMonth = 730;
const HoursPerDay = 24;

export default Vue.extend({
  props: {
    value: Number,
    label: String,
    readonly: Boolean,
    required: Boolean,
  },
  created() {
    this.valueChangedFromOutside(this.value);
  },
  data() {
    return {
      years: null as number | null,
      months: null as number | null,
      days: null as number | null,
    };
  },
  computed: {
    /**
     * readOnlyValue computes the string which is displayed in a span when this
     * component is rendered in readonly mode. It includes year, month, and day
     * labels translated as appropriate (e.g. 3 Years, 2 Months).
     */
    readOnlyValue(): string {
      const parts = [] as string[];
      if (this.years && this.years > 0) {
        const yearLabel = i18n.t('time.years');
        parts.push(`${this.years} ${yearLabel}`);
      }
      if (this.months && this.months > 0) {
        const monthLabel = i18n.t('time.months');
        parts.push(`${this.months} ${monthLabel}`);
      }
      if (parts.length === 0 || (this.days && this.days > 0)) {
        const dayLabel = i18n.t('time.days');
        parts.push(`${this.days} ${dayLabel}`);
      }
      return parts.join(', ');
    },

    /**
     * computedDurationInHours builds the value which would be emitted at this
     * moment (if it were to be emitted). It simply converts years + months + days
     * into hours.
     */
    computedDurationInHours(): number {
      let newHours = 0;
      if (this.days) {
        newHours += this.days * HoursPerDay;
      }
      if (this.months) {
        newHours += this.months * HoursPerMonth;
      }
      if (this.years) {
        newHours += this.years * HoursPerYear;
      }
      return newHours;
    },

    /**
     * computedRules builds the array of validation functions which will be
     * applied to the years, months, and days text fields.
     */
    computedRules(): any[] {
      if (!this.required) {
        // No validation at all if we don't have a required prop
        return [];
      }
      if (this.computedDurationInHours > 0) {
        // No validation at all if we have non-zero duration
        return [];
      }
      return [(v: any) => v > 0 || i18n.t('error.required')];
    },
  },
  watch: {
    value(newValue: number) {
      this.valueChangedFromOutside(newValue);
    },
  },
  methods: {
    /**
     * valueChangedFromOutside translates an externally data-bound value (in
     * hours) into the local years, months, and days values.
     */
    valueChangedFromOutside(newValue: number): void {
      if (newValue) {
        let remainingHours = 0;
        this.years = Math.floor((this.value || 0) / HoursPerYear);
        remainingHours = (this.value || 0) - this.years * HoursPerYear;
        this.months = Math.floor(remainingHours / HoursPerMonth);
        remainingHours = remainingHours - this.months * HoursPerMonth;
        this.days = Math.floor(remainingHours / HoursPerDay);
      } else {
        this.years = 0;
        this.months = 0;
        this.days = 0;
      }
    },

    /**
     * partsChanged is the handler for one of the years, months or days fields
     * being changed internally.
     */
    partsChanged(): void {
      this.$emit('input', this.computedDurationInHours);
    },

    /**
     * numbersOnly is the keypress handler for the years, months and days fields.
     * It ignores all non-numeric input.
     */
    numbersOnly(evt: any): boolean {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 && // Below 31 are control codes like tab
        (charCode < 48 || charCode > 57) && // 48-57 are the digis 0-9
        charCode !== 46 // 46 is the decimal point
      ) {
        evt.preventDefault();
        return false;
      } else {
        return true;
      }
    },
  },
});
