



































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString, routeToCriteria } from '@/lib/criteria';
import { Department } from '@/vuex/investigation/department';

import FinalizedByDate from '@/components/investigation/dashboard/FinalizedByDate.vue';
import DisposedChargesBySeverity from '@/components/investigation/dashboard/DisposedChargesBySeverity.vue';
import DossierDocketEventCounts from '@/components/investigation/dashboard/DossierDocketEventCounts.vue';
import DossiersByCivilCaseTopic from '@/components/investigation/dashboard/DossiersByCivilCaseTopic.vue';
import DossiersByDepartmentLocationTable from '@/components/investigation/DossiersByDepartmentLocationTable.vue';
import DossiersByDepartmentTable from '@/components/investigation/DossiersBySubdepartmentTable.vue';
import DossiersByLawsuitType from '@/components/investigation/dashboard/DossiersByLawsuitType.vue';
import PendingChargesBySeverity from '@/components/investigation/dashboard/PendingChargesBySeverity.vue';

export default Vue.extend({
  data() {
    return {
      loading: false,
      error: null as any,

      menuExpanded: false,

      criteria: {
        activeOnly: true,
        lastDepartmentLocationID: 1,
        lastDepartmentID: 0,
      },

      stats: {
        dossierCount: 0,
        averageAge: '',
      },

      departmentSummaries: [] as any[],
    };
  },
  created(): void {
    this.fetchData();
  },
  computed: {
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('investigation', ['fullDepartmentName']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['dossierStatuses']),
    ...mapGetters('investigation', ['departmentWithID']),

    /**
     * department holds a reference to the "focused" Department for which stats
     * are being displayed. It is provided by a URL parameter.
     */
    department(): Department {
      return this.departmentWithID(this.criteria.lastDepartmentID);
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        routeToCriteria(this.$route, this.criteria);
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      try {
        const queryString = criteriaToQueryString(this.criteria);
        const url = `/dossiers/stats?${queryString}`;
        const response = await investigationAPI.get(url);
        const newStats = _.get(response, 'data.data', {});
        this.stats = Object.assign(this.stats, newStats);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    FinalizedByDate,
    DisposedChargesBySeverity,
    DossierDocketEventCounts,
    DossiersByCivilCaseTopic,
    DossiersByDepartmentLocationTable,
    DossiersByDepartmentTable,
    DossiersByLawsuitType,
    PendingChargesBySeverity,
  },
});
