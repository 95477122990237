














































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';

import CourtroomSelector from '@/components/court/courtroom/CourtroomSelector.vue';

import { Case } from '@/vuex/court/case';

export default Vue.extend({
  props: {
    value: Boolean,
    caseID: Number,
  },

  data() {
    return {
      isOpen: false,
      error: null as any,

      filedAt: moment().format(),
      hearingDate: '',
      hearingTypeID: 0,
      notes: '',
      time: '', // this and below for the time picker
      courtroomID: 0,
    };
  },

  computed: {
    ...mapGetters('court', ['caseWithID']),

    kase(): Case {
      return this.caseWithID(this.caseID);
    },
  },

  watch: {
    /**
     * value is watched so that the externally-bound v-model can be transferred
     * to its internal equivalent (isOpen).
     */
    value(val) {
      this.isOpen = val;
    },

    /**
     * isOpen is watched so that changes in the internal isOpen value can be
     * broadcast to the external v-model which to which this component is
     * bound.
     */
    isOpen(val) {
      this.$emit('input', val);
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.hearingDate = '';
      this.hearingTypeID = 0;
      this.time = '';
      this.filedAt = moment().format();
      this.notes = '';
      this.courtroomID = 0;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post('cases/create-hearing', {
          data: {
            caseID: this.caseID,
            hearingDate: this.hearingDate,
            typeID: this.hearingTypeID,
            time: this.time,
            notes: this.notes,
            filedAt: this.filedAt,
            courtroomID: this.courtroomID,
          },
        });
        // Apply the received state to the Vuex store
        const newState = response.data.data;
        const newID = Object.keys(newState.hearing)[0];
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/prependToTarget', {
          target: 'hearingIDsForCase',
          index: this.caseID,
          value: newID,
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        // Add the event to the docket
        this.$store.commit('court/addDocketEntry', response);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    CourtroomSelector,
  },
});
