var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-10"},[_c('v-row',{staticClass:"mb-6",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"8","sm":"8"}},[_c('div',{class:{
          'mt-4': !_vm.criteria.facilityID,
          'text-h5': !!_vm.criteria.facilityID,
          'text-h3': !_vm.criteria.facilityID,
        }},[_c('GeographyBreadcrumb',{attrs:{"id":_vm.criteria.facilityLocationID,"locationParamName":"facilityLocationID","to":_vm.hasAllFacilities
              ? { name: 'offenderDashboard', query: Object.assign({}, _vm.criteria) }
              : {}}})],1),(_vm.criteria.facilityID)?_c('div',{staticClass:"text-h4"},[_c('HousingFacilityBreadcrumb',{attrs:{"id":_vm.criteria.facilityID,"to":{ name: 'offenderDashboard', query: Object.assign({}, _vm.criteria) }}})],1):_vm._e()]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('BooleanSelector',{staticClass:"mt-4",attrs:{"label":_vm.$t('person.age'),"unknown-label":_vm.$t('adult.plural') + ' + ' + _vm.$t('juvenile.plural'),"true-label":_vm.$t('juvenile.plural'),"false-label":_vm.$t('adult.plural')},model:{value:(_vm.criteria.isJuvenile),callback:function ($$v) {_vm.$set(_vm.criteria, "isJuvenile", $$v)},expression:"criteria.isJuvenile"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"2"}},[_c('v-img',{staticClass:"float-right",attrs:{"max-width":"100%","width":"150","contain":"","src":"/opa-logo.png"}})],1)],1),(_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)],1):_vm._e(),(!_vm.loading)?_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-h4"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria),
              }}},[_c('NumFmt',{attrs:{"value":_vm.totalPopulation}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.currentPopulation'))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-h4"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria, {isDetainee: false}),
              }}},[_c('NumFmt',{attrs:{"value":_vm.prisonerCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.prisonersHeader))])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-h4"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria, {isDetainee: true}),
              }}},[_c('NumFmt',{attrs:{"value":_vm.detaineeCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t('offender.detainee.plural'))+" ")])]),(this.elderlyCount > 0)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-h4"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria, {elderlyOnly: true}),
              }}},[_c('NumFmt',{attrs:{"value":_vm.elderlyCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t('elderlyOffender.plural'))+" "),_c('br'),_vm._v(" ( "+_vm._s(_vm.$t('person.age'))+" "+_vm._s(_vm.elderlyAge)+"+ ) ")])]):_vm._e()],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-h4"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria, {genderID: 1}),
              }}},[_c('NumFmt',{attrs:{"value":_vm.maleCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.nameForIDInCollection(1, 'profile/genders'))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-h4"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria, {genderID: 2}),
              }}},[_c('NumFmt',{attrs:{"value":_vm.femaleCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.nameForIDInCollection(2, 'profile/genders'))+" ")])]),(_vm.adultCount > 0)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-h4"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria, {isJuvenile: false}),
              }}},[_c('NumFmt',{attrs:{"value":_vm.adultCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t('adult.plural'))+" ")])]):_vm._e(),(_vm.juvenileCount > 0)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-h4"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria, {isJuvenile: true}),
              }}},[_c('NumFmt',{attrs:{"value":_vm.juvenileCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t('juvenile.plural'))+" ")])]):_vm._e()],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',{attrs:{"dense":"","align-stretch":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-card',{staticClass:"fill-height",attrs:{"outlined":"","color":"error"}},[_c('v-card-text',{staticClass:"white--text text-right"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',{staticClass:"text-h4 font-weight-bold"},[_c('router-link',{staticClass:"white--text",attrs:{"to":{
                        name: 'offenderSearch',
                        query: Object.assign({}, _vm.criteria, {missingDossier: true}),
                      }}},[_c('NumFmt',{attrs:{"value":_vm.missingDossierCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t('offender.missingDossier'))+" ")])]),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',{staticClass:"text-h4 font-weight-bold"},[_c('router-link',{staticClass:"white--text",attrs:{"to":{
                        name: 'offenderSearch',
                        query: Object.assign({}, _vm.criteria, {pastDue: true}),
                      }}},[_c('NumFmt',{attrs:{"value":_vm.pastDueCount}})],1)],1),_c('div',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.pastDue'))+" ")])])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-card',{staticClass:"fill-height",attrs:{"outlined":"","color":"warning"}},[_c('v-card-text',{staticClass:"white--text text-right"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',{staticClass:"text-h4 font-weight-bold"},[_c('router-link',{staticClass:"white--text",attrs:{"to":{
                        name: 'offenderSearch',
                        query: Object.assign({}, _vm.criteria,
                          {nearingRelease: true}),
                      }}},[_c('NumFmt',{attrs:{"value":_vm.nearingReleaseCount}})],1)],1),_c('div',{staticClass:"text-overline"})]),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',{staticClass:"text-h4 font-weight-bold"},[_c('router-link',{staticClass:"white--text",attrs:{"to":{
                        name: 'offenderSearch',
                        query: Object.assign({}, _vm.criteria,
                          {imminentRelease: true}),
                      }}},[_c('NumFmt',{attrs:{"value":_vm.imminentReleaseCount}})],1)],1),_c('div',{staticClass:"text-overline"})])],1),_c('v-row',{staticClass:"text-right",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-overline",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('offender.nearingRelease'))+" ")])]),_c('v-col',{staticClass:"text-overline",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',[_vm._v(_vm._s(_vm.$t('offender.imminentRelease')))])])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('OffendersBySecurityLevel',{attrs:{"criteria":_vm.criteria}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('OffendersByCustodyStatus',{attrs:{"criteria":_vm.criteria}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('OffendersByCriminalGroup',{attrs:{"criteria":_vm.criteria}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('OffendersByNationality',{attrs:{"criteria":_vm.criteria}})],1),(_vm.incomingTransfers.length)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('transferIn.plural'))+" ")]),_c('v-card-text',_vm._l((_vm.incomingTransfers),function(s){return _c('v-row',{key:s.status,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.$t('transfer.statusOptions.' + s.status))+" ")]),_c('v-col',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                      name: 'housingFacilityTransfers',
                      params: { id: _vm.criteria.facilityID },
                    }}},[_c('NumFmt',{attrs:{"value":s.count}})],1)],1)],1)}),1)],1)],1):_vm._e(),(_vm.outgoingTransfers.length)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('transferOut.plural'))+" ")]),_c('v-card-text',_vm._l((_vm.outgoingTransfers),function(s){return _c('v-row',{key:s.status,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.$t('transfer.statusOptions.' + s.status))+" ")]),_c('v-col',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                      name: 'housingFacilityTransfers',
                      params: { id: _vm.criteria.facilityID },
                    }}},[_c('NumFmt',{attrs:{"value":s.count}})],1)],1)],1)}),1)],1)],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},[_c('OffendersByLocationTable',{attrs:{"criteria":_vm.criteria}}),_c('OffendersByFacilityTable',{attrs:{"criteria":_vm.criteria}})],1)],1),(_vm.criteria.facilityID)?_c('OffenderRosterTable',{attrs:{"criteria":_vm.criteria}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }