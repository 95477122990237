























import Vue from 'vue';
import courtAPI from '@/vuex/court/courtAPI';

export default Vue.extend({
  props: ['courtID'],
  data() {
    return {
      isOpen: false,
      name: '',
      error: null as any,
    };
  },
  watch: {
    isOpen(isDialogOpening: boolean) {
      if (isDialogOpening) {
        this.reset();
      }
    },
  },
  methods: {
    reset(): void {
      this.name = '';
    },
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post('courts/courtrooms/create', {
          data: {
            name: this.name,
            courtID: this.courtID,
          },
        });
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/appendToTarget', {
          target: 'courtroomIDsForCourt',
          index: this.courtID,
          value: Object.keys(newState.courtroom),
        });
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
