



















import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('court', ['judgeName']),
    ...mapState('court', ['judgeRemovalReasons']),
    ...mapGetters('language', ['nameForIDInCollection']),
    payload(): any {
      return this.entry.payload;
    },
  },
});
