

































































import _ from 'lodash';
import Vue from 'vue';
import { cloneDeep, tap, set } from 'lodash';
import { mapGetters } from 'vuex';

import { RoleEntityType, UserRole } from '@/vuex/user/user';

import CourtSelector from '@/components/court/court/CourtSelector.vue';
import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import RoleEntityTypeSelector from '@/components/user/RoleEntityTypeSelector.vue';
import RoleSelector from '@/components/user/RoleSelector.vue';
import i18n from '@/i18n';

export default Vue.extend({
  props: {
    value: Object,
  },

  computed: {
    ...mapGetters('language', ['valueForLocale']),

    /**
     * userRole computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    userRole(): UserRole {
      return (
        this.value || {
          id: 0,
          userID: 0,
          roleID: 0,
          entityType: RoleEntityType.Global,
          hasAllEntities: false,
          entityID: 0,
        }
      );
    },

    /**
     * entityType computes the entityType that's currently selected in the form.
     */
    entityType(): string {
      return this.userRole.entityType;
    },

    /**
     * allLabel computes the words used on the "All" checkbox. The label is'
     * dynamic depending on which entityType is selected. This will return
     * something like "All Courts" or "All Departments".
     */
    allLabel(): string {
      const all = i18n.t('all').toString();
      switch (this.entityType) {
        case RoleEntityType.Court:
          return all + ' ' + i18n.t('court.plural').toString();
        case RoleEntityType.Department:
          return all + ' ' + i18n.t('department.plural').toString();
        case RoleEntityType.HousingFacility:
          return all + ' ' + i18n.t('housingFacility.plural').toString();
      }
      return '';
    },

    isGlobal(): boolean {
      return this.entityType === RoleEntityType.Global;
    },

    isCourt(): boolean {
      return this.entityType === RoleEntityType.Court;
    },

    isDepartment(): boolean {
      return this.entityType === RoleEntityType.Department;
    },

    isHousingFacility(): boolean {
      return this.entityType === RoleEntityType.HousingFacility;
    },
  },

  methods: {
    /**
     * update is the target of the @input and @change handlders in all form
     * UI components. It builds a new, complete, entity and $emits it so
     * that data binding consistently holds a full, updated object.
     */
    update(key: string, value: any): void {
      const ur = tap(cloneDeep(this.userRole), (v) => set(v, key, value));
      this.$emit('input', ur);
    },

    /**
     * updateEntityType is a variation of the update method which handles
     * events when the entityType field is changed. These changes need to
     * affect several other fields, so we make all those changes together
     * before doing the $emit of the new Object.
     */
    updateEntityType(newType: RoleEntityType): void {
      const ur: UserRole = cloneDeep(this.userRole);
      ur.entityType = newType;
      // The roleID and entityID must now be invalid after the
      // entityType is changed.
      ur.hasAllEntities = false;
      ur.roleID = 0;
      ur.entityID = 0;
      this.$emit('input', ur);
    },
  },

  components: {
    CourtSelector,
    FacilitySelector,
    RoleSelector,
    RoleEntityTypeSelector,
  },
});
