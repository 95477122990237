















import _ from 'lodash';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import geographyAPI from '@/vuex/geography/geographyAPI';
import { Geography } from '@/vuex/geography/geography';

import GeographyForm from '@/components/geography/GeographyForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      loading: false,
      error: null as any,

      updatedGeography: null as Geography | null,
    };
  },

  computed: {
    ...mapGetters('geography', ['geographyWithID']),
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    async reset(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('geography/fetchGeography', this.id);
      const myCopy = Object.assign({}, this.geographyWithID(this.id));
      this.updatedGeography = myCopy;
      this.loading = false;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.updatedGeography) {
        return;
      }
      this.error = null;
      try {
        const response = await geographyAPI.post('geographies/update', {
          data: {
            id: this.updatedGeography.id,
            name: this.updatedGeography.name,
            type: this.updatedGeography.type,
            code: this.updatedGeography.code,
            iso3166Code: this.updatedGeography.iso3166Code,
            parentID: this.updatedGeography.parentID,
          },
        });

        // Update Vuex state
        const newState = response.data.data;
        this.$store.commit('geography/setState', newState);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    GeographyForm,
  },
});
