











































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import ChargesSummary from '@/components/charges/ChargesSummary.vue';
import ParticipantDialog from '@/components/court/participant/ParticipantDialog.vue';
import ParticipantName from '@/components/court/participant/ParticipantName.vue';
import PartyAttorneyDisplay from '@/components/court/attorney/PartyAttorneyDisplay.vue';
import SentenceSummary from '@/components/court/SentenceSummary.vue';

export default Vue.extend({
  props: {
    participant: Object,
    dense: Boolean,
  },

  data() {
    return {
      editing: false,
    };
  },

  computed: {
    ...mapState('court', ['attorneyTypes', 'partyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['attorneyName', 'partyName']),

    partyID(): number {
      return this.participant.partyID;
    },

    dossierID(): number {
      return this.participant.dossierID;
    },

    caseID(): number {
      return this.participant.caseID;
    },
  },

  methods: {
    expand(): void {
      if (this.participant && this.participant.id) {
        (this.$refs.participantDialog as any).open(this.participant.id);
      }
    },
  },

  components: {
    ChargesSummary,
    ParticipantDialog,
    ParticipantName,
    PartyAttorneyDisplay,
    SentenceSummary,
  },
});
