





























































import _ from 'lodash';
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('court', ['crimeReportWithID', 'partyName']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('crime', ['crimeWithID']),
    ...mapGetters('profile', ['profileName']),
    ...mapState('language', ['locale']),
    payload(): any {
      return this.entry.payload;
    },

    crimeReportID(): number {
      if (
        !this.entry.affectedRecords ||
        !this.entry.affectedRecords.crimeReportIDs
      ) {
        return 0;
      }
      return _.first(this.entry.affectedRecords.crimeReportIDs) || 0;
    },

    victimNames(): string {
      if (!this.payload) {
        return '';
      }
      return _.chain(this.payload.victimIDs || [])
        .map(this.partyName)
        .compact()
        .value()
        .join(', ');
    },

    crimeNames(): string {
      if (!this.payload) {
        return '';
      }
      return _.chain(this.payload.crimeIDs || [])
        .map((id) => this.crimeWithID(id).title[this.locale])
        .compact()
        .value()
        .join(', ');
    },

    suspectNames(): string {
      if (!this.payload) {
        return '';
      }
      return _.map(this.payload.suspectIDs, (id) => this.profileName(id)).join(
        ', ',
      );
    },
  },
  components: {},
});
