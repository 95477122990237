




























































import Vue from 'vue';

import { AxiosResponse } from 'axios';
import attachmentAPI from '@/vuex/attachment/attachmentAPI';

export default Vue.extend({
  props: {
    entityType: String,
    entityID: Number,
    location: String,
  },

  data() {
    return {
      error: null as any,
      isOpen: false,
      file: null as File | null,
      internalEntityID: 0,
      internalEntityType: 'temp',
      label: '',
      percentUploaded: 0.0,
      mimeType: '',
      fileNameOriginal: '',
      uploadStarted: false,
      uploadResponse: null,
    };
  },

  computed: {
    src(): any {
      if (this.file) {
        return URL.createObjectURL(this.file);
      }
      return null;
    },
    isSaveAllowed(): boolean {
      if (!this.file) {
        return false;
      }
      if (!this.label) {
        return false;
      }
      return true;
    },
  },

  watch: {
    entityType: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.internalEntityType = newVal;
        } else {
          this.internalEntityType = 'temp';
        }
      },
    },
    entityID: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.internalEntityID = newVal;
        } else {
          this.internalEntityID = Math.floor(Math.random() * -999999);
        }
      },
    },
  },

  methods: {
    filePicked(): void {
      if (this.file) {
        this.label = this.fileNameToLabel(this.file.name);
        this.mimeType = this.file.type;
        this.fileNameOriginal = this.file.name;
      }
    },
    reset(): void {
      this.error = null;
      this.fileNameOriginal = '';
      this.file = null;
      this.label = '';
      this.percentUploaded = 0.0;
      this.uploadStarted = false;
      this.mimeType = '';
      this.uploadResponse = null;
    },
    async save(): Promise<void> {
      const qs = [
        `entityType=${this.internalEntityType}`,
        `entityID=${this.internalEntityID}`,
        `location=${this.location}`,
        `label=${encodeURIComponent(this.label)}`,
        `fileNameOriginal=${encodeURIComponent(this.fileNameOriginal)}`,
        `mimeType=${this.mimeType}`,
      ];
      this.uploadStarted = true;
      try {
        const response = await attachmentAPI.put(
          '/upload?' + qs.join('&'),
          this.file,
          {
            onUploadProgress: (evt) => {
              if (evt.lengthComputable) {
                this.percentUploaded = Math.round(
                  (evt.loaded * 100) / evt.total,
                );
              }
            },
          },
        );
        const newState = response.data.data;
        this.$store.commit('attachment/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.uploadStarted = false;
        this.error = error;
      }
    },
    /**
     * updateEntityID exists to be called externally (via a this.$refs handle
     * to an instance of this component). It is necessary because the ID of the
     * entity to which this image is attached is sometimes not known at the time
     * of upload.
     */
    async updateEntityID(entityID: number): Promise<AxiosResponse> {
      const data = {
        oldEntityType: this.internalEntityType,
        newEntityType: this.internalEntityType,
        oldEntityID: this.internalEntityID,
        newEntityID: parseInt(entityID.toString(), 10),
      };
      return attachmentAPI.post('/finalize', { data });
    },
    fileNameToLabel(fileName: string): string {
      return (fileName || '').split('.')[0].replace(/_/g, ' ').trim();
    },
  },
});
