





















import Vue from 'vue';
export default Vue.extend({
  props: {
    value: Boolean,
    title: String,
    dense: Boolean,
    dark: Boolean,
    flat: Boolean,
    color: String,
  },

  data() {
    return {
      expanded: true,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal: boolean): void {
        this.expanded = newVal;
      },
    },
    expanded: {
      handler(newVal: boolean) {
        this.$emit('input', newVal);
      },
    },
  },
});
