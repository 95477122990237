






import Vue from 'vue';

import OffenderSearcher from '@/components/offender/OffenderSearcher.vue';

export default Vue.extend({
  methods: {
    /**
     * queryChanged listens for the same-named event to be emitted from
     * the searcher and updates the pushState/URL to reflect the new
     * page. This is critical to preserve the behavior of the back button
     * when links in the search results are clicked. Beacuse the $route is not
     * constantly parsed, this does not trigger a loop back into re-setting the
     * query criteria inside the searcher.
     */
    queryChanged(query: string): void {
      if (query !== '') {
        query = `?${encodeURI(query)}`;
      }
      const newPath = `${this.$route.path}${query}`;
      if (this.$route.fullPath !== newPath) {
        this.$router.replace(newPath);
      }
    },
  },

  components: {
    OffenderSearcher,
  },
});
