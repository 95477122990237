



































































import moment from 'moment';
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      error: null as any,

      lastNDays: 90,

      stats: {
        createdInPeriod: 0,
        disposedInPeriod: 0,
        clearanceRatio: 0.0,
        averageTimeToDisposition: 0.0,
      },
    };
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * fullCriteria builds the actual API criteria which will be sent. This is
     * done by combining the incoming criteria prop with customizations set
     * directly on this component.
     */
    fullCriteria(): any {
      const criteria = Object.assign({}, this.criteria);
      if (this.lastNDays > 0) {
        criteria.startDate = moment().subtract(this.lastNDays, 'days').format();
      }
      return criteria;
    },

    /**
     * queryString builds the URL querystring which will be sent to the API
     * endpoint. It exists as a standalone computed prop so that the watcher
     * can trigger only when the query has changed.
     */
    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      const url = `/cases/clearance-ratio?${this.queryString}`;

      try {
        const response = await courtAPI.get(url);
        const newStats = _.get(response, 'data.data', {});
        Object.assign(this.stats, newStats);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
});
