









































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },

  data() {
    return {
      loading: false,
      error: null as any,

      results: [] as any[],
    };
  },
  computed: {
    ...mapState('crime', ['severities']),
    ...mapGetters('language', ['nameForIDInCollection']),

    items(): any[] {
      const total = _.sumBy(this.results, 'count');
      return _.map(this.results, (row) => ({
        label: this.nameForIDInCollection(row.severityID, this.severities),
        ...row,
        percent: Math.round((row.count / total) * 100) + '%',
      }));
    },

    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    headers(): any[] {
      return [
        { text: i18n.t('charge.severity'), value: 'label' },
        { text: i18n.t('crimeReport.plural'), value: 'count', align: 'right' },
        {
          text: i18n.t('dashboard.percentage'),
          value: 'percent',
          align: 'right',
        },
      ];
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;

      try {
        const queryString = criteriaToQueryString(this.criteria);
        const url = `/crime-reports/by-severity?${queryString}`;
        const response = await investigationAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
});
