













































































import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender } from '@/vuex/offender/offender';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },

  data() {
    return {
      isOpen: false,

      housingFacilityID: 0,
      escapedAt: moment().format(),
      wasFailedEscape: false,
      causedBuildingDamage: false,
      causedStaffInjuries: false,
      helpedOthersEscape: false,
      remarks: '',
      error: null as any,
    };
  },

  computed: {
    ...mapGetters('offender', ['offenderWithID']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.escapedAt = moment().format();
      this.remarks = '';
      this.housingFacilityID =
        (this.offender && this.offender.housingFacilityID) || 0;
      this.causedBuildingDamage = false;
      this.causedStaffInjuries = false;
      this.helpedOthersEscape = false;
      this.wasFailedEscape = false;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('escapes/create', {
          data: {
            offenderID: this.offenderID,
            housingFacilityID: this.housingFacilityID,
            escapedAt: this.escapedAt,
            remarks: this.remarks,
            causedBuildingDamage: this.causedBuildingDamage,
            causedStaffInjuries: this.causedStaffInjuries,
            helpedOthersEscape: this.helpedOthersEscape,
            wasFailedEscape: this.wasFailedEscape,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.escape)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'escapeIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.escape),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
