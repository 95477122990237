import Vue from 'vue';
import settingsAPI from './settingsAPI';

export const state = {
  settings: {},
  settingsLoaded: false,
};

export const getters = {};

export const mutations = {
  setSetting(state, setting: { name: string; value: any }) {
    Vue.set(state.settings, setting.name, setting.value);
  },
  setSettingsLoaded(state, isLoaded) {
    state.settingsLoaded = isLoaded;
  },
};

export const actions = {
  async fetchSettings(
    { state, commit },
    payload = {} as { force: boolean },
  ): Promise<void> {
    if (!payload.force && state.settingsLoaded) {
      return Promise.resolve();
    }
    const response = await settingsAPI.get(`/settings`);
    if (response.data && response.data.data) {
      const settings = response.data.data;
      for (const setting of settings) {
        commit('setSetting', setting);
      }
      commit('setSettingsLoaded', true);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
