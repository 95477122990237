

























import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { PartyAttorney } from '@/vuex/court/participant';

export default Vue.extend({
  props: {
    value: Array,
  },
  computed: {
    ...mapState('court', ['attorneyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['attorneyWithID', 'attorneyName']),
    partyAttorneys(): any[] {
      const pas = (this.value || []) as PartyAttorney[];
      return pas.map((pa: PartyAttorney) => {
        return Object.assign({}, pa, {
          attorney: this.attorneyWithID(pa.attorneyID),
        });
      });
    },
  },
});
