var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('person.nationality'))+" / "+_vm._s(_vm.$t('person.birthplace'))+" ")]),_c('v-card-text',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_vm._l((_vm.nationalityResults),function(row){return _c('v-row',{key:'nationality' + row.nationalityID,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(_vm.nameForIDInCollection(row.nationalityID, 'profile/nationalities'))+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('router-link',{attrs:{"to":{
            name: 'offenderSearch',
            query: Object.assign({}, _vm.criteria,
              {nationalityID: row.nationalityID}),
          }}},[_c('NumFmt',{attrs:{"value":row.count}})],1)],1)],1)}),(!_vm.loading)?_c('v-divider',{staticClass:"my-4"}):_vm._e(),_vm._l((_vm.birthplaceResults),function(row){return _c('v-row',{key:'country' + row.countryID,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('GeographyName',{attrs:{"id":row.countryID}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('router-link',{attrs:{"to":{
            name: 'offenderSearch',
            query: Object.assign({}, _vm.criteria,
              {birthplaceGeographyID: row.countryID}),
          }}},[_c('NumFmt',{attrs:{"value":row.count}})],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }