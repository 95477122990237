

































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Offender } from '@/vuex/offender/offender';
import { ActiveTransferStatuses } from '@/vuex/offender/transfer';

import MoveDialog from '@/components/offender/transfer/MoveDialog.vue';
import TransferRequestDialog from '@/components/offender/transfer/TransferRequestDialog.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', [
      'offenderWithID',
      'offenderIsReleased',
      'transfersForOffender',
    ]),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    /**
     * isReleased computes whether the currently viewed Offender has already
     * been released. It returns true if so.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },

    /**
     * canMove returns true if the Move menu item should be displayed.
     * This can return false due to status (the prisoner is not movable) or due
     * to permissions (the currrent user is not allowed to do moves here).
     */
    canMove(): boolean {
      if (!this.offender) {
        return false;
      }

      if (this.isReleased) {
        return false;
      }

      if (this.hasActiveTransfer) {
        return false;
      }

      return this.hasPermission(
        'offender.move',
        'facility',
        this.offender.housingFacilityID,
      );
    },

    /* canTransfer returns true if the Tranfer menu item should be
     * displayed. This can return false due to status (the prisoner is not
     * in a Transferable state) or due to permissions (the currrent user is
     * not allowed to request transfers here).
     */
    canTransfer(): boolean {
      if (!this.offender) {
        return false;
      }

      if (this.isReleased) {
        return false;
      }

      if (this.hasActiveTransfer) {
        return false;
      }

      return this.hasPermission(
        'offender.transfer.request',
        'facility',
        this.offender.housingFacilityID,
      );
    },

    /**
     * hasActiveTransfer computes whether this Offender has a not-yet-completed
     * transfer
     */
    hasActiveTransfer(): boolean {
      const transfers = this.transfersForOffender(this.offenderID);
      const activeTransfers = _.filter(transfers, (transfer) => {
        return _.includes(ActiveTransferStatuses, transfer.status);
      });
      return activeTransfers.length > 0;
    },
  },

  components: {
    MoveDialog,
    TransferRequestDialog,
  },
});
