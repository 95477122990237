












































import Vue from 'vue';
export default Vue.extend({
  props: {
    data: Object,
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    juvenileOrAdult(): string {
      if (this.data.ftype === 10 || this.data.ftype === 11) {
        return 'juvenile.singular';
      }
      return 'adult.singular';
    },
    detaineeOrPrisoner(): string {
      if (this.data.ftype === 6 || this.data.ftype === 11) {
        return 'offender.detainee.singular';
      }
      return 'offender.prisoner.singular';
    },
  },
});
