













import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';

import { VSelectItem } from '@/lib/vue-typescript';
import { TAll, TUnknown } from '@/lib/translated';

export default Vue.extend({
  props: {
    value: String,
    label: String,
    rules: Array,
    disabled: Boolean,
    readonly: Boolean,
    zeroLabel: String,
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapState('profile', ['bloodTypes']),
    /**
     * bloodTypeChoices translates the raw array of blood types (
     * which includes 'Unknown' and English-character labels of the
     * types... like AB+, O-, etc) into a VSelectItem for the v-select.
     *
     * We translate the Unknown item on the fly into Dari here.
     */
    bloodTypeChoices(): VSelectItem[] {
      const rawTypes = this.bloodTypes;
      return _.map(rawTypes, (t: string) => {
        const value = t;
        let text = t;
        if (t === 'Unknown' || t === '') {
          if (this.zeroLabel === 'all') {
            text = TAll[this.locale];
          } else if (this.zeroLabel === 'unknown') {
            text = TUnknown[this.locale];
          }
        }
        return { value, text };
      });
    },
  },
});
