











































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import courtAPI from '@/vuex/court/courtAPI';
import { criteriaToQueryString, routeToCriteria } from '@/lib/criteria';

import AgeOfPendingCaseload from '@/components/court/dashboard/AgeOfPendingCaseload.vue';
import CaseClearanceRatio from '@/components/court/dashboard/CaseClearanceRatio.vue';
import CaseDocketEventCounts from '@/components/court/dashboard/CaseDocketEventCounts.vue';
import CasesByCivilCaseTopic from '@/components/court/dashboard/CasesByCivilCaseTopic.vue';
import CasesByCourtLocationTable from '@/components/court/dashboard/CasesByCourtLocationTable.vue';
import CasesByCourtRevenue from '@/components/court/dashboard/CasesByCourtRevenue.vue';
import CasesByCourtTable from '@/components/court/dashboard/CasesByCourtTable.vue';
import CasesByDispositionReason from '@/components/court/dashboard/CasesByDispositionReason.vue';
import CasesByLawsuitType from '@/components/court/dashboard/CasesByLawsuitType.vue';
import CasesByPunishmentType from '@/components/court/dashboard/CasesByPunishmentType.vue';
import CourtHearingCalendar from '@/components/court/hearing/CourtHearingCalendar.vue';
import DisposedChargesBySeverity from '@/components/court/dashboard/DisposedChargesBySeverity.vue';

export default Vue.extend({
  data() {
    return {
      loading: false,
      error: null as any,
      menuExpanded: false,

      stats: {
        activeCount: 0,
        averageAge: 0,
        nearingDeadline: 0,
        pastDeadline: 0,
      },

      criteria: {
        courtID: 0,
        locationID: 1,
      },
    };
  },
  computed: {
    ...mapGetters('court', ['courtWithID']),

    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        routeToCriteria(this.$route, this.criteria);
      },
    },
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    /**
     * fetchData performs multiple simultaneous API calls to the backend
     * to populate all the dashboard statistics and tables. It should be
     * called on page load, anytime the active courtID changes or when globas
     * parameters are changed.
     */
    async fetchData(): Promise<void> {
      this.error = null;
      this.loading = true;

      try {
        await this.fetchCourtStats();

        if (this.criteria.courtID) {
          await this.$store.dispatch('court/fetchCourtroomsForCourt', {
            courtID: this.criteria.courtID,
            force: false,
          });
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    /**
     * fetchCourtStates triggers the API call to retrieve the top-level stats
     * for this entire Court.
     */
    async fetchCourtStats(): Promise<void> {
      const queryString = criteriaToQueryString(this.criteria);
      const url = `/cases/stats?${queryString}`;
      const response = await courtAPI.get(url);
      if (response && response.data && response.data.data) {
        this.stats = response.data.data;
      }
    },
  },
  components: {
    AgeOfPendingCaseload,
    CaseDocketEventCounts,
    CasesByCivilCaseTopic,
    CasesByCourtRevenue,
    CasesByCourtTable,
    CasesByCourtLocationTable,
    CasesByDispositionReason,
    CasesByLawsuitType,
    CasesByPunishmentType,
    CaseClearanceRatio,
    CourtHearingCalendar,
    DisposedChargesBySeverity,
  },
});
