







































import moment from 'moment';
import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';

import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';

export default Vue.extend({
  props: {
    caseID: Number,
    judgeID: Number,
  },
  data() {
    return {
      isOpen: false,
      judgeRemovalReasonID: 0,
      filedAt: moment().format(),
      error: null as any,
    };
  },
  methods: {
    /**
     * reset is our event handler for CMSFormDialog's reset event. It fires when
     * the dialog is activated. We use it to create a dialog-local copy of the
     * entity being edited, or to create a new entity from scratch if none
     * has been created yet.
     */
    reset(): void {
      this.judgeRemovalReasonID = 0;
      this.filedAt = moment().format();
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We  use it to emit the
     * complete SentenceComponent object which was created by the dialog.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post('/cases/remove-case-judge', {
          data: {
            caseID: this.caseID,
            judgeID: this.judgeID,
            judgeRemovalReasonID: this.judgeRemovalReasonID,
            filedAt: this.filedAt,
          },
        });
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/addDocketEntry', response);

        await this.$store.dispatch('court/fetchCaseJudges', {
          caseID: this.caseID,
        });

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    JudgeSelector,
  },
});
