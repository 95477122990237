






import Vue from 'vue';

import ProsecutorSearcher from '@/components/investigation/prosecutor/ProsecutorSearcher.vue';

export default Vue.extend({
  components: {
    ProsecutorSearcher,
  },
});
