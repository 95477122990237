















































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender, custodyStatuses } from '@/vuex/offender/offender';

import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },

  data() {
    return {
      isOpen: false,

      statuses: custodyStatuses,

      releaseDate: moment().format(),
      releaseCustodyStatus: 'released',
      releaseReasonID: 0,
      releaseDetails: '',
      error: null as any,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', ['offenderWithID', 'offenderIsReleased']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    /**
     * canRelease computes whether this offender can be released. It is used
     * to hide the activator for this dialog. This checks both status (an
     * already-released Offender cannot be released) as well as permissions
     * (not all users will have the permissions to release an Offender).
     */
    canRelease(): boolean {
      if (!this.offender) {
        return false;
      }

      if (this.offenderIsReleased(this.offenderID)) {
        return false;
      }

      return this.hasPermission(
        'offender.release',
        'facility',
        this.offender.housingFacilityID,
      );
    },

    /**
     * expectedReleaseDate is a helper that wraps the expectedReleaseAt field
     * on the Offender.
     */
    expectedReleaseDate(): string {
      if (!this.offender) {
        return '';
      }

      return this.offender.expectedReleaseAt || '';
    },

    /**
     * releaseDateTooSoon computes whether the expected release date of the
     * offender is beforet the date being entered for the release on this
     * form. When true it triggers the display of the on-screen warning.
     */
    releaseDateTooSoon(): boolean {
      if (!this.expectedReleaseDate) {
        // Can't be too soon if its not set
        return false;
      }
      return this.releaseDate < this.offender.expectedReleaseAt;
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.releaseDate = moment().format();
      this.releaseCustodyStatus = 'released';
      this.releaseReasonID = 0;
      this.releaseDetails = '';
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the release event to the database and update the
     * Offender.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('offenders/release-offender', {
          data: {
            offenderID: this.offenderID,
            freedAt: this.releaseDate,
            custodyStatus: this.releaseCustodyStatus,
            releaseReasonID: this.releaseReasonID,
            releaseDetails: this.releaseDetails,
          },
        });
        const newState = response.data.data;
        this.$store.commit('offender/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    OffenderNameAndPIN,
  },
});
