






















































































































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { HousingFacility } from '@/vuex/housing/housing';
import { criteriaToQueryString, routeToCriteria } from '@/lib/criteria';

import OffenderRosterTable from '@/components/offender/dashboard/OffenderRosterTable.vue';
import OffendersByCriminalGroup from '@/components/offender/dashboard/OffendersByCriminalGroup.vue';
import OffendersByCustodyStatus from '@/components/offender/dashboard/OffendersByCustodyStatus.vue';
import OffendersByFacilityTable from '@/components/offender/dashboard/OffendersByFacilityTable.vue';
import OffendersByLocationTable from '@/components/offender/dashboard/OffendersByLocationTable.vue';
import OffendersByNationality from '@/components/offender/dashboard/OffendersByNationality.vue';
import OffendersBySecurityLevel from '@/components/offender/dashboard/OffendersBySecurityLevel.vue';

export default Vue.extend({
  /**
   * created is triggered only when the URL is first navigated to via the
   * vue-router. This is the only time we want to parse the $route and convert
   * it to a criteria in the OffenderSearcher.
   */
  created() {
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchStats = _.debounce(this.fetchStats, 50);

    // First take the URL parameters and map them onto the criteria.
    routeToCriteria(this.$route, this.criteria);

    // Apply Permissions-based Default Criteria
    if (!this.hasAllFacilities) {
      const facilityIDs = this.entityIDsWithPermission('dashboard.prison');
      if (!_.includes(facilityIDs, this.criteria.facilityID)) {
        if (facilityIDs.length > 0) {
          this.criteria.facilityID = facilityIDs[0];
        } else {
          // User has no Facility they are allowed to see
          // TODO Redirect to access denied page
        }
      }
    }

    // Perform the initial API call
    this.fetchStats();
  },

  data() {
    return {
      loading: false,
      error: null as any,
      expanded: [0],

      criteria: {
        facilityID: 0,
        facilityLocationID: 1,
        isJuvenile: null,
        custodyStatuses: ['in-custody'],
      },

      totalPopulation: 0,
      elderlyCount: 0,
      elderlyAge: 0,
      pastDueCount: 0,
      missingDossierCount: 0,
      nearingReleaseDays: 0,
      nearingReleaseCount: 0,
      imminentReleaseDays: 0,
      imminentReleaseCount: 0,
      maleCount: 0,
      femaleCount: 0,
      adultCount: 0,
      juvenileCount: 0,
      prisonerCount: 0,
      detaineeCount: 0,
      incomingTransfers: [],
      outgoingTransfers: [],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'hasAllFacilities',
      'allowedFacilityIDs',
      'entityIDsWithPermission',
    ]),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('housing', ['facilityWithID']),

    facility(): HousingFacility {
      return this.facilityWithID(this.criteria.facilityID);
    },

    prisonersHeader(): string {
      if (
        this.facility &&
        this.facility.canHoldJuveniles &&
        !this.facility.canHoldAdults
      ) {
        return this.$i18n.t('offender.confinee.plural').toString();
      }
      return this.$i18n.t('offender.prisoner.plural').toString();
    },

    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      routeToCriteria(this.$route, this.criteria);
    },

    queryString(newQuery, oldQuery) {
      this.fetchStats();
    },
  },
  methods: {
    async fetchStats(): Promise<void> {
      this.loading = true;
      this.error = null;

      try {
        const url = `/offenders/stats?${this.queryString}`;
        const response = await offenderAPI.get(url);

        if (response && response.data && response.data.data) {
          const stats = response.data.data;
          this.totalPopulation = stats.totalPopulation;
          this.elderlyCount = stats.elderlyCount;
          this.elderlyAge = stats.elderlyAge;
          this.pastDueCount = stats.pastDueCount;
          this.missingDossierCount = stats.missingDossierCount;
          this.nearingReleaseDays = stats.nearingReleaseDays;
          this.nearingReleaseCount = stats.nearingReleaseCount;
          this.imminentReleaseDays = stats.imminentReleaseDays;
          this.imminentReleaseCount = stats.imminentReleaseCount;
          this.prisonerCount = stats.prisonerCount;
          this.detaineeCount = stats.detaineeCount;
          this.maleCount = stats.maleCount;
          this.femaleCount = stats.femaleCount;
          this.adultCount = stats.adultCount;
          this.juvenileCount = stats.juvenileCount;
          this.incomingTransfers = stats.incomingTransfers;
          this.outgoingTransfers = stats.outgoingTransfers;
        }
        this.expanded = [0];

        let query = this.queryString;
        if (query !== '') {
          query = `?${query}`;
        }
        const newPath = `${this.$route.path}${query}`;
        if (this.$route.fullPath !== newPath) {
          this.$router.push(newPath);
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    reset(): void {
      this.criteria = {
        facilityID: 0,
        facilityLocationID: 1,
        isJuvenile: null,
        custodyStatuses: ['in-custody'],
      };
    },
  },
  components: {
    OffenderRosterTable,
    OffendersByCriminalGroup,
    OffendersByCustodyStatus,
    OffendersByFacilityTable,
    OffendersByLocationTable,
    OffendersByNationality,
    OffendersBySecurityLevel,
  },
});
