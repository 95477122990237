import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';

export const DOSSIER_NUMBER_LENGTH = 24;
export const BLANK_DOSSIER_NUMBERS = ['00-00-00-00-0000-0000-00'];

// Status constants
// Statuses come from the dossier_statuses table, and could be added to the
// database, so this list is not necessarily exhaustive.
// This list has a server-side mirror in dossier.go.
export const enum DossierStatusID {
  Unknown = 0,
  Active = 1,
  Enforcement = 2,
  Closed = 3,
}

export const ActiveDossierStatusIDs = [
  DossierStatusID.Active,
  DossierStatusID.Enforcement,
];

export const enum DossierStageID {
  BeforeCourt = 0,
  Primary = 1,
  Appellate = 2,
  Supreme = 3,
  Revision = 4,
  Tanfiz = 5,
}

export const AllDossierStageIDs = [
  DossierStageID.BeforeCourt,
  DossierStageID.Primary,
  DossierStageID.Appellate,
  DossierStageID.Supreme,
  DossierStageID.Revision,
  DossierStageID.Tanfiz,
];

export interface Dossier {
  id: number;
  currentCaseID: number;
  currentCourtStageID: number;
  statusID: number;
  stageID: number;
  number: string;
  filedAt: string;
  registeredAt: string;
  type: string;
  topicID: number;
  lawsuitTypeIDs: number[];
  firstDepartmentID: number;
  lastDepartmentID: number;
  firstCourtID: number;
  lastCourtID: number;
  isFinal: boolean;
  finalizedAt: string;
}

export const state = {
  dossierIDsForParty: {} as { [partyID: number]: number[] },
  dossier: {} as { [id: number]: Dossier },
};

export const getters = {
  dossierWithID:
    (state) =>
    (id): Dossier | null => {
      return state.dossier[id] || null;
    },
  dossiersForParty:
    (state) =>
    (partyID: number): Dossier[] => {
      return (state.dossierIDsForParty[partyID] || []).map((id) => {
        return state.dossier[id];
      });
    },
};
export const mutations = {};

export const actions = {
  async fetchDossier({ commit, dispatch }, id: number): Promise<Dossier> {
    const response = await courtAPI.get(`dossiers/${id}`);
    // Server returns state containing objects for the court and the
    // investigation stores
    const combinedState = response.data.data;

    // Split off the investigation state and store that here
    const investigationState = _.pick(combinedState, ['crimeReport']);
    commit('investigation/setState', investigationState, { root: true });

    // Split off the profile state
    const profileState = _.pick(combinedState, ['profile']);
    commit('profile/setState', profileState, { root: true });

    // Split off the court state and send it to the court state to be stored
    const courtState = _.pick(combinedState, [
      'attorney',
      'case',
      'dossier',
      'participant',
      'party',
      'subjectProperty',
      'summons',
    ]);
    commit('setState', courtState);

    commit('createIndex', {
      target: 'participantIDsForDossier',
      data: courtState.participant,
      indexKey: 'dossierID',
    });

    commit('createIndex', {
      target: 'caseIDsForDossier',
      data: courtState.case,
      indexKey: 'dossierID',
    });

    if (courtState.case) {
      _.forEach(Object.keys(courtState.case), (caseID) => {
        dispatch('fetchCase', caseID);
      });
    }

    return state.dossier[id];
  },

  async fetchDossiersForParty({ commit, dispatch }, partyID: number) {
    const response = await courtAPI.get(`parties/${partyID}/dossiers`);

    const newState = _.get(response, 'data.data');
    commit('setState', newState);
    if (newState.dossier) {
      const dossierIDs = Object.keys(newState.dossier);
      commit('setTarget', {
        target: 'dossierIDsForParty',
        index: partyID,
        value: dossierIDs,
      });

      await Promise.all(
        _.map(dossierIDs, (id) => dispatch('fetchDossier', id)),
      );
    }
  },
};
