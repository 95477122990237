

















































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { AllCourtStageIDs, CourtStageIDs } from '@/vuex/court/court';
import { Case } from '@/vuex/court/case';

import NewCaseDialog from '@/components/court/case/NewCaseDialog.vue';
import CaseSummaryCard from '@/components/court/case/CaseSummaryCard.vue';

export default Vue.extend({
  props: {
    id: Number,
    allowAdding: Boolean,
  },
  data() {
    return {
      addingStageID: CourtStageIDs.primary,
      newCaseDialogOpen: false,
    };
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['casesForDossier', 'courtName']),
    ...mapState('court', ['courtStages']),
    cases(): Case[] {
      return this.casesForDossier(this.id) || ([] as Case[]);
    },
    stageCases(): any {
      return AllCourtStageIDs.map((stageID) => ({
        stageID,
        cases: this.cases.filter((c) => c.stageID === stageID) || [],
      }));
    },
  },
  methods: {
    addCaseInStage(stageID: number): void {
      this.addingStageID = stageID;
      this.newCaseDialogOpen = true;
    },
  },
  components: {
    NewCaseDialog,
    CaseSummaryCard,
  },
});
