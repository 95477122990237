
















































































































































































































































































































































































































































































































































import Vue from 'vue';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import CaseTypeSelector from '@/components/court/CaseTypeSelector.vue';
import CellSelector from '@/components/offender/facility/CellSelector.vue';
import ChargesForm from '@/components/charges/ChargesForm.vue';
import CourtroomSelector from '@/components/court/courtroom/CourtroomSelector.vue';
import CourtSelector from '@/components/court/court/CourtSelector.vue';
import CourtStageSelector from '@/components/court/CourtStageSelector.vue';
import CrimeSelector from '@/components/crime/CrimeSelector.vue';
import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import HeadOfCourtSelector from '@/components/court/judge/HeadOfCourtSelector.vue';
import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';
import PartySearchSelector from '@/components/court/party/PartySearchSelector.vue';
import PartyTypeSelector from '@/components/dossier/PartyTypeSelector.vue';
import ProfileSearchSelector from '@/components/profile/ProfileSearchSelector.vue';

export default Vue.extend({
  data() {
    return {
      localeDateRangeValue: '',
      localeDateRangeAllowCustom: true,
      localeDateRangeTwoFields: 'never',
      phoneNumber: '',
      attorneyTypeID: 1,
      chosenDate: moment().format(),
      chosenDateWithTime: false,
      chosenDateClearable: false,
      chosenDatePastOnly: true,
      chosenDateReadonly: false,
      chosenDateYearFirst: false,
      chosenDateMinuteIncrement: 15,
      birthdate: '',
      birthdateIsApproximate: false,
      geographyID: 12127,
      heratDistrictID: 232,
      facilityID: 119,
      judgeID: 1,
      judgeRandomize: true,
      judgeID2: 0,
      judgeStages: [],
      judgeGeographyID: 0,
      cellID: 0,
      demoSnackbarMessage: 'I am a snackbar',
      demoSnackbarStatus: 'Info',
      parties: [] as number[],
      toggleMultiplePartySearchSelector: false,
      partyTypeID: 3,
      suspectIDs: 0,
      dossierID: 1,
      intField0: 0,
      intFieldNull: null,
      intField3: 3,
      intFieldString: '',
      caseID: 1,
      courtID: 1,
      fee: 0,
      crimeID: 0,
      crimeIDs: [],
      crimeSelectorChips: true,
      crimeSelectorReadonly: false,
      crimeSelectorClearable: false,
      crimeSelectorDisabled: false,
      excludeNonchargeable: false,
      charges: [],
      chargesReadonly: false,
      courtroomID: 0,
      courtStageID: 1,
      caseType: '',
      headOfCourtJudgeID: 0,
      profileSelectorPartyMode: false,
      weight: 0,
      departmentID: 2001,
      requireLeafNode: false,
    };
  },
  computed: {
    ...mapState('court', ['partyTypes', 'attorneyTypes', 'docketEventTypes']),
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('court', ['courtName', 'fullCourtName']),
  },
  components: {
    CellSelector,
    ChargesForm,
    CourtroomSelector,
    CourtSelector,
    CrimeSelector,
    FacilitySelector,
    HeadOfCourtSelector,
    JudgeSelector,
    PartySearchSelector,
    PartyTypeSelector,
    ProfileSearchSelector,
    CourtStageSelector,
    CaseTypeSelector,
  },
  methods: {
    showSnackbar() {
      this.$store.dispatch(
        `application/showSnackbar${this.demoSnackbarStatus}`,
        this.demoSnackbarMessage,
      );
    },
  },
});
