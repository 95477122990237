















import Vue from 'vue';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

import {
  ActiveChargeDispositionTypeIDs,
  GuiltyChargeDispositionTypeIDs,
} from '@/vuex/court/charge';

import { Charge } from '@/vuex/court/charge';

import AggravatorsMitigatorsDetail from '@/components/charges/AggravatorsMitigatorsDetail.vue';
import ChargeName from '@/components/charges/ChargeName.vue';

export default Vue.extend({
  props: {
    charges: {
      type: Array,
      default: () => [] as Charge[],
    },
    guiltyOnly: Boolean,
    activeOnly: Boolean,
    noPrefix: Boolean,
    short: Boolean,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['chargeDispositionTypes']),

    chargesForDisplay(): any[] {
      let charges = _.cloneDeep(this.charges || []);

      if (this.guiltyOnly) {
        charges = _.filter(charges, (c: Charge) =>
          _.includes(GuiltyChargeDispositionTypeIDs, c.dispositionTypeID),
        );
      }

      if (this.activeOnly) {
        charges = _.filter(charges, (c: Charge) =>
          _.includes(ActiveChargeDispositionTypeIDs, c.dispositionTypeID),
        );
      }

      return charges.map((c: any) => {
        let deleted = false;
        let showPrefix = false;
        if (c.dispositionTypeID === 0) {
          // Charges with an unknown disposition never include a prefix and
          // never get strikethrough styling.
          showPrefix = false;
          deleted = false;
        } else {
          // Charges with a known disposition have their prefix controlled
          // by the prop, and have strikethrough styling governed by
          // their specific disposition type.
          showPrefix = !this.noPrefix;
          deleted = !_.includes(
            GuiltyChargeDispositionTypeIDs,
            c.dispositionTypeID,
          ); // Guilty charges are not deleted
        }
        return Object.assign(c, { deleted, showPrefix });
      });
    },
  },
  components: {
    AggravatorsMitigatorsDetail,
    ChargeName,
  },
});
