





















import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { Crime } from '@/vuex/crime/crime';
import { Corpus } from '@/vuex/corpus/corpus';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    label: String,
    value: [Array, Number],
    multiple: Boolean,
    clearable: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    hideDetails: Boolean,
    singleLine: Boolean,
    chips: Boolean,
    deletableChips: Boolean,
    rules: Array,
    excludeNonchargeable: Boolean,
  },
  data() {
    return {
      search: '',
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('crime', ['allCrimes', 'crimeWithID']),
    ...mapGetters('corpus', ['corpusWithID']),
    crimeChoices(): VSelectItem[] {
      let crimes = (this.allCrimes || []) as Crime[];
      if (this.excludeNonchargeable) {
        crimes = _.reject(crimes, this.isNonchargeable);
      }
      const optionForSelect = (c: Crime): VSelectItem => {
        return { text: (c.title[this.locale] || '').trim(), value: c.id };
      };
      return _.chain(crimes).map(optionForSelect).sortBy('text').value();
    },
  },
  methods: {
    /**
     * fetchData ensures the crimes and corpora are loaded
     */
    async fetchData(): Promise<void> {
      // NOTE fetchCrimes and fetchCorpora are not likely necessary since
      // App.vue calls fetchCrimes during the progress bar loaded. We supply
      // force = false, so it won't re-do the API call.
      await Promise.all([
        this.$store.dispatch('crime/fetchCrimes', false),
        this.$store.dispatch('corpus/fetchCorpora', false),
      ]);
    },

    /**
     * isNonChargeable returns true when the Crime is non-chargeable, or if it
     * is in a non-chargeable Corpus.
     */
    isNonchargeable(crime: Crime): boolean {
      if (crime.isNonchargeable) {
        return true;
      }
      const corpus: Corpus = this.corpusWithID(crime.corpusID);
      if (corpus && corpus.isNonchargeable) {
        return true;
      }
      return false;
    },
  },
});
