var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.results.length)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ExpandableCard',{staticClass:"fill-height",attrs:{"title":_vm.$t('subdepartment.plural'),"value":_vm.value,"flat":"","dense":"","dark":"","color":"black"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.results,"headers":_vm.headers,"no-data-text":_vm.$t('error.nodata')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('router-link',{attrs:{"to":{
                name: 'detectionDashboard',
                query: Object.assign({}, _vm.criteria,
                  {departmentID: item.departmentID}),
              }}},[_c('DepartmentName',{attrs:{"id":item.departmentID}})],1)],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'crimeReportSearch',
                query: Object.assign({}, _vm.criteria,
                  {departmentID: item.departmentID}),
              }}},[_vm._v(" "+_vm._s(item.crimeReportCount)+" ")])],1),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s((item.averageFilingDelay || 0).toFixed(1))+" "+_vm._s(_vm.$t('time.days'))+" ")])])]}}],null,false,4138221696)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }