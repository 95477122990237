import _ from 'lodash';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Prosecutor {
  id: number;
  name: string;
  surname: string;
  fatherName: string;
  grandfatherName: string;
  birthplaceGeographyID: number;
  residenceGeographyID: number;
  genderID: number;
  phoneNumber: string;
  emailAddress: string;
  isDeleted: boolean;
  partyID: number;
  caseTypes: string[];
  stageIDs: number[];
}

export const state = {
  prosecutor: {} as { [id: number]: Prosecutor },
};

export const getters = {
  prosecutorWithID:
    (state) =>
    (id: number): Prosecutor | null => {
      return state.prosecutor[id] || null;
    },

  prosecutorName:
    (state) =>
    (prosecutorID: number): string => {
      const prosecutor = state.prosecutor[prosecutorID];
      if (!prosecutor) {
        return `Unknown Prosecutor: ${prosecutorID}`;
      }
      return `${prosecutor.name} ${prosecutor.surname}`;
    },
};

export const mutations = {};

export const actions = {
  async fetchProsecutor({ commit }, id: number) {
    const response = await investigationAPI.get(`prosecutors/${id}`);
    const prosecutor = response.data.data;
    const newState = modelsToState('prosecutor', prosecutor);
    commit('setState', newState);
    commit('setTarget', {
      target: 'prosecutor',
      index: prosecutor.id,
      value: prosecutor,
    });
  },
};
