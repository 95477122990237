




































import Vue from 'vue';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    payload(): any {
      return this.entry.payload;
    },
  },
});
