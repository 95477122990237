












import Vue from 'vue';

export default Vue.extend({
  props: {
    value: Boolean,
    trueLabel: String,
    falseLabel: String,
    unknownLabel: String,
  },
  computed: {
    internalValue(): string {
      switch (this.value) {
        case null:
          return 'null';
        case undefined:
          return 'null';
        case false:
          return 'false';
        case true:
          return 'true';
      }
    },
  },
});
