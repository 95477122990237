



















































































































































































































import { mapState, mapGetters } from 'vuex';
import { VForm } from '@/lib/vue-typescript';
import Github from '@/lib/github';

import Vue from 'vue';
export default Vue.extend({
  name: 'FeedbackWidget',
  data() {
    return {
      pendingApi: false,
      feedbackDialogOpen: false,
      userAgent: '',
      platform: '',
      browserCodeName: '',
      browserName: '',
      browserVersion: '',
      browserLanguage: '',
      browserOnline: false,
      cookiesEnabled: false,
      routerPath: '',
      windowURL: '',
      submitterName: '',
      submitterEmail: '',
      submitterDepartment: '',
      feedbackType: '',
      details: '',
    };
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['hasPermission']),
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },

  watch: {
    feedbackDialogOpen(val) {
      if (val) {
        this.openFeedbackDialog();
      }
    },
  },
  methods: {
    openFeedbackDialog(): void {
      this.userAgent = window.navigator.userAgent;
      this.platform = window.navigator.platform;
      this.browserCodeName = window.navigator.appCodeName;
      this.browserName = window.navigator.appName;
      this.browserVersion = window.navigator.appVersion;
      this.browserLanguage = window.navigator.language;
      this.cookiesEnabled = window.navigator.cookieEnabled;
      this.browserOnline = window.navigator.onLine;
      this.routerPath = this.$route.fullPath;
      this.windowURL = window.location.href;

      this.feedbackDialogOpen = true;
    },

    validate(): boolean {
      return this.form.validate();
    },

    async postFeedback(): Promise<void> {
      const title = this.generateTitle();
      const body = this.generateBody();
      const label = this.generateLabel();

      try {
        await Github.createIssue(title, body, label);
      } catch (err) {
        console.error('There was a problem creating a Github issue: ' + err);
      }
    },
    async submit(): Promise<void> {
      if (!this.validate()) {
        return;
      }

      this.pendingApi = true;
      try {
        await this.postFeedback();
        this.$store.dispatch(
          'application/showSnackbarSuccess',
          this.$t('feedback.apiSuccess'),
        );
        this.feedbackDialogOpen = false;
        this.pendingApi = false;
      } catch (e) {
        this.$store.dispatch(
          'application/showSnackbarError',
          this.$t('feedback.apiFail'),
        );
        this.pendingApi = false;
      }
    },

    generateTitle(): string {
      return `${this.generateLabel()} at ${this.windowURL}`;
    },

    generateBody(): string {
      let userID = 'Unknown; not logged in';
      let userName = 'Unknown; not logged in';
      let userEmail = 'Unknown; not logged in';

      if (this.user) {
        userID = this.user.id;
        userName = this.user.username;
        userEmail = this.user.email;
      }

      let detailsString = '[No details supplied]';
      if (this.details) {
        detailsString = `
## Feedback Details From User

"""
${this.details}
"""
        `;
      }

      const body = `
# ${this.generateLabel()} on ${this.windowURL}

${detailsString}

---

Encountered at ${this.windowURL}

## User Information

**Automatically Collected**
- CMS Username: ${userName}
- CMS Email: ${userEmail}
- CMS User ID: ${userID}

**Self-Reporting as**
- Name: ${this.submitterName}
- Email: ${this.submitterEmail}
- Department: ${this.submitterDepartment}

## Browser Stats

- **User Agent String**: ${this.userAgent}
- **Operating System**: ${this.platform}
- **Language**: ${this.browserLanguage}
- **Name**: ${this.browserName}
- **Codename**: ${this.browserCodeName}
- **Version**: ${this.browserVersion}
- **Was Online?**: ${this.browserOnline ? 'Yes' : 'No'}
- **Cookies Enabled?**: ${this.cookiesEnabled ? 'Yes' : 'No'}
      `;
      return body;
    },

    generateLabel(): string {
      // TODO: change when using translated labels
      return this.feedbackType.toLowerCase();
    },
  },
});
