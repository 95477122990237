























import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import { Permission } from '@/vuex/user/user';

export default Vue.extend({
  props: {
    // label is a passthrough to the v-autocomplete label prop
    label: String,

    // rules is a passthrough to the v-autocomplete rules prop
    rules: {
      type: Array,
      default: () => [], // Default value is a function which returns a blank array
    },
    // disabled is a passthrough to the v-autocomplete disabled prop
    disabled: Boolean,
    // value is the prop that makes this component work with v-model
    value: Number,

    // Pass-through variables to v-autocomplete
    hint: String,
    persistentHint: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    placeholder: String,
    autoSelectFirst: Boolean,
    autoFocus: Boolean,
    prependIcon: String,
    appendIcon: String,
    appendOuterIcon: String,
  },
  computed: {
    ...mapState('user', ['permissions']),
    permissionChoices(): Permission[] {
      return _.sortBy(this.permissions, 'key');
    },
  },
  methods: {
    permissionChanged(newVal) {
      this.$emit('input', newVal);
    },
  },
});
