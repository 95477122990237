













import _ from 'lodash';
import Vue from 'vue';

import { PartyAttorney } from '@/vuex/court/participant';

import PartyAttorneyForm from '@/components/court/attorney/PartyAttorneyForm.vue';

export default Vue.extend({
  props: {
    value: Object,
  },

  data() {
    return {
      isOpen: false,

      partyAttorney: null as PartyAttorney | null,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state, which
     * for this component is the PartyAttorney object which is bound to our
     * v-model value prop.
     */
    reset(): void {
      this.partyAttorney = Object.assign({}, this.value);
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to emit the input event, which updates the v-model value
     * which is bound to this object.
     */
    save(): void {
      this.$emit('input', this.partyAttorney);

      this.isOpen = false;
    },
  },

  components: {
    PartyAttorneyForm,
  },
});
