import _ from 'lodash';
import Vue from 'vue';
import { MutationTree } from 'vuex';

/**
 * modelsToState converts an array of domain models to a state object
 * that can be passed to the setState mutation. For an array
 * of Escape objects, modelsToState('escape', escapes) returns
 *
 *    {
 *      escape: {
 *        34: {
 *          id: 34,
 *          offenderID: 1,
 *          housingFacilityID: 1,
 *          ....
 *        }
 *      }
 *    }
 *
 * Objects are keyed using the `id` property by default, but
 * this can be overridden by providing a different property
 * as the third argument. For example LocaleMessages don't
 * have integer ids:
 *
 * modelsToState('localeMessage', messages, 'key') returns
 *
 * {
 *   localeMessage: {
 *     "escape.housingFacility": {
 *       key: "escape.housingFacility",
 *       message: {
 *         en: "Housing Facility",
 *         ...
 *       }
 *     }
 *   }
 * }
 */
export function modelsToState(
  type: string,
  models: any[],
  key: string = 'id',
): any {
  if (!Array.isArray(models)) {
    models = [models];
  } // if any model sent to this function isn't an array, this makes them one
  const newState = {};
  newState[type] = _.keyBy(models, key);
  return newState;
}

/**
 * firstModelIDInState retrieves the first entity ID in the provided state
 * for a particular domain model type (e.g. the first Prosecutor in the state).
 *
 * Example usage:
 *
 * const newState = response.data.data;
 * const newID = firstModelIDInState(newState, 'prosecutor');
 *
 * @param state A domain model state object
 * @param type  The (string) domain type for which we want the first ID
 */
export function firstModelIDInState(state: any, type: string): number {
  const modelState = _.get(state, type);
  if (!modelState) {
    console.error(`firstModelIDInState found no ${type} in state`);
    return 0;
  }
  const keys = Object.keys(modelState);
  if (keys.length < 1) {
    console.error(
      `firstModelIDInState found no entities of type '${type}' in state`,
    );
    return 0;
  }
  return parseInt(keys[0], 10);
}

/**
 * domainModelMutations provides several universal mutations
 * that can be imported into any store
 */
export const domainmodelMutations: MutationTree<any> = {
  /**
   * setState is a generic mutation which does a deep assignment of any number
   * of properties in the state. Calling setState with this object:
   *
   *    {
   *      "leave": { "45": { "id": 45, "offenderID": 23, ... } },
   *      "offender": { "23": { "id": 23, "custodyStatus", ... } }
   *    }
   *
   * Would update both the leave and offender lookup tables in
   * the Vuex state.
   *
   * NOTE: Top-level keys in newState that don't already exist in
   * state are quietly ignored. This is done to allow calling this
   * function on multiple stores with newState that might affect
   * more than one Vuex store.
   */
  setState(state, newState): void {
    const isObject = (obj: any): boolean => {
      return obj && typeof obj === 'object' && !Array.isArray(obj);
    };

    // deepAssign iterates over each item in newObj. If newState
    // and oldObj both hold a complex object at that address, it
    // recurses down another level with the same behavior up until
    // maxLevel. Once maxLevel is reached, or when non-complex
    // values are encountered, it overwrites the entire
    // key in the destination instead of copying prop-by-prop
    // recursively.
    const deepAssign = (oldObj, newObj, level, maxLevel) => {
      for (const key of Object.keys(newObj)) {
        if (!oldObj.hasOwnProperty(key) && level === 0) {
          console.error(`setState mutation is attempting to set "${key}" which is not
              defined in the Vuex state. Forgot to create it? Typo?`);
          continue;
        }
        const source = newObj[key];
        const target = oldObj[key];
        if (isObject(source) && isObject(target) && level < maxLevel) {
          deepAssign(target, source, level + 1, maxLevel);
        } else {
          Vue.set(oldObj, key, source);
        }
      }
    };

    deepAssign(state, newState, 0, 1);
  },

  /**
   * setTarget is a generic mutation which is a thin wrapper
   * around a Vue.set() call. This mutation is similar to
   * setState, but with more fine-grained control. Where
   * setState can set any number of properties, setTarget
   * sets a single value.
   *
   * The three parameters target,
   * index (optional) and value correspond to the parameters
   * from the Vue.set docs: https://vuejs.org/api/#Vue-set.
   *
   * This is easiest to understand from an example.
   * Given the following state:
   *
   * state = {
   *   escape: { [id: number}: Escape },
   *   escapeIDsForOffender: { [offenderID: number]: number[] }
   * }
   *
   * Calling:
   *
   *   setTarget({
   *     target: 'escapeIDsForOffender',
   *     index: 45, // The ID for the current offender
   *     value: [1,2,3], // Array of escapes from the server
   *   })
   *
   * Would have the same effect as:
   *
   *   state.escapeIDsForOffender[45] = [1,2,3];
   *
   * The key parameter can be omitted to set a value in the
   * top-level of the state. Consider a different structure:
   *
   * state = {
   *   crime: { [id: number]: Crime },
   *   crimeIDs: [] as number[],
   * };
   *
   * In this case, you would omit the key parameter, so
   * calling:
   *
   *   setTarget({ target: 'crimeIDs', value: [1,2,3] });
   *
   * would have the same effect as:
   *
   *   state.crimeIDs = [1,2,3];
   *
   */
  setTarget(state, payload: { target: string; index: any; value: any }): void {
    if (payload.target === null || payload.target === undefined) {
      console.error('setTarget called without target');
      return;
    }
    if (!state.hasOwnProperty(payload.target)) {
      console.error(
        `Target "${payload.target}" does not exist in Vuex. Forgot to create it? Typo?`,
      );
      return;
    }
    if (payload.value === null || payload.value === undefined) {
      console.error('setTarget called without value');
      return;
    }
    if (payload.index === null || payload.index === undefined) {
      Vue.set(state, payload.target, payload.value);
    } else {
      Vue.set(state[payload.target], payload.index, payload.value);
    }
  },

  /**
   * appendToTarget is a generic mutation which is a thin
   * wrapper around a Vue.set call. See: https://vuejs.org/api/#Vue-set
   *
   * The parameters target, index, and value work similarly to Vue.set,
   * except the value parameter, which will be merged to the end
   * of any existing array value (removing duplicates).
   *
   * As with the setTarget() mutation, the index parameter can be
   * omitted, indicating a mutation to a top-level array directly.
   *
   * The value parameter can be given a single value or an array.
   */
  appendToTarget(
    state,
    payload: { target: string; index: any; value: any },
  ): void {
    if (payload.target === null || payload.target === undefined) {
      console.error('appendToTarget called without target');
      return;
    }
    if (!state.hasOwnProperty(payload.target)) {
      console.error(
        `Target "${payload.target}" does not exist in Vuex. Forgot to create it? Typo?`,
      );
      return;
    }
    if (payload.value === null || payload.value === undefined) {
      console.error('appendToTarget called without value');
      return;
    }
    // Ensure value is wrapped in an array
    const newValues = Array.isArray(payload.value)
      ? payload.value
      : [payload.value];

    // Prevent appending objects to ID array indices
    if (typeof newValues[0] === 'object' && payload.target.includes('IDs')) {
      console.error(
        'appendToTarget called with an array of objects. ' +
          'Expected ID primitives. ' +
          'Did you use Object.values() instead of Object.keys()?',
      );
      return;
    }

    if (payload.index === null || payload.index === undefined) {
      // If no index was provided, the target itself holds the array
      const oldValues = state[payload.target] || [];
      if (_.isObject(oldValues) && !_.isArray(oldValues)) {
        console.error(
          `appendToTarget is unable to append ${payload.value} to ${payload.target}[undefined]. Undefined index supplied for an object.`,
        );
        return;
      }
      Vue.set(state, payload.target, _.uniq([...oldValues, ...newValues]));
    } else {
      // If an index was provided, the array is keyed inside the target variable
      const oldValues = state[payload.target][payload.index] || [];
      if (_.isObject(oldValues) && !_.isArray(oldValues)) {
        console.error(
          `appendToTarget is unable to append ${payload.value} to ${payload.target}[undefined]. Undefined index supplied for an object.`,
        );
      }
      Vue.set(
        state[payload.target],
        payload.index,
        _.uniq([...oldValues, ...newValues]),
      );
    }
  },

  /**
   * prependToTarget is a generic mutation which is a thin
   * wrapper around a Vue.set call. See: https://vuejs.org/api/#Vue-set
   *
   * The parameters target, index, and value work similarly to Vue.set,
   * except the value parameter, which will be merged to the beginning
   * of any existing array value (removing duplicates).
   *
   * As with the setTarget() mutation, the index parameter can be
   * omitted, indicating a mutation to a top-level array directly.
   *
   * The value parameter can be given a single value or an array.
   */
  prependToTarget(
    state,
    payload: { target: string; index: any; value: any },
  ): void {
    // Convert bad input into helpful error messages
    if (payload.target === null || payload.target === undefined) {
      console.error('prependToTarget called without target');
      return;
    }
    if (payload.value === null || payload.value === undefined) {
      console.error('prependToTarget called without value');
      return;
    }
    if (!state.hasOwnProperty(payload.target)) {
      console.error(
        `Target  "${payload.target}" does not exist in Vuex. Forgot to create it? Typo?`,
      );
      return;
    }

    // Ensure value is wrapped in an array
    const newValues = Array.isArray(payload.value)
      ? payload.value
      : [payload.value];

    // Prevent prepending objects to ID array indices
    if (typeof newValues[0] === 'object' && payload.target.includes('IDs')) {
      console.error(
        'prependToTarget called with an array of objects. ' +
          'Expected ID primitives. ' +
          'Did you use Object.values() instead of Object.keys()?',
      );
      return;
    }

    // Assign the new state
    if (payload.index === null || payload.index === undefined) {
      // If no key was provided, the index itself holds the array
      const oldValues = state[payload.target] || [];
      if (_.isObject(oldValues) && !_.isArray(oldValues)) {
        console.error(
          `prependToTarget is unable to prepend ${payload.value} to ${payload.target}[undefined]. Undefined index supplied for an object.`,
        );
        return;
      }
      Vue.set(state, payload.target, _.uniq([...newValues, ...oldValues]));
    } else {
      // If a key was provided, the array is keyed inside the index variable
      const oldValues = state[payload.target][payload.index] || [];
      Vue.set(
        state[payload.target],
        payload.index,
        _.uniq([...newValues, ...oldValues]),
      );
    }
  },

  removeFromTarget(
    state,
    payload: { target: string; index: any; value: any },
  ): void {
    // Convert bad input into helpful error messages
    if (payload.target === null || payload.target === undefined) {
      console.error('removeFromTarget called without target');
      return;
    }
    if (payload.value === null || payload.value === undefined) {
      console.error('removeFromTarget called without value');
      return;
    }
    if (!state.hasOwnProperty(payload.target)) {
      console.error(
        `Target  "${payload.target}" does not exist in Vuex. Forgot to create it? Typo?`,
      );
      return;
    }

    if (payload.index === null || payload.index === undefined) {
      const oldValues = state[payload.target] || [];
      const newValues = _.reject(oldValues, (v) => v == payload.value); // tslint:disable-line:triple-equals

      Vue.set(state, payload.target, newValues);
    } else {
      // If an index was provided, the array is keyed inside the target variable
      const oldValues = state[payload.target][payload.index] || [];
      const newValues = _.reject(oldValues, (v) => v == payload.value); // tslint:disable-line:triple-equals

      if (_.isObject(oldValues) && !_.isArray(oldValues)) {
        console.error(
          `removeFromTarget is unable to remove ${payload.value} from ${payload.target}[${payload.index}]. Undefined index supplied for an object.`,
        );
      }

      Vue.set(state[payload.target], payload.index, newValues);
    }
  },

  createIndex(state, payload: { target: string; data: any; indexKey: string }) {
    // Convert bad input into helpful error messages
    if (payload.target === null || payload.target === undefined) {
      console.error('createIndex called without target');
      return;
    }
    if (!state.hasOwnProperty(payload.target)) {
      console.error(
        `Target "${payload.target}" does not exist in Vuex (trying to createIndex). Forgot to create it? Typo?`,
      );
      return;
    }

    const withIDs = _.mapValues(payload.data, (entity, key) => {
      return Object.assign({}, entity, {
        id: key, // There is very likely already an id property that matches key, but just to make sure
      });
    });
    const groups = _.groupBy(withIDs, payload.indexKey);
    const groupsWithIDs = _.mapValues(groups, (entities) => {
      return _.map(entities, 'id');
    });
    _.forEach(groupsWithIDs, (idArray, index) => {
      Vue.set(state[payload.target], index, idArray);
    });
  },
};
