const state = {
  snackbar: {
    message: '',
    isOpen: false,
    colorClass: 'info',
  },
};

const getters = {
  snackbarMessage(state): string {
    return state.snackbar.message;
  },
  snackbarIsOpen(state): boolean {
    return state.snackbar.isOpen;
  },
  snackbarColorClass(state): string {
    return state.snackbar.colorClass;
  },
};

const mutations = {
  setSnackbarMessage(state, message: string) {
    state.snackbar.message = message;
  },

  openSnackbarSuccess(state): void {
    state.snackbar.isOpen = true;
    state.snackbar.colorClass = 'success';
  },

  openSnackbarError(state): void {
    state.snackbar.isOpen = true;
    state.snackbar.colorClass = 'error';
  },

  openSnackbarInfo(state): void {
    state.snackbar.isOpen = true;
    state.snackbar.colorClass = 'info';
  },

  setSnackbarClosed(state): void {
    state.snackbar.isOpen = false;
  },
};

const actions = {
  showSnackbarSuccess({ commit, dispatch }, message: string): void {
    commit('setSnackbarMessage', message);
    commit('openSnackbarSuccess');
  },

  showSnackbarError({ commit, dispatch }, message: string): void {
    commit('setSnackbarMessage', message);
    commit('openSnackbarError');
  },

  showSnackbarInfo({ commit, dispatch }, message: string): void {
    commit('setSnackbarMessage', message);
    commit('openSnackbarInfo');
  },

  closeSnackbar({ commit }): void {
    commit('setSnackbarClosed');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
