import _ from 'lodash';
import Vue from 'vue';

export interface CivilDecision {
  id: number;
  caseID: number;
  favorPartyIDs: number[];
  againstPartyIDs: number[];
  settlementValueInPuls: number;
  settlementNotes: string;
  filedAt: string;
  registeredAt: string;
  decisionNumber: string;
  verdictText: string;
}

export const state = {
  civilDecision: {} as { [id: number]: CivilDecision },
  civilDecisionIDForCase: {} as { [caseID: number]: number },
};

export const getters = {
  civilDecisionWithID:
    (state) =>
    (id: number): CivilDecision => {
      return state.civilDecision[id];
    },

  civilDecisionForCase:
    (state) =>
    (caseID: number): CivilDecision | null => {
      const decisionID = state.civilDecisionIDForCase[caseID];
      if (!decisionID) {
        return null;
      }
      return state.civilDecision[decisionID] || null;
    },

  settlementValueInPulsForCase:
    (state, getters) =>
    (caseID: number): number => {
      const decision = getters.civilDecisionForCase(caseID) as CivilDecision;
      if (decision) {
        return decision.settlementValueInPuls;
      }
      return 0;
    },
};
export const mutations = {
  /**
   * reindexCivilDecision re-computes the civilDecisionIDForCase
   * index from scratch by scanning the entire local civilDecision
   * state and finding the ones with the provided caseID. This
   * mutation is intended to be called after a new civil decision
   * is added to the state.
   */
  reindexCivilDecision(state, caseID): void {
    const all: CivilDecision[] = Object.values(state.civilDecision);
    const pertinent: CivilDecision[] = _.filter(all, { caseID });
    const id = _.chain(pertinent).sortBy('registeredAt').last().value();
    Vue.set(state.civilDecisionIDForCase, caseID, id);
  },
};
export const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
