

















import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';

// MAX_CONSECUTIVE_ZEROS controls the point at which the validator considers
// this a "blank" dossier number because it has too many zeros.
const MAX_CONSECUTIVE_ZEROS = 6;

export default Vue.extend({
  props: {
    value: String,
    rules: Array,
    label: String,
    hint: String,
    required: Boolean,
    readonly: Boolean,
    hideDetails: Boolean,
    singleLine: Boolean,
    loading: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    disablePadding: Boolean,
    unique: Boolean,
  },

  created() {
    // Since the checkExistsAPI call is made in response to user
    // keypresses (which can be very fast), we debounce it so that it's only
    // called once every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.checkExistsAPI = _.debounce(this.checkExistsAPI, 200);
  },

  data() {
    return {
      /**
       * awaitingUniquenessCheck is set to true which we are waiting for the
       * results of a server-side uniqueness check
       */
      awaitingUniquenessCheck: false,

      /**
       * blockSizes describes the format of the dossier number by the length
       * of the blocks of digits which are separated by dashes. So if blockSizes
       * were set to 2-2, it would require values in the format ##-##
       */
      blockSizes: [2, 2, 2, 2, 4, 4, 2],

      /**
       * isDuplicate tracks the results of the last check to the API to see if
       * the entered dossier number is a duplicate. It is updated asynchrously
       * after each API call.
       */
      isDuplicate: false,
    };
  },
  computed: {
    /**
     * mask converts the blockSizes into the format expected by vue-the-mask.
     * [2,2,2,2,4,4,2] becomes the string ##-##-##-##-####-####-##
     */
    mask(): string {
      return this.blockSizes.map((size) => '#'.repeat(size)).join('-');
    },

    /**
     * computedRules combines the custom rules provided by the user with the
     * internal/hard-coded rules, like those triggered by props like required.
     * It's important to realize this function is not run after every
     * modification to the field... it runs at the time the component initializes
     * and adds an array of validation functions... it is those functions which
     * are run on every field modification.
     */
    computedRules(): any[] {
      const rules: any[] = this.rules || [];
      if (this.required) {
        rules.push(this.forbidBlankNumbers);
      }
      return rules;
    },

    /**
     * errorMessages returns the field errors which arent' known until the
     * the response from an async call is returned t(unlike rules/combinedRules,
     * which must be synchronous validation functions).
     *
     * The unique prop requires an async call to the server to check for
     * duplicates, so we return errors from that process here.
     */
    errorMessages(): string[] {
      const msgs: string[] = [];
      if (this.unique && this.isDuplicate) {
        msgs.push(i18n.t('error.duplicate').toString());
      }
      return msgs;
    },
  },

  watch: {
    /**
     * value is watched so that we can trigger a uniqueness check any time the
     * value changes, whether from "inside" in the v-text-field, or when set
     * externally by the consumer of this component.
     */
    value(newVal: string): void {
      if (this.unique) {
        this.determineUniqueness();
      } else {
        // When not requiring uniqueness, make sure the tracking variable is
        // in the non-error state.
        this.isDuplicate = false;
      }
    },
  },

  methods: {
    /**
     * formatNumber iterates over the current value block-by-block, and pads
     * the start of each one with '0' until it reaches the length defined
     * by the blockSizes array.
     */
    formatNumber(): void {
      if (this.value.length < this.mask.length && !this.disablePadding) {
        const oldBlocks = this.value.split('-');
        const newBlocks = [] as string[];
        for (let i = 0; i < this.blockSizes.length; i++) {
          const padded = (oldBlocks[i] || '').padStart(this.blockSizes[i], '0');
          newBlocks.push(padded);
        }
        this.$emit('input', newBlocks.join('-'));
      }
    },

    /**
     * forbidBlankNumbers is a Vuetify control validator. It returns true if the
     * provided (v) value is a non-blank, and returns a translated error message
     * string if it is blank.
     *
     * NOTE: "blank" here means either:
     *
     * 1) That it's literally a blank string, or
     * 2) That it contains too many zeros in a row where "too many"
     *    is controlled by MAX_CONSECUTIVE_ZEROS.
     */
    forbidBlankNumbers(v: string): boolean | string {
      const errMessage = i18n.t('error.required').toString();

      if (!v) {
        return errMessage;
      }

      const numsOnly = v.replace(/[^0-9]+/g, '');
      const re = new RegExp(`[0]{${MAX_CONSECUTIVE_ZEROS},}`);
      if (numsOnly.match(re)) {
        return errMessage;
      }

      return true;
    },

    /**
     * determineUniqueness starts the process of checking if a duplicate number
     * error message needs to be triggered for the form. It does synchronous
     * checks first, and only triggers a server-side API check when necessary.
     */
    determineUniqueness(): void {
      if (this.value.length < this.mask.length) {
        // Don't check for duplicates if the user hasn't finished entering their
        // number. Assume the value will be unique when they are finished...
        // in other words, treat the value as unique until proven as a duplicate.
        this.isDuplicate = false;
        return;
      }

      // Start the waiting animation
      this.awaitingUniquenessCheck = true;

      // Trigger the uniqueness check on the API
      this.checkExistsAPI(); // Note: execution is debounced
    },

    /**
     * checkExistsAPI makes an API call to verify that a dossier
     * number exists or not. This function is protected by a debounce wrapper so
     * that it is not called repeatedly too quickly.
     */
    async checkExistsAPI(): Promise<void> {
      const url = `/dossiers/exists?number=${this.value}`;
      try {
        const response = await courtAPI.get(url);
        this.isDuplicate = response.data.data === true;
      } catch (err) {
        console.error(err);
      } finally {
        // End the waiting animation
        this.awaitingUniquenessCheck = false;
      }
    },
  },
});
