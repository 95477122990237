





























import _ from 'lodash';
import Vue from 'vue';

import etlAPI from '@/vuex/etl/etlAPI';

import CMS1Viewer from '@/components/etl/CMS1Viewer.vue';

export default Vue.extend({
  created() {
    // Make every call to this.fetchData be debounced so that
    // it can never be called faster than once every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,

      caseType: 'criminal',
      dossierNumber: '',
      legacyData: null,
    };
  },

  computed: {},

  watch: {
    caseType(newValue: string) {
      this.fetchData();
    },

    dossierNumber(newValue: string) {
      this.fetchData();
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      try {
        this.error = null;
        this.loading = true;

        const url = `/legacydata/${this.caseType}/${this.dossierNumber}`;
        const res = await etlAPI.get(url);
        this.legacyData = _.get(res, 'data', null);
      } catch (err) {
        this.error = err;
        this.legacyData = null;
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    CMS1Viewer,
  },
});
