















































import moment from 'moment';
import Vue from 'vue';
import offenderAPI from '@/vuex/offender/offenderAPI';

import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data(): any {
    return {
      isOpen: false,

      createdAt: moment().format(),
      medicalProfessional: '',
      notes: '',
      error: null as any,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.createdAt = moment().format();
      this.medicalProfessional = '';
      this.notes = '';
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post(
          'health-history-records/create',
          {
            data: {
              offenderID: this.offenderID,
              createdAt: this.createdAt,
              medicalProfessional: this.medicalProfessional,
              notes: this.notes,
            },
          },
        );
        const newState = response.data.data;
        const newID = Object.keys(newState.healthHistoryRecord)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'healthHistoryRecordIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.healthHistoryRecord),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    OffenderNameAndPIN,
  },
});
