

























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString } from '@/lib/criteria';
import { Geography } from '@/vuex/geography/geography';

export default Vue.extend({
  props: {
    value: Boolean,
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      results: [],
    };
  },
  computed: {
    ...mapGetters('geography', ['geographyWithID', 'fullGeographyName']),

    geographies(): Geography[] {
      return _.chain(this.results || [])
        .map('locationID')
        .map(this.geographyWithID)
        .compact()
        .value();
    },

    /**
     * mostCommonType scans the list of facilities and returns the one that
     * occurs most frequently. This is used to create a summary title.
     */
    mostCommonGeographyType(): string {
      const types = _.map(this.geographies || [], 'type') as string[];
      return _.chain(types)
        .countBy() // Produces { "facility": 14, "cell": 10 }
        .entries() // Converts to [["facility", 14], ["cell", 10]]
        .maxBy(_.last) // Filters to ["facility", 14]
        .head() // Retrieves first element ("facility")
        .value() as string; // Exit lodash chain and type-assert to string
    },

    /**
     * queryString computes the parameters to send to the API. This must
     * be a computed property so that it can be watched to trigger a new
     * API call.
     */
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    headers(): any[] {
      return [
        { text: i18n.t('name'), value: 'name' },
        {
          text: i18n.t('crimeReport.activeCrimeReports'),
          align: 'right',
          value: 'crimeReportCount',
        },
        {
          text: 'Average Filing Delay', // TODO Translate
          align: 'right',
          value: 'averageFilingDelay',
        },
      ];
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const url = `/crime-reports/by-location?${this.queryString}`;
        const res = await investigationAPI.get(url);
        this.results = _.get(res, 'data.data', []);
      } finally {
        this.loading = false;
      }
    },
  },
});
