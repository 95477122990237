


























































































































import Vue from 'vue';

import { SubjectProperty } from '@/vuex/court/subjectProperty';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    id: Number,
    object: Object,
  },
  computed: {
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('court', ['subjectPropertyWithID']),
    ...mapGetters('language', ['nameForIDInCollection']),
    subjectProperty(): SubjectProperty | null {
      if (this.id) {
        return this.subjectPropertyWithID(this.id);
      }
      if (this.object) {
        return this.object;
      }
      return null;
    },
  },
});
