



import Vue from 'vue';
import i18n from '@/i18n';

export default Vue.extend({
  props: {
    hours: Number,
  },
  computed: {
    /**
     * formatAFN converts the value prop from Puls to AFN and performs an
     * Internationalized number format on it.
     */
    hoursToWords(): string {
      const HoursPerYear = 8760;
      const HoursPerMonth = 730;
      const HoursPerDay = 24;
      let remainingHours = this.hours || 0;
      const years = Math.floor(remainingHours / HoursPerYear);
      remainingHours = remainingHours - years * HoursPerYear;
      const months = Math.floor(remainingHours / HoursPerMonth);
      remainingHours = remainingHours - months * HoursPerMonth;
      const days = Math.floor(remainingHours / HoursPerDay);
      const parts = [] as string[];
      if (years > 0) {
        const yearLabel = i18n.t('time.years');
        parts.push(`${years} ${yearLabel}`);
      }
      if (months > 0) {
        const monthLabel = i18n.t('time.months');
        parts.push(`${months} ${monthLabel}`);
      }
      if (days > 0) {
        const dayLabel = i18n.t('time.days');
        parts.push(`${days} ${dayLabel}`);
      }
      return parts.join(', ');
    },
  },
});
