


















































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import userAPI from '@/vuex/user/userAPI';
import { criteriaToQueryString } from '@/lib/criteria';
import { modelsToState } from '@/lib/vuex-domainmodel';
import { User } from '@/vuex/user/user';

import NewUserDialog from '@/components/user/NewUserDialog.vue';
import EditUserDialog from '@/components/user/EditUserDialog.vue';
import RoleEntityTypeSelector from '@/components/user/RoleEntityTypeSelector.vue';
import RoleSelector from '@/components/user/RoleSelector.vue';

export default Vue.extend({
  created(): void {
    this.fetchData();
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },

  data() {
    return {
      loading: false,

      // Server pagination
      currentPage: 1,
      serverTotalResults: 0,
      pageSize: 10,

      criteria: {
        q: '',
        includeDeleted: false,
        entityType: '',
        roleID: 0,
      },
      userIDs: [] as number[],
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('user', ['userWithID']),

    users(): User[] {
      return _.map(this.userIDs || [], this.userWithID);
    },

    footerProps(): any {
      const opts = {
        itemsPerPageOptions: [5, 15, 50, 150],
        itemsPerPageText: this.$i18n.t('uiElements.rowsPerPage'),
      };
      return opts;
    },

    /**
     * queryString computes the URL querystring parameters which will be
     * submitted to the server to perform the search. NOTE that this
     * value is combined with pagination criteria inside fetchData, so this
     * value doesn't exactly match the API request.
     */
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    headers(): any[] {
      const h: any[] = [
        {
          text: i18n.t('user.username'),
          value: 'username',
        },
        {
          text: i18n.t('person.fullName'),
          value: 'fullName',
        },
        {
          text: i18n.t('person.initials'),
          value: 'initials',
        },
        {
          text: i18n.t('user.email'),
          value: 'email',
        },
        {
          text: i18n.t('user.lastLoginAt'),
          value: 'lastLoginAt',
        },
      ];

      if (this.canEdit) {
        h.unshift({
          value: 'id',
          text: i18n.t('command.edit'),
          width: '30px',
          sortable: false,
        });
      }
      return h;
    },

    /**
     * canAdd computes whether the current user is allowed to create
     * new users.
     */
    canAdd(): boolean {
      return this.hasPermission('admin.user.create');
    },

    /**
     * canEdit computes whether the current user is allowed to edit
     * existing users.
     */
    canEdit(): boolean {
      return this.hasPermission('admin.user.create');
    },

    /**
     * canExport computes whether the current user is allowed to download
     * an Excel export of the data on the screen.
     */
    canExport(): boolean {
      return this.hasPermission('admin.user.create');
    },
  },

  watch: {
    /**
     * queryString is watched so that a new search can be triggered.
     * We also reset back to page #1 anytime a new search is triggered.
     */
    queryString(newVal: string) {
      this.currentPage = 1;
      this.fetchData();
    },
  },

  methods: {
    /**
     * afterUserAdded is triggered when the user successfully adds a new
     * record via the add dialog attached to the search interface. It receives
     * the ID of the newly-created User and updates the search reuslts.
     */
    afterUserAdded(newID: number): void {
      this.userIDs.unshift(newID);
      if (this.serverTotalResults < 1) {
        this.serverTotalResults = 1;
      }
    },

    async fetchData(): Promise<void> {
      this.loading = true;

      const args = [] as string[];
      args.push(`page[size]=${this.pageSize}`);
      args.push(`page[number]=${this.currentPage}`);
      args.push(this.queryString);

      try {
        const response = await userAPI.get(`/users/search?${args.join('&')}`);
        this.serverTotalResults =
          _.get(response, 'data.meta.totalResults') || 0;

        const users = _.get(response, 'data.data', []);
        this.userIDs = _.map(users, 'id');
        const newState = modelsToState('user', users);
        this.$store.commit('user/setState', newState);
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    NewUserDialog,
    EditUserDialog,
    RoleEntityTypeSelector,
    RoleSelector,
  },
});
