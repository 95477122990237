
























import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'Snackbar',
  computed: {
    ...mapGetters('application', ['snackbarMessage', 'snackbarColorClass']),
    snackbarIsOpen: {
      // This needs to be a separate get/set computed property to allow the
      // timeout prop on v-snackbar to properly set open/closed
      get(): boolean {
        return this.$store.getters['application/snackbarIsOpen'];
      },
      set(open): void {
        if (!open) {
          this.$store.dispatch('application/closeSnackbar');
        }
      },
    },
    timeoutTime() {
      return this.snackbarColorClass === 'error' ? -1 : 500;
    },
    isError() {
      return this.snackbarColorClass === 'error';
    },
  },
  methods: {
    ...mapActions('application', [
      'showSnackbarSuccess',
      'showSnackbarError',
      'showSnackbarInfo',
      'closeSnackbar',
    ]),
    back() {
      history.back();
    },
  },
});
