





















































































































































































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { CaseStatus } from '@/vuex/court/case';
import {
  DocketEventType,
  DocketEventTypeModule,
  AllDocketEventTypeModules,
} from '@/vuex/court/options';
import { DossierStatusID } from '@/vuex/court/dossier';
import { TranslatedString } from '@/lib/translated';
import { VSelectItem } from '@/lib/vue-typescript';

import CaseTypeSelector from '@/components/court/CaseTypeSelector.vue';
import CustomFieldsTable from './CustomFieldsTable.vue';
import CourtStageSelector from '@/components/court/CourtStageSelector.vue';

export default Vue.extend({
  props: {
    value: Object,
  },

  data() {
    return {
      addingField: false,
    };
  },

  computed: {
    ...mapGetters('language', ['languages']),
    ...mapState('court', ['caseTypes']),

    /**
     * docketEventType computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    docketEventType(): DocketEventType {
      return this.value
        ? this.value
        : {
            module: 'court',
            categoryID: 0,
            rank: 0,
            name: {} as TranslatedString,
            willChangeDossierStage: false,
            newDossierStageID: 0,
            willChangeDepartment: false,
            newDepartmentID: 0,
            willChangeDossierStatus: false,
            newDossierStatusID: DossierStatusID.Unknown,
            willChangeCaseStatus: false,
            newCaseStatusID: CaseStatus.Unknown,
            willChangeCourtStage: false,
            newCourtStageID: 0,
            caseDispositionReasonID: 0,
            allowedDossierStatuses: [],
            allowedCourtStageIDs: [],
            allowedDossierStageIDs: [],
            allowedCaseTypes: this.caseTypes,
            fields: [],
            willSetAwarenessNumber: false,
            willFinalizeDossier: false,
            isDiscontinued: false,
          };
    },

    /**
     * isCourtModule computes whether this DocketEventType is related to
     * individual Court Cases.
     */
    isCourtModule(): boolean {
      return this.docketEventType.module === DocketEventTypeModule.Court;
    },

    /**
     * isInvestigationModule computes whether this DocketEventType is related
     * to the AGO/Huquq/Tanfiz actions around a Court Case, rather than
     * "inside" one.
     */
    isInvestigationModule(): boolean {
      return (
        this.docketEventType.module === DocketEventTypeModule.Investigation
      );
    },

    /**
     * willChangeCaseStatus is a computed sub-property of docketEventType
     * which allows us to watch when that field changes.
     */
    willChangeCaseStatus(): boolean {
      return !!this.docketEventType.willChangeCaseStatus;
    },

    /**
     * willDisposeCase computes whether this docket event will result in the
     * Court case being disposed.
     */
    willDisposeCase(): boolean {
      return this.docketEventType.newCaseStatusID === CaseStatus.Disposed;
    },

    /**
     *
     * willChangeDossierStatus is a computed sub-property of docketEventType
     * which allows us to watch when that field changes.
     */
    willChangeDossierStatus(): boolean {
      return !!this.docketEventType.willChangeDossierStatus;
    },

    /**
     * moduleChoices builds the array of objects to feed to the module selector
     */
    moduleChoices(): VSelectItem[] {
      return AllDocketEventTypeModules.map((m) => ({
        value: m,
        text: i18n.t('docketEventType.module.' + m),
      }));
    },
  },

  watch: {
    willChangeCaseStatus(willChange: boolean): void {
      if (willChange) {
        this.update('newCaseStatusID', CaseStatus.Disposed);
      } else {
        this.update('newCaseStatusID', CaseStatus.Unknown);
      }
    },

    willChangeDossierStatus(willChange: boolean): void {
      if (willChange) {
        this.update('newDossierStatus', DossierStatusID.Active);
      } else {
        this.update('newDossierStatus', DossierStatusID.Unknown);
      }
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.docketEventType), (v) => set(v, key, modifiedValue)),
      );
    },
  },

  components: {
    CaseTypeSelector,
    CustomFieldsTable,
    CourtStageSelector,
  },
});
