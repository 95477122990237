
















































import Vue from 'vue';

import DetectionTab from './DetectionTab.vue';
import DetentionTab from './DetentionTab.vue';
import InvestigationTab from './InvestigationTab.vue';
import CourtsTab from './CourtsTab.vue';
import PrisonTab from './PrisonTab.vue';

export default Vue.extend({
  props: {
    data: Object,
  },

  data() {
    return {
      tab: 'detection',
    };
  },

  components: {
    DetectionTab,
    DetentionTab,
    InvestigationTab,
    CourtsTab,
    PrisonTab,
  },
});
