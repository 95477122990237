


















import Vue from 'vue';
export default Vue.extend({
  props: {
    value: [Number, String],
    clearable: Boolean,
    label: String,
    hint: String,
    suffix: String,
    prefix: String,
    placeholder: String,
    readonly: Boolean,
    persistentHint: Boolean,
    hideDetails: Boolean,
    singleLine: Boolean,
    loading: Boolean,
    rules: Array,
  },
  data() {
    return {
      internalValue: '',
    };
  },
  computed: {
    combinedRules(): any[] {
      return [
        (v) =>
          Number.isFinite(Number(v)) ||
          this.$i18n.t('error.numeric').toString(),
        ...(this.rules || []),
      ];
    },
  },
  created() {
    this.valueChangedFromOutside(this.value);
  },
  watch: {
    value(val: any) {
      this.valueChangedFromOutside(val);
    },
    internalValue(val: any) {
      this.inputChanged(val);
    },
  },
  methods: {
    /**
     * inputChanged is the event handler for modifications made to our internal
     * textfield component. These changes originate from the user's keyboard
     * typing in the input control.
     *
     * This method is responsible for converting the string input in the text
     * field into a Number-typed output, with the Number 0 being returned if
     * the input wasn't parseable as a Number.
     */
    inputChanged(newValue: any): void {
      // input is now a blank string, which means a 0 integer value
      // so we emit that and return immediately.
      if (newValue === '') {
        this.$emit('input', 0);
        return;
      }

      if (Number.isFinite(Number(newValue))) {
        // Valid number parsed, emit it.
        this.$emit('input', Number(newValue));
      } else {
        // No valid number found. Emit 0 instead.
        this.$emit('input', 0);
      }
    },

    /**
     * valueChangedFromOutside is the event handler for modifications made to
     * the value prop on this component. It fires when anything outside this
     * component changes its value, including when the initial value is first
     * set when the component is created.
     */
    valueChangedFromOutside(newValue: any): void {
      // Convert to string
      let strVal = (newValue || '').toString();

      // If the value was set externally to zero (or a value for which
      // parseInt returns 0), we want the internal text field to be blank
      // instead of the number 0.
      if (strVal === '0' || parseInt(strVal, 10) === 0 || strVal === '') {
        strVal = '';
        this.$emit('input', 0);
      }

      this.internalValue = strVal;
    },
  },
});
