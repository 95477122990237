













import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { Charge } from '@/vuex/court/charge';
import { Participant } from '@/vuex/court/participant';

import ChargesForm from '@/components/charges/ChargesForm.vue';
import ParticipantName from '@/components/court/participant/ParticipantName.vue';

export default Vue.extend({
  props: {
    value: Array,
    dossierID: Number,
    caseID: Number,
  },

  created() {
    this.resetInternalParticipants();
  },

  data() {
    return {
      participants: [] as Participant[],
    };
  },

  computed: {
    ...mapGetters('court', ['defendantsForCase', 'defendantsForDossier']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['attorneyTypes', 'partyTypes']),

    /**
     * dispositionTypeIDs exists so that it can be watched. We need to trigger
     * a data-binding update when any of the charge dispositions are changed.
     * This outputs the raw IDs so that a deep watch doesn't need to be added
     * on the entire participants array.
     */
    dispositionTypeIDs(): number[] {
      return _.chain(this.participants || [])
        .map((p: Participant) => p.charges || [])
        .flatten()
        .map((c: Charge) => c.dispositionTypeID)
        .value();
    },
  },

  watch: {
    caseID(newID: number) {
      this.resetInternalParticipants();
    },
    dossierID(newID: number) {
      this.resetInternalParticipants();
    },
    dispositionTypeIDs(): void {
      this.$emit('input', this.participants);
    },
  },

  methods: {
    /**
     * resetInternalParticipants deletes and re-creates the internal array
     * of Participant in the form. This is called when the component is
     * initialized, and any time the CaseID or DossierID are changed.
     * Important to note that this function causes data loss. Charges which
     * have been changed will be lost when this occurs. Presumably that's
     * OK and even desirable because the Charge[] would be incorrect if it
     * was related to a different caseID or dossierID.
     */
    resetInternalParticipants(): void {
      if (this.caseID) {
        this.participants = [...this.defendantsForCase(this.caseID)];
      } else if (this.dossierID) {
        this.participants = [...this.defendantsForDossier(this.dossierID)];
      } else {
        this.participants = [];
      }
    },
  },

  components: {
    ChargesForm,
    ParticipantName,
  },
});
