














import Vue from 'vue';
import { PunishmentType } from '@/vuex/crime/crime';

import crimeAPI from '@/vuex/crime/crimeAPI';

import PunishmentTypeForm from '@/components/crime/punishmentType/PunishmentTypeForm.vue';

export default Vue.extend({
  data(): any {
    return {
      isOpen: false,
      punishmentType: null as null | PunishmentType,
      error: null as any,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.punishmentType = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        await crimeAPI.post('punishment-types/create', {
          data: {
            name: this.punishmentType.name,
            categoryID: this.punishmentType.categoryID,
            rank: this.punishmentType.rank,
            isATI: this.punishmentType.isATI,
            isImprisonment: this.punishmentType.isImprisonment,
            isConsequential: this.punishmentType.isConsequential,
            canHaveAmount: this.punishmentType.canHaveAmount,
            requiresAmount: this.punishmentType.requiresAmount,
            canHaveDuration: this.punishmentType.canHaveDuration,
            requiresDuration: this.punishmentType.requiresDuration,
            canReferenceSubjectProperty:
              this.punishmentType.canReferenceSubjectProperty,
            requiresNotes: this.punishmentType.requiresNotes,
            trackFulfillment: this.punishmentType.trackFulfillment,
          },
        });

        // Update Vuex
        this.$store.dispatch('crime/fetchOptions', { force: true });

        // Close the Dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    PunishmentTypeForm,
  },
});
