


























import Vue from 'vue';

export default Vue.extend({
  computed: {
    url(): string {
      return this.$route.fullPath;
    },
  },
  methods: {
    back() {
      history.back();
    },
    home() {
      this.$router.push({ name: 'home' });
    },
  },
});
