var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.results.length > 1)?_c('v-expansion-panel',[(_vm.mostCommonType)?_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t(_vm.mostCommonType + 'Summary'))+" ")]):_vm._e(),_c('v-expansion-panel-content',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}}):_vm._e(),_c('FormError',{attrs:{"error":_vm.error}}),_c('v-data-table',{attrs:{"dense":"","items":_vm.results,"loading":_vm.loading,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('router-link',{attrs:{"to":{
                name: 'offenderDashboard',
                query: { facilityLocationID: item.locationID },
              }}},[_c('GeographyName',{attrs:{"id":item.locationID}})],1)],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria, {facilityLocationID: item.locationID}),
              }}},[_vm._v(" "+_vm._s(item.headcount)+" ")])],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria,
                  {facilityLocationID: item.locationID,
                  isDetainee: true}),
              }}},[_vm._v(" "+_vm._s(item.detaineeCount)+" ")])],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria,
                  {facilityLocationID: item.locationID,
                  isDetainee: false}),
              }}},[_vm._v(" "+_vm._s(item.prisonerCount)+" ")])],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria,
                  {facilityLocationID: item.locationID,
                  isJuvenile: false}),
              }}},[_vm._v(" "+_vm._s(item.adultCount)+" ")])],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria,
                  {facilityLocationID: item.locationID,
                  isJuvenile: true}),
              }}},[_vm._v(" "+_vm._s(item.juvenileCount)+" ")])],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria,
                  {facilityLocationID: item.locationID,
                  pastDue: true}),
              }}},[_vm._v(" "+_vm._s(item.pastDueCount)+" ")])],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria,
                  {facilityLocationID: item.locationID,
                  nearingRelease: true}),
              }}},[_vm._v(" "+_vm._s(item.nearingReleaseCount)+" ")])],1),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'offenderSearch',
                query: Object.assign({}, _vm.criteria,
                  {facilityLocationID: item.locationID,
                  imminentRelease: true}),
              }}},[_vm._v(" "+_vm._s(item.imminentReleaseCount)+" ")])],1)])]}}],null,false,3863434436)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }