import _ from 'lodash';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import i18n from '@/i18n';
import store from '@/vuex/store';

const API_BASE = process.env.VUE_APP_API_BASE || '/api/v1/';

/*******************************************************************************
 * addAuthInterceptors intercepts requests and responses for authorization
 * purposes.
 *
 * For requests, it adds a JWT Bearer token to the Authorization header.
 * For responses, it catches auth-related failures and updates local auth
 * state to reflect the failure.
 *
 */
export function addAuthInterceptors(axios: AxiosInstance): void {
  axios.interceptors.request.use(authRequestInterceptor);

  axios.interceptors.response.use(
    // Any 2XX status codes hit this code path. We don't modify these
    // responses, so we just return the original response.
    (response) => {
      return response;
    },
    // Any non-2XX status codes hit this code path. We catch certain
    // errors and process them globally.
    authorizationResponseErrorInterceptor,
  );
}

/*******************************************************************************
 * authorizationRequestInterceptor is an Axios interceptor which adds an
 * Authorization header with a Bearer token taken from the auth Vuex store.
 * To avoid failures parsing a blank token server-side, no header is added (i.e
 * this interceptor does nothing) if the auth store does not have a token.
 *
 */
export function authRequestInterceptor(
  config: AxiosRequestConfig,
): AxiosRequestConfig {
  const token = store.state.auth.token;
  if (token) {
    config.headers = config.headers || {}; // Ensure config.headers is defined
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
}

/*******************************************************************************
 * authorizationResponseErrorInterceptor is an Axios response interceptor which
 * catches response errors which indicate an authorization failure and updates
 * the Vuex store to reflect them.
 */
export function authorizationResponseErrorInterceptor(error: any): any {
  if (error.response) {
    // 401 - Unauthorized Response, Login Required
    if (error.response.status === 401) {
      const resp = error.response;

      // Detect the authentication error format and update the store's state
      // to indicate to the user what the error was.
      if (resp.data && resp.data.errors && resp.data.errors.length > 0) {
        // Return the whole error object to the state, since the FormError component
        // handles/translates these properly.
        store.commit('auth/setError', error);
      }

      // TODO - Potentially too aggressive. It's fine when 401 Unauthorized
      // is only returned by login, but eventually we might have a permissions
      // mismatch, where we let a user *attempt* to make an API request that the
      // server rejects with a 401 Unauthorized response... we don't really need
      // to log the user out in that circumstance.
      store.dispatch('auth/logout');
    }

    // 403 - Forbidden, Re-Logging in won't help
    if (error.response.status === 403) {
      const code = _.get(error, 'response.data.errors[0].code', 'Forbidden');
      const msg = i18n.t(code);
      if (window && window.location && window.location.pathname !== '/login') {
        store.dispatch('application/showSnackbarError', msg);
      }
    }
  }

  // Pass the failure on to the calling function.
  return Promise.reject(error);
}

/*******************************************************************************
 * Export the default Axios instance using the standard interceptors.
 */
const api = axios.create({ baseURL: `${API_BASE}` });
addAuthInterceptors(api);
export default api;
