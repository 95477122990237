






























import _ from 'lodash';
import Vue from 'vue';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      results: [] as any[],
    };
  },
  computed: {
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const url = `/offenders/by-custody-status?${this.queryString}`;
        const response = await offenderAPI.get(url);
        const rawResults = _.get(response, 'data.data', []);

        // Offenders with 'Unknown' Custody Status are relics of bad data from
        // CMS 1.0. We exclude these from the Dashboard aggregate on purpose
        // to avoid confusing/distracting the users of CMS 2.0
        this.results = _.reject(rawResults, { custodyStatus: 'unknown' });
      } finally {
        this.loading = false;
      }
    },
  },
});
