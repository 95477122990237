






































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { LookupTableRow } from '@/vuex/language/language';
import { blankTranslatedString } from '@/lib/translated';
import translationAPI from '@/vuex/language/translationAPI';

export default Vue.extend({
  props: {
    table: {
      type: Object,
      required: true,
    },
  },

  data(): any {
    return {
      isOpen: false,
      error: null as any,

      row: {
        table: '',
        type: '',
        rank: 0,
        name: blankTranslatedString(),
      } as LookupTableRow,
    };
  },

  computed: {
    ...mapGetters('language', ['languages']),

    /**
     * showRank computes whether a field should be shown to allow editing the
     * rank property based on whether the selected table supports it.
     */
    showRank(): boolean {
      return this.table && this.table.hasRank;
    },

    /**
     * showType computes whether a field shold be shown to allow ediitng the
     * type property based on whether the selected table supports it.
     */
    showType(): boolean {
      return this.table && this.table.hasType;
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state, which
     * in this case is to reset the row object to a blank one.
     */
    reset(): void {
      this.row = {
        table: this.table.name,
        type: '',
        rank: 0,
        name: blankTranslatedString(),
      };
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      const newRow = {
        table: this.row.table,
        type: this.row.type,
        name: this.row.name,
        rank: this.row.rank,
      };

      try {
        await translationAPI.post('lookup-table-rows/add', { data: newRow });

        /**
         * Since some LookupTableRows are also options for various Vuex states
         * and they are stored as fixed arrays, we need to trigger re-fetch
         * of the options when a new row is added. This is quite inefficient,
         * but should happen very rarely.
         */
        this.$store.dispatch('user/fetchOptions');
        this.$store.dispatch('crime/fetchOptions');
        this.$store.dispatch('crime/fetchCrimes');
        this.$store.dispatch('offender/fetchOptions');
        this.$store.dispatch('profile/fetchOptions');
        this.$store.dispatch('court/fetchOptions');

        // Notify the parent of the new data
        this.$emit('saveCompleted', newRow);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
