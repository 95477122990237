























import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Department } from '@/vuex/investigation/department';

export default Vue.extend({
  props: {
    id: Number,
    to: Object,
    departmentParamName: {
      type: String,
      default: 'departmentID',
    },
  },
  computed: {
    ...mapGetters('investigation', ['departmentAncestors']),
    ...mapGetters('auth', ['hasPermission']),

    ancestors(): Department[] {
      return this.departmentAncestors(this.id) || [];
    },

    items(): any[] {
      const items = _.map(this.ancestors, (dept) => {
        const item: any = Object.assign({}, dept);
        if (this.to && this.id !== item.id) {
          item.to = _.cloneDeep(this.to);
          _.set(item, `to.query.${this.departmentParamName}`, dept.id);
        }
        return item;
      });

      const rootItem = { to: _.cloneDeep(this.to) };
      _.set(rootItem, `to.query.${this.departmentParamName}`, 0);
      items.splice(0, 0, rootItem);
      return items;
    },
  },
});
