




























































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import profileAPI from '@/vuex/profile/profileAPI';
import {
  changedCriteria,
  criteriaToQueryString,
  routeToCriteria,
} from '@/lib/criteria';

import AddProfileDialog from '@/components/profile/AddProfileDialog.vue';
import ProfileDetailsCard from '@/components/profile/ProfileDetailsCard.vue';
import ProfileIdentityCard from '@/components/profile/ProfileIdentityCard.vue';

export default Vue.extend({
  props: {
    /**
     * dense triggers a more condensed design for the component, which is useful
     * when it appears in a popup or other compressed space.
     */
    dense: Boolean,
  },

  created() {
    // Load the initial data...
    this.fetchData();
    // ...then make every future call to this.fetchData be debounced so that
    // it can never be called faster than once every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,
      advancedSearch: false,

      initialCriteria: {},
      criteria: {
        q: '',
        name: '',
        surname: '',
        fatherName: '',
        grandfatherName: '',
        alias: '',
        genderID: 0,
        birthplaceGeographyID: 0,
        residenceGeographyID: 0,
        minAge: 0,
        maxAge: 0,
        hairColorID: 0,
        eyeColorID: 0,
      },

      results: [] as any[],

      page: {
        number: 1,
        size: 10,
        serverCount: 0,
      },
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('auth', ['hasPermission']),

    items(): any[] {
      return _.map(this.results, (row) => {
        return Object.assign({}, row, {
          name: `${row.name} ${row.surname}`,
          fatherName: `${row.fatherName}, ${row.grandfatherName}`,
          identification: `${row.nationalID} ${row.eNationalID}`,
        });
      });
    },

    /**
     * criteriaUnchanged returns true when the currently-active criteria
     * is unchanged from its original, default state on the form.
     */
    criteriaUnchanged(): boolean {
      return _.isEqual(this.criteria, this.initialCriteria);
    },

    /**
     * queryString computes the URL querystring parameters which will be
     * submitted to the server to perform the search. NOTE that this
     * value is combined with pagination criteria inside fetchData, so this
     * value doesn't exactly match the API request.
     */
    queryString(): string {
      if (_.isEmpty(this.initialCriteria)) {
        this.initialCriteria = _.cloneDeep(this.criteria);
      }
      const cc = changedCriteria(this.criteria, this.initialCriteria);
      return criteriaToQueryString(cc, this.page);
    },

    /**
     * headers dynamically builds the headers array so that translations for
     * the column titles can update live, and so that the edit link column is
     * removed *automatically when the selectable prop is set
     */
    headers(): any[] {
      const headers: any[] = [
        { text: i18n.t('person.name'), value: 'name' },
        {
          text: i18n.t('person.age'),
          value: 'birthdate',
        },
        {
          text: i18n.t('person.fatherName'),
          value: 'fatherName',
        },
        {
          text:
            i18n.t('person.nationalID') + ' / ' + i18n.t('person.eNationalID'),
          value: 'identification',
        },
        {
          text:
            i18n.t('person.birthplace') + ' / ' + i18n.t('person.residence'),
          value: 'birthplaceAndResidence',
        },
        {
          text: i18n.t('dossier.plural'),
          value: 'totalDossierCount',
          align: 'right',
        },
      ];
      return headers;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (_.isEmpty(this.initialCriteria)) {
          this.initialCriteria = _.cloneDeep(this.criteria);
        }
        routeToCriteria(this.$route, this.criteria, this.page);
        if (!this.criteriaUnchanged && this.criteria.q === '') {
          this.advancedSearch = true;
        }
      },
    },

    /**
     * queryString is watched so that a new search can be triggered.
     */
    queryString(newVal: string, oldVal: string) {
      this.fetchData();
    },

    /**
     * criteria is watched so that when the search criteria is changed after
     * results have already been returned, we can reset the page number.
     */
    criteria: {
      deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        if (this.page.serverCount > 0 && this.page.number > 1) {
          this.page.number = 1;
        }
      },
    },

    /**
     * advancedSearch changes the UI, so we need to make sure to reset
     * the no longer visible fields
     */
    advancedSearch(isAdvanced: boolean) {
      if (isAdvanced) {
        this.resetSimpleCriteria();
      } else {
        this.resetAdvancedCriteria();
      }
    },
  },

  methods: {
    /**
     * queryChanged listens for changes in the currently-active querystring
     * (including pagination parameters)
     */
    queryChanged(query: string): void {
      if (query !== '') {
        query = `?${encodeURI(query)}`;
      }
      const newPath = `${this.$route.path}${query}`;
      if (this.$route.fullPath !== newPath) {
        this.$router.replace(newPath);
      }
    },

    /**
     * reset clears the search and resets search results. Primarily designed
     * to be called externally via a $ref to this component so that the searcher
     * can be reset when a dialog is opened.
     */
    reset(): void {
      this.$nextTick(() => {
        this.resetSimpleCriteria();
        this.resetAdvancedCriteria();

        if (this.$refs.query) {
          (this.$refs.query as any).focus();
        }
      });
    },

    resetSimpleCriteria(): void {
      this.criteria.q = '';
    },

    resetAdvancedCriteria(): void {
      this.criteria = Object.assign(this.criteria, this.initialCriteria);
    },

    /**
     * fetchData performs the actual search. It hits the API with the active
     * query and pagination criteria and updates the locally-held search results
     * data.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;

      try {
        const url = `/background-check?${this.queryString}`;
        const resp = await profileAPI.get(url);
        this.page.serverCount = _.get(resp, 'data.meta.totalResults', 0);
        this.results = _.get(resp, 'data.data', []);
        this.queryChanged(this.queryString);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    AddProfileDialog,
    ProfileDetailsCard,
    ProfileIdentityCard,
  },
});
