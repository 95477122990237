





















import Vue from 'vue';

import SubjectPropertyExpander from '@/components/subjectProperty/SubjectPropertyExpander.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    payload(): any {
      return this.entry.payload;
    },
  },
  components: {
    SubjectPropertyExpander,
  },
});
