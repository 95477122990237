import _ from 'lodash';
import { TranslatedString } from '@/lib/translated';

export interface Charge {
  id?: number;
  dossierID?: number;
  caseID?: number;
  partyID?: number;
  crimeID: number;
  participationTypeID: number;
  completionStatusID: number;
  crimeSeverityID: number;
  aggravators?: TranslatedString[];
  mitigators?: TranslatedString[];
  generalAggravatorIDs?: number[];
  generalMitigatorIDs?: number[];
  dispositionTypeID: number;
}

/**
 * ChargeDispositionTypeID is an enum which mirrors the data in the
 * backend charge_disposition_types database. It exists to allow client-side
 * dynamic behaviors triggered by certain disposition types.
 *
 * Each Charge introduced by a Prosecutor needs to be "disposed" at some point
 * (i.e. it needs to reach a resolution). Some dispositions are reached because
 * of actions of the prosecutor, such as NolleProsequi, which means the
 * Prosecutor decided not to prosecute the charge. Others are reached because
 * of the actions of a Judge, such as JudgeDismissed, Acquitted, or Guilty.
 */
export const enum ChargeDispositionTypeID {
  Unknown = 0,
  Guilty = 1,
  Acquitted = 2,
  JudgeDismissed = 3,
  NolleProsequi = 4,
}

/**
 * GuiltyChargeDispositionTypeIDs is a convenience constant which can be
 * referenced in client-side decision making around only those charges which
 * a Judge found the Defendant guilty.
 */
export const GuiltyChargeDispositionTypeIDs = [ChargeDispositionTypeID.Guilty];

/**
 * ActiveChargeDispositionTypeIDs is a convenience constant which can be
 * referenced when Guilty or "potentially Guilty" charges are desired.
 */
export const ActiveChargeDispositionTypeIDs = [
  ChargeDispositionTypeID.Unknown,
  ChargeDispositionTypeID.Guilty,
];

/**
 * NotGuiltyChargeDispositionTypeIDs is a convenience constant which can be
 * referenced in client-side decision making around only those charges which
 * are resolved in the Defendant not being guilty of the charge.
 */
export const NotGuiltyChargeDispositionTypeIDs = [
  ChargeDispositionTypeID.Acquitted,
  ChargeDispositionTypeID.JudgeDismissed,
  ChargeDispositionTypeID.NolleProsequi,
];

/**
 * JudgeDispositionTypeIDs is a convenience constant which holds the Charge
 * disposition types which can only be set by a Judge. This is intended to be
 * referenced so that Prosecutors get these types excluded on screens which
 * with they interact.
 */
export const JudgeDispositionTypeIDs = [
  ChargeDispositionTypeID.Guilty,
  ChargeDispositionTypeID.Acquitted,
  ChargeDispositionTypeID.JudgeDismissed,
];