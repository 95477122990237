var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"value":_vm.value,"label":_vm.label ||
    (this.multiple
      ? _vm.$t('punishmentType.plural')
      : _vm.$t('punishmentType.singular')),"items":_vm.punishmentTypeChoices,"rules":_vm.rules,"chips":_vm.chips,"multiple":_vm.multiple,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(this.validTypeIDs)?{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:{
        AllowedPunishmentType: _vm.isValidTypeID(item.value),
        WarningPunishmentType: !_vm.isValidTypeID(item.value),
      }},[_vm._v(" "+_vm._s(item.text)+" ")])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }