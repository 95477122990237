






































































































































































































































































































































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Case } from '@/vuex/court/case';
import { Dossier } from '@/vuex/court/dossier';
import { CourtFeeTransaction, CustomField } from '@/vuex/court/options';

import CivilDecisionForm from '@/components/court/decision/CivilDecisionForm.vue';
import CourtSelector from '@/components/court/court/CourtSelector.vue';
import CriminalDecisionForm from '@/components/court/decision/CriminalDecisionForm.vue';
import DefendantCharges from '@/components/charges/DefendantCharges.vue';
import DossierCaseSelector from '@/components/dossier/DossierCaseSelector.vue';
import DossierLookupField from '@/components/dossier/DossierLookupField.vue';
import FeeAssessmentForm from '@/components/court/fee/FeeAssessmentForm.vue';
import FeePaymentForm from '@/components/court/fee/FeePaymentForm.vue';
import HeadOfCourtSelector from '@/components/court/judge/HeadOfCourtSelector.vue';
import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';
import ParticipantDecisionEditor from '@/components/court/ParticipantDecisionEditor.vue';
import ParticipantForm from '@/components/court/participant/ParticipantForm.vue';
import PartySelector from '@/components/court/party/PartySelector.vue';
import SubjectPropertyEditor from '@/components/subjectProperty/SubjectPropertyEditor.vue';
import SubjectPropertiesForm from '@/components/subjectProperty/SubjectPropertiesForm.vue';
import SubjectPropertyForm from '@/components/subjectProperty/SubjectPropertyForm.vue';
import SubjectPropertySelector from '@/components/subjectProperty/SubjectPropertySelector.vue';
import SummonsForm from '@/components/court/summons/SummonsForm.vue';

export default Vue.extend({
  props: {
    value: Boolean,
    caseID: Number,
    dossierID: Number,
    preselectedDocketEventTypeID: Number,
  },

  data() {
    return {
      error: null as any,
      isOpen: false,

      filedAt: moment().format(),
      docketEventType: this.preselectedDocketEventTypeID,
      departmentID: 0,
      awarenessNumber: '',
      notes: '',
      fieldData: {} as any,
      dossierIsFinal: false,
      finalCaseID: 0,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection', 'valueForLocale']),
    ...mapState('court', ['docketEventTypes', 'feeCategories']),
    ...mapGetters('court', [
      'caseWithID',
      'dossierWithID',
      'settlementValueInPulsForCase',
      'subjectPropertyTotalValueInPulsForDossier',
    ]),

    dossier(): Dossier {
      return this.dossierWithID(this.dossierID);
    },

    kase(): Case {
      return this.caseWithID(this.caseID);
    },

    caseType(): string {
      if (this.dossier) {
        return this.dossier.type;
      }
      return '';
    },

    courtStageID(): number {
      if (this.kase) {
        return this.kase.stageID;
      }
      return 0;
    },

    docketEventTypeItems(): any {
      return _.orderBy(this.docketEventTypes, ['name.' + this.locale], ['asc']);
    },

    selectedDocketEventType(): any {
      return _.find(this.docketEventTypes, { id: this.docketEventType });
    },

    willChangeDepartment(): boolean {
      if (!this.selectedDocketEventType) {
        return false;
      }
      return this.selectedDocketEventType.willChangeDepartment;
    },

    willFinalizeDossier(): boolean {
      if (!this.selectedDocketEventType) {
        return false;
      }
      return this.selectedDocketEventType.willFinalizeDossier;
    },

    willSetAwarenessNumber(): boolean {
      if (!this.selectedDocketEventType) {
        return false;
      }
      return this.selectedDocketEventType.willSetAwarenessNumber;
    },

    customFields(): any {
      if (this.selectedDocketEventType) {
        return this.selectedDocketEventType.fields;
      }
      return [];
    },

    courtID(): number {
      if (this.kase) {
        return this.kase.courtID;
      }
      return 0;
    },

    preselectedType(): any {
      return _.find(this.docketEventTypes, {
        id: this.preselectedDocketEventTypeID,
      });
    },
  },

  watch: {
    isOpen(val) {
      this.$emit('input', val);
    },

    value(val) {
      this.isOpen = val;
    },

    preselectedDocketEventTypeID(val) {
      this.docketEventType = val;
    },

    willFinalizeDossier(willFinalize: boolean) {
      if (willFinalize) {
        this.dossierIsFinal = true;
      }
    },

    dossierIsFinal(nowFinal: boolean) {
      if (!nowFinal) {
        this.finalCaseID = 0;
      }
    },
  },

  methods: {
    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      // Send the API call
      try {
        const response = await courtAPI.post('cases/create-docket-event', {
          data: {
            caseID: this.caseID,
            dossierID: this.dossierID,
            filedAt: this.filedAt,
            typeID: this.docketEventType,
            departmentID: this.departmentID,
            awarenessNumber: this.awarenessNumber,
            notes: this.notes,
            dossierIsFinal: this.dossierIsFinal,
            finalCaseID: this.finalCaseID,
            fieldData: this.fieldData,
          },
        });

        // Update the State
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);

        // Attach the attachments
        const logEntry = response.data.logEntry;
        if (logEntry) {
          const newID = logEntry.id;
          (this.$refs.attachments as any).updateEntityID(newID);
        }

        // Add to the docket
        this.$store.commit('court/addDocketEntry', response);

        // Re-index CivilDecisions if they were added to the state
        if (this.hasFieldOfType(['CivilDecision'])) {
          this.$store.commit('court/reindexCivilDecision', this.caseID);
        }

        // Re-index Participants if they were added to the state
        if (this.hasFieldOfType(['AddParticipant'])) {
          if (this.caseID) {
            this.$store.commit('court/reindexCaseParticipants', this.caseID);
          } else {
            this.$store.commit(
              'court/reindexDossierParticipants',
              this.dossierID,
            );
          }
        }

        // Re-index Subject Properties if they were added to the state
        if (
          this.hasFieldOfType(['AddSubjectProperty', 'AddSubjectProperties'])
        ) {
          this.$store.commit(
            'court/reindexSubjectPropertiesForDossier',
            this.dossierID,
          );
        }

        // Close the Dialog
        this.close();
      } catch (error) {
        this.error = error;
      }
    },

    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.filedAt = moment().format();
      this.docketEventType = this.preselectedDocketEventTypeID || 0;
      this.notes = '';
      if (this.dossier) {
        this.departmentID = this.dossier.lastDepartmentID;
      } else {
        this.departmentID = 0;
      }
      this.awarenessNumber = '';
      this.fieldData = {};
      this.finalCaseID = 0;
      this.dossierIsFinal = false;
    },

    close(): void {
      this.isOpen = false;
    },

    /**
     * hasFieldOfType returns true if this docket event has data for a field
     * of the requested type.
     */
    hasFieldOfType(desiredType: string | string[]): boolean {
      const allTypes: string[] = _.map(this.customFields, 'type');
      const desiredTypes = _.flatten([desiredType]);
      const matches = _.intersection(allTypes, desiredTypes);
      return matches.length > 0;
    },
  },
  components: {
    CourtSelector,
    CivilDecisionForm,
    CriminalDecisionForm,
    DefendantCharges,
    DossierCaseSelector,
    DossierLookupField,
    FeeAssessmentForm,
    FeePaymentForm,
    HeadOfCourtSelector,
    JudgeSelector,
    ParticipantForm,
    ParticipantDecisionEditor,
    PartySelector,
    SubjectPropertyEditor,
    SubjectPropertiesForm,
    SubjectPropertyForm,
    SubjectPropertySelector,
    SummonsForm,
  },
});
