













































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { LookupItem } from '@/vuex/court/options';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['hearingTypes']),
    ...mapGetters('court', ['courtroomName']),
    payload(): any {
      if (!this.entry) {
        return null;
      }
      return this.entry.payload;
    },
    hearingType(): LookupItem | null {
      if (!this.payload) {
        return null;
      }
      return this.nameForIDInCollection(this.payload.typeID, this.hearingTypes);
    },
    nameForCourtroom(): string {
      return this.courtroomName(this.payload.courtroomID);
    },
  },
});
