

















import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  created() {
    if (this.$vuetify) {
      this.$vuetify.rtl = this.languageIsRTL;
    }
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['languages', 'languageIsRTL']),
  },

  methods: {
    userChangedLanguage(newLocale: string): void {
      this.$store.commit('language/setLocale', newLocale);

      if (this.$vuetify) {
        this.$vuetify.rtl = this.languageIsRTL;
      }
    },
  },
});
