























import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';

import { mapGetters, mapState } from 'vuex';
import { Department } from '@/vuex/investigation/department';
import { VSelectItem } from '@/lib/vue-typescript';
import { TAll, TNone, TUnknown } from '@/lib/translated';

export default Vue.extend({
  props: {
    label: String,
    value: Number,
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    clearable: Boolean,
    /**
     * requireLeafNode triggers a validator to force the user to select a
     * fully-qualified Department: one with no children below it.
     */
    requireLeafNode: Boolean,

    /**
     * zeroLabel controls how the "blank" or "zero" entry is labeled. Valid
     * choices are 'all', 'unknown'.
     */
    zeroLabel: String,
  },
  data() {
    return {
      loading: false,
      internalValues: [0],
    };
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('investigation', [
      'departmentWithID',
      'departmentAncestorIDs',
      'departmentName',
      'departmentsUnder',
    ]),

    /**
     * computedRules combines the user-provided rules prop with rules triggered
     * by other factors.
     */
    computedRules(): any[] {
      const rules: any[] = this.rules || [];
      if (this.requireLeafNode) {
        rules.push(this.mustBeLeafNode);
      }
      return rules;
    },

    /**
     * department provides convenient access to the Department entity which
     * is currently selected (the rightmost dropdown selection)
     */
    department(): Department | null {
      return this.departmentWithID(this.value) || null;
    },

    /**
     * dropdowns is the heart of this component. It builds an array of
     * VSelectItem[], with each one representing the choices which should be
     * in a Dropdown control presented to the user.
     */
    dropdowns(): VSelectItem[][] {
      return _.chain(this.internalValues)
        .map((id: number, index: number) => {
          const priorID = _.get(this.internalValues, index - 1);
          let parentID = id;
          const department = this.departmentWithID(id);
          if (department && priorID !== id) {
            parentID = department.parentID;
          }
          const choices = _.chain(this.departmentsUnder(parentID))
            .map((dept) => ({
              text: `${dept.name[this.locale]}`,
              value: dept.id,
              rank: dept.rank || 999999,
            }))
            .orderBy(['rank', 'text'])
            .value();
          if (!_.includes(_.map(choices, 'value'), id)) {
            choices.unshift({ text: this.zeroText, value: id, rank: 0 });
          } else {
            choices.unshift({
              text: this.zeroText,
              value: parentID,
              rank: 0,
            });
          }
          return !!choices.length ? choices : null;
        })
        .compact()
        .value();
    },

    /**
     * zeroText computes the text property of the dropdown entry which is the
     * "zero" value, which at the top level represents no Department selected,
     * and at lower levels means the parent is selected with available children,
     * but no child is yet selected. This function outputs translated values
     * of "All" or "None" depending on the setting of the zeroLabel prop.
     */
    zeroText(): string {
      if (this.zeroLabel === 'all') {
        return TAll[this.locale].toString();
      } else if (this.zeroLabel === 'none') {
        return TNone[this.locale].toString();
      }
      return TUnknown[this.locale].toString();
    },

    /**
     * cols computes the number of columns each dropdown should occupy. It is
     * determined based on the number of dropdowns which are needed.
     */
    cols(): number {
      const count = this.dropdowns.length;
      if (count < 2) {
        return 12;
      }
      if (count % 2 === 0 && count < 4) {
        return 12 / count;
      }
      return 4;
    },
  },
  watch: {
    /**
     * value is watched so that the internalValue can be updated to represent
     * the new externally-provided value.
     */
    value: {
      immediate: true,
      handler(newVal: number) {
        const ids = this.departmentAncestorIDs(newVal);
        const department = this.departmentWithID(newVal);

        if (!!department && department.childCount > 0) {
          // Add a second copy of the chosen id to occupy the
          // Unknown entry of its children.
          ids.push(newVal);
        }
        Vue.set(this, 'internalValues', ids);
      },
    },
  },
  methods: {
    /**
     * mustBeLeafNode is a validator function which ensures that the selected
     * Department is a "leaf" node (one with no children beneath it).
     * Returns true if the validation passes, and an error string otherwise.
     */
    mustBeLeafNode(v: any): boolean | string {
      if (!this.department) {
        // Not having a department means we can't verify it's a leaf node,
        // but that doesn't make it an error state.
        return true;
      }
      if (this.department.childCount && this.department.childCount > 0) {
        // Children found under the selected item
        return i18n.t('error.required').toString();
      }
      return true;
    },

    /**
     * dropdownChanged is the event handler for the @change event on all
     * dropdowns.
     */
    dropdownChanged(i: number, newVal: number): void {
      if (i > 0 && !newVal) {
        this.$emit('input', this.internalValues[i - 1] || 0);
        return;
      }
      this.$emit('input', newVal || 0);
    },
  },
});
