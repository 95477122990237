















import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';
import { AppealPartyTypeIDs, NonAppealPartyTypes } from '@/vuex/court/party';
import { PartyTypeID } from '@/vuex/court/party';
import { VSelectItem } from '@/lib/vue-typescript';
import { TAll } from '@/lib/translated';
import { CourtStageIDs } from '@/vuex/court/court';

export default Vue.extend({
  props: {
    value: Number,
    label: String,
    readonly: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    hideDetails: Boolean,
    singleLine: Boolean,
    rules: Array,
    caseType: String,
    courtStageID: Number,
    originalPartyTypeID: Number,
    includeUnknown: Boolean,
    zeroLabel: { type: String },
  },
  computed: {
    ...mapGetters('language', ['valueForLocale']),
    ...mapState('language', ['locale']),
    ...mapState('court', ['partyTypes']),

    /**
     * partyTypeChoices builds the choice values for the dropdown.
     */
    partyTypeChoices(): VSelectItem[] {
      let excludedPartyTypeIDs = [] as PartyTypeID[];
      if (!this.includeUnknown) {
        excludedPartyTypeIDs.push(PartyTypeID.Unknown);
      }

      // Prevent Parties which were originally Defendants from being changed
      // to any other "primary" party type.
      if (this.originalPartyTypeID === PartyTypeID.Defendant) {
        const invalidPrimaryTypes = _.difference(NonAppealPartyTypes, [
          PartyTypeID.Defendant,
        ]);
        excludedPartyTypeIDs = [
          ...excludedPartyTypeIDs,
          ...invalidPrimaryTypes,
        ];
      }

      // Prevent Parties which were originally Prosecutors from being changed
      // to any other "primary" party type.
      if (this.originalPartyTypeID === PartyTypeID.Prosecutor) {
        const invalidPrimaryTypes = _.difference(NonAppealPartyTypes, [
          PartyTypeID.Prosecutor,
        ]);
        excludedPartyTypeIDs = [
          ...excludedPartyTypeIDs,
          ...invalidPrimaryTypes,
        ];
      }

      // Exclude Apellate options in Primary court cases and in the investigation
      // docket when no courtStage is known
      if (
        this.courtStageID === CourtStageIDs.primary ||
        this.courtStageID === CourtStageIDs.unknown
      ) {
        excludedPartyTypeIDs = [...excludedPartyTypeIDs, ...AppealPartyTypeIDs];
      }

      // Exclude types that are invalid at the appeals stages
      if (
        this.courtStageID === CourtStageIDs.appellate ||
        this.courtStageID === CourtStageIDs.supreme ||
        this.courtStageID === CourtStageIDs.revision
      ) {
        excludedPartyTypeIDs = [
          ...excludedPartyTypeIDs,
          PartyTypeID.Petitioner,
          PartyTypeID.Respondent,
          PartyTypeID.CivilDefendant,
          PartyTypeID.Defendant,
          PartyTypeID.Prosecutor,
          PartyTypeID.Plaintiff,
        ];
      }

      // Exclude types that are invalid in criminal cases
      if (this.caseType === 'criminal') {
        excludedPartyTypeIDs = [
          ...excludedPartyTypeIDs,
          PartyTypeID.Petitioner,
          PartyTypeID.Respondent,
          PartyTypeID.CivilDefendant,
        ];
      }

      // Exclude types that are invalid in non-criminal cases
      if (this.caseType && this.caseType !== 'criminal') {
        excludedPartyTypeIDs = [
          ...excludedPartyTypeIDs,
          PartyTypeID.Defendant,
          PartyTypeID.Victim,
        ];
      }

      let choices = _.chain(this.partyTypes)
        .reject((pt) => _.includes(excludedPartyTypeIDs, pt.id))
        .map((pt) => ({
          value: pt.id,
          text: pt.name[this.locale],
        }))
        .value();

      // Override the Unknown/Zero Label if requested
      if (this.zeroLabel === 'all') {
        choices = _.map(choices, (c) => {
          if (c.value === 0) {
            c.text = this.valueForLocale(TAll);
          }
          return c;
        });
      }

      return choices;
    },

    /**
     * computedRules combines the rules prop value (if it exists) with a
     * hard-coded rule that flags choices which are invalid based on the
     * caseType and courtStage props.
     */
    computedRules(): any[] {
      const validIDs = _.map(this.partyTypeChoices, 'value');
      return [
        ...(this.rules || []),
        (v) => _.includes(validIDs, v) || i18n.t('error.invalidChoice'),
      ];
    },
  },
});
