
































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { criteriaToQueryString } from '@/lib/criteria';

import LookupTableMatrix from '@/components/global/formatting/LookupTableMatrix.vue';

/**
 * DisposedChargeCount defines the shape of the "long format" data which the
 * serverreturns. It is transformed into a "wide format" by the
 * LookupTableMatrix component.
 */
interface DisposedChargeCount {
  dispositionTypeID: number;
  crimeSeverityID: number;
  count: number;
}

export default Vue.extend({
  props: {
    criteria: Object,
  },

  data() {
    return {
      loading: false,
      error: null as any,

      disposedBetween: '',

      results: [] as DisposedChargeCount[],
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('crime', ['severities']),
    ...mapState('court', ['chargeDispositionTypes']),

    /**
     * fullCriteria builds the actual API criteria which will be sent. This is
     * done by combining the incoming criteria prop with customizations set
     * directly on this component.
     */
    fullCriteria(): any {
      const criteria = Object.assign({}, this.criteria);
      criteria.activeOnly = false; // TODO Do this?
      criteria.disposedBetween = this.disposedBetween;
      return criteria;
    },

    /**
     * queryString builds the URL querystring which will be sent to the API
     * endpoint. It exists as a standalone computed prop so that the watcher
     * can trigger only when the query has changed.
     */
    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      try {
        const url = `/charges/by-disposition-severity?${this.queryString}`;
        const response = await courtAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },
  },

  components: {
    LookupTableMatrix,
  },
});
