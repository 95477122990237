



























































































import Vue from 'vue';

import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { cloneDeep, tap, set } from 'lodash';

import { SubjectProperty } from '@/vuex/court/subjectProperty';
import { CivilDecision } from '@/vuex/court/civilDecision';
import { Participant } from '@/vuex/court/participant';
import { Case } from '@/vuex/court/case';

import SubjectPropertiesSummary from '@/components/subjectProperty/SubjectPropertiesSummary.vue';

export default Vue.extend({
  props: {
    value: {
      type: Object,
    },
    caseID: Number,
    dossierID: Number,
  },

  data() {
    return {
      isOpen: false,
      error: null,

      filedAt: '',
      decisionNumber: '',
      favorPartyIDs: [] as number[],
      againstPartyIDs: [] as number[],
      settlementValueInPuls: 0,
      verdictText: '',
      rulingsSelected: true,
    };
  },

  computed: {
    ...mapGetters('court', ['partiesForDossier']),
    ...mapState('court', ['partyTypes']),
    ...mapGetters('court', [
      'caseWithID',
      'subjectPropertyForDossier',
      'partyName',
      'primaryParticipantsForCase',
    ]),

    civilDecision(): CivilDecision {
      return this.value
        ? this.value
        : {
            id: 0,
            caseID: this.caseID,
            favorPartyIDs: [],
            againstPartyIDs: [],
            settlementValueInPuls: 0,
            settlementNotes: '',
            filedAt: '',
            registeredAt: '',
            decisionNumber: '',
            verdictText: '',
          };
    },
    kase(): Case {
      return this.caseWithID(this.caseID);
    },

    primaryParticipants(): Participant[] {
      return this.primaryParticipantsForCase(this.caseID);
    },

    subjectProperties(): SubjectProperty[] {
      return this.subjectPropertyForDossier(this.dossierID);
    },

    /**
     * partyInOneListOnly returns true only if there are no overlaps between
     * the list of
     */
    partyInOneListOnly(): boolean {
      return (
        _.intersection(this.favorPartyIDs, this.againstPartyIDs).length === 0
      );
    },
  },

  watch: {
    /**
     * value is watched so that the externally-bound v-model can be transferred
     * to its internal equivalent (isOpen).
     */
    value(val) {
      this.isOpen = val;
    },

    /**
     * isOpen is watched so that changes in the internal isOpen value can be
     * broadcast to the external v-model which to which this component is
     * bound.
     */
    isOpen(val) {
      this.$emit('input', val);
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.favorPartyIDs = [];
      this.againstPartyIDs = [];
      this.settlementValueInPuls = 0;
      this.decisionNumber = '';
      this.verdictText = '';
    },

    /**
     * update is the handler for most form fields on this form. It ensures that
     * any field which is change triggers the entire object to be re-built and
     * $emitted to consumers. This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     *
     * This particular one performs some "watch"-like behaviors to ensure
     * irrelevant fields get reset to their defaults when the Subject Property
     * typeID is changed.
     */
    update(key: string, value: any, modifier: string = ''): void {
      // -----------------------------------------------------------------------
      // Apply modifiers to the submitted value (if requested)
      let modifiedValue = value;

      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }

      // Build a new entity object including the changed field
      const newCivilDecision = tap(cloneDeep(this.civilDecision), (v) =>
        set(v, key, modifiedValue),
      );
      this.$emit('input', newCivilDecision);
    },
  },

  components: {
    SubjectPropertiesSummary,
  },
});
