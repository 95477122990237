import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import { TranslatedString, TUnknown } from '@/lib/translated';
import { domainmodelMutations, modelsToState } from '@/lib/vuex-domainmodel';

import {
  state as attorneyState,
  getters as attorneyGetters,
  mutations as attorneyMutations,
  actions as attorneyActions,
} from './attorney';
import {
  state as dossierState,
  getters as dossierGetters,
  mutations as dossierMutations,
  actions as dossierActions,
} from './dossier';
import {
  state as finePaymentState,
  getters as finePaymentGetters,
  mutations as finePaymentMutations,
  actions as finePaymentActions,
} from './finePayment';
import {
  state as hearingState,
  getters as hearingGetters,
  mutations as hearingMutations,
  actions as hearingActions,
} from './hearing';
import {
  state as holidayState,
  getters as holidayGetters,
  mutations as holidayMutations,
  actions as holidayActions,
} from './holiday';
import {
  state as judgesState,
  getters as judgesGetters,
  mutations as judgesMutations,
  actions as judgesActions,
} from './judge';
import {
  state as caseJudgeState,
  getters as caseJudgeGetters,
  mutations as caseJudgeMutations,
  actions as caseJudgeActions,
} from './caseJudge';
import {
  state as participantState,
  getters as participantGetters,
  mutations as participantMutations,
  actions as participantActions,
} from './participant';
import {
  state as partyState,
  getters as partyGetters,
  mutations as partyMutations,
  actions as partyActions,
} from './party';
import {
  state as optionsState,
  getters as optionsGetters,
  mutations as optionsMutations,
  actions as optionsActions,
} from './options';
import {
  state as subjectPropertyState,
  getters as subjectPropertyGetters,
  mutations as subjectPropertyMutations,
  actions as subjectPropertyActions,
} from './subjectProperty';
import {
  state as summonsState,
  getters as summonsGetters,
  mutations as summonsMutations,
  actions as summonsActions,
} from './summons';
import {
  state as caseState,
  getters as caseGetters,
  mutations as caseMutations,
  actions as caseActions,
} from './case';
import {
  state as docketState,
  getters as docketGetters,
  mutations as docketMutations,
  actions as docketActions,
} from './docket';
import {
  state as civilDecisionState,
  getters as civilDecisionGetters,
  mutations as civilDecisionMutations,
  actions as civilDecisionActions,
} from './civilDecision';
import {
  state as relatedDossierState,
  getters as relatedDossierGetters,
  mutations as relatedDossierMutations,
  actions as relatedDossierActions,
} from './relatedDossier';

export interface Court {
  id: number;
  parentID: number | null;
  rank: number;
  path: string;
  code: string;
  name: TranslatedString;
  locationGeographyID: number;
  stageIDs: number[];
  headOfCourtJudgeID: number;
  legacyDepartmentIDs: number[];
  year: number;
  lastCaseNumber: number;
  cms1Importance: number;
  cms1RecordCount: number;
  maxCaseDurationInDays: number;
  warnAfterDays: number;
  childCount?: number;
}

export interface Courtroom {
  id: number;
  name: string;
  courtID: number;
  isDeleted: boolean;
}

export class CourtStageIDs {
  public static readonly unknown = 0;
  public static readonly primary = 1;
  public static readonly appellate = 2;
  public static readonly supreme = 3;
  public static readonly revision = 4;
}

export const AllCourtStageIDs = [
  CourtStageIDs.primary,
  CourtStageIDs.appellate,
  CourtStageIDs.supreme,
  CourtStageIDs.revision,
];

const state = {
  ...attorneyState,
  ...caseJudgeState,
  ...caseState,
  ...civilDecisionState,
  ...docketState,
  ...dossierState,
  ...finePaymentState,
  ...hearingState,
  ...holidayState,
  ...judgesState,
  ...optionsState,
  ...participantState,
  ...partyState,
  ...subjectPropertyState,
  ...summonsState,
  ...relatedDossierState,

  court: {} as { [id: number]: Court },
  courtIDs: [] as number[],
  courtsLoaded: false,
  courtroom: {} as { [id: number]: Courtroom },
  courtroomIDsForCourt: {} as { [courtID: number]: number[] },
  courtChildIDs: {} as { [id: number]: number[] },
};

const getters = {
  ...attorneyGetters,
  ...caseGetters,
  ...caseJudgeGetters,
  ...civilDecisionGetters,
  ...docketGetters,
  ...dossierGetters,
  ...finePaymentGetters,
  ...hearingGetters,
  ...holidayGetters,
  ...judgesGetters,
  ...optionsGetters,
  ...participantGetters,
  ...partyGetters,
  ...subjectPropertyGetters,
  ...summonsGetters,
  ...relatedDossierGetters,

  allCourts(state): Court[] {
    return _.map(state.courtIDs, (id: number) => state.court[id]);
  },

  courtWithID:
    (state) =>
    (courtID: number): Court => {
      return state.court[courtID];
    },

  /**
   * courtName takes a CourtID and returns the translated name for that Court,
   * or the translated phrase Unknown: <id> if the courtID didn't match a
   * Court in the state.
   */
  courtName:
    (state) =>
    (id: number): string => {
      const court = state.court[id];
      if (!court) {
        return TUnknown[i18n.locale] + `: ${id}`;
      }
      return court.name[i18n.locale];
    },

  /**
   * fullCourtName takes a CourtID and returns its translated name along with
   * all of its ancestor's names. For example:
   *
   * Appellate :: Civil :: Badghis Appellate Court Civil Division
   */
  fullCourtName:
    (state, getters) =>
    (id: number): string => {
      const courts: Court[] = getters.courtAncestors(id);
      return _.map(courts, (c) => c.name[i18n.locale]).join(' :: ');
    },

  /**
   * courtroomsForCourt retrieves the list of Courtrooms which exist within
   * the provided courtID.
   */
  courtroomsForCourt:
    (state) =>
    (courtID: number): Courtroom[] => {
      const courtroomIDs = state.courtroomIDsForCourt[courtID];
      return _.map(courtroomIDs, (id: number) => state.courtroom[id]);
    },

  /**
   * courtroomWithID retrieves a single Courtroom given its ID.
   */
  courtroomWithID:
    (state) =>
    (courtroomID: number): Courtroom => {
      return state.courtroom[courtroomID];
    },

  /**
   * courtroomName returns the name of a Courtroom when given its ID
   */
  courtroomName:
    (state) =>
    (courtroomID: number): string => {
      const courtroom = state.courtroom[courtroomID];
      if (courtroom) {
        return courtroom.name;
      }
      return '';
    },

  courtChildCount:
    (state) =>
    (parentID: number): number => {
      const ids: number[] = state.courtChildIDs[parentID || 0] || [];
      return ids.length || 0;
    },

  /**
   * courtsUnder returns the array of Courts that reside
   * as children of the provided Court id.
   */
  courtsUnder:
    (state, getters) =>
    (parentID: number): Court[] => {
      const ids: number[] = state.courtChildIDs[parentID || 0] || [];
      return _.map(ids, (id: number): Court => state.court[id]);
    },

  /**
   * courtAncestorIDs builds an array of the Court IDs which are ancestors of
   * the submitted ID. The most distant ancestor will be first in the array
   * and the provided CourtID will be last in the array.
   */
  courtAncestorIDs:
    (state) =>
    (id: number): number[] => {
      const ids: number[] = [];
      let currID = id;
      while (state.court[currID]) {
        ids.unshift(currID);
        currID = state.court[currID].parentID;
      }
      if (ids.length === 0) {
        ids.unshift(0);
      }
      return ids;
    },

  /**
   * courtAncestors builds the array of a Court and its ancestors. The most
   * distant ancestor will be first in the array and Court matching the provided
   * ID will be last.
   */
  courtAncestors:
    (state, getters) =>
    (id: number): Court[] => {
      const ids = getters.courtAncestorIDs(id);
      return _.chain(ids)
        .map((id) => state.court[id])
        .compact()
        .value();
    },
};

const mutations = {
  ...domainmodelMutations,

  ...attorneyMutations,
  ...caseJudgeMutations,
  ...caseMutations,
  ...civilDecisionMutations,
  ...docketMutations,
  ...dossierMutations,
  ...finePaymentMutations,
  ...hearingMutations,
  ...holidayMutations,
  ...judgesMutations,
  ...optionsMutations,
  ...participantMutations,
  ...partyMutations,
  ...subjectPropertyMutations,
  ...summonsMutations,
  ...relatedDossierMutations,

  setCourtsLoaded(state, val: boolean) {
    state.courtsLoaded = val;
  },

  /**
   * calculateCourtChildStats is the mutation which should be called upon the
   * initial download of Court data, or when any Court structure is changed.
   * It pre-computes the childCount property for each Court to make it
   * efficient to figure out if a Court is a leaf node or not.
   */
  calculateCourtChildStats(state) {
    Vue.set(
      state,
      'courtIDs',
      _.chain(Object.values(state.court)).compact().map('id').value(),
    );
    Vue.set(state, 'courtChildIDs', {});

    for (const id of state.courtIDs) {
      const court = state.court[id];
      const parentID = court.parentID || 0;
      if (!state.courtChildIDs.hasOwnProperty(parentID)) {
        Vue.set(state.courtChildIDs, parentID, []);
        if (parentID && state.court[parentID]) {
          Vue.set(state.court[parentID], 'childCount', 0);
        }
      }
      state.courtChildIDs[parentID].push(id);
      if (parentID) {
        state.court[parentID].childCount++;
      }
    }
  },
};

const actions = {
  ...attorneyActions,
  ...caseActions,
  ...caseJudgeActions,
  ...civilDecisionActions,
  ...docketActions,
  ...dossierActions,
  ...finePaymentActions,
  ...hearingActions,
  ...holidayActions,
  ...judgesActions,
  ...optionsActions,
  ...participantActions,
  ...partyActions,
  ...subjectPropertyActions,
  ...summonsActions,
  ...relatedDossierActions,

  /**
   * fetchCourt retrieves Court entities from the server and
   * injects them into the local Vuex store. It accepts a single ID
   * or an array of IDs.
   */
  async fetchCourt({ commit }, id: number | number[]) {
    const ids: number[] = _.flatten([id]);
    const idsToFetch = _.difference(ids, state.courtIDs);

    if (idsToFetch.length > 0) {
      const idsStr = idsToFetch.join(',');
      const response = await courtAPI.get(`courts/batch?ids=${idsStr}`);
      const newState = response.data.data;
      commit('setState', newState);
      commit('calculateCourtChildStats');
    }
  },

  async fetchCourts(
    { commit, state },
    payload = {} as { force: boolean },
  ): Promise<void> {
    if (!payload.force && state.courtsLoaded) {
      return Promise.resolve();
    }
    const response = await courtAPI.get(`/courts`);
    const courts = response.data.data;
    const newState = modelsToState('court', courts);
    commit('setState', newState);
    commit('setTarget', {
      target: 'courtIDs',
      value: _.map(courts, 'id'),
    });
    commit('setCourtsLoaded', true);
    commit('calculateCourtChildStats');
  },

  async fetchCourtroomsForCourt(
    { commit, getters },
    payload: { courtID: number; force: boolean },
  ) {
    // Cache existing data unless force: true is provided
    const existingData = getters.courtroomsForCourt(payload.courtID);
    if (existingData.length && !payload.force) {
      return;
    }

    const response = await courtAPI.get(
      `/courts/${payload.courtID}/courtrooms`,
    );
    const courtrooms = response.data.data;
    const newState = modelsToState('courtroom', courtrooms);
    commit('setState', newState);
    commit('createIndex', {
      target: 'courtroomIDsForCourt',
      data: newState.courtroom,
      indexKey: 'courtID',
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
