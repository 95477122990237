






























































































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import crimeAPI from '@/vuex/crime/crimeAPI';
import { Crime, LegacyCrime } from '@/vuex/crime/crime';

import CrimeForm from '@/components/crime/crime/CrimeForm.vue';
import EditLegacyCrimeDialog from '@/components/crime/legacyCrime/EditLegacyCrimeDialog.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      loading: false,
      error: null as any,

      crime: null as Crime | null,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('crime', ['crimeWithID']),
    ...mapGetters('crime', ['legacyCrimesWithCrimeID']),

    /**
     * legacyCrimes computes the array of LegacyCrime objects which have
     * this Crime mapped as their destination.
     */
    legacyCrimes(): LegacyCrime[] | [] {
      if (this.crime) {
        return this.legacyCrimesWithCrimeID(this.crime.id);
      } else {
        return [];
      }
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.fetchData();
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.crime) {
        return;
      }

      try {
        const res = await crimeAPI.post('/crimes/update', { data: this.crime });
        const newState = _.get(res, 'data.data', {});
        this.$store.commit('crime/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },

    /**
     * deleteCrime is called after the user *confirms* they want to delete this
     * crime. This method triggers the actual deletion of the data from the
     * database.
     */
    async deleteCrime(): Promise<void> {
      try {
        await crimeAPI.post('/crimes/delete', { data: { crimeID: this.id } });
        this.$store.commit('crime/removeFromTarget', {
          target: 'crimeIDs',
          value: this.id,
        });
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },

    async fetchData(): Promise<void> {
      this.loading = true;

      // Ensure we have the latest data in Vuex
      try {
        await this.$store.dispatch('crime/fetchCrime', this.id);
        await this.$store.dispatch('crime/fetchLegacyCrimesMappedTo', this.id);
        // Get a local copy so that edits aren't persisted in Vuex until the
        // user actually hits Save
        const localCopy: Crime = _.cloneDeep(this.crimeWithID(this.id));
        this.crime = localCopy;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    CrimeForm,
    EditLegacyCrimeDialog,
  },
});
