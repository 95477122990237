













import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';
import { firstModelIDInState } from '@/lib/vuex-domainmodel';
import { Party } from '@/vuex/court/party';

import PartyForm from '@/components/court/party/PartyForm.vue';

export default Vue.extend({
  data() {
    return {
      error: null as any,
      isOpen: false,
      party: null as Party | null,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.error = null;
      this.party = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.party) {
        return;
      }

      try {
        this.error = null;
        const response = await courtAPI.post('parties/create', {
          data: {
            name: this.party.name,
            surname: this.party.surname,
            fatherName: this.party.fatherName,
            grandfatherName: this.party.grandfatherName,
            aliases: this.party.aliases,
            nationalID: this.party.nationalID,
            genderID: this.party.genderID,
            isKochi: this.party.isKochi,
            occupation: this.party.occupation,
            phoneNumber: this.party.phoneNumber,
            emailAddress: this.party.emailAddress,
            birthplaceGeographyID: this.party.birthplaceGeographyID,
            residenceGeographyID: this.party.residenceGeographyID,
            isOrganization: this.party.isOrganization,
            organizationName: this.party.organizationName,
            businessLicenseNumber: this.party.businessLicenseNumber,
          },
        });
        // Store the newly-created entity in the Vuex store
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);

        // Notify consumers of the newly-created record's ID
        const newID = firstModelIDInState(newState, 'party');
        this.$emit('saved', newID);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    PartyForm,
  },
});
