













import _ from 'lodash';
import Vue from 'vue';

import crimeAPI from '@/vuex/crime/crimeAPI';
import { CrimeGroup } from '@/vuex/crime/crime';

import CrimeGroupForm from '@/components/crime/group/CrimeGroupForm.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      error: null as any,

      crimeGroup: null as CrimeGroup | null,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.crimeGroup = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.crimeGroup) {
        return;
      }

      this.error = null;
      try {
        const response = await crimeAPI.post('crime-groups/create ', {
          data: {
            name: this.crimeGroup.name,
            crimeIDs: this.crimeGroup.crimeIDs,
          },
        });

        const newState = _.get(response, 'data.data', {});
        const newID = Object.keys(newState.crimeGroup)[0];
        this.$store.commit('crime/setState', newState);
        this.$store.commit('crime/prependToTarget', {
          target: 'crimeGroupIDs',
          value: newID,
        });

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    CrimeGroupForm,
  },
});
