














import _ from 'lodash';
import Vue from 'vue';

import userAPI from '@/vuex/user/userAPI';
import { Role } from '@/vuex/user/user';

import RoleForm from './RoleForm.vue';

export default Vue.extend({
  data(): any {
    return {
      isOpen: false,
      error: null as any,
      role: null as null | Role,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.role = null;
      this.error = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      try {
        this.error = null;
        const response = await userAPI.post('roles/create', {
          data: {
            name: this.role.name,
            entityType: this.role.entityType,
            hasAllPermissions: this.role.hasAllPermissions,
            permissionIDs: this.role.permissionIDs,
          },
        });

        // Update Vuex State
        const newState = _.get(response, 'data.data', {});
        const roleState = _.get(newState, 'role', {});
        const roles: Role[] = Object.values(roleState) || [];
        this.$store.commit('user/setRole', roles);

        // Close the Dialog
        this.isOpen = false;
      } catch (err) {
        this.error = err;
      }
    },
  },
  components: {
    RoleForm,
  },
});
