import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface CrimeReport {
  id: number;
  dossierID: number;
  dossierNumber?: string; // Only included in search results
  awarenessNumber: string;
  departmentID: number;
  locationID: number;
  occurredOn: string;
  occurredAt: string;
  crimeIDs: number[];
  description: string;
  victimIDs: number[];
  officerName: string;
  wantedSuspectDescription: string;
  coalitionForcesAffected: boolean;
  filedAt: string;
  registeredAt: string;
  suspectIDs: number[];
}

export const state = {
  crimeReport: {} as { [id: number]: CrimeReport },
  crimeReportIDForDossier: {} as { [dossierID: number]: number },
};

export const getters = {
  crimeReportWithID:
    (state) =>
    (id): CrimeReport | null => {
      return state.crimeReport[id] || null;
    },
  crimeReportForDossier:
    (state) =>
    (dossierID: number): CrimeReport | null => {
      const crimeReportID = state.crimeReportIDForDossier[dossierID];
      if (!crimeReportID) {
        return null;
      }
      return state.crimeReport[crimeReportID];
    },
};

export const mutations = {};

export const actions = {
  /**
   * fetchCrimeReport retrieves a single Crime Report entity from the server
   * and injects its data into the Vuex store.
   */
  async fetchCrimeReport({ commit, dispatch }, id: number) {
    const response = await investigationAPI.get(`crime-reports/${id}`);

    // Server returns state containing objects for the court and the
    // investigation stores.
    const combinedState = response.data.data;

    // Split off the investigation state and store that here
    const investigationState = _.pick(combinedState, ['crimeReport']);
    commit('setState', investigationState);

    // Split off the profile state and store it
    const profileState = _.pick(combinedState, ['profile']);
    commit('profile/setState', profileState, { root: true });

    // Split off the court state and send it to the court state to be stored
    const courtState = _.pick(combinedState, ['dossier', 'party']);
    commit('court/setState', courtState, { root: true });
  },

  /**
   * fetchCrimeReportForDossier retrieves a single Crime Report entity from the
   * server and injects its data into the Vuex store. Unlike fetchCrimeReport,
   * this function retrieves records based on the Dossier ID rather than the
   * ID of the CrimeReport record.
   */
  async fetchCrimeReportForDossier({ commit }, payload: { dossierID: number }) {
    const response = await courtAPI.get(
      `dossiers/${payload.dossierID}/crime-report`,
    );
    const crimeReport = response.data.data;
    const newState = modelsToState('crimeReport', crimeReport);
    commit('setState', newState);
    commit('setTarget', {
      target: 'crimeReportIDForDossier',
      index: payload.dossierID,
      value: crimeReport.id,
    });
  },
};
