<template>
  <v-dialog
    v-model="isOpen"
    :max-width="maxWidth"
    :scrollable="!notScrollable"
    :fullscreen="fullscreen"
  >
    <template v-slot:activator="slotProps">
      <slot name="activator" v-bind="slotProps">
        <v-btn
          x-small
          :text="text"
          v-on="slotProps.on"
          :title="$t('command.expand')"
        >
          <v-icon x-small>{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="secondary">
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="isOpen = false">
          <v-icon>fa-times-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
      default: 'fa-expand-alt',
    },
    maxWidth: {
      type: [Number, String],
      default: 800,
    },
    text: Boolean,
    notScrollable: Boolean,
    fullscreen: Boolean,
  },
  data() {
    return {
      isOpen: false,
    };
  },
});
</script>
