export interface Summons {
  id: number;
  caseID: number;
  partyID: number;
  courtID: number;
  dossierID: number;
  filedAt: string;
  registeredAt: string;
  issuanceNumber: string;
  iteration: number;
  issuanceExpectedLocationID: number;
  issuanceNotes: string;
  presenceDate: string;
  presenceTime: string;
  presenceCourtroomID: number;
  servedAt: string;
  serviceFiledAt: string;
  serviceResultID: number;
  modeOfServiceID: number;
  serviceLocationID: number;
  servedToName: string;
  serviceNotes: string;
  appeared: boolean;
}

export const state = {
  summons: {} as { [id: number]: Summons },
};

export const getters = {
  summonsWithID:
    (state) =>
    (id: number): Summons => {
      return state.summons[id];
    },
};

export const mutations = {};
export const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
