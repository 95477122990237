



















































































































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import router from '@/router';

import { SubjectProperty } from '@/vuex/court/subjectProperty';

import CrimeSelector from '@/components/crime/CrimeSelector.vue';
import SuspectsTable from '@/components/detection/crimeReport/SuspectsTable.vue';
import VictimsTable from '@/components/detection/crimeReport/VictimsTable.vue';
import SubjectPropertiesForm from '@/components/subjectProperty/SubjectPropertiesForm.vue';

export default Vue.extend({
  props: {
    title: String,
  },
  data() {
    return {
      isOpen: false,

      error: null as any,

      occurredOn: moment().format(),
      occurredAt: '',
      dossierNumber: '',
      awarenessNumber: '',
      departmentID: 0,
      locationID: 1,
      crimeIDs: [] as number[],
      description: '',
      knownSuspects: false,
      knownSuspectProfileIDs: [] as number[],
      victimIDs: [] as number[],
      wantedSuspectDescription: '',
      wantedSuspectDescriptionBackup: '',
      officerName: '',
      coalitionForcesAffected: false,
      filedAt: moment().format(),
      registeredAt: moment().format(),
      subjectProperties: [] as SubjectProperty[],
    };
  },

  watch: {
    /**
     * knownSuspects is watched so that if the user toggles the known suspects
     * we don't lose what was previously entered in the Wanted Suspect Description
     * if they switch back. We don't want to send the wanted suspect description
     * to the server if suspectIDs are sent, but we also don't want to lose
     * the user's entered information if they toggle back-and-forth briefly.
     * Hence the need to store a "backup" value.
     */
    knownSuspects(val: boolean): void {
      if (val) {
        this.wantedSuspectDescriptionBackup = this.wantedSuspectDescription;
        this.wantedSuspectDescription = '';
      } else {
        this.wantedSuspectDescription = this.wantedSuspectDescriptionBackup;
        this.wantedSuspectDescriptionBackup = '';
        this.knownSuspectProfileIDs = [];
      }
    },
  },

  methods: {
    reset(): void {
      this.dossierNumber = '';
      this.awarenessNumber = '';
      this.departmentID = 0;
      this.filedAt = moment().format();
      this.occurredOn = moment().format();
      this.occurredAt = '';
      this.crimeIDs = [];
      this.description = '';
      this.victimIDs = [];
      this.officerName = '';
      this.wantedSuspectDescription = '';
      this.coalitionForcesAffected = false;
      this.filedAt = moment().format();
      this.registeredAt = moment().format();
      this.subjectProperties = [] as SubjectProperty[];
      this.locationID = 1;
      this.knownSuspectProfileIDs = [] as number[];
      this.knownSuspects = false;
      this.error = null;
    },

    async save(): Promise<void> {
      try {
        const response = await investigationAPI.post('crime-reports/create', {
          data: {
            dossierNumber: this.dossierNumber,
            awarenessNumber: this.awarenessNumber,
            departmentID: this.departmentID,
            locationID: this.locationID,
            occurredOn: this.occurredOn,
            occurredAt: this.occurredAt,
            crimeIDs: this.crimeIDs,
            description: this.description,
            victimIDs: this.victimIDs,
            officerName: this.officerName,
            wantedSuspectDescription: this.wantedSuspectDescription,
            suspectIDs: this.knownSuspectProfileIDs,
            hasKnownSuspects: this.knownSuspectProfileIDs.length > 0,
            coalitionForcesAffected: this.coalitionForcesAffected,
            filedAt: this.filedAt,
            subjectProperties: this.subjectProperties,
          },
        });

        // Since we have separate Vuex stores for court and investigation
        // We need to split the returned state into two
        const dossierState = { dossier: response.data.data.dossier };
        this.$store.commit('court/setState', dossierState);

        const crimeReportState = {
          crimeReport: response.data.data.crimeReport,
        };
        this.$store.commit('investigation/setState', crimeReportState);
        const crimeReportID = Object.keys(crimeReportState.crimeReport)[0];

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(crimeReportID);
        router.push({
          name: 'crimeReportShow',
          params: { id: crimeReportID },
        });
        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    CrimeSelector,
    SuspectsTable,
    VictimsTable,
    SubjectPropertiesForm,
  },
});
