







































































import Vue from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender } from '@/vuex/offender/offender';
import { TreatmentType } from '@/vuex/offender/medical';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: { offenderID: Number },
  data(): any {
    return {
      housingFacilityID: 0,
      createdAt: moment().format(),
      treatmentTypeID: TreatmentType.Medication,
      administeredBy: '',
      treatmentDescription: '',
      remarks: '',
      isOpen: false,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('offender', ['offenderWithID']),
    offender(): Offender | null {
      return this.offenderWithID(this.offenderID) || null;
    },
  },
  methods: {
    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('treatments/create', {
          data: {
            housingFacilityID: this.housingFacilityID,
            offenderID: this.offenderID,
            createdAt: this.createdAt,
            treatmentTypeID: this.treatmentTypeID,
            administeredBy: this.administeredBy,
            treatmentDescription: this.treatmentDescription,
            remarks: this.remarks,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.treatment)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'treatmentIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.treatment),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },

    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.createdAt = moment().format();
      this.administeredBy = '';
      this.treatmentTypeID = TreatmentType.Medication;
      this.treatmentDescription = '';
      this.remarks = '';
      if (this.offender) {
        this.housingFacilityID = this.offender.housingFacilityID;
      }
    },
  },
  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
