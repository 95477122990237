













































































import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender } from '@/vuex/offender/offender';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },

  data(): any {
    return {
      isOpen: false,
      error: null as any,

      reasonID: 0,
      fromHousingFacilityID: 0,
      toHousingFacilityID: 0,
      arrivedAt: moment().format(),
      notes: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', ['offenderWithID']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    /**
     * canBackfill computes whether a Transfer can be backfilled. It controls
     * whether or not this dialog's activator is visible.
     */
    canBackfill(): boolean {
      if (!this.offender) {
        return false;
      }

      return this.hasPermission('offender.transfer.backfill');
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.arrivedAt = moment().format();
      this.reasonID = 0;
      this.toHousingFacilityID = 0;
      this.notes = '';
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('transfers/backfill', {
          data: {
            offenderID: this.offenderID,
            fromHousingFacilityID: this.fromHousingFacilityID,
            toHousingFacilityID: this.toHousingFacilityID,
            arrivedAt: this.arrivedAt,
            reasonID: this.reasonID,
            notes: this.notes,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.transfer)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'transferIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.transfer),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        // Close the Dialog
        this.isOpen = false;
      } catch (err) {
        this.error = err;
      }
    },
  },

  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
