var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('LookupTableItemSelector',{attrs:{"label":_vm.$t('subjectProperty.type'),"value":_vm.subjectProperty.typeID,"items":"court/subjectPropertyTypes","rules":[function (v) { return !!v || _vm.$t('error.required'); }],"exclude-unknown":""},on:{"input":function($event){return _vm.update('typeID', $event)}}})],1),_c('v-spacer'),(_vm.subjectProperty.totalValueInPuls)?_c('v-col',{attrs:{"cols":"2"}},[_c('AFNCurrencyField',{attrs:{"value":_vm.subjectProperty.totalValueInPuls,"label":_vm.$t('subjectProperties.totalValue'),"readonly":""}})],1):_vm._e()],1),_c('v-row',[(_vm.isDrugs)?_c('v-col',{attrs:{"cols":"9"}},[_c('LookupTableItemSelector',{attrs:{"label":_vm.$t('court.drug.drugType'),"items":"court/illegalDrugs","value":_vm.subjectProperty.drugID,"rules":[function (v) { return !!v || _vm.$t('error.required'); }],"exclude-unknown":""},on:{"input":function($event){return _vm.update('drugID', $event)}}})],1):_vm._e(),(_vm.isCurrency)?_c('v-col',{attrs:{"cols":_vm.isForeignCurrency ? 5 : 9}},[_c('LookupTableItemSelector',{attrs:{"label":_vm.$t('currency.singular'),"items":"court/currencies","value":_vm.subjectProperty.currencyID,"rules":[function (v) { return !!v || _vm.$t('error.required'); }],"exclude-unknown":""},on:{"input":function($event){return _vm.update('currencyID', $event)}}})],1):_vm._e(),(_vm.isForeignCurrency)?_c('v-col',{attrs:{"cols":"4"}},[_c('IntField',{attrs:{"label":_vm.nameForIDInCollection(
            _vm.subjectProperty.currencyID,
            'court/currencies'
          ),"value":_vm.subjectProperty.foreignCurrencyAmount},on:{"input":function($event){return _vm.update('foreignCurrencyAmount', $event)}}})],1):_vm._e(),(_vm.hasQuantity)?_c('v-col',{attrs:{"cols":"4"}},[_c('IntField',{attrs:{"label":_vm.$t('subjectProperties.quantity'),"value":_vm.subjectProperty.quantity},on:{"input":function($event){return _vm.update('quantity', $event, 'number')}}})],1):_vm._e(),(_vm.hasLabel)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":_vm.$t('subjectProperties.label'),"value":_vm.subjectProperty.label,"required":"","rules":[function (v) { return !!v || this$1.$i18n.t('error.required'); }]},on:{"input":function($event){return _vm.update('label', $event, 'trim')}}})],1):_vm._e(),(_vm.hasLandArea)?_c('v-col',{attrs:{"cols":"4"}},[_c('LandAreaField',{attrs:{"label":_vm.$t('subjectProperties.landArea'),"type":"number","rules":_vm.requiredForLand},on:{"input":function($event){return _vm.update('landAreaInSquareMeters', $event, 'number')}},model:{value:(_vm.subjectProperty.landAreaInSquareMeters),callback:function ($$v) {_vm.$set(_vm.subjectProperty, "landAreaInSquareMeters", $$v)},expression:"subjectProperty.landAreaInSquareMeters"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"3"}},[_c('AFNCurrencyField',{attrs:{"label":_vm.$t('subjectProperties.itemValue') +
          (_vm.isForeignCurrency
            ? ' (' + _vm.nameForIDInCollection(1, 'court/currencies') + ')'
            : ''),"rules":[function (v) { return !!v || this$1.$i18n.t('error.required'); }],"required":""},on:{"input":function($event){return _vm.update('itemValueInPuls', $event)}},model:{value:(_vm.subjectProperty.itemValueInPuls),callback:function ($$v) {_vm.$set(_vm.subjectProperty, "itemValueInPuls", $$v)},expression:"subjectProperty.itemValueInPuls"}})],1)],1),(_vm.hasLegalDescription)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('subjectProperties.description'),"value":_vm.subjectProperty.description,"rows":"2"},on:{"input":function($event){return _vm.update('description', $event, 'trim')}}})],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('GeographySelector',{attrs:{"label":_vm.$t('location.singular'),"value":_vm.subjectProperty.locationGeographyID,"rules":_vm.requiredForLand},on:{"input":function($event){return _vm.update('locationGeographyID', $event)}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"value":_vm.subjectProperty.locationDetails,"rows":"2","label":_vm.$t('subjectProperties.locationDetails')},on:{"input":function($event){return _vm.update('locationDetails', $event, 'trim')}}})],1),(_vm.subjectProperty.id)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('item.isDeleted'),"input-value":_vm.subjectProperty.isDeleted},on:{"change":function($event){return _vm.update('isDeleted', !!$event)}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }