









import Vue from 'vue';

import { toLocalizedTime, toLocalizedDate } from '@/lib/dates';

export default Vue.extend({
  props: {
    /**
     * value is the property which accepts the date/datetime value to be
     * formatted. It is expected to be an ISO 8601-formatted date.
     */
    value: String,

    /**
     * showTime canbe set to true if the time part is desired
     */
    showTime: Boolean,
  },

  computed: {
    /**
     * datePart computes the locale-specific date format to display
     */
    datePart(): string {
      return toLocalizedDate(this.value, false);
    },

    /**
     * timePart computes the locale-specific time format to display
     */
    timePart(): string {
      return toLocalizedTime(this.value);
    },
  },
});
