














































import moment from 'moment';
import Vue from 'vue';

import offenderAPI from '@/vuex/offender/offenderAPI';

/**
 * LeaveCancelDialog wraps a Vuetify v-dialog with a form to cancel a
 * Leave. Just like a v-dialog, it expects a v-model="" attribute,
 * which trigger the visibility of the dialog. It also requires a
 * leaveID="" parameter to indicate the Leave which should be
 * cancelled.
 */
export default Vue.extend({
  props: ['value', 'leaveID'],
  data() {
    return {
      isOpen: false,
      canceledAt: '',
      cancelReason: '',
      error: null as any,
    };
  },
  // created is the Vue component lifecycle callback which triggers
  // when the component appears on the page the first time.
  created() {
    this.reset();
  },
  watch: {
    value(val) {
      if (val) {
        this.reset();
      }
    },
  },
  methods: {
    // reset clears all form fields to their initial values
    reset(): void {
      this.canceledAt = moment().format();
      this.cancelReason = '';
    },
    // save submits the cancelation request to the server
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('leaves/cancel', {
          data: {
            leaveID: this.leaveID,
            canceledAt: this.canceledAt,
            cancelReason: this.cancelReason,
          },
        });
        const newState = response.data.data;
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'leaveIDsForOffender',
          index: this.leaveID,
          value: Object.keys(newState.leave),
        });
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
