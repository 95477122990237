









import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    label: String,
    value: Number,
  },

  computed: {
    /**
     * dayChoices builds the options for the dropdown which lets the user
     * select which date range of dispositions they'd like the report to work
     * over.
     */
    dayChoices(): VSelectItem[] {
      const dayOptions = [7, 30, 60, 90, 365];
      const choices = _.map(dayOptions, (days) => {
        return {
          text: `${days} ${i18n.t('time.days')}`,
          value: days,
        };
      });
      choices.push({ text: `${i18n.t('time.allTime')}`, value: 0 });
      return choices;
    },
  },
});
