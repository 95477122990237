































































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { PunishmentType } from '@/vuex/crime/crime';
import PunishmentTypeIcons from '@/components/crime/punishmentType/PunishmentTypeIcons.vue';

export default Vue.extend({
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('language', ['languages']),

    /**
     * punishmentType computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    punishmentType(): PunishmentType {
      return this.value
        ? this.value
        : {
            id: 0,
            name: { en: '', fa: '', ps: '' },
            categoryID: 2,
            rank: 0,
            isATI: false,
            isImprisonment: false,
            isConsequential: false,
            canHaveAmount: false,
            requiresAmount: false,
            canHaveDuration: false,
            requiresDuration: false,
            canReferenceSubjectProperty: false,
            requiresNotes: false,
            trackFulfillment: false,
          };
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      // -----------------------------------------------------------------------
      // Apply modifiers to the submitted value (if requested)
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      if (modifier === 'number') {
        let quant = parseInt(value, 10);
        // Force non-numeric input to a number so that the form
        // ignores it (this will show '0' if you type 'a').
        if (Number.isNaN(quant)) {
          quant = 0;
        }
        modifiedValue = quant;
      }

      // Build the new PunishmentType object
      const newObject = tap(cloneDeep(this.punishmentType), (v) =>
        set(v, key, modifiedValue),
      );

      if (key === 'canHaveAmount' && !value) {
        // Can't require an amount if an amount isn't allowed
        newObject.requiresAmount = false;
      }

      if (key === 'requiresAmount' && value) {
        // If we require an amount, then we also must allow one
        newObject.canHaveAmount = true;
      }

      if (key === 'canHaveDuration' && !value) {
        // Can't require a duration if a duration isn't allowed
        newObject.requiresDuration = false;
      }

      if (key === 'requiresDuration' && value) {
        // If we require a duration, then a duration must be allowed
        newObject.canHaveDuration = true;
      }

      // -----------------------------------------------------------------------
      // Notify the parent that the object changed
      this.$emit('input', newObject);
    },
  },
  components: {
    PunishmentTypeIcons,
  },
});
