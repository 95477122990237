






























import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import { Dossier } from '@/vuex/court/dossier';

export default Vue.extend({
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters('court', ['caseWithID', 'courtName', 'dossierWithID']),
    kase(): Case {
      return this.caseWithID(this.id);
    },
    dossier(): Dossier | null {
      if (!this.kase) {
        return null;
      }
      return this.dossierWithID(this.kase.dossierID);
    },
  },
});
