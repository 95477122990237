














import _ from 'lodash';
import Vue from 'vue';

import { PartyAttorney } from '@/vuex/court/participant';

import PartyAttorneyForm from '@/components/court/attorney/PartyAttorneyForm.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,

      partyAttorney: null as PartyAttorney | null,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state, which
     * is a null PartyAttorney object, which will trigger the PartyAttorneyForm
     * to reset.
     */
    reset(): void {
      this.partyAttorney = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to emit the PartyAttorney externally so that it can be
     * handled.
     */
    save(): void {
      this.$emit('save', this.partyAttorney);

      this.isOpen = false;
    },
  },
  components: {
    PartyAttorneyForm,
  },
});
