















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

export default Vue.extend({
  props: {
    value: [Array, Number],
    label: String,
    disabled: Boolean,
    dossierID: Number,
    multiple: Boolean,
    rules: Array,

    /**
     * includeDeleted opts out of the default behavior of hiding Subject
     * Properties which have been deleted.
     */
    includeDeleted: Boolean,
  },
  computed: {
    ...mapGetters('court', ['subjectPropertyForDossier']),
    subjectPropertyChoices(): SubjectProperty[] {
      let choices: SubjectProperty[] = this.subjectPropertyForDossier(
        this.dossierID,
      );
      if (!this.includeDeleted) {
        choices = _.filter(choices, { isDeleted: false });
      }
      return choices;
    },
  },
  methods: {
    subjectPropertiesSelected(newVal) {
      // newVal is updating when a property is selected, all IDs will be in array
      this.$emit('input', newVal);
    },
  },
});
