
































import Vue from 'vue';
import moment from 'moment';

import offenderAPI from '@/vuex/offender/offenderAPI';

export default Vue.extend({
  props: {
    id: Number,
  },

  data(): any {
    return {
      isOpen: false,
      releaseDate: moment().format(),
      error: null as any,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.releaseDate = moment().format();
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await offenderAPI.post('release-accompanying-child', {
          data: {
            id: this.id,
            releaseDate: this.releaseDate,
          },
        });
        const newState = response.data.data;
        this.$store.commit('offender/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
