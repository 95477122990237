































import Vue from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';

import { Dossier } from '@/vuex/court/dossier';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import CourtRevenueSummary from '@/components/court/CourtRevenueSummary.vue';
import PrimaryParticipants from '@/components/court/participant/PrimaryParticipants.vue';
import SubjectPropertiesSummary from '@/components/subjectProperty/SubjectPropertiesSummary.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  computed: {
    ...mapGetters('court', ['dossierWithID', 'subjectPropertyForDossier']),

    dossier(): Dossier {
      return this.dossierWithID(this.id);
    },

    subjectProperties(): SubjectProperty[] {
      if (this.id) {
        return this.subjectPropertyForDossier(this.id);
      }
      return [];
    },
  },

  components: {
    CourtRevenueSummary,
    PrimaryParticipants,
    SubjectPropertiesSummary,
  },
});
