



























































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import { criteriaToQueryString } from '@/lib/criteria';
import { modelsToState } from '@/lib/vuex-domainmodel';
import { Party } from '@/vuex/court/party';

import AddPartyDialog from '@/components/court/party/AddPartyDialog.vue';
import EditPartyDialog from '@/components/court/party/EditPartyDialog.vue';

export default Vue.extend({
  props: {
    selectable: Boolean,
    dense: Boolean,
  },
  created() {
    // Load the initial data...
    this.fetchData();
    // then make every future call to this.fetchData be debounced so that
    // it can never be called faster than once every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },
  data() {
    return {
      loading: false,
      advancedSearch: false,
      criteria: {
        q: '',
        name: '',
        surname: '',
        fatherName: '',
        grandfatherName: '',
        alias: '',
        genderID: 0,
        birthplaceGeographyID: 0,
        residenceGeographyID: 0,
      },
      resultIDs: [] as number[],
      currentPage: 1,
      serverTotalResults: 0,
      pageSize: 10,
    };
  },
  computed: {
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('court', ['fullPartyName', 'partyWithID']),
    ...mapGetters('auth', ['hasPermission']),

    allowAdding(): boolean {
      if (this.hasPermission('party.create')) {
        if (!this.selectable) {
          // On the PartyList page, or any context where the user is not
          // being asked to select something, allow them to Add new parties
          // anytime.
          return true;
        }
        // Otherwise require them to search first
        return !!this.queryString;
      }

      return false;
    },

    /**
     * parties computes the array of Party entities which currently appear
     * in the data table. By injecting search results into the Party store in
     * Vuex, we ensure that all Party entity data for the selected item
     * lives on when selecting a Party for use on other pages.
     */
    parties(): Party[] {
      return _.map(this.resultIDs || [], this.partyWithID);
    },

    /**
     * queryString computes the URL querystring parameters which will be
     * submitted to the server to perform the search. NOTE that this
     * value is combined with pagination criteria inside fetchData, so this
     * value doesn't exactly match the API request.
     */
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    /**
     * headers dynamically builds the headers array so that translations for
     * the column titles can update live.
     */
    headers(): any[] {
      const headers: any[] = [
        { text: i18n.t('person.name'), value: 'name ', width: '30%' },
        { text: i18n.t('person.alias.plural'), value: 'alias ', width: '15%' },
        {
          text: i18n.t('person.phoneNumber'),
          value: 'phoneNumber',
        },
        {
          text: i18n.t('person.emailAddress'),
          value: 'emailAddress',
        },
        {
          text: i18n.t('person.residence'),
          value: 'residenceGeographyID',
        },
      ];
      if (!this.selectable) {
        headers.unshift({
          text: i18n.t('command.edit'),
          value: null,
          sortable: false,
        });
      }
      return headers;
    },
  },
  watch: {
    /**
     * queryString is watched so that a new search can be triggered.
     * We also reset back to page #1 anytime a new search is triggered.
     */
    queryString(newVal: string) {
      this.currentPage = 1;
      this.fetchData();
    },

    /**
     * advancedSearch changes the UI, so we need to make sure to reset
     * the no longer visible fields
     */
    advancedSearch(isAdvanced: boolean) {
      this.reset();
    },
  },
  methods: {
    /**
     * afterPartyAdded is triggered when the user successfully adds a new
     * record via the add dialog attached to the search interface. It receives
     * the ID of the newly-created Party, and immediately selects it if the
     * searcher is in selectable mode. If not, it forcefully adds it to the
     * front of the search results so the user immediately sees it.
     */
    afterPartyAdded(newID: number): void {
      if (this.selectable) {
        this.selectParty(newID);
      } else {
        this.resultIDs.unshift(newID);
        if (this.serverTotalResults < 1) {
          this.serverTotalResults = 1;
        }
      }
    },

    /**
     * selectParty is triggered by a click on one of the rows in the table,
     * only when the selectable prop is set.
     */
    selectParty(partyID: any): void {
      this.$emit('selected', parseInt(partyID, 10));
    },

    /**
     * reset clears the search and resets search results. Primarily designed
     * to be called externally via a $ref to this component so that the searcher
     * can be reset when a dialog is opened.
     */
    reset(): void {
      this.$nextTick(() => {
        this.criteria.q = '';
        this.criteria.name = '';
        this.criteria.surname = '';
        this.criteria.fatherName = '';
        this.criteria.grandfatherName = '';
        this.criteria.alias = '';
        this.criteria.genderID = 0;
        this.criteria.birthplaceGeographyID = 0;
        this.criteria.residenceGeographyID = 0;
        this.fetchData();
        if (this.$refs.query) {
          (this.$refs.query as any).focus();
        }
      });
    },

    /**
     * fetchData performs the actual search. It hits the API with the active
     * query and pagination criteria and updates the locally-held search results
     * data.
     */
    async fetchData(): Promise<void> {
      this.loading = true;

      const args = [] as string[];
      args.push(`page[size]=${this.pageSize}`);
      args.push(`page[number]=${this.currentPage}`);
      args.push(this.queryString);

      try {
        const response = await courtAPI.get(
          `/parties/search?` + args.join('&'),
        );
        if (response && response.data && response.data.data) {
          if (response.data.meta) {
            this.serverTotalResults = response.data.meta.totalResults;
          }
          const parties = response.data.data;
          this.resultIDs = _.map(parties, 'id');
          const newState = modelsToState('party', parties);
          this.$store.commit('court/setState', newState);

          // Ensure the referenced Geographies are loaded
          const geoIDs = _.chain(this.parties)
            .map((p) => [p.residenceGeographyID])
            .flatten()
            .compact()
            .uniq()
            .value();
          this.$store.dispatch('geography/fetchGeography', geoIDs);
        }
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    AddPartyDialog,
    EditPartyDialog,
  },
});
