















import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';

import userAPI from '@/vuex/user/userAPI';
import { Role } from '@/vuex/user/user';

import RoleForm from './RoleForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      error: null as any,
      role: null as Role | null,
      loading: false,
    };
  },

  computed: {
    ...mapState('user', ['roles']),
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      const localCopy = Object.assign({}, _.find(this.roles, { id: this.id }));
      this.role = localCopy;
      this.error = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.role) {
        return;
      }

      try {
        this.error = null;

        // API Call and Response
        const response = await userAPI.post('roles/update', {
          data: {
            id: this.role.id,
            name: this.role.name,
            hasAllPermissions: this.role.hasAllPermissions,
            permissionIDs: this.role.permissionIDs,
          },
        });

        // Update Vuex state
        const newState = _.get(response, 'data.data', {});
        const roleState = _.get(newState, 'role', {});
        const roles: Role[] = Object.values(roleState) || [];
        this.$store.commit('user/setRole', roles);

        // Close the dialog
        this.isOpen = false;
      } catch (err) {
        this.error = err;
      }
    },
  },
  components: {
    RoleForm,
  },
});
