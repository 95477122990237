








import moment from 'moment';
import Vue from 'vue';

import i18n from '@/i18n';

export default Vue.extend({
  props: {
    birthdate: String,
    isApproximate: Boolean,
    noPrefix: Boolean,
    noSuffix: Boolean,
  },
  computed: {
    ageInYears(): number {
      if (!this.birthdate) {
        return 0;
      }
      const age = moment().diff(this.birthdate, 'years');
      if (age < 1) {
        return 0;
      }
      return age;
    },
    prefix(): string {
      if (this.noPrefix) {
        return '';
      }
      if (this.isApproximate && this.ageInYears > 0) {
        return '~';
      }
      return '';
    },
    suffix(): string {
      if (this.noSuffix) {
        return '';
      }
      if (this.ageInYears > 0) {
        return i18n.t('time.years').toString();
      }
      return '';
    },
  },
});
