


























































































































































































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import ClassificationScoreSlider from '@/components/offender/classification/ClassificationScoreSlider.vue';

export default Vue.extend({
  props: {
    offender: {
      type: Object,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('offender', [
      'criminalGroups',
      'criminalGroupRoles',
      'infectiousDiseases',
      'mentalDisorders',
      'motherAndChildConcerns',
      'privileges',
    ]),
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('offender', ['editableFlags']),
    ...mapGetters('language', ['valueForLocale', 'nameForIDInCollection']),
  },

  methods: {
    /**
     * updateProfile is the handler for all UI form field edit events which
     * pertain to the Profile entity. This function rebuilds a new Profile
     * object based on the prop, and emits the updateProfile event.
     */
    updateProfile(key: string, val: any): void {
      const newP = tap(cloneDeep(this.profile), (p) => set(p, key, val));

      if (newP.criminalGroupIDs.length < 1) {
        // When no Criminal Groups are selected, we don't want a
        // CriminalGroupRole set
        newP.criminalGroupRoleID = 0;
      }
      if (newP.criminalGroupIDs.length >= 1 && newP.criminalGroupRoleID === 0) {
        // When at least one Criminal Group is set, don't allow Unknown to be
        // set for the CriminalGroupRoleID
        newP.criminalGroupRoleID = 1;
      }

      this.$emit('updateProfile', newP);
    },

    /**
     * updateOffender is the handler for all UI form field edit events which
     * pertain to the Offender entity. This function rebuilds a new Offender
     * object based on the prop, and emits the updateOffender event.
     */
    updateOffender(key: string, val: any): void {
      const newO = tap(cloneDeep(this.offender), (o) => set(o, key, val));
      this.$emit('updateOffender', newO);
    },
  },
  components: {
    ClassificationScoreSlider,
  },
});
