import Vue from 'vue';
import _ from 'lodash';
import { Route } from 'vue-router';

export interface PagerData {
  number: number;
  size: number;
  serverCount: number;
}

/**
 * criteriaToQueryString takes a one-layer-deep object of key-value pairs, and
 * converts it to a URL querystring. It is essentially the opposite of the
 * param.QueryStruct feature in the Go backend. It optionally accepts a second
 * parameter containing the paging status
 *
 * For easier handling on the server, we handle array values with multiple
 * entries in a special way. If any of the values on the right side of the
 * criteria object are Arrays, they will be expanded into multiple instances of
 * that parameter in the querystring. For example, if you
 * have the following criteria object:
 *
 *      teas = { teaColors: ['black', 'green'] }
 *
 * Then calling criteriaToQueryString(teas), would result in this output:
 *
 *      teaColors=black&teaColors=green
 *
 * This format translates in the backend Go code to the following url.Values:
 *
 * map[string][]string = {
 *  "teaColors": ["black", "green"]
 * }
 *
 * @param criteria An Object of key-value pairs where each key will become
 * a key in the URL querystring.
 * @param pd The pagination data parameter that indicates the current page
 * number and page size.
 *
 * @returns A string that can be used as the querystring for a URL.
 */
export function criteriaToQueryString(
  criteria: object,
  pd?: PagerData,
): string {
  const keys = populatedCriteriaKeys(criteria);
  const parts: string[] = [];
  const criteriaPart = _.chain(keys)
    // Make sure the keys are always in alphabetical order
    // so change detection isn't flagged by randomness in key
    // order
    .sort()
    // Convert remaining non-blank keys into a equals-separated key
    // value string (e.g. 'key=value' or 'key=value1&key=value2' for arrays)
    .map((key) => {
      // Convert all criteria values into arrays of URI-encoded string values
      const values = _.chain([criteria[key]])
        // Then flatten it back to a single-level array in case it already was
        // an array
        .flatten()
        // Convert each value to a URI-encoded string
        .map(_.toString)
        .map(encodeURIComponent)
        .value();
      return _.map(values, (val) => `${key}=${val}`).join('&');
    })
    .reject(_.isEmpty)
    .value()
    .join('&');
  if (criteriaPart !== '') {
    parts.push(criteriaPart);
  }
  if (pd) {
    parts.push(`page[number]=${pd.number}`);
    parts.push(`page[size]=${pd.size}`);
  }
  return parts.join('&');
}

/**
 * routeToCriteria takes a Vue Router Route object and converts it into criteria
 * object which can be combined with local data and later submitted to
 * criteriaToQueryString for sending to an API endpoint.
 *
 * @param route The Vue Router route object (this.$route in a component)
 * @param pd The pagination data object (optional)
 */
export function routeToCriteria(
  route: Route,
  criteria: object,
  pd?: PagerData,
) {
  // pageRE is how we recognize a pagination parameter in the querystring
  const pageRE = /^page\[(\w+)\]$/;
  const keys = Object.keys(route.query);
  for (const key of keys) {
    const val: any = route.query[key];

    const match = pageRE.exec(key);
    if (match != null) {
      if (pd) {
        Vue.set(pd, match[1], parseInt(val, 10));
      }
      continue;
    }

    if (Array.isArray(criteria[key])) {
      const ids = [] as any[];
      if (Array.isArray(val)) {
        for (const strID of val) {
          ids.push(safeIDConvert(strID));
        }
      } else {
        ids.push(safeIDConvert(val));
      }
      Vue.set(criteria, key, ids);
    } else if (_.isNumber(criteria[key])) {
      Vue.set(criteria, key, safeIDConvert(val));
    } else if (Array.isArray(val)) {
      Vue.set(criteria, key, _.uniq(val));
    } else {
      if (val === 'true' || val === true) {
        Vue.set(criteria, key, true);
      } else if (val === 'false' || val === false) {
        Vue.set(criteria, key, false);
      } else if (val === 'null' || val === null || val === undefined) {
        Vue.set(criteria, key, null);
      } else {
        Vue.set(criteria, key, decodeURIComponent(val));
      }
    }
  }
}

/**
 * populatedCriteriaKeys takes a criteria and returns an array of the criteria
 * keys which are populated with data (i.e. the "active search terms").
 *
 * @param criteria An object containing an entity search criteria
 * @returns
 */
export function populatedCriteriaKeys(criteria: object): string[] {
  const populatedKeys: string[] = [];
  const allKeys = Object.keys(criteria);
  for (const key of allKeys) {
    const val = criteria[key];

    if (val === '') {
      continue;
    }

    if (val === 0) {
      continue;
    }

    if (val === null) {
      continue;
    }

    if (val === undefined) {
      continue;
    }

    if (_.isArray(val) && val.length === 0) {
      continue;
    }

    populatedKeys.push(key);
  }

  return populatedKeys;
}

/**
 * changedCriteria builds a new object containing only the parameters from
 * currentCriteria (its first param) which differ from their values in
 * originalCriteria (the second param). This function helps build the queryString
 * with as few parameters as possible.
 *
 * @param  currentCriteria  Object whose criteria are desired in the output.
 * @param  originalCriteria Object with which to compare
 * @return An object containing the subset of properties in currentCriteria
 * which differ from originalCriteria.
 */
export function changedCriteria(currentCriteria, originalCriteria): object {
  return _.transform(
    currentCriteria,
    (result: object, value: any, key: string) => {
      if (!_.isEqual(value, originalCriteria[key])) {
        result[key] =
          _.isObject(value) && _.isObject(originalCriteria[key])
            ? changedCriteria(value, originalCriteria[key])
            : value;
      }
    },
  );
}

/**
 * safeIDConvert intelligently converts string input into a number if the string
 * contains only digits (i.e. it looks like an integer ID). Otherwise it leaves
 * the string unchanged.
 */
export function safeIDConvert(val: string | number | null): string | number {
  let strVal = '';
  if (val === null || val === undefined) {
    strVal = '';
  } else {
    strVal = val.toString();
  }
  if (strVal.match(/\d+/g)) {
    return _.toNumber(val);
  }
  return strVal;
}
