















































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    active: Boolean,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'entityIDsWithPermission']),

    /**
     * offenderDashboardRoutes computes an array of Vue router Locations which
     * are this user's dashboard links. If the user has no Offender dashboard
     * permissions, an empty array is returned.
     */
    offenderDashboardRoutes(): any[] {
      // Get a list of entity IDs for which the current user has permissions.
      // This is an array which might contain '*' indicating "all entities".
      const entityIDs = this.entityIDsWithPermission('dashboard.prison');

      // Define the common denominator route template. This will be enhanced
      // with query parameters for the facilityID below
      const routeTemplate = { name: 'offenderDashboard' };

      // Produce a Location/Route object for each permitted entity
      return _.map(entityIDs, (id: number | string) => {
        if (id === '*') {
          return Object.assign({}, routeTemplate, {
            // The root OffenderDashboard route. The null facilityID is
            // required to override a param which might have been added
            // separately
            query: { facilityID: null },
          });
        }
        return Object.assign({}, routeTemplate, {
          query: { facilityID: id },
        });
      });
    },
  },
});
