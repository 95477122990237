


















import Vue from 'vue';
import _ from 'lodash';

import AccompanyingChildrenTable from '@/components/offender/accompanyingChild/AccompanyingChildrenTable.vue';
import LeavesTable from '@/components/offender/leave/LeavesTable.vue';
import TransfersTable from '@/components/offender/transfer/TransfersTable.vue';

/*
 * The AccompanyingChildrenTable component is always included in the markup,
 * BUT will become invisible if not relevant for this offender (see the
 * component itself for those rules)
 */
export default Vue.extend({
  props: {
    id: Number,
    contentOnly: Boolean,
  },
  components: {
    AccompanyingChildrenTable,
    LeavesTable,
    TransfersTable,
  },
});
