
























































































































































































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender } from '@/vuex/offender/offender';
import { Profile } from '@/vuex/profile/profile';
import { VisitType } from '@/vuex/offender/medical';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data() {
    return {
      isOpen: false,
      error: null as any,

      // Set the initial state of every form element
      housingFacilityID: 0,
      createdAt: moment().format(),
      visitTypeID: VisitType.Exam,
      doctor: '',
      chiefComplaint: '',
      historyOfPresentIllness: '',
      diagnosis: '',
      prescription: '',
      followUpAt: '',
      hospitilizationRecommended: false,
      infectiousDiseaseIDs: [] as number[],
      drugIDs: [] as number[],
      mentalDisorderIDs: [] as number[],
      disabilityIDs: [] as number[],
      motherChildConcernIDs: [] as number[],
      mentalStatusCheckbox: false,
      appearance: '',
      behavior: '',
      speech: '',
      mood: '',
      affect: '',
      thoughts: '',
      perception: '',
      insight: '',
      judgement: '',
      cognition: '',
      remarks: '',
    };
  },
  computed: {
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('profile', ['profileWithID']),

    offender(): Offender | null {
      return this.offenderWithID(this.offenderID) || null;
    },

    profile(): Profile | null {
      if (!this.offender) {
        return null;
      }
      return this.profileWithID(this.offender.profileID) || null;
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.error = null;

      this.createdAt = moment().format();
      this.visitTypeID = VisitType.Exam;
      this.doctor = '';
      this.chiefComplaint = '';
      this.historyOfPresentIllness = '';
      this.diagnosis = '';
      this.prescription = '';
      this.followUpAt = ''; // Follow-up should be blank until populated
      this.hospitilizationRecommended = false;
      this.infectiousDiseaseIDs = [];
      this.drugIDs = [];
      this.mentalDisorderIDs = [];
      this.disabilityIDs = [];
      this.motherChildConcernIDs = [];
      this.mentalStatusCheckbox = false;
      this.appearance = '';
      this.behavior = '';
      this.speech = '';
      this.mood = '';
      this.affect = '';
      this.thoughts = '';
      this.perception = '';
      this.insight = '';
      this.judgement = '';
      this.cognition = '';
      this.remarks = '';

      if (this.offender) {
        this.housingFacilityID = this.offender.housingFacilityID;
      } else {
        this.housingFacilityID = 0;
      }

      if (this.profile) {
        // Use the ES6 spread operator to get a copy of these arrays
        // so that the original ones are not modified merely by
        // interacting with the form and then canceling the submission.
        this.infectiousDiseaseIDs = [...this.profile.infectiousDiseaseIDs];
        this.mentalDisorderIDs = [...this.profile.mentalDisorderIDs];
        this.motherChildConcernIDs = [...this.profile.motherChildConcernIDs];
      } else {
        this.infectiousDiseaseIDs = [];
        this.motherChildConcernIDs = [];
        this.mentalDisorderIDs = [];
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      const newExam = {
        offenderID: this.offenderID,
        housingFacilityID: this.housingFacilityID,
        createdAt: this.createdAt,
        visitTypeID: this.visitTypeID,
        doctor: this.doctor,
        chiefComplaint: this.chiefComplaint,
        historyOfPresentIllness: this.historyOfPresentIllness,
        diagnosis: this.diagnosis,
        prescription: this.prescription,
        followUpAt: this.followUpAt,
        hospitilizationRecommended: this.hospitilizationRecommended,
        infectiousDiseaseIDs: this.infectiousDiseaseIDs,
        mentalDisorderIDs: this.mentalDisorderIDs,
        motherChildConcernIDs: this.motherChildConcernIDs,
        drugIDs: this.drugIDs,
        disabilityIDs: this.disabilityIDs,
        remarks: this.remarks,
      };
      if (this.mentalStatusCheckbox) {
        /**
         * Only add the mental status exam fields if the
         * user checked the box.
         */
        Object.assign(newExam, {
          appearance: this.appearance,
          behavior: this.behavior,
          speech: this.speech,
          mood: this.mood,
          affect: this.affect,
          thoughts: this.thoughts,
          perception: this.perception,
          insight: this.insight,
          judgement: this.judgement,
          cognition: this.cognition,
        });
      }

      try {
        const response = await offenderAPI.post('exams/create', {
          data: newExam,
        });

        const combinedState = _.get(response, 'data.data', {});

        const offenderState = _.pick(combinedState, ['offender', 'exam']);
        const newID = Object.keys(offenderState.exam)[0];
        this.$store.commit('offender/setState', offenderState);
        this.$store.commit('offender/prependToTarget', {
          target: 'examIDsForOffender',
          index: this.offenderID,
          value: Object.keys(offenderState.exam),
        });

        const profileState = _.pick(combinedState, ['profile']);
        this.$store.commit('profile/setState', profileState);

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
