import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface RelatedDossier {
  id: number;
  dossierID: number;
  relatedDossierID: number;
  relatedDossierNumber: string;
}

export const state = {
  relatedDossierIDsForDossier: {} as { [dossierID: number]: number[] },
  relatedDossier: {} as { [id: number]: RelatedDossier },
};

export const getters = {
  relatedDossiersForDossier:
    (state) =>
    (dossierID: number): RelatedDossier[] => {
      return (state.relatedDossierIDsForDossier[dossierID] || []).map(
        (id: number) => state.relatedDossier[id],
      );
    },
};

export const mutations = {};

export const actions = {
  async fetchRelatedDossiers(
    { commit, state },
    payload: { dossierID: number },
  ) {
    const response = await courtAPI.get(
      `/dossiers/${payload.dossierID}/related-dossiers`,
    );
    const relatedDossiers = response.data.data;
    const newState = modelsToState('relatedDossier', relatedDossiers);
    commit('setState', newState);
    commit('setTarget', {
      target: 'relatedDossierIDsForDossier',
      index: payload.dossierID,
      value: _.map(relatedDossiers, 'id'),
    });
  },
};
