






















import { cloneDeep, tap, set } from 'lodash';
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { FeeAssessment } from '@/vuex/court/options';

import AssessmentAmountSelector from '@/components/court/fee/AssessmentAmountSelector.vue';

export default Vue.extend({
  props: {
    label: String,
    value: Object,
    dossierID: {
      type: Number,
      required: true,
    },
    caseID: {
      type: Number,
      default: 0,
    },
    categoryID: {
      type: Number,
      required: true,
    },
    required: Boolean,
    items: Array,
  },

  data() {
    return {
      selectedAmount: 0,
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', [
      'settlementValueInPulsForCase',
      'subjectPropertyTotalValueInPulsForDossier',
    ]),

    /**
     * feeAssessment computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    feeAssessment(): FeeAssessment {
      return this.value
        ? this.value
        : {
            id: 0,
            dossierID: this.dossierID,
            caseID: this.caseID,
            categoryID: this.categoryID,
            amountInPuls: 0,
            percentOfValue: 0,
            disputedValueInPuls: 0,
            /**
             * We leave assessedAt blank, and don't expose a UI for it because
             * the FiledAt date on the surrounding docket event represents this
             * concept. The server action will copy action.FiledAt to this
             * field before saving.
             */
            assessedAt: '',
          };
    },

    /**
     * disputedValueInPuls utilizes the provided dossierID and caseID props to
     * figure out what the value of the lawsuit is. If we are on a case with a
     * decision, that will be used. If not, use the total value of all
     * subject property on the case.
     */
    disputedValueInPuls(): number {
      const settlementValue = this.settlementValueInPulsForCase(this.caseID);
      if (settlementValue > 0) {
        return settlementValue;
      }
      return this.subjectPropertyTotalValueInPulsForDossier(this.dossierID);
    },
  },

  watch: {
    /**
     * selectedAmount is watched so that we can convert the selected value into
     * the amountInPuls field of the FeeAssessment.
     */
    selectedAmount(feeAmount: number) {
      const fa = Object.assign({}, this.feeAssessment);

      const isPercentage = feeAmount > 0 && feeAmount < 1;
      if (isPercentage) {
        fa.disputedValueInPuls = this.disputedValueInPuls;
        fa.percentOfValue = feeAmount;

        // Set amountInPuls from a calcul
        feeAmount = fa.disputedValueInPuls * feeAmount;
        feeAmount = Math.round(feeAmount / 100) * 100;
        fa.amountInPuls = feeAmount;
      } else {
        fa.amountInPuls = feeAmount;
      }

      this.$emit('input', fa);
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.feeAssessment), (v) => set(v, key, modifiedValue)),
      );
    },
  },

  components: {
    AssessmentAmountSelector,
  },
});
