













































import Vue from 'vue';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

import { CivilDecision } from '@/vuex/court/civilDecision';

export default Vue.extend({
  props: {
    caseID: Number,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['partyTypes']),
    ...mapGetters('court', ['civilDecisionForCase', 'partyName']),

    decision(): CivilDecision {
      return this.civilDecisionForCase(this.caseID);
    },
  },
});
