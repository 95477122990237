































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

import i18n from '@/i18n';
import { Crime } from '@/vuex/crime/crime';

import CorpusSelector from '@/components/corpus/CorpusSelector.vue';
import EditCrimeDialog from '@/components/crime/crime/EditCrimeDialog.vue';
import NewCrimeDialog from '@/components/crime/crime/NewCrimeDialog.vue';

export default Vue.extend({
  props: {
    selectable: Boolean,

    value: {
      type: Array,
      default: () => [] as number[],
    },

    excludedIDs: {
      type: Array as () => number[],
      default: () => [] as number[],
    },

    disableStats: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      advancedSearch: false,

      criteria: {
        q: '',
        title: '',
        corpusID: 0,
        article: 0,
        severityID: 0,
        imprisonmentDurationID: 0,
        reviewStatus: '',
      },

      currentPage: 1,

      selectedCrimes: [] as Crime[],
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('corpus', ['corpusWithID']),
    ...mapGetters('crime', ['allCrimes']),
    ...mapGetters('auth', ['hasPermission']),

    canAdd(): boolean {
      return !this.selectable && this.hasPermission('admin.crimeType.create');
    },

    canEdit(): boolean {
      return !this.selectable && this.hasPermission('admin.crimeType.create');
    },

    /**
     * crimes builds the list of Crime objects which match all applied criteria.
     * We also add a `corpus` property to allow display and filtering by
     * corpus.
     */
    crimes(): Crime[] {
      return (
        _.chain(this.allCrimes)
          // First filter out any items explicitly excluded by the prop
          .reject((c) => _.includes(this.excludedIDs, c.id))
          // Then apply the filter criteria from the page
          .filter((c) => {
            return _.every([
              this.matchesCorpus(c),
              this.matchesArticle(c),
              this.matchesCrimeTitle(c),
              this.matchesSeverity(c),
              this.matchesImprisonmentDuration(c),
              this.matchesReviewStatus(c),
            ]);
          })
          .map((c) => {
            return Object.assign({}, c, {
              reviewScore: this.reviewScore(c),
              corpus: this.corpusWithID(c.corpusID),
            });
          })
          .value()
      );
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a computed property so
     * that it will update with new translations when the locale changes.
     */
    headers(): any[] {
      const headers: any[] = [
        { value: 'id', text: i18n.t('id'), width: '40px', align: 'right' },
        {
          text: i18n.t('crime.title'),
          value: `title.${this.locale}`,
        },
        {
          text: i18n.t('crime.article'),
          value: 'article',
          align: 'right',
        },
        {
          text: i18n.t('corpus.singular'),
          value: `corpus.title.${this.locale}`,
        },
      ];

      if (!this.disableStats) {
        headers.push({
          text: i18n.t('review.progress'),
          value: 'reviewScore',
          width: '36px',
        });
      }

      if (this.canEdit) {
        headers.unshift({ value: 'edit', width: '40px', sortable: false });
      }

      return headers;
    },
  },

  watch: {
    advancedSearch(isAdvanced: boolean) {
      if (isAdvanced) {
        this.resetSimpleCriteria();
      } else {
        this.resetAdvancedCriteria();
      }
    },

    selectedCrimes(crimes: Crime[]) {
      this.$emit('input', _.map(crimes, 'id'));
    },
  },

  methods: {
    ...mapActions('corpus', ['fetchCorpora']),

    reset(): void {
      this.resetSimpleCriteria();
      this.resetAdvancedCriteria();
      this.$nextTick(() => {
        const query = this.$refs.query as any;
        if (query) {
          query.focus();
        }
      });
    },

    resetSimpleCriteria(): void {
      this.criteria.q = '';
    },

    resetAdvancedCriteria(): void {
      this.criteria.title = '';
      this.criteria.corpusID = 0;
      this.criteria.article = 0;
      this.criteria.severityID = 0;
      this.criteria.imprisonmentDurationID = 0;
      this.criteria.reviewStatus = '';
    },

    /**
     * matchesCrimeTitle returns true if the provided Crime is a valid match for
     * the crime title specified in the Advanced Search criteria. It always
     * returns true if no crime title is specified in the Advanced Search
     * criteria.
     */
    matchesCrimeTitle(crime: Crime): boolean {
      if (!this.criteria.title) {
        return true;
      }
      const search = this.criteria.title.toLowerCase();
      const title = crime.title[this.locale].toLowerCase();
      if (title.includes(search)) {
        return true;
      }
      return false;
    },

    /**
     * matchesCorpus returns true if the provided Crime is a valid match for
     * the Corpus specified in the advanced search criteria. It always returns
     * true if no Corpus is selected.
     */
    matchesCorpus(crime: Crime): boolean {
      if (!this.criteria.corpusID) {
        return true;
      }
      return crime.corpusID === this.criteria.corpusID;
    },

    /**
     * matchesImprisonmentDuration returns true if the provided Crime is a
     * valid match for imprisonment duration selection in the Advanced Search
     * criteria. It always returns true if no criteria is specified.
     */
    matchesImprisonmentDuration(crime: Crime): boolean {
      const targetID = this.criteria.imprisonmentDurationID;
      if (!targetID) {
        return true;
      }
      return _.includes(crime.validImprisonmentDurationIDs, targetID);
    },

    /**
     * matchesSeverity returns true if the provided Crime is a valid match for
     * the severity selection in the Advanced Search criteria. It always
     * returns true if no criteria is specified.
     */
    matchesSeverity(crime: Crime): boolean {
      const targetID = this.criteria.severityID;
      if (!targetID) {
        return true;
      }
      return _.includes(crime.validSeverityIDs, targetID);
    },

    /**
     * matchesArticle returns true if the provided Crime is a valid match for
     * the article data in the Advanced Search criteria. It always returns
     * true if no criteria is specified.
     */
    matchesArticle(crime: Crime): boolean {
      if (!this.criteria.article) {
        return true;
      }
      const article = crime.article;
      if (article === this.criteria.article) {
        return true;
      }
      return false;
    },

    matchesReviewStatus(crime: Crime): boolean {
      if (!this.criteria.reviewStatus) {
        return true;
      }
      switch (this.criteria.reviewStatus) {
        case 'needsReview':
          return !crime.lastReviewedByUserID;
        case 'needsApproval':
          return !!crime.lastReviewedByUserID && !crime.lastApprovedByUserID;
        case 'needsGiroaReview':
          return (
            !!crime.lastReviewedByUserID &&
            !!crime.lastApprovedByUserID &&
            !crime.lastGiroaReviewByUserID
          );
        case 'needsGiroaApproval':
          return (
            !!crime.lastReviewedByUserID &&
            !!crime.lastApprovedByUserID &&
            !!crime.lastGiroaReviewByUserID &&
            !crime.lastGiroaApprovedByUserID
          );
        case 'completed':
          return (
            !!crime.lastReviewedByUserID &&
            !!crime.lastApprovedByUserID &&
            !!crime.lastGiroaReviewByUserID &&
            !!crime.lastGiroaApprovedByUserID
          );
      }
      return false;
    },

    /**
     * reviewScore maps a LegacyCrime to its score of out 100 representing how
     * much of the review process is completed for this LegacyCrime.
     */
    reviewScore(c: Crime): number {
      let count = 0;
      const fields = ['Reviewed', 'Approved', 'GiroaReview', 'GiroaApproved'];
      for (const prefix of fields) {
        const key = `last${prefix}ByUserID`;
        if (c[key] > 0) {
          count = count + 1;
        }
      }
      if (count === 0) {
        return 10;
      }
      return (count / 4.0) * 100;
    },

    /**
     * reviewColor returns the progressbar color based on its value.
     */
    reviewColor(score: number) {
      if (score >= 90) {
        return 'green darken-2';
      }
      if (score >= 75) {
        return 'green lighten-2';
      }
      if (score >= 25) {
        return 'orange darken-2';
      }
      return 'red';
    },

    /**
     * overallCompletion accepts a field prefix (like 'Reviewed'), and scans the
     * crimes to find how many have that field checked. The result is returned
     * as a percentage (out of 100) for use in a v-progressbar.
     */
    overallCompletion(prefix: string): number {
      const key = `last${prefix}ByUserID`;
      const total = this.allCrimes.length;
      const finished = _.filter(this.allCrimes, (c) => !!c[key]).length;
      return (finished / total) * 100;
    },

    rowClasses(crime: Crime): string[] {
      const classes = [] as string[];
      if (this.selectable) {
        classes.push('selectable');
      }
      if (crime.isNonchargeable) {
        classes.push('isDeleted');
      }
      return classes;
    },
  },

  components: {
    CorpusSelector,
    EditCrimeDialog,
    NewCrimeDialog,
  },
});
