







































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import profileAPI from '@/vuex/profile/profileAPI';
import { Profile } from '@/vuex/profile/profile';

export default Vue.extend({
  props: {
    profileID: Number,
    value: Boolean,
  },
  data() {
    return {
      error: null as any,
      isOpen: false,

      profile: null as Profile | null,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('profile', ['profileWithID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to fetch and populate the profile object from the Vuex
     * data.
     */
    async reset(): Promise<void> {
      const localCopy = Object.assign({}, this.profileWithID(this.profileID));
      this.profile = localCopy;
      this.error = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.profile) {
        return;
      }
      try {
        this.error = null;
        const response = await profileAPI.post('profiles/update-identity', {
          data: {
            profileID: this.profileID,
            name: this.profile.name,
            surname: this.profile.surname,
            fatherName: this.profile.fatherName,
            grandfatherName: this.profile.grandfatherName,
            birthplaceGeographyID: this.profile.birthplaceGeographyID,
            residenceGeographyID: this.profile.residenceGeographyID,
            aliases: this.profile.aliases,
          },
        });
        const newState = response.data.data;
        this.$store.commit('profile/setState', newState);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
