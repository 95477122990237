




























































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    charge: Object,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection', 'valueForLocale']),
    ...mapState('language', ['locale']),
    ...mapState('court', ['generalAggravators', 'generalMitigators']),
    showActivator(): boolean {
      if (!this.charge) {
        return false;
      }
      if (
        (this.charge.aggravators && this.charge.aggravators.length > 0) ||
        (this.charge.generalAggravatorIDs &&
          this.charge.generalAggravatorIDs.length > 0)
      ) {
        return true;
      }
      if (
        (this.charge.mitigators && this.charge.mitigators.length > 0) ||
        (this.charge.generalMitigatorIDs &&
          this.charge.generalMitigatorIDs.length > 0)
      ) {
        return true;
      }
      return false;
    },
  },
});
