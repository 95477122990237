var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","extended":"","dark":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.hasPermission('admin.translation.create'))?_c('NewLocaleMessageDialog'):_vm._e()]},proxy:true}])},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('localeMessage.plural')))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","single-line":"","hide-details":"","clearable":"","label":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.wrongAPIServer)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Adding or editing translation settings here is not recommended. Translations in this environment are being served by "),_c('strong',[_vm._v(_vm._s(_vm.localeAPIHostname))]),_vm._v(". Please login to that server to add or edit translations. "),_c('br'),_c('br'),_vm._v(" If you do edit translations on this server, they will dissappear on the next browser refresh and will not show up on pages other than this one. ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localeMessages,"search":_vm.search,"no-data-text":_vm.$t('error.nodata')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ isDeleted: item.isDeleted }},[_c('td',{staticClass:"text-center"},[_c('EditLocaleMessageDialog',{attrs:{"key-name":item.key}})],1),_c('td',{staticClass:"key"},[_vm._v(_vm._s(item.key))]),_c('td',[_vm._v(_vm._s(item.message.en))]),_c('td',[_c('span',{class:{
                          'red--text': item.message.fa == item.message.en,
                        }},[_vm._v(" "+_vm._s(item.message.fa)+" ")])]),_c('td',[_c('span',{class:{
                          'red--text': item.message.ps == item.message.en,
                          'orange--text':
                            item.message.ps == item.message.fa &&
                            item.message.ps != item.message.en,
                        }},[_vm._v(" "+_vm._s(item.message.ps)+" ")])]),_c('td',{staticClass:"text-center"},[(item.lastVerifiedAt)?_c('v-icon',{attrs:{"small":"","color":"accent"}},[_vm._v(" fa-check ")]):_vm._e()],1)])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }