























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Profile } from '@/vuex/profile/profile';

import ProfileSearcher from '@/components/profile/ProfileSearcher.vue';

export default Vue.extend({
  props: {
    value: [Array, Number],

    // label is a passthrough to the v-autocomplete label prop
    label: String,

    // rules is a passthrough to the v-autocomplete rules prop
    rules: Array,

    // disabled is a passthrough to the v-autocomplete disabled prop
    disabled: Boolean,

    // value is the prop that makes this component work with v-model
    clearable: Boolean,

    // partyMode changes the v-model binding behavior of the
    // ProfileSearchSelector. Normally, the ProfileSearchSelector binds
    // with the ID of a profile.
    partyMode: Boolean,

    // Controls whether multiple profiles may be selected
    multiple: Boolean,
  },
  data() {
    return {
      loading: false,
      isOpen: false,

      // profileIDs is the internal representation of the IDs for the
      // selected profiles. When partyMode is false, this variable
      // will be updated to ensure it always matches the value prop.
      // When partyMode is true, this variable holds the equivalent
      // profileIDs to match the partyIDs which are in the value
      // prop.
      profileIDs: [] as number[],
    };
  },
  watch: {
    /**
     * isOpen is watched so that we can ensure the search criteria
     * in the profileSearcher gets reset each time we begin a new
     * selection process.
     */
    isOpen(newVal: boolean): void {
      this.$nextTick(() => {
        (this.$refs.profileSearcher as any).reset();
      });
    },

    /**
     * value is watched so that when something outside this component
     * changes the value to which we are binding data, the internal
     * representation held inside this component (profileIDs) can
     * be updated to match.
     */
    value(newVal: number | number[]): void {
      const newValAsArray = _.flatten([newVal]) as number[];
      if (newValAsArray.length === 0 && this.profileIDs.length !== 0) {
        // Special early-exit case that works for partyMode and non-partyMode.
        // When the incoming data is blank, we can blank the local data.
        this.profileIDs = [];
        return;
      }

      if (this.partyMode) {
        // The incoming data are Party IDs
        // TODO - Could we somehow ask the server to translate
        // PartyIDs => ProfileIDs here?
      } else {
        // Incoming data are Profile IDs
        // To avoid infinite recursion between the value and profileIDs
        // watchers, we only apply the change if the IDs differ.
        const idsMatch = _.isEqual(
          _.sortBy(newValAsArray),
          _.sortBy(this.profileIDs),
        );
        if (!idsMatch) {
          this.profileIDs = [...newValAsArray];
        }
      }
    },

    /**
     * profileIDs is watched so that when the internal representation
     * of data is changed (by user selection activity), we can broadcast
     * that change out to get externally data-bound values updated.
     */
    async profileIDs(newIDs: number[]): Promise<void> {
      let ids = [...this.profileIDs] as number[];

      if (this.partyMode) {
        // In partyMode, we can't be confident that the referenced party
        // objects are loaded in the court.party Vuex store, so we have to
        // fetch them.
        if (this.profiles.length > 0) {
          ids = _.map(this.profiles, 'partyID');
          this.loading = true;
          await this.$store.dispatch('court/fetchParty', ids);
          this.loading = false;
        }
      }

      if (this.multiple) {
        this.$emit('input', ids);
      } else {
        this.$emit('input', _.first(ids));
      }
    },
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('court', ['partyName']),
    ...mapGetters('profile', ['profileWithID', 'profileName']),

    profiles(): Profile[] {
      return _.map(this.profileIDs, this.profileWithID);
    },

    currentProfileName(): string {
      // Use a different name-mapping function in party and non-party mode
      const nameFunc = this.partyMode ? this.partyName : this.profileName;
      // Convert value to an array of IDs, which might be empty
      const ids = _.flatten([this.value || []]);
      // Map the ids into names
      return _.map(ids, nameFunc).join(', ');
    },
  },
  methods: {
    /**
     * clearSelection is the event handler for the textfield's clear button
     * We use it to reset the data-bound value to its zero value. In
     * single mode, this is the number 0. In multiple mode, this is an empty
     * array.
     */
    clearSelection(): void {
      if (!this.multiple) {
        this.$emit('input', 0);
      } else {
        this.$emit('input', []);
      }
    },

    /**
     * makeSelection is the event handler for the user either clicking-on
     * or finishing the creation of an entity. In both cases, that ID becomes
     * part of the selection.
     */
    makeSelection(selectedID: any): void {
      if (!this.multiple) {
        this.profileIDs = [selectedID];
        this.isOpen = false;
      } else {
        this.profileIDs = _.uniq([...this.profileIDs, selectedID]);
      }
    },

    /**
     * removeSelectionAtIndex is the event handler for the X (remove) button
     * on the chips of selected items in multiple mode. We use it to splice
     * out the clicked item from the values and re-emit the updated value.
     */
    removeSelectionAtIndex(i: number): void {
      this.profileIDs.splice(i, 1);
    },
  },
  components: {
    ProfileSearcher,
  },
});
