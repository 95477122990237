































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Court } from '@/vuex/court/court';

import CourtAddDialog from '@/components/court/court/CourtAddDialog.vue';
import CourtEditDialog from '@/components/court/court/CourtEditDialog.vue';
import CourtSelector from '@/components/court/court/CourtSelector.vue';

export default Vue.extend({
  name: 'CourtContainer',
  props: {
    parentID: Number,
  },
  data() {
    return {
      search: '',
      page: 1,
      newParentID: this.parentID,
      isMoveOpen: false,
      isReplaceOpen: false,
      replacementID: 0,
      selected: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('court', ['courtsUnder', 'courtChildCount', 'allCourts']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapState('language', ['locale']),
    canAdd(): boolean {
      return this.hasPermission('court.create');
    },
    courts(): Court[] {
      return this.courtsUnder(this.parentID);
    },

    selectedIDs(): number[] {
      return _.map(this.selected, 'id');
    },

    /**
     * translatedCourts returns an array of Courts with the name attribute
     * converted from a TranslatedString to the string for the active locale,
     * and adds a locationGeography attibute set to the full name of the geography.
     * This is done so that the v-data-table's search can interact with these
     * fields instead of the Object or number which underlie them.
     */
    translatedCourts(): any[] {
      return _.chain(this.courts)
        .map((court) =>
          Object.assign({}, court, {
            translatedName: court.name[this.locale],
            locationGeography: this.fullGeographyName(
              court.locationGeographyID,
            ),
            rank: court.rank || 999999, // Sort blank ranks to the end
          }),
        )
        .orderBy(['rank', 'translatedName'])
        .value();
    },

    containers(): Court[] {
      return _.filter(
        this.translatedCourts,
        (c: Court) => c.childCount && c.childCount > 0,
      );
    },

    uncontainedCourts(): Court[] {
      return _.filter(
        this.translatedCourts,
        (c: Court) => !c.childCount || c.childCount < 1,
      );
    },

    headers(): any[] {
      const h = [
        {
          text: 'Code',
          value: 'code',
          align: 'right',
        },
        {
          text: this.$i18n.t('person.name'),
          value: 'translatedName',
        },
        {
          text: this.$i18n.t('location.singular'),
          value: 'locationGeography',
        },
        {
          text: 'CMS1 Records',
          value: 'cms1RecordCount',
          align: 'right',
        },
        {
          text: 'CMS 1 Importance',
          value: 'cms1Importance',
          align: 'right',
        },
      ] as any[];
      if (this.canAdd) {
        h.unshift({
          text: this.$i18n.t('command.edit'),
          value: 'id',
          width: '2%',
          sortable: false,
        });
      }
      return h;
    },

    searchable(): boolean {
      return this.uncontainedCourts.length > 10;
    },

    paginated(): boolean {
      return this.uncontainedCourts.length > 10;
    },
  },
  watch: {
    search(newVal: string) {
      // Always jump to page 1 after the search changes
      this.page = 1;
    },
    parentID(newID: number) {
      this.newParentID = newID;
    },
    isReplaceOpen() {
      this.replacementID = 0;
    },
  },
  methods: {
    async move(): Promise<void> {
      const response = await courtAPI.post('/courts/move', {
        data: {
          oldParentID: this.parentID,
          newParentID: this.newParentID,
          childIDs: this.selectedIDs,
        },
      });
      const newState = response.data.data;
      this.$store.commit('court/setState', newState);
      this.$store.commit('court/calculateCourtChildStats');
      this.selected = [];
      this.isMoveOpen = false;
    },
    async replace(): Promise<void> {
      const response = await courtAPI.post('/courts/replace', {
        data: {
          oldCourtIDs: this.selectedIDs,
          newCourtID: this.replacementID,
        },
      });
      const newState = response.data.data;
      this.$store.commit('court/setState', newState);
      this.$store.commit('court/calculateCourtChildStats');
      this.selected = [];
      this.isReplaceOpen = false;
    },
  },
  components: {
    CourtSelector,
    CourtEditDialog,
    CourtAddDialog,
  },
});
