






















































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import ChargesSummary from '@/components/charges/ChargesSummary.vue';
import SubjectPropertyCard from '@/components/subjectProperty/SubjectPropertyCard.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['attorneyName']),
    ...mapState('court', [
      'partyTypes',
      'civilCaseTopics',
      'lawsuitTypes',
      'attorneyTypes',
    ]),
    ...mapGetters('crime', ['crimeWithID']),
    payload(): any {
      return this.entry.payload;
    },
    lawsuitTypeList(): string {
      return (this.payload.lawsuitTypeIDs || [])
        .map((id) => {
          return this.nameForIDInCollection(id, this.lawsuitTypes);
        })
        .join(', ');
    },
  },
  methods: {
    attorneyNamesAndTypes(partyAttorneys): string {
      return _.map(partyAttorneys, (pa) => {
        const name = this.attorneyName(pa.attorneyID);
        const type = this.nameForIDInCollection(
          pa.attorneyTypeID,
          this.attorneyTypes,
        );
        return `${name} (${type})`;
      }).join(', ');
    },
  },
  components: {
    ChargesSummary,
    SubjectPropertyCard,
  },
});
