



















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { DocketEntry } from '@/vuex/court/docket';
import { Dossier } from '@/vuex/court/dossier';

import NewDocketEntryMenu from './NewDocketEntryMenu.vue';
import PartitionedDocketEventTable from '@/components/docket/PartitionedDocketEventTable.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
  },
  created(): void {
    this.fetchData();
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['docketEventTypes', 'dossierStatuses']),
    ...mapGetters('court', ['dossierWithID', 'docketEventsForDossier']),

    caseType(): string {
      const dossier: Dossier = this.dossierWithID(this.dossierID);
      if (!dossier) {
        return '';
      }
      return dossier.type;
    },

    /**
     * events retrieves the pre-sorted list of docket events which have been
     * added to this Dossier
     */
    events(): DocketEntry[] {
      return this.docketEventsForDossier(this.dossierID);
    },
  },
  watch: {
    /**
     * dossierID is watched so that the docket events can be re-fetched
     * if this component is switched to another Dossier
     */
    dossierID(val: number): void {
      this.fetchData();
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('court/fetchDocketEntriesForDossier', {
        dossierID: this.dossierID,
        force: false,
      });
      this.loading = false;
    },
  },
  components: {
    NewDocketEntryMenu,
    PartitionedDocketEventTable,
  },
});
