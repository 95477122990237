































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { Profile } from '@/vuex/profile/profile';
import { Offender } from '@/vuex/offender/offender';

import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';
import OffenderClassificationForm from '@/views/offender/OffenderClassificationForm.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      offender: null as Offender | null,
      profile: null as Profile | null,
    };
  },
  computed: {
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('profile', ['profileWithID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    async reset(): Promise<void> {
      if (this.offenderID) {
        const localOffenderCopy = this.offenderWithID(this.offenderID);
        this.offender = Object.assign({}, localOffenderCopy);

        const localProfileCopy = this.profileWithID(
          localOffenderCopy.profileID,
        );
        this.profile = Object.assign({}, localProfileCopy);
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the classification info (Profile and Offender) to
     * the database and update local state with the results.3
     */
    async save(): Promise<void> {
      if (!this.offender || !this.profile) {
        return;
      }

      const response = await offenderAPI.post('offenders/reclassify', {
        data: {
          offenderID: this.offenderID,
          classificationScore: this.offender.classificationScore,
          flagIDs: this.offender.flagIDs,
          privilegeIDs: this.offender.privilegeIDs,
          isJuvenile: this.offender.isJuvenile,
          isDetainee: this.offender.isDetainee,

          criminalGroupIDs: this.profile.criminalGroupIDs,
          criminalGroupRoleID: this.profile.criminalGroupRoleID,
          infectiousDiseaseIDs: this.profile.infectiousDiseaseIDs,
          mentalDisorderIDs: this.profile.mentalDisorderIDs,
          motherChildConcernIDs: this.profile.motherChildConcernIDs,
        },
      });
      const combinedState = response.data.data;

      const offenderState = _.pick(combinedState, ['offender']);
      this.$store.commit('offender/setState', offenderState);

      const profileState = _.pick(combinedState, ['profile']);
      this.$store.commit('profile/setState', profileState);

      this.isOpen = false;
    },
  },
  components: {
    OffenderNameAndPIN,
    OffenderClassificationForm,
  },
});
