















































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { SentenceAdjustment } from '@/vuex/offender/sentenceAdjustment';

import AddSentenceAdjustmentDialog from '@/components/offender/sentenceAdjustment/AddSentenceAdjustmentDialog.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },

  created(): void {
    if (this.offenderID) {
      this.fetchData();
    }
  },

  computed: {
    ...mapGetters('court', ['dossierWithID']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('offender', ['sentenceAdjustmentReasons']),
    ...mapGetters('offender', [
      'sentenceAdjustmentsForOffender',
      'offenderIsReleased',
    ]),
    ...mapGetters('investigation', ['pardonDecreeWithID']),
    ...mapGetters('auth', ['hasPermission']),

    adjustments(): SentenceAdjustment[] {
      return this.sentenceAdjustmentsForOffender(this.offenderID);
    },

    translatedAdjustments(): any[] {
      return _.map(this.adjustments, (a) => {
        let tOp = i18n.t('sentenceAdjustment.set');
        if (a.adjustmentDirection > 0) {
          tOp = i18n.t('sentenceAdjustment.add');
        } else if (a.adjustmentDirection < 0) {
          tOp = i18n.t('sentenceAdjustment.subtract');
        }
        const pardonDecree = a.pardonDecreeID
          ? this.pardonDecreeWithID(a.pardonDecreeID)
          : null;
        return Object.assign(a, {
          translatedOperation: tOp,
          pardonDecree,
        });
      });
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },

    /**
     * canAdd returns true if has permission and also acceptable to add a new Sentence Adjustment
     */
    canAdd(): boolean {
      return (
        this.hasPermission('offender.createSentenceAdjustment') &&
        !this.isReleased
      );
    },

    /**
     * headers computes the array of VDataTable header description objects.
     * This needs to be a computed property so that translates can update
     * when the language is changed.
     */
    headers(): any[] {
      return [
        { value: 'filedAt', text: i18n.t('filedAt'), width: '130px' },
        { value: 'reasonID', text: i18n.t('sentenceAdjustment.reason') },
        {
          value: 'adjustmentDirection',
          text: i18n.t('sentenceAdjustment.direction'),
        },
        {
          value: 'adjustmentAmountInHours',
          text: i18n.t('sentenceAdjustment.amount'),
        },
        {
          value: 'newDurationInHours',
          text: i18n.t('sentenceAdjustment.newDuration'),
        },
        {
          value: 'newExpectedReleaseAt',
          text: i18n.t('sentenceAdjustment.newExpectedRelease'),
        },
        { value: null, text: i18n.t('action.plural') },
      ];
    },
  },

  watch: {
    /**
     * offenderID is watched so that the data set of SentenceAdjustments can
     * be re-fetched when the offender changes.
     */
    offenderID(val: number): void {
      if (this.offenderID) {
        this.fetchData();
      }
    },
  },

  methods: {
    fetchData(): void {
      this.$store.dispatch('offender/fetchSentenceAdjustments', {
        offenderID: this.offenderID,
      });
    },
  },
  components: {
    AddSentenceAdjustmentDialog,
  },
});
