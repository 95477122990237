













































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Charge, GuiltyChargeDispositionTypeIDs } from '@/vuex/court/charge';

import ChargesTable from '@/components/charges/ChargesTable.vue';
import SentenceSummary from '@/components/court/SentenceSummary.vue';

export default Vue.extend({
  props: {
    dossier: Object,
    partyID: Number,
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters('court', [
      'courtName',
      'caseWithID',
      'courtWithID',
      'participantInCase',
    ]),
    ...mapGetters('language', ['nameForIDInCollection']),

    kase(): any {
      return this.caseWithID(this.dossier.currentCaseID);
    },

    cardColor(): string {
      if (this.hasGuiltyCharges) {
        return 'error';
      }
      return 'primary';
    },

    hasGuiltyCharges(): boolean {
      if (!this.participant) {
        return false;
      }
      if (!this.participant.charges) {
        return false;
      }
      for (const charge of this.participant.charges) {
        if (
          _.includes(GuiltyChargeDispositionTypeIDs, charge.dispositionTypeID)
        ) {
          return true;
        }
      }
      return false;
    },

    participant(): any {
      if (this.kase) {
        return this.participantInCase(this.kase.id, this.partyID);
      }
    },

    charges(): Charge[] {
      if (!this.participant) {
        return [];
      }
      return this.participant.charges;
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      if (this.dossier && this.dossier.currentCaseID) {
        await this.$store.dispatch(
          'court/fetchCase',
          this.dossier.currentCaseID,
        );
      }
    },
  },

  components: {
    ChargesTable,
    SentenceSummary,
  },
});
