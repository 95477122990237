














import Vue from 'vue';
import { PunishmentType } from '@/vuex/crime/crime';

import crimeAPI from '@/vuex/crime/crimeAPI';
import { mapGetters } from 'vuex';

import PunishmentTypeForm from '@/components/crime/punishmentType/PunishmentTypeForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data(): any {
    return {
      isOpen: false,
      error: null as any,

      punishmentType: null as PunishmentType | null,
    };
  },
  computed: {
    ...mapGetters('crime', ['punishmentTypeWithID']),
  },
  watch: {
    isOpen(nowOpening: boolean): void {
      if (nowOpening) {
        // Existing PunishmentType stored in Vuex, supplied via our id prop
        const myCopy = Object.assign({}, this.punishmentTypeWithID(this.id));
        this.punishmentType = myCopy;
      }
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.punishmentType = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        await crimeAPI.post('punishment-types/update', {
          data: {
            id: this.punishmentType.id,
            name: this.punishmentType.name,
            categoryID: this.punishmentType.categoryID,
            rank: this.punishmentType.rank,
            isATI: this.punishmentType.isATI,
            isImprisonment: this.punishmentType.isImprisonment,
            isConsequential: this.punishmentType.isConsequential,
            canHaveAmount: this.punishmentType.canHaveAmount,
            requiresAmount: this.punishmentType.requiresAmount,
            canHaveDuration: this.punishmentType.canHaveDuration,
            requiresDuration: this.punishmentType.requiresDuration,
            canReferenceSubjectProperty:
              this.punishmentType.canReferenceSubjectProperty,
            requiresNotes: this.punishmentType.requiresNotes,
            trackFulfillment: this.punishmentType.trackFulfillment,
          },
        });

        // Update Vuex
        this.$store.dispatch('crime/fetchOptions', { force: true });

        // Close the Dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: { PunishmentTypeForm },
});
