






























































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import { CourtFeeTransaction, DocketEventType } from '@/vuex/court/options';
import { Summons } from '@/vuex/court/summons';

import CaseStatusLabel from '@/components/court/case/CaseStatusLabel.vue';
import ChargesSummary from '@/components/charges/ChargesSummary.vue';
import IssueSummonsDetails from '@/components/court/summons/IssueSummonsDetails.vue';
import ParticipantSummary from '@/components/court/participant/ParticipantSummary.vue';
import RecordSummonsServiceDialog from '@/components/court/summons/RecordSummonsServiceDialog.vue';
import SubjectPropertyCard from '@/components/subjectProperty/SubjectPropertyCard.vue';
import SentenceSummary from '@/components/court/SentenceSummary.vue';
import { CustomFieldTypes } from '@/vuex/court/customFieldType';

export default Vue.extend({
  props: {
    entry: Object,
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapState('court', [
      'docketEventTypes',
      'feeCategories',
      'dispositionReasons',
    ]),
    ...mapGetters('court', [
      'caseWithID',
      'judgeName',
      'partyName',
      'subjectPropertyName',
      'summonsWithID',
    ]),

    /**
     * docketEventType computes the full DocketEventType object which
     * corresponds to this DocketEvent.
     */
    docketEventType(): DocketEventType | null {
      if (this.entry && this.entry.payload && this.entry.payload.typeID) {
        return _.find(this.docketEventTypes, { id: this.entry.payload.typeID });
      }
      return null;
    },

    /**
     * fields computes the list of custom field objects which are assocaited
     * with this DocketEvent's type. This is used to iterate over all fields
     * for display in the docket event's content area.
     */
    fields(): any[] {
      if (!this.docketEventType) {
        return [];
      }

      const fields = this.docketEventType.fields || [];
      return fields.map((field: any) => {
        const typ = _.find(CustomFieldTypes, { name: field.type });
        // Return a new copy of the field, with value added
        return Object.assign({}, field, {
          value: _.get(this.fieldData, field.name),
          noLabel: !!typ?.noLabel,
        });
      });
    },

    fieldData(): any {
      if (!this.payload) {
        return {};
      }
      return this.payload.fieldData || {};
    },

    fee(): CourtFeeTransaction | null {
      if (!this.fieldData) {
        return null;
      }
      if (this.fieldData.feeAmount) {
        return this.fieldData.feeAmount;
      }
      if (this.fieldData.paymentAmount) {
        return this.fieldData.paymentAmount;
      }
      return null;
    },

    payload(): any {
      return this.entry.payload;
    },

    affectedSummons(): Summons {
      const id = _.get(this, 'entry.affectedRecords.summonsIDs[0]', 0);
      return this.summonsWithID(id);
    },

    finalCase(): Case | null {
      if (!this.payload.finalCaseID) {
        return null;
      }
      return this.caseWithID(this.payload.finalCaseID);
    },
  },

  methods: {
    /**
     * fieldValuePresent determines whether a field is populated with a value
     * in a field type-aware way. We need to be more sophisticated than a
     * simple `if (value)` test because some field types might be falsy, but
     * still require display.
     */
    fieldValuePresent(field): boolean {
      if (typeof field.value !== undefined && field.value !== null) {
        return true;
      }
      return false;
    },
  },

  components: {
    CaseStatusLabel,
    ChargesSummary,
    IssueSummonsDetails,
    ParticipantSummary,
    RecordSummonsServiceDialog,
    SentenceSummary,
    SubjectPropertyCard,
  },
});
