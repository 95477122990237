var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CMSFormDialog',{attrs:{"title":_vm.$t('sentence.singular'),"maxWidth":"1000px"},on:{"cancel":_vm.cancel,"reset":_vm.reset,"save":_vm.save},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('PunishmentTypeSelector',{attrs:{"value":_vm.punishmentTypeID,"disabled":""}})],1),(_vm.punishmentType.canHaveDuration && _vm.punishmentType.isImprisonment)?_c('v-col',{attrs:{"cols":"12"}},[_c('ImprisonmentDurationsLegend',{attrs:{"minDuration":_vm.minDuration,"maxDuration":_vm.maxDuration}})],1):_vm._e(),(_vm.punishmentType.canHaveDuration)?_c('v-col',{attrs:{"cols":"12"}},[_c('ImprisonmentDurationField',{attrs:{"required":""},model:{value:(_vm.durationInHours),callback:function ($$v) {_vm.durationInHours=$$v},expression:"durationInHours"}})],1):_vm._e(),(_vm.punishmentType.canHaveAmount)?_c('v-col',{attrs:{"cols":"12"}},[_c('AFNCurrencyField',{attrs:{"rules":_vm.punishmentType.requiresAmount
              ? [function (v) { return !!v || _vm.$t('error.required'); }]
              : [],"label":_vm.$t('cashFine.amount')},model:{value:(_vm.amountInPuls),callback:function ($$v) {_vm.amountInPuls=$$v},expression:"amountInPuls"}})],1):_vm._e(),(_vm.punishmentType.canReferenceSubjectProperty)?_c('v-col',{attrs:{"cols":"12"}},[_c('SubjectPropertySelector',{attrs:{"dossierID":_vm.dossierID,"label":_vm.$t('subjectProperty.singular'),"rules":_vm.punishmentType.canReferenceSubjectProperty
              ? [function (v) { return v.length || _vm.$t('error.required'); }]
              : [],"multiple":""},model:{value:(_vm.subjectPropertyIDs),callback:function ($$v) {_vm.subjectPropertyIDs=$$v},expression:"subjectPropertyIDs"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(_vm.punishmentType.requiresNotes)?_c('v-textarea',{attrs:{"rows":"3","label":_vm.$t('notes'),"rules":_vm.punishmentType.requiresNotes
              ? [function (v) { return !!v || _vm.$t('error.required'); }]
              : []},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"notes"}}):_vm._e()],1),(!_vm.isValid)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.$t('punishment.invalid')))])],1):_vm._e(),(_vm.fineOutOfRange || _vm.durationOutOfRange)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.$t('punishment.outOfRange')))])],1):_vm._e()],1)],1),_c('div',[_c('v-checkbox',{attrs:{"readonly":_vm.readonly,"color":_vm.checkColor,"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:{
            'font-weight-bold': _vm.isChecked,
            'text--disabled': !_vm.isValid && !_vm.isChecked,
            'error--text': _vm.isChecked && !_vm.isValid,
            'green--text': _vm.isChecked && _vm.isValid,
            'text--primary': !_vm.isChecked && _vm.isValid,
          }},[_vm._v(" "+_vm._s(_vm.punishmentType.name[_vm.locale])+" "),(_vm.amountInPuls)?_c('span',[_vm._v(" ( "),_c('span',{class:{ 'error--text': _vm.isError }},[_c('AFNDisplay',{attrs:{"value":_vm.amountInPuls}})],1),_vm._v(" ) ")]):_vm._e(),(_vm.durationInHours)?_c('span',[_vm._v(" ( "),_c('span',{class:{ 'error--text': _vm.isError }},[_c('Duration',{attrs:{"hours":_vm.durationInHours}})],1),_vm._v(" ) ")]):_vm._e()])]},proxy:true}]),model:{value:(_vm.isChecked),callback:function ($$v) {_vm.isChecked=$$v},expression:"isChecked"}}),_c('div',{class:{
        'error--text': _vm.isError,
        'ml-8': true,
        'text-caption': true,
      }},[(_vm.showFineRange)?_c('div',[_vm._v("🇦🇫 "+_vm._s(_vm.fineRange))]):_vm._e(),(_vm.showDurationRange)?_c('div',[_vm._v(_vm._s(_vm.durationRange))]):_vm._e(),(_vm.subjectPropertyIDs.length)?_c('div',[_vm._v(" "+_vm._s(_vm.subjectPropertyName(_vm.subjectPropertyIDs))+" ")]):_vm._e(),(_vm.notes)?_c('div',[_vm._v(_vm._s(_vm.notes))]):_vm._e(),(_vm.isConsequential)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('punishmentType.isConsequential'))+" ")]):_vm._e(),(_vm.fineOutOfRange || _vm.durationOutOfRange)?_c('div',[_c('v-icon',{attrs:{"x-small":"","color":"error"}},[_vm._v(" fa-exclamation-triangle ")]),_vm._v(" "+_vm._s(_vm.$t('punishment.outOfRange'))+" ")],1):_vm._e(),(_vm.isChecked && !_vm.isValid)?_c('div',[_c('v-icon',{attrs:{"x-small":"","color":"error"}},[_vm._v(" fa-exclamation-triangle ")]),_vm._v(" "+_vm._s(_vm.$t('punishment.invalid'))+" ")],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }