





































import moment from 'moment';
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';

import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';
import { CaseJudge } from '@/vuex/court/caseJudge';

export default Vue.extend({
  props: {
    caseJudgeID: Number,
    judgeID: Number,
    caseID: Number,
  },
  data() {
    return {
      isOpen: false,

      isLeadJudge: false,
      isInvestigatorJudge: false,
      filedAt: moment().format(),
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('court', ['caseJudgesByCaseID']),
    caseJudge(): CaseJudge {
      return this.caseJudgesByCaseID(this.caseID).find(
        (cj) => cj.id === this.caseJudgeID,
      );
    },
  },
  methods: {
    ...mapActions('court', ['fetchCaseJudges']),

    /**
     * reset is our event handler for CMSFormDialog's reset event. It fires when
     * the dialog is activated. We use it to reset all fields to their current
     * values for the existing case judge.
     */
    reset(): void {
      const localCopy = Object.assign({}, this.caseJudge);
      this.isLeadJudge = localCopy.isLeadJudge;
      this.isInvestigatorJudge = localCopy.isInvestigatorJudge;
      this.filedAt = moment().format();
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We use it to persist the
     * case judge changes to the server.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.put('/cases/update-case-judge', {
          data: {
            id: this.caseJudgeID,
            caseID: this.caseID,
            judgeID: this.judgeID,
            isLeadJudge: this.isLeadJudge,
            isInvestigatorJudge: this.isInvestigatorJudge,
            filedAt: this.filedAt,
          },
        });
        const newState = response.data.data;

        this.$store.commit('court/setState', newState);
        this.$store.commit('court/addDocketEntry', response);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    JudgeSelector,
  },
});
