













































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Party } from '@/vuex/court/party';

export default Vue.extend({
  props: {
    partyID: Number,
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapState('profile', ['genders']),
    ...mapState('court', ['partyTypes']),
    ...mapGetters('court', ['partyWithID']),
    ...mapState('language', ['locale']),
    party(): Party {
      return this.partyWithID(this.partyID);
    },
  },
});
