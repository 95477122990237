















































import moment from 'moment';
import Vue from 'vue';

import offenderAPI from '@/vuex/offender/offenderAPI';

export default Vue.extend({
  props: {
    transferID: Number,
  },

  data() {
    return {
      isOpen: false,
      cancelDate: '',
      cancelReason: '',
      error: null as any,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.cancelDate = moment().format();
      this.cancelReason = '';
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await offenderAPI.post('transfers/cancel', {
          data: {
            transferID: this.transferID,
            cancelDate: this.cancelDate,
            cancelReason: this.cancelReason,
          },
        });
        const newState = response.data.data;
        this.$store.commit('offender/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
