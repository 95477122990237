




































































































import Vue from 'vue';

import { VForm } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    value: Boolean,
    add: Boolean,
    edit: Boolean,
    remove: Boolean,
    title: String,
    color: String,
    loading: Boolean,
    saving: Boolean,
    buttonSize: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: [String, Number],
      default: 800,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    notPersistent: {
      type: Boolean,
      default: false,
    },
    notScrollable: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    noActivator: {
      type: Boolean,
      default: false,
    },
    overlayOpacity: { type: Number, default: 0.46 },
  },
  created() {
    this.isOpen = this.value;
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    // xSmall is the default size when the consumer doesn't supply a size,
    // but they do indicate this is an edit or remove form dialog
    xSmall(): boolean {
      if (this.buttonSize === 'x-small' || this.buttonSize === 'xSmall') {
        return true;
      }
      if (this.buttonSize === '') {
        return this.edit || this.remove;
      }
      return false;
    },
    small(): boolean {
      return this.buttonSize === 'small';
    },
    // medium is the default size when the consumer doesn't supply a size
    // but they indicate this is an add form dialog
    medium(): boolean {
      if (this.buttonSize === 'medium') {
        return true;
      }
      if (this.buttonSize === '') {
        return this.add;
      }
      return false;
    },
    large(): boolean {
      return this.buttonSize === 'large';
    },
    xLarge(): boolean {
      return this.buttonSize === 'x-large' || this.buttonSize === 'xLarge';
    },
  },
  watch: {
    value(nowOpen: boolean): void {
      this.isOpen = nowOpen;
    },
    isOpen(nowOpen: boolean): void {
      if (nowOpen) {
        this.reset();
        this.focus();
      }
      this.$emit('input', nowOpen);
    },
  },
  methods: {
    /**
     * focus searches the child HTML elements of the dialog, and focuses the
     * first non-hidden, non-disabled element that it finds.
     */
    focus(): void {
      this.$nextTick(() => {
        const form = this.$refs.form as any;
        if (!form) {
          return;
        }
        const el = form.$el;
        if (!el || !el.querySelector) {
          return;
        }
        if (el.querySelector) {
          const searches = [
            'input:not([type=hidden]):not([disabled]):not([readonly])',
            'textarea:not([type=hidden]):not([disabled]):not([readonly])',
          ];
          const firstInput = form.$el.querySelector(searches.join(','));
          if (firstInput && firstInput.focus && firstInput.select) {
            setTimeout(() => {
              firstInput.focus();
              firstInput.select();
            }, 0);
          }
        }
      });
    },
    reset(): void {
      const form = this.$refs.form as VForm;
      if (form) {
        form.resetValidation();
      }
      this.$emit('reset');
    },
    cancel(): void {
      this.$emit('cancel');
      this.isOpen = false;
    },
    close(): void {
      this.isOpen = false;
    },
    save(): void {
      const form = this.$refs.form as VForm;
      if (!form.validate()) {
        return;
      }
      this.$emit('save');
    },
  },
});
