








































import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';

import { Court, Courtroom } from '@/vuex/court/court';

import AddCourtroomDialog from '@/components/court/courtroom/AddCourtroomDialog.vue';
import EditCourtroomDialog from '@/components/court/courtroom/EditCourtroomDialog.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    id(val: number): void {
      if (this.id) {
        this.fetchData();
      }
    },
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('court', ['courtroomsForCourt', 'courtWithID']),
    headers(): any[] {
      return [
        {
          text: this.$i18n.t('court.name'),
          value: 'name',
          width: '80%',
        },
        {
          text: this.$i18n.t('action.plural'),
          value: 'id',
          width: '20%',
        },
      ];
    },
    court(): Court {
      return this.courtWithID(this.id);
    },
    courtrooms(): Courtroom[] {
      return _.sortBy(
        this.courtroomsForCourt(this.id) || [],
        (cr: Courtroom) => cr.name,
      );
    },
  },
  methods: {
    fetchData(): void {
      this.loading = true;
      this.$store
        .dispatch('court/fetchCourtroomsForCourt', {
          courtID: this.id,
          force: true,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  components: {
    AddCourtroomDialog,
    EditCourtroomDialog,
  },
});
