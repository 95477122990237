import _ from 'lodash';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Incident {
  id: number;
  offenderID: number;
  housingFacilityID: number;
  type: string;
  occurredAt: Date;
  reportedAt: Date;
  reportedBy: string;
  details: string;
}

export const enum IncidentType {
  Violent = 1,
  NonViolent = 2,
}

export const state = {
  incident: {} as { [id: number]: Incident },
  incidentIDsForOffender: {} as { [offenderID: number]: number[] },
};

export const getters = {
  incidentsForOffender:
    (state) =>
    (offenderID: number): Incident[] => {
      return (state.incidentIDsForOffender[offenderID] || []).map(
        (id: number) => state.incident[id],
      ) as Incident[];
    },
};

export const mutations = {};

export const actions = {
  async fetchIncidents({ commit }, payload: { offenderID: number }) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/incidents`,
    );
    const incidents = response.data.data;
    const newState = modelsToState('incident', incidents);
    commit('setState', newState);
    commit('setTarget', {
      target: 'incidentIDsForOffender',
      index: payload.offenderID,
      value: _.map(incidents, 'id'),
    });
  },
};
