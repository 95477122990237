













































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Offender } from '@/vuex/offender/offender';

import ReleaseDialog from '@/components/offender/ReleaseDialog.vue';
import ReportIncidentDialog from '@/components/offender/incident/ReportIncidentDialog.vue';
import TransferAddMenu from '@/components/offender/transfer/TransferAddMenu.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', ['offenderIsReleased', 'offenderWithID']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    canViewMedical(): boolean {
      // can view medical tab explicitly
      if (
        this.hasPermission(
          'offender.medical.view',
          'facility',
          this.offender.housingFacilityID,
        )
      ) {
        return true;
      }

      // or can edit any medical record within the tab
      if (
        this.hasPermission(
          'offender.medical.createExam',
          'facility',
          this.offender.housingFacilityID,
        ) ||
        this.hasPermission(
          'offender.medical.createTreatment',
          'facility',
          this.offender.housingFacilityID,
        ) ||
        this.hasPermission(
          'offender.medical.createIntakeHealthScreening',
          'facility',
          this.offender.housingFacilityID,
        ) ||
        this.hasPermission(
          'offender.medical.createHistory',
          'facility',
          this.offender.housingFacilityID,
        )
      ) {
        return true;
      }

      return false;
    },

    menuItems(): any[] {
      const tabs: Array<{ routeName: string; linkLabel: string }> = [];
      tabs.push(
        {
          routeName: 'offenderProfile',
          linkLabel: 'profile.profile',
        },
        {
          routeName: 'offenderHousing',
          linkLabel: 'housing.singular',
        },
        {
          routeName: 'offenderSentence',
          linkLabel: 'sentence.singular',
        },
        {
          routeName: 'offenderPrograms',
          linkLabel: 'program.plural',
        },
      );

      if (this.canViewMedical) {
        tabs.push({
          routeName: 'offenderMedical',
          linkLabel: 'medical.medical',
        });
      }

      return tabs;
    },
  },
  components: {
    ReleaseDialog,
    ReportIncidentDialog,
    TransferAddMenu,
  },
});
