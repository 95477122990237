




































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { CrimeReport } from '@/vuex/investigation/crimeReport';
import { Dossier } from '@/vuex/court/dossier';

import CrimeReportDisplay from '@/views/detection/CrimeReportDisplay.vue';
import VictimsTable from '@/components/detection/crimeReport/VictimsTable.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  created(): void {
    this.fetchData();
  },

  computed: {
    ...mapGetters('investigation', ['crimeReportWithID']),
    ...mapGetters('court', ['dossierWithID']),
    crimeReport(): CrimeReport {
      return this.crimeReportWithID(this.id);
    },
    dossierID(): number {
      if (this.crimeReport) {
        return this.crimeReport.dossierID;
      }
      return 0;
    },
    dossier(): Dossier | null {
      if (this.dossierID) {
        return this.dossierWithID(this.dossierID);
      }
      return null;
    },
  },

  watch: {
    id(val: number): void {
      if (this.id) {
        this.fetchData();
      }
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      try {
        await this.$store.dispatch('investigation/fetchCrimeReport', this.id);
        setTimeout(window.print, 1000);
      } catch (e) {
        const anyE = e as any;
        let errorMessage = `${anyE.name}: ${anyE.message}. `;
        if (anyE.message.match(/404/)) {
          errorMessage += `Cant find Crime Report #${this.id}`;
        }
        this.$store.dispatch(`application/showSnackbarError`, errorMessage);
      }
    },
  },
  components: {
    CrimeReportDisplay,
    VictimsTable,
  },
});
