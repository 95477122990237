














































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { AfghanistanID } from '@/vuex/geography/geography';
import { Court, CourtStageIDs } from '@/vuex/court/court';
import { TranslatedString } from '@/lib/translated';

import CourtSelector from '@/components/court/court/CourtSelector.vue';
import CourtStageSelector from '@/components/court/CourtStageSelector.vue';
import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';

export default Vue.extend({
  props: {
    value: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters('language', ['languages']),

    /**
     * court computes the active state of the entity object being edited on this
     * form. It dynamically returns either the provided value prop, or a blank
     * entity with the default/initial data for all fields on the entity. This
     * should be treated as a readonly value within this component.
     * Modifications should be made my calling the update() function, which
     * will apply the modification to a copy of the  entity and $emit it.
     * The two-way data binding will then cause this component's value prop to
     * hold the new, modified entity.
     */
    court(): Court {
      return this.value
        ? this.value
        : {
            id: 0,
            parentID: null,
            rank: 0,
            code: '',
            name: {} as TranslatedString,
            locationGeographyID: AfghanistanID,
            stageIDs: [CourtStageIDs.primary],
            headOfCourtJudgeID: 0,
            legacyDepartmentIDs: [],
            year: 0,
            lastCaseNumber: 0,
            maxCaseDurationInDays: 30,
            warnAfterDays: 25,
            cms1RecordImportance: 0,
            cms1RecordCount: 0,
          };
    },
  },

  methods: {
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.court), (v) => set(v, key, modifiedValue)),
      );
    },
  },

  components: {
    CourtSelector,
    JudgeSelector,
    CourtStageSelector,
  },
});
