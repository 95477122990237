



































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';

import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Participant } from '@/vuex/court/participant';

import ParticipantForm from './ParticipantForm.vue';

export default Vue.extend({
  props: {
    value: Boolean,
    dossierID: Number,
    caseID: Number,
    caseType: String,
    courtStageID: Number,
    noActivator: Boolean,
    tentativelyAddedPartyIDs: Array,
  },
  created() {
    this.isOpen = this.value;
  },
  data() {
    return {
      isOpen: false,
      filedAt: '',
      participant: null as Participant | null,

      error: null as any,
    };
  },
  computed: {
    ...mapGetters('court', [
      'fullPartyName',
      'participantsForCase',
      'participantsForDossier',
    ]),

    /**
     * saveAllowed prevents the save button from being clicked until a PartyID
     * and PartyTypeID are chosen.
     */
    saveAllowed(): boolean {
      const p = this.participant;
      return !!p && !!p.partyTypeID && !!p.partyID;
    },

    /**
     * wasAlreadyAdded returns true if the PartyID the user has selected already
     * exists either in the Vuex store for this case/dossier or in the
     * tentativelyAddedPartyIDs prop which was supplied to this component.
     */
    wasAlreadyAdded(): boolean {
      if (!this.participant) {
        return false;
      }
      return _.includes(this.alreadyAddedPartyIDs, this.participant.partyID);
    },

    /**
     * alreadyAddedPartyIDs returns the array of partyIDs which are already
     * associated with this case or dossier. This attribute combines the
     * persisted partyIDs which are saved to the server and added to Vuex
     * and the tentativelyAddedPartyIDs which were supplied to this component
     * as props.
     */
    alreadyAddedPartyIDs(): number[] {
      const alreadyAddedIDs = [] as number[];
      if (this.caseID) {
        // Add the partyIDs from the caseID if it was supplied as a prop.
        // We want the case's properties to override the dossier's.
        const ids = _.map(this.participantsForCase(this.caseID), 'partyID');
        alreadyAddedIDs.push(...ids);
      } else if (this.dossierID) {
        // Add the partyIDs from the dossier if we don't have a caseID supplied
        // as a prop, but we do have a dossierID. This will be the case on the
        // Dossier screen.
        const ids = _.map(
          this.participantsForDossier(this.dossierID),
          'partyID',
        );
        alreadyAddedIDs.push(...ids);
      }
      const additionalIDs = (this.tentativelyAddedPartyIDs || []) as number[];
      alreadyAddedIDs.push(...additionalIDs);
      return (
        _.chain(alreadyAddedIDs)
          // Remove falsey values in case there's a participant without
          // a party assigned yet
          .compact()
          // Remove duplicates
          .uniq()
          .value()
      );
    },
  },
  watch: {
    value(val) {
      this.isOpen = val;
    },
    isOpen(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.filedAt = moment().format();
      this.participant = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     *
     * It behaves in two distinct ways:
     *
     *   If the dossierID prop is not set, then it just $emits the Participant.
     *
     *   Otherwise, it performs an API call to persist the Participant to the
     *   database and add it to local state.
     */
    async save(): Promise<void> {
      if (!this.participant) {
        return;
      }

      if (!this.dossierID) {
        const emittedCopy = Object.assign({}, this.participant);
        this.$emit('saved', emittedCopy);
      }
      this.error = null;
      try {
        const response = await courtAPI.post('participants/create', {
          data: {
            dossierID: this.dossierID,
            caseID: this.caseID,
            partyID: this.participant.partyID,
            partyTypeID: this.participant.partyTypeID,
            originalPartyTypeID: this.participant.partyTypeID,
            attorneys: this.participant.attorneys || [],
            charges: this.participant.charges || [],
            filedAt: this.filedAt,
          },
        });

        const newState = response.data.data;
        const newParticipant = Object.values(
          newState.participant,
        )[0] as Participant;

        // Add all entities to Vuex state
        this.$store.commit('court/setState', newState);

        // Immediately update the docket
        this.$store.commit('court/addDocketEntry', response);

        if (this.caseID) {
          /**
           * =================================================================
           * CASE UPDATES
           */
          // Case Participants
          this.$store.commit('court/appendToTarget', {
            target: 'participantIDsForCase',
            index: this.caseID,
            value: newParticipant.id,
          });
        }

        if (this.dossierID) {
          /**
           * =================================================================
           * DOSSIER UPDATES
           */
          // Dossier Participants
          this.$store.commit('court/appendToTarget', {
            target: 'participantIDsForDossier',
            index: this.dossierID,
            value: newParticipant.id,
          });
        }

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    ParticipantForm,
  },
});
