









































































































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { Crime } from '@/vuex/crime/crime';

export default Vue.extend({
  props: {
    crimeReport: Object,
  },
  computed: {
    ...mapGetters('crime', ['crimeWithID']),
    ...mapState('language', ['locale']),
    ...mapGetters('profile', ['profileName']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('court', ['partyName']),
    crimes(): Crime[] {
      if (!this.crimeReport) {
        return [];
      }
      return _.compact(this.crimeReport.crimeIDs || []).map((id) =>
        this.crimeWithID(id),
      );
    },
    crimeNames(): string[] {
      return this.crimes.map((crime) => {
        return crime.title[this.locale];
      });
    },
    suspectNames(): string[] {
      if (!this.crimeReport) {
        return [];
      }
      return _.map(this.crimeReport.suspectIDs, (id) => this.profileName(id));
    },
    victimNames(): string[] {
      if (!this.crimeReport) {
        return [];
      }
      return _.map(this.crimeReport.victimIDs, (id) => this.partyName(id));
    },
  },
});
