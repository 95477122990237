




















































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { TranslatedString, TUnknown } from '@/lib/translated';
import { FateOfAccused, InvestigationDecisionTypes } from '@/vuex/court/cms1';

import ChargeName from '@/components/charges/ChargeName.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),

    payload(): any {
      return _.get(this, 'entry.payload', {});
    },

    fateOfAccused(): string {
      if (!this.payload || !this.payload.release) {
        return '';
      }
      const fate = FateOfAccused[this.payload.release];
      if (!fate) {
        return this.payload.release;
      }
      return fate[this.locale];
    },

    decisionType(): string {
      const attDec = _.get(this, 'payload.attDec', null);
      if (!attDec) {
        return '';
      }
      const decType: TranslatedString = _.get(
        InvestigationDecisionTypes,
        attDec,
        TUnknown,
      );
      if (decType) {
        return decType[this.locale];
      }
      return '';
    },
  },

  components: {
    ChargeName,
  },
});
