




































































































































































































import moment from 'moment';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { Offender } from '@/vuex/offender/offender';
import { Participant } from '@/vuex/court/participant';
import { Profile } from '@/vuex/profile/profile';

import ChargesSummary from '@/components/charges/ChargesSummary.vue';
import OffenderCustodyStatus from '@/views/offender/OffenderCustodyStatus.vue';
import OffenderDossierLinkDialog from '@/components/offender/OffenderDossierLinkDialog.vue';
import OffenderFlags from '@/components/offender/classification/OffenderFlags.vue';
import OffenderName from '@/components/offender/OffenderName.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('housing', [
      'cellName',
      'facilityWithID',
      'fullFacilityName',
    ]),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['participantInDossier']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    profile(): Profile | null {
      if (this.offender && this.offender.profileID) {
        return this.profileWithID(this.offender.profileID);
      }
      return null;
    },

    participant(): Participant | null {
      if (this.offender && this.profile) {
        return this.participantInDossier(
          this.offender.dossierID,
          this.profile.partyID,
        );
      }
      return null;
    },

    /**
     * percentTimeServed calculates the percentage of this prisoner's
     * sentence that has already been served. In order to avoid confusing
     * display, this returns 0 for detainees and for folks without an
     * imprisonmentDurationInHours.
     */
    percentTimeServed(): number {
      if (!this.offender) {
        return 0;
      }
      if (this.offender.isDetainee) {
        return 0;
      }

      const hoursToServe = this.offender.imprisonmentDurationInHours;
      if (hoursToServe <= 0) {
        // Avoid divide by zero
        return 0;
      }

      const now = moment();
      const start = moment(this.offender.detainedAt);
      const hoursServed = now.diff(start, 'hours');
      return (hoursServed / hoursToServe) * 100.0;
    },

    /**
     * detaineePrisonerLabel returns 'Detainee', 'Prisoner', or 'Confinee'
     * as appropriate to this offender's classification.
     */
    detaineePrisonerLabel(): string {
      if (!this.offender) {
        return '';
      }

      if (this.offender.isDetainee) {
        return i18n.t('offender.detainee.singular').toString();
      }

      if (this.offender.isJuvenile) {
        return i18n.t('offender.confinee.singular').toString();
      }

      return i18n.t('offender.prisoner.singular').toString();
    },
  },
  components: {
    ChargesSummary,
    OffenderCustodyStatus,
    OffenderDossierLinkDialog,
    OffenderFlags,
    OffenderName,
  },
});
