





























































































































import _ from 'lodash';
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';
import { Program } from '@/vuex/offender/program';
import OffenderProgramActions from './OffenderProgramActions.vue';

import BeginOffenderProgramDialog from '@/components/offender/programs/BeginOffenderProgramDialog.vue';

/**
 * OffenderProgramsTable displays all programs an offender has participated in
 * or is currently participating in.
 */
export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },

  created(): void {
    /**
     * At startup, we only want to fetch data if an offenderID was correctly
     * provided. A separate watcher on offenderID (see below) will trigger
     * data fetch if the offenderID is changed later.
     */
    if (this.offenderID) {
      this.fetchData();
    }
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('housing', ['facilityName']),
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('offender', ['courses']),
    ...mapGetters('offender', ['programsForOffender', 'offenderIsReleased']),

    /**
     * programs builds the current list of the offender's programs from
     * the Vuex
     */
    programs(): Program[] {
      return this.programsForOffender(this.offenderID);
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },

    /**
     * canAdd returns true if its acceptable to add a new Sentence Adjustment
     */
    canAdd(): boolean {
      if (this.isReleased) {
        return false;
      }
      return this.hasPermission('offender.program.create');
    },
  },

  watch: {
    /**
     * If offenderID prop is changed on this component, we need to ensure
     * data for the new Offender is fetched.
     */
    offenderID(val: number): void {
      if (this.offenderID) {
        this.fetchData();
      }
    },
  },

  methods: {
    /**
     * fetchData asks the Vuex store to fetch all programs for this offender
     */
    fetchData(): void {
      this.$store.dispatch('offender/fetchPrograms', {
        offenderID: this.offenderID,
      });
    },
  },
  components: {
    BeginOffenderProgramDialog,
    OffenderProgramActions,
  },
});
