import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Hearing {
  id: number;
  caseID: number;
  typeID: number;
  hearingDate: string;
  time: string;
  courtroomID: number;
  notes: string;
  isCancelled: boolean;
  cancellationReasonID: number;
  cancellationNotes: string;
  filedAt: Date;
  registeredAt: string;
}

export const state = {
  hearing: {} as { [id: number]: Hearing },
  hearingIDsForCase: {} as { [id: number]: number[] },
  hearingIDsForCourt: {} as { [id: number]: number[] },
};

export const getters = {
  hearingsForCase:
    (state) =>
    (caseID: number): Hearing[] => {
      return (state.hearingIDsForCase[caseID] || []).map(
        (id: number) => state.hearing[id],
      ) as Hearing[];
    },
  hearingsForCourt:
    (state) =>
    (courtID: number): Hearing[] => {
      return (state.hearingIDsForCourt[courtID] || []).map(
        (id: number) => state.hearing[id],
      ) as Hearing[];
    },
  hearingWithID:
    (state) =>
    (id: number): Hearing | null => {
      return state.hearing[id] || null;
    },
};

export const mutations = {};

export const actions = {
  async fetchHearings({ commit }, payload: { caseID: number }) {
    const response = await courtAPI.get(`cases/${payload.caseID}/hearings`);
    const hearings = response.data.data;
    const newState = modelsToState('hearing', hearings);
    commit('setState', newState);
    commit('setTarget', {
      target: 'hearingIDsForCase',
      index: payload.caseID,
      value: _.map(hearings, 'id'),
    });
  },
  async fetchCourtHearings({ commit }, payload: { courtID: number }) {
    const response = await courtAPI.get(
      `courts/${payload.courtID}/upcoming-hearings`,
    );
    const hearings = response.data.data;
    const newState = modelsToState('hearing', hearings);
    commit('setState', newState);
    commit('setTarget', {
      target: 'hearingIDsForCourt',
      index: payload.courtID,
      value: _.map(hearings, 'id'),
    });
  },
};
