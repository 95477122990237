













import Vue from 'vue';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';
import { Participant } from '@/vuex/court/participant';
import { Party } from '@/vuex/court/party';
import { PartyTypeID } from '@/vuex/court/party';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    label: String,
    value: Number,
    items: {
      type: Array,
      default: () => [] as Party[],
    },
    dossierID: Number,
    caseID: Number,
    disabled: Boolean,
    clearable: Boolean,
    defendantsOnly: Boolean,
    rules: Array,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['party', 'partyTypes']),
    ...mapGetters('court', [
      'participantsForCase',
      'participantsForDossier',
      'partyWithID',
      'partyName',
    ]),
    defaultLabel(): string {
      if (this.defendantsOnly) {
        return this.nameForIDInCollection(
          PartyTypeID.Defendant,
          this.partyTypes,
        );
      }
      return i18n.t('party.singular').toString();
    },
    participants(): Participant[] {
      let participants: Participant[] = [];
      if (this.caseID) {
        participants = this.participantsForCase(this.caseID);
      } else if (this.dossierID) {
        participants = this.participantsForDossier(this.dossierID);
      }
      if (this.defendantsOnly) {
        participants = _.filter(participants, {
          partyTypeID: PartyTypeID.Defendant,
        });
      }
      return participants;
    },
    choices(): VSelectItem[] {
      let parties: Party[] = [];
      if (this.items.length > 0) {
        parties = this.items as Party[];
      } else if (this.participants.length) {
        parties = _.map(this.participants, (p) => this.partyWithID(p.partyID));
      }
      return _.chain(parties)
        .map((p) => ({ value: p.id, text: this.partyName(p.id) }))
        .sortBy('text')
        .value();
    },
  },
  methods: {
    partyChanged(newVal) {
      // newVal is updating when the party is changed... this should be sending it Form
      this.$emit('input', newVal);
    },
  },
});
