
















































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Offender } from '@/vuex/offender/offender';
import { Treatment } from '@/vuex/offender/medical';

import NewTreatmentDialog from './NewTreatmentDialog.vue';

export default Vue.extend({
  props: {
    id: Number,
    contentOnly: Boolean,
  },

  created(): void {
    if (this.id) {
      this.fetchData();
    }
  },

  data(): any {
    return {
      adding: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('housing', ['facilityName']),
    ...mapGetters('offender', [
      'treatmentsForOffender',
      'offenderIsReleased',
      'offenderWithID',
    ]),
    ...mapGetters('language', ['nameForIDInCollection']),

    offender(): Offender {
      return this.offenderWithID(this.id);
    },

    treatments(): Treatment[] {
      return this.treatmentsForOffender(this.id);
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.id);
    },

    /**
     * canAdd returns true if its acceptable to add a new Sentence Adjustment
     */
    canAdd(): boolean {
      return (
        !this.isReleased &&
        this.hasPermission(
          'offender.medical.createTreatment',
          'facility',
          this.offender.housingFacilityID,
        )
      );
    },
  },

  watch: {
    offenderID(val: number): void {
      if (this.id) {
        this.fetchData();
      }
    },
  },

  methods: {
    fetchData(): void {
      this.$store.dispatch('offender/fetchTreatments', {
        offenderID: this.id,
      });
    },
  },
  components: {
    NewTreatmentDialog,
  },
});
