

































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { AccompanyingChild } from '@/vuex/offender/accompanyingChild';
import { Offender, Flags } from '@/vuex/offender/offender';
import { Profile } from '@/vuex/profile/profile';

import AddAccompanyingChildDialog from '@/components/offender/accompanyingChild/AddAccompanyingChildDialog.vue';
import EditAccompanyingChildDialog from '@/components/offender/accompanyingChild/EditAccompanyingChildDialog.vue';
import ReleaseAccompanyingChildDialog from '@/components/offender/accompanyingChild/ReleaseAccompanyingChildDialog.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },

  created(): void {
    /**
     * At startup, we only want to fetch data if an offenderID was correctly
     * provided. A separate watcher on offenderID (see below) will trigger
     * data fetch if the offenderID is changed later.
     */
    if (this.offenderID) {
      this.fetchData();
    }
  },

  computed: {
    ...mapGetters('offender', [
      'offenderWithID',
      'accompanyingChildrenForOffender',
    ]),
    ...mapGetters('profile', ['profileWithID']),

    offender(): Offender | null {
      return this.offenderWithID(this.offenderID) || null;
    },

    profile(): Profile | null {
      if (!this.offender) {
        return null;
      }
      return this.profileWithID(this.offender.profileID) || null;
    },

    children(): AccompanyingChild[] {
      return this.accompanyingChildrenForOffender(this.offenderID);
    },

    childrenTableVisible(): boolean {
      return this.withChildFlag || this.children.length > 0;
    },

    canAddChildren(): boolean {
      return this.withChildFlag;
    },

    withChildFlag(): boolean {
      if (this.offender) {
        if (this.offender.flagIDs.includes(Flags.withChildID)) {
          return true;
        }
      }
      if (this.profile) {
        if (this.profile.motherChildConcernIDs.includes(Flags.withChildID)) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    /**
     * If offenderID prop is changed on this component, we need to ensure
     * data for the new Offender is fetched.
     */
    offenderID(val: number): void {
      if (this.offenderID) {
        this.fetchData();
      }
    },
  },

  methods: {
    /**
     * fetchData asks the Vuex store to fetch all children for this Offender and
     * update local state with the results. We don't need to listen for a
     * response because the `children` computed property will update automatically.
     */
    fetchData(): void {
      this.$store.dispatch('offender/fetchAccompanyingChildren', {
        offenderID: this.offenderID,
      });
    },
  },

  components: {
    AddAccompanyingChildDialog,
    EditAccompanyingChildDialog,
    ReleaseAccompanyingChildDialog,
  },
});
