import _ from 'lodash';
import Vue from 'vue';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Exam {
  id: number;
  offenderID: number;
  housingFacilityID: number;
  createdAt: Date;
  visitTypeID: number;
  doctor: string;
  chiefComplaint: string;
  historyOfPresentIllness: string;
  diagnosis: string;
  prescription: string;
  followUpAt: Date;
  hospitilizationRecommended: boolean;
  pregnant: boolean;
  breastfeeding: boolean;
  infectiousDiseaseIDs: number[];
  drugIDs: number[];
  mentalDisorderIDs: number[];
  disabilityIDs: number[];
  appearance: string;
  behavior: string;
  speech: string;
  mood: string;
  affect: string;
  thoughts: string;
  perception: string;
  insight: string;
  judgement: string;
  cognition: string;
  remarks: string;
}

export const enum VisitType {
  Exam = 1,
  FollowUp = 2,
  Evaluation = 3,
  LabResults = 4,
  Other = 5,
}
export interface Treatment {
  id: number;
  offenderID: number;
  createdAt: Date;
  treatmentTypeID: number;
  administeredBy: string;
  treatmentDescriptin: string;
  remarks: string;
  housingFacilityID: number;
}

export const enum TreatmentType {
  Medication = 1,
  PhysicalTherapy = 2,
  Other = 3,
}
export interface HealthHistoryRecord {
  id: number;
  offenderID: number;
  createdAt: Date;
  medicalProfessional: string;
  notes: string;
}

export interface IntakeHealthScreening {
  id: number;
  offenderID: number;
  createdAt: Date;
  housingFacilityID: number;
  hasPrescribedMedication: boolean;
  prescribedMedication: string;
  isAllergic: boolean;
  allergicMedication: string;
  hasBeenUnderPhysicianCare: boolean;
  careForWhat: string;
  hasBeenHospitalized: boolean;
  hospitalizedWhereWhat: string;
  hasDisabilitiesOrMentalHealth: boolean;
  whatDisabilitiesOrIssues: string;
  hasHeartMurmurOrTreatedCondition: boolean;
  hasBeenTreatedForTumorOrCancer: boolean;
  hasProlongedBleeding: boolean;
  hasLatexAllergy: boolean;
  useNonprescriptionDrugs: boolean;
  isPregnant: boolean;
  infectiousDiseaseIDs: number[];
  healthConditionIDs: number[];
  motherChildConcernIDs: number[];
  unlistedHealthCondition: string;
  hasInjuryAbuse: boolean;
  injuryAbuse: string;
  hasSufferedDisease: boolean;
  sufferedDisease: string;
}

export const state = {
  exam: {} as { [id: number]: Exam },
  examIDsForOffender: {} as { [id: number]: Exam },

  treatment: {} as { [id: number]: Treatment },
  treatmentIDsForOffender: {} as { [id: number]: number[] },

  healthHistoryRecord: {} as { [id: number]: HealthHistoryRecord },
  healthHistoryRecordIDsForOffender: {} as { [offenderID: number]: number[] },

  intakeHealthScreening: {} as { [id: number]: IntakeHealthScreening },
  intakeHealthScreeningIDsForOffender: {} as { [id: number]: number[] },
};

export const getters = {
  examsForOffender:
    (state) =>
    (offenderID: number): Exam[] => {
      return (state.examIDsForOffender[offenderID] || []).map(
        (id: number) => state.exam[id],
      ) as Exam[];
    },
  intakeHealthScreeningsForOffender:
    (state) =>
    (offenderID: number): IntakeHealthScreening[] => {
      return (state.intakeHealthScreeningIDsForOffender[offenderID] || []).map(
        (id: number) => state.intakeHealthScreening[id],
      ) as IntakeHealthScreening[];
    },
  healthHistoryRecordsForOffender:
    (state) =>
    (offenderID: number): HealthHistoryRecord[] => {
      return (state.healthHistoryRecordIDsForOffender[offenderID] || []).map(
        (id: number) => state.healthHistoryRecord[id],
      ) as HealthHistoryRecord[];
    },
  treatmentsForOffender:
    (state) =>
    (offenderID: number): Treatment[] => {
      return (state.treatmentIDsForOffender[offenderID] || []).map(
        (id: number) => state.treatment[id],
      ) as Treatment[];
    },
};

export const mutations = {
  setIntakeHealthScreeningsForOffender(
    state,
    payload: { offenderID: number; screenings: IntakeHealthScreening[] },
  ) {
    for (const screening of payload.screenings) {
      Vue.set(state.intakeHealthScreening, screening.id, screening);
    }
    const ids = _.map(payload.screenings, 'id');
    Vue.set(state.intakeHealthScreeningIDsForOffender, payload.offenderID, ids);
  },
  /**
   * addIntakeHealthScreeningToOffender associates a newly-created
   * IntakeHealthScreening to its Offender by ID
   */
  addIntakeHealthScreeningToOffender(
    state,
    payload: { offenderID: number; screeningID: number },
  ) {
    const ids =
      state.intakeHealthScreeningIDsForOffender[payload.offenderID] || [];
    ids.unshift(payload.screeningID);
    Vue.set(state.intakeHealthScreeningIDsForOffender, payload.offenderID, ids);
  },
};

export const actions = {
  /**
   * fetchExams fetches the complete list of Exams for the referenced
   * Offender from the server and replaces all local Exam state
   * for that Offender.
   */
  async fetchExams({ commit }, payload: { offenderID: number }) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/exams`,
    );
    const exams = response.data.data;
    const newState = modelsToState('exam', exams);
    commit('setState', newState);
    commit('setTarget', {
      target: 'examIDsForOffender',
      index: payload.offenderID,
      value: _.map(exams, 'id'),
    });
  },
  /**
   * fetchIntakeHealthScreenings fetches the complete list of
   * IntakeHealthScreenings from the server and replaces all local
   * IntakeHealthScreening records for that Offender.
   */
  async fetchIntakeHealthScreenings(
    { commit },
    payload: { offenderID: number },
  ) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/intake-health-screenings`,
    );
    const screenings = response.data.data;
    const newState = modelsToState('intakeHealthScreening', screenings);
    commit('setState', newState);
    commit('setTarget', {
      target: 'intakeHealthScreeningIDsForOffender',
      index: payload.offenderID,
      value: _.map(screenings, 'id'),
    });
  },
  /**
   * fetchTreatments fetches the complete list of Treatments for the
   * referenced Offender from the server.
   */
  async fetchTreatments({ commit }, payload: { offenderID: number }) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/treatments`,
    );
    const treatments = response.data.data;
    const newState = modelsToState('treatment', treatments);
    commit('setState', newState);
    commit('setTarget', {
      target: 'treatmentIDsForOffender',
      index: payload.offenderID,
      value: _.map(treatments, 'id'),
    });
  },
  /**
   * fetchHealthHistoryRecords fetches the complete list of
   * HealthHistoryRecords for the referenced Offender from the server and
   * replaces all local state.
   */
  async fetchHealthHistoryRecords({ commit }, payload: { offenderID: number }) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/health-history-records`,
    );
    const healthHistoryRecords = response.data.data;
    const newState = modelsToState('healthHistoryRecord', healthHistoryRecords);
    commit('setState', newState);
    commit('setTarget', {
      target: 'healthHistoryRecordIDsForOffender',
      index: payload.offenderID,
      value: _.map(healthHistoryRecords, 'id'),
    });
  },
};
