





















































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import router from '@/router';
import {
  changedCriteria,
  criteriaToQueryString,
  routeToCriteria,
} from '@/lib/criteria';

import CaseTypeSelector from '@/components/court/CaseTypeSelector.vue';
import CourtSelector from '@/components/court/court/CourtSelector.vue';
import CourtStageSelector from '@/components/court/CourtStageSelector.vue';
import CrimeSelector from '@/components/crime/CrimeSelector.vue';
import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';
import PartySearchSelector from '@/components/court/party/PartySearchSelector.vue';

import { Case } from '@/vuex/court/case';

export default Vue.extend({
  props: {
    /**
     * route expects the $route object from the parent component. It is optional
     * and should only be supplied by OffenderList, where we *want* a tight
     * connection between the page URL and the OffenderSearcher criteria object.
     */
    route: Object,

    /**
     * dense triggers a more condensed design for the component, which is useful
     * when it appears in a popup or other compressed space.
     */
    dense: Boolean,
  },

  created() {
    if (this.route) {
      this.routeChanged();
    }

    // Load the initial data...
    this.fetchData();

    // ...then make every future calls to this.fetchData or this.routeChanged
    // be debounced so that they can never be called faster than once
    // every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);

    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.routeChanged = _.debounce(this.routeChanged, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,
      advancedSearch: false,

      initialCriteria: {},
      criteria: {
        q: '',

        activeOnly: false,
        finalOnly: false,
        disposedOnly: false, // No UI yet. Exists on backend for some widgets
        pastDeadlineOnly: false,
        nearingDeadlineOnly: false,

        statusID: 0,
        stageID: 0,
        caseType: '',

        number: '',
        dossierNumber: '',

        courtID: 0,
        locationID: 0,

        judgeID: 0,
        partyIDs: [],
        partyMatchAll: false,
        partyTypeID: 0,

        crimeIDs: [],
        crimeMatchAll: false,
        chargeDispositionID: 0,
        severityID: 0,

        filedAtOrAfter: '',
        filedAtOrBefore: '',

        disposedBetween: '',

        withDocketEventTypeID: 0,
        withoutDocketEventTypeID: 0,
      },

      results: [] as Case[],

      page: {
        number: 1,
        size: 10,
        serverCount: 0,
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * criteriaUnchanged returns true when the currently-active criteria
     * is unchanged from its original, default state on the form.
     */
    criteriaUnchanged(): boolean {
      return _.isEqual(this.criteria, this.initialCriteria);
    },

    /**
     * queryString computes the URL querystring parameters which will be
     * submitted to the server to perform the search. NOTE that this
     * value is combined with pagination criteria inside fetchData, so this
     * value doesn't exactly match the API request.
     */
    queryString(): string {
      if (_.isEmpty(this.initialCriteria)) {
        this.initialCriteria = _.cloneDeep(this.criteria);
      }
      const cc = changedCriteria(this.criteria, this.initialCriteria);
      return criteriaToQueryString(cc, this.page);
    },

    /**
     * canExport computes whether the current user is allowed to download
     * an Excel export of the data on the screen.
     */
    canExport(): boolean {
      return this.hasPermission('case.excelExport');
    },

    /**
     * headers dynamically builds the headers array so that translations for
     * the column titles can update live, and so that the edit link column is
     * removed *automatically when the selectable prop is set
     */
    headers(): any[] {
      return [
        {
          text: i18n.t('dossier.dossierNumber'),
          value: 'dossierNumber',
          width: '203px',
        },
        { text: i18n.t('case.caseNumber.singular'), value: 'number' },
        { text: i18n.t('case.filedAt'), value: 'filedAt' },
        { text: i18n.t('court.status'), value: 'statusID' },
        { text: i18n.t('party.plural'), value: 'partyNames' },
        { text: i18n.t('judge.plural'), value: 'judgeNames' },
      ];
    },
  },

  watch: {
    /**
     * route is watched so that when the $route of the topmost component
     * changes, we can update the query string. Note that we don't watch
     * $route directly from this component (note the lack of a dollar sign here),
     * because that would potentially trigger at undesirable times.
     *
     * Since we can search for Cases from other places than the full
     * CaseList page, we don't always want the route to be tied to this
     * component's behavior.
     */
    route(route: any) {
      this.routeChanged();
    },

    /**
     * queryString is watched so that a new search can be triggered.
     */
    queryString(newVal: string) {
      this.fetchData();
    },

    /**
     * criteria is watched so that when the search criteria is changed after
     * results have already been returned, we can reset the page number.
     */
    criteria: {
      deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        if (this.page.serverCount > 0 && this.page.number > 1) {
          this.page.number = 1;
        }
      },
    },

    /**
     * advancedSearch changes the UI, so we need to make sure to reset
     * the no longer visible fields
     */
    advancedSearch(isAdvanced: boolean) {
      if (isAdvanced) {
        this.resetSimpleCriteria();
      } else {
        this.resetAdvancedCriteria();
      }
    },
  },

  methods: {
    /**
     * routeChanged is triggered when the page URL changes.
     */
    routeChanged(): void {
      routeToCriteria(this.route, this.criteria, this.page);
      if (!this.criteriaUnchanged && this.criteria.q === '') {
        this.advancedSearch = true;
      }
    },

    /**
     * fetchData triggers the the API endpoint and updates the table with the
     * results for the currently-selected page. This function is triggered
     * both when the criteria changes and when the page changes.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;

      try {
        const url = `/cases/search?${this.queryString}`;
        const resp = await courtAPI.get(url);
        this.page.serverCount = _.get(resp, 'data.meta.totalResults', 0);
        this.results = _.get(resp, 'data.data', []);
        this.$emit('queryChanged', this.queryString);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    /**
     * reset clears the search and resets search results. Primarily designed
     * to be called externally via a $ref to this component so that the searcher
     * can be reset when a dialog is opened.
     */
    reset(): void {
      this.resetSimpleCriteria();
      this.resetAdvancedCriteria();
    },

    resetSimpleCriteria(): void {
      this.criteria.q = '';
    },

    resetAdvancedCriteria(): void {
      this.criteria = Object.assign(this.criteria, this.initialCriteria);
    },

    /**
     * goToCase is the row click handler for case search results. It triggers
     * navigation to the case detail page for the case in that row.
     */
    goToCase(kase: Case): void {
      router.push({
        name: 'caseShow',
        params: { id: String(kase.id) },
      });
    },
  },

  components: {
    CaseTypeSelector,
    CourtSelector,
    CourtStageSelector,
    CrimeSelector,
    JudgeSelector,
    PartySearchSelector,
  },
});
