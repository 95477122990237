
































































import _ from 'lodash';
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';
import { Hearing } from '@/vuex/court/hearing';

export default Vue.extend({
  props: {
    courtID: Number,
  },

  data() {
    return {
      loading: false,
    };
  },

  created(): void {
    this.fetchData();
  },

  computed: {
    ...mapGetters('court', ['hearingsForCourt', 'courtroomName']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['hearingTypes']),

    /**
     * hearings returns the filtered array of hearings which should
     * be displayed based on current filter settings
     */
    hearings(): Hearing[] {
      let hearings = [] as Hearing[];
      hearings = this.hearingsForCourt(this.courtID);
      // Remove cancelled hearings
      hearings = _.filter(hearings, (h) => !h.isCancelled);
      return _.sortBy(hearings, (h) => h.hearingDate);
    },

    /**
     * hearingsIndexedByDate groups the hearings by date after they've
     * been filtered.
     */
    hearingsIndexedByDate(): any {
      return _.groupBy(this.hearings, (h) => h.hearingDate);
    },
  },

  methods: {
    fetchData(): void {
      this.fetchHearingsForCourt();
    },

    fetchHearingsForCourt(): void {
      this.$store.dispatch('court/fetchCourtHearings', {
        courtID: this.courtID,
      });
    },
  },
  components: {},
});
