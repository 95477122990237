import _ from 'lodash';
import Vue from 'vue';
import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface SubjectProperty {
  id?: number; // Unset during creation when creating New Dossier
  dossierID: number;
  typeID: number;
  label: string;
  description: string;
  drugID: number;
  currencyID: number;
  foreignCurrencyAmount: number;
  locationGeographyID: number;
  locationDetails: string;
  landAreaInSquareMeters: number;
  itemValueInPuls: number;
  quantity: number;
  totalValueInPuls: number;
  isDeleted: boolean;
}

export const enum SubjectPropertyTypeID {
  Unknown = 0,
  Movable = 1,
  Immovable = 2,
  Drugs = 3,
  Currency = 4,
}

export const state = {
  subjectProperty: {} as { [id: number]: SubjectProperty },
  subjectPropertyIDsForDossier: {} as { [dossierID: number]: number[] },
};

export const getters = {
  subjectPropertyForDossier:
    (state) =>
    (dossierID: number): SubjectProperty[] => {
      const properties = state.subjectPropertyIDsForDossier[dossierID] || [];
      return properties.map(
        (id: number) => state.subjectProperty[id],
      ) as SubjectProperty[];
    },
  subjectPropertyTotalValueInPulsForDossier:
    (state, getters) =>
    (dossierID: number): number => {
      const properties = getters.subjectPropertyForDossier(dossierID) || [];
      return _.chain(properties)
        .map((subjectProperty) => subjectProperty.totalValueInPuls)
        .sum()
        .value();
    },
  subjectPropertyWithID:
    (state) =>
    (id: number): SubjectProperty => {
      return state.subjectProperty[id];
    },
  /**
   * subjectPropertyName builds a short string describing one or more subject
   * properties when provided with their IDs. This function depends on the
   * subject property already having been loaded into Vuex.
   */
  subjectPropertyName:
    (state, getters, rootState, rootGetters) =>
    (subjectPropertyID: number | number[]): string => {
      const ids = _.flatten([subjectPropertyID]);
      return _.chain(ids)
        .map((id: number) => {
          const subProp = state.subjectProperty[id];
          if (!subProp) {
            return `Unknown Subject Property: ${id}`;
          }

          const nfIDInCol = rootGetters['language/nameForIDInCollection'];
          if (
            subProp.typeID === SubjectPropertyTypeID.Drugs &&
            subProp.drugID
          ) {
            return nfIDInCol(subProp.drugID, 'court/illegalDrugs');
          }
          if (
            subProp.typeID === SubjectPropertyTypeID.Currency &&
            subProp.currencyID
          ) {
            return nfIDInCol(subProp.currencyID, 'court/currencies');
          }
          let label = subProp.label;
          if (subProp.description) {
            label = label + ' - ' + subProp.description;
          }
          return label;
        })
        .value()
        .join(', ');
    },
};

export const mutations = {
  /**
   * reindexSubjectPropertiesForDossier re-computes the
   * subjectPropertyIDsForDossier index from scratch by scanning the entire
   * local subjectProperty state and finding the ones with the provided
   * dossierID. This mutation is intended to be called after new subject
   * properties are added to the state.
   */
  reindexSubjectPropertiesForDossier(state, dossierID): void {
    const all: SubjectProperty[] = Object.values(state.subjectProperty);
    const ids = _.chain(all).filter({ dossierID }).map('id').value();
    Vue.set(state.subjectPropertyIDsForDossier, dossierID, ids);
  },
};

export const actions = {
  async fetchSubjectPropertiesForDossier(
    { commit },
    payload: { dossierID: number },
  ) {
    const response = await courtAPI.get(
      `dossiers/${payload.dossierID}/subject-properties`,
    );
    const subjectProperties = response.data.data;
    const newState = modelsToState('subjectProperty', subjectProperties);
    commit('setState', newState);
    commit('setTarget', {
      target: 'subjectPropertyIDsForDossier',
      index: payload.dossierID,
      value: _.map(subjectProperties, 'id'),
    });
  },
};
