













import _ from 'lodash';
import Vue from 'vue';

import { Jurisdiction } from '@/vuex/court/judge';
import JudgeJurisdictionForm from '@/components/court/judge/JudgeJurisdictionForm.vue';

export default Vue.extend({
  props: {
    value: Object,
  },
  data() {
    return {
      isOpen: false,
      jurisdiction: null as Jurisdiction | null,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      const myCopy = Object.assign({}, this.value);
      this.jurisdiction = myCopy;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    save(): void {
      this.$emit('input', this.jurisdiction);
      this.isOpen = false;
    },
  },
  components: {
    JudgeJurisdictionForm,
  },
});
