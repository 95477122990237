






















































































































































































































































































































import Vue from 'vue';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { IntakeHealthScreening } from '@/vuex/offender/medical';
import { Offender } from '@/vuex/offender/offender';
import { TranslateResult } from 'vue-i18n';

import NewIntakeHealthScreeningDialog from './NewIntakeHealthScreeningDialog.vue';

export default Vue.extend({
  props: {
    id: Number,
    contentOnly: Boolean,
  },

  data(): any {
    return {
      adding: false,
    };
  },

  created(): void {
    this.fetchData();
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', [
      'intakeHealthScreeningsForOffender',
      'offenderIsReleased',
      'offenderWithID',
    ]),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('housing', ['facilityName']),
    ...mapState('offender', [
      'infectiousDiseases',
      'healthConditions',
      'motherAndChildConcerns',
    ]),
    intakeHealthScreenings(): IntakeHealthScreening[] {
      return this.intakeHealthScreeningsForOffender(this.id);
    },

    offender(): Offender {
      return this.offenderWithID(this.id);
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.id);
    },

    /**
     * canAdd returns true if its acceptable to add a new Sentence Adjustment
     */
    canAdd(): boolean {
      return (
        !this.isReleased &&
        this.hasPermission(
          'offender.medical.createIntakeHealthScreening',
          'facility',
          this.offender.housingFacilityID,
        )
      );
    },
  },

  watch: {
    id(newVal: number): void {
      this.fetchData();
    },
  },

  methods: {
    fetchData(): void {
      this.$store.dispatch('offender/fetchIntakeHealthScreenings', {
        offenderID: this.id,
      });
    },
    translateExamType(val): TranslateResult {
      if (val) {
        return this.$i18n.t('medical.exam.visitTypes.' + val);
      }
      return this.$i18n.t('medical.intakes.intakeExam');
    },
    booleanWithExplanation(hasCondition: boolean, message: any): string {
      if (hasCondition) {
        if (message) {
          if (Array.isArray(message)) {
            return this.$i18n.t('command.yes') + ' - ' + message.join(', ');
          } else {
            return this.$i18n.t('command.yes') + ` - ${message}`;
          }
        } else {
          return this.$i18n.t('command.yes');
        }
      } else {
        return this.$i18n.t('command.no');
      }
    },
    findDiseases(val: number[]): string[] {
      return _.map(val, (id) =>
        this.nameForIDInCollection(id, this.infectiousDiseases),
      );
    },
    findHealthCondition(val: number[]): string[] {
      return _.map(val, (id) =>
        this.nameForIDInCollection(id, this.healthConditions),
      );
    },
    findMotherChildConcern(val: number[]): string[] {
      return _.map(val, (id) =>
        this.nameForIDInCollection(id, this.motherAndChildConcerns),
      );
    },
  },
  components: {
    NewIntakeHealthScreeningDialog,
  },
});
