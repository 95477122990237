


































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import PrimaryDetectionNav from '@/components/nav/PrimaryDetectionNav.vue';
import PrimaryInvestigationNav from '@/components/nav/PrimaryInvestigationNav.vue';
import PrimaryCourtNav from '@/components/nav/PrimaryCourtNav.vue';
import PrimaryPrisonNav from '@/components/nav/PrimaryPrisonNav.vue';
import PrimaryAdministrationNav from '@/components/nav/PrimaryAdministrationNav.vue';

export default Vue.extend({
  data() {
    return {
      waiting: true,
    };
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['hasPermission']),
  },

  components: {
    PrimaryCourtNav,
    PrimaryDetectionNav,
    PrimaryInvestigationNav,
    PrimaryPrisonNav,
    PrimaryAdministrationNav,
  },
});
