


































































































































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

import i18n from '@/i18n';

import { LegacyCrime } from '@/vuex/crime/crime';

import ChargeName from '@/components/charges/ChargeName.vue';
import CrimeAdminMenu from '@/components/crime/CrimeAdminMenu.vue';
import EditLegacyCrimeDialog from '@/components/crime/legacyCrime/EditLegacyCrimeDialog.vue';

export default Vue.extend({
  created(): void {
    this.fetchData();
  },
  data() {
    return {
      loading: true,
      search: '',
      filterMode: '',
    };
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['align']),
    ...mapGetters('crime', ['allCrimes', 'allLegacyCrimes', 'crimeWithID']),

    /**
     * legacyCrimes is the initial data set of the Legacy Crimes entities,
     * with a few table-specific extra columns added.
     */
    legacyCrimes(): any[] {
      const all: LegacyCrime[] = this.allLegacyCrimes;
      return _.chain(all)
        .map((lc) =>
          Object.assign({}, lc, {
            reviewScore: this.reviewScore(lc),
            legacyNameTranslated: lc.legacyName[this.locale],
          }),
        )
        .sortBy('legacyID')
        .value();
    },

    /**
     * headers builds the VTable column headers. It needs to be a computed prop
     * so that translations of the column header text are made when the
     * translation is changed.
     */
    headers(): any[] {
      const h = [
        {
          text: i18n.t('legacyCrime.legacyID'),
          value: 'legacyID',
        },
        {
          text: i18n.t('legacyCrime.legacyName'),
          value: 'legacyNameTranslated',
          width: '48%',
        },
        {
          text: i18n.t('legacyCrime.crimeID'),
          value: 'crimeID',
          align: 'right',
        },
        {
          text: i18n.t('legacyCrime.crime'),
          value: 'crimeTitleTranslated',
          width: '48%',
        },
        {
          text: i18n.t('review.progress'),
          value: 'reviewScore',
          width: 36,
        },
      ];
      return h;
    },

    /**
     * filteredLegacyCrimes applies the selected filter to the legacyCrimes
     * items array.
     */
    filteredLegacyCrimes(): LegacyCrime[] {
      const crimes = this.legacyCrimes;

      let criteria: any = null;
      switch (this.filterMode) {
        case 'unmapped':
          criteria = { crimeID: 0 };
          break;
        case 'needsReview':
          criteria = (lc: LegacyCrime) => !lc.lastReviewedByUserID;
          break;
        case 'needsApproval':
          criteria = (lc: LegacyCrime) =>
            !!lc.lastReviewedByUserID && !lc.lastApprovedByUserID;
          break;
        case 'needsGiroaReview':
          criteria = (lc: LegacyCrime) =>
            !!lc.lastReviewedByUserID &&
            !!lc.lastApprovedByUserID &&
            !lc.lastGiroaReviewByUserID;
          break;
        case 'needsGiroaApproval':
          criteria = (lc: LegacyCrime) =>
            !!lc.lastReviewedByUserID &&
            !!lc.lastApprovedByUserID &&
            !!lc.lastGiroaReviewByUserID &&
            !lc.lastGiroaApprovedByUserID;
          break;
        case 'completed':
          criteria = (lc: LegacyCrime) =>
            !!lc.lastReviewedByUserID &&
            !!lc.lastApprovedByUserID &&
            !!lc.lastGiroaReviewByUserID &&
            !!lc.lastGiroaApprovedByUserID;
          break;
      }

      if (criteria) {
        return _.filter(crimes, criteria);
      }
      return crimes;
    },

    /**
     * editIcon flips the mapping button icon to point the correct direction
     * based on the selected language direction.
     */
    editIcon(): string {
      if (this.align === 'left') {
        return 'fa-arrow-right';
      }
      return 'fa-arrow-left';
    },
  },

  methods: {
    /**
     * fetchData loads the full list of Legacy Crime mappings. It is not loaded
     * by default.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('crime/fetchLegacyCrimes', true);
      this.loading = false;
    },

    /**
     * reviewScore maps a LegacyCrime to its score of out 100 representing how
     * much of the review process is completed for this LegacyCrime.
     */
    reviewScore(lc: LegacyCrime): number {
      let count = 0;
      const fields = ['Reviewed', 'Approved', 'GiroaReview', 'GiroaApproved'];
      for (const prefix of fields) {
        const key = `last${prefix}ByUserID`;
        if (lc[key] > 0) {
          count = count + 1;
        }
      }
      if (count === 0) {
        return 10;
      }
      return (count / 4.0) * 100;
    },

    /**
     * reviewColor returns the progressbar color based on its value.
     */
    reviewColor(score: number) {
      if (score >= 90) {
        return 'green darken-2';
      }
      if (score >= 75) {
        return 'green lighten-2';
      }
      if (score >= 25) {
        return 'orange darken-2';
      }
      return 'red';
    },

    /**
     * overallCompletion accepts a field prefix (like 'Reviewed'), and scans the
     * crimes to find how many have that field checked. The result is returned
     * as a percentage (out of 100) for use in a v-progressbar.
     */
    overallCompletion(prefix: string): number {
      const key = `last${prefix}ByUserID`;
      const total = this.legacyCrimes.length;
      const finished = _.filter(this.legacyCrimes, (lc) => !!lc[key]).length;
      return (finished / total) * 100;
    },
  },
  components: {
    ChargeName,
    CrimeAdminMenu,
    EditLegacyCrimeDialog,
  },
});
