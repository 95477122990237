
























































import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import AddSubjectPropertyDialog from '@/components/subjectProperty/AddSubjectPropertyDialog.vue';
import EditSubjectPropertyDialog from '@/components/subjectProperty/EditSubjectPropertyDialog.vue';
import SubjectPropertyForm from '@/components/subjectProperty/SubjectPropertyForm.vue';
import SubjectPropertyExpander from '@/components/subjectProperty/SubjectPropertyExpander.vue';

export default Vue.extend({
  props: {
    value: Array,
  },
  computed: {
    ...mapGetters('geography', ['fullGeographyName']),

    subjectProperties(): SubjectProperty[] {
      return (this.value ? this.value : []) as SubjectProperty[];
    },

    headers(): any[] {
      return [
        { value: 'edit', text: i18n.t('command.edit'), width: '5%' },
        { value: 'label', text: i18n.t('subjectProperties.label') },
        { value: 'description', text: i18n.t('subjectProperties.description') },
        { value: 'locationGeographyID', text: i18n.t('location.singular') },
        {
          value: 'quantity',
          align: 'right',
          text: i18n.t('subjectProperties.quantity'),
        },
        {
          value: 'totalValueInPuls',
          align: 'right',
          text: i18n.t('subjectProperties.totalValue'),
        },
        {
          value: 'delete',
          text: i18n.t('command.delete'),
          align: 'center',
          width: '5%',
        },
        { value: null, text: '', width: '5%' },
      ];
    },
  },

  methods: {
    addProperty(p: SubjectProperty) {
      const properties = [...this.subjectProperties, p];
      this.$emit('input', properties);
    },

    removeProperty(i: number) {
      const newItems = [...this.subjectProperties];
      newItems.splice(i, 1);
      this.$emit('input', newItems);
    },
  },

  components: {
    AddSubjectPropertyDialog,
    EditSubjectPropertyDialog,
    SubjectPropertyExpander,
    SubjectPropertyForm,
  },
});
