



















import Vue from 'vue';
import i18n from '@/i18n';

import { mapGetters, mapState } from 'vuex';
import { Attachment } from '@/vuex/attachment/attachment';

import MimeTypeIcon from '@/components/global/attachments/MimeTypeIcon.vue';

export default Vue.extend({
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityID: Number,
    location: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: false,
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      adding: false,
      internalEntityID: 0,
      internalEntityType: 'temp',
    };
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('attachment', ['attachmentsForEntity']),
    labelText(): string {
      if (this.label || this.label === '') {
        return this.label;
      }
      if (this.attachments.length > 1) {
        return i18n.t('attachment.plural').toString();
      } else {
        return i18n.t('attachment.singular').toString();
      }
    },
    attachments(): Attachment[] {
      return this.attachmentsForEntity(
        this.entityType,
        this.entityID,
        this.location,
      );
    },
  },
  methods: {
    openDownloadLink(uri: string): void {
      window.open(uri, '_blank');
    },
    fetchData(): void {
      this.$store.dispatch('attachment/fetchAttachments', {
        entityType: this.entityType,
        entityID: this.entityID,
        location: this.location,
      });
    },
  },
  components: {
    MimeTypeIcon,
  },
});
