var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"shaped":""}},[_c('v-toolbar',{attrs:{"dense":"","color":"primary lighten-4"}},[_c('v-toolbar-title',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.label || _vm.$t('crime.plural'))+" ")]),_c('v-dialog',{attrs:{"max-width":"900","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","absolute":"","bottom":"","x-small":"","color":"accent"}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-plus ")])],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('CrimeSearcher',{ref:"crimeSearcher",attrs:{"excludedIDs":_vm.value,"selectable":"","disable-stats":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"error"},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t('command.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"accent","type":"submit"},on:{"click":function($event){_vm.addPending();
              _vm.isOpen = false;}}},[_vm._v(" "+_vm._s(_vm.$t('command.ok'))+" ")])]},proxy:true}]),model:{value:(_vm.pendingCrimeIDAdditions),callback:function ($$v) {_vm.pendingCrimeIDAdditions=$$v},expression:"pendingCrimeIDAdditions"}})],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.crimes,"items-per-page":15,"sort-by":("title." + _vm.locale)},scopedSlots:_vm._u([{key:("item.corpus.title." + _vm.locale),fn:function(ref){
              var value = ref.value;
return [_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('ConfirmationButton',{attrs:{"small":"","icon":""},on:{"confirm":function($event){return _vm.deleteCrimeWithID(item.id)}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('command.remove'))+": "),_c('CrimeTitle',{attrs:{"id":item.id}}),_vm._v("? ")]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash-alt")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }