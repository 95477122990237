import _ from 'lodash';
import moment from 'moment-timezone';
import jmoment from 'moment-jalaali';

import i18n from '@/i18n';

export const MONTHNAMES = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  fa: [
    'حمل', // Hamal (Aries)
    'ثور', // Sawr (Taurus)
    'جوزا', // Jawzā (Gemini)
    'سرطان', // Saraṭān (Cancer)
    'اسد', // Asad (Leo)
    'سنبله', // Sonbola (Virgo)
    'میزان', // Mizān (Libra)
    'عقرب', // Aqrab (Scorpio)
    'قوس', // Qaws (Sagittarius)
    'جدی', // Jadi (Capricorn)
    'دلو', // Dalvæ (Aquarius)
    'حوت', // Hūt (Pisces)
  ],
  ps: [
    'وری', // Wray (Aries)
    'غويی', // Ǧwayay (Taurus)
    'غبرګولی', // Ǧbargolay (Gemini)
    'چنګاښ', // Čungāx̌ (Cancer)
    'زمری', // Zmaray (Leo)
    'وږی', // Waǵay (Virgo)
    'تله', // Təla (Libra)
    'لړم', // Laṛam (Scorpio)
    'ليندۍ', // Līndəi (Sagittarius)
    'مرغومی', // Marǧūmay (Capricorn)
    'سلواغه', // Salwāǧa (Aquarius)
    'كب', // Kab (Pisces)
  ],
};

/*
  inCurrentJalaaliYear calculates based on the Islamic calendar (starts on 
  March 21 each year).
*/
export function inCurrentJalaaliYear(date: string): boolean {
  if (!date) {
    // An empty date is definitely not in the current year.
    return false;
  }

  if (_.isString(date) === true) {
    const specifiedDate = moment(date);
    const currentNewYear = moment().startOf('year').month(2).day(21);

    let startOfYear;
    let endOfYear;
    if (specifiedDate.isBefore(currentNewYear)) {
      startOfYear = currentNewYear.clone().subtract(1, 'year');
      endOfYear = currentNewYear;
    } else {
      startOfYear = currentNewYear;
      endOfYear = currentNewYear.clone().add(1, 'year');
    }

    return specifiedDate.isBetween(
      startOfYear.startOf('day'),
      endOfYear.endOf('day'),
    );
  } else {
    throw new Error(
      `TypeError: inCurrentJalaaliYear() expects date as type 'string'`,
    );
  }
}

/**
 * toLocalizedDate translates a raw date value into a locale-specific
 * presentation format.
 *
 * @param isoDate A string holding a date in ISO (YYYY-MM-DD) format
 */
export function toLocalizedDate(isoDate: string, withTime?: boolean): string {
  if (!isoDate) {
    return '';
  }

  const rawInTZ = moment.tz(isoDate, 'Asia/Kabul');

  // English Format
  if (i18n.locale === 'en') {
    if (withTime) {
      return rawInTZ.format('DD-MMM-YYYY HH:mm');
    } else {
      return rawInTZ.format('DD-MMM-YYYY');
    }
  }

  // Dari/Pashto Format
  if (withTime) {
    // Because the jmoment library does not support time zones, we have to
    // build the time part with moment.tz and the date part with jmoment,
    // and then combine the two values together.
    const datePart = jmoment(rawInTZ.format()).format('jYYYY-jMM-jDD');
    const timePart = rawInTZ.format('HH:mm');
    return `${datePart} ${timePart}`;
  } else {
    return jmoment(rawInTZ.format(), 'YYYY-MM-DD').format('jYYYY-jMM-jDD');
  }
}

/**
 * toLocalizedTime translates a raw date value into a locale-specific
 * of just its time part.
 *
 * @param isoDate A string holding a date in ISO (YYYY-MM-DDTHH:MM:SS) format
 * @returns
 */
export function toLocalizedTime(isoDate: string): string {
  if (!isoDate) {
    return '';
  }

  if (i18n.locale !== 'en') {
    const jmom = jmoment(String(isoDate), 'YYYY-MM-DD');
    return jmom.format('jYYYY-jMM-jDD');
  }

  const mom = moment(String(isoDate), 'YYYY-MM-DD');
  return mom.format('DD-MMM-YYYY');
}

/**
 * toLocalizedMonthName is a formatting function supplied to the v-date-picker. It
 * is provided with the currently selected date, and is expected to return
 * the string in the header above the month calendar (e.g. October 2020).
 * For the English locale, our implementation is identical to the
 * v-date-picker default. We had to customize to return the correct month
 * names for Dari and Pashto locale displays.
 */
export function toLocalizedMonthName(isoDate: string): string {
  let year = 0;
  let month = 0;
  if (i18n.locale === 'en') {
    const gregorianDate = moment(isoDate);
    year = gregorianDate.year();
    month = gregorianDate.month();
  } else {
    const jalaaliDate = jmoment(isoDate);
    year = jalaaliDate.jYear();
    month = jalaaliDate.jMonth();
  }
  return `${MONTHNAMES[i18n.locale][month]} ${year}`;
}
