
















import _ from 'lodash';
import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import SubjectPropertyForm from '@/components/subjectProperty/SubjectPropertyForm.vue';

export default Vue.extend({
  props: {
    value: Boolean,
    dossierID: {
      default: 0,
      type: Number,
    },
    caseID: {
      default: 0,
      type: Number,
    },
    noActivator: Boolean,
    buttonSize: String,
  },

  created() {
    this.isOpen = !!this.value;
  },

  data() {
    return {
      isOpen: false,
      error: null as any,

      subjectProperty: null as SubjectProperty | null,
    };
  },

  watch: {
    value(newVal) {
      this.isOpen = !!newVal;
    },

    isOpen(newVal) {
      this.$emit('input', !!newVal);
    },
  },

  methods: {
    reset(): void {
      this.subjectProperty = null;
    },

    // save is the event handler for the CMSFormDialog's @save event.
    //
    save(): void {
      if (!this.subjectProperty) {
        return;
      }
      if (this.dossierID) {
        this.saveToServer(this.subjectProperty);
      } else {
        this.saveToParent(this.subjectProperty);
      }
    },

    // saveToParent is used when the dossierID prop was not supplied to this
    // form. We just notify the parent component that a new SubjectProperty
    // was added.
    saveToParent(sp: SubjectProperty): void {
      this.$emit('saved', sp);
      this.isOpen = false;
    },

    // saveToServer is used when the dossierID prop is known and we can persist
    // immediately to the server.
    //
    async saveToServer(sp: SubjectProperty): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post('subject-properties/create', {
          data: {
            dossierID: this.dossierID,
            caseID: this.caseID,
            typeID: sp.typeID,
            drugID: sp.drugID,
            currencyID: sp.currencyID,
            foreignCurrencyAmount: sp.foreignCurrencyAmount,
            label: sp.label,
            description: sp.description,
            locationGeographyID: sp.locationGeographyID,
            locationDetails: sp.locationDetails,
            landAreaInSquareMeters: sp.landAreaInSquareMeters,
            itemValueInPuls: sp.itemValueInPuls,
            quantity: sp.quantity,
            totalValueInPuls: sp.totalValueInPuls,
          },
        });
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/prependToTarget', {
          target: 'subjectPropertyIDsForDossier',
          index: this.dossierID,
          value: Object.keys(newState.subjectProperty),
        });

        // Add to the docket
        this.$store.commit('court/addDocketEntry', response);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    SubjectPropertyForm,
  },
});
