































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import CaseStatusLabel from '@/components/court/case/CaseStatusLabel.vue';

export default Vue.extend({
  props: {
    id: Number,
    color: String,
    unlinked: Boolean,
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['caseWithID', 'courtName']),

    kase(): Case {
      return this.caseWithID(this.id);
    },

    to(): any {
      if (this.unlinked) {
        return undefined;
      }
      return { name: 'caseShow', params: { id: this.kase.id } };
    },
  },

  components: {
    CaseStatusLabel,
  },
});
