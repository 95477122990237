import courtAPI from '@/vuex/court/courtAPI';
import { TranslatedString } from '@/lib/translated';

export interface LookupItem {
  id: number;
  name: TranslatedString;
  type?: string;
  rank?: number;
}

export interface AttorneyType {
  id: number;
  name: string;
}

export interface DocketEventType {
  id: number;
  name: string;
  module: string;
  willChangeDossierStage: boolean;
  newDossierStageID: number;
  willChangeDepartment: boolean;
  newDepartmentID: number;
  willChangeCourtStage: boolean;
  newCourtStageID: number;
  willChangeCaseStatus: boolean;
  willChangeDossierStatus: boolean;
  newCaseStatusID: number;
  caseDispositionReasonID: number;
  newDossierStatusID: number;
  allowedCourtStageIDs: number[];
  allowedDossierStageIDs: number[];
  allowedCaseTypes: string[];
  fields: CustomField[];
  categoryID: number;
  rank: number;
  willSetAwarenessNumber: boolean;
  willFinalizeDossier: boolean;
  isDiscontinued: boolean;
}

export const enum DocketEventTypeModule {
  Investigation = 'investigation',
  Detection = 'detection',
  Court = 'court',
}

export const AllDocketEventTypeModules = [
  DocketEventTypeModule.Detection,
  DocketEventTypeModule.Investigation,
  DocketEventTypeModule.Court,
];

export interface CustomField {
  name: string;
  label: TranslatedString;
  type: string;
  typeArgs: CustomFieldTypeArgs;
  // required: boolean;
  isRequired: boolean;
}

export interface CustomFieldTypeArgs {
  tableName?: string;
  feeCategoryID?: number;
  allowedChoices?: any[];
}

export interface CourtFeeTransaction {
  categoryID: number; // References feeCategories lookup table
  amountInPuls: number;
  calculation?: any;
}

export interface FeeAssessment {
  id?: number;
  dossierID: number;
  caseID: number;
  categoryID: number;
  amountInPuls: number;
  percentOfValue: number;
  disputedValueInPuls: number;
  assessedAt?: string;
}

export interface FeePayment {
  id?: number;
  dossierID: number;
  caseID: number;
  categoryID: number;
  amountInPuls: number;
  paidAt?: string;
}

export const enum CaseTopicID {
  Property = 1,
  Business = 2,
  Financial = 3,
  Administrative = 4,
  Family = 5,
}

export const state = {
  optionsLoaded: false,

  attorneyTypes: [] as LookupItem[],
  cancellationReasons: [] as LookupItem[],
  caseStatuses: [] as LookupItem[],
  caseTypes: [] as LookupItem[],
  chargeDispositionTypes: [] as LookupItem[],
  civilCaseTopics: [] as LookupItem[],
  civilObjectionTypes: [] as LookupItem[],
  courtStages: [] as LookupItem[],
  criminalObjectionTypes: [] as LookupItem[],
  currencies: [] as LookupItem[],
  dispositionReasons: [] as LookupItem[],
  dispositionTypes: [] as LookupItem[],
  docketEventCategories: [] as LookupItem[],
  docketEventTypes: [] as DocketEventType[],
  dossierStages: [] as LookupItem[],
  dossierStatuses: [] as LookupItem[],
  feeCategories: [] as LookupItem[],
  generalAggravators: [] as LookupItem[],
  generalMitigators: [] as LookupItem[],
  hearingTypes: [] as LookupItem[],
  illegalDrugs: [] as LookupItem[],
  judgeRemovalReasons: [] as LookupItem[],
  lawsuitTypes: [] as LookupItem[],
  partyTypes: [] as LookupItem[],
  releaseReasons: [] as LookupItem[],
  subjectPropertyTypes: [] as LookupItem[],
  summonsModes: [] as LookupItem[],
  summonsServiceResults: [] as LookupItem[],
};

export const getters = {};

export const mutations = {
  /**
   * setOptionsLoaded sets the tracking variable which identifies
   * whether the options have been successfully fetched from the
   * API or not.
   */
  setOptionsLoaded(state, isLoaded) {
    state.optionsLoaded = isLoaded;
  },
};

export const actions = {
  /**
   * fetchOptions fetches all dropdown choices for entities in this Vuex
   * store from the API.
   *
   * Most of the time it should be called as fetchOptions({ force: false }),
   * to avoid re-fetching options from the API that have already been
   * loaded.
   */
  async fetchOptions(
    { state, commit },
    payload = {} as { force: boolean },
  ): Promise<boolean> {
    // Early return when the options are already loaded
    if (!payload.force && state.optionsLoaded) {
      return Promise.resolve(true);
    }

    // Fetch all offender-related options from the API
    const response = await courtAPI.get('/options');
    if (response.data && response.data.data) {
      const newState = response.data.data;
      // Set each option array in the state. This will throw
      // an error if the server sends any top-level
      // properties which are not initialized to their
      // initial state (typically an empty array) above in
      // the state definition.
      commit('setState', newState);

      // Set loaded
      commit('setOptionsLoaded', true);
      return Promise.resolve(true);
    }

    // We reach here only in case of failure
    return Promise.reject();
  },
};
