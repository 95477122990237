























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import NewDepartmentDialog from '@/components/investigation/NewDepartmentDialog.vue';
import EditDepartmentDialog from '@/components/investigation/EditDepartmentDialog.vue';
import DepartmentContainer from '@/components/investigation/DepartmentContainer.vue';

export default Vue.extend({
  data() {
    return {
      loading: true,
      search: '',
      grouped: true,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('investigation', ['allDepartments']),
    ...mapGetters('auth', ['hasPermission']),

    /**
     * translatedDepartments returns an array of Departments with the name attribute
     * converted from a TranslatedString to the string for the active locale,
     * and adds a locationGeography attibute set to the full name of the geography.
     * This is done so that the v-data-table's search can interact with these
     * fields instead of the Object or number which underlie them.
     */
    translatedDepartments(): any[] {
      return _.chain(this.allDepartments)
        .map((department) =>
          Object.assign({}, department, {
            translatedName: department.name[this.locale], //
            locationGeography: this.fullGeographyName(
              department.locationGeographyID,
            ),
          }),
        )
        .orderBy('translatedName')
        .value();
    },

    /**
     * headers builds the column header metadata for the v-data-table
     */
    headers(): any[] {
      const h = [
        {
          text: this.hasPermission('admin.department.create')
            ? this.$i18n.t('command.edit')
            : 'ID',
          value: 'id',
          width: '2%',
          sortable: false,
        },
        {
          text: 'Code',
          value: 'code',
          align: 'right',
        },
        {
          text: this.$i18n.t('person.name'),
          value: 'translatedName',
        },
        {
          text: this.$i18n.t('location.singular'),
          value: 'locationGeography',
        },
        {
          text: this.$i18n.t('legacyDepartment.plural'),
          value: 'legacyDepartmentIDs',
        },
        {
          text: 'CMS1 Records',
          value: 'cms1RecordCount',
          align: 'right',
        },
        {
          text: 'CMS 1 Importance',
          value: 'cms1Importance',
          align: 'right',
        },
      ];
      return h;
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('investigation/fetchDepartments');
      this.loading = false;
    },
  },
  components: {
    DepartmentContainer,
    EditDepartmentDialog,
    NewDepartmentDialog,
  },
});
