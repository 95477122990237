import _ from 'lodash';
import Vue from 'vue';

import api from '@/api';

/**
 * AccompanyingChild describes a child incarcerated with its mother
 */
export interface AccompanyingChild {
  id: number;
  offenderID: number;
  intakeDate: string;
  releaseDate: string;
  name: string;
  birthdate: string;
  birthdateIsApproximate: boolean;
  notes: string;
  isReleased: boolean;
}

export const state = {
  accompanyingChild: {} as { [id: number]: AccompanyingChild },
  accompanyingChildrenIDsForOffender: {} as { [offenderID: number]: number[] },
};

export const getters = {
  accompanyingChildrenForOffender:
    (state) =>
    (offenderID: number): AccompanyingChild[] => {
      return (state.accompanyingChildrenIDsForOffender[offenderID] || []).map(
        (id: number) => state.accompanyingChild[id],
      ) as AccompanyingChild[];
    },

  accompanyingChildWithID:
    (state) =>
    (accompanyingChildID: number): AccompanyingChild => {
      return state.accompanyingChild[accompanyingChildID];
    },
};

export const mutations = {
  /**
   * setAccompanyingChildrenForOffender either sets or replaces all
   * accompanyingChildren that are associated with the provided offender.
   * This function updates both the state.accompanyingChild lookup object and
   * the accompanyingChildrenIDsForOffender index.
   */
  setAccompanyingChildrenForOffender(
    state,
    payload: { offenderID: number; accompanyingChildren: AccompanyingChild[] },
  ) {
    for (const accompanyingChild of payload.accompanyingChildren) {
      Vue.set(state.accompanyingChild, accompanyingChild.id, accompanyingChild);
    }
    const ids = _.map(payload.accompanyingChildren, 'id');
    Vue.set(state.accompanyingChildrenIDsForOffender, payload.offenderID, ids);
  },
  /**
   * addAccompanyingChildToOffender prepends the ID of a newly created
   * accompanyingChild to the provided offender's list of accompanyingChildren.
   */
  addAccompanyingChildToOffender(
    state,
    payload: { offenderID: number; accompanyingChildID: number },
  ) {
    const ids =
      state.accompanyingChildrenIDsForOffender[payload.offenderID] || [];
    ids.unshift(payload.accompanyingChildID);
    Vue.set(state.accompanyingChildrenIDsForOffender, payload.offenderID, ids);
  },
};

export const actions = {
  /**
   * fetchAccompanyingChildren retrieves the complete list of accompanyingChildren
   * for a particular offender from the server, and updates the local store with the results.
   */
  async fetchAccompanyingChildren({ commit }, payload: { offenderID: number }) {
    const response = await api.get(
      `/offenderservice/offenders/${payload.offenderID}/accompanying-children`,
    );
    const offenderID = payload.offenderID;
    const accompanyingChildren = response.data.data;
    commit('setAccompanyingChildrenForOffender', {
      offenderID,
      accompanyingChildren,
    });
  },
};
