






















import Vue from 'vue';
import _ from 'lodash';

import { mapGetters, mapState } from 'vuex';

import SentenceComponentDescription from '@/components/enforcement/SentenceComponentDescription.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('crime', ['punishmentTypeWithID']),
    ...mapState('court', ['docketEventTypes']),
    payload(): any {
      return this.entry.payload;
    },
  },
  components: {
    SentenceComponentDescription,
  },
});
