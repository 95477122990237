



















































import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import LeaveCancelDialog from '@/components/offender/leave/LeaveCancelDialog.vue';

import { Leave } from '@/vuex/offender/leave';

/**
 * LeaveActions displays the correct array of buttons to
 * interact with a single Leave. It requires a leave="" prop
 * which should be assigned a complete Leave object because
 * decision making about which buttons to show requires
 */
export default Vue.extend({
  props: {
    leaveID: Number,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),

    // leave provides the Leave object from the Vuex store for our leaveID
    leave(): Leave | null {
      return this.$store.state.offender.leave[this.leaveID] || null;
    },

    /**
     * status is a convenience wrapper around leave.status. It returns 'unknown'
     * if the Leave object is not populated yet, otherwise it returns the
     * value of leave.status.
     */
    status(): string {
      if (!this.leave) {
        return 'unknown';
      }
      return this.leave.status;
    },

    /**
     * isApprovable returns true if this Leave is awaiting to be approved/denied
     * and the current user has permission to do that.
     */
    isApprovable(): boolean {
      if (this.status !== 'pending') {
        return false;
      }

      if (!this.leave) {
        return false;
      }

      return this.hasPermission(
        'offender.leave.approve',
        'facility',
        this.leave.housingFacilityID,
      );
    },

    /**
     * isStartable returns true if this Leave is in a startable state, and
     * the current user has permissions to start it.
     */
    isStartable(): boolean {
      if (this.status !== 'scheduled') {
        return false;
      }

      if (!this.leave) {
        return false;
      }

      return this.hasPermission(
        'offender.leave.start',
        'facility',
        this.leave.housingFacilityID,
      );
    },

    /**
     * isCancelable returns true if this Leave is in a cancellable state,
     * and the current user has permissions to cancel it.
     */
    isCancelable(): boolean {
      // Only allow cancellation when in a status where cancellation makes
      // sense.
      if (this.status !== 'pending' && this.status !== 'scheduled') {
        return false;
      }

      if (!this.leave) {
        return false;
      }

      return this.hasPermission(
        'offender.leave.request',
        'facility',
        this.leave.housingFacilityID,
      );
    },

    /**
     * isCompletable returns true if this Leave is in a completable state,
     * and the current user has the permission to mark it completed.
     */
    isCompletable(): boolean {
      if (this.status !== 'in-progress') {
        return false;
      }

      if (!this.leave) {
        return false;
      }

      return this.hasPermission(
        'offender.leave.complete',
        'facility',
        this.leave.housingFacilityID,
      );
    },
  },

  methods: {
    async approve(): Promise<void> {
      const response = await offenderAPI.post('leaves/approve', {
        data: { leaveID: this.leaveID },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);
    },

    async deny(): Promise<void> {
      const response = await offenderAPI.post('leaves/deny', {
        data: { leaveID: this.leaveID },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);
    },

    async start(): Promise<void> {
      const response = await offenderAPI.post('leaves/start', {
        data: { leaveID: this.leaveID },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);
    },

    async complete(): Promise<void> {
      const response = await offenderAPI.post('leaves/complete', {
        data: { leaveID: this.leaveID },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);
    },
  },
  components: {
    LeaveCancelDialog,
  },
});
