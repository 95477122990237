





























































































































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import housingAPI from '@/vuex/housing/housingAPI';
import { HousingFacility } from '@/vuex/housing/housing';

import { TranslatedString } from '@/lib/translated';

export default Vue.extend({
  props: {
    parentFacilityID: Number,
  },

  data() {
    return {
      isOpen: false,
      error: null as any,
      typeOptions: ['facility', 'building', 'block', 'wing', 'floor', 'cell'],

      selectedType: 'block',
      numberToAdd: 1,
      namePrefix: {} as TranslatedString,
      canHoldJuveniles: false,
      canHoldAdults: false,
      canHoldDetainees: false,
      canHoldPrisoners: false,
      canHoldFemales: false,
      canHoldMales: false,
      capacity: 0,
    };
  },

  computed: {
    ...mapGetters('housing', ['facilityWithID', 'facilitiesUnder']),
    ...mapState('language', ['locales']),
    ...mapGetters('language', ['languages']),

    parentFacility(): HousingFacility {
      return this.facilityWithID(this.parentFacilityID);
    },

    /**
     * mostCommonType computes the most common facility type (Cell, Block, etc)
     * of the existing children of the provided parentFacilityID. This is used
     * to label the activator button and to set the default type selection
     * on reset.
     */
    mostCommonType(): string {
      const siblings = this.facilitiesUnder(this.parentFacilityID) || [];
      const types = _.map(siblings, 'type') as string[];
      const mostCommon = _.chain(types)
        .countBy() // Produces { "facility": 14, "cell": 10 }
        .entries() // Converts to [["facility", 14], ["cell", 10]]
        .maxBy(_.last) // Filters to ["facility", 14]
        .head() // Retrieves first element ("facility")
        .value() as string; // Exit lodash chain and type-assert to string
      return mostCommon || 'block';
    },
  },

  watch: {
    selectedType: {
      immediate: true,
      handler() {
        this.updatePrefixes();
      },
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      if (this.parentFacility) {
        this.canHoldJuveniles = this.parentFacility.canHoldJuveniles;
        this.canHoldAdults = this.parentFacility.canHoldAdults;
        this.canHoldDetainees = this.parentFacility.canHoldDetainees;
        this.canHoldPrisoners = this.parentFacility.canHoldPrisoners;
        this.canHoldFemales = this.parentFacility.canHoldFemales;
        this.canHoldMales = this.parentFacility.canHoldMales;
      }

      this.selectedType = this.mostCommonType;
      this.numberToAdd = 1;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        // Execute the API call
        const resp = await housingAPI.post('facilities/add-locations', {
          data: {
            housingFacilityID: this.parentFacilityID,
            facilityType: this.selectedType,
            numberToAdd: this.numberToAdd,
            namePrefix: this.namePrefix,
            canHoldJuveniles: this.canHoldJuveniles,
            canHoldAdults: this.canHoldAdults,
            canHoldDetainees: this.canHoldDetainees,
            canHoldPrisoners: this.canHoldPrisoners,
            canHoldFemales: this.canHoldFemales,
            canHoldMales: this.canHoldMales,
            capacity: this.capacity,
          },
        });

        // Obtain the resulting state and inject it into Vuex
        const newState = _.get(resp, 'data.data');
        this.$store.commit('housing/setState', newState);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },

    /**
     * updatePrefixes uses the selectedType to build a fully translated initial
     * value for the prefixes of the new facility names.
     */
    updatePrefixes(): void {
      this.namePrefix.en = String(
        this.$i18n.t('housingFacility.typeOptions.' + this.selectedType, 'en'),
      );
      this.namePrefix.fa = String(
        this.$i18n.t('housingFacility.typeOptions.' + this.selectedType, 'fa'),
      );
      this.namePrefix.ps = String(
        this.$i18n.t('housingFacility.typeOptions.' + this.selectedType, 'ps'),
      );
    },
  },
});
