














import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Court } from '@/vuex/court/court';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    label: String,
    value: Number,
    courtID: {
      type: Number,
      required: true, // Can't have a Head of Court if the courtID isn't supplied
    },
    rules: Array,
    readonly: Boolean,
    disabled: Boolean,
    hint: String,
    persistentHint: Boolean,
  },
  computed: {
    ...mapGetters('court', ['courtWithID', 'judgeWithID', 'judgeName']),

    /**
     * judgeChoices builds the list of choices which will appear in the
     * dropdown. Since there is only one judge who can be the head of court,
     * it will return an array of either 0 or 1 judge.
     */
    judgeChoices(): VSelectItem[] {
      const judgeChoices = [] as VSelectItem[];
      if (this.court && this.court.headOfCourtJudgeID) {
        const judge = this.judgeWithID(this.court.headOfCourtJudgeID);
        judgeChoices.push({
          value: judge.id,
          text: this.judgeName(judge.id),
        });
      }
      return judgeChoices;
    },

    court(): Court {
      return this.courtWithID(this.courtID);
    },
  },
  watch: {
    /**
     * courtID is watched so that we can $emit the headOfCourtJudgeID for that
     * court whenever it is changed. We $emit 0 when the courtID is set to a
     * non-existent court.
     */
    courtID: {
      immediate: true,
      handler() {
        if (this.court) {
          this.$emit('input', this.court.headOfCourtJudgeID);
        } else {
          this.$emit('input', 0);
        }
      },
    },
  },
});
