
























































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { VForm } from '@/lib/vue-typescript';

import CorpusSelector from '@/components/corpus/CorpusSelector.vue';

export default Vue.extend({
  props: {
    error: {
      type: Error,
    },
    corpus: {
      type: Object,
    },
  },

  async created(): Promise<void> {
    this.waiting = true;
    await this.fetchCorpora();
    this.waiting = false;
  },

  data(): any {
    return {
      waiting: true,
    };
  },

  computed: {
    ...mapGetters('language', ['languages']),
  },

  methods: {
    ...mapActions('corpus', ['fetchCorpora']),

    required(v): boolean | string {
      return !!v || this.$i18n.t('error.required').toString();
    },

    validate(): boolean {
      const form = this.$refs.form as VForm;
      return form.validate();
    },

    submit(): void {
      this.$emit('submit');
    },
  },

  components: {
    CorpusSelector,
  },
});
