






















































































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import i18n from '@/i18n';
import { mapGetters, mapState } from 'vuex';
import {
  SubjectProperty,
  SubjectPropertyTypeID,
} from '@/vuex/court/subjectProperty';

export default Vue.extend({
  props: {
    value: Object,
  },

  computed: {
    ...mapGetters('language', ['valueForLocale', 'nameForIDInCollection']),
    ...mapState('court', ['illegalDrugs', 'subjectPropertyTypes']),
    subjectPropertyTypeChoices(): any[] {
      return this.subjectPropertyTypes.map((s) => ({
        value: s,
        text: i18n.t('subjectProperty.subjectPropertyTypes.' + s),
      }));
    },

    /**
     * subjectProperty computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    subjectProperty(): SubjectProperty {
      return this.value
        ? this.value
        : {
            id: 0,
            dossierID: 0,
            typeID: SubjectPropertyTypeID.Movable,
            drugID: 0,
            currencyID: 0,
            label: '',
            description: '',
            locationGeographyID: 1,
            locationDetails: '',
            landAreaInSquareMeters: 0,
            itemValueInPuls: 0,
            quantity: 1,
            totalValueInPuls: 0,
          };
    },

    drugName(): any {
      if (this.subjectProperty.drugID !== undefined) {
        return this.nameForIDInCollection(
          this.subjectProperty.drugID,
          this.illegalDrugs,
        );
      }
      return ' ';
    },

    hasLabel(): boolean {
      switch (this.subjectProperty.typeID) {
        case SubjectPropertyTypeID.Drugs:
          return false;
        case SubjectPropertyTypeID.Currency:
          return false;
        default:
          return true;
      }
    },

    isForeignCurrecy(): boolean {
      return this.subjectProperty.currencyID !== 1;
    },

    isDrugs(): boolean {
      return this.subjectProperty.typeID === SubjectPropertyTypeID.Drugs;
    },

    isCurrency(): boolean {
      return this.subjectProperty.typeID === SubjectPropertyTypeID.Currency;
    },

    isForeignCurrency(): boolean {
      if (!this.isCurrency) {
        return false;
      }
      return this.subjectProperty.currencyID !== 1;
    },

    hasQuantity(): boolean {
      return this.subjectProperty.typeID === SubjectPropertyTypeID.Movable;
    },

    hasLandArea(): boolean {
      return this.subjectProperty.typeID === SubjectPropertyTypeID.Immovable;
    },

    hasLegalDescription(): boolean {
      if (this.isCurrency) {
        return false;
      }
      if (this.isDrugs) {
        return false;
      }
      return true;
    },

    // requiredForLand returns an error if the value is blank and the
    // subject property is not movable (i.e. if it is land).
    requiredForLand(): any[] {
      if (this.hasLandArea) {
        return [(v) => v > 0 || this.$i18n.t('error.required')];
      }
      return [];
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      // -----------------------------------------------------------------------
      // Apply modifiers to the submitted value (if requested)
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      if (modifier === 'number') {
        let quant = parseInt(value, 10);
        // Force non-numeric input to a number so that the form
        // ignores it (this will show '0' if you type 'a').
        if (Number.isNaN(quant)) {
          quant = 0;
        }
        modifiedValue = quant;
      }

      // Build a new entity object including the changed field
      const newSP = tap(cloneDeep(this.subjectProperty), (v) =>
        set(v, key, modifiedValue),
      );

      if (key === 'typeID') {
        // Force a Quantity of 1 for everything except Movable property
        // (Drugs, Currency, and Real Estate don't need this)
        if (newSP.typeID !== SubjectPropertyTypeID.Movable) {
          newSP.quantity = 1;
        }

        // Force Land Area of 0 for everything except Immovable property
        if (newSP.typeID !== SubjectPropertyTypeID.Immovable) {
          newSP.landAreaInSquareMeters = 0;
        }

        // Force Drug ID of 0 for everything except Drugs
        if (newSP.typeID !== SubjectPropertyTypeID.Drugs) {
          newSP.drugID = 0;
        }

        // Force Currency ID of 0 for everything except Currency
        if (newSP.typeID !== SubjectPropertyTypeID.Currency) {
          newSP.currencyID = 0;
        } else {
          if (newSP.currencyID === 0) {
            // Default-select Afghani
            newSP.currencyID = 1;
          }
        }
      }

      // Ensure the totalValueInPuls field is set
      newSP.totalValueInPuls = newSP.quantity * newSP.itemValueInPuls;

      // -----------------------------------------------------------------------
      // Notify the parent that the object changed
      this.$emit('input', newSP);
    },
  },
});
