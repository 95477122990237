




































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import { CaseJudge } from '@/vuex/court/caseJudge';
export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('court', ['judgeName', 'caseWithID']),
    payload(): any {
      return this.entry.payload;
    },
    caseID(): number {
      return this.payload.caseID;
    },
    kase(): Case {
      return this.caseWithID(this.caseID);
    },
    caseJudges(): CaseJudge {
      return this.payload.caseJudges;
    },
  },
});
