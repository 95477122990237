















































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { blankTranslatedString } from '@/lib/translated';
import UserInitials from '@/components/user/UserInitials.vue';

import translationAPI from '@/vuex/language/translationAPI';

export default Vue.extend({
  props: {
    keyName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      error: null as any,

      message: blankTranslatedString(),
      isVerified: false,
      lastVerifiedByUserID: 0,
      lastVerifiedAt: null as null | string,
      isDeleted: false,
    };
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('language', ['localeMessage']),
    ...mapGetters('language', ['languages']),
  },

  watch: {
    isVerified(newVal) {
      if (newVal) {
        this.lastVerifiedAt = moment().format();
        this.lastVerifiedByUserID = this.user.id;
      } else {
        this.lastVerifiedAt = null;
        this.lastVerifiedByUserID = 0;
      }
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      const existing = this.localeMessage[this.keyName];
      if (existing) {
        this.message = _.cloneDeep(existing.message);
        this.isVerified = !!existing.lastVerifiedAt;
        this.lastVerifiedByUserID = existing.lastVerifiedByUserID;
        this.lastVerifiedAt = existing.lastVerifiedAt;
        this.isDeleted = existing.isDeleted;
      } else {
        alert('Unknown key: ' + this.keyName);
        this.isOpen = false;
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const res = await translationAPI.post('locale-messages/update', {
          data: {
            key: this.keyName,
            message: this.message,
            lastVerifiedByUserID: this.lastVerifiedByUserID,
            lastVerifiedAt: this.lastVerifiedAt,
            isDeleted: this.isDeleted,
          },
        });
        const newState = _.get(res, 'data.data', {});
        this.$store.commit('language/setState', newState);
        this.$store.commit('language/refreshBrowserLocaleMessages');

        // Close the Dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    UserInitials,
  },
});
