






































































































































































































































































































































































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import i18n from '@/i18n';
import jmoment from 'moment-jalaali';
import router from '@/router';
import {
  changedCriteria,
  criteriaToQueryString,
  routeToCriteria,
} from '@/lib/criteria';
import { Offender } from '@/vuex/offender/offender';
import { VSelectItem } from '@/lib/vue-typescript';

import CrimeSelector from '@/components/crime/CrimeSelector.vue';
import CustodyStatusSelector from '@/components/offender/classification/CustodyStatusSelector.vue';
import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderFlags from '@/components/offender/classification/OffenderFlags.vue';
import SecurityLevelSelector from '@/components/offender/classification/SecurityLevelSelector.vue';

export default Vue.extend({
  props: {
    /**
     * route expects the $route object from the parent component. It is optional
     * and should only be supplied by OffenderList, where we *want* a tight
     * connection between the page URL and the OffenderSearcher criteria object.
     */
    route: Object,

    /**
     * dense triggers a more condensed design for the component, which is useful
     * when it appears in a popup or other compressed space.
     */
    dense: Boolean,
  },

  created() {
    if (this.route) {
      this.routeChanged();
    }

    // Load the initial data...
    this.fetchData();

    // ...then make every future calls to this.fetchData or this.routeChanged
    // be debounced so that they can never be called faster than once
    // every N milliseconds.
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
    this.routeChanged = _.debounce(this.routeChanged, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,
      advancedSearch: false,

      initialCriteria: {},
      criteria: {
        // Profile Criteria
        q: '',
        name: '',
        surname: '',
        fatherName: '',
        grandfatherName: '',
        alias: '',
        genderID: 0,
        criminalGroupIDs: [],
        birthplaceGeographyID: 0,
        residenceGeographyID: 0,
        minAge: 0,
        maxAge: 0,
        elderlyOnly: false,
        infectiousDiseaseIDs: [],
        mentalDisorderIDs: [],
        motherChildConcernIDs: [],
        maritalStatusID: 0,
        nativeLanguageID: 0,
        secondLanguageID: 0,
        militaryRankID: 0,
        isKochi: null as boolean | null,
        nationalityID: 0,
        minHeightInCm: 0,
        maxHeightInCm: 0,
        minWeightInKg: 0,
        maxWeightInKg: 0,
        hairColorID: 0,
        eyeColorID: 0,

        // Offender Criteria
        facilityLocationID: 1,
        facilityID: 0,
        din: '',
        pin: '',
        securityLevel: '',
        isDetainee: null,
        isJuvenile: null,
        pastDue: false,
        nearingRelease: false,
        imminentRelease: false,
        missingDossier: false,
        custodyStatuses: [],
        dossierNumber: '',
        detainedAtOrAfter: '',
        detainedAtOrBefore: '',
        expectedReleaseAtOrAfter: '',
        expectedReleaseAtOrBefore: '',
        freedAtOrAfter: '',
        freedAtOrBefore: '',
        releaseReasonID: 0,
        benefittedFromPardonDecreeInYear: 0,
        notBenefittedFromPardonDecreeInYear: 0,
        dossierCrimeIDs: [],
        includeUndisposedCharges: false,
        finalDossierOnly: false,
        minPercentOfTimeServed: 0,
        maxPercentOfTimeServed: 0,
      },

      results: [],

      page: {
        number: 1,
        size: 10,
        serverCount: 0,
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * criteriaUnchanged returns true when the currently-active criteria
     * is unchanged from its original, default state on the form.
     */
    criteriaUnchanged(): boolean {
      return _.isEqual(this.criteria, this.initialCriteria);
    },

    /**
     * queryString computes the URL querystring parameters which will be
     * submitted to the server to perform the search. NOTE that this
     * value is combined with pagination criteria inside fetchData, so this
     * value doesn't exactly match the API request.
     */
    queryString(): string {
      if (_.isEmpty(this.initialCriteria)) {
        this.initialCriteria = _.cloneDeep(this.criteria);
      }
      const cc = changedCriteria(this.criteria, this.initialCriteria);
      return criteriaToQueryString(cc, this.page);
    },

    /**
     * canExport computes whether the current user is allowed to download
     * an Excel export of the data on the screen.
     */
    canExport(): boolean {
      return this.hasPermission('offender.excelExport');
    },

    jalaaliYears(): VSelectItem[] {
      const years: VSelectItem[] = [];
      const thisYear = jmoment().jYear();
      for (let i = 0; i < 10; i++) {
        years.push({
          value: thisYear - i,
          text: thisYear - i,
        });
      }
      return years;
    },

    /**
     * headers dynamically builds the headers array so that translations for
     * the column titles can update live, and so that the edit link column is
     * removed *automatically when the selectable prop is set
     */
    headers(): any[] {
      return [
        { text: i18n.t('person.name'), value: 'name' },
        { text: i18n.t('person.surname'), value: 'surname' },
        { text: i18n.t('person.fatherName'), value: 'fatherName' },
        { text: i18n.t('person.grandfatherName'), value: 'grandfatherName' },
        { text: i18n.t('person.alias.plural'), value: 'aliases' },
        {
          text: i18n.t('person.birthplace'),
          value: 'birthplaceGeographyID',
        },
        {
          text: i18n.t('person.age'),
          value: 'birthdate',
          align: 'right',
          width: '4em',
        },
        { text: i18n.t('offender.din'), value: 'DIN' },
        { text: i18n.t('offender.pin'), value: 'PIN' },
        { text: i18n.t('flag.plural'), value: 'flagIDs' },
        {
          text: i18n.t('release.releaseDate'),
          value: 'expectedReleaseAt',
          align: 'center',
        },
        { value: 'id' }, // Open in new window column
      ];
    },
  },

  watch: {
    /**
     * route is watched so that when the $route of the topmost component
     * changes, we can update the query string. Note that we don't watch
     * $route directly from this component (note the lack of a dollar sign here),
     * because that would potentially trigger at undesirable times.
     *
     * Since we can search for Offenders from other places than the full
     * OffenderList page, we don't always want the route to be tied to this
     * component's behavior.
     */
    route(route: any) {
      this.routeChanged();
    },

    /**
     * queryString is watched so that a new search can be triggered.
     */
    queryString(newVal: string, oldVal: string) {
      this.fetchData();
    },

    /**
     * criteria is watched so that when the search criteria is changed after
     * results have already been returned, we can reset the page number.
     */
    criteria: {
      deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        if (this.page.serverCount > 0 && this.page.number > 1) {
          this.page.number = 1;
        }
      },
    },

    /**
     * advancedSearch changes the UI, so we need to make sure to reset
     * the no longer visible fields
     */
    advancedSearch(isAdvanced: boolean) {
      if (isAdvanced) {
        this.resetSimpleCriteria();
      } else {
        this.resetAdvancedCriteria();
      }
    },
  },

  methods: {
    /**
     * routeChanged is triggered when the page URL changes.
     */
    routeChanged(): void {
      routeToCriteria(this.route, this.criteria, this.page);
      if (!this.criteriaUnchanged && this.criteria.q === '') {
        this.advancedSearch = true;
      }
    },

    /**
     * fetchData triggers the the API endpoint and updates the table with the
     * results for the currently-selected page. This function is triggered
     * both when the criteria changes and when the page changes.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;

      try {
        const url = `/offenders/search?${this.queryString}`;
        const res = await offenderAPI.get(url);
        this.page.serverCount = _.get(res, 'data.meta.totalResults', 0);
        this.results = _.get(res, 'data.data', []);
        this.$emit('queryChanged', this.queryString);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    /**
     * reset clears the search and resets search results. Primarily designed
     * to be called externally via a $ref to this component so that the searcher
     * can be reset when a dialog is opened.
     */
    reset(): void {
      this.resetSimpleCriteria();
      this.resetAdvancedCriteria();
    },

    resetSimpleCriteria(): void {
      this.criteria.q = '';
    },

    resetAdvancedCriteria(): void {
      this.criteria = Object.assign(this.criteria, this.initialCriteria);
    },

    /**
     * goToOffender is the row click handler for case search results. It triggers
     * navigation to the Offender detail page for the offender in that row.
     */
    goToOffender(row: Offender): void {
      router.push({
        name: 'offenderProfile',
        params: { id: String(row.id) },
      });
    },
  },

  components: {
    CrimeSelector,
    CustodyStatusSelector,
    FacilitySelector,
    OffenderFlags,
    SecurityLevelSelector,
  },
});
