













































import Vue from 'vue';
import _, { cloneDeep } from 'lodash';
import { mapState, mapGetters } from 'vuex';

import { Charge, GuiltyChargeDispositionTypeIDs } from '@/vuex/court/charge';
import {
  Crime,
  ImprisonmentDuration,
  PunishmentType,
} from '@/vuex/crime/crime';
import { SentenceComponent } from '@/vuex/court/participant';

import SentenceComponentDialog from '@/components/court/case/SentenceComponentDialog.vue';

interface Choice {
  type: PunishmentType;
  isValid: boolean;
}

export default Vue.extend({
  props: {
    value: Array as () => SentenceComponent[],
    charges: Array as () => Charge[],
    dossierID: Number,
  },

  data() {
    return {
      applyConsequential: false,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('crime', ['imprisonmentDurations', 'punishmentTypes']),
    ...mapGetters('crime', [
      'crimeWithID',
      'punishmentTypeWithID',
      'imprisonmentDurationWithID',
    ]),

    /**
     * components is the internal representation of our v-model data-bound
     * value, the list of sentence components which are actually being
     * manipulated.
     */
    components(): SentenceComponent[] {
      return this.value
        ? (this.value as SentenceComponent[])
        : ([] as SentenceComponent[]);
    },

    /**
     * componentsByType is an alternative (dictionary/lookup table) view of the
     * sentence components. Since the UI is oriented around the punishment
     * types and not the sentence components, we use this structure heavily
     * in the UI.
     */
    componentsByType(): { [punishmentTypeID: number]: SentenceComponent } {
      return (
        _.chain(this.components)
          // Create an object with numeric keys (punishment IDs), and values
          // which are arrays with that type.... since there should only be
          // one sentence component per punishment type...
          .groupBy('punishmentTypeID')
          // We re-map the values to hold the first (and only) value of the array
          .mapValues((components: SentenceComponent[]) => components[0])
          .value()
      );
    },

    /**
     * categorizedPunishmentTypes returns all punishment types (valid or not),
     * grouped by their punishment category IDs.
     *
     * Instead of using PunishmentType, we use a new Choice type, which
     * carries an indication of whether the UI should treat this punishment
     * type as a valid one.
     */
    categorizedPunishmentTypes(): { [categoryID: number]: Choice[] } {
      const types = this.punishmentTypes as PunishmentType[];

      // First build an array of Choice[]
      const choices = _.chain(types)
        .filter((pt) => pt.id !== 0) // Remove 'Unknown'
        .map((type) => {
          // It's valid if its in the list of valid IDs which were identified
          // based on the guilty crime types.
          let isValid = _.includes(this.validPunishmentTypeIDs, type.id);

          // ... and it's also valid if the user has applied consequential
          // penalties and this can be a consequential one.
          if (this.applyConsequential && type.isConsequential) {
            isValid = true;
          }

          return {
            type,
            isValid,
          } as Choice;
        })
        .value();

      // Then group those by categoryID, and order the Choice[] with valid
      // items first, then by their ranks and names.
      const groups = _.groupBy(choices, (choice) => choice.type.categoryID);
      const result = _.mapValues(groups, (choices) => {
        return _.orderBy(
          choices,
          [
            (c) => (c.isValid ? 1 : 0),
            (c) => c.type.rank,
            (c) => c.type.name[this.locale],
          ],
          ['desc', 'asc', 'asc'],
        );
      });
      return result;
    },

    /**
     * guiltyCrimes builds the complete list of Crime objects from the Charges
     * which are marked as guilty. Returns an empty array when there are no
     * guilty charges.
     */
    guiltyCrimes(): Crime[] {
      return _.chain((this.charges || []) as Charge[])
        .filter((c: Charge) =>
          _.includes(GuiltyChargeDispositionTypeIDs, c.dispositionTypeID),
        )
        .map((c: Charge) => this.crimeWithID(c.crimeID))
        .value();
    },

    /**
     * needsConsequentialPunishment returns true if one if the guilty crimes
     * indicates that it should trigger automatic application of the
     * consequential penalties.
     */
    needsConsequentialPunishment(): boolean {
      return _.some(this.guiltyCrimes, {
        triggersConsequentialPunishments: true,
      });
    },

    /**
     * validPunishmentTypeIDs examines all the guilty crimes and returns the
     * unique list of punishment type IDs which are marked as valid on them.
     */
    validPunishmentTypeIDs(): number[] {
      return _.chain(this.guiltyCrimes)
        .map((crime: Crime) => {
          return crime.validPunishmentTypeIDs;
        })
        .flatten()
        .uniq()
        .compact()
        .value();
    },

    /**
     * minFine computes the minimum allowed fine (in puls) from the guilty
     * crimes.
     */
    minFine(): number {
      const min = _.chain(this.guiltyCrimes)
        .map((crime: Crime) => crime.minimumFineInPuls)
        .compact()
        .min()
        .value();
      return min || 0;
    },

    /**
     * maxFine computes the maximum allowed fine (in puls) from the guilty crimes.
     */
    maxFine(): number {
      const max = _.chain(this.guiltyCrimes)
        .map((crime: Crime) => crime.maximumFineInPuls)
        .compact()
        .max()
        .value();
      return max || 0;
    },

    /**
     * validImprisonmentDurations computes the unique list of imprisonment
     * durations which are valid for the guilty crimes.
     */
    validImprisonmentDurations(): ImprisonmentDuration[] {
      const valids = _.chain(this.guiltyCrimes)
        .map((crime: Crime) => crime.validImprisonmentDurationIDs)
        .flatten()
        .uniq()
        .compact()
        .map((id: number) => this.imprisonmentDurationWithID(id))
        .value();
      return valids || [];
    },

    /**
     * minDuration computes the minimum duration (as an Imprisonment Duration
     * object) which is allowed based on the guilty crimes.
     */
    minDuration(): ImprisonmentDuration | null {
      const min = _.chain(this.validImprisonmentDurations)
        .sortBy((d: ImprisonmentDuration) => d.minimumHours)
        .first()
        .value();
      return min || null;
    },

    /**
     * maxDuration computes the maximum duration (as an Imprisonment Duration
     * object) which is allowed based on the guilty crimes.
     */
    maxDuration(): ImprisonmentDuration | null {
      const max = _.chain(this.validImprisonmentDurations)
        .sortBy((d: ImprisonmentDuration) => d.maximumHours)
        .last()
        .value();
      return max || null;
    },
  },

  watch: {
    /**
     * guiltyCrimes is watched so that we can automatically
     * apply the consequential punishments when the user is guilty of a crime
     * which triggers them (and vice versa).
     */
    guiltyCrimes(): void {
      if (this.needsConsequentialPunishment) {
        this.applyConsequential = true;
      }
    },

    /**
     * applyConsequential (the checkbox indicating that consequential
     * penalties should be applied) is watched so that we can automatically
     * build SentenceComponents for each consequential punishment.
     */
    applyConsequential(apply: boolean): void {
      const newValues = cloneDeep(this.components);

      // First any existing SentenceComponent entries for punishment types
      // which can be applied as consequential penalties.
      const cps = _.filter(this.punishmentTypes, { isConsequential: true });
      const cpIDs = _.map(cps, 'id');
      _.remove(newValues, (sc) => _.includes(cpIDs, sc.punishmentTypeID));

      // Next, build a new SentenceComponent only if the checkbox is now
      // checked.
      if (apply) {
        for (const punishmentType of cps) {
          newValues.push({
            punishmentTypeID: punishmentType.id,
            categoryID: punishmentType.categoryID,
            durationInHours: 0,
            amountInPuls: 0,
            subjectPropertyIDs: [],
            notes: '',
            isConsequential: true,
          });
        }
      }

      // Finally, $emit the new value. This value will immediately loop back
      // into this component as this.components
      this.$emit('input', newValues);
    },
  },

  methods: {
    /**
     * componentModified is our v-model handler for user modifications which
     * happen on a SentenceComponentDialog. Since the "native" structure of this
     * data is an array, we need to use Vue.set and Vue.delete to properly
     * modify the data in the array to preserve reactivity.
     */
    componentModified(
      punishmentTypeID: number,
      changed: SentenceComponent,
    ): void {
      const newValues = [...cloneDeep(this.components)];
      const i = _.findIndex(
        newValues,
        (existing) => punishmentTypeID === existing.punishmentTypeID,
      );
      if (changed) {
        if (i === -1) {
          // Append New
          newValues.push(changed);
        } else {
          // Replace Existing at Index
          Vue.set(newValues, i, changed);
        }
      } else if (i !== -1) {
        // Remove Existing
        Vue.delete(newValues, i);
      }

      this.$emit('input', newValues);
    },
  },

  components: {
    SentenceComponentDialog,
  },
});
