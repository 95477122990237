
















































































































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import moment from 'moment';

import i18n from '@/i18n';
import { User } from '@/vuex/user/user';

import UserRolesForm from '@/components/user/UserRolesForm.vue';

export default Vue.extend({
  props: {
    value: Object,
  },

  computed: {
    /**
     * user computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    user(): User {
      const today = moment().format();
      return this.value
        ? this.value
        : {
            username: '',
            fullName: '',
            initials: '',
            email: '',
            telephone: '',
            remarks: '',
            password: '',
            passwordConfirmation: '',
            roles: [],
            isSuspended: false,
            passwordLastChangedAt: today,
            isDeleted: false,
            deletedReason: '',
            suspendedReason: '',
            reactivatedReason: '',
          };
    },

    isActive(): boolean {
      return !(this.user.isDeleted || this.user.isSuspended);
    },

    showReactivatedReason(): boolean {
      if (!this.isActive) {
        // Never show reactivation reason if the user isn't active
        return false;
      }

      if (this.user.deletedReason !== '' || this.user.suspendedReason !== '') {
        // If the user has a deletedReason or a suspendedReason, it
        // means they were once deleted or suspended, so we show the
        // reactivationReason field
        return true;
      }

      return false;
    },
  },

  methods: {
    /**
     * requiredOnCreate is a Vuetify text field validation function
     * which always passes validation when editing an existing user,
     * and requires input when editing a new user.
     *
     * Used on password and passwordConfirmation fields.
     */
    requiredOnCreate(v): boolean | string {
      if (this.user && this.user.id > 0) {
        return true;
      }
      return !!v || i18n.t('error.required').toString();
    },

    /**
     * mustMatchPassword is a Vuetify text field validation function
     * which evaluates the content against the user.password field to ensure
     * it matches.
     *
     * Used on the passwordConfirmation field.
     */
    mustMatchPassword(v): boolean | string {
      if (this.user && this.user.password) {
        return (
          v === this.user.password ||
          i18n.t('validation.mustMatchPassword').toString()
        );
      }
      return true;
    },

    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.user), (v) => set(v, key, modifiedValue)),
      );
    },
  },

  components: {
    UserRolesForm,
  },
});
