











import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Escape } from '@/vuex/offender/escape';
import RecaptureDialog from './RecaptureDialog.vue';

export default Vue.extend({
  props: {
    escapeID: Number,
    offenderID: Number,
  },
  data() {
    return {
      openRecaptureDialog: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', ['escapeWithID']),
    escape(): Escape {
      return this.escapeWithID(this.escapeID);
    },
    isNotRecaptured(): boolean {
      return this.escape.recapturedAt === null;
    },
  },
  methods: {
    recapturePrompt(): void {
      this.openRecaptureDialog = true;
    },
  },
  components: {
    RecaptureDialog,
  },
});
