


























































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    profile: Object,
  },
  computed: {
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('language', ['localeStringForBloodType']),
  },
});
