














import Vue from 'vue';
import i18n from '@/i18n';
import { mapState } from 'vuex';

export default Vue.extend({
  props: {
    label: String,
    value: [String, Array],
    rules: Array,
    disabled: Boolean,
    multiple: Boolean,
    chips: Boolean,
    clearable: Boolean,
  },
  computed: {
    ...mapState('court', ['caseTypes']),
    caseTypeChoices(): any[] {
      return this.caseTypes.map((s) => ({
        value: s,
        text: i18n.t('dossier.caseTypeOptions.' + s),
      }));
    },
  },
});
