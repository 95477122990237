








































import _ from 'lodash';
import Vue from 'vue';

import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),

    payload(): any {
      return this.entry.payload || {};
    },

    /**
     * directionKey returns the translation key to be used to
     * explain the direction of adjustment
     */
    directionKey(): string {
      const dir = _.get(this.payload, 'adjustmentDirection', 0);
      if (dir > 0) {
        return 'sentenceAdjustment.add';
      } else if (dir < 0) {
        return 'sentenceAdjustment.subtract';
      }

      return 'sentenceAdjustment.set';
    },
  },
});
