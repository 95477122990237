

























































































































































































import { cloneDeep, tap, set } from 'lodash';
import jmoment from 'moment-jalaali';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { AfghanistanID, Geography } from '@/vuex/geography/geography';
import { Department } from '@/vuex/investigation/department';
import { TranslatedString } from '@/lib/translated';

export default Vue.extend({
  props: {
    value: Object,
  },

  data() {
    return {
      parentSelectorUnlocked: false,
      legacyDepartmentsUnlocked: false,
    };
  },

  computed: {
    ...mapGetters('language', ['languages']),
    ...mapGetters('geography', [
      'geographyWithID',
      'provinceCode',
      'districtCode',
      'fullGeographyName',
    ]),
    ...mapGetters('investigation', [
      'fullDepartmentName',
      'departmentMinistryCode',
    ]),

    /**
     * department computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    department(): Department {
      return this.value
        ? this.value
        : {
            id: 0,
            name: {} as TranslatedString,
            parentID: null,
            locationGeographyID: AfghanistanID,
            year: 0,
            lastCaseNumber: 0,
            cms1RecordCount: 0,
            cms1Importance: 0,
            legacyDepartmentIDs: [],
          };
    },

    parentIsEditable(): boolean {
      if (!this.department.id) {
        // When creating a new Department, the parent is always editable
        return true;
      }
      // Otherwise allow editing if the parent selector has been unlocked
      return this.parentSelectorUnlocked;
    },

    currentYear(): string {
      return jmoment().format('jYYYY');
    },

    ministryPart(): string {
      return this.departmentMinistryCode(this.department.parentID);
    },

    departmentCodePart(): string {
      return this.department.code;
    },

    departmentCodeColor(): string {
      if (!this.department.code) {
        return 'error';
      }
      return '';
    },

    caseNumberPart(): string {
      return ((this.department.lastCaseNumber || 0) + 1)
        .toString()
        .padStart(4, '0');
    },

    geography(): Geography {
      return this.geographyWithID(this.department.locationGeographyID);
    },

    provincePart(): string {
      const code = this.provinceCode(this.department.locationGeographyID);
      return code || '??';
    },

    districtPart(): string {
      const code = this.districtCode(this.department.locationGeographyID);
      return code || '??';
    },

    yearPart(): string {
      if (this.department.year) {
        return this.department.year.toString();
      }
      return this.currentYear;
    },

    yearPartColor(): string {
      if (this.department.year) {
        return 'warning--text';
      }
      return '';
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.department), (v) => set(v, key, modifiedValue)),
      );
    },
  },
});
