





















































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { Offender } from '@/vuex/offender/offender';
import { PardonDecree } from '@/vuex/investigation/pardonDecree';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data() {
    return {
      error: null as TranslateResult | null,
      isOpen: false,
      search: '',
      selected: [],
    };
  },
  computed: {
    ...mapGetters('investigation', ['allPardonDecrees']),
    ...mapGetters('offender', ['offenderWithID']),
    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },
    pardonDecrees(): PardonDecree[] {
      // Reject pardon decrees already associated with offender
      return _.reject(this.allPardonDecrees, (pardonDecree) => {
        return this.offender.pardonDecreeIDs.includes(pardonDecree.id);
      });
    },
    selectedIDs(): number[] {
      return _.map(this.selected, 'id');
    },
    headers(): any[] {
      const h = [
        {
          text: i18n.t('pardonDecrees.number'),
          value: 'number',
          width: '20%',
        },
        {
          text: i18n.t('summons.issuedAt'),
          value: 'issuedAt',
          width: '30%',
        },
        {
          text: i18n.t('corpus.title'),
          value: 'title',
          width: '45%',
        },
      ];
      return h;
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.error = null;
      this.search = '';
      this.selected = [];
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We use it to persist the
     * object to the server.
     */
    async save(): Promise<void> {
      if (this.selectedIDs.length === 0) {
        this.error = i18n.t('error.required');
        return;
      }

      const response = await offenderAPI.post('offenders/add-pardon-decree', {
        data: {
          offenderID: this.offenderID,
          pardonDecreeIDs: this.selectedIDs,
        },
      });
      const newState = response.data.data;
      this.$store.commit('offender/setState', newState);

      this.isOpen = false;
    },
  },
});
