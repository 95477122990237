
































































































































































































































































import _ from 'lodash';
import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';
import {
  CompletionStatusID,
  Crime,
  ParticipationTypeID,
  PunishmentType,
} from '@/vuex/crime/crime';
import { blankTranslatedString } from '@/lib/translated';

import CorpusSelector from '@/components/corpus/CorpusSelector.vue';
import PunishmentTypeSelector from '@/components/crime/punishmentType/PunishmentTypeSelector.vue';
import UserInitials from '@/components/user/UserInitials.vue';

export default Vue.extend({
  props: {
    value: Object,
    defaultCorpusID: Number,
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['align', 'languages', 'valueForLocale']),
    ...mapState('crime', ['punishmentTypes']),
    ...mapState('auth', ['user']),

    /**
     * crime computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    crime(): Crime {
      const defaultCorpusID = !!this.defaultCorpusID ? this.defaultCorpusID : 0;
      return this.value
        ? this.value
        : {
            id: 0,
            title: blankTranslatedString(),
            text: blankTranslatedString(),
            corpusID: defaultCorpusID,
            article: 0,
            relatedArticles: [],
            validSeverityIDs: [],
            validParticipationTypeIDs: [ParticipationTypeID.PrincipalOffender],
            validPunishmentTypeIDs: [],
            triggersConsequentialPunishments: false,
            validImprisonmentDurationIDs: [],
            validCompletionStatusIDs: [CompletionStatusID.Completed],
            aggravators: [],
            mitigators: [],
            lastReviewedAt: null,
            lastReviewedByUserID: 0,
            lastApprovedAt: null,
            lastApprovedByUserID: 0,
            lastGiroaReviewAt: null,
            lastGiroaReviewByUserID: 0,
            lastGiroaApprovedAt: null,
            lastGiroaApprovedByUserID: 0,
            isNonchargeable: false,
          };
    },

    /**
     * selectedPunishmentTypes computes the array of PunishmentType objects
     * based on the list of selected (a.k.a "valid") punishmentTypeIDs.
     */
    selectedPunishmentTypes(): PunishmentType[] {
      const types = this.punishmentTypes as PunishmentType[];
      const myValidIDs = this.crime.validPunishmentTypeIDs;
      return types.filter((t) => _.includes(myValidIDs, t.id));
    },

    /**
     * canHaveAmount returns true if any of the selected Punishment Types
     * can have amounts like a Cash Fine would.
     */
    canHaveAmount(): boolean {
      const selected = this.selectedPunishmentTypes as PunishmentType[];
      return _.some(selected, 'canHaveAmount');
    },

    /**
     * canHaveImprisonment returns true if any of the selected Punishment Types
     * can have an imprisonment duration like Imprisonment and Juvenile
     * Confinement do.
     */
    canHaveImprisonment(): boolean {
      const selected = this.selectedPunishmentTypes as PunishmentType[];
      return _.some(selected, 'isImprisonment');
    },

    /**
     * imprisonmentDurationRules is the rules attribute for the imrprisonment
     * duration dropdown. It requires at least one choice only when Imprisonment
     * has been selected as a valid punishment type.
     */
    imprisonmentDurationRules(): any[] {
      return [
        (v) => {
          if (!this.canHaveImprisonment) {
            return true;
          }
          return v.length > 0 || i18n.t('error.required');
        },
      ];
    },

    /**
     * Review and Approval Tracking
     *
     * Each of these fields uses a settable getter to control both the
     * -ByUserID and -At timestamp parts of the tracking
     */
    isApproved: {
      get(): boolean {
        return !!this.crime.lastApprovedByUserID;
      },
      set(value: boolean): void {
        this.updateReviewCheckbox('lastApproved', value);
      },
    },

    isReviewed: {
      get(): boolean {
        return !!this.crime.lastReviewedByUserID;
      },
      set(value: boolean): void {
        this.updateReviewCheckbox('lastReviewed', value);
      },
    },

    isGiroaReview: {
      get(): boolean {
        return !!this.crime.lastGiroaReviewByUserID;
      },
      set(value: boolean): void {
        this.updateReviewCheckbox('lastGiroaReview', value);
      },
    },

    isGiroaApproved: {
      get(): boolean {
        return !!this.crime.lastGiroaApprovedByUserID;
      },
      set(value: boolean): void {
        this.updateReviewCheckbox('lastGiroaApproved', value);
      },
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      if (modifier === 'number') {
        let quant = parseInt(value, 10);
        // Force non-numeric input to a number so that the form
        // ignores it (this will show '0' if you type 'a').
        if (Number.isNaN(quant)) {
          quant = 0;
        }
        modifiedValue = quant;
      }

      const newCrime = tap(cloneDeep(this.crime), (v) =>
        set(v, key, modifiedValue),
      );
      this.$emit('input', newCrime);
    },

    /**
     * updateReviewCheckbox is a special handler for the review checkboxes.
     * It applies a individual field edits to the -UserID and -At timestamps,
     * and emits the input event with the resulting Crime object.
     */
    updateReviewCheckbox(prefix: string, newVal: boolean): void {
      const newCrime = cloneDeep(this.crime);
      if (newVal) {
        newCrime[prefix + 'ByUserID'] = this.user.id;
        newCrime[prefix + 'At'] = moment().format();
      } else {
        newCrime[prefix + 'ByUserID'] = 0;
        newCrime[prefix + 'At'] = null;
      }
      this.$emit('input', newCrime);
    },

    minFineSmallerThanMaxFine(v): boolean | string {
      // Don't use value of v because it hasn't been
      // translated to Afghani
      const minFine = this.crime.minimumFineInPuls;
      const maxFine = this.crime.maximumFineInPuls;
      if (minFine && maxFine && minFine > maxFine) {
        return '> ' + i18n.t('crime.maximumFine').toString();
      }
      return true;
    },

    maxFineLargerThanMinFine(v): boolean | string {
      // Don't use value of v because it hasn't been
      // translated to Afghani
      const minFine = this.crime.minimumFineInPuls;
      const maxFine = this.crime.maximumFineInPuls;
      if (minFine && maxFine && maxFine < minFine) {
        return '< ' + i18n.t('crime.minimumFine').toString();
      }
      return true;
    },
  },

  components: {
    CorpusSelector,
    PunishmentTypeSelector,
    UserInitials,
  },
});
