import _ from 'lodash';
import { domainmodelMutations } from '@/lib/vuex-domainmodel';
import attachmentAPI from './attachmentAPI';

export interface Attachment {
  id?: number;
  entityType: string;
  entityID: number;
  location?: string;
  label: string;
  mimeType: string;
  fileSize?: number;
  fileNameOriginal: string;
  thumbnailURI?: string;
  uri?: string;
  uploadedAt: string | Date | null;
  isDeleted: boolean;
}

const state = {
  attachment: {} as { [id: number]: Attachment },
};

const getters = {
  attachmentsForEntity:
    (state) =>
    (entityType: string, entityID: number, location = ''): Attachment[] => {
      const attachments = (Object.values(state.attachment) ||
        []) as Attachment[];
      return _.chain(attachments)
        .filter((a) => {
          return (
            a.entityType === entityType &&
            a.entityID === entityID &&
            a.location === location
          );
        })
        .sortBy((a) => a.uploadedAt)
        .value() as Attachment[];
    },
};

const mutations = {
  ...domainmodelMutations,
};

const actions = {
  async fetchAttachments(
    { commit },
    payload = {} as { entityType: string; entityID: number; location: string },
  ): Promise<void> {
    const response = await attachmentAPI.get(
      `/attachments/${payload.entityType}/${payload.entityID}?location=${payload.location}`,
    );
    const newState = response.data.data;
    commit('setState', newState);
    return Promise.resolve();
  },
};

const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default store;
