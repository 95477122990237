var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('v-col',{attrs:{"cols":_vm.cols,"sm":_vm.sm}},[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('fee.summary'))+" ")]),_c('v-card-text',[_c('FormError',{attrs:{"error":_vm.error}}),_c('v-data-table',{attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.categoryID",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.nameForIDInCollection(value, 'court/feeCategories'))+" ")]}},{key:"item.assessedInPuls",fn:function(ref){
var value = ref.value;
return [_c('AFNDisplay',{attrs:{"value":value}})]}},{key:"item.paidInPuls",fn:function(ref){
var value = ref.value;
return [_c('AFNDisplay',{attrs:{"value":value}})]}},{key:"item.balanceInPuls",fn:function(ref){
var value = ref.value;
return [_c('AFNDisplay',{attrs:{"value":value}})]}}],null,true)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }