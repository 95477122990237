













































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { criteriaToQueryString } from '@/lib/criteria';
import { HousingFacility } from '@/vuex/housing/housing';

import OffenderFlags from '@/components/offender/classification/OffenderFlags.vue';

export default Vue.extend({
  props: {
    criteria: Object,
  },

  created() {
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },

  data() {
    return {
      loading: false,
      error: null as any,

      q: '',
      results: [],

      // Pagination Attributes
      currentPage: 1,
      pageSize: 10,
      serverTotalResults: 0,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('housing', ['facilityWithID']),

    facility(): HousingFacility {
      return this.facilityWithID(this.criteria.facilityID);
    },

    fullCriteria(): any {
      return Object.assign({}, this.criteria, {
        q: this.q,
        facilityIDExact: true,
      });
    },

    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },

    headers(): any[] {
      return [
        { value: 'name', text: i18n.t('person.name') },
        { value: 'surname', text: i18n.t('person.surname') },
        { value: 'DIN', text: i18n.t('offender.din') },
        { value: 'PIN', text: i18n.t('offender.pin') },
        { value: null, text: i18n.t('dashboard.roster.custody') },
        { sortable: false, value: null, text: i18n.t('flag.plural') },
        {
          value: 'expectedReleaseAt',
          text: i18n.t('offender.expectedReleaseAt'),
          align: 'center',
        },
      ];
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.currentPage = 1;
        this.results = [];
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.results = [];
      this.error = null;

      const args = [] as string[];
      args.push(`page[size]=${this.pageSize}`);
      args.push(`page[number]=${this.currentPage}`);
      args.push(this.queryString);

      try {
        const url = `/offenders/search?${args.join('&')}`;
        const res = await offenderAPI.get(url);
        this.serverTotalResults = _.get(res, 'data.meta.totalResults') || 0;
        this.results = _.get(res, 'data.data', []);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    OffenderFlags,
  },
});
