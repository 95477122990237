























import Vue from 'vue';
import _ from 'lodash';
import { mapState } from 'vuex';

export default Vue.extend({
  props: {
    minDuration: {
      type: Object,
      required: false,
    },
    maxDuration: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState('crime', ['imprisonmentDurations']),
    ...mapState('language', ['locale']),
    filteredImprisonmentDurations() {
      // Get all except unknown duration
      return this.imprisonmentDurations.slice(
        1,
        this.imprisonmentDurations.length,
      );
    },
  },
  methods: {
    inRange(currentDurationID: number): boolean {
      const minimumDurationBoundary: number =
        (this.minDuration && this.minDuration.id) || 0;

      let maximumDurationBoundary: number;

      if (!this.minDuration && !this.maxDuration) {
        maximumDurationBoundary = 0;
      } else {
        maximumDurationBoundary =
          (this.maxDuration && this.maxDuration.id + 1) ||
          this.imprisonmentDurations.length;
      }

      return _.inRange(
        currentDurationID,
        minimumDurationBoundary,
        maximumDurationBoundary,
      );
    },
  },
});
