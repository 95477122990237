

















import _ from 'lodash';
import Vue from 'vue';

import { CustomField } from '@/vuex/court/options';

import CustomFieldForm from './CustomFieldForm.vue';

export default Vue.extend({
  props: {
    alreadyUsedNames: Array,
  },
  data() {
    return {
      isOpen: false,

      customField: null as CustomField | null,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.customField = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to emit out the newly-created field object.
     */
    save(): void {
      if (!this.customField) {
        return;
      }

      const isRepeated = _.includes(
        this.alreadyUsedNames,
        this.customField.name,
      );
      if (isRepeated) {
        return;
      }

      this.$emit('save', this.customField);
      this.isOpen = false;
    },
  },
  components: {
    CustomFieldForm,
  },
});
