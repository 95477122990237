














import _ from 'lodash';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Judge } from '@/vuex/court/judge';

import JudgeForm from '@/components/court/judge/JudgeForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      loading: false,
      error: null as any,

      updatedJudge: null as Judge | null,
    };
  },

  computed: {
    ...mapGetters('court', ['judgeWithID']),
  },

  methods: {
    ...mapActions('court', ['fetchJudge']),

    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.fetchData();
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.updatedJudge) {
        return;
      }
      this.error = null;

      try {
        // API Call and Response
        const response = await courtAPI.post('judges/update', {
          data: {
            id: this.updatedJudge.id,
            name: this.updatedJudge.name,
            surname: this.updatedJudge.surname,
            fatherName: this.updatedJudge.fatherName,
            grandfatherName: this.updatedJudge.grandfatherName,
            emailAddress: this.updatedJudge.emailAddress,
            phoneNumber: this.updatedJudge.phoneNumber,
            isDeleted: this.updatedJudge.isDeleted,
            jurisdictions: this.updatedJudge.jurisdictions,
          },
        });
        const newState = _.get(response, 'data.data', {});
        this.$store.commit('court/setState', newState);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },

    /**
     * fetchData fetches the entire judge object from the server to make sure
     * we have current info before editing a Judge.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      await this.fetchJudge(this.id);
      const myCopy = Object.assign({}, this.judgeWithID(this.id));
      this.updatedJudge = myCopy;
      this.loading = false;
    },
  },

  components: {
    JudgeForm,
  },
});
