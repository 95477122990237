





















































import Vue from 'vue';

export default Vue.extend({
  /*
    event listener required by consumer/parent:
      - '@confirm', as in @confirm="someFunction(someArg)".
        this will get called in the parent if the affirmative is clicked
    optional props:
      - `confirm-if`. Optionally accepts a conditional; if the passed
         conditional is true, the confirmation will appear.
         If it's false **THE CONFIRM CALLBACK WILL EXECUTE IMMEDIATELY**.
      - `prompt`. this is techically optional, but necessary for UI-purposes. 
         there is no default for prompt. It can be passed in a simple prop,
         or the `body` slot can be used for more complicated markup
      - `title`. (defaults to translated 'warning')
      - `yes`. (defaults to translated 'yes')
      - `no`. (defaults to translated 'no')

    slots:
      - default slot: This where the button magic happens. You can treat
        ConfirmationButton just like you would a v-btn, as far as the default
        slot (button title and icon) is concerned.
      - body slot: Used in place of 'prompt' prop if a customized
        v-card-text is desired.
  */
  props: {
    color: {
      type: String,
      default: '',
    },
    confirmIf: {
      // Pass a conditional here
      type: Boolean,
      default: true,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    prompt: {
      required: false,
      type: String,
    },
    title: {
      type: String,
    },
    toolbarColor: {
      type: String,
      default: 'warning',
    },
    yes: {
      type: String,
    },
    yesColor: {
      type: String,
      default: 'accent',
    },
    no: {
      type: String,
    },
    noColor: {
      type: String,
      default: 'error',
    },
  },
  data(): any {
    return {
      dialogOpen: false,
      event: null as any,
    };
  },

  computed: {
    hideNo(): boolean {
      if (this.no === '') {
        return true;
      }
      return false;
    },

    noText(): string {
      if (this.no) {
        return this.no;
      }
      return this.$i18n.t('command.no');
    },

    yesText(): string {
      if (this.yes) {
        return this.yes;
      }
      return this.$i18n.t('command.yes');
    },

    titleText(): string {
      if (this.title) {
        return this.title;
      }
      return this.$i18n.t('uiElements.confirm.warning');
    },
  },

  methods: {
    openConfirmDialog(event: any): void {
      this.event = event;

      // display the confirm dialog unless we've been given a conditional.
      if (this.confirmIf) {
        this.dialogOpen = true;
      } else {
        this.executeConfirm(this.event);
      }
    },

    executeConfirm(): void {
      this.$emit(
        'confirm',
        this.event,
      ); /* This triggers the PARENT template to call the */
      this.close(); /* function it passed as a 'confirm' prop        */
      return;
    },

    close(): void {
      this.dialogOpen = false;
    },
  },
});
