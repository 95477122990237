


























import Vue from 'vue';

import OffenderCharges from '@/views/offender/OffenderCharges.vue';
import OffenderPardonDecreesTable from '@/components/offender/pardonDecree/OffenderPardonDecreesTable.vue';
import SentenceAdjustmentsTable from '@/components/offender/sentenceAdjustment/SentenceAdjustmentsTable.vue';

export default Vue.extend({
  props: {
    id: Number,
    contentOnly: Boolean,
  },
  components: {
    OffenderCharges,
    OffenderPardonDecreesTable,
    SentenceAdjustmentsTable,
  },
});
