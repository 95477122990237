
















































import Vue from 'vue';
import _ from 'lodash';
import { cloneDeep, tap, set } from 'lodash';
import { mapGetters } from 'vuex';

import { JudgeDispositionTypeIDs } from '@/vuex/court/charge';
import { Participant, SentenceComponent } from '@/vuex/court/participant';

import ChargesForm from '@/components/charges/ChargesForm.vue';
import SentenceComponentsForm from '@/components/court/case/SentenceComponentsForm.vue';

export default Vue.extend({
  props: {
    value: Array as () => Participant[],
    caseID: Number,
    dossierID: Number,
  },

  computed: {
    ...mapGetters('court', [
      'defendantsForCase',
      'defendantsForDossier',
      'fullPartyName',
    ]),

    participants(): Participant[] {
      if (this.value && this.value.length > 0) {
        return this.value;
      }

      let participants: Participant[] = [];
      if (this.caseID) {
        participants = _.cloneDeep(this.defendantsForCase(this.caseID));
      } else if (this.dossierID) {
        participants = _.cloneDeep(this.defendantsForDossier(this.dossierID));
      }

      participants = _.map(participants, (p) => {
        // Initialize a blank set of sentence components
        p.sentenceComponents = [] as SentenceComponent[];

        // Any charges which had a judge-set Disposition Type get reset so this
        // judge can set their own.
        p.charges = _.map(p.charges, (c) => {
          if (_.includes(JudgeDispositionTypeIDs, c.dispositionTypeID)) {
            c.dispositionTypeID = 0;
          }
          return c;
        });
        return p;
      });

      return participants;
    },
  },

  methods: {
    updateParticipant(i: number, key: string, value: any): void {
      // Create a deep copy of the current participants array
      const newParticipants = cloneDeep(this.participants);

      // Apply the change to the copy from the array
      tap(newParticipants[i], (p) => {
        set(p, key, value);
      });

      this.$emit('input', newParticipants);
    },
  },

  components: {
    ChargesForm,
    SentenceComponentsForm,
  },
});
