










import Vue from 'vue';

import OffenderProgramsTable from '@/components/offender/programs/OffenderProgramsTable.vue';

export default Vue.extend({
  props: {
    id: Number,
    contentOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OffenderProgramsTable,
  },
});
