




























import _ from 'lodash';
import Vue from 'vue';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      results: [] as any[],
    };
  },
  computed: {
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const url = `/offenders/by-security-level?${this.queryString}`;
        const response = await offenderAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } finally {
        this.loading = false;
      }
    },
  },
});
