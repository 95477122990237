













































































import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { blankTranslatedString } from '@/lib/translated';
import { VSelectItem } from '@/lib/vue-typescript';
import { AfghanistanID, Geography } from '@/vuex/geography/geography';

export default Vue.extend({
  props: {
    value: Object,

    initialParentID: Number,
    initialType: String,
  },

  data() {
    return {
      parentSelectorUnlocked: false,
    };
  },

  computed: {
    ...mapGetters('language', ['languages']),
    ...mapState('language', ['locale']),

    /**
     * geography computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    geography(): Geography {
      return this.value
        ? this.value
        : {
            id: 0,
            name: blankTranslatedString(),
            type: '',
            code: '',
            iso3166Code: '',
            parentID: AfghanistanID,
            path: '',
          };
    },

    parentIsEditable(): boolean {
      if (!this.geography.id) {
        // When creating a new Geography, the parent is always editable
        return true;
      }
      // Otherwise allow editing if the parent selector has been unlocked
      return this.parentSelectorUnlocked;
    },

    /**
     * typeChoices builds the array of objects to feed to the type selector
     */
    typeChoices(): VSelectItem[] {
      const choices = [
        {
          value: 'country',
          text: i18n.t('geo.country'),
        },
        {
          value: 'province',
          text: i18n.t('geo.province'),
        },
        {
          value: 'district',
          text: i18n.t('geo.district'),
        },
        {
          value: 'village',
          text: i18n.t('geo.village'),
        },
      ];
      return choices;
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any, modifier: string = ''): void {
      let modifiedValue = value;
      if (modifier === 'trim') {
        modifiedValue = value.trim();
      }
      this.$emit(
        'input',
        tap(cloneDeep(this.geography), (v) => set(v, key, modifiedValue)),
      );
    },
  },
});
