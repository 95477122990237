














import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { DocketEventType } from '@/vuex/court/options';

import DocketEventTypeForm from './DocketEventTypeForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      error: null as any,

      docketEventType: null as DocketEventType | null,
    };
  },
  computed: {
    ...mapState('court', ['docketEventTypes']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      if (this.id) {
        const match = _.find(this.docketEventTypes, (rt) => rt.id === this.id);
        const myCopy = Object.assign({}, match);
        this.docketEventType = myCopy;
      } else {
        this.docketEventType = null;
      }
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We use it to persist the
     * object to the server.
     */
    async save(): Promise<void> {
      if (this.docketEventType === null) {
        return;
      }
      this.error = null;
      try {
        // Send the API call
        const response = await courtAPI.post('docket-event-types/update', {
          data: {
            id: this.docketEventType.id,
            module: this.docketEventType.module,
            categoryID: this.docketEventType.categoryID,
            rank: this.docketEventType.rank,
            name: this.docketEventType.name,
            willChangeDossierStage: this.docketEventType.willChangeDossierStage,
            newDossierStageID: this.docketEventType.newDossierStageID,
            willChangeDepartment: this.docketEventType.willChangeDepartment,
            newDepartmentID: this.docketEventType.newDepartmentID,
            willChangeDossierStatus:
              this.docketEventType.willChangeDossierStatus,
            newDossierStatusID: this.docketEventType.newDossierStatusID,
            willChangeCaseStatus: this.docketEventType.willChangeCaseStatus,
            newCaseStatusID: this.docketEventType.newCaseStatusID,
            willChangeCourtStage: this.docketEventType.willChangeCourtStage,
            newCourtStageID: this.docketEventType.newCourtStageID,
            caseDispositionReasonID:
              this.docketEventType.caseDispositionReasonID,
            allowedCourtStageIDs: this.docketEventType.allowedCourtStageIDs,
            allowedDossierStageIDs: this.docketEventType.allowedDossierStageIDs,
            allowedCaseTypes: this.docketEventType.allowedCaseTypes,
            fields: this.docketEventType.fields,
            willSetAwarenessNumber: this.docketEventType.willSetAwarenessNumber,
            willFinalizeDossier: this.docketEventType.willFinalizeDossier,
            isDiscontinued: this.docketEventType.isDiscontinued,
          },
        });

        // Update the Vuex store.
        // Special Case Warning!!
        // This is a little different than most Vuex updates in the app because
        // docket event types are stored like options arrays instead of as
        // indexed lookup tables. We have to find the right index in the array
        // to update.
        const types = _.get(response, 'data.data.docketEventType');
        const updatedType = _.first(Object.values(types)) as DocketEventType;
        const i = _.findIndex(this.docketEventTypes, { id: updatedType.id });
        this.$store.commit('court/setTarget', {
          target: 'docketEventTypes',
          index: i,
          value: updatedType,
        });

        // Dismiss the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    DocketEventTypeForm,
  },
});
