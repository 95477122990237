

















import _ from 'lodash';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Judge } from '@/vuex/court/judge';
import { Court } from '@/vuex/court/court';

export default Vue.extend({
  props: {
    // label is a passthrough to the v-autocomplete label prop
    label: String,

    // Override just in case it is easier to customize the list
    // outside of this component
    items: {
      type: Array,
      default: () => [] as Judge[],
    },
    // rules is a passthrough to the v-autocomplete rules prop
    rules: {
      type: Array,
      default: () => [], // Default value is a function which returns a blank array
    },
    // disabled is a passthrough to the v-autocomplete disabled prop
    disabled: Boolean,
    // value is the prop that makes this component work with v-model
    value: Number,
    clearable: Boolean,
    autoSelectFirst: Boolean,
    autoFocus: Boolean,
    // courtID is used filter judges by court
    courtID: Number,
    // caseID is used filter judges by case
    caseID: Number,
    // exclude is an array prop that contains IDs of judges to filter out of
    // the list so that they are not even visible to be selected.
    excludedIDs: {
      type: Array,
      default: () => [] as number[],
    },
    randomize: Boolean,
    locationGeographyID: Number,

    stageIDs: [Number, Array],
  },
  data() {
    return {
      loading: false,
      filterCourtID: 0,
      temp: Number,
    };
  },
  created() {
    this.fetchData();
    this.randomizeJudgesIfNeeded();
  },
  watch: {
    courtID() {
      this.randomizeJudgesIfNeeded();
    },
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('geography', ['isDescendantGeography', 'geographyWithID']),
    ...mapGetters('court', [
      'activeJurisdictionsForJudge',
      'courtWithID',
      'judgeName',
      'allJudges',
      'caseJudgesByCaseID',
    ]),

    // judgeChoices is the heart of this component. It builds a list of Judges
    // a couple different ways:
    //
    // - A hard-coded list (when the items prop is supplied)
    // - A court-based list (when the courtID prop is supplied)
    // - All judges in the database, optionally filtered by the stages,
    //   and locationGeographyID prop
    //
    judgeChoices(): Judge[] {
      // Initially start with all non-deleted judges
      let availableJudges = this.allJudges.filter((j: Judge) => !j.isDeleted);

      if (this.items && this.items.length > 0) {
        // ---------------------------------------------------------------------
        //
        // Items Mode
        //
        // ---------------------------------------------------------------------
        // Replace the availableJudges with those supplied in the items prop.
        availableJudges = this.items as Judge[];
      } else if (this.caseID) {
        // ---------------------------------------------------------------------
        //
        // CaseJudge Mode
        //
        // ---------------------------------------------------------------------
        const caseJudgeIDs = _.map(
          this.caseJudgesByCaseID(this.caseID),
          'judgeID',
        );
        availableJudges = availableJudges.filter((judge) => {
          return caseJudgeIDs.includes(judge.id);
        });
      } else if (this.court) {
        // ---------------------------------------------------------------------
        //
        // Court Mode
        //
        // ---------------------------------------------------------------------
        availableJudges = availableJudges.filter((j: Judge) =>
          this.isJudgeValidForCourt(j, this.court),
        );
      } else {
        // ---------------------------------------------------------------------
        //
        // Default Mode (all judges with optional filters)
        //
        // ---------------------------------------------------------------------

        // Stage Filter
        if (this.normalizedStages.length > 0) {
          availableJudges = availableJudges.filter((j: Judge) =>
            this.isJudgeValidForStages(j, this.normalizedStages),
          );
        }

        // Location Filter
        if (this.locationGeographyID) {
          availableJudges = availableJudges.filter((j: Judge) =>
            this.isJudgeValidForLocation(j, this.locationGeographyID),
          );
        }
      }

      // ---------------------------------------------------------------------
      //
      // End Mode-Specific Checks
      //
      // The following code applies in all modes
      //
      // ---------------------------------------------------------------------

      // If excludedIDs prop was provided, we make sure those IDs don't appear
      // in the list.
      if (this.excludedIDs.length > 0) {
        availableJudges = availableJudges.filter(
          (j) => !_.includes(this.excludedIDs, j.id),
        );
      }

      // Finally sort the list alphabetically
      return _.sortBy(availableJudges, 'name') as Judge[];
    },

    court(): Court {
      return this.courtWithID(this.courtID);
    },

    /**
     * normalizedStagesa enables the stages prop to be provided with either a
     * single stage or an array of stages. If neither are provided, it returns
     * an empty array.
     */
    normalizedStages(): number[] {
      return _.chain([this.stageIDs]).flatten().compact().value() as number[];
    },
  },
  methods: {
    ...mapActions('court', ['fetchJudges']),

    /**
     * fetchData ensures we have the list of judges fetched from the server
     * (it usually is, so we don't force the API call to happen).
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('court/fetchJudges');
      this.randomizeJudgesIfNeeded();
      this.loading = false;
    },

    /**
     * randomizeJudgesIfNeeded
     */
    randomizeJudgesIfNeeded() {
      // Only randomize if the randomize prop is enabled
      if (!this.randomize) {
        return;
      }

      // Don't randomize if our value is already set to a valid choice.
      const haveValidJudgeAlready =
        this.value && this.judgeChoices.some((j) => j.id === this.value);
      if (haveValidJudgeAlready) {
        return;
      }

      // Dont randomize if there are less than 2 choices.
      if (this.judgeChoices.length < 2) {
        return;
      }

      // Find the index of a random judge and trigger selection of it
      const randomIndex = Math.floor(Math.random() * this.judgeChoices.length);
      const randomJudge = this.judgeChoices[randomIndex];
      this.$emit('input', randomJudge.id);
    },

    /**
     * isJudgeValidForCourt returns true if the provided Judge has a valid,
     * active jurisdiction for the provided Court.
     */
    isJudgeValidForCourt(judge: Judge, court: Court): boolean {
      const validStage = this.isJudgeValidForStages(judge, court.stageIDs);
      const validGeo = this.isJudgeValidForLocation(
        judge,
        court.locationGeographyID,
      );
      return validStage && validGeo;
    },

    /**
     * isJudgeValidForStages returns true if the provided Judge has an active
     * jurisdiction that matches the provided stages.
     */
    isJudgeValidForStages(judge: Judge, stageIDs: number[]): boolean {
      for (const jurisdiction of this.activeJurisdictionsForJudge(judge.id)) {
        if (_.intersection(jurisdiction.stageIDs, stageIDs).length > 0) {
          return true;
        }
      }
      return false;
    },

    /**
     * isJudgeValidForLocation returns true if the provided Judge has an active
     * jurisdiction indicating that the judge's residence geography is
     * a parent (i.e. "above") the provided geographyID. For example, if the
     * Jurisdiction indicates the judge has residence in Balkh, and
     * Mazar-i-Sharif (which is in Balkh) is provided as a geographyID, then this
     * function would return true.
     */
    isJudgeValidForLocation(judge: Judge, geographyID: number): boolean {
      for (const jurisdiction of this.activeJurisdictionsForJudge(judge.id)) {
        const parentGeographyID = jurisdiction.residenceGeographyID;
        const childGeographyID = geographyID;
        if (
          this.isDescendantGeography({ parentGeographyID, childGeographyID })
        ) {
          return true;
        }
      }
      return false;
    },
  },
});
