var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-10"},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-spacer'),_c('CrimeAdminMenu'),_c('v-spacer')]},proxy:true}])},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('legacyCrime.plural'))+" ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","placeholder":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(!_vm.loading)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-caption",attrs:{"cols":"12"}},[_vm._v(" To effectively migrate CMS1 case information, each CMS 1 crime type needs to be "),_c('em',[_vm._v("mapped")]),_vm._v(" to a "),_c('strong',[_vm._v("single")]),_vm._v(" CMS 2 crime type, including the details of the Completion Status (was the crime completed, or just attempted, etc), and which Participation Type (was the person merely an Accomplice, etc). This interface provides the tools to ensure each CMS 1 crime type is correctly mapped. ")]),_c('v-col',_vm._l(([
              'Reviewed',
              'Approved',
              'GiroaReview',
              'GiroaApproved' ]),function(prefix){return _c('v-progress-linear',{key:prefix,staticClass:"mb-1",attrs:{"value":_vm.overallCompletion(prefix),"height":"20","color":"green lighten-2"}},[_vm._v(" "+_vm._s(prefix)+" ")])}),1),_c('v-col',{staticClass:"px-10"},[_c('v-select',{attrs:{"items":[
              { text: 'All', value: '' },
              { text: 'Unmapped', value: 'unmapped' },
              { text: 'Needs Review', value: 'needsReview' },
              { text: 'Needs Approval', value: 'needsApproval' },
              {
                text: 'Needs GIRoA Review',
                value: 'needsGiroaReview',
              },
              {
                text: 'Needs GIRoA Approval',
                value: 'needsGiroaApproval',
              },
              { text: 'Completed', value: 'completed' } ],"item-text":"text","item-value":"value","label":"Filter By:"},model:{value:(_vm.filterMode),callback:function ($$v) {_vm.filterMode=$$v},expression:"filterMode"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.filteredLegacyCrimes,"headers":_vm.headers,"items-per-page":15,"search":_vm.search,"footer-props":{
              'items-per-page-options': [15, 20, 40, 50, 100],
            },"no-data-text":_vm.$t('error.nodata')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.legacyID))]),_c('td',[_vm._v(_vm._s(item.legacyNameTranslated))]),_c('td',{staticClass:"text-right"},[_c('EditLegacyCrimeDialog',{attrs:{"legacyID":item.legacyID},scopedSlots:_vm._u([{key:"activator",fn:function(slotProps){return [_c('v-btn',_vm._g({attrs:{"block":"","small":"","color":!item.crimeID ? 'red' : ''}},slotProps.on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.editIcon))]),_vm._v(" "+_vm._s(item.crimeID)+" ")],1)]}}],null,true)})],1),_c('td',{class:{ 'red--text': item.crimeID == 0 }},[_c('ChargeName',{attrs:{"charge":item}})],1),_c('td',[_c('v-progress-circular',{attrs:{"value":item.reviewScore,"color":_vm.reviewColor(item.reviewScore)}})],1)])]}}],null,false,3216668736)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }