







































import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';

import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';

export default Vue.extend({
  props: {
    courtID: Number,
    caseID: Number,
  },

  data() {
    return {
      isOpen: false,

      judgeID: 0,
      isLeadJudge: false,
      isInvestigatorJudge: false,
      filedAt: moment().format(),
      error: null as any,
    };
  },

  computed: {
    ...mapGetters('court', ['caseJudgesByCaseID']),
    currentJudgeIDs(): number[] {
      return (this.caseJudgesByCaseID(this.caseID) || []).map(
        (cj) => cj.judgeID,
      );
    },
    saveAllowed(): boolean {
      return !!this.judgeID;
    },
  },

  methods: {
    /**
     * reset is our event handler for CMSFormDialog's reset event. It fires when
     * the dialog is activated. We use it to reset all fields to their zero
     * values.
     */
    reset(): void {
      this.judgeID = 0;
      this.isLeadJudge = false;
      this.isInvestigatorJudge = false;
      this.filedAt = moment().format();
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We use it to persist the
     * case judge to the server.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post('/cases/create-case-judge', {
          data: {
            caseID: this.caseID,
            judgeID: this.judgeID,
            isLeadJudge: this.isLeadJudge,
            isInvestigatorJudge: this.isInvestigatorJudge,
            filedAt: this.filedAt,
          },
        });

        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/prependToTarget', {
          target: 'caseJudgeIDsForCase',
          index: this.caseID,
          value: Object.keys(newState.caseJudge),
        });
        this.$store.commit('court/addDocketEntry', response);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    JudgeSelector,
  },
});
