




















































import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';

import { Offender } from '@/vuex/offender/offender';
import { Profile } from '@/vuex/profile/profile';

import ProfileHeaderNav from '@/components/profile/ProfileHeaderNav.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('court', ['dossiersForParty']),
    ...mapGetters('offender', ['offendersForProfile']),
    ...mapGetters('housing', ['facilityName']),
    profile(): Profile {
      return this.profileWithID(this.id);
    },
    offenders(): Offender[] {
      if (!this.profile) {
        return [];
      }
      return this.offendersForProfile(this.id);
    },
    offenderHeaders(): any[] {
      return [
        { text: i18n.t('custodyStatus.singular'), value: 'custodyStatus' },
        { text: i18n.t('offender.din'), value: 'DIN' },
        { text: i18n.t('offender.pin'), value: 'PIN' },
        {
          text: i18n.t('housingFacility.singular'),
          value: 'housingFacilityID',
        },
        { text: i18n.t('offender.detainedAt'), value: 'detainedAt' },
        {
          text: i18n.t('offender.expectedReleaseAt'),
          value: 'expectedReleaseAt',
        },
        {
          text: i18n.t('offender.freedAt'),
          value: 'freedAt',
        },
      ];
    },
  },
  components: {
    ProfileHeaderNav,
  },
});
