

















import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Number,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
    },
    singleLine: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },
  created() {
    // Force the value-change translation to happen
    // upon initial display
    this.valueChangedFromOutside(this.value);
  },
  data() {
    return {
      realValue: null as number | null,
      displayedValue: null as string | null,
    };
  },
  watch: {
    value(newValue: number) {
      this.valueChangedFromOutside(newValue);
    },
  },
  methods: {
    valueChangedFromOutside(newValue: number) {
      if (newValue) {
        this.realValue = parseInt(newValue.toString(), 10);
        this.displayedValue = (this.realValue / 100).toLocaleString('en-US');
      } else {
        this.displayedValue = null;
        this.realValue = null;
      }
    },
    updateValue(): void {
      if (!this.displayedValue) {
        this.realValue = null;
      } else {
        this.realValue = Math.round(
          parseFloat(this.displayedValue.replace(/[^\d.]/g, '')) * 100.0,
        );
      }
      this.$emit('input', this.realValue);
    },
  },
});
