







































import Vue from 'vue';
export default Vue.extend({
  props: {
    data: Object,
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    juvenileOrAdult(): string {
      if (this.data.ftype === 10 || this.data.ftype === 11) {
        return 'juvenile.singular';
      }
      return 'adult.singular';
    },

    detaineeOrPrisoner(): string {
      if (this.data.source === 1) {
        return 'offender.detainee.singular';
      }
      return 'offender.prisoner.singular';
    },

    accStatus(): string {
      switch (this.data.accStatus) {
        case 32:
          return 'Released';
        case 33:
          return 'Deceased';
        case 34:
          return 'Escaped';
        case 35:
          return 'Sentence Implementation on Hold';
        case 36:
          return 'On Leave';
        case 37:
          return 'Hospitalized';
        case 38:
          return 'Executed';
        case 39:
          return 'Incarcerated at JRC (Open Center)';
        case 40:
          return 'Incarcerated at JRC (Close Center)';
        case 41:
          return 'Incarcerated at Prison';
        case 42:
          return 'Incarcerated at Detention Center';
        case 84:
          return 'Sentenced to Death';
        case 140:
          return 'Unknown';
        case 155:
          return 'Transferred to Prison';
        case 156:
          return 'Transferred to JRC';
        case 173:
          return 'Moved to Iran Country';
        case 175:
          return 'Temporary Moved to CNJC - Detention';
        case 176:
          return 'Based on Decree No 1 (COVID 19), Released from Detention';
        case 177:
          return 'Released by Valid Grantee';
        case 178:
          return 'Released by Bail';
        case 179:
          return 'Based on Decree #15 Released from Detention Center';
        case 180:
          return 'Based on Decree #1 (Peace Decree) released from Detention Center';
      }
      return 'Unknown';
    },
  },
});
