













import _ from 'lodash';
import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';
import { Attorney } from '@/vuex/court/attorney';
import { firstModelIDInState } from '@/lib/vuex-domainmodel';

import AttorneyForm from './AttorneyForm.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      error: null as any,
      attorney: null as null | Attorney,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.attorney = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.attorney) {
        return;
      }
      this.error = null;
      try {
        const response = await courtAPI.post('create-attorney', {
          data: {
            name: this.attorney.name,
            surname: this.attorney.surname,
            fatherName: this.attorney.fatherName,
            grandfatherName: this.attorney.grandfatherName,
            licenseNumber: this.attorney.licenseNumber,
            phoneNumber: this.attorney.phoneNumber,
            emailAddress: this.attorney.emailAddress,
            residenceGeographyID: this.attorney.residenceGeographyID,
          },
        });

        const newState = response.data.data;
        this.$store.commit('court/setState', newState);

        const newID = firstModelIDInState(newState, 'attorney');
        this.$emit('saved', newID);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    AttorneyForm,
  },
});
