





























import Vue from 'vue';
import { mapGetters } from 'vuex';

import { FinePayment } from '@/vuex/court/finePayment';

export default Vue.extend({
  props: {
    dossierID: Number,
    partyID: Number,
  },
  computed: {
    ...mapGetters('court', ['finePaymentsForDossierPartyWithID']),
    payments(): FinePayment[] | [] {
      const finePayments =
        this.finePaymentsForDossierPartyWithID(this.dossierID, this.partyID) ||
        [];

      return finePayments;
    },
  },
});
