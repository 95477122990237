






import Vue from 'vue';

import AttorneySearcher from '@/components/court/attorney/AttorneySearcher.vue';

export default Vue.extend({
  components: { AttorneySearcher },
});
