

















































































































import Vue from 'vue';
import _ from 'lodash';
import i18n from '@/i18n';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { mapGetters } from 'vuex';
import { Offender } from '@/vuex/offender/offender';
import { PardonDecree } from '@/vuex/investigation/pardonDecree';

import OffenderFlags from '@/components/offender/classification/OffenderFlags.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  data() {
    return {
      loading: false,
      offenders: [] as Offender[],
    };
  },
  created(): void {
    this.fetchData();
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('investigation', ['pardonDecreeWithID']),
    ...mapGetters('profile', ['profileWithID']),

    /**
     * pardonDecree computes the PardonDecree entity matching the URL
     */
    pardonDecree(): PardonDecree {
      return this.pardonDecreeWithID(this.id);
    },

    /**
     * offendersWithProfiles combines the raw API results with the Profile
     * for each Offender.
     */
    offendersWithProfiles(): any[] {
      return _.map(this.offenders, (o) =>
        Object.assign(o, {
          profile: this.profileWithID(o.profileID) || {},
          routerLink: { name: 'offenderProfile', params: { id: String(o.id) } },
        }),
      );
    },

    /**
     * canExport computes whether the current user is allowed to download
     * an Excel export of the data on the screen.
     */
    canExport(): boolean {
      return this.hasPermission('admin.pardonDecree.excelExport');
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a commputed property so that it will
     * update with new translations when the locale changes.
     */
    headers(): any[] {
      return [
        { text: i18n.t('person.name'), value: 'name' },
        { text: i18n.t('person.surname'), value: 'surname' },
        { text: i18n.t('offender.din'), value: 'DIN' },
        { text: i18n.t('offender.pin'), value: 'PIN' },
        { text: i18n.t('flag.plural'), value: 'flagIDs' },
        {
          text: i18n.t('offender.expectedReleaseAt'),
          value: 'expectedRelease',
        },
      ];
    },
  },

  watch: {
    /**
     * id is watched so that the PardonDecree and Offenders can be re-fetched
     * when the URL changes to the ID of a new PardonDecree
     */
    id(newID: number) {
      this.fetchData();
    },
  },

  methods: {
    /**
     * fetchData retrieves the PardonDecree and recipient Offenders for this
     * PardonDecree
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const url = `pardon-decrees/${this.id}/offenders`;
        const response = await investigationAPI.get(url);
        const offenders = _.get(response, 'data.data', []);
        this.offenders = offenders;

        // Ensure each Offender's Profile is loaded in Vuex
        const profileIDs = _.map(offenders, 'profileID');
        await this.$store.dispatch('profile/fetchProfile', profileIDs);
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    OffenderFlags,
  },
});
