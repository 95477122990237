






















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('court', ['judgeName']),
    payload(): any {
      return this.entry.payload;
    },
  },
});
