










































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Participant, SentenceComponent } from '@/vuex/court/participant';
import { LookupTableRow } from '@/vuex/language/language';

import AddFinePaymentDialog from '@/components/enforcement/AddFinePaymentDialog.vue';
import FinePaymentsTable from '@/components/enforcement/FinePaymentsTable.vue';
import FulfillSentenceComponentDialog from '@/components/enforcement/FulfillSentenceComponentDialog.vue';
import PartyImprisonmentsTable from '@/components/enforcement/PartyImprisonmentsTable.vue';
import SentenceComponentDescription from '@/components/enforcement/SentenceComponentDescription.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
    caseID: Number,
    partyID: Number,
  },

  computed: {
    ...mapGetters('court', ['participantsForCase', 'participantsForDossier']),
    ...mapGetters('crime', [
      'punishmentTypeWithID',
      'punishmentTypeCategoryWithID',
    ]),
    ...mapState('crime', ['punishmentTypeCategories']),
    ...mapState('language', ['locale']),

    defendant(): Participant {
      const participants = this.caseID
        ? this.participantsForCase(this.caseID)
        : this.participantsForDossier(this.dossierID);
      return _.find(participants, { partyID: this.partyID });
    },

    /**
     * activeCategories narrows the list of Punishment Type Categories down to
     * those for which this defendant has sentence components.
     */
    activeCategories(): LookupTableRow[] {
      return _.filter(
        this.punishmentTypeCategories || [],
        (c) => this.categorizedPunishments[c.id],
      );
    },

    /**
     * sentenceComponents extends the raw sentenceComponents array from the
     * defendant to add full objects for the punishmentType and the category
     * of the punishment type.
     */
    sentenceComponents(): SentenceComponent[] {
      const defendant: Participant = this.defendant;
      if (!defendant || !defendant.sentenceComponents?.length) {
        return [];
      }
      return _.map(defendant.sentenceComponents || [], (c) => {
        // Add .type and .category properties
        const typeID = c.punishmentTypeID || 0;
        const punishmentType = this.punishmentTypeWithID(typeID);
        const catID = c.categoryID;
        const category = this.punishmentTypeCategoryWithID(catID);
        return _.extend(c, { punishmentType, category });
      });
    },

    /**
     * categorizedPunishments removes the sentence components which are
     * consequential (those are rendered separately) and then groups them
     * by categoryID so that they can be displayed in categorized groups
     */
    categorizedPunishments(): { [categoryID: number]: SentenceComponent[] } {
      return _.chain(this.sentenceComponents)
        .filter((c) => !c.isConsequential)
        .groupBy('categoryID')
        .value();
    },

    /**
     * consequentialPunishments provides the list of only the consequential
     * sentence components
     */
    consequentialPunishments(): SentenceComponent[] {
      return _.chain(this.sentenceComponents)
        .filter((c) => c.isConsequential)
        .value();
    },
  },

  components: {
    AddFinePaymentDialog,
    FinePaymentsTable,
    FulfillSentenceComponentDialog,
    PartyImprisonmentsTable,
    SentenceComponentDescription,
  },
});
