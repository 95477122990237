





























import jmoment from 'moment-jalaali';
import moment from 'moment';
import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';

export default Vue.extend({
  props: {
    value: String,
    isApproximate: Boolean,
    required: Boolean,
    readonly: Boolean,
    disabled: Boolean,
  },
  created() {
    this.date = this.value;
    this.dateKnown = !this.isApproximate;
  },
  data(): any {
    return {
      age: 0,
      dateKnown: false,
      date: '',
    };
  },
  watch: {
    value(newValue: string): void {
      this.date = newValue;
    },
    isApproximate(newApproximate: boolean): void {
      this.dateKnown = !newApproximate;
    },
    /**
     * age is watched so that we can update the date to an approximate date from
     * that year, but only if it's not already set to a date in the correct year.
     */
    age(): void {
      if (this.age !== this.ageFromDate(this.date)) {
        this.dateKnown = false;
        this.date = this.dateFromAge(this.age);
      }
    },
    date(): void {
      if (this.age !== this.ageFromDate(this.date)) {
        this.age = this.ageFromDate(this.date);
      }
      this.$emit('input', this.date);
    },
    dateKnown(): void {
      this.$emit('approximateChanged', !this.dateKnown);
    },
  },
  computed: {
    /**
     * ageDisabled determines when the age field is allowed to be edited
     */
    ageDisabled(): boolean {
      if (this.disabled) {
        return true;
      }
      return this.dateKnown;
    },

    /**
     * dateDisabled determins when the date field is allowed to be edited.
     */
    dateDisabled(): boolean {
      if (this.disabled) {
        return true;
      }
      return !this.dateKnown;
    },

    ageRules(): any[] {
      if (this.required && !this.ageDisabled) {
        return [(v) => v > 0 || i18n.t('error.required')];
      }
      return [];
    },

    dateRules(): any[] {
      if (this.required && !this.dateDisabled) {
        return [(v) => !!v || i18n.t('error.required')];
      }
      return [];
    },
  },

  methods: {
    /**
     * ageFromDate is a helper function which calculates an age in years from
     * a date in the internal (gregorian) date format (YYYY-MM-DD).
     */
    ageFromDate(date: string): number {
      if (!date) {
        return 0;
      }
      const ageInYears = moment().diff(date, 'years');
      if (ageInYears < 0) {
        return 0;
      }
      return ageInYears;
    },

    /**
     * dateFromAge is a helper function which calculates an approximate
     * birthdate when given an age in years. The birthdate is assumed to be
     * the first of Hamal (01-01) in the Afghan Jalaali calendar, but the
     * value is returned in our native internal date format (gregorian calendar),
     * so the returned date will be on March 21st of the Gregorian year.
     *
     * In other words, this function will always return YYYY-03-21, with the
     * YYYY part determined by the supplied age in years.
     */
    dateFromAge(age: number): string {
      if (age <= 0) {
        return '';
      }
      const birthYear = jmoment().jYear() - age;
      const jBirthdate = jmoment(birthYear + '-01-01', 'jYYYY-jMM-jDD');
      return jBirthdate.format('YYYY-MM-DD');
    },
  },
});
