


































import Vue from 'vue';

import FacilityRecord from './FacilityRecord.vue';
import ReleaseInfoRecord from './ReleaseInfoRecord.vue';

export default Vue.extend({
  props: {
    data: Object,
  },
  components: {
    FacilityRecord,
    ReleaseInfoRecord,
  },
});
