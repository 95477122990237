





































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Profile } from '@/vuex/profile/profile';
import { Offender } from '@/vuex/offender/offender';
import { Flags } from '@/vuex/offender/offender';

import EscapesTable from '@/components/offender/escape/EscapesTable.vue';
import IncidentsTable from '@/components/offender/incident/IncidentsTable.vue';
import OffenderReclassificationDialog from '@/views/offender/OffenderReclassificationDialog.vue';
import ProfileDetailsCard from '@/components/profile/ProfileDetailsCard.vue';
import ProfileIdentityCard from '@/components/profile/ProfileIdentityCard.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
    contentOnly: Boolean,
  },

  data(): any {
    return {
      highProfileOffenderID: Flags.highProfileOffenderID,
      securityThreatID: Flags.securityThreatID,
      specialManagementID: Flags.specialManagementID,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('offender', ['offenderWithID', 'offenderIsReleased']),
    ...mapGetters('profile', ['profileWithID']),

    profile(): Profile {
      return this.profileWithID(this.offender.profileID);
    },

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    /**
     * canReclassify returns true if the current user is allowed to reclassify
     * this offender.
     */
    canReclassify(): boolean {
      if (this.isReleased) {
        return false;
      }
      if (!this.offender) {
        return false;
      }
      return this.hasPermission(
        'offender.reclassify',
        'facility',
        this.offender.housingFacilityID,
      );
    },

    /**
     * isReleased computes whether this Offender has been released. It is used
     * to alter the display and disable features which are not used for
     * released Offenders.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.offenderID);
    },
  },
  components: {
    EscapesTable,
    IncidentsTable,
    OffenderReclassificationDialog,
    ProfileDetailsCard,
    ProfileIdentityCard,
  },
});
