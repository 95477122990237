
















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Charge } from '@/vuex/court/charge';
import {
  CompletionStatusID,
  Crime,
  ParticipationTypeID,
} from '@/vuex/crime/crime';

import AggravatorsMitigatorsDetail from '@/components/charges/AggravatorsMitigatorsDetail.vue';
import CrimeTitle from '@/components/crime/CrimeTitle.vue';

export default Vue.extend({
  props: {
    /**
     * charge expectes a Charge object, typically **one item** in an array of
     * Charge[].
     */
    charge: {
      type: Object,
      required: true,
      default: () => {
        return {} as Charge;
      },
    },

    /**
     * short Suppresses the display of the completion status, participation
     * type and severity chips entirely, regardless of hte logic that determines
     * when each is displayed.
     */
    short: Boolean,

    /**
     * full Forces the display of the completion status, participation type
     * and severity chips, regardless of whether the logic says they should
     * be displayed.
     */
    full: Boolean,
  },

  computed: {
    ...mapGetters('crime', ['crimeWithID']),
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * crime computes the full Crime object which is referenced by the crimeID
     * on this Charge.
     */
    crime(): Crime | null {
      if (!this.charge) {
        return null;
      }
      return this.crimeWithID(this.charge.crimeID);
    },

    /**
     * completionStatusLabel returns the translated text of the completion
     * status chip, or a blank string if that chip should not be shown.
     */
    completionStatusLabel(): string {
      if (this.short) {
        // In short mode, we never display the Commpletion Status
        return '';
      }

      if (!this.charge.completionStatusID) {
        // We never want to display "Unknown" in the chip
        return '';
      }

      if (this.charge.completionStatusID === CompletionStatusID.Completed) {
        // We don't want 'Completed' to appear unless we're in full mode
        if (!this.full) {
          return '';
        }
      }

      return this.nameForIDInCollection(
        this.charge.completionStatusID,
        'crime/completionStatuses',
      );
    },

    /**
     * participationTypeLabel returns the translated text of the participation
     * type chip, or a blank string if that chip should not be shown.
     */
    participationTypeLabel(): string {
      if (this.short) {
        // In short mode, we never display the Participation Type
        return '';
      }

      if (!this.charge.completionStatusID) {
        // We never want to display "Unknown" in the chip
        return '';
      }

      if (
        this.charge.participationTypeID ===
        ParticipationTypeID.PrincipalOffender
      ) {
        // We don't want 'Principal Offender' to appear unless we're in full mode
        if (!this.full) {
          return '';
        }
      }

      return this.nameForIDInCollection(
        this.charge.participationTypeID,
        'crime/participationTypes',
      );
    },

    /**
     * severityLabel returns the translated text of the severity
     * chip, or a blank string if that chip should not be shown.
     */
    severityLabel(): string {
      if (this.short) {
        // In short mode, we never display the Severity
        return '';
      }

      if (!this.charge.crimeSeverityID) {
        // We never want to display "Unknown" in the chip
        return '';
      }

      if (this.crime) {
        const thisSeverity = [this.charge.crimeSeverityID];
        const onlyValidSeverity = _.isEqual(
          thisSeverity,
          this.crime.validSeverityIDs,
        );
        if (onlyValidSeverity) {
          // When this charge has the only severity which is valid for this
          // crime type, we only want to display the severity in full mode
          if (!this.full) {
            return '';
          }
        }
      }

      return this.nameForIDInCollection(
        this.charge.crimeSeverityID,
        'crime/severities',
      );
    },

    chipProps(): object {
      return {
        label: true,
        xSmall: true,
        class: 'mx-1 px-2',
      };
    },
  },

  components: {
    AggravatorsMitigatorsDetail,
    CrimeTitle,
  },
});
