

















































































import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { AfghanistanID } from '@/vuex/geography/geography';
import { Case } from '@/vuex/court/case';
import { Party } from '@/vuex/court/party';
import { Summons } from '@/vuex/court/summons';

import PartySelector from '@/components/court/party/PartySelector.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  data() {
    return {
      isOpen: false,
      error: null as any,

      partyID: 0,
      servedAt: moment().format(),
      servedToName: '',
      serviceResultID: 1,
      modeOfServiceID: 1,
      serviceLocationID: AfghanistanID,
      serviceNotes: '',
    };
  },
  computed: {
    ...mapGetters('court', ['caseWithID', 'partyWithID', 'summonsWithID']),
    summons(): Summons | null {
      return this.summonsWithID(this.id);
    },
    kase(): Case | null {
      if (this.summons) {
        return this.caseWithID(this.summons.caseID);
      }
      return null;
    },
    party(): Party | null {
      if (this.summons) {
        return this.partyWithID(this.summons.partyID);
      }
      return null;
    },
  },
  methods: {
    reset(): void {
      this.partyID = 0;
      this.servedAt = moment().format();
      if (this.party) {
        this.servedToName = `${this.party.name} ${this.party.surname}`;
      } else {
        this.servedToName = '';
      }

      if (this.summons) {
        this.serviceLocationID = this.summons.issuanceExpectedLocationID;
      } else {
        this.serviceLocationID = 1;
      }

      this.serviceResultID = 1;
      this.modeOfServiceID = 1;
      this.serviceNotes = '';
    },
    async save(): Promise<void> {
      if (this.summons == null) {
        return;
      }
      this.error = null;

      try {
        const response = await courtAPI.post('summonses/record-service', {
          data: {
            id: this.id,
            dossierID: this.summons.dossierID,
            caseID: this.summons.caseID,
            servedAt: this.servedAt,
            serviceResultID: this.serviceResultID,
            serviceFiledAt: this.servedAt,
            modeOfServiceID: this.modeOfServiceID,
            serviceLocationID: this.serviceLocationID,
            servedToName: this.servedToName,
            serviceNotes: this.serviceNotes,
          },
        });

        // Update the local copy of the summons with the new data
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);

        // Make the docket immediately show the addition
        this.$store.commit('court/addDocketEntry', response);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    PartySelector,
  },
});
