


































import Vue from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';

import {
  Transfer,
  TransferStatus,
  ActiveTransferStatuses,
} from '@/vuex/offender/transfer';

import HousingFacilityTransfersTable from '@/components/offender/transfer/HousingFacilityTransfersTable.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  data() {
    return {
      statuses: ActiveTransferStatuses,
    };
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters('offender', ['transfersForFacility']),
    ...mapGetters('housing', ['fullFacilityName']),

    transfers(): Transfer[] {
      return this.transfersForFacility(this.id);
    },

    activeTransfers(): Transfer[] {
      return _.filter(this.transfers, (t: Transfer) =>
        _.includes(ActiveTransferStatuses, t.status),
      );
    },

    incomingTransfers(): any {
      return _.chain(this.activeTransfers)
        .filter(['toHousingFacilityID', this.id])
        .groupBy('status')
        .value();
    },

    outgoingTransfers(): any {
      return _.chain(this.activeTransfers)
        .filter(['fromHousingFacilityID', this.id])
        .groupBy('status')
        .value();
    },

    incomingStatuses(): TransferStatus[] {
      return _.filter(ActiveTransferStatuses, (s: TransferStatus) => {
        return !!this.incomingTransfers[s];
      });
    },

    outgoingStatuses(): TransferStatus[] {
      return _.filter(ActiveTransferStatuses, (s: TransferStatus) => {
        return !!this.outgoingTransfers[s];
      });
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      await this.$store.dispatch('offender/fetchActiveTransfersForFacility', {
        id: this.id,
      });
      const offenderIDs = _.map(this.transfers, 'offenderID');
      this.$store.dispatch('offender/fetchOffender', offenderIDs);
    },
  },

  components: {
    HousingFacilityTransfersTable,
  },
});
