























































































































































import _ from 'lodash';
import Vue from 'vue';
import { cloneDeep, tap, set } from 'lodash';
import { mapState, mapGetters } from 'vuex';

import { Charge } from '@/vuex/court/charge';
import { Crime, CompletionStatus } from '@/vuex/crime/crime';
import { LookupTableRow } from '@/vuex/language/language';

import CrimeSelector from '@/components/crime/CrimeSelector.vue';

export default Vue.extend({
  props: {
    value: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('language', ['languages', 'valueForLocale']),
    ...mapState('crime', [
      'completionStatuses',
      'participationTypes',
      'severities',
    ]),
    ...mapGetters('crime', ['crimeWithID']),

    /**
     * charge computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    charge(): Charge {
      return this.value
        ? this.value
        : {
            crimeID: 0,
            participationTypeID: 0,
            completionStatusID: 0,
            crimeSeverityID: 0,
            aggravators: [],
            mitigators: [],
            dispositionTypeID: 0,
          };
    },

    crime(): Crime {
      return this.crimeWithID(this.charge.crimeID);
    },

    participationTypeChoices(): LookupTableRow[] {
      const all = this.participationTypes as LookupTableRow[];
      const choices = all.filter((c) =>
        this.crime.validParticipationTypeIDs.includes(c.id),
      );
      return choices;
    },

    completionStatusChoices(): CompletionStatus[] {
      const all = this.completionStatuses as CompletionStatus[];
      const choices = all.filter((c) =>
        this.crime.validCompletionStatusIDs.includes(c.id),
      );
      return choices;
    },

    severityChoices(): LookupTableRow[] {
      const all = this.severities as LookupTableRow[];
      const choices = all.filter((c) =>
        this.crime.validSeverityIDs.includes(c.id),
      );
      return choices;
    },

    aggravatorChoices(): any[] {
      return this.crime.aggravators || [];
    },

    mitigatorChoices(): any[] {
      return this.crime.mitigators || [];
    },
  },

  methods: {
    crimeChanged(newCrimeID: number) {
      const newCharge = cloneDeep(this.charge);
      newCharge.crimeID = newCrimeID;

      // Get the new crime so that we can validate our exisitng data
      // against it.
      const newCrime = this.crimeWithID(newCrimeID);

      if (newCrime) {
        // Ensure the participation type is valid for the new crime, and if
        // there's only one choice, choose it.
        if (newCrime.validParticipationTypeIDs.length === 1) {
          newCharge.participationTypeID = newCrime.validParticipationTypeIDs[0];
        } else {
          // If there's more than one choice, try in order: 1) the current participation
          // type, 2) 'Principal Offender'. Fall back to 0 (which will trigger
          // a validation error and forced choice) otherwise.
          newCharge.participationTypeID =
            _.find([newCharge.participationTypeID, 1], (idToTry) => {
              return _.includes(newCrime.validParticipationTypeIDs, idToTry);
            }) || 0;
        }

        // Ensure completion status type is valid for the new crime, and if
        // there's only one choice, choose it.
        if (newCrime.validCompletionStatusIDs.length === 1) {
          newCharge.completionStatusID = newCrime.validCompletionStatusIDs[0];
        } else {
          // If there's more than one choice, try in order: 1) the current
          // completion status, 2) 'Completion'. Fall back to 0 (which will
          // trigger a validation error and forced choice) otherwise.
          newCharge.completionStatusID =
            _.find([newCharge.completionStatusID, 1], (idToTry) => {
              return _.includes(newCrime.validCompletionStatusIDs, idToTry);
            }) || 0;
        }

        // Ensure severity is valid for the new crime, and if there's only one
        // choice, choose it.
        if (newCrime.validSeverityIDs.length === 1) {
          newCharge.crimeSeverityID = newCrime.validSeverityIDs[0];
        } else {
          newCharge.crimeSeverityID =
            _.find([newCharge.crimeSeverityID, 2], (idToTry) => {
              return _.includes(newCrime.validSeverityIDs, idToTry);
            }) || 0;
        }
      }

      // Never allow a charge with a changed crime to keep its prior
      // aggravators and mitigators.
      newCharge.aggravators = [];
      newCharge.mitigators = [];

      // Notify consumers of the change via the input event
      this.$emit('input', newCharge);

      if (newCrimeID) {
        this.loading = true;
        this.$store.dispatch('crime/fetchCrime', newCrimeID).finally(() => {
          this.loading = false;
        });
      }
    },

    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any): void {
      this.$emit(
        'input',
        tap(cloneDeep(this.charge), (v) => set(v, key, value)),
      );
    },
  },
  components: {
    CrimeSelector,
  },
});
