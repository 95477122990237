



















































































































import _ from 'lodash';
import Vue from 'vue';

import { Corpus } from '@/vuex/corpus/corpus';
import { TAll, TUnknown } from '@/lib/translated';
import { mapState, mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  props: {
    value: {
      type: Number,
      default: null,
    },

    label: {
      type: String,
    },

    leafOnly: {
      type: Boolean,
      default: false,
    },

    exclude: {
      type: Array,
      default: () => [],
    },

    notNull: {
      type: Boolean,
      default: false,
    },

    /**
     * zeroLabel can be used to override the display of the "zero" choice
     * (the one with id == 0). In some contexts (such as when using a selector
     * as a search input) you want the lack of a selection to mean "All".
     * In other contexts (such as data entry), you want the lack of an entry
     * to mean "Unknown". Use zero-label="all" for the former,
     * and zero-label="unknown" for the latter. Most of the time,
     * zero-label="unknown" is unnecessary because the database value for 0
     * is often already set to that.
     */
    zeroLabel: { type: String },
  },

  async created() {
    await this.fetchCorpora(false);
    this.setSelectedCorpusID(this.value);
  },

  data() {
    return {
      atRootLevel: true,
      dialogOpen: false,
      selectedCorpusID: null as number | null,
      focusedCorpusID: null as number | null,
    };
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapState('corpus', ['corporaByID']),
    ...mapGetters('corpus', ['corporaWithParentID', 'corpusHasChildren']),

    fieldLabel(): string {
      if (this.label) {
        return this.label;
      }
      return this.$i18n.t('corpus.singular').toString();
    },

    allowNullSelection(): boolean {
      return !this.notNull;
    },

    selectedCorpus(): Corpus | null {
      if (this.selectedCorpusID === null) {
        return null;
      }
      return this.corporaByID[this.selectedCorpusID] || null;
    },

    selectedCorpusTitle(): string {
      if (!this.selectedCorpusID) {
        switch (this.zeroLabel) {
          case 'all':
            return TAll[this.locale];
          case 'unknown':
            return TUnknown[this.locale];
          default:
            return this.$i18n.t('corpus.root').toString();
        }
      }

      const items = [] as string[];
      let parentID = this.selectedCorpusID as number | null;
      while (parentID !== null) {
        const parent = this.corporaByID[parentID];
        if (parent) {
          items.unshift(parent.title[this.locale]);
          parentID = parent.parentID;
        } else {
          parentID = null;
        }
      }
      if (items.length < 1) {
        return String(this.$i18n.t('corpus.root'));
      }
      return items.join(' / ');
    },

    focusedCorpus(): Corpus | null {
      if (this.focusedCorpusID === null) {
        return null;
      }
      return this.corporaByID[this.focusedCorpusID];
    },

    focusedCorpusTitle(): string {
      if (this.focusedCorpus === null) {
        return this.$i18n.t('corpus.root').toString();
      }
      return this.focusedCorpus.title[this.locale];
    },

    choices(): Corpus[] {
      return this.corporaWithParentID(this.focusedCorpusID).filter((corpus) => {
        return !_.includes(this.exclude, corpus.id);
      });
    },

    tooltip(): string {
      const items = [] as string[];
      let parentID = this.selectedCorpusID;
      while (parentID !== null) {
        const parent = this.corporaByID[parentID];
        if (parent) {
          items.unshift(parent.title[this.locale]);
          parentID = parent.parentID;
        } else {
          parentID = null;
        }
      }
      if (items.length < 1) {
        switch (this.zeroLabel) {
          case 'all':
            return TAll[this.locale];
          case 'unknown':
            return TUnknown[this.locale];
          default:
            return this.$i18n.t('corpus.root').toString();
        }
      }
      return items.join(' / ');
    },
  },

  watch: {
    value(newValue) {
      this.setSelectedCorpusID(newValue);
    },
  },

  methods: {
    ...mapActions('corpus', ['fetchCorpora']),

    setSelectedCorpusID(id: number | null) {
      this.selectedCorpusID = id;
      if (id === null) {
        this.focusedCorpusID = null;
        this.atRootLevel = true;
      } else {
        this.atRootLevel = false;
        const corpus = this.corporaByID[id];
        if (corpus) {
          this.focusedCorpusID = corpus.parentID;
        }
      }
    },

    open() {
      if (this.dialogOpen) {
        return;
      }
      this.setSelectedCorpusID(this.value);
      this.dialogOpen = true;
    },

    cancel() {
      this.dialogOpen = false;
    },

    goUp() {
      if (this.focusedCorpusID === null) {
        this.atRootLevel = true;
      }
      if (this.focusedCorpus) {
        this.focusedCorpusID = this.focusedCorpus.parentID;
      }
    },

    focusCorpus(id: number | null) {
      if (this.corpusHasChildren(id)) {
        this.focusedCorpusID = id;
        this.atRootLevel = false;
      } else {
        this.selectCorpus(id);
      }
    },

    selectCorpus(id: number | null) {
      this.setSelectedCorpusID(id);
      this.$emit('input', id);
      this.dialogOpen = false;
    },
  },
});
