







































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import i18n from '@/i18n';
import { DocketEntry } from '@/vuex/court/docket';
import { DocketEventType } from '@/vuex/court/options';
import DocketEventTable from '@/components/docket/DocketEventTable.vue';
import { Dossier } from '@/vuex/court/dossier';

interface Partition {
  name: string;
  events: DocketEntry[];
  isFinalPartition: boolean;
  stageID?: number;
  statusID?: number;
  isClosed?: boolean;

  firstDate?: string;
  lastDate?: string;
  dayCount?: number;
}

type PartitionOrNull = Partition | null;

export default Vue.extend({
  name: 'PartitionedDocket',
  props: {
    dossierID: Number,
    events: Array,
  },
  data() {
    return {
      openPanels: [] as number[],
    };
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['docketEventTypes']),
    ...mapGetters('court', ['dossierWithID']),

    /**
     * partitions is the heart of this component. It is essentially a
     * transformation over the docket events in this table. But it "splits" or
     * "partitions" the events whenever a stage-changing event is encountered.
     *
     * This Partition object is unique to this component and is defined purely
     * for ease-of-use in the UI code.
     */
    partitions(): Partition[] {
      // Initialize the data as an empty state. Assume no partitions until we
      // encounter the first event.
      const partitions = [] as Partition[];
      let currentPartition = null as PartitionOrNull;
      const events = this.events as DocketEntry[];

      // Iterate over each provided event
      for (const e of events) {
        // newPartition will only be non-null if this event needs to trigger
        // a new Partition, which happens either because it's the first event,
        // or because it is an event which changes the Dossier Stage.
        const newPartition = this.newPartitionForEvent(e, currentPartition);
        if (newPartition) {
          // When we create a new partition and a prior one exists,
          // we need to mark the prior one "closed".
          if (partitions.length > 0) {
            partitions[partitions.length - 1].isClosed = true;
          }
          partitions.push(newPartition);
          currentPartition = newPartition;
        }

        // Add the event to whichever partition ended up being the current one
        if (currentPartition) {
          currentPartition.events.push(e);
        }
      }

      // Whatever partition is "current" at the end is, by-definition, the
      // final partition.
      if (currentPartition) {
        currentPartition.isFinalPartition = true;
      }

      return _.chain(partitions)
        .filter((p) => p.events.length > 0)
        .map(this.calcStats)
        .value();
    },

    /**
     * dossier returns the Dossier object around which this PartitionedDocket
     * is being presented
     */
    dossier(): Dossier {
      return this.dossierWithID(this.dossierID);
    },

    /**
     * caseType returns the dossier.type field as long as the Dossier exists.
     * It's needed to dynamically relabel certain elements differently for
     * different types of cases ("Investigation" vs "Huquq" for example).
     */
    caseType(): string {
      if (!this.dossier) {
        return '';
      }
      return this.dossier.type;
    },
  },

  watch: {
    /**
     * events is watched so that the final partition can be defaulted to being
     * the expanded partiion, with all others being closed.
     */
    events: {
      immediate: true,
      handler() {
        this.openPanels = [this.partitions.length - 1];
      },
    },
  },

  methods: {
    /**
     * initialPartition is a utility function to create the initial Partition
     * object when the first docket event is enountered.
     */
    initialPartition(): Partition {
      return {
        name: this.stageName(0),
        stageID: 0,
        events: [],
        isFinalPartition: false,
      };
    },

    /**
     * newPartitionForEvent examines a docket event and figures out if that
     * event is the point at which a new partition needs to begin.
     */
    newPartitionForEvent(
      e: DocketEntry,
      current: PartitionOrNull,
    ): PartitionOrNull {
      let newPartition: PartitionOrNull = null;

      const stageID = this.stageForEvent(e);
      if (stageID !== null && (!current || stageID !== current.stageID)) {
        const name = this.stageName(stageID);
        newPartition = { name, stageID, events: [], isFinalPartition: false };
      }

      if (!current && !newPartition) {
        newPartition = this.initialPartition();
      }

      return newPartition;
    },

    /**
     * calcStats is a post-processor for Partitions which runs AFTER they have
     * all been created. Its purpose is to set the following properties which
     * are displayed in the UI:
     *
     * - firstDate - Which is the date of the first event in the partiion
     * - lastDate - Which can be one of three things: 1) The date of the last
     *    event in the partition (if the partition is "closed" because another
     *    came after it), 2) Today's date, if the partition is the last one
     *    and the case has not been finalized yet, or 3) The finalizedAt date
     *    for the Dossier if the Dossier is finalized.
     * - dayCount - THe difference in days between firstDate and lastDate.
     */
    calcStats(p: Partition): Partition {
      const begin = moment.tz(
        _.chain(p.events).map('filedAt').first().value(),
        'Asia/Kabul',
      );

      let end = null as moment.Moment | null;
      if (p.isClosed) {
        end = moment.tz(
          _.chain(p.events).map('filedAt').last().value(),
          'Asia/Kabul',
        );
      } else if (this.dossier && this.dossier.finalizedAt) {
        end = moment.tz(this.dossier.finalizedAt, 'Asia/Kabul');
      }

      return Object.assign(p, {
        firstDate: begin.format(),
        lastDate: end ? end.format() : '',
        dayCount: end
          ? end.diff(begin, 'days') + 1
          : moment().diff(begin, 'days') + 1,
      });
    },

    /**
     * stageForEvent returns the dossier stage (a number indicating an ID
     * from court/dossierStages) which the provided event changes the Dossier
     * to, or null if the provided event does not have any effect on the active
     * Dossier Stage.
     */
    stageForEvent(e: DocketEntry): number | null {
      const t = this.customTypeForEvent(e);
      if (t && t.willChangeDossierStage) {
        return t.newDossierStageID;
      }

      // CMS1 Court Record
      const stageID = _.get(e, 'payload.stageID', null);
      if (e.actionType === 'CourtRecord' && stageID) {
        return stageID;
      }
      if (e.actionType === 'CreateCase' && stageID) {
        return stageID;
      }

      const payloadDossierStageID = _.get(e, 'payload.dossierStageID', null);
      return payloadDossierStageID;
    },

    /**
     * customTypeForEvent returns null if the provided entry came from a
     * "hard-coded" docket event (a low-level domainmodel Action). If the entry
     * was a CreateDocketEvent event (a dynamic, administrator-created event, in
     * other words), then this returns the DocketEventType to which the event
     * belongs. This is used to determine whether the event should change the
     * active Partition or not.
     */
    customTypeForEvent(e: DocketEntry): DocketEventType | null {
      if (e.actionType !== 'CreateDocketEvent') {
        return null;
      }
      const id = _.get(e, 'payload.typeID');
      if (!id) {
        return null;
      }
      const eventType: DocketEventType = _.find(this.docketEventTypes, { id });
      return eventType || null;
    },

    /**
     * stageName takes a dossier stage ID (from the court/dossierStages lookup
     * table) and returns the translated string to use as the title for the
     * partition that relates to it. Normally this function would be
     * implemented with nameForIDInCollection(id, 'court/dossierStages'), but
     * here we want to display a custom title for the 0 stage.
     */
    stageName(id: number): string {
      if (id === 0) {
        switch (this.caseType) {
          case 'criminal':
            return i18n.t('investigation.singular').toString();
          case 'civil':
            return i18n.t('huquq.singular').toString();
          case 'state':
            return i18n.t('stateCase.plural').toString();
          default:
            return 'Unknown Case Type';
        }
      }
      return this.nameForIDInCollection(id, 'court/dossierStages');
    },

    /**
     * expandAll is a utility function to expand all expansion panels (i.e. to
     * expand all Partitions)
     */
    expandAll(): void {
      this.openPanels = this.partitions.map((k, i) => i);
    },

    /**
     * collapseAll is a utility function to collapse all expansion panels
     * (i.e. to close all Partitions)
     */
    collapseAll(): void {
      this.openPanels = [];
    },
  },
  components: {
    DocketEventTable,
  },
});
