




















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import crimeAPI from '@/vuex/crime/crimeAPI';
import { LegacyCrime } from '@/vuex/crime/crime';

import LegacyCrimeForm from '@/components/crime/legacyCrime/LegacyCrimeForm.vue';

export default Vue.extend({
  props: {
    legacyID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      error: null as any,

      legacyCrime: null as LegacyCrime | null,
    };
  },
  computed: {
    ...mapGetters('crime', ['legacyCrimeWithLegacyID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.fetchData();
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.legacyCrime) {
        return;
      }
      this.error = null;

      try {
        // API Call and Response
        const data = Object.assign({}, this.legacyCrime);
        const response = await crimeAPI.post('legacy-crimes/update', { data });
        const newState = response.data.data;
        this.$store.commit('crime/setState', newState);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },

    /**
     * fetchData fetches the entire judge object from the server to make sure
     * we have current info before editing a Judge.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      const crime = this.legacyCrimeWithLegacyID(this.legacyID);
      const myCopy = Object.assign({}, crime);
      this.legacyCrime = myCopy;
      this.loading = false;
    },
  },
  components: {
    LegacyCrimeForm,
  },
});
