







import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { User } from '@/vuex/user/user';
import { TUnknown } from '@/lib/translated';

export default Vue.extend({
  props: {
    id: {
      type: Number,
    },
  },
  created(): void {
    if (this.id) {
      this.fetchData();
    }
  },
  data() {
    return {
      waiting: false,
    };
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('user', ['userWithID']),

    user(): User {
      return this.userWithID(this.id);
    },

    userInitials(): string {
      if (this.user) {
        return `${this.user.initials}`;
      } else {
        return (TUnknown[this.locale] || 'Unknown User') + ` #${this.id}`;
      }
    },
  },
  watch: {
    id(newID) {
      // This allows this component to be used unconditionally. When id isn't
      // present - either because the crime isn't reviewed or the checkbox is currently
      // UNchecked - the component will render nothing and any previous local data
      // will be wiped.
      if (newID) {
        if (!this.userWithID(newID)) {
          this.fetchData();
        }
      }
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.waiting = true;
      try {
        await this.$store.dispatch('user/fetchUser', { id: this.id });
      } finally {
        this.waiting = false;
      }
    },
  },
});
