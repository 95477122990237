var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.hasPermission('dashboard.court') ||
    _vm.hasPermission('case.create') ||
    _vm.hasPermission('search.cases') ||
    _vm.hasPermission('attorney.view') ||
    _vm.hasPermission('court.view') ||
    _vm.hasPermission('judge.view') ||
    _vm.hasPermission('party.view')
  )?_c('v-list-group',{attrs:{"value":_vm.active},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('v-subheader',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm.$t('module.court'))+" ")])],1)]},proxy:true}],null,false,3777372588)},[_vm._l((_vm.courtDashboardRoutes),function(rte,index){return _c('v-list-item',{key:index,attrs:{"to":rte,"exact":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.singular'))+" "),_c('div',{staticClass:"text--disabled text-caption"},[(rte.query.courtID > 0)?_c('CourtName',{attrs:{"id":rte.query.courtID}}):_c('GeographyName',{attrs:{"id":1}})],1)])],1)],1)}),(_vm.hasPermission('case.create'))?_c('NewCaseDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('case.singular'))+" ")])],1)],1)]}}],null,false,1879375396)}):_vm._e(),(_vm.hasPermission('search.cases'))?_c('v-list-item',{attrs:{"to":{ name: 'caseSearch' },"active":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-search")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('case.plural'))+" ")])],1)],1):_vm._e(),(_vm.hasPermission('attorney.view'))?_c('v-list-item',{attrs:{"to":{ name: 'attorneyList' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-user-tie")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('attorney.plural'))+" ")])],1)],1):_vm._e(),(_vm.hasPermission('court.view'))?_c('v-list-item',{attrs:{"to":{ name: 'courts' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-university")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('court.plural'))+" ")])],1)],1):_vm._e(),(_vm.hasPermission('judge.view'))?_c('v-list-item',{attrs:{"to":{ name: 'judgeList' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-gavel")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('judge.plural'))+" ")])],1)],1):_vm._e(),(_vm.hasPermission('party.view'))?_c('v-list-item',{attrs:{"to":{ name: 'partyList' }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-user-friends")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('party.plural'))+" ")])],1)],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }