



































































































import _ from 'lodash';
import Vue from 'vue';
import axios from 'axios';

import FeedbackWidget from '@/components/nav/FeedbackWidget.vue';
import LanguageSelector from '@/components/nav/LanguageSelector.vue';
import PrimaryNav from '@/components/nav/PrimaryNav.vue';
import Snackbar from '@/components/nav/Snackbar.vue';

import { mapState } from 'vuex';

export default Vue.extend({
  name: 'App',
  created() {
    Promise.all([
      // Load settings and translations
      this.$store.dispatch('language/initializeLocale'),
      this.$store.dispatch('settings/fetchSettings'),

      // Load service options (dropdowns, etc)
      this.$store.dispatch('user/fetchOptions'),
      this.$store.dispatch('crime/fetchOptions'),
      this.$store.dispatch('corpus/fetchCorpora'),
      this.$store.dispatch('crime/fetchCrimes'),
      this.$store.dispatch('offender/fetchOptions'),
      this.$store.dispatch('profile/fetchOptions'),
      this.$store.dispatch('court/fetchOptions'),
      this.$store.dispatch('court/fetchCourts'),
      this.$store.dispatch('investigation/fetchDepartments'),
      this.$store.dispatch('court/fetchJudges'),
      this.$store.dispatch('investigation/fetchPardonDecrees'),
      this.$store.dispatch('geography/fetchGeographies'),
      this.$store.dispatch('housing/fetchFacilities'),
    ]).then(() => {
      this.initialized = true;
    });

    this.fetchBuildDate();
  },

  data() {
    return {
      initialized: false,
      drawer: null,
      buildTimestamp: '',
      commitHash: '',
    };
  },

  computed: {
    ...mapState('auth', ['user']),
    printView(): boolean {
      return this.$route && this.$route.meta?.printView;
    },
  },

  methods: {
    async fetchBuildDate() {
      try {
        const response = await axios.get('/build.json');
        this.buildTimestamp = _.get(response, 'data.buildTimestamp');
        this.commitHash = _.get(response, 'data.commitHash');
      } catch (error) {
        console.error(error);
      }
    },
  },

  components: {
    FeedbackWidget,
    LanguageSelector,
    PrimaryNav,
    Snackbar,
  },
});
