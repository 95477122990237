

























import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';

import { Case } from '@/vuex/court/case';

import CaseNumberField from '@/components/court/case/CaseNumberField.vue';
import CourtSelector from '@/components/court/court/CourtSelector.vue';

/*
 * Pass a courtID to this component if you want the court selector to be in
 * a disabled/readonly state, pre-set. Otherwise, this component will allow
 * users to choose in which court they want to search.
 */
export default Vue.extend({
  props: {
    value: Number,
    courtID: {
      type: Number,
      default: 0,
    },
    // This prop can be passed through to the CourtSelector props
    courtStageID: Number,
    // This prop can be passed through to the CourtSelector props
    courtRules: Array,
  },

  data() {
    return {
      loading: false,
      failedSearch: false,
      caseID: 0,
      caseNumber: '',
      internalCourtID: 0,
    };
  },

  computed: {
    ...mapGetters('court', ['caseWithID']),

    kase(): Case {
      return this.caseWithID(this.caseID);
    },

    /**
     * caseFindable returns true when the component has enough information
     * to perform a search for the case. This property acts as a gate to
     * prevent nonsense searches from being sent to the server.
     */
    caseFindable(): boolean {
      return this.caseNumber.length === 9 && !!this.internalCourtID;
    },
  },
  watch: {
    value(newVal: number) {
      // Reset internal data if the ID is zeroed from outside.
      if (!newVal) {
        this.caseID = 0;
        this.caseNumber = '';
      }
    },
    caseNumber(newVal: string) {
      // in the situation where the court number is entered _before_ the caseNumber
      if (this.caseFindable) {
        this.findCase();
      } else {
        this.$emit('input', 0);
      }
    },
    courtID(val: number) {
      this.internalCourtID = val;
    },
    internalCourtID(val: number) {
      // in the situation where the case number is entered _before_ the courtID
      if (this.caseFindable) {
        this.findCase();
      } else {
        this.$emit('input', 0);
      }
    },
  },
  methods: {
    /**
     * findCase performs the API call to search on the server for a case
     * matching the provided number in the provided court. It sets the
     * loading variable true while the processing is happening.
     */
    async findCase(): Promise<void> {
      this.loading = true;
      this.failedSearch = false;

      try {
        const url = `/cases/search?number=${this.caseNumber}&courtID=${this.internalCourtID}`;
        const response = await courtAPI.get(url);

        // Only single-result exact matches are valid
        if (response.data.meta.totalResults === 1) {
          const result = response.data.data[0];
          this.caseID = result.id;
          this.$emit('input', this.caseID);
        } else {
          this.failedSearch = true;
        }
      } catch (err) {
        this.$store.dispatch('application/showSnackbarError', err);
      }

      this.loading = false;
    },
  },
  components: {
    CaseNumberField,
    CourtSelector,
  },
});
