





















































import moment from 'moment';
import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';

export default Vue.extend({
  props: {
    dossierID: Number,
    partyID: Number,
  },
  data() {
    return {
      isOpen: false,
      paidAt: moment().format(),
      amountInPuls: 0,
      receiptNumber: '',
      notes: '',
      error: null as any,
    };
  },
  methods: {
    /**
     * reset is our event handler for CMSFormDialog's reset event. It fires when
     * the dialog is activated. We use it to reset the form.
     */
    reset(): void {
      this.paidAt = moment().format();
      this.amountInPuls = 0;
      this.receiptNumber = '';
      this.notes = '';
    },

    /**
     * save is our event handler for CMSFormDialog's save event. It fires when
     * the save button is clicked on the dialog. We use it to submit the
     * completion event to the server.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const response = await courtAPI.post('courts/add-fine-payment', {
          data: {
            dossierID: this.dossierID,
            partyID: this.partyID,
            paidAt: this.paidAt,
            amountInPuls: this.amountInPuls,
            receiptNumber: this.receiptNumber,
            notes: this.notes,
          },
        });

        // The newState should contain a finePayment entity
        const newState = response.data.data;
        const newID = Object.keys(newState.finePayment)[0];

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        // ... we update local state
        this.$store.commit('court/setState', newState);
        this.$store.commit('court/appendToTarget', {
          target: 'finePaymentIDsForDossier',
          index: this.dossierID,
          value: Object.keys(newState.finePayment),
        });

        // Add to the docket
        this.$store.commit('court/addDocketEntry', response);

        // ... and close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
