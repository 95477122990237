













import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Court } from '@/vuex/court/court';

import CourtHearingCalendar from '@/components/court/hearing/CourtHearingCalendar.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  mounted(): void {
    setTimeout(window.print, 2000);
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('court', ['courtWithID']),

    court(): Court {
      return this.courtWithID(this.id);
    },
  },

  components: {
    CourtHearingCalendar,
  },
});
