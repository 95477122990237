import Vue from 'vue';
import _ from 'lodash';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';
import { TranslatedString, TUnknown } from '@/lib/translated';
import i18n from '@/i18n';

export interface Department {
  id: number;
  parentID: number | null;
  rank: number;
  code: string;
  name: TranslatedString;
  locationGeographyID: number;
  legacyDepartmentIDs: number[];
  year: number;
  lastCaseNumber: number;
  cms1Importance: number;
  cms1RecordCount: number;
  childCount?: number;
}

export const state = {
  departmentsLoaded: false,
  departmentIDs: [] as number[],
  department: {} as { [id: number]: Department },
  departmentChildIDs: {} as { [id: number]: number[] },
};

export const getters = {
  departmentWithID:
    (state) =>
    (id: number): Department | null => {
      return state.department[id] || null;
    },

  /**
   * allDepartments is a convenience getter to retrieve the entire list of
   * Departments which are currently stored in the Vuex store.
   */
  allDepartments(state): Department[] {
    return _.map(state.departmentIDs, (id) => state.department[id]);
  },

  departmentMinistryCode:
    (state, getters) =>
    (id: number): string => {
      const ancestorIDs: number[] = _.compact(
        getters.departmentAncestorIDs(id),
      );
      if (ancestorIDs.length < 1) {
        return '??';
      }
      return state.department[ancestorIDs[0]].code;
    },

  fullDepartmentName:
    (state, getters) =>
    (id: number): string => {
      const departments: Department[] = getters.departmentAncestors(id);
      return _.map(departments, (d) => d.name[i18n.locale]).join(' :: ');
    },

  /**
   * departmentName returns the common name for the Department.
   */
  departmentName:
    (state) =>
    (id: number): string => {
      const department = state.department[id];
      if (!department) {
        return TUnknown[i18n.locale] + `: ${id}`;
      }
      return department.name[i18n.locale];
    },

  /**
   * departmentsUnder returns the array of Departments that reside
   * as children of the provided Department id.
   */
  departmentsUnder:
    (state, getters) =>
    (parentID: number): Department[] => {
      const ids: number[] = state.departmentChildIDs[parentID || 0] || [];
      return _.map(ids, (id: number): Department => state.department[id]);
    },

  departmentChildCount:
    (state) =>
    (parentID: number): number => {
      const ids: number[] = state.departmentChildIDs[parentID || 0] || [];
      return ids.length || 0;
    },

  departmentAncestorIDs:
    (state) =>
    (id: number): number[] => {
      const ids: number[] = [];
      let currID = id;
      while (state.department[currID]) {
        ids.unshift(currID);
        currID = state.department[currID].parentID;
      }
      if (ids.length === 0) {
        ids.unshift(0);
      }
      return ids;
    },

  departmentAncestors:
    (state, getters) =>
    (id: number): Department[] => {
      const ids = getters.departmentAncestorIDs(id);
      return _.chain(ids)
        .map((id) => state.department[id])
        .compact()
        .value();
    },
};

export const mutations = {
  setDepartmentsLoaded(state, val: boolean) {
    state.departmentsLoaded = val;
  },
  calculateDepartmentChildStats(state) {
    Vue.set(
      state,
      'departmentIDs',
      _.chain(Object.values(state.department)).compact().map('id').value(),
    );
    Vue.set(state, 'departmentChildIDs', {});
    for (const id of state.departmentIDs) {
      const department = state.department[id];
      const parentID = department.parentID || 0;
      if (!state.departmentChildIDs.hasOwnProperty(parentID)) {
        Vue.set(state.departmentChildIDs, parentID, []);
        if (parentID && state.department[parentID]) {
          Vue.set(state.department[parentID], 'childCount', 0);
        }
      }
      state.departmentChildIDs[parentID].push(id);
      if (parentID) {
        state.department[parentID].childCount++;
      }
    }
  },
};

export const actions = {
  /**
   * fetchDepartment retrieves Department entities from the server and
   * injects them into the local Vuex store. It accepts a single ID
   * or an array of IDs.
   */
  async fetchDepartment({ commit }, id: number | number[]) {
    const ids: number[] = _.flatten([id]);
    const idsToFetch = _.difference(ids, state.departmentIDs);

    if (idsToFetch.length > 0) {
      const idsStr = idsToFetch.join(',');
      const response = await investigationAPI.get(
        `departments/batch?ids=${idsStr}`,
      );
      const newState = response.data.data;
      commit('setState', newState);
      commit('calculateDepartmentChildStats');
    }
  },

  /**
   * fetchDepartments fetches the initial set of globally-available Department
   * entities from the server and stores them in the Vuex store. It refuses
   * to re-fetch the data is it was already fetched. This can be overridden
   * by supplying { force: true } in the payload.
   */
  async fetchDepartments(
    { commit, state },
    payload = {} as { force: boolean },
  ): Promise<void> {
    if (!payload.force && state.departmentsLoaded) {
      return Promise.resolve();
    }
    const response = await investigationAPI.get(`departments`);
    const departments = response.data.data;
    const newState = modelsToState('department', departments);
    commit('setState', newState);
    commit('setTarget', {
      target: 'departmentIDs',
      value: _.map(departments, 'id'),
    });
    commit('setDepartmentsLoaded', true);
    commit('calculateDepartmentChildStats');
  },
};
