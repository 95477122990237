import Vue from 'vue';
import Router from 'vue-router';
import store from '@/vuex/store';
import {
  fetchCaseFirst,
  fetchOffenderFirst,
  fetchProfileFirst,
} from './router-data';

Vue.use(Router);

// Root Routes -----------------------------------------------------------------
// These will be in the main app.js
// -----------------------------------------------------------------------------
import Login from '@/views/auth/Login.vue';
import HomeRedirector from '@/views/HomeRedirector.vue';
import NotFound from '@/views/NotFound.vue';
import Unauthorized from '@/views/auth/Unauthorized.vue';

// User Routes -----------------------------------------------------------------
// Works as a default home page if user has no dashboard permissions
// -----------------------------------------------------------------------------
import UserHome from '@/views/user/UserHome.vue';

// Admin Chunk -----------------------------------------------------------------
// Rarely-used administrative features, regardless of which area of focus.
//
// -----------------------------------------------------------------------------
import AttorneyList from '@/views/courts/AttorneyList.vue';
import ComponentLibrary from '@/views/ComponentLibrary.vue';
import CorpusEdit from '@/views/corpus/CorpusEdit.vue';
import CorpusList from '@/views/corpus/CorpusList.vue';
import CorpusNew from '@/views/corpus/CorpusNew.vue';
import CorpusShow from '@/views/corpus/CorpusShow.vue';
import CourtCalendarPrint from '@/views/courts/CourtCalendarPrint.vue';
import CourtList from '@/views/courts/CourtList.vue';
import CourtroomList from '@/views/courts/CourtroomList.vue';
import CrimeGroupList from '@/views/crime/CrimeGroupList.vue';
import CrimeList from '@/views/crime/CrimeList.vue';
import DepartmentList from '@/views/investigation/DepartmentList.vue';
import DocketEventTypeList from '@/views/courts/DocketEventTypeList.vue';
import GeographyList from '@/views/geography/GeographyList.vue';
import HolidayList from '@/views/courts/HolidayList.vue';
import HousingFacilityList from '@/views/housing/HousingFacilityList.vue';
import JudgeList from '@/views/courts/JudgeList.vue';
import LegacyCrimeList from '@/views/crime/LegacyCrimeList.vue';
import LocaleMessageList from '@/views/translation/LocaleMessageList.vue';
import LookupTableEditor from '@/views/translation/LookupTableEditor.vue';
import PardonDecreeList from '@/views/investigation/PardonDecreeList.vue';
import PartyList from '@/views/courts/PartyList.vue';
import ProsecutorsList from '@/views/investigation/ProsecutorsList.vue';
import PunishmentTypesList from '@/views/crime/PunishmentTypesList.vue';
import RoleList from '@/views/user/RoleList.vue';
import UserAuditReport from '@/views/user/UserAuditReport.vue';
import UserList from '@/views/user/UserList.vue';

// Court Chunk -----------------------------------------------------------------
// End-user court case management
//
// -----------------------------------------------------------------------------
import CaseDossierDetail from '@/views/courts/CaseDossierDetail.vue';
import CaseList from '@/views/courts/CaseList.vue';
import CaseShow from '@/views/courts/CaseShow.vue';
import CourtDashboard from '@/views/courts/CourtDashboard.vue';

// Investigation Chunk ---------------------------------------------------------
// End-user dossier/investigation management
// -----------------------------------------------------------------------------
import CrimeReportPrint from '@/views/detection/CrimeReportPrint.vue';
import CrimeReportList from '@/views/detection/CrimeReportList.vue';
import CrimeReportShow from '@/views/detection/CrimeReportShow.vue';
import DetectionDashboard from '@/views/investigation/DetectionDashboard.vue';
import DossierContainer from '@/views/dossier/DossierContainer.vue';
import DossierList from '@/views/dossier/DossierList.vue';
import InvestigationDashboard from '@/views/investigation/InvestigationDashboard.vue';
import PardonDecreeShow from '@/views/investigation/PardonDecreeShow.vue';
import ProfileDossiers from '@/views/profile/ProfileDossiers.vue';
import ProfileFacilities from '@/views/profile/ProfileFacilities.vue';
import ProfileList from '@/views/profile/ProfileList.vue';
import ProfileShow from '@/views/profile/ProfileShow.vue';
import BackgroundCheck from '@/views/profile/BackgroundCheck.vue';

// Prison Chunk ----------------------------------------------------------------
// End-user prison management.
// -----------------------------------------------------------------------------
import HousingFacilityTransfers from '@/views/housing/HousingFacilityTransfers.vue';
import IntakeStepper from '@/views/offender/IntakeStepper.vue';
import OffenderContainer from '@/views/offender/OffenderContainer.vue';
import OffenderDashboard from '@/views/offender/OffenderDashboard.vue';
import OffenderList from '@/views/offender/OffenderList.vue';
import OffenderPrint from '@/views/offender/OffenderPrint.vue';

// CMS1 Viewer / ETL Features --------------------------------------------------
import CMS1StandaloneViewer from '@/views/etl/CMS1StandaloneViewer.vue';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/admin/component-library',
      name: 'componentLibrary',
      component: ComponentLibrary,
    },
    {
      path: '/',
      name: 'home',
      component: HomeRedirector,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: Unauthorized,
    },
    {
      path: '/user-home',
      name: 'userHome',
      component: UserHome,
    },
    {
      path: '/admin/courts',
      name: 'courts',
      component: CourtList,
    },
    {
      path: '/cases/search',
      name: 'caseSearch',
      component: CaseList,
    },
    {
      path: '/courts/dashboard',
      name: 'courtDashboard',
      component: CourtDashboard,
    },
    {
      path: '/courts/:id/courtrooms',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'courtrooms',
      component: CourtroomList,
    },
    {
      path: '/crimes',
      name: 'crimeList',
      component: CrimeList,
    },
    {
      path: '/crime-groups',
      name: 'crimeGroupList',
      component: CrimeGroupList,
    },
    {
      path: '/legacy-crimes',
      name: 'legacyCrimeList',
      component: LegacyCrimeList,
    },
    {
      path: '/departments/detection-dashboard',
      name: 'detectionDashboard',
      component: DetectionDashboard,
    },
    {
      path: '/departments/investigation-dashboard',
      name: 'investigationDashboard',
      component: InvestigationDashboard,
    },
    {
      path: '/crime-reports/search',
      name: 'crimeReportSearch',
      component: CrimeReportList,
    },
    {
      path: '/crime-reports/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'crimeReportShow',
      component: CrimeReportShow,
    },
    {
      path: '/crime-reports/:id/print',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'crimeReportPrint',
      component: CrimeReportPrint,
      meta: { printView: true },
    },
    {
      path: '/corpora',
      name: 'corpusList',
      component: CorpusList,
    },
    {
      path: '/corpus/new',
      name: 'corpusNew',
      component: CorpusNew,
    },
    {
      path: '/corpus/:id',
      name: 'corpusShow',
      props: (route) => ({ id: Number(route.params.id) }),
      component: CorpusShow,
    },
    {
      path: '/corpus/:id/edit',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'corpusEdit',
      component: CorpusEdit,
    },
    {
      path: '/dossiers/search',
      name: 'dossierSearch',
      component: DossierList,
    },
    {
      path: '/housing-facilities',
      name: 'housingFacilityList',
      props: (route) => ({ parentID: Number(route.query.parentID) }),
      component: HousingFacilityList,
    },
    {
      path: '/housing-facilities/:id/transfers',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'housingFacilityTransfers',
      component: HousingFacilityTransfers,
    },
    {
      path: '/offenders/dashboard',
      name: 'offenderDashboard',
      component: OffenderDashboard,
    },
    {
      path: '/admin/users',
      name: 'userList',
      component: UserList,
    },
    {
      path: '/admin/roles',
      name: 'roleList',
      component: RoleList,
    },
    {
      path: '/admin/user-audit',
      name: 'userAuditReport',
      component: UserAuditReport,
    },
    {
      path: '/offenders/search',
      name: 'offenderSearch',
      component: OffenderList,
    },
    {
      path: '/offenders/new',
      name: 'offenderNew',
      component: IntakeStepper,
    },
    {
      path: '/offenders/:id',
      redirect: '/offenders/:id/profile',
    },
    {
      path: '/offenders/:id/profile',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'offenderProfile',
      component: OffenderContainer,
      beforeEnter: fetchOffenderFirst,
    },
    {
      path: '/offenders/:id/housing',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'offenderHousing',
      component: OffenderContainer,
      beforeEnter: fetchOffenderFirst,
    },
    {
      path: '/offenders/:id/sentence',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'offenderSentence',
      component: OffenderContainer,
      beforeEnter: fetchOffenderFirst,
    },
    {
      path: '/offenders/:id/programs',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'offenderPrograms',
      component: OffenderContainer,
      beforeEnter: fetchOffenderFirst,
    },
    {
      path: '/offenders/:id/medical',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'offenderMedical',
      component: OffenderContainer,
      beforeEnter: fetchOffenderFirst,
    },
    {
      path: '/offenders/:id/print',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'offenderPrint',
      component: OffenderPrint,
      beforeEnter: fetchOffenderFirst,
      meta: { printView: true },
    },
    {
      path: '/courts/:id/calendar/print',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'courtCalendarPrint',
      component: CourtCalendarPrint,
      meta: { printView: true },
    },
    {
      path: '/profiles/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'profileShow',
      component: ProfileShow,
      beforeEnter: fetchProfileFirst,
    },
    {
      path: '/profiles/:id/dossiers',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'profileDossiers',
      component: ProfileDossiers,
      beforeEnter: fetchProfileFirst,
    },
    {
      path: '/profiles/:id/facilities',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'profileFacilities',
      component: ProfileFacilities,
      beforeEnter: fetchProfileFirst,
    },
    {
      path: '/translation/locale-messages',
      name: 'localeMessageList',
      component: LocaleMessageList,
    },
    {
      path: '/translation/lookup-tables',
      name: 'lookupTableEditor',
      component: LookupTableEditor,
    },
    {
      path: '/admin/punishment-types',
      name: 'punishmentTypesList',
      component: PunishmentTypesList,
    },
    {
      path: '/dossiers/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'dossierShow',
      component: DossierContainer,
    },
    {
      path: '/dossiers/:id/courts',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'dossierCourts',
      component: DossierContainer,
    },
    {
      path: '/dossiers/:id/enforcement',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'dossierEnforcement',
      component: DossierContainer,
    },
    {
      path: '/dossiers/:id/docket',
      redirect: '/dossiers/:id',
    },
    {
      path: '/cases/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'caseShow',
      component: CaseShow,
      beforeEnter: fetchCaseFirst,
    },
    {
      path: '/cases/:id/dossier',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'caseDossierDetail',
      component: CaseDossierDetail,
      beforeEnter: fetchCaseFirst,
    },
    {
      path: '/admin/judges',
      name: 'judgeList',
      component: JudgeList,
    },
    {
      path: '/admin/prosecutors',
      name: 'prosecutors',
      component: ProsecutorsList,
    },
    {
      path: '/admin/attorneys',
      name: 'attorneyList',
      component: AttorneyList,
    },
    {
      path: '/admin/departments',
      name: 'departmentList',
      component: DepartmentList,
    },
    {
      path: '/admin/docket-event-types',
      name: 'docketEventTypeList',
      component: DocketEventTypeList,
    },
    {
      path: '/admin/geographies',
      name: 'geographyList',
      component: GeographyList,
    },
    {
      path: '/admin/holidays',
      name: 'holidayList',
      component: HolidayList,
    },
    {
      path: '/admin/pardon-decrees',
      name: 'pardonDecreeList',
      component: PardonDecreeList,
    },
    {
      path: '/admin/pardon-decrees/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'pardonDecreeShow',
      component: PardonDecreeShow,
    },
    {
      path: '/admin/parties',
      name: 'partyList',
      component: PartyList,
    },
    {
      path: '/admin/profiles',
      name: 'profileList',
      component: ProfileList,
    },
    {
      path: '/admin/background-check',
      name: 'backgroundCheck',
      component: BackgroundCheck,
    },
    {
      path: '/admin/cms1-viewer',
      name: 'cms1Viewer',
      component: CMS1StandaloneViewer,
    },

    // '*' catches all routes that don't match any of the above and redirects to the 404 page.
    // This route MUST be the last item in routes[] in order to work as expected.
    {
      path: '*',
      component: NotFound,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/autoLogin');

  const loggedIn = !!store.getters['auth/isAuthenticated'];

  // Always allow non-logged in visits to /login
  if (!loggedIn && to.path === '/login') {
    next();
    return;
  }

  // Redirect home instead of /login if already logged in
  if (to.path === '/login' && loggedIn) {
    next('/');
    return;
  }

  if (loggedIn) {
    next();
    return;
  }

  // Redirect to /login if logged out
  if (!loggedIn) {
    if (to.path !== '/login') {
      store.commit('auth/setAfterLoginPath', to.path);
    }
    next('/login');
  }
});

export default router;
