


































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Party } from '@/vuex/court/party';

import AddVictimDialog from '@/components/detection/crimeReport/AddVictimDialog.vue';

export default Vue.extend({
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('court', ['partyWithID']),
    victims(): Party[] {
      return _.map(this.value || [], (id) => this.partyWithID(id));
    },
    headers(): any[] {
      const h = [
        {
          text: this.$i18n.t('person.name'),
          value: 'name',
        },
        {
          text: this.$i18n.t('person.surname'),
          value: 'surname',
        },
        {
          text: this.$i18n.t('person.fatherName'),
          value: 'fatherName',
        },
        {
          text: this.$i18n.t('person.grandfatherName'),
          value: 'grandfatherName',
        },
        {
          text: this.$i18n.t('command.delete'),
          width: '36px',
        },
      ];
      return h;
    },
  },
  methods: {
    addVictim(partyID: number): void {
      const newItems = _.uniq([...this.value, partyID]);
      this.$emit('input', newItems);
    },
    deleteVictimByIndex(index: number): void {
      const newItems = [...this.value];
      newItems.splice(index, 1);
      this.$emit('input', newItems);
    },
  },
  components: {
    AddVictimDialog,
  },
});
