












import _ from 'lodash';
import Vue from 'vue';

import i18n from '@/i18n';
import { LookupItem } from '@/vuex/court/options';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    /**
     * items is the Vuex state address this component will pull its
     * choices from. Example usage:
     *
     * items="offender/courses"
     */
    items: {
      type: String,
      required: true,
    },
    translationKeyPrefix: {
      type: String,
      required: true,
    },
    value: String,
    label: String,
    rules: Array,
    disabled: Boolean,
    clearable: Boolean,
  },
  computed: {
    /**
     * types computes the actual list of LookupTableItem type choices for the
     * dropdown. The list is based on the `type` field from the items
     * supplied via the `items` prop.
     */
    types(): VSelectItem[] {
      const types = _.chain(this.lookupTableItems).map('type').uniq().value();
      return _.map(types, (type) => ({
        value: type,
        text: i18n.t(this.translationKeyPrefix + '.' + type),
      }));
    },

    /**
     * lookupTableItems builds the raw data of all possible lookup table items.
     * We extract the list of type choices from this list.
     */
    lookupTableItems(): LookupItem[] {
      let choices: LookupItem[] = [];

      // Get the choices from the state
      const itemNames = this.items.split('/');
      if (itemNames.length > 1) {
        const moduleName = itemNames[0];
        const variableName = itemNames[1];
        // A deep clone is needed so that transformations below aren't
        // persisted in Vuex State
        choices = _.cloneDeep(this.$store.state[moduleName][variableName]);
      } else {
        // A deep clone is needed so that transformations below aren't
        // persisted in Vuex State
        choices = _.cloneDeep(this.$store.state[this.items]);
      }
      if (!choices) {
        throw new Error(`Could not find "${this.items}" in Vuex state.`);
      }

      // Filter choices with no name
      choices = _.filter(choices, (c) => {
        return !!c.name;
      });

      return choices;
    },
  },
});
