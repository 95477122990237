






















import Vue from 'vue';

import ProfileSearchSelector from '@/components/profile/ProfileSearchSelector.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      suspectProfileID: 0,
    };
  },
  methods: {
    reset(): void {
      this.suspectProfileID = 0;
    },
    save(): void {
      this.$emit('save', this.suspectProfileID);
      this.isOpen = false;
    },
  },
  components: {
    ProfileSearchSelector,
  },
});
