var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(slotProps){return [_c('v-btn',_vm._g({attrs:{"small":""}},slotProps.on),[(_vm.dossier.isLegacyLocked)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa-lock")]):_vm._e(),_vm._v(" CMS1 Data ("+_vm._s(_vm.versions.length)+") ")],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('legacyDossierVersion.plural'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("fa-times-circle")])],1)],1),_c('v-card-text',[_c('FormError',{attrs:{"error":_vm.error}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"selectable",attrs:{"items":_vm.versions,"headers":_vm.headers,"hide-default-footer":"","disable-pagination":"","disable-sort":""},on:{"click:row":_vm.versionSelected},scopedSlots:_vm._u([{key:"item.cms1LastChangedAt",fn:function(ref){
var value = ref.value;
return [_c('Date',{attrs:{"value":value,"show-time":""}})]}},{key:"item.firstReceivedAt",fn:function(ref){
var value = ref.value;
return [_c('Date',{attrs:{"value":value,"show-time":""}})]}},{key:"item.lastReceivedAt",fn:function(ref){
var value = ref.value;
return [_c('Date',{attrs:{"value":value,"show-time":""}})]}},{key:"item.cms1LastChangedTable",fn:function(ref){
var value = ref.value;
return [_c('pre',[_vm._v(_vm._s(value))])]}},{key:"item.timesReceived",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.timesReceived)+" ("+_vm._s(item.timesLoaded)+") ")]}}],null,true)})],1)],1),(_vm.selected)?_c('v-row',[(_vm.selected)?_c('v-col',{attrs:{"cols":"12"}},[(_vm.selectedDataLoading)?_c('v-progress-linear',{staticClass:"my-6",attrs:{"indeterminate":""}}):_c('CMS1Viewer',{attrs:{"data":_vm.selectedData},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"error","loading":_vm.isLoading},nativeOn:{"click":function($event){return _vm.load(_vm.selected.id)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fa-redo")]),_vm._v(" Re-Migrate ")],1)]},proxy:true}],null,false,844462360)})],1):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }