




































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { PartyAttorney } from '@/vuex/court/participant';

import AddPartyAttorneyDialog from '@/components/court/attorney/AddPartyAttorneyDialog.vue';
import EditPartyAttorneyDialog from '@/components/court/attorney/EditPartyAttorneyDialog.vue';
import PartyAttorneyDisplay from '@/components/court/attorney/PartyAttorneyDisplay.vue';

export default Vue.extend({
  props: {
    value: Array,
  },

  computed: {
    ...mapState('court', ['attorneyTypes']),
    ...mapGetters('language', ['nameForIDInCollection']),

    partyAttorneys(): PartyAttorney[] {
      return (this.value ? this.value : []) as PartyAttorney[];
    },
  },

  methods: {
    addPartyAttorney(partyAttorney: PartyAttorney): void {
      const newItems = [...(this.value || []), partyAttorney];
      this.$emit('input', newItems);
    },

    deletePartyAttorneyAtIndex(index: number): void {
      const newItems = [...this.value];
      newItems.splice(index, 1);
      this.$emit('input', newItems);
    },
  },

  components: {
    AddPartyAttorneyDialog,
    EditPartyAttorneyDialog,
    PartyAttorneyDisplay,
  },
});
