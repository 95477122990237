import Vue from 'vue';
import _ from 'lodash';
import { PunishmentType } from '../crime/crime';
import { Charge } from './charge';
import { PartyTypeID } from './party';
import { LookupTableRow } from '@/vuex/language/language';

export interface Participant {
  id?: number; // Unset during creation
  dossierID?: number;
  caseID?: number;
  partyID: number;
  partyTypeID: number;
  originalPartyTypeID: number;
  isDeleted?: boolean;
  charges?: Charge[];
  attorneys?: PartyAttorney[];
  sentenceComponents?: SentenceComponent[];
  isDecisionAccepted?: boolean | null; // Omitting this implies null not false
}

export interface PartyAttorney {
  id?: number; // Unset during creation
  partyID?: number; // Unset when being added to a new party
  attorneyID: number;
  attorneyTypeID: number;
  isCourtAssigned: boolean;
}

export interface SentenceComponent {
  id?: number;
  dossierID?: number;
  caseID?: number;
  partyID?: number;
  punishmentTypeID: number;
  categoryID: number;
  durationInHours: number;
  amountInPuls: number;
  notes: string;
  subjectPropertyIDs: number[];
  isConsequential: boolean;
  isFulfilled?: boolean;

  /**
   * Non-Persisted Properties. These are not exchanged with the server, and
   * can often be undefined. Certain parts of the UI add these to make display
   * logic simpler.
   */
  punishmentType?: PunishmentType;
  category?: LookupTableRow;
}

export const state = {
  /**
   * participant is the domain model lookup for Participant entities
   */
  participant: {} as { [id: number]: Participant },

  /**
   * participantIDsForCase is an index tracking which Participant IDs belong
   * to which caseIDs.
   */
  participantIDsForCase: {} as { [caseID: number]: number[] },

  /**
   * participantIDsForDossier is an index tracking which Participant IDs belong
   * to which dossierIDs.
   */
  participantIDsForDossier: {} as { [dossierID: number]: number[] },
};

export const getters = {
  /**
   * participantWithID is a simple helper function to retrieve a single
   * Participant entity from Vuex state by its database row ID.
   */
  participantWithID:
    (state) =>
    (participantID: number): Participant => {
      return state.participant[participantID];
    },

  /**
   * participantInDossierOrCase is used to retrieve a single Participant from
   * the state, based on its complete key (dossierID + caseID + partyID). Since
   * Vuex state is held separately for Dossier Participants and Case Participants,
   * this function decides which state to check based on whether the caseID
   * was provided or not.
   */
  participantInDossierOrCase:
    (state, getters) =>
    (dossierID: number, caseID: number, partyID: number): Participant => {
      if (caseID) {
        return getters.participantInCase(caseID, partyID);
      }
      return getters.participantInDossier(dossierID, partyID);
    },

  /**
   * participantInDossier retrieves a single Participant object from the Vuex
   * state by searching the Participants attached to the provided dossierID
   * and finding the one who's partyID matches the provided partyID.
   */
  participantInDossier:
    (state) =>
    (dossierID: number, partyID: number): Participant => {
      const participantIDs = state.participantIDsForDossier[dossierID] || [];
      const participants = _.map(participantIDs, (id) => state.participant[id]);
      return _.find(participants, { partyID });
    },

  /**
   * participantInCase retrieves a single Participant object from the Vuex
   * state by searching the Participants attached to the provided caseID
   * and finding the one who's partyID matches the provided partyID.
   */
  participantInCase:
    (state) =>
    (caseID: number, partyID: number): Participant => {
      const participantIDs = state.participantIDsForCase[caseID] || [];
      const participants = _.map(participantIDs, (id) => state.participant[id]);
      return _.find(participants, { partyID });
    },

  /**
   * participantsForDossier retrieves ALL Participants who have been associated
   * with the provided dossierID.
   */
  participantsForDossier:
    (state, getters) =>
    (dossierID: number): Participant[] => {
      const participantIDs = state.participantIDsForDossier[dossierID] || [];
      return participantIDs.map((id: number) => {
        return state.participant[id];
      });
    },

  /**
   * primaryParticipantsForDossier is based on participantsForDossier, but it
   * filters the list down to just those that should be presented at the top
   * of the case, the "primary" participants. This is the Plaintiff and Respondent
   * for civil cases and the Prosecutor and Defendant(s) for criminal cases.
   */
  primaryParticipantsForDossier:
    (state, getters) =>
    (dossierID: number): Participant[] => {
      const all: Participant[] = getters.participantsForDossier(dossierID);
      const primary = all.filter((p: Participant) =>
        _.includes(getters.primaryPartyTypeIDs, p.partyTypeID),
      );
      return _.filter(primary, (p) => !p.isDeleted);
    },

  /**
   * defendantsForDossier is based on participantsForDossier, but it
   * filters the list down to just those who are Defendants.
   */
  defendantsForDossier:
    (state, getters) =>
    (dossierID: number): Participant[] => {
      const all: Participant[] =
        getters.primaryParticipantsForDossier(dossierID);
      return all.filter(
        (p) =>
          p.partyTypeID === PartyTypeID.Defendant ||
          p.originalPartyTypeID === PartyTypeID.Defendant,
      );
    },

  /**
   * participantsForCase retrieves ALL Participants who have been associated
   * with the provided caseID.
   */
  participantsForCase:
    (state, getters) =>
    (caseID: number): Participant[] => {
      const participantIDs = state.participantIDsForCase[caseID] || [];
      return participantIDs.map((id: number) => {
        return state.participant[id];
      });
    },

  /**
   * primaryParticipantsForCase is based on participantsForCase, but it
   * filters the list down to just those that should be presented at the top
   * of the case, the "primary" participants. This is the Plaintiff and Respondent
   * for civil cases and the Prosecutor and Defendant(s) for criminal cases.
   */
  primaryParticipantsForCase:
    (state, getters) =>
    (caseID: number): Participant[] => {
      const all: Participant[] = getters.participantsForCase(caseID);
      const primary = all.filter((p: Participant) =>
        _.includes(getters.primaryPartyTypeIDs, p.partyTypeID),
      );
      return _.filter(primary, (p) => !p.isDeleted);
    },

  /**
   * defendantsForCase is based on participantsForCase, but it
   * filters the list down to just those who are Defendants.
   */
  defendantsForCase:
    (state, getters) =>
    (caseID: number): Participant[] => {
      const all: Participant[] = getters.primaryParticipantsForCase(caseID);
      return all.filter((p) => p.partyTypeID === PartyTypeID.Defendant);
    },
};

export const mutations = {
  /**
   * reindexCaseParticipants re-computes the participantIDsForCase
   * index from scratch by scanning the entire local participant
   * state and finding the ones with the provided caseID. This
   * mutation is intended to be called after new subject properties
   * are added to the state.
   */
  reindexCaseParticipants(state, caseID): void {
    const all: any[] = Object.values(state.participant);
    const ids = _.chain(all).filter({ caseID }).map('id').value();
    Vue.set(state.participantIDsForCase, caseID, ids);
  },
  /**
   * reindexDossierParticipants re-computes the participantIDsForDossier
   * index from scratch by scanning the entire local participant
   * state and finding the ones with the provided dossierID. This
   * mutation is intended to be called after new subject properties
   * are added to the state.
   */
  reindexDossierParticipants(state, dossierID): void {
    const all: Participant[] = Object.values(state.participant);
    const ids = _.chain(all).filter({ dossierID, caseID: 0 }).map('id').value();
    Vue.set(state.participantIDsForDossier, dossierID, ids);
  },
};

export const actions = {};
