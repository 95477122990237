var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.translatedFacilities.length)?_c('v-data-iterator',{attrs:{"items":_vm.translatedFacilities,"search":_vm.search,"disable-pagination":!_vm.isPaginated,"hide-default-footer":!_vm.isPaginated,"footer-props":{ itemsPerPageOptions: [-1, 10, 20, 50, 100] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [(_vm.translatedFacilities.length)?_c('v-expansion-panels',{staticClass:"mt-4",attrs:{"multiple":""}},_vm._l((items),function(f){return _c('v-expansion-panel',{key:f.id},[_c('v-expansion-panel-header',{attrs:{"color":"grey lighten-3"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[(
                      _vm.hasPermissionInPath('facility.edit', 'facility', f.path)
                    )?_c('v-col',{attrs:{"cols":"1"}},[_c('EditHousingFacilityDialog',{attrs:{"facilityID":f.id}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"1"}},[(f.acceptsTransfers)?_c('v-icon',{attrs:{"x-small":"","color":"grey","title":_vm.$t('housingFacility.acceptsTransfers')}},[_vm._v(" fa-truck ")]):_vm._e()],1),_c('v-col',{class:{ isDeleted: f.hidden },attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(f.translatedName)+" ")])],1)],1),_c('v-expansion-panel-content',[(_vm.hasPermission('facility.edit', 'facility', f.id))?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center"},[(f.hidden)?_c('ConfirmationButton',{attrs:{"prompt":'Are you sure you want to unhide ' +
                        f.translatedName +
                        '?',"x-small":"","text":"","color":"accent"},on:{"confirm":function($event){return _vm.unHideFacility(f.id)}}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("fa-eye")]),_vm._v(" "+_vm._s(_vm.$t('command.unHide'))+": "+_vm._s(f.translatedName)+" ")],1):_vm._e(),(!f.hidden)?_c('ConfirmationButton',{attrs:{"prompt":'Are you sure you want to hide ' +
                        f.translatedName +
                        '?',"x-small":"","text":"","color":"error"},on:{"confirm":function($event){return _vm.hideFacility(f.id)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa-eye-slash")]),_vm._v(" "+_vm._s(_vm.$t('command.hide'))+": "+_vm._s(f.translatedName)+" ")],1):_vm._e()],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('LabeledData',{attrs:{"cols":2,"label":_vm.$t('housingFacility.type'),"value":_vm.$t('housingFacility.typeOptions.' + f.type)}}),_c('LabeledData',{attrs:{"cols":2,"label":_vm.$t('housingFacility.capacity'),"value":f.capacity}}),(f.canHoldJuveniles)?_c('LabeledData',{attrs:{"cols":2,"label":_vm.$t('housingFacility.canHoldJuveniles'),"value":f.canHoldJuveniles}}):_vm._e(),(f.canHoldAdults)?_c('LabeledData',{attrs:{"cols":2,"label":_vm.$t('housingFacility.canHoldAdults'),"value":f.canHoldAdults}}):_vm._e(),(f.canHoldDetainees)?_c('LabeledData',{attrs:{"cols":2,"label":_vm.$t('housingFacility.canHoldDetainees'),"value":f.canHoldDetainees}}):_vm._e(),(f.canHoldPrisoners)?_c('LabeledData',{attrs:{"cols":2,"label":_vm.$t('housingFacility.canHoldPrisoners'),"value":f.canHoldPrisoners}}):_vm._e(),(f.canHoldMales)?_c('LabeledData',{attrs:{"cols":2,"label":_vm.$t('housingFacility.canHoldMales'),"value":f.canHoldMales}}):_vm._e(),(f.canHoldFemales)?_c('LabeledData',{attrs:{"cols":2,"label":_vm.$t('housingFacility.canHoldFemales'),"value":f.canHoldFemales}}):_vm._e()],1),_c('HousingFacilityContainer',{attrs:{"facilityID":f.id,"depth":_vm.depth + 1}}),(_vm.canAddChildren)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.canAddChildren(f))?_c('AddFacilityChildrenDialog',{attrs:{"parentFacilityID":f.id}}):_vm._e()],1)],1):_vm._e()],1)],1)}),1):_vm._e()]}}],null,false,2093706723)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }