

























import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import SampleCaseNav from './SampleCaseNav.vue';
import PrimaryDetectionNav from './PrimaryDetectionNav.vue';
import PrimaryInvestigationNav from './PrimaryInvestigationNav.vue';
import PrimaryCourtNav from './PrimaryCourtNav.vue';
import PrimaryPrisonNav from './PrimaryPrisonNav.vue';
import PrimaryAdministrationNav from './PrimaryAdministrationNav.vue';

export default Vue.extend({
  computed: {
    ...mapState('settings', ['settings']),
    ...mapGetters('auth', ['hasPermission']),
  },
  methods: {
    logout(): void {
      this.$store.dispatch('auth/logout');
    },
  },
  components: {
    SampleCaseNav,
    PrimaryCourtNav,
    PrimaryDetectionNav,
    PrimaryInvestigationNav,
    PrimaryPrisonNav,
    PrimaryAdministrationNav,
  },
});
