




import { Crime } from '@/vuex/crime/crime';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import { TUnknown } from '@/lib/translated';

export default Vue.extend({
  props: {
    id: Number,
  },

  computed: {
    ...mapGetters('crime', ['crimeWithID']),
    ...mapGetters('language', ['valueForLocale']),

    crime(): Crime {
      return this.crimeWithID(this.id);
    },

    title(): string {
      if (!this.crime) {
        let str = this.valueForLocale(TUnknown);
        str = str + ' ' + i18n.t('crimeType.singular') + `( #${this.id} )`;
        return str;
      }
      return this.valueForLocale(this.crime.title);
    },
  },
});
