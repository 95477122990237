












































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import NewCaseDialog from '@/components/court/case/NewCaseDialog.vue';

export default Vue.extend({
  props: {
    active: Boolean,
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'entityIDsWithPermission']),

    /**
     * courtDashboardRoutes computes an array of Vue router Locations which
     * are this user's dashboard links. If the user has no Court dashboard
     * permissions, an empty array is returned.
     */
    courtDashboardRoutes(): any {
      // Get a list of entity IDs for which the current user has permissions.
      // This is an array which might contain '*' indicating "all entities".
      const entityIDs = this.entityIDsWithPermission('dashboard.court');

      // Define the common denominator route template. This will be enhanced
      // with query parameters for the courtID below
      const routeTemplate = { name: 'courtDashboard' };

      // Produce a Location/Route object for each permitted entity
      return _.map(entityIDs, (id: number | string) => {
        if (id === '*') {
          return Object.assign({}, routeTemplate, {
            // The root CourtDashboardRoute route. The null courtID is
            // required to override a param which might have been added
            // separately
            query: { courtID: null },
          });
        }
        return Object.assign({}, routeTemplate, {
          query: { courtID: id },
        });
      });
    },
  },
  components: {
    NewCaseDialog,
  },
});
