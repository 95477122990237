
































































































import _ from 'lodash';
import Vue from 'vue';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { criteriaToQueryString, routeToCriteria } from '@/lib/criteria';

import CrimeReportProvinceMap from '@/components/detection/dashboard/CrimeReportProvinceMap.vue';
import CrimeReportsByCrimeTable from '@/components/detection/dashboard/CrimeReportsByCrimeTable.vue';
import CrimeReportsByDepartmentTable from '@/components/detection/dashboard/CrimeReportsByDepartmentTable.vue';
import CrimeReportsByLocationTable from '@/components/detection/dashboard/CrimeReportsByLocationTable.vue';
import CrimeReportsBySeverityTable from '@/components/detection/dashboard/CrimeReportsBySeverityTable.vue';
import DossierDocketEventCounts from '@/components/investigation/dashboard/DossierDocketEventCounts.vue';

export default Vue.extend({
  data() {
    return {
      loading: false,

      menuExpanded: false,

      criteria: {
        activeOnly: true,
        locationID: 1,
        departmentID: 0,
      },

      // Stats holds the top-line statistics for this department
      stats: {
        crimeReportCount: 0,
        averageFilingDelay: 0,
      },
    };
  },
  computed: {
    /**
     * dossierCriteria converts the crtieria object into a DossierCriteria
     * instead of a CrimeReportCriteria.
     */
    dossierCriteria(): any {
      return {
        activeOnly: this.criteria.activeOnly,
        firstDepartmentLocationID: this.criteria.locationID,
        firstDepartmentID: this.criteria.departmentID,
      };
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        routeToCriteria(this.$route, this.criteria);
        this.fetchData();
      },
    },
  },

  methods: {
    /**
     * fetchData is the top-level function which triggers all other data
     * fetches and waits for them all to complete before changing the loading
     * variable back to false.
     */
    async fetchData(): Promise<void> {
      this.loading = true;
      try {
        const queryString = criteriaToQueryString(this.criteria);
        const url = `/crime-reports/stats?${queryString}`;
        const response = await investigationAPI.get(url);
        const newStats = _.get(response, 'data.data', {});
        this.stats = Object.assign(this.stats, newStats);
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    CrimeReportProvinceMap,
    CrimeReportsByCrimeTable,
    CrimeReportsByDepartmentTable,
    CrimeReportsByLocationTable,
    CrimeReportsBySeverityTable,
    DossierDocketEventCounts,
  },
});
