










































import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import i18n from '@/i18n';

import { AfghanistanID } from '@/vuex/geography/geography';
import { CourtStageIDs } from '@/vuex/court/court';
import { Jurisdiction } from '@/vuex/court/judge';

import EditJudgeJurisdictionDialog from '@/components/court/judge/EditJudgeJurisdictionDialog.vue';
import NewJudgeJurisdictionDialog from '@/components/court/judge/NewJudgeJurisdictionDialog.vue';

export default Vue.extend({
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      defaultJurisdiction: {
        id: 0,
        judgeID: 0,
        startsAt: null,
        endsAt: null,
        residenceGeographyID: AfghanistanID,
        stageIDs: [CourtStageIDs.primary],
      },
    };
  },
  computed: {
    ...mapState('court', ['courtStages']),
    ...mapGetters('geography', ['fullGeographyName']),
    ...mapGetters('language', ['nameForIDInCollection']),
    headers(): any[] {
      const h = [
        {
          text: this.$i18n.t('command.edit'),
          value: 'edit',
          width: '60px',
          sortable: false,
        },
        {
          text: this.$i18n.t('daterange.start'),
          value: 'startsAt',
          width: '120px',
        },
        {
          text: this.$i18n.t('daterange.end'),
          value: 'endsAt',
          width: '120px',
        },
        {
          text: 'Residence',
          value: 'residenceGeographyID',
        },
        {
          text: i18n.t('court.stage.singular'),
          value: 'stages',
        },
        { value: 'delete', width: '100px', sortable: false },
      ];
      return h;
    },
    jurisdictions(): Jurisdiction[] {
      return (this.value || []) as Jurisdiction[];
    },
  },
  methods: {
    addJurisdiction(jurisdiction: Jurisdiction): void {
      if (!jurisdiction) {
        jurisdiction = this.defaultJurisdiction;
      }
      const newJurisdictions = [...this.jurisdictions, jurisdiction];
      this.$emit('input', newJurisdictions);
    },
    deleteJurisdiction(id: any): void {
      const newJurisdictions = [...this.jurisdictions];
      const index = newJurisdictions.indexOf(id);
      newJurisdictions.splice(index, 1);
      this.$emit('input', newJurisdictions);
    },
  },
  components: {
    EditJudgeJurisdictionDialog,
    NewJudgeJurisdictionDialog,
  },
});
