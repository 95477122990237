



























































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { IncidentType } from '@/vuex/offender/incident';
import { Offender } from '@/vuex/offender/offender';

import FacilitySelector from '@/components/offender/facility/FacilitySelector.vue';
import OffenderNameAndPIN from '@/components/offender/OffenderNameAndPIN.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },
  data() {
    return {
      isOpen: false,
      occurredAt: moment().format(),
      housingFacilityID: 0,
      typeID: IncidentType.Violent,
      reportedBy: '',
      details: '',
      victims: [] as string[],
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('offender', ['offenderWithID', 'offenderIsReleased']),

    offender(): Offender {
      return this.offenderWithID(this.offenderID);
    },

    /**
     * canAddIncident computes whether an Incident can be added for this
     * Offender. This checks both status (released Offenders can't have new
     * incidents added, for example), and permissions (not all users will have
     * permissions to add an Incident).
     */
    canAddIncident(): boolean {
      if (this.offenderIsReleased(this.offenderID)) {
        return false;
      }

      if (!this.offender) {
        return false;
      }

      return this.hasPermission(
        'offender.incident.create',
        'facility',
        this.offender.housingFacilityID,
      );
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      const offender = this.offenderWithID(this.offenderID);
      if (offender) {
        this.housingFacilityID = offender.housingFacilityID;
      }

      this.occurredAt = moment().format();
      this.typeID = IncidentType.Violent;
      this.reportedBy = '';
      this.details = '';
      this.victims = [];
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        // API call and response
        const response = await offenderAPI.post('offenders/create-incident', {
          data: {
            offenderID: this.offenderID,
            housingFacilityID: this.housingFacilityID,
            occurredAt: this.occurredAt,
            typeID: this.typeID,
            victims: _.compact(this.victims),
            details: this.details,
            reportedBy: this.reportedBy,
          },
        });
        const newState = response.data.data;
        const newID = Object.keys(newState.incident)[0];
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'incidentIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.incident),
        });

        // Attach the attachments
        (this.$refs.attachments as any).updateEntityID(newID);

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },

    addVictim(): void {
      this.victims.push('');
    },
  },
  components: {
    FacilitySelector,
    OffenderNameAndPIN,
  },
});
