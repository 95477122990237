















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { AllCourtStageIDs } from '@/vuex/court/court';
import { VSelectItem } from '@/lib/vue-typescript';
import { TAll } from '@/lib/translated';

export default Vue.extend({
  props: {
    label: String,
    value: [Number, Array],
    rules: Array,
    hint: String,
    persistentHint: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    chips: Boolean,
    items: {
      required: false,
      type: [Number, Array],
    },

    includeUnknown: Boolean,
    unknownLabel: String,
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('language', ['locale']),

    /**
     * stageIDs builds the list of court stage IDs that the user will be
     * allowed to select from. This can be supplied directly by the user via
     * the items prop, or obtained via the AllCourtStageIDs const. Choices for
     * null (Unknown) and 0 (Before Court) are added optionally if props are
     * set.
     */
    stageIDs(): any[] {
      // Wrap and flatten items to convert from a single item to an array
      const ids: any[] = this.items
        ? _.flatten([this.items])
        : _.cloneDeep(AllCourtStageIDs);

      if (this.includeUnknown) {
        ids.unshift(0);
      }
      return ids;
    },

    /**
     * stageChoices builds the array of dropdown selection items based on
     * which stageIDs have been selected.
     */
    stageChoices(): VSelectItem[] {
      const choices: VSelectItem[] = _.map(this.stageIDs, (id) =>
        this.buildItemForID(id),
      );
      return choices;
    },
  },

  methods: {
    buildItemForID(id: number): VSelectItem {
      let text = this.nameForIDInCollection(id, 'court/courtStages');
      if (!id) {
        if (this.unknownLabel === 'all') {
          text = TAll[this.locale];
        }
      }
      return { value: id, text };
    },
  },
});
