












































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { Hearing } from '@/vuex/court/hearing';
import { LookupItem } from '@/vuex/court/options';

import _ from 'lodash';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', ['hearingWithID', 'courtroomName']),
    ...mapState('court', ['hearingTypes']),
    payload(): any {
      if (!this.entry) {
        return null;
      }
      return this.entry.payload;
    },
    hearingType(): LookupItem | null {
      if (!this.hearing) {
        return null;
      }
      return this.nameForIDInCollection(this.hearing.typeID, this.hearingTypes);
    },
    hearingID(): string {
      if (!this.entry.affectedRecords) {
        return '';
      }
      return _.first(this.entry.affectedRecords.hearingIDs) || '';
    },
    hearing(): Hearing | null {
      if (!this.hearingID) {
        return null;
      }
      return this.hearingWithID(this.hearingID);
    },
  },
});
