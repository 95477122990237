





























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { CrimeReport } from '@/vuex/investigation/crimeReport';
import { Dossier } from '@/vuex/court/dossier';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import CrimeReportDisplay from './CrimeReportDisplay.vue';
import DetectionDocket from '@/components/docket/DetectionDocket.vue';
import SubjectPropertiesSummary from '@/components/subjectProperty/SubjectPropertiesSummary.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  created(): void {
    this.fetchData();
  },
  watch: {
    id(val: number): void {
      if (this.id) {
        this.fetchData();
      }
    },
  },
  computed: {
    ...mapGetters('investigation', ['crimeReportWithID', 'fullDepartmentName']),
    ...mapGetters('court', ['dossierWithID', 'subjectPropertyForDossier']),
    crimeReport(): CrimeReport {
      return this.crimeReportWithID(this.id);
    },
    dossierID(): number {
      if (this.crimeReport) {
        return this.crimeReport.dossierID;
      }
      return 0;
    },
    dossier(): Dossier | null {
      if (this.dossierID) {
        return this.dossierWithID(this.dossierID);
      }
      return null;
    },
    subjectProperties(): SubjectProperty[] {
      if (this.dossierID) {
        return this.subjectPropertyForDossier(this.dossierID);
      }
      return [];
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      try {
        // Fetch the Crime Report
        await this.$store.dispatch('investigation/fetchCrimeReport', this.id);
        if (this.dossierID) {
          await this.$store.dispatch('court/fetchDossier', this.dossierID);
          await this.$store.dispatch('court/fetchSubjectPropertiesForDossier', {
            dossierID: this.dossierID,
          });
        }
      } catch (e) {
        if (e.message.match(/404|500/)) {
          this.$store.dispatch(
            `application/showSnackbarError`,
            `${e.name}: ${e.message}. Cant find Crime Report #${this.id}`,
          );
        }
      }
    },
  },
  components: {
    CrimeReportDisplay,
    DetectionDocket,
    SubjectPropertiesSummary,
  },
});
