









































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';

import NewDossierDialog from '@/components/dossier/NewDossierDialog.vue';

export default Vue.extend({
  props: {
    active: Boolean,
  },
  data() {
    return {
      dossierDialogOpen: false,
      dossierDialogTitle: '',
      dossierCaseType: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'entityIDsWithPermission']),

    investigationDashboardRoutes(): any {
      const routeTemplate = { name: 'investigationDashboard' };
      const entityIDs = this.entityIDsWithPermission('dashboard.investigation');

      // Produce a Location/Route object for each permitted entity
      return _.map(entityIDs, (id: number | string) => {
        if (id === '*') {
          return Object.assign({}, routeTemplate, {
            // The root InvestigationDashboard route. The null lastDepartmentID is
            // required to override a param which might have been added
            // separately
            query: { lastDepartmentID: null },
          });
        }
        return Object.assign({}, routeTemplate, {
          query: { lastDepartmentID: id },
        });
      });
    },
  },
  methods: {
    openDossierDialog(mode: string = ''): void {
      switch (mode) {
        case 'huquq': {
          this.dossierCaseType = 'civil';
          this.dossierDialogTitle = '+ ' + i18n.t('huquqPetition.singular');
          break;
        }
        case 'criminal': {
          this.dossierCaseType = 'criminal';
          this.dossierDialogTitle = '+ ' + i18n.t('investigation.singular');
          break;
        }
        case 'state': {
          this.dossierCaseType = 'state';
          this.dossierDialogTitle = '+ ' + ' State Case';
          break;
        }
        default: {
          this.dossierDialogTitle = '';
          this.dossierCaseType = '';
          break;
        }
      }
      this.dossierDialogOpen = true;
    },
  },
  components: {
    NewDossierDialog,
  },
});
