import _ from 'lodash';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { TranslatedString } from '@/lib/translated';

export interface Flag {
  id: number;
  name: TranslatedString;
  icon: string;
  notePrompt: TranslatedString;
  noteRequired: boolean;
  showOnDetailPage: boolean;
  showInTables: boolean;
  isSystemFlag: boolean;
}
export interface LookupItem {
  id: number;
  name: TranslatedString;
  rank: number;
}

export const enum AdjustmentReasonID {
  Intake = 1,
  PardonDecree = 2,
  DetentionExtension = 3,
  CourtDecision = 4,
  ClericalError = 5,
  PostponedEnforcement = 6,
  ConvictionOfAnotherCrime = 10,
}

export const state = {
  optionsLoaded: false,

  courses: [],
  criminalGroups: [] as LookupItem[],
  criminalGroupRoles: [] as LookupItem[],
  custodyStatuses: [],
  disabilities: [] as LookupItem[],
  drugAddictions: [] as LookupItem[],
  flags: [],
  healthConditions: [] as LookupItem[],
  infectiousDiseases: [] as LookupItem[],
  incidentTypes: [] as LookupItem[],
  leaveReasons: [] as LookupItem[],
  mentalDisorders: [] as LookupItem[],
  motherAndChildConcerns: [],
  privileges: [],
  releaseReasons: [] as LookupItem[],
  securityLevels: [],
  sentenceAdjustmentReasons: [] as LookupItem[],
  transferReasons: [] as LookupItem[],
  treatmentTypes: [] as LookupItem[],
  visitTypes: [] as LookupItem[],
};

export const getters = {
  /**
   * courseWithID is a convenience getter to get a Course LookupItem
   * from its ID. The facility must already be loaded in the Vuex state.
   */
  courseWithID:
    (state) =>
    (id): LookupItem => {
      return _.find(state.courses, { id });
    },

  /**
   * editableFlags returns the set of known Flags which are end-user
   * editable (those that are not "system" flags).
   */
  editableFlags(state): Flag[] {
    return state.flags.filter((flag) => !flag.isSystemFlag);
  },
};

export const mutations = {
  /**
   * setOptionsLoaded sets the tracking variable which identifies
   * whether the options have been successfully fetched from the
   * API or not.
   */
  setOptionsLoaded(state, isLoaded) {
    state.optionsLoaded = isLoaded;
  },
};

export const actions = {
  /**
   * fetchOptions fetches all dropdown choices for entities in this Vuex
   * store from the API.
   *
   * Most of the time it should be called as fetchOptions({ force: false }),
   * to avoid re-fetching options from the API that have already been
   * loaded.
   */
  async fetchOptions(
    { state, commit },
    payload = {} as { force: boolean },
  ): Promise<boolean> {
    // Early return when the options are already loaded
    if (!payload.force && state.optionsLoaded) {
      return Promise.resolve(true);
    }

    // Fetch all offender-related options from the API
    const response = await offenderAPI.get('/options');
    if (response.data && response.data.data) {
      const newState = response.data.data;
      // Set each option array in the state. This will throw
      // an error if the server sends any top-level
      // properties which are not initialized to their
      // initial state (typically an empty array) above in
      // the state definition.
      commit('setState', newState);

      // Set loaded
      commit('setOptionsLoaded', true);
      return Promise.resolve(true);
    }

    // We reach here only in case of failure
    return Promise.reject();
  },
};
