
















import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { Party } from '@/vuex/court/party';

import ChargesSummary from '@/components/charges/ChargesSummary.vue';
import { Charge } from '@/vuex/court/charge';
export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('court', ['partyTypes']),
    ...mapGetters('court', [
      'fullPartyName',
      'partiesForDossier',
      'partyWithID',
      'dossierWithID',
    ]),
    payload(): any {
      return this.entry.payload;
    },
    charges(): Charge[] {
      return this.payload.charges as Charge[];
    },
    party(): Party {
      return this.partyWithID(this.payload.partyID);
    },
  },
  components: {
    ChargesSummary,
  },
});
