



import Vue from 'vue';
import i18n from '@/i18n';

export default Vue.extend({
  props: {
    value: Number,
  },
  computed: {
    /**
     * formatWeight converts the value prop from milligrams to other weight
     * depending on factoring
     */
    formatWeight(): string {
      if (!this.value) {
        return '';
      }

      if (this.value % 4529848320 === 0) {
        const strVal = (this.value / 4529848320).toLocaleString();
        return `${strVal} ${i18n.t('weight.kharwaar')}`;
      }

      if (this.value % 56623104 === 0) {
        const strVal = (this.value / 56623104).toLocaleString();
        return `${strVal} ${i18n.t('weight.man')}`;
      }

      if (this.value % 7077888 === 0) {
        const strVal = (this.value / 7077888).toLocaleString();
        return `${strVal} ${i18n.t('weight.sayr')}`;
      }

      if (this.value % 1769472 === 0) {
        const strVal = (this.value / 1769472).toLocaleString();
        return `${strVal} ${i18n.t('weight.chaarak')}`;
      }

      if (this.value % 442368 === 0) {
        const strVal = (this.value / 442368).toLocaleString();
        return `${strVal} ${i18n.t('weight.paaw')}`;
      }

      if (this.value % 110592 === 0) {
        const strVal = (this.value / 110592).toLocaleString();
        return `${strVal} ${i18n.t('weight.khurd')}`;
      }

      if (this.value % 18432 === 0) {
        const strVal = (this.value / 18432).toLocaleString();
        return `${strVal} ${i18n.t('weight.toolee')}`;
      }

      if (this.value % 4608 === 0) {
        const strVal = (this.value / 4608).toLocaleString();
        return `${strVal} ${i18n.t('weight.misqaal')}`;
      }

      if (this.value % 1000 === 0) {
        const strVal = (this.value / 1000).toLocaleString();
        return `${strVal} ${i18n.t('weight.gram')}`;
      }

      if (this.value % 192 === 0) {
        const strVal = (this.value / 192).toLocaleString();
        return `${strVal} ${i18n.t('weight.nakhud')}`;
      }

      const strVal = this.value.toLocaleString();
      return `${strVal} ${i18n.t('weight.milligrams')}`;
    },
  },
});
