




import Vue from 'vue';

export default Vue.extend({
  props: {
    value: Number,
  },
  computed: {
    formattedNumber(): string {
      return new Intl.NumberFormat().format(this.value);
    },
  },
});
