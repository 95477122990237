











import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Case } from '@/vuex/court/case';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    dossierID: Number,
    label: String,
    disabled: Boolean,
    clearable: Boolean,
    rules: Array,
  },
  computed: {
    ...mapGetters('court', ['casesForDossier', 'courtName']),

    /**
     * cases computes the raw list of Case objects which are associated with
     * the dossierID prop. This forms the basis for the items in the VSelect.
     */
    cases(): Case[] {
      return this.casesForDossier(this.dossierID) || [];
    },

    /**
     * items converts the array of Case into an array of VSelectItems that
     * VSelect expects.
     */
    items(): VSelectItem[] {
      return _.map(this.cases, (kase) => ({
        value: kase.id,
        text: `${kase.number} : ${this.courtName(kase.courtID)}`,
      }));
    },
  },
  watch: {
    /**
     * dossierID is watched so that the Dossier can be fetched in case that
     * Dossier's list of Cases is not available.
     */
    dossierID: {
      immediate: true,
      handler(newDossierID: number): void {
        if (newDossierID) {
          this.fetchData();
        }
      },
    },
  },
  methods: {
    /**
     * fetchData performs an API call to update the Vuex state with the Dossier
     * data (including its Cases) for the Dossier referenced by the dossierID
     * prop.
     */
    async fetchData(): Promise<void> {
      await this.$store.dispatch('court/fetchDossier', this.dossierID);
    },
  },
});
