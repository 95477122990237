



















































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import translationAPI from '@/vuex/language/translationAPI';
import { blankTranslatedString } from '@/lib/translated';

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      error: null as any,
      valid: true,

      key: '',
      message: blankTranslatedString(),
      lastVerifiedByUserID: 0,
      lastVerifiedAt: null as null | string,
      similar: [] as string[],
    };
  },
  computed: {
    ...mapGetters('language', [
      'languages',
      'localeMessages',
      'localeMessageKeys',
    ]),

    /**
     * haveValidKey provides a mechanism to delay data entry and saving
     * until a valid key has been provided.
     */
    haveValidKey(): boolean {
      return this.requireValidKey(this.key) === true;
    },

    /**
     * similarKeys computes an array of keys which share a prefix with the
     * user's entry. This is a building block for validators and is also
     * shown to the user below their entry.
     */
    similarKeys(): string[] {
      if (this.key.length < 2) {
        return [];
      }

      const matching = _.filter(this.localeMessageKeys, (k) =>
        k.startsWith(this.key),
      );
      matching.sort();
      return _.take(matching, 12);
    },
  },

  methods: {
    /**
     * reset is triggered when the form first opens. It's responsible for
     * clearing all validation errors and resetting the form back to its
     * empty state.
     */
    reset(): void {
      this.valid = true;
      this.key = '';
      this.message = { en: '', fa: '', ps: '' };
      this.lastVerifiedByUserID = 0;
      this.lastVerifiedAt = null;
    },

    /**
     * save is the handler for the save button's click event. We use it to
     * persist data to the server and close the dialog if that was successful.
     */
    async save(): Promise<void> {
      this.error = null;
      try {
        const res = await translationAPI.post('locale-messages/update', {
          data: {
            key: this.key,
            message: this.message,
            lastVerifiedByUserID: this.lastVerifiedByUserID,
            lastVerifiedAt: this.lastVerifiedAt,
          },
        });
        const newState = _.get(res, 'data.data', {});
        this.$store.commit('language/setState', newState);
        this.$store.commit('language/refreshBrowserLocaleMessages');

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },

    /**
     * requireValidKey is a Vuetify rules validator function. It takes the
     * current vale in a text field as input and returns either true (if the
     * the input is considered valid), or an error message describing what was
     * invalid about the input.
     */
    requireValidKey(newKey: string): boolean | string {
      newKey = newKey || ''; // Ensure we start with a string instead of null/undefi
      if (newKey.length < 2) {
        return 'Too short';
      }
      if (newKey.indexOf(' ') >= 0) {
        return 'Cannot contain spaces';
      }
      if (_.includes(this.localeMessageKeys, newKey)) {
        return 'Key already exists';
      }
      if (/\.$/.test(newKey)) {
        return 'Key cannot end with a "." character';
      }
      const matching = _.filter(this.localeMessageKeys, (existingKey) => {
        const existingKeyAsParent = `${existingKey}.`;
        const newKeyAsParent = `${newKey}.`;
        if (existingKey.startsWith(newKeyAsParent)) {
          return true;
        }
        if (newKey.startsWith(existingKeyAsParent)) {
          return true;
        }
      }).sort();
      if (matching.length > 0) {
        return `Key conflicts with: ${matching.join(', ')}`;
      }
      return true;
    },
  },
});
