var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.canAdd)?_c('CourtAddDialog'):_vm._e(),_c('v-spacer'),_c('v-switch',{attrs:{"label":"Grouped"},model:{value:(_vm.grouped),callback:function ($$v) {_vm.grouped=$$v},expression:"grouped"}})]},proxy:true}])},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('court.plural')))]),_c('v-spacer'),(!_vm.grouped)?_c('v-text-field',{attrs:{"label":_vm.$t('search'),"append-icon":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}):_vm._e()],1),_c('v-card-text',[(_vm.grouped)?_c('CourtContainer',{staticClass:"mt-8",attrs:{"parentID":0}}):_vm._e(),(!_vm.grouped)?_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.translatedCourts,"search":_vm.search,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [10, 20, 50, 100] },"no-data-text":_vm.$t('error.nodata')},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('td',[(_vm.canAdd)?_c('CourtEditDialog',{attrs:{"id":item.id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":""}},on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("fa-edit")]),_vm._v(" "+_vm._s(item.id)+" ")],1)]}}],null,true)}):_vm._e()],1)]}},{key:"item.dashboard",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":value}},[_c('v-icon',{attrs:{"small":"","left":"","color":"secondary"}},[_vm._v("fa-chart-bar")]),_vm._v(" "+_vm._s(_vm.$t('dashboard.singular'))+" ")],1)]}},{key:"item.courtrooms",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":value}},[_c('v-icon',{attrs:{"small":"","left":"","color":"secondary"}},[_vm._v("fa-list")]),_vm._v(" "+_vm._s(_vm.$t('court.courtroom.plural'))+" ")],1)]}}],null,true)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }