






















import _ from 'lodash';
import Vue from 'vue';

import PartySearchSelector from '@/components/court/party/PartySearchSelector.vue';

export default Vue.extend({
  data() {
    return {
      isOpen: false,

      partyID: 0,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.partyID = 0;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use to to notify other components which partyID was selected.
     */
    save(): void {
      this.$emit('save', this.partyID);
      this.isOpen = false;
    },
  },
  components: {
    PartySearchSelector,
  },
});
