





























































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { LookupTableRow } from '@/vuex/language/language';
import translationAPI from '@/vuex/language/translationAPI';

export default Vue.extend({
  props: {
    table: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },

  data(): any {
    return {
      isOpen: false,
      error: null as any,

      internalRow: null as LookupTableRow | null,
    };
  },

  computed: {
    ...mapGetters('language', ['languages']),

    /**
     * showRank computes whether a field should be shown to allow editing the
     * rank property based on whether the selected table supports it.
     */
    showRank(): boolean {
      return this.table && this.table.hasRank;
    },

    /**
     * showType computes whether a field shold be shown to allow ediitng the
     * type property based on whether the selected table supports it.
     */
    showType(): boolean {
      return this.table && this.table.hasType;
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state, which
     * in this case is to reset the internalRow variable to a deep copy of the
     * provided row prop.
     */
    reset(): void {
      if (this.row) {
        this.internalRow = _.cloneDeep(this.row) as LookupTableRow;
      }
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        await translationAPI.post('lookup-table-rows/edit', {
          data: {
            table: this.internalRow.table,
            id: this.internalRow.id,
            type: this.internalRow.type,
            name: this.internalRow.name,
            rank: this.internalRow.rank,
          },
        });
        this.$emit('saveCompleted');
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
});
