import { render, staticRenderFns } from "./OffendersByLocationTable.vue?vue&type=template&id=801ec5a0&"
import script from "./OffendersByLocationTable.vue?vue&type=script&lang=ts&"
export * from "./OffendersByLocationTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VDataTable,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VProgressLinear})
