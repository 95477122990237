
























































































































































































import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Case } from '@/vuex/court/case';
import { CaseTopicID, LookupItem } from '@/vuex/court/options';
import { Court } from '@/vuex/court/court';
import { Dossier } from '@/vuex/court/dossier';
import { Participant } from '@/vuex/court/participant';
import { PartyTypeID } from '@/vuex/court/party';

import CaseLookupField from '@/components/court/case/CaseLookupField.vue';
import CaseNumberField from '@/components/court/case/CaseNumberField.vue';
import CaseTypeSelector from '@/components/court/CaseTypeSelector.vue';
import CourtSelector from '@/components/court/court/CourtSelector.vue';
import CourtStageSelector from '@/components/court/CourtStageSelector.vue';
import DossierLookupField from '@/components/dossier/DossierLookupField.vue';
import JudgeSelector from '@/components/court/judge/JudgeSelector.vue';
import ParticipantsForm from '@/components/court/participant/ParticipantsForm.vue';

export default Vue.extend({
  props: {
    value: Boolean,
    forceStageID: Number,
    dossierID: Number,
    noActivator: Boolean || false,
  },

  created() {
    this.caseDossierID = this.dossierID;
  },

  data() {
    return {
      isOpen: false,
      step: 1,
      error: null as any,

      caseID: 0,
      caseDossierID: 0,
      newDossierNumber: '',
      lawsuitTypeIDs: [] as number[],
      caseType: 'civil', // Can be 'civil', 'state', or 'criminal'
      topicID: 0,
      courtID: 0,
      priorCourtID: 0,
      caseNumber: '',
      filedAt: '',
      participants: [] as Participant[],
      stageID: this.forceStageID,
    };
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('court', [
      'caseWithID',
      'courtWithID',
      'dossierWithID',
      'participantsForDossier',
      'participantsForCase',
    ]),
    ...mapState('court', ['civilCaseTopics']),

    caseTopics(): LookupItem[] {
      if (this.caseType === 'state') {
        return this.civilCaseTopics.filter((t) => t.id !== CaseTopicID.Family);
      }
      return this.civilCaseTopics;
    },

    court(): Court {
      return this.courtWithID(this.courtID);
    },

    headOfCourtJudgeID(): number {
      return this.court ? this.court.headOfCourtJudgeID : 0;
    },

    kase(): Case {
      return this.caseWithID(this.caseID);
    },

    dossier(): Dossier {
      return this.dossierWithID(this.caseDossierID);
    },

    /**
     * stageChoices returns the list of currently valid choices for the stage
     * selection interface.
     */
    stageChoices(): number[] {
      // If we're being externally forced to a particular stage, then
      // make that the only option.
      if (this.forceStageID) {
        return [this.forceStageID];
      }

      // Otherwise the stageChoices are derived from the stages which
      // are valid in the selected court
      if (this.court) {
        return this.court.stageIDs;
      }

      // Finally, if we are not forced to a particular stage and
      // we don't have a court, then there is no known list of
      // valid stages, so just return blank.
      return [];
    },
  },

  watch: {
    /**
     * value is watched so that the externally-bound v-model can be transferred
     * to its internal equivalent (isOpen).
     */
    value(val) {
      this.isOpen = val;
    },

    /**
     * isOpen is watched so that changes in the internal isOpen value can be
     * broadcast to the external v-model which to which this component is
     * bound.
     */
    isOpen(val) {
      this.$emit('input', val);
    },

    /**
     * stageChoices is watched to the stage variable
     * remains valid whenever the list of valid stageChoices is changed.
     * It will auto-select the first valid stage when there are valid
     * choices and reset to an empty value otherwise.
     */
    stageChoices: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.stageID = newVal[0];
        }
      },
    },
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.step = 1;

      this.caseID = 0;
      this.priorCourtID = 0;
      this.caseNumber = '';
      this.filedAt = moment().format();
      this.stageID = this.forceStageID;
      if (this.dossierID) {
        this.caseDossierID = this.dossierID;
        this.copyFromDossier();
      } else {
        this.caseDossierID = 0;
      }
      this.lawsuitTypeIDs = [];
    },

    /**
     * copyFromDossier fetches Dossier associated with caseDossierID, and then
     * pre-populates the case form with data from the matching dossier.
     *
     * This behavior is triggered in two scenarios:
     *
     * 1. Automatically, when the dialog is opened with a dossierID prop set
     *    externally (e.g. from the Dossier Courts page).
     * 2. Manually, when the user clicks the copy/import button after
     *    looking up a dossier by number.
     */
    async copyFromDossier(): Promise<void> {
      await this.$store.dispatch('court/fetchDossier', this.caseDossierID);

      this.newDossierNumber = this.dossier.number;
      this.lawsuitTypeIDs = this.dossier.lawsuitTypeIDs;
      this.topicID = this.dossier.topicID;
      this.caseType = this.dossier.type;

      const participants = this.participantsForDossier(this.caseDossierID);
      this.participants = [...participants];
      this.step = 2;
    },

    /**
     * copyFromCase fetches the case associated with caseID, and then
     * pre-populates the new case with data from the matching case.
     *
     * This behavior is triggered only from the copy/import button after looking
     * up a case by court + case number.
     */
    async copyFromCase(): Promise<void> {
      await this.$store.dispatch('court/fetchCase', this.caseID);

      this.caseDossierID = this.kase.dossierID;
      if (this.dossier) {
        this.newDossierNumber = this.dossier.number;
        this.lawsuitTypeIDs = this.dossier.lawsuitTypeIDs;
        this.topicID = this.dossier.topicID;
        this.caseType = this.dossier.type;
      }

      const participants = this.participantsForCase(this.caseID);
      this.participants = [...participants];

      this.step = 2;
    },

    /**
     * startBlank() sets up the new case dialog to build both a dossier and
     * a case simultaneously. It is triggered only from the "Start with Blank
     * Case" button.
     */
    startBlank(): void {
      this.lawsuitTypeIDs = [];

      // We assume that a blank case initiated directly with the court rather
      // than via the Huquq or AGO are most likely civil in nature.
      this.caseType = 'civil';
      this.topicID = 1;
      this.resetParticipants();

      this.step = 2;
    },

    /**
     * caseTypeChanged is the event handler for the user manually changing
     * the case type dropdown. When they do this, we need to reset various
     * other fields which depent on the case type.
     */
    caseTypeChanged(): void {
      if (this.caseType === 'criminal') {
        this.topicID = 0;
      }
      if (this.caseType === 'civil') {
        this.topicID = 1; // Property
      }
      if (this.caseType === 'state') {
        this.topicID = 4; // Administrative
      }
      this.resetParticipants();
    },

    /**
     * resetParticipants re-creates the array with empty parties that
     * are appropriate to the case type (e.g. petitioner and respondent for
     * civil)
     */
    resetParticipants() {
      let typeIDs = [] as number[];

      if (this.caseType === 'criminal') {
        typeIDs = [PartyTypeID.Defendant];
      }
      if (this.caseType === 'civil') {
        typeIDs = [PartyTypeID.Plaintiff, PartyTypeID.Respondent];
      }
      if (this.caseType === 'state') {
        typeIDs = [PartyTypeID.Petitioner, PartyTypeID.Respondent];
      }

      this.participants = typeIDs.map((typeID) => {
        return {
          partyID: 0,
          partyTypeID: typeID,
          originalPartyTypeID: typeID,
        };
      });
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      this.error = null;

      try {
        const response = await courtAPI.post('cases/create', {
          data: {
            caseType: this.caseType,
            dossierNumber: this.newDossierNumber,
            lawsuitTypeIDs: this.lawsuitTypeIDs,
            topicID: this.topicID,
            dossierID: this.caseDossierID,
            courtID: this.courtID,
            headOfCourtJudgeID: this.headOfCourtJudgeID,
            stageID: this.stageID,
            filedAt: this.filedAt,
            number: this.caseNumber,
            participants: this.participants,
          },
        });

        // Apply the server state to the appropriate Vuex store
        const newState = response.data.data;
        const newID = Object.keys(newState.case)[0];
        this.$store.commit('court/setState', newState);

        // Close the dialog
        this.isOpen = false;

        // Redirect to the page where the newly-created case appears
        this.$router.push({
          name: 'caseShow',
          params: { id: newID },
        });
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    CaseLookupField,
    CaseNumberField,
    CaseTypeSelector,
    CourtSelector,
    CourtStageSelector,
    DossierLookupField,
    JudgeSelector,
    ParticipantsForm,
  },
});
