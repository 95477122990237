import _ from 'lodash';
import { domainmodelMutations } from '@/lib/vuex-domainmodel';
import profileAPI from './profileAPI';

export interface Profile {
  id: number;
  partyID: number;
  name: string;
  surname: string;
  fatherName: string;
  grandfatherName: string;
  birthplaceGeographyID: number;
  residenceGeographyID: number;
  nationalityID: number;
  birthdate: string;
  birthdateIsApproximate: boolean;
  heightInCm: number;
  weightInKg: number;
  hairColorID: number;
  eyeColorID: number;
  genderID: number;
  bloodType: string;
  maritalStatusID: number;
  numberOfChildren: number;
  nativeLanguageID: number;
  secondLanguageID: number;
  nationalID: string;
  eNationalID: string;
  passportNumber: string;
  isMilitary: boolean;
  militaryRankID: number;
  occupation: string;
  description: string;
  identifyingMarks: string;
  canRead: boolean;
  canWrite: boolean;
  educationLevelID: number;
  familyContact: string;
  visitors: string;
  aliases: [];
  phoneNumber: string;
  emailAddress: string;
  isKochi: boolean;
  criminalGroupIDs: [];
  criminalGroupRoleID: number;
  infectiousDiseaseIDs: number[];
  mentalDisorderIDs: number[];
  motherChildConcernIDs: number[];
}

const state = {
  profile: {} as { [id: number]: Profile },

  optionsLoaded: false,
  bloodTypes: [],
  educationLevels: [],
  eyeColors: [],
  genders: [],
  hairColors: [],
  languages: [],
  maritalStatuses: [],
  militaryRanks: [],
  nationalities: [],
};

const getters = {
  profileWithID:
    (state) =>
    (id): Profile => {
      return state.profile[id];
    },
  profileName:
    (state) =>
    (id: number): string => {
      const profile = state.profile[id];
      if (profile) {
        return `${profile.name} ${profile.surname}`;
      }
      return `Unknown Profile: ${id}`;
    },
};

const mutations = {
  ...domainmodelMutations, // provides setState, appendToTarget, etc
  /**
   * setOptionsLoaded set ths tracking variable which identifies
   * whether the options have been successfully fetched from the
   * API or not.
   */
  setOptionsLoaded(state, isLoaded): void {
    state.optionsLoaded = isLoaded;
  },
};

const actions = {
  /**
   * fetchOptions fetches all dropdown choices for entities in this
   * Vuex store from the API.
   */
  async fetchOptions(
    { state, commit },
    payload = {} as { force: boolean },
  ): Promise<boolean> {
    // Early return when the options are already loaded
    if (!payload.force && state.optionsLoaded) {
      return Promise.resolve(true);
    }

    // Fetch all profile-related options from the API
    const response = await profileAPI.get(`/options`);
    if (response.data && response.data.data) {
      const newState = response.data.data;

      // Set each option array in the state. This will
      // throw an error if the server sends any top-level
      // properties which are not initialized to their
      // initial state (typically an empty array) above in
      // the state definition.
      commit('setState', newState);

      // Set loaded
      commit('setOptionsLoaded', true);

      return Promise.resolve(true);
    }

    // We reach here only in case of API retrieval failure
    return Promise.reject();
  },

  /**
   * fetchProfile retrieves Criminal Profile entities from the server and
   * injects them into the local Vuex store. It accepts a single profile ID
   * or an array of IDs.
   */
  async fetchProfile({ commit }, id: number | number[]): Promise<void> {
    const idsStr = _.flatten([id]).join(',');
    if (idsStr) {
      const response = await profileAPI.get(`profiles/batch?ids=${idsStr}`);
      const newState = response.data.data;
      commit('setState', newState);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
