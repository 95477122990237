import moment from 'moment';
import _ from 'lodash';
import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface Judge {
  id: number;
  name: string;
  surname: string;
  fatherName: string;
  grandfatherName: string;
  phoneNumber: string;
  emailAddress: string;
  isDeleted: boolean;
  jurisdictions: Jurisdiction[];
}

export interface Jurisdiction {
  id: number;
  judgeID: number;
  startsAt: string | null;
  endsAt: string | null;
  residenceGeographyID: number;
  stageIDs: number[];
}

export const state = {
  judgeIDs: [],
  judge: {} as { [id: number]: Judge },
  judgesLoaded: false,
  jurisdiction: {} as Jurisdiction,
};

export const getters = {
  judgeWithID:
    (state) =>
    (id: number): Judge | null => {
      return state.judge[id] || null;
    },
  allJudges(state): Judge[] {
    return _.map(state.judgeIDs, (id: number) => state.judge[id]);
  },
  judgeName:
    (state, getters) =>
    (id: number): string => {
      const judge = getters.judgeWithID(id);
      if (judge !== null) {
        return `${judge.name} ${judge.surname}`;
      } else {
        return '';
      }
    },
  fullJudgeName:
    (state, getters) =>
    (id: number): string => {
      const judge = getters.judgeWithID(id);
      if (judge !== null) {
        return `${judge.name} ${judge.surname} ${judge.fatherName} ${judge.grandfatherName}`;
      } else {
        return '';
      }
    },
  activeJurisdictionsForJudge:
    (state, getters) =>
    (id: number): Jurisdiction[] => {
      const judge = getters.judgeWithID(id);
      if (!judge) {
        return [];
      }
      return (judge.jurisdictions || []).filter(
        (jurisdiction: Jurisdiction) => {
          const validStart =
            jurisdiction.startsAt === null ||
            jurisdiction.startsAt < moment().format('YYYY-MM-DD');
          const validEnd =
            jurisdiction.endsAt === null ||
            jurisdiction.endsAt > moment().format('YYYY-MM-DD');
          return validStart && validEnd;
        },
      );
    },
};

export const mutations = {
  /**
   * setJudgesLoaded sets the tracking variable which identifies
   * whether the judges have been successfully fetched from the
   * API or not.
   */
  setJudgesLoaded(state, val: boolean) {
    state.judgesLoaded = val;
  },
};

export const actions = {
  /**
   * fetchJudges fetches all dropdown choices for entities in this Vuex
   * store from the API.
   *
   * Most of the time it should be called as fetchJudges({ force: false }),
   * to avoid re-fetching judges from the API that have already been
   * loaded.
   */
  async fetchJudges(
    { commit, state },
    payload = {} as { force: boolean },
  ): Promise<void> {
    // Early return when the judges are already loaded
    if (!payload.force && state.judgesLoaded) {
      return Promise.resolve();
    }

    // Fetch all offender-related judges from the API
    const response = await courtAPI.get('/judges');
    const judges = response.data.data;
    const newState = modelsToState('judge', judges);
    commit('setState', newState);
    commit('setTarget', {
      target: 'judgeIDs',
      value: _.map(judges, 'id'),
    });
    commit('setJudgesLoaded', true);
  },

  // fetchJudge doesn't have a promise like fetchJudges.  Should it?  How?
  async fetchJudge({ commit }, id: number): Promise<void> {
    const response = await courtAPI.get(`/judges/${id}`);
    const judge = response.data.data;

    const newState = modelsToState('judge', judge);
    commit('setState', newState);
    commit('setTarget', {
      target: 'judge',
      index: judge.id,
      value: judge,
    });
  },
};
