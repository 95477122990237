























import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import { Dossier, DOSSIER_NUMBER_LENGTH } from '@/vuex/court/dossier';

export default Vue.extend({
  props: {
    required: Boolean,
    value: Number,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      failedSearch: false,
      dossierID: 0,
      dossierNumber: '',
    };
  },
  computed: {
    ...mapGetters('court', ['dossierWithID']),
    dossier(): Dossier {
      return this.dossierWithID(this.dossierID);
    },

    /**
     * computedRules adds a special rule to consider the field invalid if a
     * real Dossier # has not been entered (i.e. if dossierID == 0).
     */
    computedRules(): any[] {
      const rules: any[] = this.rules || [];
      if (this.required && this.dossierID <= 0) {
        return [(v) => i18n.t('error.required')];
      }
      return rules;
    },
  },
  watch: {
    value(newVal) {
      // Reset internal data if the ID is zeroed from outside.
      if (!newVal) {
        this.dossierID = 0;
        if (this.dossierNumber.length === DOSSIER_NUMBER_LENGTH) {
          // Clear the dossier number
          this.dossierNumber = '';
        }
      } else {
        /**
         * When value changes externally to a dossierID that's different from
         * what we have internally, it means that some other process changed it
         * to a (presumably valid) Dossier's ID. When that happens, we need to
         * clear the dossierNumber search field, reset our internal dossierID,
         * and eliminate the unsuccessful search indicator.
         */
        if (newVal !== this.dossierID) {
          this.dossierNumber = '';
          this.dossierID = newVal;
          this.failedSearch = false;
        }
      }
    },
    dossierNumber(newVal) {
      if (newVal.length === DOSSIER_NUMBER_LENGTH) {
        this.findDossier();
      } else {
        this.$emit('input', 0);
      }
    },
  },
  methods: {
    async findDossier(): Promise<void> {
      this.loading = true;
      this.failedSearch = false;

      try {
        const url = `/dossiers/search?number=${this.dossierNumber}`;
        const response = await courtAPI.get(url);

        // Only single-result exact matches are valid
        if (response.data.meta.totalResults === 1) {
          const result = response.data.data[0];
          this.dossierID = result.id;

          // Make sure other data gets loaded
          await this.$store.dispatch('court/fetchDossier', this.dossierID);
          this.$emit('input', this.dossierID);
        } else {
          this.failedSearch = true;
          this.$emit('input', 0);
        }
      } catch (err) {
        this.$store.dispatch('application/showSnackbarError', err);
      }

      this.loading = false;
    },
  },
});
