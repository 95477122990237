






























import { cloneDeep, tap, set } from 'lodash';
import Vue from 'vue';

import { PartyAttorney } from '@/vuex/court/participant';

import AttorneySelector from '@/components/court/attorney/AttorneySelector.vue';

export default Vue.extend({
  props: {
    value: Object,
  },

  computed: {
    /**
     * partyAttorney computes the current state of the object being edited on this
     * form. If an Object was supplied to the `value` prop, then that is
     * returned unmodified. If the incoming `value` is null, then we compute
     * an initial (blank) object for the form.
     *
     * This function should return an object which fulfils the entire type
     * specification without an "as Thing" explicit type assertion.
     *
     * This property should be treated as readonly. Modifications should be made
     * by calling the update() function, which will apply the modification to a
     * copy of the entity and $emit it. Two-way data binding will then cause
     * this component's `value` prop to hold the new, modified entity.
     */
    partyAttorney(): PartyAttorney {
      const v = this.value
        ? this.value
        : {
            attorneyID: 0,
            attorneyTypeID: 1,
            isCourtAssigned: false,
          };
      return v;
    },
  },

  methods: {
    /**
     * update is the handler for the @input event of form fields on this form.
     * Instead of directly updating an object property, any field change
     * triggers the entire object to be re-built and $emitted to the consumer,
     * ensuring the state displayed on the form and the local data object
     * remain consistent.
     *
     * This method is critical for proper Vuex reactivity
     * of complex objects being edited in forms.
     */
    update(key: string, value: any): void {
      this.$emit(
        'input',
        tap(cloneDeep(this.partyAttorney), (v) => set(v, key, value)),
      );
    },
  },

  components: {
    AttorneySelector,
  },
});
