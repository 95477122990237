





























































import _ from 'lodash';
import Vue from 'vue';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';

import translationAPI from '@/vuex/language/translationAPI';
import { LookupTable, LookupTableRow } from '@/vuex/language/language';

import EditLookupTableRowDialog from '@/components/translation/EditLookupTableRowDialog.vue';
import NewLookupTableRowDialog from '@/components/translation/NewLookupTableRowDialog.vue';

export default Vue.extend({
  created(): void {
    this.fetchTableList();
  },

  data(): any {
    return {
      /**
       * selectedTableName is initialized to a blank value. It will be set to
       * the first table in the list of tables after that list is fetched.
       */
      selectedTableName: '',
      tables: [] as LookupTable[],
      rows: [] as LookupTableRow[],
      search: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    /**
     * selectedTable returns the entire LookupTable object which is currently
     * selected via the dropdown.
     */
    selectedTable(): LookupTable | null {
      return _.find(this.tables, { name: this.selectedTableName }) || null;
    },

    /**
     * allowAdding computes whether the Add button should be displayed. It can
     * be hidden when the current user doesn't have the permission or when the
     * currently selected LookupTable does not allow additions via the U
     * (a setting that is editable in server-side code)
     */
    allowAdding(): boolean {
      return (
        this.hasPermission('admin.lookupTableRow.create') &&
        this.selectedTable &&
        this.selectedTable.allowAdding
      );
    },

    /**
     * showType computes whether the Type column should be shown based on whether
     * the currently selected LookupTable supports it.
     */
    showType(): boolean {
      return this.selectedTable && this.selectedTable.hasType;
    },

    /**
     * showRank computes whether the Rank column should be shown
     * based on whether the currently selected LookupTable supports
     * it.
     */
    showRank(): boolean {
      return this.selectedTable && this.selectedTable.hasRank;
    },

    /**
     * headers computes the array of column header definitions for the
     * v-data-table. It needs to be a computed property so
     * that it will update with new translations when the locale changes.
     */
    headers(): any[] {
      const h: any[] = [
        {
          text: i18n.t('command.edit'),
          value: 'edit',
          align: 'center',
          sortable: false,
        },
        { text: i18n.t('id'), value: 'id', align: 'center' },
      ];

      if (this.showType) {
        h.push({ text: i18n.t('program.type'), value: 'type' });
      }

      h.push({ text: i18n.t('en'), value: 'name.en' });
      h.push({ text: i18n.t('fa'), value: 'name.fa' });
      h.push({ text: i18n.t('ps'), value: 'name.ps' });

      if (this.showRank) {
        h.push({ text: i18n.t('rank.singular'), value: 'rank' });
      }

      return h;
    },

    /**
     * unpaginated returns true if there are relatively few total rows such that
     * it makes sense to just show the entire table with no pagination UI.
     */
    unpaginated(): boolean {
      return this.rows.length < 40;
    },
  },

  watch: {
    selectedTableName() {
      this.fetchRows();
    },
  },

  methods: {
    /**
     * fetchTableList retrieves the list of available LookupTable options from
     * the relevant API endpoint. It should be called only once when this
     * component is created.
     */
    async fetchTableList(): Promise<void> {
      this.rows = [];
      this.tables = [];
      const response = await translationAPI.get('lookup-tables');
      const tables = _.get(response, 'data.data', []);
      this.tables = _.sortBy(tables, 'name');
      if (this.tables && this.tables.length > 0) {
        this.selectedTableName = this.tables[0].name;
      }
    },

    /**
     * fetchRows retrieves the talbe rows for the selected lookup table. It is
     * called any time a new table is selected and any time new rows are added
     * or modified.
     */
    async fetchRows(): Promise<void> {
      this.rows = [];
      const response = await translationAPI.get(
        `lookup-tables/${this.selectedTableName}/rows`,
      );
      const rows: any[] = _.get(response, 'data.data', []);
      this.rows = _.orderBy(rows, ['type', 'rank', 'name']);
    },
  },

  components: {
    EditLookupTableRowDialog,
    NewLookupTableRowDialog,
  },
});
