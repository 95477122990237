


















import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import { VSelectItem } from '@/lib/vue-typescript';
import { DocketEventType } from '@/vuex/court/options';

export default Vue.extend({
  props: {
    /**
     * module allows the user to limit the list of DocketEventType entries
     * in the dropdown to those from a particular module (e.g. 'court',
     * 'investation', or 'detection')
     */
    module: String,

    label: String,
    value: [Number, Array],
    rules: Array,
    disabled: Boolean,
    readonly: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    chips: Boolean,
    smallChips: Boolean,
    deletableChips: Boolean,
  },
  computed: {
    ...mapState('court', ['docketEventTypes']),
    ...mapGetters('language', ['valueForLocale']),

    docketEventChoices(): VSelectItem[] {
      let types: DocketEventType[] = this.docketEventTypes;
      if (this.module) {
        types = _.filter(types, { module: this.module });
      }

      return _.chain(types)
        .map((t: DocketEventType) => ({
          text: this.valueForLocale(t.name),
          value: t.id,
        }))
        .sortBy('text')
        .value();
    },
  },
});
