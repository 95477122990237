







































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    summons: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('court', ['partyName', 'courtroomName']),
  },
});
