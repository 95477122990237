






















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Participant } from '@/vuex/court/participant';
import { LeftPartyTypeIDs, RightPartyTypeIDs } from '@/vuex/court/party';

import ParticipantSummary from './ParticipantSummary.vue';

export default Vue.extend({
  props: {
    dossierID: Number,
    caseID: Number,
    dense: Boolean,
  },

  computed: {
    ...mapGetters('court', [
      'primaryParticipantsForCase',
      'primaryParticipantsForDossier',
    ]),

    /**
     * primaryParticipants returns either the Dossier or Case Participants
     * which are marked as "primary".
     */
    primaryParticipants(): Participant[] {
      if (this.caseID) {
        return this.primaryParticipantsForCase(this.caseID);
      } else {
        return this.primaryParticipantsForDossier(this.dossierID, this.caseID);
      }
    },

    leftParticipants(): Participant[] {
      return _.filter(this.primaryParticipants, (p) => {
        return _.includes(LeftPartyTypeIDs, p.partyTypeID);
      });
    },

    rightParticipants(): Participant[] {
      return _.filter(this.primaryParticipants, (p) => {
        return _.includes(RightPartyTypeIDs, p.partyTypeID);
      });
    },
  },
  components: {
    ParticipantSummary,
  },
});
