














import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Geography } from '@/vuex/geography/geography';

export default Vue.extend({
  props: {
    id: Number,
    to: Object,
    locationParamName: {
      type: String,
      default: 'locationID',
    },
  },
  computed: {
    ...mapGetters('geography', ['geographyAncestors']),

    ancestors(): Geography[] {
      return this.geographyAncestors(this.id) || [];
    },

    items(): any[] {
      return _.map(this.ancestors, (geo) => {
        const item: any = Object.assign({}, geo);
        if (this.to && this.id !== item.id) {
          item.to = _.cloneDeep(this.to);
          _.set(item, `to.query.${this.locationParamName}`, geo.id);
        }
        return item;
      });
    },
  },
});
