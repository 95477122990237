













import _ from 'lodash';
import Vue from 'vue';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { firstModelIDInState } from '@/lib/vuex-domainmodel';
import { Prosecutor } from '@/vuex/investigation/prosecutor';

import ProsecutorForm from './ProsecutorForm.vue';

export default Vue.extend({
  data() {
    return {
      error: null as any,
      isOpen: false,

      prosecutor: null as Prosecutor | null,
    };
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.error = null;
      this.prosecutor = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event. It persists
     * the record to the database via the API.
     */
    async save(): Promise<void> {
      if (!this.prosecutor) {
        return;
      }
      try {
        const response = await investigationAPI.post('prosecutors/create', {
          data: {
            name: this.prosecutor.name,
            surname: this.prosecutor.surname,
            fatherName: this.prosecutor.fatherName,
            grandfatherName: this.prosecutor.grandfatherName,
            birthplaceGeographyID: this.prosecutor.birthplaceGeographyID,
            residenceGeographyID: this.prosecutor.residenceGeographyID,
            genderID: this.prosecutor.genderID,
            emailAddress: this.prosecutor.emailAddress,
            phoneNumber: this.prosecutor.phoneNumber,
            caseTypes: this.prosecutor.caseTypes,
            stageIDs: this.prosecutor.stageIDs,
            isDeleted: this.prosecutor.isDeleted,
          },
        });

        // Write the new state into the investigation store
        const newState = response.data.data;
        this.$store.commit('investigation/setState', newState);

        const newID = firstModelIDInState(newState, 'prosecutor');
        this.$emit('saved', newID);
        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    ProsecutorForm,
  },
});
