
















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import investigationAPI from '@/vuex/investigation/investigationAPI';
import { Department } from '@/vuex/investigation/department';

import DepartmentForm from '@/components/investigation/DepartmentForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      updatedDepartment: null as Department | null,
      error: null as any,
    };
  },
  computed: {
    ...mapGetters('investigation', ['departmentWithID']),
    dialogTitle(): string {
      if (!this.updatedDepartment) {
        return '';
      }
      return ` #${this.updatedDepartment.id}`;
    },
  },
  watch: {
    /**
     * isOpen is watched because we need to notice when the dialog's activator
     * has been clicked (which opens the dialog). This is our trigger to fetch
     * a dialog-local copy of the object to present to the user for editing.
     */
    isOpen(nowOpening: boolean): void {
      if (nowOpening && this.id) {
        const myCopy = Object.assign({}, this.departmentWithID(this.id));
        this.updatedDepartment = myCopy;
      }
    },
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset(): void {
      this.updatedDepartment = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.updatedDepartment) {
        return;
      }
      this.error = null;
      try {
        const response = await investigationAPI.post('departments/update', {
          data: {
            id: this.updatedDepartment.id,
            parentID: this.updatedDepartment.parentID,
            rank: this.updatedDepartment.rank,
            code: this.updatedDepartment.code,
            name: this.updatedDepartment.name,
            locationGeographyID: this.updatedDepartment.locationGeographyID,
            year: this.updatedDepartment.year,
            lastCaseNumber: this.updatedDepartment.lastCaseNumber,
            legacyDepartmentIDs: this.updatedDepartment.legacyDepartmentIDs,
          },
        });

        // Update Vuex state
        const newState = response.data.data;
        this.$store.commit('investigation/setState', newState);
        this.$store.commit('investigation/calculateDepartmentChildStats');

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    DepartmentForm,
  },
});
