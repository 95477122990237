var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":"","tile":"","color":"white"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"text-h4",attrs:{"cols":"12","md":"3","lg":"3"}},[_c('div',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.$t('module.court')))]),_vm._v(" "+_vm._s(_vm.$t('dashboard.singular'))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"7","lg":"7","xl":"7"}},[_c('div',{staticClass:"text-overline"},[_c('GeographyBreadcrumb',{attrs:{"id":_vm.criteria.locationID || 1,"to":{ name: 'courtDashboard', query: Object.assign({}, _vm.criteria) }}})],1),_c('div',{staticClass:"text-h4"},[_c('CourtBreadcrumb',{attrs:{"id":_vm.criteria.courtID || 0,"to":{ name: 'courtDashboard', query: Object.assign({}, _vm.criteria) },"departmentParamName":"courtID"}})],1)]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('v-img',{attrs:{"max-width":"60%","width":"150","contain":"","src":"/court-logo.png"}})],1),(_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_vm._e()],1)],1)],1),_c('FormError',{attrs:{"error":_vm.error}}),(!_vm.loading)?_c('v-row',{attrs:{"align-stretch":""}},[_c('CasesByCourtLocationTable',{attrs:{"criteria":_vm.criteria},model:{value:(_vm.menuExpanded),callback:function ($$v) {_vm.menuExpanded=$$v},expression:"menuExpanded"}}),_c('CasesByCourtTable',{attrs:{"criteria":_vm.criteria},model:{value:(_vm.menuExpanded),callback:function ($$v) {_vm.menuExpanded=$$v},expression:"menuExpanded"}})],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-h2 text-right",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"12"}},[_c('router-link',{attrs:{"to":{
              name: 'caseSearch',
              query: Object.assign({}, this.criteria, {activeOnly: true}),
            }}},[_c('NumFmt',{attrs:{"value":_vm.stats.activeCount}})],1),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('case.activeCases'))+" ")])],1),_c('v-col',{attrs:{"cols":"6","md":"12"}},[_vm._v(" "+_vm._s((_vm.stats.averageAge || 0).toFixed(1))+" "),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('case.averageAge'))+" "),_c('br'),_vm._v(" ( "+_vm._s(_vm.$t('time.days'))+" ) ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9","lg":"10"}},[_c('CaseClearanceRatio',{attrs:{"criteria":_vm.criteria}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-row',{staticClass:"align-stretch",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"error"}},[_c('v-card-text',{staticClass:"white--text text-right"},[_c('router-link',{staticClass:"text-h3 font-weight-bold white--text",attrs:{"to":{
                  name: 'caseSearch',
                  query: Object.assign({}, this.criteria,
                    {activeOnly: true,
                    pastDeadlineOnly: true}),
                }}},[_vm._v(" "+_vm._s(_vm.stats.pastDeadline)+" ")]),_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t('case.pastDeadline'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"warning"}},[_c('v-card-text',{staticClass:"white--text text-right"},[_c('router-link',{staticClass:"text-h3 font-weight-bold white--text",attrs:{"to":{
                  name: 'caseSearch',
                  query: Object.assign({}, this.criteria,
                    {activeOnly: true,
                    nearingDeadlineOnly: true}),
                }}},[_vm._v(" "+_vm._s(_vm.stats.nearingDeadline)+" ")]),_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t('case.nearingDeadline'))+" ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('AgeOfPendingCaseload',{attrs:{"criteria":_vm.criteria}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('DisposedChargesBySeverity',{attrs:{"criteria":_vm.criteria}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CaseDocketEventCounts',{attrs:{"criteria":_vm.criteria}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('CasesByPunishmentType',{attrs:{"criteria":_vm.criteria}}),_c('CasesByDispositionReason',{attrs:{"criteria":_vm.criteria}}),_c('CasesByCivilCaseTopic',{attrs:{"criteria":_vm.criteria}}),_c('CasesByLawsuitType',{attrs:{"criteria":_vm.criteria}}),_c('CasesByCourtRevenue',{attrs:{"criteria":_vm.criteria}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CourtHearingCalendar',{attrs:{"courtID":_vm.criteria.courtID || 0}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }