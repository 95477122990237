













import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Courtroom } from '@/vuex/court/court';

export default Vue.extend({
  props: {
    courtID: {
      type: Number,
      required: true,
    },
    value: Number,
    label: String,
    rules: [],
    disabled: Boolean,
    readonly: Boolean,
    clearable: Boolean,
  },
  created(): void {
    this.fetchData();
  },
  watch: {
    courtID(val: number): void {
      if (this.courtID) {
        this.fetchData();
      }
    },
  },
  computed: {
    ...mapGetters('court', ['courtroomsForCourt']),
    courtroomChoices(): Courtroom[] {
      return _.sortBy(
        this.courtroomsForCourt(this.courtID) || [],
        (cr: Courtroom) => cr.name,
      );
    },
  },
  methods: {
    courtroomsChanged(selectedCourtroomID) {
      this.$emit('input', selectedCourtroomID);
    },
    fetchData(): void {
      this.$store.dispatch('court/fetchCourtroomsForCourt', {
        courtID: this.courtID,
        force: false,
      });
    },
  },
});
