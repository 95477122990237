import axios from 'axios';

// Usage:
// import Github from '@/lib/github';
// Label can be one of three options: comment, bug, question
// Github.createIssue("Issue title", "Issue body details", "comment");

export default class Github {
  static BASE_URL = 'https://api.github.com/repos/jsspaf-cms/cms2';

  // CMSFeedback Github user in JSSP org
  // Personal token
  static TOKEN = 'cb7f1dd6e1960efaf99a77012c26856697c37f19';

  static createIssue(
    title: string,
    body: string,
    label: string,
  ): Promise<void> {
    // Milestone for "CMS Feedback" tracked on Github
    // TODO: move to env variables?
    const MILESTONE_ID = 2;

    const config = {
      method: 'post',
      baseURL: this.BASE_URL + '/issues',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `token ${this.TOKEN}`,
      },
      data: {
        title,
        body,
        milestone: MILESTONE_ID,
        labels: [label],
      },
    };

    return axios.post(config.baseURL, config.data, { headers: config.headers });
  }
}
