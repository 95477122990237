



















import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Offender } from '@/vuex/offender/offender';
import { Profile } from '@/vuex/profile/profile';

import OffenderHeader from '@/components/offender/OffenderHeader.vue';
import OffenderHousing from '@/views/offender/OffenderHousing.vue';
import OffenderMedical from '@/views/offender/OffenderMedical.vue';
import OffenderNavToolbar from '@/components/offender/OffenderNavToolbar.vue';
import OffenderProfile from '@/views/offender/OffenderProfile.vue';
import OffenderPrograms from '@/views/offender/OffenderPrograms.vue';
import OffenderSentence from '@/views/offender/OffenderSentence.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters('offender', ['offenderWithID']),
    ...mapGetters('profile', ['profileWithID']),
    ...mapGetters('auth', ['hasPermission']),

    offender(): Offender | null {
      return this.offenderWithID(this.id) || null;
    },

    profile(): Profile | null {
      if (this.offender && this.offender.profileID) {
        return this.profileWithID(this.offender.profileID) || null;
      } else {
        return null;
      }
    },

    canViewMedical(): boolean {
      if (this.offender) {
        if (
          this.hasPermission(
            'offender.medical.view',
            'facility',
            this.offender.housingFacilityID,
          )
        ) {
          return true;
        }

        if (
          this.hasPermission(
            'offender.medical.createExam',
            'facility',
            this.offender.housingFacilityID,
          ) ||
          this.hasPermission(
            'offender.medical.createTreatment',
            'facility',
            this.offender.housingFacilityID,
          ) ||
          this.hasPermission(
            'offender.medical.createIntakeHealthScreening',
            'facility',
            this.offender.housingFacilityID,
          ) ||
          this.hasPermission(
            'offender.medical.createHistory',
            'facility',
            this.offender.housingFacilityID,
          )
        ) {
          return true;
        }
      }

      return false;
    },

    canViewHousing(): boolean {
      if (this.offender) {
        if (
          this.hasPermission('offender.transfer.request') ||
          this.hasPermission('offender.leave.request') ||
          this.hasPermission('offender.leave.backfill') ||
          this.hasPermission('offender.move')
        ) {
          return true;
        }
      }
      return false;
    },

    canViewSentence(): boolean {
      if (this.offender) {
        if (
          this.hasPermission('offender.createSentenceAdjustment') ||
          this.hasPermission('offender.assignPardonDecree')
        ) {
          return true;
        }
      }
      return false;
    },

    canViewProfile(): boolean {
      if (this.offender) {
        if (
          this.hasPermission('offender.escape.create') ||
          this.hasPermission('offender.reclassify') ||
          this.hasPermission('offender.incident.create')
        ) {
          return true;
        }
      }
      return false;
    },

    canViewPrograms(): boolean {
      return this.hasPermission('offender.program.create');
    },
  },

  watch: {
    id() {
      this.fetchData();
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      await this.$store.dispatch('offender/fetchOffender', this.id);
      if (this.offender && this.offender.dossierID) {
        const dossierID = this.offender.dossierID;
        await this.$store.dispatch('court/fetchDossier', dossierID);
      }
      setTimeout(window.print, 1000);
    },
  },
  components: {
    OffenderHeader,
    OffenderHousing,
    OffenderMedical,
    OffenderNavToolbar,
    OffenderProfile,
    OffenderPrograms,
    OffenderSentence,
  },
});
