




import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

const loading = false;

export default Vue.extend({
  props: {
    id: Number,
    full: Boolean,
    hideUnknown: Boolean,
  },
  computed: {
    ...mapGetters('housing', ['facilityName', 'fullFacilityName']),
    ...mapState('housing', ['facility']),

    name(): string {
      const name = this.facilityName(this.id);
      return this.exists ? name : loading || this.hideUnknown ? '' : name;
    },

    fullName(): string {
      const fullName = this.fullFacilityName(this.id);
      return this.exists
        ? fullName
        : loading || this.hideUnknown
        ? ''
        : fullName;
    },

    title(): string {
      return this.name === this.fullName ? '' : this.fullName;
    },

    exists(): boolean {
      if (!this.id) {
        return false;
      }

      return !!this.facility[this.id];
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(facilityID: number) {
        if (!facilityID) {
          // No need to queue a fetch for the Unknown facility
          return;
        }

        if (!this.facility[facilityID]) {
          this.$store.dispatch('housing/fetchFacility', {
            force: false,
            facilityID,
          });
        }
      },
    },
  },
});
