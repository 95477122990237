import _ from 'lodash';
import { Participant } from './participant';
import courtAPI from './courtAPI';

export const PrimaryPartyMaxRank = 30;

export interface Party {
  id: number;
  name: string;
  surname: string;
  fatherName: string;
  grandfatherName: string;
  aliases: [];
  nationalID: string;
  genderID: number;
  isKochi: boolean;
  occupation: string;
  phoneNumber: string;
  emailAddress: string;
  birthplaceGeographyID: number;
  residenceGeographyID: number;
  isOrganization: boolean;
  organizationName: string;
  businessLicenseNumber: string;
  isDeleted: boolean;
}

export const enum PartyTypeID {
  Unknown = 0,
  Defendant = 1,
  Respondent = 2,
  Petitioner = 3,
  Plaintiff = 4,
  Prosecutor = 5,
  Victim = 6,
  Witness = 7,
  Expert = 8,
  RestitutionRecipient = 9,
  Appellant = 10,
  Appellee = 11,
  CivilDefendant = 12,
}

export const ProsecutorPartyTypeIDs = [PartyTypeID.Prosecutor];
export const DefendantPartyTypeIDs = [PartyTypeID.Defendant];
export const AppealPartyTypeIDs = [PartyTypeID.Appellant, PartyTypeID.Appellee];
/**
 * NonAppealPartyTypes holds the broadest list of "regular" parties which can
 * appear in "non-appeal" (i.e. "Primary") cases.
 */
export const NonAppealPartyTypes = [
  PartyTypeID.Defendant,
  PartyTypeID.Respondent,
  PartyTypeID.Petitioner,
  PartyTypeID.Plaintiff,
  PartyTypeID.Prosecutor,
  PartyTypeID.Victim,
  PartyTypeID.Witness,
  PartyTypeID.Expert,
  PartyTypeID.RestitutionRecipient,
  PartyTypeID.CivilDefendant,
];
export const LeftPartyTypeIDs = [
  PartyTypeID.Petitioner,
  PartyTypeID.Prosecutor,
  PartyTypeID.Plaintiff,
  PartyTypeID.Appellant,
];
export const RightPartyTypeIDs = [
  PartyTypeID.Respondent,
  PartyTypeID.Defendant,
  PartyTypeID.Appellee,
];

export const state = {
  party: {} as { [id: number]: Party },
};

export const getters = {
  partyWithID:
    (state) =>
    (partyID: number): Party => {
      return state.party[partyID];
    },
  primaryPartyTypes: (state) => {
    return _.filter(state.partyTypes, (pt) => pt.rank < PrimaryPartyMaxRank);
  },
  primaryPartyTypeIDs: (state, getters) => {
    return _.map(getters.primaryPartyTypes, (pt) => pt.id);
  },

  /**
   * partyName accepts either a single number or an array of numbers and returns
   * the name/surname or the Party with that id. In the case of multiple ids,
   * the result is a comma-separated string.
   */
  partyName:
    (state) =>
    (partyID: number | number[]): string => {
      const ids = _.flatten([partyID]);
      const parties: Party[] = _.map(ids, (id) => state.party[id]);
      const names: string[] = _.map(parties, (party) => {
        if (!party) {
          return `Unknown Party: ${partyID}`;
        }
        if (party.isOrganization) {
          return `${party.organizationName}`;
        }
        return `${party.name} ${party.surname}`;
      });
      return names.join(', ');
    },

  fullPartyName:
    (state) =>
    (partyID: number): string => {
      const party = state.party[partyID];
      if (!party) {
        return `Unknown Party: ${partyID}`;
      }

      let name = '';
      if (party.isOrganization) {
        name = party.organizationName;
        if (party.businessLicenseNumber) {
          name = name + ` (${party.businessLicenseNumber})`;
        }
      } else {
        name = `${party.name} ${party.surname}`;
      }
      return name;
    },
  partiesForDossier:
    (state, getters) =>
    (dossierID: number): Party[] => {
      const participants = getters.participantsForDossier(dossierID);
      return _.map(participants, (participant: Participant) => {
        return state.party[participant.partyID];
      });
    },
  partiesForCase:
    (state, getters) =>
    (caseID: number): Party[] => {
      const participants = getters.participantsForCase(caseID);
      return _.map(participants, (participant: Participant) => {
        return state.party[participant.partyID];
      }) as Party[];
    },
};

export const mutations = {};

export const actions = {
  /**
   * fetchParty retrieves court Party entities from the server and
   * injects them into the local Vuex store. It accepts a single party ID
   * or an array of IDs.
   */
  async fetchParty({ commit }, id: number | number[]): Promise<void> {
    const idsStr = _.flatten([id]).join(',');
    if (idsStr) {
      const response = await courtAPI.get(`/parties/batch?ids=${idsStr}`);
      const partyState = response.data.data;
      commit('setState', partyState);
    }
  },
};
