















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { AllDossierStageIDs } from '@/vuex/court/dossier';
import { TAll, TUnknown } from '@/lib/translated';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    label: String,
    value: [Number, Array],
    hint: String,
    persistentHint: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    chips: Boolean,
    items: {
      required: false,
      type: [Number, Array],
    },
    clearable: Boolean,

    includeUnknown: Boolean,
    unknownLabel: String,
  },
  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapState('language', ['locale']),

    /**
     * stageIDs builds the list of court stage IDs that the user will be
     * allowed to select from. This can be supplied directly by the user via
     * the items prop, or obtained via the AllCourtStageIDs const. Choices for
     * null (Unknown) and 0 (Before Court) are added optionally if props are
     * set.
     */
    stageIDs(): any[] {
      // Wrap and flatten items to convert from a single item to an array
      const ids: any[] = this.items
        ? _.flatten([this.items])
        : _.cloneDeep(AllDossierStageIDs);

      if (this.includeUnknown) {
        ids.unshift('unknown');
      }
      return ids;
    },

    /**
     * stageChoices builds the array of dropdown selection items.
     */
    stageChoices(): VSelectItem[] {
      const choices: VSelectItem[] = _.map(this.stageIDs, (id) =>
        this.buildItemForID(id),
      );
      return choices;
    },
  },

  methods: {
    buildItemForID(id: number | string): VSelectItem {
      if (id === 'unknown') {
        let label = TUnknown[this.locale];
        if (this.unknownLabel === 'all') {
          label = TAll[this.locale];
        }
        return {
          value: 'unknown',
          text: label,
        };
      }

      return {
        value: id,
        text: this.nameForIDInCollection(id, 'court/dossierStages'),
      };
    },
  },
});
