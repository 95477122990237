















































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  mounted() {
    (this.$refs.username as HTMLElement).focus();
  },
  data() {
    return {
      username: '',
      password: '',
      loginPending: false,
    };
  },
  computed: {
    ...mapState('auth', ['error']),
  },
  watch: {
    '$i18n.local'(newValue) {
      (this.$refs.username as HTMLElement).focus();
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    async attemptLogin(): Promise<void> {
      const creds = {
        username: this.username,
        password: this.password,
      };
      this.loginPending = true;
      await this.login(creds);
      this.loginPending = false;
    },
  },
});
