




















































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';
import { Charge } from '@/vuex/court/charge';

import AddChargeDialog from './AddChargeDialog.vue';
import AggravatorsMitigatorsDetail from './AggravatorsMitigatorsDetail.vue';

export default Vue.extend({
  props: {
    value: {
      type: Array,
      default: () => [] as Charge[],
    },
    readonly: Boolean,
    requireDispositions: Boolean,
  },

  computed: {
    ...mapState('language', ['locale']),
    ...mapState('crime', [
      'completionStatuses',
      'participationTypes',
      'severities',
    ]),
    ...mapState('court', ['chargeDispositionTypes']),
    ...mapGetters('language', ['valueForLocale', 'nameForIDInCollection']),
    ...mapGetters('crime', ['crimeWithID']),

    charges(): Charge[] {
      if (this.value) {
        return this.value as Charge[];
      } else {
        return [];
      }
    },

    translatedCharges(): any[] {
      return this.charges.map((charge) => {
        const crime = this.crimeWithID(charge.crimeID);
        return Object.assign({}, charge, {
          crime: this.valueForLocale(crime.title),
          completionStatus: this.nameForIDInCollection(
            charge.completionStatusID,
            this.completionStatuses,
          ),
          participationType: this.nameForIDInCollection(
            charge.participationTypeID,
            this.participationTypes,
          ),
          severity: this.nameForIDInCollection(
            charge.crimeSeverityID,
            this.severities,
          ),
        });
      });
    },

    headers(): any[] {
      return [
        { value: 'crime', text: i18n.t('charge.crime.singular') },
        {
          value: 'participationType',
          text: i18n.t('charge.participationType'),
        },
        { value: 'completionStatus', text: i18n.t('charge.completionStatus') },
        { value: 'severity', text: i18n.t('charge.severity') },
        { value: null, text: i18n.t('charge.disposition') },
      ];
    },

    dispositionRules(): any[] {
      if (!this.requireDispositions) {
        return [];
      }
      return [(v) => !!v || i18n.t('error.required')];
    },
  },

  methods: {
    addCharge(charge: Charge): void {
      const newItems = [...(this.value || []), charge];
      this.$emit('input', newItems);
    },

    updateChargeDisposition(index: number, typeID: number): void {
      const changedItems = [...this.charges];
      changedItems[index].dispositionTypeID = typeID;
      this.$emit('input', changedItems);
    },
  },

  components: {
    AddChargeDialog,
    AggravatorsMitigatorsDetail,
  },
});
