import _ from 'lodash';
import offenderAPI from '@/vuex/offender/offenderAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface SentenceAdjustment {
  id: number;
  offenderID: number;
  dossierID: number;
  reasonID: number;
  pardonDecreeID: number;
  adjustmentDirection: number;
  adjustmentAmountInHours: number;
  oldDurationInHours: number;
  newDurationInHours: number;
  oldDetainedAt: string;
  newDetainedAt: string;
  newExpectedReleaseAt: string;
  notes: string;
  filedAt: string;
  registeredAt: string;
}

export const enum SentenceAdjustmentReasonID {
  Unknown = 0,
  Intake = 1,
  // See sentence_adjustment_reasons database table
  // for other options to enumerate here if needed.
}

export const state = {
  sentenceAdjustment: {} as { [id: number]: SentenceAdjustment },
  sentenceAdjustmentIDsForOffender: {} as { [offenderID: number]: number[] },
};

export const getters = {
  sentenceAdjustmentWithID:
    (state) =>
    (id: number): SentenceAdjustment => {
      return state.sentenceAdjustment[id];
    },
  sentenceAdjustmentsForOffender:
    (state) =>
    (id: number): SentenceAdjustment[] => {
      return (state.sentenceAdjustmentIDsForOffender[id] || []).map(
        (id: number) => state.sentenceAdjustment[id],
      );
    },
};

export const mutations = {};

export const actions = {
  /**
   * fetchSentenceAdjustments retrieves the complete list of sentence adjustments
   * for a particular offender.
   */
  async fetchSentenceAdjustments(
    { commit, dispatch },
    payload: { offenderID: number },
  ) {
    const response = await offenderAPI.get(
      `offenders/${payload.offenderID}/sentence-adjustments`,
    );
    const adjustments = _.get(response, 'data.data');
    const newState = modelsToState('sentenceAdjustment', adjustments);
    commit('setState', newState);
    commit('setTarget', {
      target: 'sentenceAdjustmentIDsForOffender',
      index: payload.offenderID,
      value: _.map(adjustments, 'id'),
    });

    // Ensure any SentenceAdjustment-related Dossiers are added to state.
    const dossierIDs = _.chain(adjustments).map('dossierID').compact().value();
    for (const id of dossierIDs) {
      dispatch('court/fetchDossier', id, { root: true });
    }
  },
};
