var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":_vm.contentOnly || _vm.isReleased,"tile":_vm.contentOnly || _vm.isReleased}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"},scopedSlots:_vm._u([(_vm.canAdd && !_vm.contentOnly)?{key:"extension",fn:function(){return [_c('AddSentenceAdjustmentDialog',{attrs:{"offenderID":_vm.offenderID}})]},proxy:true}:null],null,true)},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('sentenceAdjustment.plural')))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.translatedAdjustments,"disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('Date',{attrs:{"value":item.filedAt}})],1),_c('td',[_vm._v(" "+_vm._s(_vm.nameForIDInCollection(item.reasonID, _vm.sentenceAdjustmentReasons))+" ")]),_c('td',[_vm._v(_vm._s(item.translatedOperation))]),_c('td',[_c('Duration',{attrs:{"hours":item.adjustmentAmountInHours}})],1),_c('td',[_c('Duration',{attrs:{"hours":item.newDurationInHours}})],1),_c('td',[_c('Date',{attrs:{"value":item.newExpectedReleaseAt}})],1),_c('td',[_c('ExpanderDialog',{attrs:{"title":_vm.$t('sentenceAdjustment.singular')}},[_c('v-row',[_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('filedAt')}},[_c('Date',{attrs:{"value":item.filedAt}})],1),_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('sentenceAdjustment.direction'),"value":item.translatedOperation}}),_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('sentenceAdjustment.amount'),"value":item.adjustmentAmountInHours}},[_c('Duration',{attrs:{"hours":item.adjustmentAmountInHours}})],1)],1),_c('v-row',[_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('sentenceAdjustment.reason'),"value":_vm.nameForIDInCollection(
                      item.reasonID,
                      _vm.sentenceAdjustmentReasons
                    )}}),(item.dossierID && _vm.dossierWithID(item.dossierID))?_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('dossier.dossierNumber'),"value":_vm.dossierWithID(item.dossierID).number}}):_vm._e(),(item.pardonDecreeID)?_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('pardonDecrees.singular'),"value":item.pardonDecree.number + ': ' + item.pardonDecree.title}}):_vm._e()],1),_c('v-row',[(item.oldDurationInHours)?_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('sentenceAdjustment.oldDuration')}},[_c('Duration',{attrs:{"hours":item.oldDurationInHours}})],1):_vm._e(),_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('sentenceAdjustment.newDuration')}},[_c('Duration',{attrs:{"hours":item.newDurationInHours}})],1)],1),(
                  item.newDetainedAt &&
                  item.newDetainedAt != item.oldDetainedAt
                )?_c('v-row',[_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('sentenceAdjustment.oldDetainedAt')}},[_c('Date',{attrs:{"value":item.oldDetainedAt}})],1),_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('sentenceAdjustment.newDetainedAt')}},[_c('Date',{attrs:{"value":item.newDetainedAt}})],1)],1):_vm._e(),_c('v-row',[_c('LabeledData',{attrs:{"cols":"4","label":_vm.$t('sentenceAdjustment.newExpectedRelease')}},[_c('Date',{attrs:{"value":item.newExpectedReleaseAt}})],1)],1),_c('v-row',[_c('LabeledData',{attrs:{"cols":"12","label":_vm.$t('notes'),"value":item.notes}})],1)],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }