



















import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Offender } from '@/vuex/offender/offender';

import OffenderHeader from '@/components/offender/OffenderHeader.vue';
import OffenderHousing from '@/views/offender/OffenderHousing.vue';
import OffenderMedical from '@/views/offender/OffenderMedical.vue';
import OffenderNavToolbar from '@/components/offender/OffenderNavToolbar.vue';
import OffenderProfile from '@/views/offender/OffenderProfile.vue';
import OffenderPrograms from '@/views/offender/OffenderPrograms.vue';
import OffenderSentence from '@/views/offender/OffenderSentence.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters('offender', ['offenderWithID', 'offenderIsReleased']),

    offender(): Offender {
      return this.offenderWithID(this.id);
    },

    offenderPage(): string {
      return this.$route.name || '';
    },

    /**
     * isReleased computes whether the currently-viewed offender has already
     * been released.
     */
    isReleased(): boolean {
      return this.offenderIsReleased(this.id);
    },
  },
  components: {
    OffenderHeader,
    OffenderHousing,
    OffenderMedical,
    OffenderNavToolbar,
    OffenderProfile,
    OffenderPrograms,
    OffenderSentence,
  },
});
