





































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import AttorneySearcher from '@/components/court/attorney/AttorneySearcher.vue';

export default Vue.extend({
  props: {
    value: Number,

    // label is a passthrough to the v-autocomplete label prop
    label: String,

    // rules is a passthrough to the v-autocomplete rules prop
    rules: {
      type: Array,
      default: () => [], // Default value is a function which returns a blank array
    },
    // disabled is a passthrough to the v-autocomplete disabled prop
    disabled: Boolean,
    // value is the prop that makes this component work with v-model
    clearable: Boolean,
    // exclude is an array prop that contains IDs of attorneys to filter out of
    // the list so that they are not even visible to be selected.
    excludedIDs: {
      type: Array,
      default: () => [] as number[],
    },
  },
  data() {
    return {
      loading: false,
      isOpen: false,
    };
  },
  watch: {
    isOpen(newVal: boolean) {
      this.$nextTick(() => {
        (this.$refs.attorneySearcher as any).reset();
      });
    },
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapGetters('court', ['attorneyName']),
    currentAttorneyName(): string {
      if (this.value) {
        return this.attorneyName(this.value);
      }
      return '';
    },
  },
  methods: {
    attorneyChanged(newVal) {
      this.$emit('input', newVal);
    },
  },
  components: {
    AttorneySearcher,
  },
});
