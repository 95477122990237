import _ from 'lodash';
import Vue from 'vue';

import courtAPI from '@/vuex/court/courtAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';
import { AxiosResponse } from 'axios';

export interface DocketEntry {
  id: number;
  payload: any;
  actionType: string;
  userID: number;
  caseID: number;
  dossierID: number;
  entryTypeID: number;
  filedAt: string; // Calculated client-side, does not exist on server
  filedAtSort: string; // Calculated client-side, does not exist on server
  submittedAt: Date | string;
  description: string;
}

/**
 * sortDocketEvents processes an array of server-provided docket event objects
 * and prepares them to display in "docket order" in a docket table. This
 * involves extracting a consistent filedAt date for each item, and a supplemental
 * filedAtSort attribute, which truncates filedAt to the date portion and adds
 * the event ID number, so that later-created events always occur after
 * earlier-created events on the same day, event if the times of the two events
 * would suggest a different sort order.
 */
function sortDocketEvents(events: DocketEntry[]): DocketEntry[] {
  return _.chain(events)
    .map((e) => {
      // Create a deep copy of the event to avoid modifying the original
      const event = _.cloneDeep(e);

      // Events which came from CMS1 will have a submittedDate matching the
      // date they were imported, rather than the date they were entered in CMS1.
      // To address this, we overwrite the submittedAt date with the registerdate
      // in the payload if it exists.
      const registerDate = _.get(event, 'payload.registerdate', null);
      if (registerDate) {
        event.submittedAt = registerDate;
      }

      // ADD ATTRIBUTE: filedAt
      // Figure out the effective filedAt date to display in the
      // docket. *Most* of the time, this is the filedAt date in the
      // payload. We handle exceptions for events that don't have a FiledAt date
      // here.
      switch (event.actionType) {
        case 'RecordSummonsServiceAction': {
          event.filedAt = _.get(event, 'payload.servedAt') || e.submittedAt;
          break;
        }
        case 'Intake': {
          event.filedAt = _.get(event, 'payload.detainedAt') || e.submittedAt;
          break;
        }
        case 'ReleaseOffenderAction': {
          event.filedAt = _.get(event, 'payload.freedAt') || e.submittedAt;
          break;
        }
        default: {
          event.filedAt = _.get(e, 'payload.filedAt') || e.submittedAt;
          break;
        }
      }

      // ADD ATTRIBUTE: filedAtSort
      // During display we want to resolve sorting "ties" on the date with
      // the ID so that later-created events which happened on the same day
      // appear later, regardless of the specific details of the time portion
      // (we do this because time precision during data input is inconsistent).
      const datePart = event.filedAt.substring(0, 10);
      const idPart = event.id.toString().padStart(12, '0');
      event.filedAtSort = datePart + '#' + idPart;
      return event;
    })
    .sortBy('filedAtSort')
    .value();
}

export const state = {
  docketEventIDsForCase: {} as { [docketEntryID: number]: number[] },
  docketEventIDsForDossier: {} as { [docketEntryID: number]: number[] },
  docketEntry: {} as { [id: number]: DocketEntry },
};

export const getters = {
  docketEventsForCase:
    (state) =>
    (caseID: number): DocketEntry[] => {
      const events: DocketEntry[] = (
        state.docketEventIDsForCase[caseID] || []
      ).map((id: number) => state.docketEntry[id]);
      return sortDocketEvents(events);
    },

  docketEventsForDossier:
    (state) =>
    (dossierID: number): DocketEntry[] => {
      const events: DocketEntry[] = (
        state.docketEventIDsForDossier[dossierID] || []
      ).map((id: number) => state.docketEntry[id]);
      return sortDocketEvents(events);
    },
};
export const mutations = {
  addDocketEntry(state, response: AxiosResponse): void {
    const logEntry = response.data.logEntry;
    if (!logEntry) {
      // Ignore entries which are missing docket entry metadata
      return;
    }

    // First add the docket entry data itself
    state.docketEntry[logEntry.id] = logEntry;

    // Append the ID to the case's docket entries
    const caseID = logEntry.payload.caseID;
    if (caseID) {
      const oldItems = state.docketEventIDsForCase[caseID] || [];
      Vue.set(state.docketEventIDsForCase, caseID, [...oldItems, logEntry.id]);
    }

    // Append the ID to the dossier's docket entries
    const dossierID = logEntry.payload.dossierID;
    if (dossierID) {
      const oldItems = state.docketEventIDsForDossier[dossierID] || [];
      Vue.set(state.docketEventIDsForDossier, dossierID, [
        ...oldItems,
        logEntry.id,
      ]);
    }
  },
};

export const actions = {
  async fetchDocketEntriesForCase({ commit }, payload: { caseID: number }) {
    const response = await courtAPI.get(
      `cases/${payload.caseID}/docket-events`,
    );
    const docketEntries = response.data.data;
    const newState = modelsToState('docketEntry', docketEntries);
    commit('setState', newState);
    commit('setTarget', {
      target: 'docketEventIDsForCase',
      index: payload.caseID,
      value: _.map(docketEntries, 'id'),
    });
  },

  async fetchDocketEntriesForDossier(
    { commit },
    payload: { dossierID: number },
  ) {
    const response = await courtAPI.get(
      `dossiers/${payload.dossierID}/docket-events`,
    );
    const docketEntries = response.data.data;
    const newState = modelsToState('docketEntry', docketEntries);
    commit('setState', newState);
    commit('setTarget', {
      target: 'docketEventIDsForDossier',
      index: payload.dossierID,
      value: _.map(docketEntries, 'id'),
    });
  },
};
