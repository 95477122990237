





























import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import { PunishmentType } from '@/vuex/crime/crime';
import { VSelectItem } from '@/lib/vue-typescript';

export default Vue.extend({
  props: {
    value: [Array, Number],
    items: Array,
    rules: Array,
    chips: Boolean,
    multiple: Boolean,
    label: String,
    validTypeIDs: Array,
    excludeUnknown: Boolean,
    disabled: Boolean,
  },
  computed: {
    ...mapState('language', ['locale']),
    ...mapState('crime', ['punishmentTypes', 'punishmentTypeCategories']),
    punishmentTypeChoices(): VSelectItem[] {
      let types = (
        this.items ? this.items : this.punishmentTypes
      ) as PunishmentType[];

      if (this.excludeUnknown) {
        types = types.filter((v) => v.id !== 0);
      }

      const groupedTypes = _.groupBy(types, 'categoryID');

      const choices = [] as VSelectItem[];
      for (const cat of this.punishmentTypeCategories) {
        const catTypes = groupedTypes[cat.id];

        if (catTypes && catTypes.length > 0) {
          // Add a Divider Title for this Category
          choices.push({ header: cat.name[this.locale] });
          choices.push({ divider: true });

          // Add the punishment type choices from this category
          choices.push(
            ...catTypes.map((pt: PunishmentType) => ({
              text: pt.name[this.locale],
              value: pt.id,
            })),
          );
        }
      }
      return choices;
    },
  },
  methods: {
    isValidTypeID(id: number): boolean {
      return _.includes(this.validTypeIDs || [], id);
    },
  },
});
