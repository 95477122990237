import moment from 'moment';
import _ from 'lodash';
import { domainmodelMutations } from '@/lib/vuex-domainmodel';
import offenderAPI from '@/vuex/offender/offenderAPI';

import {
  state as accompanyingChildState,
  getters as accompanyingChildGetters,
  mutations as accompanyingChildMutations,
  actions as accompanyingChildActions,
} from './accompanyingChild';
import {
  state as escapeState,
  getters as escapeGetters,
  mutations as escapeMutations,
  actions as escapeActions,
} from './escape';
import {
  state as incidentState,
  getters as incidentGetters,
  mutations as incidentMutations,
  actions as incidentActions,
} from './incident';
import {
  state as leaveState,
  getters as leaveGetters,
  mutations as leaveMutations,
  actions as leaveActions,
} from './leave';
import {
  state as medicalState,
  getters as medicalGetters,
  mutations as medicalMutations,
  actions as medicalActions,
} from './medical';
import {
  state as optionsState,
  getters as optionsGetters,
  mutations as optionsMutations,
  actions as optionsActions,
} from './options';
import {
  state as programState,
  getters as programGetters,
  mutations as programMutations,
  actions as programActions,
} from './program';
import {
  state as sentenceAdjustmentState,
  getters as sentenceAdjustmentGetters,
  mutations as sentenceAdjustmentMutations,
  actions as sentenceAdjustmentActions,
} from './sentenceAdjustment';
import {
  state as transferState,
  getters as transferGetters,
  mutations as transferMutations,
  actions as transferActions,
} from './transfer';

export interface Offender {
  id: number;
  profileID: number;
  dossierID: number;
  detainedAt: Date | string;
  imprisonmentDurationInHours: number;
  freedAt: Date | string;
  DIN: string;
  PIN: string;
  case: object;
  hasChild: boolean;
  hasStar: boolean;
  hasEscape: boolean;
  hasWarning: boolean;
  hasMedical: boolean;
  isDetainee: boolean;
  isJuvenile: boolean;
  flagIDs: number[];
  pardonDecreeIDs: number[];
  privilegeIDs: number[];
  classificationScore: number;
  housingFacilityID: number;
  cellID: number;
  custodyStatus: string;
  releaseReasonID: number;
  releaseDetails: string;
  expectedReleaseAt: string;
}

export function blankOffender(): Offender {
  return {
    id: 0,
    profileID: 0,
    dossierID: 0,
    detainedAt: moment().format(),
    imprisonmentDurationInHours: 0,
    expectedReleaseAt: moment().add(7, 'days').format(),
    freedAt: '',
    DIN: '',
    PIN: '',
    case: {},
    hasStar: false,
    hasChild: false,
    hasEscape: false,
    hasWarning: false,
    hasMedical: false,
    isDetainee: true,
    isJuvenile: false,
    flagIDs: [],
    pardonDecreeIDs: [],
    privilegeIDs: [],
    classificationScore: 20, // default for any new offenders should be 20
    housingFacilityID: 0,
    cellID: 0,
    custodyStatus: '',
    releaseReasonID: 0,
    releaseDetails: '',
  };
}

export abstract class Flags {
  public static readonly criminalGroupMemberID = 1;
  public static readonly escapeeID = 2;
  public static readonly withChildID = 3;
  public static readonly medicalConcernsID = 4;
  public static readonly infectiousDiseaseID = 5;
  public static readonly presidentialDecreeID = 6;
  public static readonly highProfileOffenderID = 7;
  public static readonly securityThreatID = 8;
  public static readonly specialManagementID = 9;
}

export const custodyStatuses: string[] = [
  'released',
  'on-bond',
  'monitored',
  'enforcement-suspended',
  'deceased',
  'executed',
];

const state = {
  ...accompanyingChildState,
  ...escapeState,
  ...incidentState,
  ...leaveState,
  ...medicalState,
  ...optionsState,
  ...programState,
  ...sentenceAdjustmentState,
  ...transferState,

  offenderIDsForProfile: {} as { [profileID: number]: number[] },
  offender: {} as { [id: number]: Offender },
};

const getters = {
  ...accompanyingChildGetters,
  ...escapeGetters,
  ...incidentGetters,
  ...leaveGetters,
  ...medicalGetters,
  ...optionsGetters,
  ...programGetters,
  ...sentenceAdjustmentGetters,
  ...transferGetters,

  /**
   * offenderWIthID is the global getter to retrieve a single Offender from the
   * Vuex state by their ID.
   */
  offenderWithID:
    (state) =>
    (id): Offender | null => {
      return state.offender[id] || null;
    },

  /**
   * offenderIsReleased is a parameterized getter which expects an Offender ID
   * and returns true if the referenced Offender has been released. Note that
   * the Offender must have been fetched and be in the local Vuex state for this
   * to work.
   */
  offenderIsReleased:
    (state) =>
    (id): boolean => {
      const o: Offender = state.offender[id];
      if (!o) {
        // If the Offender is not in the Vuex state, assume they have not
        // been released.
        return false;
      }
      return !!o.freedAt;
    },

  offendersForProfile:
    (state) =>
    (profileID: number): Offender[] => {
      return (state.offenderIDsForProfile[profileID] || []).map((id) => {
        return state.offender[id];
      });
    },
};

const mutations = {
  ...domainmodelMutations,

  ...accompanyingChildMutations,
  ...escapeMutations,
  ...incidentMutations,
  ...leaveMutations,
  ...medicalMutations,
  ...optionsMutations,
  ...programMutations,
  ...sentenceAdjustmentMutations,
  ...transferMutations,
};

const actions = {
  ...accompanyingChildActions,
  ...escapeActions,
  ...incidentActions,
  ...leaveActions,
  ...medicalActions,
  ...optionsActions,
  ...programActions,
  ...sentenceAdjustmentActions,
  ...transferActions,

  /**
   * fetchOffender triggers an API call to fetch Offender data from the API
   * and write it to the Vuex state. Dependent entities (Persons, the
   * HousingFacility for the Offender's cell) are automatically fetched as well.
   *
   * @param id The ID of a single offender or an array of offender IDs.
   *
   */
  async fetchOffender({ commit, dispatch }, id: number | number[]) {
    const idsStr = _.flatten([id]).join(',');
    const response = await offenderAPI.get(`offenders/batch?ids=${idsStr}`);
    const offenderState = response.data.data;

    // Identify IDs of dependent entities so that they can be batch-fetched
    //
    const offenders = _.values(offenderState.offender || {});
    const dispatches = [] as any[];
    const profileIDs = _.chain(offenders)
      .map('profileID')
      .compact()
      .uniq()
      .value();
    if (profileIDs.length) {
      dispatches.push(
        dispatch('profile/fetchProfile', profileIDs, { root: true }),
      );
    }
    const cellIDs = _.chain(offenders).map('cellID').compact().uniq().value();
    for (const cellID of cellIDs) {
      dispatches.push(
        dispatch(
          'housing/fetchFacility',
          { facilityID: cellID },
          { root: true },
        ),
      );
    }

    // Fetch all entity dependencies
    await Promise.all(dispatches);

    // Only add the offenders to the state after all dependencies have been
    // fetched.
    commit('setState', offenderState);
  },

  async fetchOffenderForProfile(
    { commit, dispatch },
    profileID: number | number[],
  ) {
    const idsStr = _.flatten([profileID]).join(',');
    const response = await offenderAPI.get(
      `offenders/batch?profileIDs=${idsStr}`,
    );
    const newState = response.data.data;
    commit('setState', newState);
    if (newState.offender) {
      commit('setTarget', {
        target: 'offenderIDsForProfile',
        index: profileID,
        value: Object.keys(newState.offender),
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
