















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Court } from '@/vuex/court/court';

export default Vue.extend({
  props: {
    id: Number,
    to: Object,
    courtParamName: {
      type: String,
      default: 'courtID',
    },
  },
  computed: {
    ...mapGetters('court', ['courtAncestors']),

    ancestors(): Court[] {
      return this.courtAncestors(this.id) || [];
    },

    items(): any[] {
      const items = _.map(this.ancestors, (court) => {
        const item: any = Object.assign({}, court);
        if (this.to && this.id !== item.id) {
          item.to = _.cloneDeep(this.to);
          _.set(item, `to.query.${this.courtParamName}`, court.id);
        }
        return item;
      });

      const rootItem = { to: _.cloneDeep(this.to) };
      _.set(rootItem, `to.query.${this.courtParamName}`, 0);
      items.splice(0, 0, rootItem);
      return items;
    },
  },
});
