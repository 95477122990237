














import Vue from 'vue';
import { mapGetters } from 'vuex';

import courtAPI from '@/vuex/court/courtAPI';
import { Attorney } from '@/vuex/court/attorney';

import AttorneyForm from './AttorneyForm.vue';

export default Vue.extend({
  props: {
    id: Number,
  },
  data() {
    return {
      isOpen: false,
      error: null as any,
      attorney: null as Attorney | null,
    };
  },
  computed: {
    ...mapGetters('court', ['attorneyWithID']),
  },
  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    async reset(): Promise<void> {
      await this.$store.dispatch('court/fetchAttorney', this.id);
      const localCopy = Object.assign({}, this.attorneyWithID(this.id));
      this.attorney = localCopy;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.attorney) {
        return;
      }
      this.error = null;
      try {
        const response = await courtAPI.post('/attorneys/update', {
          data: {
            id: this.attorney.id,
            name: this.attorney.name,
            surname: this.attorney.surname,
            fatherName: this.attorney.fatherName,
            grandfatherName: this.attorney.grandfatherName,
            licenseNumber: this.attorney.licenseNumber,
            phoneNumber: this.attorney.phoneNumber,
            emailAddress: this.attorney.emailAddress,
            residenceGeographyID: this.attorney.residenceGeographyID,
            isDeleted: this.attorney.isDeleted,
          },
        });
        const newState = response.data.data;
        this.$store.commit('court/setState', newState);
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  components: {
    AttorneyForm,
  },
});
