














import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import crimeAPI from '@/vuex/crime/crimeAPI';
import { CrimeGroup } from '@/vuex/crime/crime';

import CrimeGroupForm from '@/components/crime/group/CrimeGroupForm.vue';

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      loading: false,
      error: null as any,

      crimeGroup: null as CrimeGroup | null,
    };
  },

  computed: {
    ...mapGetters('crime', ['crimeGroupWithID']),
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    async reset(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('crime/fetchCrimeGroup', this.id);
      const myCopy = Object.assign({}, this.crimeGroupWithID(this.id));
      this.crimeGroup = myCopy;
      this.loading = false;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.crimeGroup) {
        return;
      }
      this.error = null;

      try {
        const response = await crimeAPI.post('crime-groups/update', {
          data: {
            id: this.crimeGroup.id,
            name: this.crimeGroup.name,
            crimeIDs: this.crimeGroup.crimeIDs,
          },
        });

        const newState = _.get(response, 'data.data', {});
        this.$store.commit('crime/setState', newState);

        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    CrimeGroupForm,
  },
});
