import { TranslatedString } from '@/lib/translated';
import { DocketEventTypeModule } from '@/vuex/court/options';

export interface CustomFieldType {
  name: string;
  unlabelled: boolean;
  supportedModules?: DocketEventTypeModule[];
}

export const CustomFieldTypes = [
  { name: 'AddSubjectProperties' },
  { name: 'AddSubjectProperty' },
  { name: 'AFNCurrencyField' },
  { name: 'AddParticipant' },
  { name: 'BooleanSelector' },
  { name: 'CivilDecision', noLabel: true, },
  { name: 'CriminalDecision', noLabel: true },
  { name: 'CourtSelector' },
  { name: 'DateField' },
  { name: 'DefendantCharges' },
  { name: 'DepartmentSelector' },
  { name: 'DossierCaseSelector' },
  { name: 'DossierLookupField' },
  { name: 'FeeAssessment' },
  { name: 'FeePayment' },
  { name: 'GeographySelector' },
  { name: 'HeadOfCourtSelector' },
  { name: 'IssueSummons' },
  { name: 'IntField' },
  { name: 'JudgeSelector' },
  { name: 'LookupTable' },
  { name: 'ParticipantDecisionEditor' },
  { name: 'PartySelector' },
  { name: 'PhoneField' },
  { name: 'RemoveParty' },
  { name: 'RemoveSubjectProperty' },
  { name: 'SubjectPropertySelector' },
  { name: 'SubjectPropertyEditor' },
  { name: 'TextField' },
];
