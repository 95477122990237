var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('transfer.statusOptions.' + _vm.status))+" ")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","no-data-text":_vm.$t('error.nodata')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('Date',{attrs:{"value":item.departedAt || item.scheduledDepartureAt}})],1),_c('td',[_c('Date',{attrs:{"value":item.estimatedArrivalAt}})],1),_c('td',[_c('router-link',{attrs:{"to":{
                name: 'offenderHousing',
                params: { id: item.offenderID },
              }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.facilityName(_vm.facilityIDForDisplay(item))))]),_c('td',[_vm._v(" "+_vm._s(_vm.nameForIDInCollection(item.reasonID, _vm.transferReasons))+" ")]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('TransferActions',{attrs:{"transferID":item.id,"offenderID":item.offenderID}}),_c('ExpanderDialog',{attrs:{"title":_vm.$t('transfer.singular')}},[_c('v-row',[(item.status)?_c('LabeledData',{attrs:{"cols":"6","md":"6","label":_vm.$t('transfer.status'),"value":_vm.translateStatus(item.status)}}):_vm._e(),(item.isDetainee)?_c('LabeledData',{attrs:{"cols":"6","md":"6","label":_vm.$t('offender.din'),"value":item.din}}):_vm._e(),(!item.isDetainee)?_c('LabeledData',{attrs:{"cols":"6","md":"6","label":_vm.$t('offender.pin'),"value":item.pin}}):_vm._e()],1),(item.scheduledDepartureAt || item.estimatedArrivalAt)?_c('v-row',[(item.scheduledDepartureAt)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.scheduledDepartureAt')}},[_c('Date',{attrs:{"value":item.scheduledDepartureAt}})],1):_vm._e(),(item.estimatedArrivalAt)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.estimatedArrivalAt')}},[_c('Date',{attrs:{"value":item.estimatedArrivalAt}})],1):_vm._e()],1):_vm._e(),(item.departedAt || item.arrivedAt)?_c('v-row',[(item.departedAt)?_c('LabeledData',{attrs:{"cols":"6","md":"6","label":_vm.$t('transfer.departedAt')}},[_c('Date',{attrs:{"value":item.departedAt}})],1):_vm._e(),(item.arrivedAt)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.arrivedAt')}},[_c('Date',{attrs:{"value":item.arrivedAt}})],1):_vm._e()],1):_vm._e(),(
                    item.fromHousingFacilityID || item.toHousingFacilityID
                  )?_c('v-row',[(item.fromHousingFacilityID)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.fromHousingFacility'),"value":_vm.facilityName(item.fromHousingFacilityID)}}):_vm._e(),(item.toHousingFacilityID)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.toHousingFacility'),"value":_vm.facilityName(item.toHousingFacilityID)}}):_vm._e()],1):_vm._e(),(item.fromCellID || item.toCellID)?_c('v-row',[(item.fromCellID)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.fromCell'),"value":_vm.cellName(item.fromCellID)}}):_vm._e(),(item.toCellID)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.toCell'),"value":_vm.cellName(item.toCellID)}}):_vm._e()],1):_vm._e(),(item.reasonID || item.notes)?_c('v-row',[(item.reasonID > 0)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.reason'),"value":_vm.nameForIDInCollection(item.reasonID, _vm.transferReasons)}}):_vm._e(),(item.notes)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('notes'),"value":item.notes}}):_vm._e()],1):_vm._e(),(item.cancelDate || item.cancelReason)?_c('v-row',[(item.cancelDate)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.cancelDate')}},[_c('Date',{attrs:{"value":item.cancelDate}})],1):_vm._e(),(item.cancelReason)?_c('LabeledData',{attrs:{"cols":"6","md":"6","sm":"12","label":_vm.$t('transfer.cancelReason'),"value":item.cancelReason}}):_vm._e()],1):_vm._e()],1)],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }