import _ from 'lodash';
import Vue from 'vue';
import { TranslatedString, TUnknown } from '@/lib/translated';

import api from '@/api';
import i18n from '@/i18n';
import { domainmodelMutations, modelsToState } from '@/lib/vuex-domainmodel';

export interface HousingFacility {
  id: number;
  type: string;
  name: TranslatedString;
  geographyID: number;
  canHoldJuveniles: boolean;
  canHoldAdults: boolean;
  canHoldDetainees: boolean;
  canHoldPrisoners: boolean;
  canHoldFemales: boolean;
  canHoldMales: boolean;
  acceptsTransfers: boolean;
  path: string;
  capacity: number;
  parentID?: number;
  hidden: boolean;
  legacyIDs: number[];
}

export interface Leave {
  id: number;
  offenderID: number;
  status: string;
  housingFacilityID: number;
  scheduledReleaseDate: string;
}

// Feel free to change - for intake demo
export interface Cell {
  id: number;
  name: TranslatedString;
}

const state = {
  facilitiesLoaded: false,
  facilityIDs: [],

  facility: {} as { [id: number]: HousingFacility },
  childIDs: {} as { [id: number]: number[] },
};

const getters = {
  /**
   * allFacilities retrieves the complete array of HousingFacility objects
   * known to Vuex.
   */
  allFacilities(state): HousingFacility[] {
    return state.facilityIDs.map((id) => state.facility[id]);
  },

  /**
   * transferrableFacilities computes the subset of allFacilities which allow
   * an incoming Transfer.
   */
  transferrableFacilities(state, getters): HousingFacility[] {
    return getters.allFacilities.filter(
      (facility) => facility.acceptsTransfers,
    );
  },

  /**
   * facilityWithID is a convenience getter to get a HousingFacility object
   * from its ID. The facility must already be loaded in the Vuex state.
   */
  facilityWithID:
    (state) =>
    (id): HousingFacility => {
      return state.facility[id];
    },

  /**
   * facilitiesUnder retrieves the array of HousingFacility objects which are
   * direct children of the provided facility ID. Passing no ID retrieves the
   * facilities at the root level.
   */
  facilitiesUnder:
    (state) =>
    (parentID: number): HousingFacility[] => {
      const facilities: HousingFacility[] = Object.values(state.facility);
      return _.filter(facilities, (f: HousingFacility) => {
        return (f.parentID || 0) === (parentID || 0);
      });
    },

  /**
   * facilityChildren
   * TODO Replace usages of this with facilitiesUnder instead. Looks like
   * * CellSelector uses this, while HousingFacilityContainer
   * uses facilitiesUnder.
   */

  facilityChildren:
    (state) =>
    (parentID): HousingFacility[] => {
      return (Object.values(state.facility) as HousingFacility[]).filter(
        (f) => f.parentID === parentID,
      );
    },

  /**
   * facilityVisibleChildren computes the subset of children under the provided
   * facilityID which are visible. This is used by CellSelector and seems like
   * it could be refactored to use facilitiesUnder with an internal reject
   * inside that component.
   *
   * TODO Remove
   */
  facilityVisibleChildren:
    (state) =>
    (parentID): HousingFacility[] => {
      return (Object.values(state.facility) as HousingFacility[]).filter(
        (f) => f.parentID === parentID && !f.hidden,
      );
    },

  /**
   * facilityAncestors computes an array of the provided Facility preceded by
   * all of its ancestors:
   *
   * [{GrandParent Object}, {Parent Object}, {My Object}]
   *
   * This getter is the full-object equivalent of ancestorIDs.
   */
  facilityAncestors:
    (state, getters) =>
    (id: number): HousingFacility[] => {
      const ids = getters.ancestorIDs(id);
      return _.chain(ids)
        .compact()
        .map((id) => state.facility[id])
        .compact()
        .value();
    },

  /**
   * ancestorIDs computes an array of the provided Facility ID preceded by all
   * of the IDs of its ancestors:
   *
   * [grandParentID, parentID, myID]
   *
   * TODO Rename to facilityAncestorIDs
   */
  ancestorIDs:
    (state) =>
    (id: number): number[] => {
      const ids = [] as number[];
      let currID = id;
      while (state.facility[currID]) {
        ids.unshift(currID);
        currID = state.facility[currID].parentID;
      }
      return ids;
    },

  /**
   * facilityName computes the translated name of a housing facility, not
   * including any ancestors or other structure.
   */
  facilityName:
    (state) =>
    (id): string => {
      const facility = state.facility[id];
      if (!facility) {
        return TUnknown[i18n.locale];
      } else {
        return facility.name[i18n.locale];
      }
    },

  /**
   * fullFacilityName computes the fully-qualified name of a facility, with
   * all parts translated and separated by slashes:
   *
   * GrandParent Name / Parent Name / My Name
   */
  fullFacilityName:
    (state, getters) =>
    (id: number): string => {
      const breadcrumb = getters.facilityAncestors(id);
      return breadcrumb.map((f) => f.name[i18n.locale]).join(' / ');
    },

  /**
   * cellName computes the translated name of a cell within a Housing Facility.
   * It returns Unknown
   */
  cellName:
    (state, getters) =>
    (id): string => {
      const facility = state.facility[id];
      if (!facility) {
        return TUnknown[i18n.locale];
      }

      const cellName = getters.fullCellName(facility.id);
      return cellName;
    },

  fullCellName:
    (state, getters) =>
    (facilityID): string => {
      const facility = getters.facilityWithID(facilityID);
      const parentFacilityID = facility.parentID;

      /*
    / Need to check for type !== 'facility' in order to avoid printing the topmost
    / facility name.
    */
      if (
        parentFacilityID &&
        getters.facilityWithID(parentFacilityID).type !== 'facility'
      ) {
        return `${getters.fullCellName(parentFacilityID)} - ${
          facility.name[i18n.locale]
        }`;
      } else {
        return `${facility.name[i18n.locale]}`;
      }
    },
};

const mutations = {
  ...domainmodelMutations,

  setFacilitiesLoaded(state, val: boolean) {
    state.facilitiesLoaded = val;
  },

  setFacilities(state, facilities: HousingFacility[]) {
    facilities.forEach((facility) => {
      Vue.set(state.facility, facility.id, facility);
    });

    const ids = _.values(state.facility)
      .filter((f) => f.type === 'facility')
      .map((f) => f.id);
    state.facilityIDs = ids;
  },

  setFacilityChildren(
    state,
    payload: { parentID: number; children: HousingFacility[] },
  ) {
    const ids = [] as number[];
    for (const facility of payload.children) {
      ids.push(facility.id);
      Vue.set(state.facility, facility.id, facility);
    }
    Vue.set(state.childIDs, payload.parentID, ids);
  },

  removeFacility(state, id: number) {
    const index = state.facilityIDs.indexOf(id);
    if (index !== -1) {
      state.facilityIDs.splice(index, 1);
    }
    Vue.delete(state.facility, id);
  },
};

const actions = {
  /**
   * fetchFacilities loads the "'facility' facilities" (i.e. the top level
   * facilities which can accept transfers). This function fetches data from
   * the server when necessary or when the force parameter is set to true.
   * Otherwise it returns cached data.
   */
  async fetchFacilities({ commit, state }, force: boolean): Promise<void> {
    if (!force && state.facilitiesLoaded) {
      return Promise.resolve();
    }
    const response = await api.get(
      `/housingservice/facilities?acceptsTransfers=true&includeAncestors=true`,
    );
    const facilities = response.data.data;
    const newState = modelsToState('facility', facilities);
    commit('setState', newState);
    commit('setTarget', {
      target: 'facilityIDs',
      value: _.map(facilities, 'id'),
    });
    commit('setFacilitiesLoaded', true);
  },

  /**
   * fetchFacility ensures that the Vuex store has populated data for the facility
   * facility provided and for all of its ancestor facilities.
   *
   * It retrieves data from the server when necessary or when
   * the force parameter is set to true. Otherwise cached data is used.
   *
   * The return value is a promise which resolves to the single requested
   * HousingFacility object.
   */
  async fetchFacility(
    { commit, state },
    command: { force: boolean; facilityID: number },
  ): Promise<HousingFacility> {
    if (!command.force && state.facility[command.facilityID]) {
      return Promise.resolve(state.facility[command.facilityID]);
    }
    const response = await api.get(
      `/housingservice/facilities/${command.facilityID}/with-ancestors`,
    );
    commit('setState', response.data.data);
    return Promise.resolve(state.facility[command.facilityID]);
  },

  /**
   * fetchFacilityChildren ensures that the Vuex store has populated data for
   * all child facilities (blocks, wings, etc) for the provided parentID.
   * It retrieves the data from the API server if necessary or if the force
   * parameter is set to true. Otherwise cached data is used.
   *
   * The return value is a promise which resolves to the array of child
   * HousingFacility objects.
   */
  // tslint:disable-next-line: max-line-length
  async fetchFacilityChildren(
    { commit, state, getters },
    command: { force: boolean; parentID: number },
  ): Promise<HousingFacility[]> {
    if (command.parentID === 0) {
      // Unknown facilities always have no children
      return Promise.resolve([]);
    }
    if (!command.force && Array.isArray(state.childIDs[command.parentID])) {
      // Return a cached result unless forced to fetch
      return Promise.resolve(getters.facilityChildren(command.parentID));
    }
    const response = await api.get(
      `/housingservice/facilities?parentIDs=${command.parentID}`,
    );
    const children = response.data.data;
    commit('setFacilityChildren', { parentID: command.parentID, children });
    return Promise.resolve(getters.facilityChildren(command.parentID));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
