




import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import store from '@/vuex/store';

/*
 * Batch Queue Fetcher
 * This little block of code prevents this Component from hammering the server
 * with many fetchGeography requested when it is used many times on the same
 * page at the same time. Because this function and its variables live outside
 * the Vue component, they are shared globally for all instances of this
 * component.
 *
 * When this component receives a new id, the watcher checks to see if that
 * Geography is already in state. If it's not, instead of fetching it
 * immediately, it adds it to a queue of ids to be fetched, and then triggers
 * a debounced function which will perform the actual API call to fetch the
 * queued batch of IDs. The debounce guarantees that the fetch happens only
 * once every N milliseconds. This way when many components appear on the
 * screen in quick succession, we only hit the server once to get the missing
 * Geographies.
 */
const maxFetchDelayMs = 200;
let loading = false;
const fetchQueue: number[] = [];
const fetcher = _.debounce(async () => {
  const ids = _.chain(fetchQueue).compact().uniq().value();
  fetchQueue.splice(0, fetchQueue.length); // Empty the queue
  await store.dispatch('geography/fetchGeography', ids);
  loading = false;
}, maxFetchDelayMs);

export default Vue.extend({
  props: {
    id: Number,
    full: Boolean,
  },
  computed: {
    ...mapGetters('geography', ['geographyName', 'fullGeographyName']),
    ...mapState('geography', ['geography']),

    name(): string {
      const name = this.geographyName(this.id);
      return this.exists ? name : loading ? '' : name;
    },

    fullName(): string {
      const fullName = this.fullGeographyName(this.id);
      return this.exists ? fullName : loading ? '' : fullName;
    },

    title(): string {
      return this.name === this.fullName ? '' : this.fullName;
    },

    exists(): boolean {
      return !!this.geography[this.id];
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(geoID: number) {
        if (!geoID) {
          // No need to queue a fetch for the Unknown geography
          return;
        }

        if (!this.geography[geoID]) {
          // Enqueue and fetch missing Geography IDs
          fetchQueue.push(geoID);
          loading = true;
          fetcher();
        }
      },
    },
  },
});
