export interface TranslatedString {
  [key: string]: string;
}

export const TUnknown: TranslatedString = {
  en: 'Unknown',
  fa: 'ناشناس',
  ps: 'نامعلوم',
};

export const TAll: TranslatedString = {
  en: 'All',
  fa: 'همه',
  ps: 'ټول',
};

export const TNone: TranslatedString = {
  en: 'None',
  fa: 'هیچ یک',
  ps: 'هیڅ نه',
};

export function blankTranslatedString(): TranslatedString {
  return { en: '', fa: '', ps: '' };
}
