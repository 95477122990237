















import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { HousingFacility } from '@/vuex/housing/housing';

export default Vue.extend({
  props: {
    id: Number,
    to: Object,
    facilityParamName: {
      type: String,
      default: 'facilityID',
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'hasAllFacilities']),
    ...mapGetters('housing', ['facilityAncestors']),

    ancestors(): HousingFacility[] {
      return this.facilityAncestors(this.id) || [];
    },

    items(): any[] {
      const items = _.map(this.ancestors, (dept) => {
        const item: any = Object.assign({}, dept);
        if (this.to && this.id !== item.id) {
          if (this.hasPermission('dashboard.prison', 'facility', item.id)) {
            item.to = _.cloneDeep(this.to);
            _.set(item, `to.query.${this.facilityParamName}`, dept.id);
          }
        }
        return item;
      });

      /**
       * The root of the Facility hierarchy is only accessible to users who
       * have been assigned a role in all facilities.
       */
      if (this.hasAllFacilities) {
        const rootItem = { to: _.cloneDeep(this.to) };
        _.set(rootItem, `to.query.${this.facilityParamName}`, 0);
        items.splice(0, 0, rootItem);
      }
      return items;
    },
  },
});
