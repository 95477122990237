

















import Vue from 'vue';
import i18n from '@/i18n';
import { Offender } from '@/vuex/offender/offender';

export default Vue.extend({
  props: {
    offenderID: Number,
    isLabeledData: Boolean,
  },
  computed: {
    offender(): Offender {
      return this.$store.getters['offender/offenderWithID'](this.offenderID);
    },

    custodyStatus(): string {
      if (!this.offender) {
        return '';
      }

      return i18n
        .t('offender.custodyStatus.' + this.offender.custodyStatus)
        .toString();
    },
  },
});
