import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css';
import Cookies from 'js-cookie';

import GlobalVariables from '@/lib/global-variables';

import store from '@/vuex/store';
import router from '@/router';
import i18n from '@/i18n';
import App from '@/App.vue';

Vue.config.productionTip = false;

// -----------------------------------------------------------------------------
// Globally-Registered Components
//
import AFNCurrencyField from '@/components/global/currency/AFNCurrencyField.vue';
import AFNDisplay from '@/components/global/currency/AFNDisplay.vue';
import AgeDisplay from '@/components/global/date/AgeDisplay.vue';
import AddAttachmentDialog from '@/components/global/attachments/AddAttachmentDialog.vue';
import AddImageAttachmentDialog from '@/components/global/attachments/AddImageAttachmentDialog.vue';
import AttachmentImage from '@/components/global/attachments/AttachmentImage.vue';
import AttachmentsLinks from '@/components/global/attachments/AttachmentsLinks.vue';
import AttachmentsList from '@/components/global/attachments/AttachmentsList.vue';
import BirthdateDisplay from '@/components/global/date/BirthdateDisplay.vue';
import BirthdateSelector from '@/components/global/date/BirthdateSelector.vue';
import BloodTypeSelector from '@/components/global/form-fields//BloodTypeSelector.vue';
import BooleanDisplay from '@/components/global/formatting/BooleanDisplay.vue';
import BooleanSelector from '@/components/global/form-fields//BooleanSelector.vue';
import BooleanRadioButton from '@/components/global/form-fields//BooleanRadioButton.vue';
import CMSFormDialog from '@/components/global/CMSFormDialog.vue';
import ConfirmationButton from '@/components/global/ConfirmationButton.vue';
import CourtBreadcrumb from '@/components/global/court/CourtBreadcrumb.vue';
import CourtName from '@/components/global/court/CourtName.vue';
import Date from '@/components/global/date/Date.vue';
import DaysAgoSelector from '@/components/global/date/DaysAgoSelector.vue';
import DepartmentBreadcrumb from '@/components/global/department/DepartmentBreadcrumb.vue';
import DepartmentName from '@/components/global/department/DepartmentName.vue';
import DepartmentSelector from '@/components/global/department/DepartmentSelector.vue';
import DocketEventTypeSelector from '@/components/global/form-fields/DocketEventTypeSelector.vue';
import DossierNumber from '@/components/global/DossierNumber.vue';
import DossierNumberField from '@/components/global/form-fields//DossierNumberField.vue';
import Duration from '@/components/global/date/Duration.vue';
import ETazkiraField from '@/components/global/form-fields//ETazkiraField.vue';
import ExpanderDialog from '@/components/global/formatting/ExpanderDialog.vue';
import ExpandableCard from '@/components/global/formatting/ExpandableCard.vue';
import FormError from '@/components/global/form-fields//FormError.vue';
import FieldValue from '@/components/global/formatting/FieldValue.vue';
import GeographyBreadcrumb from '@/components/global/geography/GeographyBreadcrumb.vue';
import GeographyName from '@/components/global/geography/GeographyName.vue';
import GeographySelector from '@/components/global/geography/GeographySelector.vue';
import HousingFacilityBreadcrumb from '@/components/global/offender/HousingFacilityBreadcrumb.vue';
import HousingFacilityName from '@/components/global/offender/HousingFacilityName.vue';
import ImprisonmentDurationField from '@/components/global/form-fields/ImprisonmentDurationField.vue';
import ImprisonmentDurationsLegend from '@/components/global/offender/ImprisonmentDurationsLegend.vue';
import IntArrayField from '@/components/global/form-fields//IntArrayField.vue';
import IntField from '@/components/global/form-fields//IntField.vue';
import LabeledData from '@/components/global/formatting/LabeledData.vue';
import LandArea from '@/components/global/formatting/LandArea.vue';
import LandAreaField from '@/components/global/form-fields/LandAreaField.vue';
import LegacyDepartmentSelector from '@/components/global/department/LegacyDepartmentSelector.vue';
import LocaleDateEntry from '@/components/global/date/LocaleDateEntry.vue';
import LocaleDateRange from '@/components/global/date/LocaleDateRange.vue';
import LookupTableItemSelector from '@/components/global/form-fields/LookupTableItemSelector.vue';
import LookupTableTypeSelector from '@/components/global/form-fields/LookupTableTypeSelector.vue';
import NumFmt from '@/components/global/formatting/NumFmt.vue';
import PhoneField from '@/components/global/form-fields//PhoneField.vue';
import ProvinceMap from '@/components/global/geography/ProvinceMap.vue';
import ReleaseDate from '@/components/global/offender/ReleaseDate.vue';
import StringArray from '@/components/global/form-fields//StringArray.vue';
import TimeField from '@/components/global/date/TimeField.vue';
import TranslatedStringArrayField from '@/components/global/form-fields//TranslatedStringArrayField.vue';
import Truncate from '@/components/global/formatting/Truncate.vue';
import Weight from '@/components/global/formatting/Weight.vue';
import WeightField from '@/components/global/form-fields//WeightField.vue';
Vue.component('AFNCurrencyField', AFNCurrencyField);
Vue.component('AFNDisplay', AFNDisplay);
Vue.component('AgeDisplay', AgeDisplay);
Vue.component('AddImageAttachmentDialog', AddImageAttachmentDialog);
Vue.component('AddAttachmentDialog', AddAttachmentDialog);
Vue.component('AttachmentImage', AttachmentImage);
Vue.component('AttachmentsLinks', AttachmentsLinks);
Vue.component('AttachmentsList', AttachmentsList);
Vue.component('BirthdateDisplay', BirthdateDisplay);
Vue.component('BirthdateSelector', BirthdateSelector);
Vue.component('BloodTypeSelector', BloodTypeSelector);
Vue.component('BooleanDisplay', BooleanDisplay);
Vue.component('BooleanRadioButton', BooleanRadioButton);
Vue.component('BooleanSelector', BooleanSelector);
Vue.component('CMSFormDialog', CMSFormDialog);
Vue.component('ConfirmationButton', ConfirmationButton);
Vue.component('CourtBreadcrumb', CourtBreadcrumb);
Vue.component('CourtName', CourtName);
Vue.component('Date', Date);
Vue.component('DaysAgoSelector', DaysAgoSelector);
Vue.component('DepartmentBreadcrumb', DepartmentBreadcrumb);
Vue.component('DepartmentName', DepartmentName);
Vue.component('DepartmentSelector', DepartmentSelector);
Vue.component('DocketEventTypeSelector', DocketEventTypeSelector);
Vue.component('DossierNumber', DossierNumber);
Vue.component('DossierNumberField', DossierNumberField);
Vue.component('Duration', Duration);
Vue.component('ETazkiraField', ETazkiraField);
Vue.component('ExpandableCard', ExpandableCard);
Vue.component('ExpanderDialog', ExpanderDialog);
Vue.component('FieldValue', FieldValue);
Vue.component('FormError', FormError);
Vue.component('GeographyBreadcrumb', GeographyBreadcrumb);
Vue.component('GeographyName', GeographyName);
Vue.component('GeographySelector', GeographySelector);
Vue.component('HousingFacilityBreadcrumb', HousingFacilityBreadcrumb);
Vue.component('HousingFacilityName', HousingFacilityName);
Vue.component('ImprisonmentDurationField', ImprisonmentDurationField);
Vue.component('ImprisonmentDurationsLegend', ImprisonmentDurationsLegend);
Vue.component('IntArrayField', IntArrayField);
Vue.component('IntField', IntField);
Vue.component('LabeledData', LabeledData);
Vue.component('LandArea', LandArea);
Vue.component('LandAreaField', LandAreaField);
Vue.component('LegacyDepartmentSelector', LegacyDepartmentSelector);
Vue.component('LocaleDateEntry', LocaleDateEntry);
Vue.component('LocaleDateRange', LocaleDateRange);
Vue.component('LookupTableItemSelector', LookupTableItemSelector);
Vue.component('LookupTableTypeSelector', LookupTableTypeSelector);
Vue.component('NumFmt', NumFmt);
Vue.component('ProvinceMap', ProvinceMap);
Vue.component('PhoneField', PhoneField);
Vue.component('ReleaseDate', ReleaseDate);
Vue.component('StringArray', StringArray);
Vue.component('TimeField', TimeField);
Vue.component('TranslatedStringArrayField', TranslatedStringArrayField);
Vue.component('Truncate', Truncate);
Vue.component('Weight', Weight);
Vue.component('WeightField', WeightField);

// Initialize rtl vuetify settings from cookies.
// the cookie may not be present; and either way it's a string which needs
// to be converted to a boolean.
const isRTLCookie = Cookies.get(GlobalVariables.RTL_COOKIE_NAME);
const isRTL = isRTLCookie && isRTLCookie === 'true';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
  rtl: isRTL,
  theme: {
    themes: {
      dark: {
        primary: '#898989',
        accent: '#00AD4D',
        secondary: '#54D97D',
        success: '#009900',
        info: '#AEAEAE',
        warning: '#F19A02',
        error: '#D31111',
      },
      light: {
        primary: '#898989',
        accent: '#007A36',
        secondary: '#104721',
        success: '#009900',
        info: '#AEAEAE',
        warning: '#F19A02',
        error: '#D31111',
      },
    },
  },
});

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import VueGoogleCharts from 'vue-google-charts';
Vue.use(VueGoogleCharts);

Vue.mixin({
  methods: {
    /**
     * $pluralT is similar to the i18n $t function, except that it decides between
     * a singular and plural locale key. For example, you could call:
     *
     * $pluralT('attorney', 1) => $t('attorney.singular') => Attorney
     * $plural('attorney', 4) => $t('attorney.plural') => Attorneys
     *
     * @param localeKey A translation key prefix which has both .singular and .plural variants.
     * @param count The number of items we have
     * @returns The translated value of either the singular or plural as appropriate.
     */
    $pluralT(localeKey: string, count = 1 as number): string {
      const key = count === 1 ? `${localeKey}.singular` : `${localeKey}.plural`;
      return `${i18n.t(key)}`;
    },
  },
});

export default new Vue({
  router,
  store,
  i18n,
  ...{ vuetify },
  render: (h) => h(App),
}).$mount('#app');
