import _ from 'lodash';
import investigationAPI from '@/vuex/investigation/investigationAPI';
import { modelsToState } from '@/lib/vuex-domainmodel';

export interface PardonDecree {
  id: number;
  number: number;
  title: string;
  summary: string;
  issuedAt: string;
  enforcementBeginsAt: string;
  isDeleted: boolean;
}

export const state = {
  pardonDecreeIDs: [],
  pardonDecree: {} as { [id: number]: PardonDecree },
  pardonDecreesLoaded: false,
};

export const getters = {
  pardonDecreeWithID:
    (state) =>
    (id: number): PardonDecree | null => {
      return state.pardonDecree[id] || null;
    },
  allPardonDecrees(state): PardonDecree[] {
    return _.map(state.pardonDecreeIDs, (id: number) => state.pardonDecree[id]);
  },
};

export const mutations = {
  /**
   * setPardonDecreesLoaded sets the tracking variable which identifies
   * whether the decres have been successfully fetched from the
   * API or not.
   */
  setPardonDecreesLoaded(state, val: boolean) {
    state.pardonDecreesLoaded = val;
  },
};

export const actions = {
  /**
   * fetchPardonDecrees fetches all dropdown choices for entities in this Vuex
   * store from the API.
   *
   * Most of the time it should be called as fetchPardonDecrees({ force: false }),
   * to avoid re-fetching decrees from the API that have already been
   * loaded.
   */
  async fetchPardonDecrees(
    { commit, state },
    payload = {} as { force: boolean },
  ): Promise<void> {
    // Early return when the pardonDecrees are already loaded
    if (!payload.force && state.pardonDecreesLoaded) {
      return Promise.resolve();
    }

    // Fetch all pardonDecrees from the API
    const response = await investigationAPI.get('/pardon-decrees');
    const pardonDecrees = response.data.data;
    const newState = modelsToState('pardonDecree', pardonDecrees);

    commit('setState', newState);
    commit('setTarget', {
      target: 'pardonDecreeIDs',
      value: _.map(pardonDecrees, 'id'),
    });

    commit('setPardonDecreesLoaded', true);
  },
  async fetchPardonDecree({ commit }, id: number) {
    const response = await investigationAPI.get(`/pardon-decrees/${id}`);
    const pardonDecree = response.data.data;

    const newState = modelsToState('pardonDecree', pardonDecree);
    commit('setState', newState);
    commit('setTarget', {
      target: 'pardonDecree',
      index: pardonDecree.id,
      value: pardonDecree,
    });
  },
};
