
















import _ from 'lodash';
import Vue from 'vue';

import { mapGetters } from 'vuex';
import { SubjectProperty } from '@/vuex/court/subjectProperty';

import SubjectPropertyLabel from '@/components/subjectProperty/SubjectPropertyLabel.vue';
import SubjectPropertyCard from '@/components/subjectProperty/SubjectPropertyCard.vue';

export default Vue.extend({
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters('court', ['subjectPropertyWithID']),
    payload(): any {
      return this.entry.payload;
    },
    subjectPropertyID(): string {
      if (!this.entry.affectedRecords) {
        return '';
      }
      return _.first(this.entry.affectedRecords.subjectPropertyIDs) || '';
    },
    subjectProperty(): SubjectProperty | null {
      if (!this.subjectPropertyID) {
        return null;
      }
      return this.subjectPropertyWithID(this.subjectPropertyID);
    },
  },
  components: {
    SubjectPropertyCard,
    SubjectPropertyLabel,
  },
});
