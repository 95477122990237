import _ from 'lodash';
import Vue from 'vue';
import { TranslatedString } from '@/lib/translated';

import api from '@/api';

export interface Corpus {
  id: number;
  title: TranslatedString;
  number: 0;
  parentID: null | number;
  isNonchargeable: boolean;
}

export function blankCorpus(): Corpus {
  return {
    id: 0,
    title: {} as TranslatedString,
    number: 0,
    parentID: null,
    isNonchargeable: false,
  };
}

const state = {
  loaded: false,
  corporaIDs: [] as number[],
  corporaByID: {} as { [id: number]: Corpus },
  corpusHasChildren: {} as { [id: number]: boolean },
};

const getters = {
  corpora(state: any): Corpus[] {
    return _.sortBy(
      state.corporaIDs
        .map((id: number) => state.corporaByID[id])
        .filter((corpus: Corpus) => corpus.parentID == null),
      'number',
    );
  },
  corporaWithParentID: (state) => (parentID: number | null) => {
    return state.corporaIDs
      .map((id: number) => state.corporaByID[id])
      .filter((corpus: Corpus) => corpus.parentID === parentID);
  },
  corpusHasChildren: (state) => (id: number | null) => {
    if (id === null) {
      return true;
    }
    return state.corpusHasChildren[id];
  },
  corpusWithID: (state) => (id) => {
    return state.corporaByID[id];
  },
};

const mutations = {
  setLoaded(state: any, val: boolean) {
    state.loaded = val;
  },
  setCorpus(state: any, corpus: Corpus) {
    if (!state.corporaByID.hasOwnProperty(corpus.id)) {
      state.corporaIDs.push(corpus.id);
    }
    Vue.set(state.corporaByID, corpus.id, corpus);
    if (corpus.parentID !== null) {
      Vue.set(state.corpusHasChildren, corpus.parentID, true);
    }
  },
  removeCorpus(state: any, id: number) {
    const index = state.corporaIDs.indexOf(id);
    if (index !== -1) {
      state.corporaIDs.splice(index, 1);
    }
    Vue.delete(state.corporaByID, id);
  },
};

const actions = {
  async fetchCorpora({ commit, state }, force: boolean): Promise<void> {
    if (!force && state.loaded) {
      return Promise.resolve();
    }
    const response = await api.get('/corpusservice/corpora');
    const corpora = response.data.data;
    corpora.forEach((corpus) => {
      commit('setCorpus', corpus);
    });
    commit('setLoaded', true);
  },
  async fetchCorpus({ commit }, id: number): Promise<void> {
    const response = await api.get(`/corpusservice/corpus/${id}`);
    commit('setCorpus', response.data.data);
  },
  async createCorpus({ commit }, corpus): Promise<void> {
    const response = await api.post('/corpusservice/corpora', { data: corpus });
    commit('setCorpus', response.data.data);
  },
  async saveCorpus({ commit }, corpus): Promise<void> {
    try {
      const response = await api.put(`/corpusservice/corpus/${corpus.id}`, {
        data: corpus,
      });
      commit('setCorpus', response.data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteCorpus({ commit }, id: number): Promise<void> {
    try {
      await api.delete(`/corpusservice/corpus/${id}`);
      commit('removeCorpus', id);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
