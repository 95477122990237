
























































































































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import userAPI from '@/vuex/user/userAPI';

import { criteriaToQueryString } from '@/lib/criteria';
import { User } from '@/vuex/user/user';

import UserSelector from '@/components/user/UserSelector.vue';

export default Vue.extend({
  created(): void {
    this.fetchData();
    // @ts-ignore https://github.com/lodash/lodash/issues/4700
    this.fetchData = _.debounce(this.fetchData, 400);
  },
  data() {
    return {
      loading: false,
      error: null as any,

      criteria: {
        userID: 0,
        submittedAtOrAfter: '',
        submittedAtOrBefore: '',
      },

      searchResults: [] as any[],

      // Pagination Attributes
      currentPage: 1,
      serverTotalResults: 0,
      pageSize: 10,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('user', ['userWithID']),

    /**
     * queryString computes the query string component of the search API call.
     * It includes pagination parameters controlled by the v-data-table props,
     * but only if they are not the only parameters.
     */
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    /**
     * canExport computes whether the current user is allowed to download
     * an Excel export of the data on the screen.
     */
    canExport(): boolean {
      return this.hasPermission('admin.userAuditReport');
    },

    footerProps(): any {
      const opts = {
        itemsPerPageOptions: [10, 20, 40, 50, 100],
        itemsPerPageText: this.$i18n.t('uiElements.rowsPerPage'),
      };
      return opts;
    },

    headers(): any[] {
      const h: any[] = [
        {
          text: i18n.t('date'),
          value: 'submittedAt',
          width: '10em',
        },
        {
          text: i18n.t('action.singular'),
          value: 'actionType',
        },
        {
          text: i18n.t('docketEventType.singular'),
          value: 'docketEventType',
        },
        {
          text: this.$i18n.t('dossier.dossierNumber'),
          value: 'dossierNumber',
        },
        {
          value: 'payload',
        },
      ];

      return h;
    },
    user(): User {
      return this.userWithID(this.criteria.userID);
    },
  },

  watch: {
    /**
     * queryString is watched so that a new search can be triggered.
     * We also reset back to page #1 anytime a new search is triggered.
     */
    queryString(newVal: string) {
      this.currentPage = 1;
      this.fetchData();
    },
  },

  methods: {
    /**
     * fetchData is called when the search button is pressed or when any table
     * pagination setting is changed. It refreshes the locally-held search
     * results with the current page of results from the server.
     */
    async fetchData(): Promise<void> {
      this.loading = true;

      const args = [] as string[];
      args.push(`page[size]=${this.pageSize}`);
      args.push(`page[number]=${this.currentPage}`);
      args.push(this.queryString);

      try {
        const url = `/users/audit-report?${args.join('&')}`;
        const res = await userAPI.get(url);
        this.serverTotalResults = _.get(res, 'data.meta.totalResults', 0);
        const results = _.get(res, 'data.data', []);
        this.searchResults = _.map(results, (r) => {
          return Object.assign(r, {
            docketEventType:
              this.valueForLocale(r.docketEventType) || i18n.t('na'),
            payloadExpanded: false,
          });
        });
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    UserSelector,
  },
});
