





























import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: Array,
    label: String,
    cols: {
      type: [Number, String],
      default: 3,
    },
  },
  mounted() {
    if (this.items.length < 1) {
      this.addItem();
    }
  },
  data() {
    return {
      items: this.value || [''],
    };
  },
  watch: {
    value(newVal: string[]) {
      // Internally, we want one extra blank item when the external array is
      // empty.
      const internalItems = [...newVal];
      if (internalItems.length === 0) {
        internalItems.push('');
      }

      // Don't apply the new value if it doesn't differ from what we already
      // have.
      if (!_.isEqual(this.items, internalItems)) {
        this.items = internalItems;
      }
    },
    items(newItems: string[]) {
      // Externally, we want to remove any blank items, so we compact the
      // internal array before emitting.
      const externalItems = _.compact([...newItems]);

      // Before emitting, we check that it's not a no-op emit. This helps
      // prevent the value and items watchers from continuously feeding
      // each other in an ouroboros.
      if (!_.isEqual(this.value, externalItems)) {
        this.$emit('input', externalItems);
      }
    },
  },
  methods: {
    addItem() {
      this.items.push('');
    },
    removeItem(i: number) {
      this.items.splice(i, 1);
    },
  },
  components: {},
});
