























import Vue from 'vue';
export default Vue.extend({
  props: {
    value: Boolean,
    label: String,
    unknownLabel: String,
    trueLabel: String,
    falseLabel: String,
    readonly: Boolean,
    hint: String,
    persistentHint: Boolean,
    rules: Array,
    loading: Boolean,
    hideDetails: Boolean,
    excludeUnknown: Boolean,
    row: Boolean,
  },
  data() {
    return {
      /**
       * internalValue always holds a string, regardless of the type of the
       * external value. This helps us data-bind to a 3-value boolean field
       * (one which can be null, false, or true).
       */
      internalValue: 'null',
    };
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler(newVal: any): void {
        switch (newVal) {
          case null:
          case undefined:
            if (this.excludeUnknown) {
              this.internalValue = 'false';
              // Correct malformatted input
              this.$emit('input', false);
            } else {
              this.internalValue = 'null';
            }
            break;
          case 'false':
          case false:
            this.internalValue = 'false';
            break;
          default:
            this.internalValue = 'true';
        }
      },
    },
    internalValue(newInternalVal: string): void {
      switch (newInternalVal) {
        case 'true':
          this.$emit('input', true);
          break;
        case 'false':
          this.$emit('input', false);
          break;
        case 'null':
          this.$emit('input', null);
          break;
      }
    },
  },
});
