




import Vue from 'vue';
export default Vue.extend({
  props: {
    value: String,
    length: {
      type: Number,
      default: 60,
    },
  },

  computed: {
    truncatedValue(): string {
      if (typeof this.value !== 'string') {
        return this.value;
      }
      if (this.value.length > this.length) {
        return this.value.substr(0, this.length) + '...';
      }
      return this.value;
    },
  },
});
