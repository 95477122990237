


































import Vue from 'vue';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },
  data() {
    return {
      loading: false,
      results: [],
    };
  },
  computed: {
    queryString(): string {
      return criteriaToQueryString(this.criteria);
    },

    headers(): any[] {
      return [
        { text: i18n.t('time.days'), alight: 'left', value: 'fromDays' },
        {
          text: i18n.t('case.plural'),
          align: 'right',
          value: 'caseCount',
        },
        {
          text: i18n.t('dashboard.percentage'),
          align: 'right',
          value: 'percent',
        },
        {
          text: i18n.t('dashboard.cumulativePercent'),
          align: 'right',
          value: 'cumulativePercent',
        },
      ];
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      const url = `/age-of-pending-caseload?${this.queryString}`;
      const response = await courtAPI.get(url);
      if (response && response.data && response.data.data) {
        this.results = response.data.data;
      }
    },
  },
});
