

















































import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

import i18n from '@/i18n';
import courtAPI from '@/vuex/court/courtAPI';
import { criteriaToQueryString } from '@/lib/criteria';

export default Vue.extend({
  props: {
    criteria: Object,
  },

  data() {
    return {
      loading: false,
      error: null as any,

      disposedBetween: '',

      results: [],
    };
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),

    /**
     * fullCriteria builds the actual API criteria which will be sent. This is
     * done by combinging the incoming criteria prop with customizations set
     * directly on this component.
     */
    fullCriteria(): any {
      const criteria = Object.assign({}, this.criteria);
      criteria.activeOnly = false;
      criteria.disposedBetween = this.disposedBetween;
      return criteria;
    },

    /**
     * queryString builds the URL querystring which will be sent to the API
     * endpoint. It exists as a standalone computed prop so that the watcher
     * can trigger only when the query has changed.
     */
    queryString(): string {
      return criteriaToQueryString(this.fullCriteria);
    },

    headers(): any[] {
      return [
        {
          text: i18n.t('dispositionReason.singular'),
          value: 'dispositionReasonID',
        },
        { text: i18n.t('court.caseCount'), value: 'caseCount', align: 'right' },
      ];
    },
  },

  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      const url = `/cases/by-disposition-reason?${this.queryString}`;

      try {
        const response = await courtAPI.get(url);
        this.results = _.get(response, 'data.data', []);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
});
