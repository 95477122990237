






import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Dossier } from '@/vuex/court/dossier';

export default Vue.extend({
  props: {
    id: Number,
    link: Boolean,
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('court', ['dossierWithID']),

    /**
     * tagName determines whether to render as a router-link or a span, based
     * on the props and permissions.
     */
    tagName(): string {
      if (this.canLink) {
        return 'router-link';
      }
      return 'span';
    },

    /**
     * to computes the route which the dossier number should link to. It will
     * be undefined if a link is not desired or not allowed because of
     * permissions.
     */
    to(): any {
      if (this.canLink) {
        return { name: 'dossierShow', params: { id: String(this.id) } };
      }
      return undefined;
    },

    /**
     * canLink returns true if the developer requested that this dossier
     * number be rendered as a link AND the currently logged-in user
     * has permissions to see that dossier.
     */
    canLink(): boolean {
      if (!this.link) {
        return false;
      }
      return this.hasPermission('dossier.view');
    },

    /**
     * dossier retrieves the Dossier object from Vuex. This will be null if
     * the Dossier has not yet been fetched from the server, or if its
     * totally made up.
     */
    dossier(): Dossier | null {
      return this.dossierWithID(this.id) || null;
    },
  },

  watch: {
    id: {
      immediate: true,
      handler(newID: number): void {
        /**
         * TODO: This approach needs some review. Two concerns:
         * 1. If the same dossierID is referenced on the same page via this
         *    component multiple times, it could trigger one API request per
         * 		component. Some debouncing/request compression like what is done
         *    in CourtName may be desirable.
         * 2. All we need is the Dossier Number text, and this request fetches
         * 		the entire Dossier and every Case on it. Perhaps we need to create
         *    an endpoint to just fetch the metatadata.
         */
        if (newID) {
          const newDossier = this.dossierWithID(newID);
          if (!newDossier) {
            this.$store.dispatch('court/fetchDossier', newID);
          }
        }
      },
    },
  },
});
