
























































import Vue from 'vue';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

import { Case } from '@/vuex/court/case';
import { Participant } from '@/vuex/court/participant';
import { GuiltyChargeDispositionTypeIDs } from '@/vuex/court/charge';

import DossierCaseTabs from '@/components/dossier/DossierCaseTabs.vue';
import DefendantSentenceSummary from '@/components/enforcement/DefendantSentenceSummary.vue';

export default Vue.extend({
  props: {
    id: Number,
  },

  computed: {
    ...mapGetters('language', ['nameForIDInCollection']),
    ...mapGetters('court', [
      'casesForDossier',
      'defendantsForDossier',
      'participantsForCase',
      'partyName',
    ]),

    defendants(): Participant[] {
      return this.defendantsForDossier(this.id);
    },
  },

  methods: {
    disabledCaseIDsForDefendant(val: Participant): number[] {
      const cases: Case[] = this.casesForDossier(this.id);
      return _.chain(cases)
        .filter((kase) => {
          const caseParticipant = _.find(this.participantsForCase(kase.id), {
            partyID: val.partyID,
          });
          if (!caseParticipant) {
            // Disable the tab for a case if this defendant is not in it.
            return true;
          }
          // Disable if this participant has no sentence for this case
          return caseParticipant.sentenceComponents?.length < 1;
        })
        .map('id')
        .value();
    },

    /**
     * highestChargeSeverity finds the highest charge severity of all guilty dispositions
     * returns a string of that severity for display
     */
    highestGuiltyChargeSeverity(val: Participant): number {
      return (
        _.chain(val.charges || [])
          .filter((c) => {
            return _.includes(
              GuiltyChargeDispositionTypeIDs,
              c.dispositionTypeID,
            );
          })
          .map('crimeSeverityID')
          .max()
          .value() || 0
      );
    },
  },

  components: {
    DossierCaseTabs,
    DefendantSentenceSummary,
  },
});
