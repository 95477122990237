























import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import i18n from '@/i18n';
import { Court } from '@/vuex/court/court';
import { VSelectItem } from '@/lib/vue-typescript';
import { TAll, TNone, TUnknown } from '@/lib/translated';

export default Vue.extend({
  props: {
    label: String,
    value: Number,
    rules: Array,
    stageID: Number,
    includeUnknown: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    clearable: Boolean,

    /**
     * requireLeafNode triggers a validator to force the user to select a
     * fully-qualified Court: one with no children below it.
     */
    requireLeafNode: Boolean,

    zeroLabel: {
      type: String,
      default: 'unknown',
    },
    mustHavePermission: String,
  },
  data() {
    return {
      internalValues: [0],
      loading: true,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters('language', ['valueForLocale']),
    ...mapGetters('court', [
      'allCourts',
      'courtWithID',
      'courtsUnder',
      'courtAncestorIDs',
    ]),
    ...mapGetters('auth', ['entityIDsWithPermission']),
    ...mapState('language', ['locale']),

    /**
     * computedRules combines the user-provided rules prop with rules triggered
     * by other factors.
     */
    computedRules(): any[] {
      const rules: any[] = this.rules || [];
      if (this.requireLeafNode) {
        rules.push(this.mustBeLeafNode);
      }
      return rules;
    },

    /**
     * court provides convenient access to the Department entity which
     * is currently selected (the rightmost dropdown selection)
     */
    court(): Court | null {
      return this.courtWithID(this.value) || null;
    },

    /**
     * dropdowns is the heart of this component. It builds an array of
     * VSelectItem[], with each one representing the choices which should be
     * in a Dropdown control presented to the user.
     */
    dropdowns(): VSelectItem[][] {
      return _.chain(this.internalValues)
        .map((id: number, index: number) => {
          const priorID = _.get(this.internalValues, index - 1);
          let parentID = id;
          const court = this.courtWithID(id);
          if (court && priorID !== id) {
            parentID = court.parentID;
          }
          let choices = _.chain(this.courtsUnder(parentID))
            .filter(this.validForStage(this.stageID))
            .map((court) => ({
              text: `${court.name[this.locale]}`,
              value: court.id,
              rank: court.rank || 999999,
            }))
            .orderBy(['rank', 'text'])
            .value();

          if (this.mustHavePermission) {
            const allowedIDs = this.entityIDsWithPermission(
              this.mustHavePermission,
            );
            const hasNone = allowedIDs.length < 1;
            const hasAll = _.includes(allowedIDs, '*');

            if (hasNone) {
              // If no allowed IDs, the list should be empty
              choices = [];
            } else if (!hasAll) {
              choices = _.filter(choices, (court) => {
                return _.includes(allowedIDs, court.value);
              });
            }
          }

          if (!_.includes(_.map(choices, 'value'), id)) {
            choices.unshift({ text: this.zeroText, value: id, rank: 0 });
          } else {
            choices.unshift({
              text: this.zeroText,
              value: parentID,
              rank: 0,
            });
          }
          return !!choices.length ? choices : null;
        })
        .compact()
        .value();
    },

    /**
     * zeroText computes the text property of the dropdown entry which is the
     * "zero" value, which at the top level represents no Department selected,
     * and at lower levels means the parent is selected with available children,
     * but no child is yet selected. This function outputs translated values
     * of "All" or "None" depending on the setting of the zeroLabel prop.
     */
    zeroText(): string {
      if (this.zeroLabel === 'all') {
        return TAll[this.locale].toString();
      } else if (this.zeroLabel === 'none') {
        return TNone[this.locale].toString();
      }
      return TUnknown[this.locale].toString();
    },

    /**
     * cols computes the number of columns each dropdown should occupy. It is
     * determined based on the number of dropdowns which are needed.
     */
    cols(): number {
      const count = this.dropdowns.length;
      if (count < 2) {
        return 12;
      }
      if (count % 2 === 0 && count < 4) {
        return 12 / count;
      }
      return 4;
    },
  },
  watch: {
    /**
     * value is watched so that the internalValue can be updated to represent
     * the new externally-provided value.
     */
    value: {
      immediate: true,
      handler(newVal: number) {
        const ids = this.courtAncestorIDs(newVal);
        const department = this.courtWithID(newVal);

        if (!!department && department.childCount > 0) {
          // Add a second copy of the chosen id to occupy the
          // Unknown entry of its children.
          ids.push(newVal);
        }
        Vue.set(this, 'internalValues', ids);
      },
    },

    /**
     * stageID is watched so that we can invalidate the currently selected
     * court if it is not valid for the new stage
     */
    stageID(newStageID: number) {
      if (!this.validForStage(newStageID)(this.court)) {
        this.$emit('input', 0);
      }
    },
  },
  methods: {
    /**
     * mustBeLeafNode is a validator function which ensures that the selected
     * Court is a "leaf" node (one with no children beneath it).
     * Returns true if the validation passes, and an error string otherwise.
     */
    mustBeLeafNode(v: any): boolean | string {
      if (!this.court) {
        // Not having a court means we can't verify it's a leaf node,
        // but that doesn't make it an error state.
        return true;
      }
      if (this.court.childCount && this.court.childCount > 0) {
        // Children found under the selected item
        return i18n.t('error.required').toString();
      }
      return true;
    },

    /**
     * validForStage returns a function which can be used in a .filter() chain
     * to ensure that a Court is valid for a particular stage ID. A Court is
     * considered valid for a stage if it or any of its children is valid for
     * that stage. This is evaluated recursively.
     *
     * Example usages:
     *
     * _.filter(courts, this.validForStage(StageAppellateID))
     * _.filter(courts, this.validForStage(this.stageID))
     * if (this.validForStage(this.stageID)(this.court)) {}
     */
    validForStage(stageID: number): (Court) => boolean {
      const fn = (court: Court): boolean => {
        // If no stageID was set, then consider everthing valid.
        if (!stageID) {
          return true;
        }

        // If the Court itself has this stageID included, then it's valid.
        if (_.includes(court.stageIDs, stageID)) {
          return true;
        }

        // If any of this Court's children is valid for this Stage, then
        // include it.
        return _.some(this.courtsUnder(court.id), fn);
      };
      return fn;
    },

    async fetchData(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('court/fetchCourts', { force: false });
      this.loading = false;
    },

    /**
     * dropdownChanged is the event handler for the @change event on all
     * dropdowns.
     */
    dropdownChanged(i: number, newVal: number): void {
      if (i > 0 && !newVal) {
        this.$emit('input', this.internalValues[i - 1]);
        return;
      }
      this.$emit('input', newVal);
    },
  },
});
