














import _ from 'lodash';
import Vue from 'vue';

import offenderAPI from '@/vuex/offender/offenderAPI';
import { AccompanyingChild } from '@/vuex/offender/accompanyingChild';

import AccompanyingChildForm from '@/components/offender/accompanyingChild/AccompanyingChildForm.vue';

export default Vue.extend({
  props: {
    offenderID: Number,
  },

  data() {
    return {
      error: null as any,
      isOpen: false,

      accompanyingChild: null as AccompanyingChild | null,
    };
  },

  methods: {
    /**
     * reset is the handler for CMSFormDialog's reset event.
     * We use it to reset the object being edited to its default state.
     */
    reset() {
      this.error = null;
      this.accompanyingChild = null;
    },

    /**
     * save is the handler for CMSFormDialog's save event.
     * We use it to persist the object to the database and close the dialog.
     */
    async save(): Promise<void> {
      if (!this.accompanyingChild) {
        return;
      }

      try {
        // Execute the API call
        const response = await offenderAPI.post('/add-accompanying-child', {
          data: {
            offenderID: this.offenderID,
            intakeDate: this.accompanyingChild.intakeDate,
            releaseDate: this.accompanyingChild.releaseDate,
            name: this.accompanyingChild.name,
            birthdate: this.accompanyingChild.birthdate,
            birthdateIsApproximate:
              this.accompanyingChild.birthdateIsApproximate,
            notes: this.accompanyingChild.notes,
          },
        });

        // Update local state in Vuex
        const newState = _.get(response, 'data.data', {});
        this.$store.commit('offender/setState', newState);
        this.$store.commit('offender/prependToTarget', {
          target: 'accompanyingChildrenIDsForOffender',
          index: this.offenderID,
          value: Object.keys(newState.accompanyingChild),
        });

        // Close the dialog
        this.isOpen = false;
      } catch (error) {
        this.error = error;
      }
    },
  },

  components: {
    AccompanyingChildForm,
  },
});
